import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
  TableHead,
} from "@mui/material";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Pagination from "../Pagination/Pagination";
import { actionTypo, actionsIcon, statusTopBox } from "./TableCSS";
import moment from "moment/moment";
import { statusConstants } from "../constants/FiltersConstant";
import styles from "./Table.module.css";

const OriginatorDealListTab = ({
  data,
  viewActionClickHandler,
  nextPageHandler,
  prevPageHandler,
  header,
  page,
  totalRecords,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    statusType =
      statusType && statusType.toLowerCase().includes("ts")
        ? statusType
        : statusType.toUpperCase();

    const Textcolor =
      statusType === statusConstants.APPROVED ||
      statusType === statusConstants.CREATED ||
      statusType === statusConstants.DONE
        ? "#277742"
        : statusType === statusConstants.REJECTED ||
          statusType === statusConstants.DEAL_REJECTED
        ? "#8D3431"
        : statusType === statusConstants.CLOSED
        ? "#2C4584"
        : statusType === statusConstants.PENDING
        ? "#996E37"
        : statusType === statusConstants.NOT_CREATED
        ? "#996E37"
        : statusType === statusConstants.APPROVAL_PENDING
        ? "#996E37"
        : "#277742";

    const boxBackgroudColor =
      statusType === statusConstants.APPROVED ||
      statusType === statusConstants.CREATED ||
      statusType === statusConstants.DONE
        ? "#E3F6E9"
        : statusType === statusConstants.REJECTED ||
          statusType === statusConstants.FAILED ||
          statusType === statusConstants.DEAL_REJECTED
        ? "#FCE6E5"
        : statusType === statusConstants.CLOSED
        ? "#E4EAFA"
        : statusType === statusConstants.PENDING
        ? "#FFF4E7"
        : statusType === statusConstants.NOT_CREATED
        ? "#FFF4E7"
        : statusType === statusConstants.APPROVAL_PENDING
        ? "#FFF4E7"
        : "#E3F6E9";

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          {statusType && (
            <p className={styles.statusPTag}>
              {statusType && statusType.toLowerCase().includes("ts")
                ? statusType
                : statusType
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")}
            </p>
          )}
        </div>
      </Box>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item?.name}
                  </StyledTableCell1>
                  <StyledTableCell component="th" scope="row">
                    {moment(item.createdAt).format("D MMM YYYY")}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.loanType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.requestedDealAmount}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <StatusContainer
                      statusType={item?.status}
                    ></StatusContainer>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        Page={page}
        TotalNoNumber={totalRecords}
        nextPageHandler={nextPageHandler}
        prevPageHandler={prevPageHandler}
      ></Pagination>
    </div>
  );
};

export default OriginatorDealListTab;
