import React from "react";
import { Button } from "@mui/material";
import { Colors } from "../../theme/theme";
import { btnStyle } from "./ButtonsCSS";

const PrimaryButton = ({
  onClickHandler,
  text,
  isDisable,
  width = "165px",
  endIcon
}) => {
  return (
    <Button
      onClick={onClickHandler}
      variant="contained"
      disabled={isDisable}
      endIcon={endIcon ? endIcon : null}
      sx={btnStyle}
      type="submit"
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
