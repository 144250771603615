import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { Alert } from "@mui/material";
import { showAlertAction } from "../../redux/app/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { alertStyle, paddingLeft } from "./AlertCSS";


const SuccessAlert = () => {
  //? useSelector
  let alert = useSelector((state) => state.app.showAlert);

  console.log({ alert });

  const [state, setState] = useState(alert);
  const [transition, setTransition] = useState(<Slide direction="up" />);

  //? useDispatch
  let dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showAlertAction(false));
  };

  useEffect(() => {
    setState(alert && alert.message ? alert.message : alert);
  }, [alert]);

  console.log({ state });
  return (
    <div>
      {state && (
        <Snackbar
          sx={paddingLeft}
          open={state}
          onClose={handleClose}
          message="I love snacks"
          autoHideDuration={6000}
        >
          <Alert
            onClose={handleClose}
            style={alertStyle}
            variant="filled"
          >
            {state}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default SuccessAlert;
