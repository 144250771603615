import * as React from "react";
import { MenuItem, Select, ListItemText, ListSubheader } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, TextField, Stack, Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";
import { Box } from "@mui/system";
import { GeneratedReportFileV2 } from "../Phase2/UploadReports/GenerateReportV2";

import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";
import {
  GenerateReportBoxContainer,
  GenerateReportButton,
  GenerateReportHeight,
  GenerateReportMenuProps,
  GenerateReportSelect,
  listSubheaderStyle,
  searchCodeStyle,
} from "../../Pages/Phase2/SearchReports/SearchReports";
import { reportConstants } from "../constants/GlobalConstants";
import { DocumentHeader } from "./../DocumentHeaderWrapper/DocumentHeader";

const MenuProps = {
  PaperProps: {
    style: GenerateReportMenuProps,
  },
};

export default function AsOnDateReportComponent({
  reportList,
  statusData,
  handleInputChange,
  displayedOptions,
  setFilter,
  filter,
  uploadReportState,
  SerachReportState,
  handleGenerateReport,
  reportHeading,
  asOnDateStatus,
  selectedReportName,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <SelectComponent
              placeholder="Select Type of Report"
              value={statusData.typeOfReport}
              onClickHandler={handleInputChange("typeOfReport")}
              optionList={reportList}
            ></SelectComponent>
          </TextWrapperComponent>
        </Grid>

        <Grid item xs={4}>
          <TextWrapperComponent text="Deal Name">
            <Select
              sx={GenerateReportSelect}
              IconComponent={ExpandMoreIcon}
              displayEmpty
              value={statusData.dealName}
              onChange={handleInputChange("dealName")}
              variant="standard"
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p className={Styles.dealNameStyle}>Deal Name</p>;
                }

                let selectedItems = uploadReportState.dealList.filter(
                  (item) => item.id == selected
                );
                return selectedItems[0]?.name;
              }}
            >
              <ListSubheader sx={listSubheaderStyle}>
                <TextField
                  size="medium"
                  placeholder="Search deal"
                  fullWidth
                  value={filter}
                  sx={{
                    input: searchCodeStyle,
                  }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </ListSubheader>

              {displayedOptions.length > 0
                ? [...displayedOptions].map((variant, index) => {
                    return (
                      <MenuItem
                        key={variant.id}
                        value={variant.id}
                        sx={GenerateReportHeight}
                      >
                        <ListItemText
                          primary={
                            variant?.dealCode?.length > 0
                              ? variant.name + "(" + variant?.dealCode + ")"
                              : variant.name
                          }
                        />
                      </MenuItem>
                    );
                  })
                : uploadReportState?.dealList.map((variant, index) => {
                    return (
                      <MenuItem
                        key={variant.id}
                        value={variant.id}
                        sx={GenerateReportHeight}
                      >
                        <ListItemText
                          primary={
                            variant?.dealCode?.length > 0
                              ? variant.name + "(" + variant?.dealCode + ")"
                              : variant.name
                          }
                        />
                      </MenuItem>
                    );
                  })}
            </Select>
          </TextWrapperComponent>
        </Grid>
      </Grid>
      <br></br>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={SerachReportState["loading"] ? true : asOnDateStatus}
          onClick={handleGenerateReport}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "Generate Report"}
        </Button>
      </div>

      {/* report data */}
      {SerachReportState["reportData"] === null ? null : SerachReportState[
          "reportData"
        ]["message"] === "No Data Found" ? null : (
        <Box sx={GenerateReportBoxContainer}>
          <Grid container>
            <Grid item xs={12}>
              <DocumentHeader title={reportHeading}></DocumentHeader>
              <div className={Styles.CommanPadding}>
                <GeneratedReportFileV2
                  // fileName={selectedReportName + ".xlsx"}
                  fileName={
                    selectedReportName === "Accounting Report" ||
                    selectedReportName === reportConstants.LEDGER_REPORT
                      ? selectedReportName + ".xlsx"
                      : SerachReportState["reportData"]["fileName"] ||
                        SerachReportState["reportData"]["filename"]
                  }
                  id={SerachReportState["reportData"]["file"]}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
