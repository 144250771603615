import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getAMLTemplate,
  uploadKYCAMLDoc,
} from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import { showAlertAction } from "../../../redux/app/appSlice";
import { deleteAmlFile } from "../../../redux/originator/originatorSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import BasicModal from "../../Modal/Modal";
import AMLUpload from "../../Wrapper/AMLUpload";
import Paths from "../../constants/Path";
import TabFooter from "../TabFooter/TabFooter";

const KYCAMLUpload = ({ nextTabHandler }) => {
  const [open, setOpen] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [loanFile, setLoanFile] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [status, setStatus] = useState(true);
  const [templateData, setTemplateData] = useState("");
  const [newloader, setNewLoader] = useState(true);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const params = useParams();
  let { dealId } = params;
  let dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loanFile != null) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [loanFile]);

  const onClickHandlerToDealsPage = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getAMLTemplate({ type: "AML_UPLOAD" })).then((res) => {
      setTemplateData(res?.payload?.data?.file);
      setNewLoader(false);
    });
  }, []);
  useEffect(() => {
    dispatch(deleteAmlFile({ fileName: "amlUpload" }));
  }, []);

  const handleClosureStatus = (e) => {
    e.preventDefault();
    dispatch(
      uploadKYCAMLDoc({
        file: loanFile,
        dealId: dealId,
        financialYear: "2022-2023",
        serviceName: "portfolio-service",
        endpoint: "upload",
      })
    ).then((res) => {
      if (res?.payload?.status == 200) {
        dispatch(showAlertAction("Your file is getting Uploaded!"));
        const userData = JSON.parse(localStorage.getItem("UserData"));
        if (userData?.roles[0] == "F1") {
          history.push(Paths.F1LISTOFDEAL);
        } else {
          history.push(
            userData?.roles?.includes("L2")
              ? Paths.L2DEALLIST
              : Paths.DEALS_LIST
          );
        }
      }
    });
    setOpen(false);
  };

  //
  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title={"Download KYC AML Upload Template"}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              />
            }
          ></DocumentHeader>
          <div
            style={{
              padding: "24px",
            }}
          >
            <ViewDownloadDeleteComp
              showEye={false}
              fileName={templateData?.filename}
              id={templateData?._id}
            ></ViewDownloadDeleteComp>
          </div>

          <DocumentHeader
            title={"Upload KYC AML"}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              />
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <AMLUpload
              docName="amlUpload"
              setLoanFile={setLoanFile}
              setdata={setState}
              isdisabled={false}
            />
          </div>


          <TabFooter
            label="Upload"
            isBtnDisable={status}
            endIcon={<DoneIcon />}
            onclickHandler={onClickHandlerToDealsPage}
          />

          <BasicModal
            open={open}
            setOpen={setOpen}
            title="Do you want to Upload this File?"
            positiveText="Yes"
            negativeText="No"
            positiveOnClickHandler={handleClosureStatus}
            setloading={setMsgLoading}
          ></BasicModal>
        </>
      )}
    </>
  );
};

export default KYCAMLUpload;
