import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import {
  finaliseoriginatorTermsheet,
  getTermSheet,
  pageStatusAction,
  uploadoriginatorTermsheet,
} from "../../../redux/TermSheet/TermSheetSlice";
import { showAlertAction } from "../../../redux/app/appSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import BasicModal from "../../Modal/Modal";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import TabFooter from "./../TabFooter/TabFooter";

const FinaliseTermSheet = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  const [loanFile, setLoanFile] = useState(null);
  const [state, setState] = useState(null);
  const [status, setStatus] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [termSheetData, setTermSheetData] = useState("");
  const [newloader, setNewLoader] = useState(true);
  const dealState = useSelector((state) => state.deal);

  let userData = JSON.parse(localStorage.getItem("UserData"));
  let activRole = localStorage.getItem("activeRole")
  let dealid = localStorage.getItem("dealId");
  let uniqueCode = localStorage.getItem("uniqueCode");
  let history = useHistory();

  const singleDealData =
    dealState?.filterdata?.poolUploadFinal?.termsheetFinalStatus;

  let removeCard = false;

  let roleName = activRole;

  if (singleDealData?.Originator === "DONE" && roleName === "Originator") {
    removeCard = true;
  }
  if (singleDealData?.L1 === "DONE" && roleName === "L1") {
    removeCard = true;
  }

  const onClickHandlerToDealsPage = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(pageStatusAction({ pageName: "FinaliseTermSheet" }));
    // nextTabHandler();
  };

  //handle rolewise to finalize termsheet
  const handleClosureStatus = (e) => {
    e.preventDefault();
    if (loanFile == undefined) {
      dispatch(
        finaliseoriginatorTermsheet({
          uniqueCode: uniqueCode,
          dealId: dealid,
          termsheetType: "final",
          roleName: activRole,
        })
      ).then((res) => {
        if (res.payload.data.status === 200) {
          dispatch(showAlertAction("Term Sheet Finalised!"));
          if (activRole == "Originator") {
            return history.push("/entitydeallist");
          }
          if (activRole != "Originator") {
            return history.push("/portfolio/deal-list");
          }
        }
      });
    } else {
      dispatch(
        uploadoriginatorTermsheet({
          file: loanFile,
          uniqueCode: uniqueCode,
          dealId: dealid,
          termsheetType: "final",
          roleName: activRole,
        })
      ).then((res) => {
        if (res.payload.data.status === 200) {
          dispatch(showAlertAction("Term Sheet Finalised!"));
          if (activRole == "Originator") {
            return history.push("/entitydeallist");
          }
          if (activRole != "Originator") {
            return history.push("/portfolio/deal-list");
          }
        }
      });
    }

    setOpen(false);
  };

  //getting deal details
  useEffect(() => {
    dispatch(getSingleDealDetails(dealid)).then((res) => {
      localStorage.setItem(
        "uniqueCode",
        res?.payload?.data?.data?.data?.uniqueCode
      );
    });
  }, []);
  //
  //getting final termsheet for finalizing
  useEffect(() => {
    if (uniqueCode != undefined) {
      dispatch(getTermSheet({ uniqueCode: uniqueCode, dealId: dealid })).then(
        (res) => {
          setTermSheetData(res?.payload?.data);
          setNewLoader(false);
        }
      );
    }
  }, []);

  //button disbale handler
  useEffect(() => {
    if (loanFile == undefined) {
      setStatus(false);
    } else {
      setStatus(false);
    }
  }, [loanFile]);

  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title="Finalised Term Sheet"
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              ></DescriptionIcon>
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <ViewDownloadDeleteComp
              fileName={
                activRole === "Originator"
                  ? termSheetData?.termsheetDraftFile?.filename
                  : termSheetData?.termsheetUploadedFile?.filename
              }
              id={
                activRole === "Originator"
                  ? termSheetData?.termsheetDraftFile?._id
                  : termSheetData?.termsheetUploadedFile?._id
              }
            ></ViewDownloadDeleteComp>
          </div>

          {/* remove upload option for L2 role  */}
          {activRole != "L2" && !removeCard && userData?.roles.length == 1 ? (
            <>
              <DocumentHeader
                title={"Term Sheet"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>
              <div style={{ padding: "10px", width: "auto" }}>
                <UploadTemplate
                  docName="FinalDraft"
                  setdata={setState}
                  setLoanFile={setLoanFile}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          {userData?.roles.length == 1 ? (
            <TabFooter
              label="Finalise"
              isBtnDisable={status}
              endIcon={<DoneIcon />}
              onclickHandler={onClickHandlerToDealsPage}
            />
          ) : (<></>)}

          <BasicModal
            open={open}
            setOpen={setOpen}
            title="Do you want to Upload this File?"
            positiveText="Yes, Finalise the term sheet"
            negativeText={
              activRole != "L2"
                ? "No, Upload corrected term sheet"
                : "No, Let Me Check"
            }
            positiveOnClickHandler={handleClosureStatus}
            setloading={setMsgLoading}
          ></BasicModal>
        </>
      )}
    </>
  );
};

export default FinaliseTermSheet;
