import React, { useState, useEffect } from "react";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import QueriesPage from "../../../components/Queries/Queries";
import Histories from "../../../components/History/Histories";
import Paths from "../../../components/constants/Path";
import ReportsDetails from "./ReportsDetails";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSingleDealReport } from "../../../redux/Phase2/UploadReportSlice";

const ViewReportsDetails = () => {
  const { id, dealname } = useParams();
  const [loanData, setLoadData] = useState("")
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  
    //? Getting the user data from local storage
    let userData = JSON.parse(localStorage.getItem("UserData"));
  
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleDealReport(id)).then((data) => {
      setLoadData(data?.payload?.data)
    });
  }, []);

  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      path: Paths.LISTOFREPORTS,
      pathName: "Reports",
    },
    {
      pathName: loanData?.dealIdRefData?.name,
    },
  ];

  let TabList = [
    {
      name:
        userData.roles[0] === "F1"
          ? "Upload Monthly Collection Report"
          : "Report",
      index: 0,
      component: <ReportsDetails loanUploadFinalId={id} />,
    },
  ]

  if(userData?.roles.length == 1){
    TabList.push(
      {
        name: "Queries",
        index: 1,
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
  
      {
        name: "History",
        index: 2,
        component: <Histories id={id} />,
      },
    )
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    //? Layout is a component which is used to render the tabs
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={loanData?.dealIdRefData?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={TabList}
      ></TabsLayout>
    </Layout>
  );
};

export default ViewReportsDetails;
