import React from "react";
import { Box, Grid, Stack, Typography, Divider, TextField, InputAdornment } from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import ReportsTable from "../../../components/Table/Phase2/ReportsTable";
import { getReportList, getVoucherDetails } from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import { useSelector } from "react-redux";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import VoucherTable from './../../../components/Table/Phase2/VoucherTable';
import { Colors } from "../../../theme/theme";
import { ReportDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from '@mui/icons-material/Description';
import Image from "../../../assets/images/Group 42722.svg";
import { useReactToPrint } from "react-to-print";
import TextWrapperComponent from './../../../components/FormComponents/TextWrapperComponent';
import { SuffixTextField } from './../../../components/FormComponents/SuffixPrefixTextFieldV2';
import styles from "./VoucherCard.module.css";

export default function CommonFields({ voucher }) {
    return <div >
        <div >
            {
                voucher !== null && <>
                    <div >
                        <div style={{ padding: "20px" }}>
                            <form>
                                <Grid container spacing={4}>
                                    <Grid item xs={4}>
                                        <TextWrapperComponent text="Transaction Id">
                                            <TextField
                                                size="small"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                value={voucher[0].transactionId}
                                                type="text"
                                                disabled={true}
                                                min={0}
                                            />
                                        </TextWrapperComponent>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextWrapperComponent text="Customer Name">
                                            <TextField
                                                size="small"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                value={voucher[0].customerName}
                                                type="text"
                                                disabled={true}
                                                min={0}
                                            />
                                        </TextWrapperComponent>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextWrapperComponent text="Branch">
                                            <TextField
                                                size="small"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                value={voucher[0].branch}
                                                type="text"
                                                disabled={true}
                                                min={0}
                                            />
                                        </TextWrapperComponent>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextWrapperComponent text="Loan Number">
                                            <TextField
                                                size="small"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                value={voucher[0].loanNumber}
                                                // onChange={handleInputChange("dpd")}
                                                type="text"
                                                disabled={true}
                                                min={0}
                                            />
                                        </TextWrapperComponent>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextWrapperComponent text="Created By">
                                            <TextField
                                                size="small"
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                value={voucher[0].createdBy}
                                                // onChange={handleInputChange("dpd")}
                                                type="text"
                                                disabled={true}
                                                min={0}
                                            />
                                        </TextWrapperComponent>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </>
            }
        </div>
    </div >
}
