import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../theme/theme";
import Layout from "../components/Layout/Layout";
import { getData } from "../API/apiHandler";
import { useEffect } from "react";
import Loader from "../utils/Loader";
import { getFormatedAmount } from "../utils/helper";

export default function Home() {
  const [data, setData] = React.useState({
    loading: false,
    dealCreation: [],
    overAllDeal: [],
  });

  const getDashboardData = async () => {
    setData({ ...data, loading: true });
    const res = await getData("/utility-service/getDashboardData");
    console.log({ res });
    setData({ ...res?.data, loading: false });
  };
  console.log({ data });

  useEffect(() => {
    getDashboardData();
  }, []);

  let userData = JSON.parse(localStorage.getItem("UserData"));
  const roleName = userData.roles[0];
  const UserDetails = () => {
    return (
      <Box paddingLeft="28px" paddingTop="20px">
        {/* <RedirectToLoginPage /> */}
        <Typography fontSize="14px" fontWeight="400" color={Colors.light_grey}>
          Hello
        </Typography>
        <Stack direction="row" justifyContent="space-between" paddingRight={4}>
          <Typography fontSize="20px" fontWeight="800" color={Colors.black}>
            {userData.name}👋
          </Typography>
          {/* {userData.roles[0] === "F1" ? (
            <Stack direction="row" justifyContent="start" spacing={3}>
              <SecoundaryButton
                onClickHandler={onClickHandlerToCreateOriginator}
                text="Upload New Report"
              ></SecoundaryButton>
            </Stack>
          ) : null} */}
        </Stack>
      </Box>
    );
  };

  const styles = {
    boxStyle: {
      boxShadow:
        "0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4)",
      borderRadius: "8px",
      padding: "13px 14px",
      minWidth: "145px",
      marginRight: "18px",
    },
    number: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "bold",
    },
    narration: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      color: Colors.light_grey,
      textTransform: "capitalize",
      marginTop: "4px",
    },
  };

  const showPos = ["Originator"];

  let creationObj = {
    APPROVED: "Deal Approved",
    PENDING: "Approval In Progress",
    REJECTED: "Rejected",
  };

  let overallObj = {
    APPROVED: "Deal Closed",
    PENDING: "Deal In Progress",
    REJECTED: "Rejected",
  };

  let auditorObj = {
    PENDING: "Pending Approval",
    DONE: "In Progress Portfolios",
  };

  const SummaryBox = ({ label, arrayData = [] }) => {
    return (
      <Box>
        <Typography
          sx={{
            ...styles?.narration,
            color: "black",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          {label}
        </Typography>
        <Box display="flex" justifyContent={"space-between"}>
          {arrayData.map((item, index) => {
            const isStatusValid =
              item?.status &&
              item.status !== "CLOSED" &&
              item.status !== "ON_HOLD";

            if (isStatusValid) {
              const statusText =
                label === "Deal Creation"
                  ? creationObj[item.status]
                  : roleName === "Auditor"
                  ? auditorObj[item.status]
                  : overallObj[item.status];

              return (
                <Box sx={styles?.boxStyle}>
                  <Typography sx={styles?.number}>{item.count}</Typography>
                  {showPos.includes(roleName) && (
                    <Typography sx={{ ...styles?.narration, color: "black" }}>
                      {getFormatedAmount(item.outstandingAmount)}
                    </Typography>
                  )}
                  <Typography sx={styles?.narration}>{statusText}</Typography>
                </Box>
              );
            }

            // Return null or any fallback component if the status is not valid
            return null;

            // return (
            //   <>
            //     {item.status &&
            //       item?.status !== "CLOSED" &&
            //       item?.status !== "ON_HOLD" && (
            //         <Box sx={styles?.boxStyle}>
            //           <Typography sx={styles?.number}>{item?.count}</Typography>
            //           {showPos.includes(roleName) && (
            //             <Typography
            //               sx={{ ...styles?.narration, color: "black" }}
            //             >
            //               {getFormatedAmount(item?.outstandingAmount)}
            //             </Typography>
            //           )}
            //           <Typography sx={styles?.narration}>
            //             {label === "Deal Creation"
            //               ? creationObj[item?.status]
            //               : roleName === "Auditor"
            //               ? auditorObj[item?.status]
            //               : overallObj[item?.status]}
            //           </Typography>
            //         </Box>
            //       )}
            //   </>
            // );
          })}
        </Box>
      </Box>
    );
  };
  //
  const showCreationData = ["L1", "L2"];
  return (
    <div>
      {data?.loading ? (
        <Loader />
      ) : (
        <>
          <UserDetails />
          <Box
            paddingLeft="24px"
            paddingTop="28px"
            display="flex"
            justifyContent={"space-between"}
          >
            {showCreationData.includes(roleName) && (
              <SummaryBox
                label="Deal Creation"
                arrayData={data?.dealCreation}
              />
            )}
            <SummaryBox label="Overall" arrayData={data?.overAllDeal} />
          </Box>
          {/* </Layout> */}
        </>
      )}
    </div>
  );
}
