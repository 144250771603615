import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from "../../assets/images/Group 42722.svg";
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import Paths from "../../components/constants/Path";
import { getSingleAmlUpload } from "../../redux/Phase3/KYCAML/KYCAMLSlice";
import AMLForm from "../Phase3/AML/AMLForm";
import AuditorLoanUploadSummaryDetails from "./AuditorKYCLoanUploadSummary/AuditorLoanUploadSummaryDetails";
import { statusConstants } from "../../components/constants/FiltersConstant";

const AuditorKYCLoanUploadSummary = () => {
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  const [uploadData, setUploadData] = useState("");

  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: params.dealId })).then((res) => {
      setUploadData(res?.payload?.data);
    });
  }, []);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.AUDITORDEALIST,
      pathName: "Deals List",
    },
    {
      pathName: dealState?.name,
    },
  ];

  let TabsList = [
    {
      name: "Loan",
      component: <AuditorLoanUploadSummaryDetails />,
      index: 0,
    },
  ];
  if (userData?.roles.length == 1) {
    TabsList.push(
      {
        name: "History",
        component: <Histories />,
        index: 1,
      },
      {
        name: "Queries",
        component: (
          <QueriesPage
            dealIdorOriginatorId={params?.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
        index: 2,
      },
    )
  }

  if (userData?.roles.length == 1) {
    if (dealState?.filterdata?.dealType !== "SELL" && uploadData != undefined) {
      TabsList.push({
        name: "Compliance",
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  } else {
    if (dealState?.filterdata?.dealType !== "SELL" &&
      uploadData &&
      uploadData?.currentStatus !== statusConstants.PENDING &&
      uploadData?.currentStatus !== statusConstants.FILE_RECEIVED &&
      uploadData?.currentStatus !== statusConstants.IN_PROGRESS) {
        TabsList.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <TabsLayout
          TabsList={TabsList}
          formName={dealState?.name}
          icon={icon}
          iconColor={"#DBE3F8"}
          image={Image}
        />
      </Layout>
    </>
  );
};

export default AuditorKYCLoanUploadSummary;
