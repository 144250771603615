import { Button, IconButton, Stack, Typography } from "@mui/material";
import EditIcon from "../../assets/icons/edit_icon.svg";
import Styles from "./DocumentHeaderWrapper.module.css";
import {
  TypographyStyleOpacity,
  IconButtonStyle,
} from "./DocumentHeaderWrapperCSS";
import AddIcon from "@mui/icons-material/Add";

export const DocumentHeader = ({
  title,
  opacity = false,
  startIcon,
  showEditButton = false,
  showAddButton = false,
  handleOnClick,
}) => {
  const TypoStyle = TypographyStyleOpacity(opacity);
  return (
    <div className={Styles.main}>
      <Stack direction="row" spacing={1}>
        {startIcon}
        <Typography sx={TypoStyle}>{title}</Typography>
      </Stack>
      {showEditButton && (
        <IconButton sx={IconButtonStyle} onClick={handleOnClick}>
          <img src={EditIcon} />
        </IconButton>
      )}
      {showAddButton && (
        <IconButton sx={IconButtonStyle} onClick={handleOnClick}>
          <AddIcon />
        </IconButton>
      )}
    </div>
  );
};
