import { Grid, Link, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageStatusAction } from "../../../redux/L1/kycDocumentsRequestSlice";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import TabFooter from "../TabFooter/TabFooter";
import DescriptionIcon from "@mui/icons-material/Description";
import ViewDownloadDeleteComp from "../../L2/ViewDownloadDeleteComp";
import SettingsIcon from "@mui/icons-material/Settings";
import OutlineButton from "../../Buttons/OutlineButton";
import WrapperComponent from "../../Wrapper/WrapperComponent";
import ManIcon from "../../../assets/images/Man.png";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { showAlertAction } from "../../../redux/app/appSlice";
import { goForReUploadNotification } from "../../../redux/L1/secondaryCheckSlice";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../../API/apiHandler";
import styles from "./L1KYCDocumentsRequestTabs.module.css"
import { descWrapper, headingWrapper, stackWrapper } from "./L1KYCDocumentsRequestTabsCSS";

const RequestKYCDocuments = ({ nextTabHandler, setValue }) => {
  const dealState = useSelector((state) => state.deal);
  let dispatch = useDispatch();
  const history = useHistory();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const nextBtnClickHandler = (e) => {
    e.preventDefault();

    dispatch(pageStatusAction({ pageName: "requestKYCDocuments" }));
    nextTabHandler();
  };

  const onClickHandler = async () => {
    // dispatch(goForReUploadNotification({ notification: `Send Request for KYC Documents for ${dealState?.name}`, notificationType: "kyc-documents", visibleBy: dealState?.originatorIdData?._id })).then((res) => {
    // })
    await postRequest("/utility-service/notification", {
      type: "FRONT-END NOTIFICATION",
      dealId: dealState?.filterdata?._id,
      event: "KYC_DRIVE_LINK_REQUEST",
    });
    dispatch(showAlertAction("Request Send Successfully"));
    history.push("/creditbureaureport/dealist");
  };

  return (
    <>
      <div className={styles.main}>
        <div>
          <Stack sx={stackWrapper}>
            <img src={ManIcon} width="150px" height="150px"></img>
            <Typography
              sx={headingWrapper}
              paddingTop={1}
            >
              Request for KYC Documents
            </Typography>
            <Typography
              sx={descWrapper}
              padding={1}
            >
              You can request for it here
            </Typography>
            {userData?.roles.length != 1 ? (
              <></>
            ) : (

              <PrimaryButton
                text="Request KYC Documents"
                onClickHandler={onClickHandler}
                width="384px"
                isDisable={
                  dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                    "APPROVED"
                    ? true
                    : false
                }
              ></PrimaryButton>
            )}
          </Stack>
        </div>
      </div>
    </>
  );
};

export default RequestKYCDocuments;
