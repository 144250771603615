import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paths from "../components/constants/Path";
import { useHistory } from "react-router-dom";

const RedirectToLoginPage = () => {
  //? useSelector

  let { redirectToLogin } = useSelector((state) => state.app);
  let history = useHistory();

  useEffect(() => {
    if (redirectToLogin) {
      return history.push("/");
    }
  }, [redirectToLogin]);

  return <div></div>;
};

export default RedirectToLoginPage;
