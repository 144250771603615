import React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import ReportsTable from "../../../components/Table/Phase2/ReportsTable";
import {
  getReportList,
  getVoucherDetails,
} from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import { useSelector } from "react-redux";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import VoucherTable from "./../../../components/Table/Phase2/VoucherTable";
import { Colors } from "../../../theme/theme";
import { ReportDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../../assets/images/Group 42722.svg";
import { useReactToPrint } from "react-to-print";
import TextWrapperComponent from "./../../../components/FormComponents/TextWrapperComponent";
import { SuffixTextField } from "./../../../components/FormComponents/SuffixPrefixTextFieldV2";
import VoucherCard from "./VoucherCard";
import CommonFields from "./CommonFieldsV2";
import MonthEndUploadTable from "./../../../components/Table/Phase2/MonthEndUploadTable";
import Styles from "./VoucherCard.module.css";
import Styles1 from "./F2.module.css";
import Logo from "../../../assets/images/Logo.png";
import { getMonthUplods } from "./../../../redux/Phase2/UploadReportSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { styled } from "@mui/material/styles";
import { MonthEndUploadListBox, MonthEndUploadListTableBox } from "./F2CSS";
import { showErrorAlertAction } from "../../../redux/app/appSlice";

const VoucherDetails = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionNo, setTransactionNo] = useState("");
  const [sortedList, setSortedList] = useState([]);
  const [state, setState] = useState({
    customerName: "",
  });
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");

  const dealState = useSelector((state) => state.deal);

  const filterHandler = (name) => {
    setFilterName(name);
  };

  //? useDispatch
  let dispatch = useDispatch();

  // useParams
  let params = useParams();

  // useEffect with getMonthUploaddata api call

  //? useSelector for getting the data from the store
  let {
    loading,
    ReportsList,
    monthEndData,
    totalPages,
    error,
    success,
    voucher,
    voucherData,
  } = useSelector((state) => state.uploadReport);

  //Using debounce Hook for transactionNo
  const debouncedSearch = useDebounce(transactionNo, 1000);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Voucher Details.pdf`,
  });

  // const handlePrint = useReactToPrint({
  //     content: () => componentRef.current,
  //     documentTitle: `Voucher Details.pdf`,
  //     copyStyles: true,
  //     print: async (printIframe) => {
  //         const document = printIframe.contentDocument;
  //         if (document) {
  //             const html = document.getElementsByTagName('html')[0];
  //             console.log(html);
  //             await html2pdf().from(html).save();
  //         }
  //     }
  // });

  let history = useHistory();

  //Breadcrumb Navigation
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Reports",
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  //user data from local storage
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const keyDownHandler = (e) => {
    console.log(e);
  };

  useEffect(() => {
    dispatch(
      getMonthUplods({
        dealId: params.dealId,
        financialYear: params.year,
        page: currentPage,
        limit: limit,
      })
    );
  }, [currentPage]);

  console.log({ voucher });

  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage == dealState.totalPages) {
      // alert("Max Number Reached!");
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const filterList = [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Closed Deals",
    },

    {
      id: "2",
      name: "Open Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));
  return (
    <Layout ref={componentRef} componentName={null} breadCrumbs={breadCrumbs}>
      <Box sx={MonthEndUploadListBox}>
        <div>
          <ReportDetails
            icon={icon}
            iconColor="#DBE3F8"
            formName={monthEndData?.data[0].dealName || ""}
          />
        </div>

        <div className={Styles1.MonthEndUploadListDiv}>
          <img
            className={Styles1.MonthEndUploadListImage}
            src={Image}
            alt="image"
          />
        </div>
      </Box>
      <Box
        sx={MonthEndUploadListTableBox}
        ref={componentRef}
        onKeyDown={keyDownHandler}
      >
        <>
          <div>
            <MonthEndUploadTable
              rows={[
                "Months",
                "No of Upload Per Months",
                "Month Closure Date",
                "Deal Status",
                "Action",
              ]}
              totalRecords={monthEndData?.total}
              page={currentPage}
              nextPageHandler={nextPageHandler}
              prevPageHandler={prevPageHandler}
              data={monthEndData?.data}
              totalCreditAmount={voucherData?.totalCredit}
              totalDebitAmount={voucherData?.totalDebit}
            />
          </div>
        </>
      </Box>
    </Layout>
  );
};

export default VoucherDetails;
