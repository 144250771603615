import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Stack, Typography, TextField, Button, Badge } from "@mui/material";
import Logo from "../../assets/images/Logo.png";
import OpsCentral from "../../assets/images/OpsCentral.png";
import { Colors } from "../../theme/theme";

export const LoginContainer = styled(Box)(({ theme }) => ({
  flex: 3.5,
  backgroundColor: `${Colors.white}`,
  boxShadow: 10,
  borderRadius: 1,
  height: "568px",
}));

//Information Component
export const InformationComponent = () => {
  return (
    <>
      <div
        style={{
          padding: "60px",
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="start">
          <img src={Logo} />
          <Typography
            alignContent="center"
            paddingLeft="10px"
            fontWeight="700"
            fontSize="20px"
          >
            Swayam
          </Typography>
        </Stack>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImageContainer src={OpsCentral}></ImageContainer>
      </div>
      <div
        style={{
          paddingTop: "10px",
        }}
      >
        <Typography
          fontSize="26px"
          textAlign="center"
          fontWeight="700"
          color={Colors.black}
        >
          One stop for all Securitization / Direct Assignment transactions
        </Typography>

        {/* <Typography
          fontSize="20px"
          textAlign="center"
          fontWeight="400"
          color={Colors.light_grey}
        >
          Direct Assignment transactions
        </Typography> */}
      </div>
    </>
  );
};
export const InformationContainer = styled(Box)(({ theme }) => ({
  flex: 7,
  background: `linear-gradient(0.66deg, #98BFD5 0.31%, rgba(92, 128, 255, 0) 106.94%)`,
  height: "100vh",
}));

export const ImageContainer = styled("img")(({ theme, src }) => ({
  src: `url(${src})`,
}));
