import { Grid, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";

const LoanDetails = () => {
  const AssetQualityFilters = [
    {
      name: "DPD",
      value: "4852052 Days",
    },
    {
      name: "Overdues",
      value: "No",
    },
    {
      name: "Branch Level 90 Plus",
      value: "80",
    },
    {
      name: "Credit Scrub Score",
      value: "4.5",
    },
  ];

  const GeoFilters = [
    {
      name: "City Exculsions",
      value: "Mumbai, Ranchi",
    },
    {
      name: "State Exclusions",
      value: "Maharashtra, Bihar",
    },
  ];

  const LoanTenureFilters = [
    {
      name: "Seasoning/ MHP",
      value: "6225",
    },
    {
      name: "Balance Tenure (in months)",
      value: "15",
    },
    {
      name: "Total Tenure (in months)",
      value: "45",
    },
    {
      name: "Max Lifetime DPD (in months",
      value: "50",
    },
    {
      name: "Max lifetime dpd -12 months",
      value: "10",
    },
    {
      name: "Max lifetime dpd -24 months",
      value: "20",
    },
    {
      name: "Restructed",
      value: "No",
    },
  ];

  const LoanPropertyFilters = [
    {
      name: "LTV",
      value: "150",
    },
    {
      name: "FOIR",
      value: "12",
    },
    {
      name: "Payment Mode",
      value: "NACH, Cash",
    },
    {
      name: "Type of Property",
      value: "Industrial, Commericial",
    },
    {
      name: "Customer Segements",
      value: "Salaried",
    },
    {
      name: "Customer Constitution",
      value: "Individual",
    },
    {
      name: "Max. Principal Outstanding",
      value: "784550",
    },
    {
      name: "PDD Completion",
      value: "Yes",
    },
  ];

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  const history = useHistory();
  const handleDealDuplicationOnClick = () => {
    return history.push(Paths.DEALDUPLICATION);
  };

  return (
    <>
      <WrapperComponent
        showButton={true}
        title="Asset Quality Filters"
        onClickButton={handleDealDuplicationOnClick}
      >
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {AssetQualityFilters.map((data) => {
                return (
                  <Grid item xs={3}>
                    <DataComp title={data.name} desc={data.value} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>
      <WrapperComponent title="Geographical Filters">
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {GeoFilters.map((data) => {
                return (
                  <Grid item xs={3}>
                    <DataComp title={data.name} desc={data.value} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>
      <WrapperComponent title="Loan Tenure Filters">
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {LoanTenureFilters.map((data) => {
                return (
                  <Grid item xs={3}>
                    <DataComp title={data.name} desc={data.value} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>
      <WrapperComponent title="Loan Property Filters">
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {LoanPropertyFilters.map((data) => {
                return (
                  <Grid item xs={3}>
                    <DataComp title={data.name} desc={data.value} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>
      <div style={{ padding: "10px" }}></div>
    </>
  );
};

export default LoanDetails;
