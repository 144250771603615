import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance, { instanceV2 } from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

export const uploadLoanTemplate = createAsyncThunk(
  "uploadLoanTemplate",
  async ({ formData, fileName }, rejectWithValue) => {
    console.log({ formData, fileName });
    try {
      const uploadData = await instanceV2.post(
        "/utility-service/upload-file",
        {
          file: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      console.log(uploadData);
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const uploadDealDocuments = createAsyncThunk(
  "uploadDealDocuments",
  async (values, rejectWithValue) => {
    console.log(values);
    // console.log({ formData, fileName });
    try {
      const uploadData = await instance.post(
        "/portfolio-service/uploadDealDocuments",
        {
          ...values,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      console.log(uploadData);
      return {
        ...uploadData,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getDealDocuments = createAsyncThunk(
  "getDealDocuments",
  async (values, rejectWithValue) => {
    try {
      const uploadData = await instance.get(
        `/portfolio-service/getDealDocuments?dealId=${values?.dealId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      console.log(uploadData);
      return {
        ...uploadData,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllDeals = createAsyncThunk(
  "getAllDeals",
  async (
    {
      limit,
      currentPage,
      debouncedSearch = undefined,
      filterName = "ALL",
      entityFilter,
      sortName,
      sortOrder,
      isCreditFilterAdded = false,
      loanFilterExists = false,
      isLoanAdded = false,
      source,
      isSecondaryCheckDone = false,
      isCreditBureauCheckDone = false,
      isApproverCommiteeAdded = false,
      isKycUploded = false,
      isPoolApproved = "",
      IsEntityExist = false,
      isKycVerificationDone = false,
      isTermsheetCompleted = false,
      dealType = "",
      originatorId = "",
    },

    { rejectWithValue }
  ) => {
    // console.log({ limit, currentPage, debouncedSearch, filterName });
    console.log({ sortName, sortOrder, filterName });
    let response;

    try {
      if (originatorId !== "") {
        response = await instance.get(
          `/portfolio-service/deal?originator=${originatorId}&page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}`
        );
        return response?.data?.data;
      }
      if (IsEntityExist && isTermsheetCompleted) {
        response = await instance.get(
          `/portfolio-service/deal?IsEntityExist=${IsEntityExist}&isTermsheetCompleted=${isTermsheetCompleted}&page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );
        return response?.data?.data;
      }
      if (isPoolApproved) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}&isPoolApproved=${isPoolApproved}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );
        return response?.data?.data;
      }

      if (sortName && sortOrder) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${sortName && sortOrder != "" ? `&${sortName}=` + sortOrder : ""}`
        );

        console.log({ originatorsListData: response?.data?.data });

        return response?.data?.data;
      }

      if (isKycUploded && isCreditBureauCheckDone && isSecondaryCheckDone) {
        // response = await instance.get(
        //   `/portfolio-service/deal?page=${currentPage}&limit=${limit}&isKycUploded=${isKycUploded}&isCreditBureauCheckDone=${isCreditBureauCheckDone}&isSecondaryCheckDone=${isSecondaryCheckDone}`
        // );

        response = await instance.get(
          `/portfolio-service/deal?isKycUploded=${isKycUploded}&isCreditBureauCheckDone=${isCreditBureauCheckDone}&isSecondaryCheckDone=${isSecondaryCheckDone}&page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }

      if (
        isCreditBureauCheckDone &&
        isSecondaryCheckDone &&
        isKycVerificationDone
      ) {
        response = await instance.get(
          `/portfolio-service/deal?isKycVerificationDone=${isKycVerificationDone}&isCreditBureauCheckDone=${isCreditBureauCheckDone}&isSecondaryCheckDone=${isSecondaryCheckDone}&page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }

      if (isCreditBureauCheckDone && isSecondaryCheckDone) {
        response = await instance.get(
          `/portfolio-service/deal?isCreditBureauCheckDone=${isCreditBureauCheckDone}&isSecondaryCheckDone=${isSecondaryCheckDone}&page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }

      if (isCreditFilterAdded) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&uploadStatus=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&loanFilterExists=${loanFilterExists}&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }

      if (isLoanAdded) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&loanFilterExists=${loanFilterExists}&loanUploadStatus=DONE${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }
      if (isSecondaryCheckDone) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&loanFilterExists=${true}&isSecondaryCheckDone=${isSecondaryCheckDone}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }
      if (isCreditBureauCheckDone) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}&isCreditBureauCheckDone=${isCreditBureauCheckDone}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }
      if (isApproverCommiteeAdded) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}&isApproverCommiteeAdded=${isApproverCommiteeAdded}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
        );

        return response?.data?.data;
      }
      if (isPoolApproved) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}&isPoolApproved =${isPoolApproved}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${entityFilter == undefined ? "" : "&entity=" + entityFilter}${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }&source=${source}`
        );

        return response?.data?.data;
      }
      if (dealType) {
        response = await instance.get(
          `/portfolio-service/deal?page=${currentPage}&limit=${limit}&dealType =${dealType}${
            filterName.toUpperCase() == "ALL"
              ? ""
              : "&status=" + filterName.toUpperCase().replace(" ", "_")
          }${entityFilter == undefined ? "" : "&entity=" + entityFilter}${
            debouncedSearch && debouncedSearch != ""
              ? "&name=" + debouncedSearch
              : ""
          }`
        );

        return response?.data?.data;
      }
      if (debouncedSearch !== undefined && debouncedSearch !== "") {
        response = await instance.get(
          `/portfolio-service/deal?name=${debouncedSearch}&page=${currentPage}&limit=${limit}`
        );
        return response?.data?.data;
      }
     
      response = await instance.get(
        `/portfolio-service/deal?page=${currentPage}&limit=${limit}${
          filterName.toUpperCase() == "ALL"
            ? ""
            : "&status=" + filterName.toUpperCase().replace(" ", "_")
        }${
          debouncedSearch && debouncedSearch != ""
            ? "&name=" + debouncedSearch
            : ""
        }&source=${source}${entityFilter == undefined ? "" : "&entity=" + entityFilter}`
      );

      console.log({ originatorsListData: response?.data?.data });

      return response?.data?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);
export const getOriginatorSpocs = createAsyncThunk(
  "getOriginatorSpocs",
  async (originator, rejectWithValue) => {
    try {
      const response = await instance.get(
        `/portfolio-service/originator/getSpocs?id=${originator}`
      );
      console.log({ response });
      return response.data.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const setDealStatus = createAsyncThunk(
  "set-dealStatus",
  async (value, rejectWithValue) => {
    console.log(value);
    let data = JSON.stringify({ id: value.id, status: value.status });
    try {
      const result = await instance.put(
        "/portfolio-service/deal/updateStatus",
        data,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      console.log(result);
      return result;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getLoanType = createAsyncThunk(
  "get-loanTypes",
  async (values, rejectWithValue) => {
    try {
      const uploadData = await instance.get(
        "/master-service/loantype",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      console.log(uploadData);
      return uploadData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getOriginators = createAsyncThunk(
  "get-originators",
  async (values, rejectWithValue) => {
    try {
      const data = await instance.get(
        "/portfolio-service/originator",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getOriginatorswithStatus = createAsyncThunk(
  "get-approved-originators",
  async (values, rejectWithValue) => {
    console.log(values);
    try {
      const data = await instance.get(
        `/portfolio-service/originator?status=${values.status}&all=true`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getApprovers = createAsyncThunk(
  "get-Approvers",
  async (values, rejectWithValue) => {
    try {
      const data = await instance.get(
        "/portfolio-service/originator/getApprovers",

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getOriginatorNames = createAsyncThunk(
  "getOriginatorNames",
  async (values, rejectWithValue) => {
    try {
      console.log(values);

      if (values !== undefined) {
        const data = await instance.get(
          `/portfolio-service/originator/getLinkUserByProfileId?profileId=${values}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return data;
      } else {
        const data = await instance.get(
          "/portfolio-service/originator/getOriginatorList",

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return data;
      }
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const getSingleDealDetails = createAsyncThunk(
  "getSingleDealDetails",
  async (values, rejectWithValue) => {
    try {
      const data = await instance.get(
        `/portfolio-service/originator/getSingleDealById?id=${values}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const createDeal = createAsyncThunk(
  "create-deal",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      name: values.name,
      originator: values.originator,
      transactionType: values.transactionType,
      loanTypeId: values.loanTypeId,
      vintageOperation: values.vintageOperation,
      rating: values.rating,
      aum: values.aum,
      netWorth: values.netWorth,
      pat: values.pat,
      crarPercent: values.crarPercent,
      gnpaPercent: values.gnpaPercent,
      nnpaPercent: values.nnpaPercent,
      requestedDealAmount: values.requestedDealAmount,
      contacts: values.contacts,
      originatorId: values.originatorId,
      entity: values.entity,
      // approvers: values.approvers,
      // originatorId: "",
      // originatorId: ["634e7802344861061374a630"],
    });
    try {
      const dealData = await instance.post(
        "/portfolio-service/deal",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addSpoc = createAsyncThunk(
  "",
  async ({ data, id }, rejectWithValue) => {
    try {
      const result = await instance.put(
        `/portfolio-service/originator/addSpocs?id=${id}`,
        {
          spocs: [
            {
              name: data.name,
              email: data.email,
              phoneNumber: data.phoneNumber,
            },
          ],
        }
      );
      console.log({ result });
      return result;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getloanUploadTemplate = createAsyncThunk(
  "getloanUploadTemplate",
  async (loanTypeId, rejectWithValue) => {
    try {
      const response = await instance.get(
        `/master-service/loanUploadTemplate?loanTypeId=${loanTypeId}`
      );
      console.log({ response });
      return response.data.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const uploadMultipleDocuments = createAsyncThunk(
  "uploadMultipleDocuments",
  async ({ formData, fileName }, rejectWithValue) => {
    // console.log({ formData, fileName });
    try {
      const uploadData = await instanceV2.post(
        "/utility-service/upload-file",
        {
          file: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  checkedPages: [
    { pageName: "basicInformation", isChecked: false },
    { pageName: "dealDetails", isChecked: false },
    { pageName: "loanTemplate", isChecked: false },
    { pageName: "assignSPOCs", isChecked: false },
  ],
  loading: null,
  error: null,
  success: null,
  totalPages: 0,
  name: "",
  originator: "",
  transactionType: "",
  loanTypeId: "",
  vintageOperation: "",
  rating: "",
  aum: "",
  netWorth: "",
  pat: "",
  crarPercent: "",
  gnpaPercent: "",
  nnpaPercent: "",
  requestedDealAmount: "",
  contacts: [],
  approvers: [],
  originatorId: [],
  originatorIdData: null,
  allLoanTypes: [],
  allOriginators: [],
  allOriginatorNames: [],
  allDeals: [],
  allSpocsList: [],
  dealLoader: false,
  loanTypeData: {},
  sortValue: true,
  sortFieldName: null,
  filterdata: null,
  loanUploadTemplate: {},
  originatorData: {},
  isTempLoading: false,
  kycDriveLinkStatus: null,
  allDealDocuments: null,
  entity: "",
};

const dealSlice = createSlice({
  name: "dealSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    basicInformation: (state, { payload }) => {
      console.log({ payload });
      state.name = payload.name;
      state.originator = payload.originator;
      state.originatorId = payload.originatorId[0];
      state.entity = payload.entity
    },
    clearAllDocuments: (state) => {
      state.allDealDocuments = null;
    },
    dealDetails: (state, { payload }) => {
      state.loanTypeId = payload.loanTypeId;
      state.aum = payload.aum;
      state.transactionType = payload.transactionType;
      state.crarPercent = payload.crarPercent;
      state.netWorth = payload.netWorth;
      state.vintageOperation = payload.vintageOperation;
      state.rating = payload.rating;
      state.gnpaPercent = payload.gnpaPercent;
      state.nnpaPercent = payload.nnpaPercent;
      state.pat = payload.pat;
      state.requestedDealAmount = payload.requestedDealAmount;
    },
    setSortValue: (state, { payload }) => {
      state.sortValue = payload;
    },
    setSortName: (state, { payload }) => {
      state.sortFieldName = payload;
    },
    loanTemplate: (state, { payload }) => {
      state.file = payload.file;
    },
    assignSpocs: (state, { payload }) => {
      state.contacts = payload.contacts;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },

    clearErrorAndSuccessMessage: (state, { payload }) => {
      state.error = "";
      state.success = "";
      state.message = "";
    },
    clearDealState: (state) => {
      state.checkedPages = [
        { pageName: "basicInformation", isChecked: false },
        { pageName: "dealDetails", isChecked: false },
        { pageName: "loanTemplate", isChecked: false },
        { pageName: "assignSPOCs", isChecked: false },
      ];
      state.loading = null;
      state.error = null;
      state.success = null;
      state.totalPages = 0;
      state.name = "";
      state.originator = "";
      state.transactionType = "";
      state.loanTypeId = "";
      state.vintageOperation = "";
      state.rating = "";
      state.aum = "";
      state.netWorth = "";
      state.pat = "";
      state.crarPercent = "";
      state.gnpaPercent = "";
      state.nnpaPercent = "";
      state.requestedDealAmount = "";
      state.contacts = "";
      state.approvers = "";
      state.originatorId = "";
      state.originatorIdData = null;
      state.file = "";
      state.loanTemplate = "";
      state.status = "";
      state.originatorData = "";
      state.loanTypeData = "";
      state.entity = "";
    },
    clearFilterData: (state, { payload }) => {
      state.filterdata = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadLoanTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadLoanTemplate.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state[payload.fileName] = payload.data;
    });
    builder.addCase(uploadLoanTemplate.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });

    builder.addCase(getLoanType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoanType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allLoanTypes = payload.data.data.data;
    });
    builder.addCase(getLoanType.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });

    builder.addCase(getOriginators.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOriginators.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allOriginators = payload.data.data.data;
    });
    builder.addCase(getOriginators.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong ";
    });

    builder.addCase(getloanUploadTemplate.pending, (state) => {
      state.isTempLoading = true;
    });
    builder.addCase(getloanUploadTemplate.fulfilled, (state, { payload }) => {
      state.isTempLoading = false;
      state.loanUploadTemplate = payload;
    });
    builder.addCase(getloanUploadTemplate.rejected, (state, { payload }) => {
      console.log({ payload });
      state.isTempLoading = false;
      // state.error = "Something went wrong ";
    });

    builder.addCase(getOriginatorswithStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getOriginatorswithStatus.fulfilled,
      (state, { payload }) => {
        console.log({ payload });
        state.loading = false;
        state.allOriginators = payload.data.data;
      }
    );
    builder.addCase(getOriginatorswithStatus.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });
    builder.addCase(getApprovers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getApprovers.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.allApprovers = payload.data.data;
    });
    builder.addCase(getApprovers.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });

    builder.addCase(getOriginatorNames.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOriginatorNames.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.allOriginatorNames = payload.data.data;
    });
    builder.addCase(getOriginatorNames.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });

    builder.addCase(getAllDeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDeals.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;

      state.allDeals = payload?.data;
      state.totalPages = payload?.totalPages;
      // state.allDeals = payload?.data?.data?.data;
    });
    builder.addCase(getAllDeals.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.error = "Something went wrong";
    });

    builder.addCase(getOriginatorSpocs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOriginatorSpocs.fulfilled, (state, { payload }) => {
      let allSpocs = [...payload].map((item) => {
        return { ...item, checked: false };
      });
      state.loading = false;
      state.allSpocsList = allSpocs;
    });
    builder.addCase(getOriginatorSpocs.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.allSpocsList = [];
      // state.error = "Something went wrong";
    });
    builder.addCase(getSingleDealDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleDealDetails.fulfilled, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.data);
      state.filterdata = payload.data.data.data;
      state.loading = false;
      state.status = payload.data.data.data.status;
      state.name = payload.data.data.data.name;
      state.entity = payload.data.data.data.entity
      state.originator = payload.data.data.data.originator;
      state.transactionType = payload.data.data.data.transactionType;
      state.loanTypeId = payload.data.data.data.loanTypeId;
      state.vintageOperation = payload.data.data.data.vintageOperation;
      state.rating = payload.data.data.data.rating;
      state.aum = payload.data.data.data.aum;
      state.netWorth = payload.data.data.data.netWorth;
      state.pat = payload.data.data.data.pat;
      state.crarPercent = payload.data.data.data.crarPercent;
      state.gnpaPercent = payload.data.data.data.gnpaPercent;
      state.nnpaPercent = payload.data.data.data.nnpaPercent;
      state.requestedDealAmount = payload.data.data.data.requestedDealAmount;
      state.contacts = payload.data.data.data.contactsId;
      state.approvers = payload.data.data.data.approversId;
      state.originatorIdData = payload.data.data.data.originatorIdData;
      state.loanTypeData = payload.data.data.data.loanTypeData;
      state.originatorData = payload.data.data.data.originatorData;
      state.kycDriveLinkStatus = payload.data.data.data.kycDriveLinkStatus;
    });
    builder.addCase(getSingleDealDetails.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.allSpocsList = [];
      // state.error = "Something went wrong";
    });
    builder.addCase(createDeal.rejected, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.response.data.message);
      state.loading = false;
      state.allSpocsList = [];
      state.error = payload.response.data.message;
    });
    builder.addCase(uploadMultipleDocuments.pending, (state, { payload }) => {
      console.log({ payload });
    });
    builder.addCase(uploadMultipleDocuments.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state[payload.fileName] = payload.data;
    });
    builder.addCase(uploadMultipleDocuments.rejected, (state, { payload }) => {
      console.log({ payload });
    });
    builder.addCase(getDealDocuments.pending, (state, { payload }) => {
      console.log({ payload });
    });
    builder.addCase(getDealDocuments.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.allDealDocuments = payload?.data?.data || {};
    });
    builder.addCase(getDealDocuments.rejected, (state, { payload }) => {
      console.log({ payload });
    });
  },
});

export const {
  pageStatusAction,
  basicInformation,
  dealDetails,
  assignSpocs,
  deleteDoc,
  setSortValue,
  clearAllDocuments,
  loanTemplate,
  clearErrorAndSuccessMessage,
  clearDealState,
  setSortName,
  clearFilterData,
} = dealSlice.actions;

export const dealReducer = dealSlice.reducer;
