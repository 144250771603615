import React, { useState, useEffect } from "react";
import TabFooter from "../../TabFooter/TabFooter";
import { TextField, Grid, FormControl } from "@mui/material";
import {
  pageStatusAction,
  dealDetails,
  getLoanType,
} from "../../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import TextWrapperComponent from "../../../FormComponents/TextWrapperComponent";
import { SuffixTextField } from "../../../FormComponents/SuffixPrefixTextFieldV2";
import BasicSelect from "../../TabSelectOptions/SelectTabOptions";
import DealDetailsForm from "./DealDetailsForm/DealDetailsForm";

const DealDeatils = ({ nextTabHandler }) => {
  let dealState = useSelector((state) => state.deal);

  const [Values, setValues] = useState({
    transactionType: dealState.transactionType || "",
    loanTypeId: dealState.loanTypeId || "",
    vintageOperation: dealState.vintageOperation || "",
    aum: dealState.aum || "",
    netWorth: dealState.netWorth || "",
    pat: dealState.pat || "",
    gnpaPercent: dealState.gnpaPercent || "",
    nnpaPercent: dealState.nnpaPercent || "",
    requestedDealAmount: dealState?.requestedDealAmount || "",
    rating: dealState.rating || "",
    crarPercent: dealState.crarPercent || "",
  });
  const [transactionType, setTransactionType] = useState(
    dealState.transactionType || ""
  );
  const [loanTypeId, setLoanTypeId] = useState(dealState.loanTypeId || "");
  let dispatch = useDispatch();
  const [status, setStatus] = useState(true);


  useEffect(() => {
    dispatch(getLoanType());
  }, []);

  useEffect(() => {
    if (
      Values.transactionType != "" &&
      Values.loanTypeId != "" &&
      Values.aum != "" &&
      Values.netWorth != "" &&
      Values.pat != "" &&
      Values.gnpaPercent != "" &&
      Values.nnpaPercent != "" &&
      Values.requestedDealAmount != "" &&
      Values.rating != "" &&
      Values.crarPercent != "" &&
      Values.vintageOperation != ""
    ) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [Values]);

  const onChangeHandler = (name) => (e) => {
    
    console.log({ Values });
    console.log(e.target.value, name);
    if(name === "requestedDealAmount") {
      
      const regex = /^[0-9a-zA-Z,]+$/;
      if (e.target.value.match(regex) || e.target.value === "") {
        console.log("isrequestedDealAmount")
        setValues({ ...Values, [name]: e.target.value });
      }
    }
    console.log({name});
    setValues({ ...Values, [name]: e.target.value });
  };

  useEffect(() => {
    setValues({
      ...Values,
      transactionType: transactionType,
      loanTypeId: loanTypeId,
    });
  }, [loanTypeId, transactionType]);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "dealDetails" }));
    dispatch(dealDetails({ ...Values }));
    nextTabHandler();
  };
  const TransactionList = [
    { id: "DA", title: "DA" },
    { id: "PTC", title: "PTC" },
  ];

  return (
    <>
      <div>
        <div style={{ padding: "20px" }}>
          {dealState?.allLoanTypes && dealState?.allLoanTypes.length != 0 && (
            <DealDetailsForm
              TransactionList={TransactionList}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              Values={Values}
              dealState={dealState}
              loanTypeId={loanTypeId}
              setLoanTypeId={setLoanTypeId}
              onChangeHandler={onChangeHandler}
            />
          )}
        </div>
      </div>
      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
    </>
  );
};

export default DealDeatils;
