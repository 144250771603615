import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Layout from '../../components/Layout/Layout.jsx';
import { styled } from "@mui/material/styles";
import { useHistory } from 'react-router-dom';
import Paths from '../../components/constants/Path.jsx';
import SecoundaryButton from '../../components/Buttons/SecoundaryButton.js';
import SecondaryCheckDealistTable from '../../components/Table/SecondaryCheckDealistTable.jsx';
import searchIcon from "../../assets/icons/searchIcon.svg"
import useDebounce from "../../Hooks/useDebounce.js";
import { getAllDeals } from "../../redux/Deals/dealSlice.js";
import { showErrorAlertAction } from "../../redux/app/appSlice.js";
import { FilterConstants, statusConstants } from '../../components/constants/FiltersConstant.js';
import Loader from './../../utils/Loader';

const PoolShortListingDealist = () => {
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);
  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if(name == "PEL" || name == "PCHFL"){
      setEntityFilter(name);
      setFilterName("")
    }else{
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    dispatch(getAllDeals({ limit, currentPage, debouncedSearch, isLoanAdded: true, filterName, entityFilter, loanFilterExists: true }));
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(
      getAllDeals({ limit, currentPage: 1, debouncedSearch, isLoanAdded: true, filterName, entityFilter, loanFilterExists: true })
    );
  }, [filterName, entityFilter]);

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deal List",
    },
  ];

  const filterList = [
    {
      id: "0",
      name: FilterConstants.ALL,
    },
    {
      id: "1",
      name: FilterConstants.PEL,
    },
    {
      id: "2",
      name: FilterConstants.PCHFL,
    },
  ];
  let history = useHistory();



  //view handler
  const clickHandlerToViewDetails = (deal, isSecondaryCheckfilter) => {
    if (isSecondaryCheckfilter === statusConstants.DONE || isSecondaryCheckfilter === statusConstants.FAILED || isSecondaryCheckfilter === statusConstants.PARTIALLY_DONE) {
      return history.push({
        pathname: `/pool-shortlisting/conduct/poolshortlistingdealist/${deal._id || deal.id || deal}/details`,
        state: deal,
      });
    }
  };

  const onClickHandlerToCreateDeal = () => {
    return history.push("/create");
  };

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };


  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
            {/* <SecoundaryButton
              onClickHandler={onClickHandlerToCreateDeal}
              text="Create New Deal"
            ></SecoundaryButton> */}
          </Stack>

          <Divider />
          <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          >
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item.name || entityFilter == item?.name ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item.name || entityFilter == item?.name ? "white" : "black",
                        backgroundColor:
                          filterName === item.name || entityFilter == item?.name? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item.name)}
                    >
                      {item.name}
                    </StyledButton>
                  </>
                ))}
            </div>

            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>
          {dealState["loading"] ? (
            <Loader/>
          ) : (
            <Box style={{ height: "calc( 100% - 64px)" }}>
              <SecondaryCheckDealistTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Loan/ Pool Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
                statusList={filterList}
              />
            </Box>
          )}
        </>
      </Box>

    </Layout>
  )
}

export default PoolShortListingDealist;
