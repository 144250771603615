import styles from "./AddNewSpoc.module.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  Stack,
  Typography,
  Grid,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { Colors } from "../../../../../theme/theme";
import TextWrapperComponent from "../../../../FormComponents/TextWrapperComponent";
import { addSpoc } from "../../../../../redux/Deals/dealSlice";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../../../redux/app/appSlice";
import { useHistory } from "react-router-dom";
import { getOriginatorSpocs } from "./../../../../../redux/Deals/dealSlice";
import { stackStyle, titleStyle, descStyle, loadingBtnStyle } from "./AddNewSpocCSS";

const AddSPOCDetails = ({ open, clickHandler, children, setOpen }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  const [isDisable, setIsDiable] = useState(true);

  const handleValidNumber = (number) => {
    if (number[0] < 6) {
      dispatch(showErrorAlertAction("Please enter valid Number"));
      number = "";
      return;
    }
    number = Math.max(0, parseInt(number)).toString().slice(0, 10);
    return number;
  };

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const dealState = useSelector((state) => state.deal);

  var history = useHistory();

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setData({ ...data, [name]: value });

    console.log({ value })

    if (name == "email") {
      let emailArray = value.split("");

      if (
        value.includes("@gmail.") ||
        value.includes("@yahoo.") ||
        value.includes("@google.") ||
        value.includes("@hotmail.") ||
        value.includes("@outlook.") ||
        value.includes(" ") ||
        value.includes("&") ||
        value.includes("$") ||
        value.includes("!") ||
        value.includes("#") ||
        value.includes("%") ||
        value.includes("^") ||
        value.includes("*") ||
        // value.test(/^![a-zA-Z0-9@]$/) ||
        emailArray.filter((char) => char == "@").length > 1
      ) {
        console.log({ lengthMatch: value.match(/[@]/g).length });
        dispatch(
          showErrorAlertAction("Email Field should contain company domain")
        );

        return;
      }
    }
  };

  const addSpocHandler = () => {
    let isValidEmail =
      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(
        data.email
      );
    if (!isValidEmail) {
      dispatch(
        showErrorAlertAction("Email Field should contain company domain")
      );
      return;
    }
    console.log(isValidEmail);
    var id = dealState["originator"];
    setLoading(true);
    dispatch(addSpoc({ data, id })).then((res) => {
      console.log(res);
      if (res.payload.status === 200) {
        setLoading(false);
        dispatch(getOriginatorSpocs(id));
        dispatch(showAlertAction(res.payload.data.message));
        clickHandler();
        setData({
          name: "",
          email: "",
          phoneNumber: "",
        });
      }
    });
  };

  useEffect(() => {
    if (
      data.name !== "" &&
      data.email !== "" &&
      data.phoneNumber !== "" &&
      data?.phoneNumber?.length > 9
    ) {
      setIsDiable(false);
    } else {
      setIsDiable(true);
    }
  }, [data.name, data.email, data.phoneNumber]);

  console.log({ open });
  return (
    <div
      className={
        open ? styles.sideDrawerWrapper : styles.sideDrawerWrapperClose
      }
    >
      <div className={styles.topSideDrawer}></div>
      <div className={open ? styles.sideDrawerOpen : styles.sideDrawerClose}>
        {open ? (
          <>
            <div>
              <div
                onClick={() => {
                  setLoading(false);
                  clickHandler();
                }}
              >
                <Stack sx={stackStyle}>
                  <Typography sx={titleStyle}>
                    Add New SPOC
                  </Typography>
                  <div className={styles.closeIconWrapper} >
                    <CloseIcon />
                  </div>
                </Stack>
                <Divider />
              </div>
              <div className={styles.TypographyWrapper}>
                <Typography sx={descStyle} >
                  Add new SPOC for this Deal, This SPOC will also be added to
                  the Originator Profie.
                </Typography>
                <br></br>
                <Grid item xs={6} paddingBottom="20px">
                  <TextWrapperComponent text="Name">
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="Enter Contact’s Name"
                      onChange={handleInputChange("name")}
                    />
                  </TextWrapperComponent>
                </Grid>

                <Grid item xs={6} paddingBottom="20px">
                  <TextWrapperComponent text="Email">
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="Enter Contact’s Email Address"
                      onChange={handleInputChange("email")}
                      onKeyPress={(e) => {
                        if (!/[a-z0-9@.]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </TextWrapperComponent>
                </Grid>

                <Grid item xs={6} paddingBottom="20px">
                  <TextWrapperComponent text=" Mobile Number">
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      type="number"
                      onInput={(e) => {
                        e.target.value = handleValidNumber(e.target.value);
                      }}
                      min={0}
                      placeholder="Enter Contact’s Mobile No."
                      onChange={handleInputChange("phoneNumber")}
                    />
                  </TextWrapperComponent>
                </Grid>
              </div>

              <div className={styles.addSpocBtnWrapper}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isDisable}
                  style={{
                    background: isDisable ? "#FAC3B3" : Colors.piramal_orange,
                    color: isDisable && "#fff",
                  }}
                  sx={loadingBtnStyle}
                  type="submit"
                  onClick={loading ? "" : addSpocHandler}
                >
                  {loading ? "Loading..." : "Add SPOC"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddSPOCDetails;
