import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  dashboardData: null,
  phase1DashboardData: null,
  loading: null,
  error: null,
  success: null,
};

export const fetchPhase1DashboardData = createAsyncThunk(
  "fetchPhase1DashboardData",
  async (rejectWithValue) => {
    try {
      const userData = await instance.get(
        "/accounting-service/getDashboardData"
      );
      return userData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const fetchDashBoardData = createAsyncThunk(
  "fetchDashBoardData",
  async (rejectWithValue) => {
    try {
      const usersData = await instance.get(
        "/accounting-service/getDashboardData"
      );
      return usersData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const DashBoardSlice = createSlice({
  name: "DashBoardSlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashBoardData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDashBoardData.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.dashboardData = payload.data.data;
    });
    builder.addCase(fetchDashBoardData.rejected, (state, { payload }) => {
      state.loading = false;
      state.dealList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
    builder.addCase(fetchPhase1DashboardData.pending, (state) => {
      state.loading = true;
      
    });
    builder.addCase(
      fetchPhase1DashboardData.fulfilled,
      (state, { payload }) => {
        console.log({ payload });
        state.loading = false;
        
        state.phase1DashboardData = payload.data.data;
      }
    );
    builder.addCase(fetchPhase1DashboardData.rejected, (state, { payload }) => {
      state.loading = false;
      
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
  },
});

export const { clearSucessAndErrorAction } = DashBoardSlice.actions;

export const dashBoardReducer = DashBoardSlice.reducer;
