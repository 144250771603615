import React from "react";
import { Box, Grid, Stack, Typography, Divider, TextField, InputAdornment } from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import ReportsTable from "../../../components/Table/Phase2/ReportsTable";
import { getReportList, getVoucherDetails } from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import { useSelector } from "react-redux";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import VoucherTable from './../../../components/Table/Phase2/VoucherTable';
import { Colors } from "../../../theme/theme";
import { ReportDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from '@mui/icons-material/Description';
import Image from "../../../assets/images/Group 42722.svg";
import { useReactToPrint } from "react-to-print";
import TextWrapperComponent from './../../../components/FormComponents/TextWrapperComponent';
import { SuffixTextField } from './../../../components/FormComponents/SuffixPrefixTextFieldV2';
import styles from "./VoucherCard.module.css";
import CommonTable from "./CommonTable"
import CommonTable2 from "./CommonTable2"
import moment from "moment/moment";

export default function CommonFields({ soaReport }) {

    console.log({ soaReport })
    let soaSummary = []

    console.log(Object.keys(soaReport).length)
    let sortedSummary = Object.keys(soaReport).sort();
    console.log({ sortedSummary })

    for (let i = 0; i < sortedSummary.length; i++) {
        if (sortedSummary[i] == "accountingEntries"
            || sortedSummary[i] == "createdAt"
            || sortedSummary[i] == "interestRateTrailData"
            || sortedSummary[i] == "loanUploadData"
            || sortedSummary[i] == "_id"
            || sortedSummary[i] == "totalDebitAmount"
            || sortedSummary[i] == "totalCreditAmount"
        ) {
            continue;

        } else {
            const str = sortedSummary[i];
            const arr = str.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1));

            if (arr[0] == "Current") {
                arr[0] = "Total";
            }
            const capitalizedStr = arr.join(" ");
            console.log(capitalizedStr);


            soaSummary.push({
                typeName: capitalizedStr,
                typeValue: sortedSummary[i] == "interestRate"
                    ? `${Number(soaReport[`${sortedSummary[i]}`]).toFixed(2)} %`
                    : sortedSummary[i] == "fromDate"
                        ? (moment(soaReport[`${sortedSummary[i]}`]).format("DD-MM-YYYY")) : sortedSummary[i] == "toDate"
                            ? (moment(soaReport[`${sortedSummary[i]}`]).format("DD-MM-YYYY"))
                            : soaReport[`${sortedSummary[i]}`],
            })
        }

    }

    console.log({ soaSummary })

    return < div >
        <div >
            {
                soaReport !== null && <>
                    <div >
                        <div style={{ padding: "20px" }}>
                            <form>
                                <Grid container spacing={4}>

                                    {
                                        soaSummary.length > 0 && soaSummary.sort().map(item => {
                                            return <>
                                                <Grid item xs={3}>
                                                    <TextWrapperComponent text={item.typeName}>
                                                        <Typography>{item.typeValue}</Typography>
                                                    </TextWrapperComponent>
                                                </Grid>
                                            </>
                                        })
                                    }

                                </Grid>
                            </form>
                        </div>
                        <div style={{ padding: "20px" }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <CommonTable2 soaReport={soaReport} />
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: "30px" }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <CommonTable soaReport={soaReport} />

                                    </Grid>
                                </Grid>

                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    </div >
}
