import React, { useState } from "react";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import { Button, Grid, Typography, Box } from "@mui/material";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import { SelectComponent } from "../../../components/FormComponents/SelectComponent";
import { useHistory } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack } from "@mui/material";
import UploadDocuments from "../../../components/Wrapper/UploadDocuments";
import { AddMoreFileComponent } from "../../../components/Wrapper/UploadComponents";
import { Colors } from "../../../theme/theme";
import Layout from "../../../components/Layout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import { FormDetails } from "./../../../components/Layout/TabsLayout/TabsComponents";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import UploadTemplateOriginator from "../../../components/Wrapper/UploadMultipleHandler";
import BasicModal from "./../../../components/Modal/Modal";
import { uploadLoanPool } from "../../../redux/originator/poolUploadSlice";
import { setEntityFile } from "../../../redux/Entity/entitySlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import styles from "./Originator.module.css";
import {
  boxWrapper,
  btnStyle,
  descriptionIconStyle,
  paddingTop,
  typo,
  typoGraphyStyle,
  typoStyle,
} from "./OriginatorCSS";
import Paths from "../../../components/constants/Path";

const EntityLoanDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const originatorState = useSelector((state) => state.originator);
  const [leReport, setleReport] = useState([{}]);
  const [open, setOpen] = useState(false);
  let params = useParams();
  let dealId = params.dealId;
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  const history = useHistory();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const handleleReportClick = () => {
    setleReport((data) => [...data, {}]);
  };

  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deals",
      path: Paths.EntityDealList,
    },
    {
      pathName: "View Entity Details",
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  useEffect(() => {
    console.log("ids", ids);
  }, [ids]);

  const onClickHandler = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePositiveBtn = () => {
    console.log(ids);
    if (ids.length === 0) {
      dispatch(showErrorAlertAction("Please select file"));
      setOpen(false);
      return;
    }

    let selectedFilesIds = ids.map((item) => item.fileId);
    if (
      dealState?.filterdata?.entityDetail?.leReports &&
      dealState?.filterdata?.entityDetail?.leReports.length > 0
    ) {
      let entityIds =
        dealState?.filterdata?.entityDetail?.leReports &&
        dealState?.filterdata?.entityDetail?.leReports.map((item) => item._id);
      console.log({ entityIds });
      dispatch(
        setEntityFile({
          dealId: params.dealId,
          leReports: [...entityIds, ...selectedFilesIds],
          file: dealState?.filterdata?.entityDetail?.file?._id,
        })
      ).then((res) => {
        if (res?.payload?.status == 201) {
          dispatch(showAlertAction("File uploaded successfully"));
        }
      });

      setOpen(false);
      history.push(Paths.EntityDealList);
      return;
    }
    let entityIds = ids.map((item) => item?.fileId);
    console.log({ entityIds });
    dispatch(
      setEntityFile({
        dealId: params.dealId,
        leReports: entityIds,
        file: dealState?.filterdata?.entityDetail?.file?._id,
      })
    );

    setOpen(false);
    history.push(Paths.EntityDealList);
  };

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      {dealState.loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={boxWrapper}>
            <div>
              <FormDetails
                icon={icon}
                iconColor={"#DBE3F8"}
                formName={dealState?.name}
              />
              <Box sx={paddingTop}></Box>
            </div>
            <div className={styles.imgWrapper}>
              <img className={styles.imgStyle} src={Image} alt="image" />
            </div>
          </Box>

          <div className={styles.paddingTop}>
            <WrapperComponent title="Entity Details">
              <>
                <div className={styles.paddingTop}>
                  <Grid container spacing={3}>
                    <Grid item xs={13}>
                      <Typography sx={typoGraphyStyle}>
                        Entity Details
                      </Typography>
                      <Typography sx={typoStyle}>
                        <ViewDownloadDeleteComp
                          fileName={
                            dealState?.filterdata?.entityDetail?.file?.filename
                          }
                          id={dealState?.filterdata?.entityDetail?.file?._id}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </>
            </WrapperComponent>

            {dealState?.filterdata?.entityDetail?.leReports &&
              dealState?.filterdata?.entityDetail?.leReports.map(
                (item, index) => {
                  return (
                    <>
                      <WrapperComponent title="LE Reports">
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography sx={typoGraphyStyle}>
                              Le Report {index + 1}
                            </Typography>
                            <Typography sx={typoStyle}>
                              <ViewDownloadDeleteComp
                                fileName={item.filename || ""}
                                id={item._id || ""}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </WrapperComponent>
                    </>
                  );
                }
              )}

            {userData?.roles.length == 1 ? (
              <>
                <WrapperComponent title="Upload LE Report">
                  <>
                    <br></br>

                    <div>
                      <div className={styles.leReportWrapper}>
                        <DescriptionIcon
                          sx={descriptionIconStyle}
                        ></DescriptionIcon>
                        <Typography sx={typo}>LE Report</Typography>
                      </div>

                      <div className={styles.paddingStyle}>
                        {leReport.map((data, i) => {
                          return (
                            <div>
                              <UploadTemplateOriginator
                                docName="financialPerformanceMetrics"
                                setIds={setIds}
                                ids={ids}
                              ></UploadTemplateOriginator>
                              <div className={styles.paddingStyle}></div>
                            </div>
                          );
                        })}
                      </div>
                      {!originatorState.loading && (
                        <div className={styles.paddingStyle}>
                          <AddMoreFileComponent
                            clickhandler={handleleReportClick}
                          ></AddMoreFileComponent>
                        </div>
                      )}
                    </div>
                  </>
                </WrapperComponent>
                <br></br>

                <div className={styles.btnWrapper}>
                  <Button
                    variant="contained"
                    sx={btnStyle}
                    type="submit"
                    disabled={
                      ids.length < 1 || originatorState.loading ? true : false
                    }
                    onClick={onClickHandler}
                  >
                    Upload
                  </Button>
                </div>
              </>
            ) : (<></>)}
            <BasicModal
              open={open}
              setOpen={setOpen}
              title="Do you want to Upload this File?"
              positiveText="Yes"
              negativeText="No"
              positiveOnClickHandler={handlePositiveBtn}
              negativeOnClickHandler={handleClose}
            ></BasicModal>
          </div>
        </>
      )}
    </Layout>
  );
};

export default EntityLoanDetails;
