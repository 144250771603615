import { Box, Stack, Typography } from '@mui/material';
import React from 'react'
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Paths from '../../components/constants/Path';
import Layout from '../../components/Layout/Layout';
import WrapperComponent from '../../components/Wrapper/WrapperComponent';
import ManIcon from "../../assets/images/Man.png";

const CONDUCTPOOLSHORTLISTING = () => {

    let history = useHistory();

    const breadCrumbs = [
      {
        path: Paths.DASHBOARD,
        pathName: "Home",
      },
      {
        pathName: "Deal List",
      },
    ];
    const onClickHandlerToCreateOriginatorProfile = () => {
      return history.push(Paths.POOLSHORTLISTINGDEALIST);
    };

    return (
        <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <WrapperComponent
        title="List of Deals"
        children={
          <Box height="419px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                display="flex"
                height="auto"
              >
                <img src={ManIcon} width="192px" height="192px"></img>
                <Typography fontSize="18px" fontWeight="800" paddingTop={1}>
                  No Secondary Checks Done yet
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight="400"
                  color="#4D4D4D"
                  padding={1}
                >
                  You can start by conducting one
                </Typography>
                <PrimaryButton
                  text="Conduct Secondary Check"
                  width="384px"
                  onClickHandler={onClickHandlerToCreateOriginatorProfile}
                ></PrimaryButton>
              </Stack>
            </Box>
          </Box>
        }
      ></WrapperComponent>
    </Layout>
    )

}

export default CONDUCTPOOLSHORTLISTING;