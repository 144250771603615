import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Image from "../../assets/images/Group 42722.svg";
import Tabs from "../../components/Tabs/Tabs";
import Layout from "../../components/Layout/Layout";
import TabLayout from "../../components/Layout/TabsLayout/Layout";
import DescriptionIcon from "@mui/icons-material/Description";
import BasicInformationTab from "../../components/Tabs/CreateDealTabs/BasicinformationTab/BasicInformationTab";
import DocumentsTab from "../../components/Tabs/CreateDealTabs/LoanTemplateUpload/DocumentsTab";
import DealDeatils from "../../components/Tabs/CreateDealTabs/DealDetailsTab/DealDetails";
import AssignSPOCTab from "../../components/Tabs/CreateDealTabs/AssignSpocs/AssignSPOCs";
import Queries from "../../components/Queries/Queries";
import Histories from "../../components/History/Histories";
import { useEffect } from "react";
import { clearDealState } from "../../redux/Deals/dealSlice";
import { breadCrums } from "../../components/constants/BreadCrumbs";
import Paths from "./../../components/constants/Path";

const DealForm = () => {
  let originatorState = useSelector((state) => state.deal);

  useEffect(() => {
    return () => {
      dispatch(clearDealState());
    };
  }, []);

  const tabs = [
    {
      tabName: "Basic Information",
      comp: BasicInformationTab,
      heading: "Deal’s Basic Details",
    },
    {
      tabName: "Deal Details",
      comp: DealDeatils,
      heading: `Deal Details for - ${originatorState.name}`,
    },
    {
      tabName: "Loan Template",
      comp: DocumentsTab,
      heading: "Upload loan template for this deal",
    },
    {
      tabName: "Assign SPOCs",
      comp: AssignSPOCTab,
      heading: `Assign SPOCs for - ${originatorState.name}`,
    },
  ];

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.CREATE_DEAL,
      pathName: "Deal",
    },
    {
      pathName: "Create New Deal",
    },
  ];

  //? useDispatch
  let dispatch = useDispatch();

  let TabsList = [
    {
      name: "Deal",
      component: (
        <Tabs
          tabs={tabs}
          status={originatorState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
    {
      name: "Queries",
      component: <Queries />,
      index: 1,
    },
    {
      name: "History",
      component: <Histories />,
      index: 2,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <TabLayout
          TabsList={TabsList}
          formName={"Create New Deal"}
          icon={icon}
          iconColor={"#DBE3F8"}
          image={Image}
        />
      </Layout>
    </>
  );
};

export default DealForm;
