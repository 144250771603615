export const FilterConstants = {
  ALL: "All",
  APPROVED: "Approved",
  ON_HOLD: "On Hold",
  REJECTED: "Rejected",
  PENDING: "Pending",
  ACTION: "Action",
  LOAN_STATUS: "Loan Status",
  Originator: "Originator",
  UPLOADED: "Uploaded",
  PEL: "PEL",
  PCHFL: "PCHFL",
};

// Please make sure the case sensitivity of written codes

export const lowerConstants = {
  CREATED: "Created",
};
export const statusConstants = {
  ALL: "ALL",
  APPROVED: "APPROVED",
  ON_HOLD: "ON HOLD",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  CREATED: "CREATED",
  DONE: "DONE",
  DEAL_REJECTED: "DEAL REJECTED",
  CLOSED: "CLOSED",
  NOT_CREATED: "NOT CREATED",
  APPROVAL_PENDING: "APPROVAL PENDING",
  FAILED: "FAILED",
  L1: "L1",
  L2: "L2",
  F1: "F1",
  C1: "C1",
  R1: "R1",
  CP1: "CP1",
  IA1: "IA1",
  CO1: "CO1",
  IN_PROGRESS: "IN_PROGRESS",
  FILE_RECEIVED: "FILE_RECEIVED",
  PARTIALLY_DONE: "PARTIALLY_DONE",
};

// add the respective path in the above object
