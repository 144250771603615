import React from "react";
import Layout from "../../components/Layout/Layout";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";
import ManIcon from "../../assets/images/Man.png";
import { Box, Stack, Typography } from "@mui/material";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import CreateOriginator from "../Originator/CreateOriginator";
import { Link, useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path";
import { useSelector } from "react-redux";
import styles from "./Deal.module.css"
import { listDealStackWrapper, noDealType, startCreate } from "./DealCSS";

const CreateDeal = () => {
  const dealState = useSelector((state) => state.deal);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Create Deal",
    },
  ];

  let history = useHistory();

  const onClickHandlerToDealCreation = () => {
    return history.push(Paths.DEAL_FORM);
  };

  if (dealState?.allDeals?.length > 0) {
    return history.push(Paths.DEALS_LIST);
  }

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <WrapperComponent
        title="List of Deals"
        children={
          <div className={styles.DealWrapper}>
            <div>
              <Stack sx={listDealStackWrapper}>
                <img src={ManIcon} width="192px" height="192px"></img>
                <Typography sx={noDealType}>
                  No Deal Created Yet
                </Typography>
                <Typography sx={startCreate}>
                  You can start by creating one
                </Typography>

                <PrimaryButton
                  text="Create New Deal"
                  onClickHandler={onClickHandlerToDealCreation}
                  width="384px"
                ></PrimaryButton>
              </Stack>
            </div>
          </div>
        }
      ></WrapperComponent>
    </Layout>
  );
};

export default CreateDeal;
