import React from "react";
import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import { useSelector } from "react-redux";
import { ReportDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../../assets/images/Group 42722.svg";
import { useReactToPrint } from "react-to-print";
import CommonFields from "./CommonFieldsV2";
import VoucherTableComp from "./../../../components/Table/Phase2/VoucherTableComp";
import Styles from "./VoucherCard.module.css";
import Styles1 from "./F2.module.css";
import Logo from "../../../assets/images/Logo.png";
import {
  MonthEndUploadListTableBox,
  ViewSOAReportDetailsTypography,
  ViewSOAReportStack,
  ViewVoucherDetailsPrintButton,
  mainBoxStyle,
  reportDetailsIconStyle,
} from "./F2CSS";

const VoucherDetails = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionNo, setTransactionNo] = useState("");
  const [sortedList, setSortedList] = useState([]);
  const [state, setState] = useState({
    customerName: "",
  });

  //? useDispatch
  let dispatch = useDispatch();

  //? useSelector for getting the data from the store
  let {
    loading,
    ReportsList,
    totalPages,
    error,
    success,
    voucher,
    voucherData,
  } = useSelector((state) => state.uploadReport);

  //Using debounce Hook for transactionNo
  const debouncedSearch = useDebounce(transactionNo, 1000);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Voucher Details.pdf`,
  });

  let history = useHistory();

  //Breadcrumb Navigation
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Reports",
    },
  ];

  let icon = <DescriptionIcon sx={reportDetailsIconStyle} />;

  //user data from local storage
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const keyDownHandler = (e) => {
    console.log(e);
  };

  console.log({ voucher });
  return (
    <Layout ref={componentRef} componentName={null} breadCrumbs={breadCrumbs}>
      <Box sx={mainBoxStyle}>
        <div>
          <ReportDetails
            icon={icon}
            iconColor="#DBE3F8"
            formName="View Reports"
          />
        </div>

        <div className={Styles1.monthEndUploadImageDiv}>
          <img
            className={Styles1.monthEndUploadImage}
            src={Image}
            alt="image"
          />
        </div>
      </Box>
      <Box
        sx={MonthEndUploadListTableBox}
        ref={componentRef}
        onKeyDown={keyDownHandler}
      >
        <>
          <div className={Styles.header}>
            <div className={Styles.logoWrapper}>
              <div className={Styles.imageWrapper}>
                <img src={Logo} alt="logo" />
              </div>
              <p className={Styles.logoName}>Swayam</p>
            </div>
          </div>
          <br />

          <div className={Styles1.ViewSOAReportDetailsDiv}>
            <Stack sx={ViewSOAReportStack}>
              <Typography sx={ViewSOAReportDetailsTypography}>
                Voucher Details
              </Typography>
            </Stack>

            <Button
              variant="contained"
              onClick={handlePrint}
              sx={ViewVoucherDetailsPrintButton}
              type="submit"
            >
              Click to Print
            </Button>
          </div>
          <Divider></Divider>

          <div>
            {voucher !== null && (
              <>
                <CommonFields voucher={voucher} />
                <VoucherTableComp
                  rows={[
                    "Ledger Code",
                    "Ledger Description",
                    "Transaction Date",
                    "Transaction For",
                    "Debit Amount",
                    "Credit Amount",
                  ]}
                  data={voucher}
                  totalCreditAmount={voucherData?.totalCredit}
                  totalDebitAmount={voucherData?.totalDebit}
                />
              </>
            )}
          </div>
        </>
      </Box>
    </Layout>
  );
};

export default VoucherDetails;
