import React from "react";
import Layout from "../../components/Layout/Layout";
import {
  Box,
  Stack,
  Typography,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DealTable from "../../components/Table/SortedDealTableV2";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SecoundaryButton from "../../components/Buttons/SecoundaryButton";
import { getAllDeals } from "../../redux/Deals/dealSlice.js";
//? react-router-dom
import { useHistory } from "react-router-dom";
import Paths from "./../../components/constants/Path";
import searchIcon from "../../assets/icons/searchIcon.svg";
import useDebounce from "../../Hooks/useDebounce";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../redux/app/appSlice";
import styles from "./Deal.module.css"
import { boxWrapper, fontStyle, stackStyle } from "./DealCSS";
import { FilterConstants } from "../../components/constants/FiltersConstant.js";
import { breadCrums } from "../../components/constants/BreadCrumbs.js";
import Loader from "../../utils/Loader.jsx";

const ListOfDeal = () => {
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const debouncedSearch = useDebounce(search, 1000);
  
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const filterHandler = (name) => {
    if(name == "PEL" || name == "PCHFL"){
      setEntityFilter(name);
      setFilterName("")
    }else{
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName, entityFilter]);

  useEffect(() => {
    dispatch(getAllDeals());
  }, []);

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        filterName,
        entityFilter,
        source: "PHFL",
      })
    )
  }, [currentPage, filterName, entityFilter, debouncedSearch]); 

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.APPROVED,
    FilterConstants.ON_HOLD,
    FilterConstants.PENDING,
    FilterConstants.REJECTED,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];
  
  let history = useHistory();

  function clickHandlerToViewDetails(deal) {
    if (
      userData.roles[0] == "C1" ||
      userData.roles[0] == "R1" ||
      userData.roles[0] == "CP1" ||
      userData.roles[0] == "IA1" ||
      userData.roles[0] == "CO1"
    ) {
      return history.push({
        pathname: `/swayamUserdeallist/${deal._id || deal}/details`,
        state: deal,
      });
    } else {
      return history.push({
        pathname: `/view/${deal._id || deal}/details`,
        state: deal,
      });
    }
  }

  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage == dealState.totalPages) {
      // alert("Max Number Reached!");
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));

      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  // Previous Page Handler Function for Pagination ends here

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        minHeight="144px"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={boxWrapper}
      >
        <>
          <Stack sx={stackStyle}>
            <Typography sx={fontStyle}  paddingBottom={2}>
              List of Deals
            </Typography>
            {userData?.roles[0] == "C1" ||
            userData.roles[0] == "R1" ||
            userData.roles[0] == "CP1" ||
            userData.roles[0] == "IA1" ||
            userData.roles[0] == "CO1" ||
            userData?.roles.length != 1 ? (
              ""
            ) : (
              <SecoundaryButton
                text="Create New Deal"
                onClickHandler={() =>
                  history.push(
                    dealState?.allDeals?.length < 1
                      ? Paths.CREATE_DEAL
                      : Paths.DEAL_FORM
                  )
                }
              ></SecoundaryButton>
            )}
          </Stack>

          <Divider></Divider>
          <div className={styles.filterListWrapper}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item || entityFilter == item ? "white" : "black",
                        backgroundColor:
                          filterName === item || entityFilter == item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <Loader />
          ) : (
            <Box>
              <DealTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                setSortOrder={setSortOrder}
                header={
                  userData.roles[0] === "C1" ||
                  userData.roles[0] == "R1" ||
                  userData.roles[0] == "CP1" ||
                  userData.roles[0] == "IA1" ||
                  userData.roles[0] == "CO1"
                    ? [
                        "Deal Name",
                        "Deal Creation Date",
                        "Asset Class",
                        "Requested Pool Size",
                        "Transaction",
                        "Deal Status",
                        "Action",
                      ]
                    : [
                        "Deal Name",
                        "Deal Creation Date",
                        "Asset Class",
                        "Requested Pool Size",
                        "Entity Type",
                        "Deal Status",
                        "Loan Status",
                        "Action",
                      ]
                }
                viewActionClickHandler={clickHandlerToViewDetails}
              ></DealTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default ListOfDeal;
