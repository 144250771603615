import moment from "moment/moment";
import styles from "./HistoryComp.module.css";

const HistoryComp = ({ data }) => {
  let dataTimeData = moment(data.createdAt).format("DD/MM/YYYY , LT");
  let splitDataTime = dataTimeData.split(",");
  const module = data?.module.toLowerCase();

  const currentStatus = data?.currentStatus.toLowerCase();
  console.log({data})
  return (
    <div className={styles.historyCompWrapper}>
      <div className={styles.dateTimeWrapper}>
        <p>{splitDataTime[0]}</p>
        <p>{splitDataTime[1]}</p>
      </div>
      <div className={styles.queryWrapper}>
        <div className={styles.nameAndQueryWrapper}>
          <p className={styles.name}>
            {module.split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")} {currentStatus.split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")} By {data.changedByIdRefData.name}
          </p>
          <p className={styles.currentStatus}> {data?.query}</p>
        </div>
      </div>
    </div>
  );
};

export default HistoryComp;
