import { Grid, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path";
import SpocTable from "../../components/Table/SpocTable";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";

const DealDetails = () => {
  const DealData = [
    {
      title: "Type of Transaction",
      desc: "PTC",
    },
    {
      title: "Product / Asset Class",
      desc: "Home Loan",
    },
    {
      title: "Vintage in Operations",
      desc: "10",
    },
    {
      title: "Rating",
      desc: "A452!",
    },
    {
      title: "AUM",
      desc: "10.23",
    },
    {
      title: "Net Worth",
      desc: "15475.544",
    },
    {
      title: "PAT",
      desc: "4565.23",
    },
    {
      title: "CRAR%",
      desc: "20%",
    },
    {
      title: "GNPA%",
      desc: "20%",
    },
    {
      title: "NNPA %",
      desc: "20%",
    },
    {
      title: "CRAR%",
      desc: "20%",
    },
    {
      title: "Requested Deal Size",
      desc: "200 Cr.",
    },
    // {
    //   title: "Bureau Name",
    //   desc: "Highmark",
    // },
  ];

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  const history = useHistory();
  const handleDealDuplicationOnClick = () => {
    return history.push(Paths.DEALDUPLICATION);
  };

  return (
    <>
      <WrapperComponent
        showButton={true}
        title="Deal Details"
        onClickButton={handleDealDuplicationOnClick}
      >
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {DealData.map((data) => {
                return (
                  <Grid item xs={3}>
                    <DataComp title={data.title} desc={data.desc} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>

      <WrapperComponent title="SPOC Details">
        <>
          <div>
            <SpocTable></SpocTable>
          </div>
        </>
      </WrapperComponent>



      <div style={{ padding: "10px" }}></div>
    </>
  );
};

export default DealDetails;
