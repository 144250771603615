import React, { useEffect} from "react";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import DescriptionIcon from "@mui/icons-material/Description";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import { useDispatch, useSelector } from "react-redux";
import {showAlertAction} from "../../../redux/app/appSlice";
import BasicModal from "../../Modal/Modal";
import Paths from "../../constants/Path";
import { useHistory, useParams } from "react-router-dom";
import UploadTemplateOriginator from "../../Wrapper/UploadLoanTemplate_Originator";
import { getloanUploadTemplate } from "../../../redux/Deals/dealSlice";
import {uploadLoanPool,removeUploadLoanTemplate,} from "../../../redux/originator/poolUploadSlice";
import Loader from "../../../utils/Loader";
import LabelWithDownload from "../../LabelWithDownload";

const LoanUploadTemplate = ({ nextTabHandler }) => {
  const dealState = useSelector((state) => state.deal);
  const [open, setOpen] = React.useState(false);
  const params = useParams();
  console.log({ dealState });
  const [status, setStatus] = React.useState(false);
  const [isloading, setIsLoading] = React.useState(false);
  const [loanFile, setLoanFile] = React.useState(null);

  const history = useHistory();
  console.log({ loanFile });
  let loanTypeId = dealState?.loanTypeData?._id;
  const [msgLoading, setMsgLoading] = React.useState(false);

  let dispatch = useDispatch();

  const handleClosureStatus = () => {
    setOpen(false);
    setIsLoading(true);
    dispatch(
      uploadLoanPool({
        formData: loanFile,
        loanId: loanTypeId,
        dealId: params.dealId,
        reUploadId: "",
      })
    )
      .then((res) => {
        setIsLoading(false);
        history.push(Paths.ORIGINATORDEALLIST);
        dispatch(showAlertAction("Your file is getting uploaded !"));
        dispatch(removeUploadLoanTemplate());
        return;
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  const nextBtnClickHandler = (e) => {
    setOpen(true);
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(getloanUploadTemplate(loanTypeId));
  }, []);

  const [state, setState] = React.useState(true);
  let poolState = useSelector((state) => state.poolUpload);

  useEffect(() => {
    console.log({ poolState });
    if (poolState.loanUpload !== null) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [poolState]);

  return (
    <>
      {isloading ? (
        <Loader />
      ) : (
        <div>
          <>
            <DocumentHeader
              title="Loan Upload Template (Downloadable)"
              startIcon={
                <DescriptionIcon
                  sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                ></DescriptionIcon>
              }
            ></DocumentHeader>
            <div style={{ padding: "10px", width: "auto" }}>

              <LabelWithDownload
                label="Loan Upload Template"
                fileId={dealState?.loanUploadTemplate?.fileData?._id}
                fileName={dealState?.loanUploadTemplate?.fileData?.filename}
              />
            </div>

            <DocumentHeader
              title="Loan Upload"
              startIcon={
                <DescriptionIcon
                  sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                ></DescriptionIcon>
              }
            ></DocumentHeader>
            <div style={{ padding: "10px" }}>
              <UploadTemplateOriginator
                loanId={dealState?.loanTypeId}
                dealId={params.dealId}
                docName="loanUpload"
                setdata={setState}
                setLoanFile={setLoanFile}
              />
            </div>
          </>
          <TabFooter
            label="Upload File"
            onclickHandler={nextBtnClickHandler}
            isBtnDisable={status}
            endIcon={<DoneIcon />}
          />

          <BasicModal
            open={open}
            setOpen={setOpen}
            title="Do you want to Upload this File?"
            positiveText="Yes"
            negativeText="No"
            positiveOnClickHandler={handleClosureStatus}
            setloading={setMsgLoading}
          ></BasicModal>
        </div>
      )}
    </>
  );
};

export default LoanUploadTemplate;
