import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SecoundaryButton from "../../components/Buttons/SecoundaryButton";
import Layout from "../../components/Layout/Layout";
import OriginatorTable from "../../components/Table/Table";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path";
import EditOriginatorDealistTable from "../../components/Table/EditOriginatorDealistTable";
import styles from "./EditOriginatorFlow.module.css"
import { listDealStack, titleTypo, topBox } from "./EditOriginatorFlowCSS";

const EditOriginatorDealist = () => {
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));
  const filterList = [
    {
      status: "All",
      bgcolor: null,
      color: null,
    },
    {
      status: "Approved",
      bgcolor: "#E3F6E9",
      color: "#277742",
    },
    {
      status: "On Hold",
      bgcolor: "#E4EAFA",
      color: "#2C4584",
    },
    {
      status: "Pending",
      bgcolor: "#FFF4E7",
      color: "#996E37",
    },
    {
      status: "Rejected",
      bgcolor: "#FCE6E5",
      color: "#8D343",
    },
  ];

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: "Originator Profiles",
    },
  ];

  const filterHandler = (status) => {
    setFilterName(status);
  };

  let history = useHistory();
  const clickHandlerToViewDetails = () => {
    history.push(Paths.VIEW_EDITORIGINATOR);
  };

  const onClickHandlerToCreateOriginator = () => {
    return history.push("/create/originator");
  };

  const TableData = [
    {
      id: 198,
      OriginatorName: "Bajaj Finserv Pvt Ltd",
      OriginatorType: "NBFC",
      CreationDate: "17 Aug 2022",
      PendingAction: "Approved",
    },

    {
      id: 81,
      OriginatorName: "Bajaj Finserv Pvt Ltd",
      OriginatorType: "NBFC",
      CreationDate: "17 Aug 2022",
      PendingAction: "Approved",
    },
    {
      id: 82,
      OriginatorName: "Bajaj Finserv Pvt Ltd",
      OriginatorType: "NBFC",
      CreationDate: "17 Aug 2022",
      PendingAction: "Approved",
    },
    {
      id: 83,
      OriginatorName: "Bajaj Finserv Pvt Ltd",
      OriginatorType: "NBFC",
      CreationDate: "17 Aug 2022",
      PendingAction: "Approved",
    },
  ];

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Box sx={topBox}>
        <>
          <Stack sx={listDealStack}>
            <Typography sx={titleTypo} >
              List of Originators
            </Typography>
            <SecoundaryButton
              onClickHandler={onClickHandlerToCreateOriginator}
              text="Create Originator Profile"
            ></SecoundaryButton>
          </Stack>

          <Divider />
          <div className={styles.filterDiv}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={
                        filterName === item.status ? "contained" : "outlined"
                      }
                      sx={{
                        color: filterName === item.status ? "white" : "black",
                        backgroundColor:
                          filterName === item.status ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item.status)}
                    >
                      {item.status}
                    </StyledButton>
                  </>
                ))}
            </div>

            <TextField
              label="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
            ></TextField>
          </div>
          <Box style={{ height: "calc( 100% - 64px)" }}>
            <EditOriginatorDealistTable
              data={TableData}
              header={[
                "Originator Name",
                "Originator Type",
                "Creation Date",
                "Status",
                "Action",
              ]}
              viewActionClickHandler={clickHandlerToViewDetails}
              statusList={filterList}
            />
          </Box>
        </>
      </Box>
    </Layout>
  );
};

export default EditOriginatorDealist;
