// import toast from "react-hot-toast";
import instance from "./BaseUrl";

export const getAllData = async (url, query = {}) => {
  const limit = query.limit || 20;
  const page = query.page || 1;

  const response = await instance({
    method: "GET",
    url: url,
    params: {
      limit,
      page,
      ...query,
    },
  });

  return response?.data;
};

export const getData = async (url, query = {}) => {
  const response = await instance({
    method: "GET",
    url: url,
    params: {
      ...query,
    },
  });
  return response?.data;
};

export const getById = async (url, id) => {
  const response = await instance({
    method: "GET",
    url: `${url}/${id}`,
  });
  return response?.data;
};

export const postRequest = async (url, data, message) => {
  try {
    const response = await instance({
      method: "POST",
      url,
      data,
    });

    // toast.success(message ?? "Data Added Successfully");

    return response?.data;
  } catch (error) {
    console.log(error);

    // toast.error(
    //   error?.response?.data?.message ||
    //     error?.response?.data?.error ||
    //     JSON.stringify(error?.response?.data) ||
    //     error?.message ||
    //     "something went wrong"
    // );
    new Error(error);
  }
};
export const updateDocumentByID = async (url, id, data) => {
  try {
    const response = await instance({
      method: "PUT",
      url: `${url}/${id}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.log(error);

    // toast.error(
    //   error?.response?.data?.message ||
    //     error?.response?.data?.error ||
    //     JSON.stringify(error?.response?.data) ||
    //     error?.message ||
    //     "something went wrong"
    // );
    new Error(error);
  }
};

export const deleteDocumentById = async (url, id) => {
  try {
    const response = await instance({
      method: "DELETE",
      url: `${url}/${id}`,
    });
    return response?.data;
  } catch (error) {
    console.log(error);

    // toast.error(
    //   error?.response?.data?.message ||
    //     error?.response?.data?.error ||
    //     JSON.stringify(error?.response?.data) ||
    //     error?.message ||
    //     "something went wrong"
    // );
    new Error(error);
  }
};
