import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSecondaryAutoCheck,
  goForRequestReupload,
  goForSecondaryAutoCheck,
  pageStatusAction,
} from "../../../redux/L1/secondaryCheckSlice";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import TabFooter from "../TabFooter/TabFooter";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "react";
import Modal from "../../Modal/Modal";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { postRequest } from "../../../API/apiHandler";
import { statusConstants } from "../../constants/FiltersConstant";
import ModalV3 from "../../Modal/ModalV3";

const SecondaryAutoCheck = ({ nextTabHandler, setValue }) => {
  let dispatch = useDispatch();
  const [secondaryautocheckdata, setSecondaryAutoCheckData] = useState("");
  const dealState = useSelector((state) => state.deal);
  const [popUpOpen, setPopUpOpen] = useState(false);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  useEffect(() => {
    if(userData?.roles.length == 1){

      if (
        dealState?.filterdata?.poolUploadFinal?.poolStatus !=
        statusConstants.APPROVED
      ) {
        if (dealState?.filterdata?.poolUploadFinal?.dedupeRejectedRecords != 0) {
          setPopUpOpen(true);
        }
        dispatch(
          getAllSecondaryAutoCheck({
            dealId: dealState?.filterdata?._id,
            uniqueCode: dealState?.filterdata?.uniqueCode,
          })
        ).then((res) => {
          setSecondaryAutoCheckData(res.payload.data[0]);
          if (res.payload.status === 200) {
            dispatch(showAlertAction("Secondary Auto Check Successful!"));
          }
        });
      }
    }
  }, []);

  const loanSummary = [
    {
      name: "Portfolio Outstanding",
      value: secondaryautocheckdata?.outstandingAmount,
    },
    {
      name: "No. of Contracts",
      value: secondaryautocheckdata?.secondarySuccessRecords,
    },
  ];

  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const positiveOnClickHandler = () => {
    console.log("positive");
    dispatch(goForRequestReupload({ dealId: dealState?.filterdata?._id })).then(
      (res) => {
        dispatch(showAlertAction(res.payload.data.message));
      }
    );

    setOpen(!open);
  };

  const negativeOnClickHandler = async () => {
    if (secondaryautocheckdata?.secondarySuccessRecords != 0) {
      console.log("HERE");
      setOpen(!open);
      await postRequest("/utility-service/notification", {
        type: "FRONT-END NOTIFICATION",

        dealId: dealState?.filterdata?._id,
        event: "SECONDARY_CHECK_COMPLETED",
      });
      dispatch(pageStatusAction({ pageName: "secondaryAutoCheck" }));
      nextTabHandler();
    } else {
      dispatch(showErrorAlertAction("No approved pools"));
    }
  };

  const positiveDeDupOnClickHandler = () => {
    console.log("positive");
    dispatch(goForRequestReupload({ dealId: dealState?.filterdata?._id })).then(
      (res) => {
        dispatch(showAlertAction(res.payload.data.message));
      }
    );

    setPopUpOpen(!popUpOpen);
  };

  const middleBtnOnClickHandler = () => {
    console.log("Api integration kr lena");
  };

  const negativeDeDupOnCLickHandler = () => {
    setPopUpOpen(false);
  };

  return (
    <>
      {dealState?.filterdata?.poolUploadFinal?.dedupeRejectedRecords != 0 ? (
        <>
          <ModalV3
            popUpOpen={popUpOpen}
            setPopUpOpen={setPopUpOpen}
            isDuplicate={true}
            title="Potential Duplicate Records"
            isFile={true}
            fileName={
              dealState?.filterdata?.poolUploadFinal?.dedupeRejectedFile
                ?.filename
            }
            fileId={
              dealState?.filterdata?.poolUploadFinal?.dedupeRejectedFile?._id
            }
            midHead1Text={"Total Records:"}
            midHead2Text={"Success Records:"}
            midHead3Text={"Rejected Records:"}
            midHead1={
              dealState?.filterdata?.poolUploadFinal?.dedupeTotalRecords
            }
            midHead2={
              dealState?.filterdata?.poolUploadFinal?.dedupeSuccessRecords
            }
            midHead3={
              dealState?.filterdata?.poolUploadFinal?.dedupeRejectedRecords
            }
            midHead4={dealState?.filterdata?.poolUploadFinal?.secondaryRejectedRecords}
            btnText="Wait for pool re-upload"
            negativeText="Proceed with the matched cases."
            positiveOnClickHandler={positiveDeDupOnClickHandler}
            isMiddleBtn={false}
            middleBtnText="Mid Text"
            middleBtnOnClickHandler={middleBtnOnClickHandler}
            negativeOnClickHandler={negativeDeDupOnCLickHandler}
            {...(dealState?.filterdata?.poolUploadFinal?.dedupeSuccessRecords >
            0
              ? {
                  dedupeProceedWithApprovedCases:
                    "Continue With Approved Pools",
                  dedupeProceedWithApprovedCasesOnClickHandler: () => {
                    dispatch(
                      goForSecondaryAutoCheck({
                        dealId: dealState?.filterdata?._id,
                        uniqueCode: dealState?.filterdata?.uniqueCode,
                        dedupeProceedWithApprovedCases: true,
                      })
                    );

                    // close the popup
                    setPopUpOpen(false);
                  },
                }
              : null)}
          ></ModalV3>
        </>
      ) : (
        ""
      )}
      {secondaryautocheckdata?.outstandingAmount > 0 ||
      secondaryautocheckdata?.secondarySuccessRecords > 0 ? (
        <>
          <DocumentHeader title={"Loan Summary"} />
          <div style={{ padding: "24px" }}>
            <Grid container spacing={4}>
              {loanSummary.map((item) => (
                <>
                  {item.value > 0 && (
                    <Grid item xs={3} key={item.name}>
                      <TextWrapperComponent text={item.name}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#20303F",
                          }}
                        >
                          {item.value}
                        </Typography>
                      </TextWrapperComponent>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </div>
          <DocumentHeader title={"Documents"} />
        </>
      ) : null}

      {dealState?.filterdata?.poolUploadFinal?.secondaryRejectedRecords != 0 ? (
        <div
          style={{
            padding: "14px",
          }}
        >
          <Typography
            fontSize="14px"
            color="#808080"
            fontWeight="400"
            paddingBottom="10px"
          >
            Rejected Pool
          </Typography>
          <ViewDownloadDeleteComp
            fileName={
              dealState?.filterdata?.poolUploadFinal?.secondaryRejectedFile
                ?.filename || ""
            }
            id={
              dealState?.filterdata?.poolUploadFinal?.secondaryRejectedFile?._id
            }
          ></ViewDownloadDeleteComp>
        </div>
      ) : (
        ""
      )}

      {dealState?.filterdata?.poolUploadFinal?.secondarySuccessRecords != 0 ? (
        <div
          style={{
            padding: "14px",
          }}
        >
          <Typography
            fontSize="14px"
            color="#808080"
            fontWeight="400"
            paddingBottom="10px"
          >
            Approved Pool
          </Typography>
          <ViewDownloadDeleteComp
            fileName={
              dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile
                ?.filename || ""
            }
            id={
              dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile?._id
            }
          ></ViewDownloadDeleteComp>
        </div>
      ) : (
        ""
      )}
{userData?.roles.length != 1 ? (
  <></>
): (
      <TabFooter
        label="Done"
        endIcon={<DoneIcon />}
        isBtnDisable={
          dealState?.filterdata?.poolUploadFinal?.poolStatus ===
          statusConstants.APPROVED
            ? true
            : false
        }
        onclickHandler={handleModalOpen}
      ></TabFooter>
)}
      <Modal
        open={open}
        setOpen={setOpen}
        title="What you would like to do?"
        negativeText="Continue With Approved Pools"
        positiveText="Wait for pool re-upload"
        positiveOnClickHandler={positiveOnClickHandler}
        negativeOnClickHandler={negativeOnClickHandler}
        isNegativeHandler={true}
        setloading={setOpen}
      ></Modal>
    </>
  );
};

export default SecondaryAutoCheck;
