export const typoGraphyStyle = {
    fontSize:"14px",
    fontWeight:"400",
    color:"#365069"
}

export const conditionalTypoStyle = (isreadOnly) => {
    if(isreadOnly){
        return{
            ...typoGraphyStyle,
            color:"#808080"
        }
    }
    return typoGraphyStyle
}

export const paddingTopStyle = {
    paddingTop: "5px"
}

export const textFieldStyle = {
    color: "#20303F",
    paddingLeft: "0px",
    paddingRight: "8px",
}

export const colorStyle = {
    color: "#5E7387"
}

export const typo = {
    color: "#5E7387",
    fontSize:"14px",
    fontWeight:"700",
}

export const fontSizeStyle = {
    fontSize: "20px"
}