import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

export const createLoanFilters = createAsyncThunk(
  "create-loan-filters",
  async (values, { rejectWithValue }) => {
    let allCities = values.excludedCities.map((city) => city.id);
    allCities = allCities.filter((city) => city !== undefined);
    let allStates = values.excludedStates.map((state) => state.id);
    allStates = allStates.filter((state) => state !== undefined);
    console.log(values);
    console.log({ allCities, allStates });
    console.log({ values });
    let data = JSON.stringify({
      isOverdue: values.isOverdue,
      branchLevelPercentage: values.branchLevelPercentage,
      creditScrubScore: values.creditScrubScore,
      excludedStates: allStates,
      excludedCities: allCities,
      completedTenure: values.completedTenure,
      remainingTenure: values.remainingTenure,
      totalTenure: values.totalTenure,
      maxTotalDpd: values.maxTotalDpd,
      max12MonthDpd: values.max12MonthDpd,
      max24MonthDpd: values.max24MonthDpd,
      isRestructured: values.isRestructured,
      ltv: values.ltv,
      foir: values.foir,
      paymentModes: values.paymentModes,
      propertyType: values.propertyType,
      customerSegment: values.customerSegment,
      customerConstitution: values.customerConstitution,
      maxPrincipalOutstanding: values.maxPrincipalOutstanding,
      isPddCompletion: values.isPddCompletion,
      dpd: values.dpd,
      vehicleTypes: values.typeOfVehicle,
      branchPar0: values.branchPar0,
    });

    console.log({ paymentModes: values.paymentModes });
    try {
      if (values.disableUpdateDetails === false) {
        console.log("create loan");
        const loandata = await instance.post(
          `/portfolio-service/deal/createCreditFilter?dealId=${values.dealId}`,

          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return {
          ...loandata,
        };
      } else {
        const loandata = await instance.post(
          `/portfolio-service/deal/createCreditFilter?dealId=${values.dealId}&update=true`,

          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return {
          ...loandata,
        };
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllStates = createAsyncThunk(
  "getAllStates",
  async (values, thunkApi) => {
    try {
      const data = await instance.get(
        `/master-service/state`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);
export const getAllCities = createAsyncThunk(
  "getAllCities",
  async (values, thunkApi) => {
    try {
      const data = await instance.get(
        `/master-service/getAllExcludedCities?stateIds=${values.searchIds}&name=${values.filter}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getloanFilterData = createAsyncThunk(
  "get-loan-filterdata",
  async (values, rejectWithValue) => {
    try {
      const data = await instance.get(
        `/portfolio-service/originator/getSingleDealById?id=${values}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const initialState = {
  checkedPages: [
    { pageName: "assetQualityFilters", isChecked: false },
    { pageName: "geographicalFilters", isChecked: false },
    { pageName: "loanTenureFilters", isChecked: false },
    { pageName: "loanPropertiesFilters", isChecked: false },
  ],
  dealId: "",
  isOverdue: false,
  value: 0,
  branchLevelPercentage: "",
  branchPar0: "",

  creditScrubScore: "",
  excludedStates: "",
  excludedCities: "",
  completedTenure: "",
  remainingTenure: "",
  totalTenure: "",
  maxTotalDpd: "",
  max12MonthDpd: "",
  max24MonthDpd: "",
  isRestructured: "",
  ltv: "",
  foir: "",
  paymentModes: "",
  propertyType: [],
  vehicleTypes: [],
  customerSegment: [],
  customerConstitution: [],
  maxPrincipalOutstanding: "",
  isPddCompletion: false,
  dpd: "",
  message: "",
  disableUpdateDetails: false,
  allStates: [],
  allCities: [],
  stateLoading: false,
  searchContent: "",
};

const loanSlice = createSlice({
  name: "loanSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    setStatus: (state, { payload }) => {
      state.value = payload;
    },
    setAssetQuality: (state, { payload }) => {
      state.dpd = payload.dpd;
      state.dealId = payload.dealId;
      state.branchLevelPercentage = payload.branchLevelPercentage;
      state.isOverdue = payload.isOverdue;
      state.creditScrubScore = payload.creditScrubScore;
      state.branchPar0 = payload.branchPar0;
    },
    setSearchContent: (state, { payload }) => {
      console.log(payload);
      let allFilteredCities = state.allCities.filter(
        (city) => city.name.toLowerCase().indexOf(payload.toLowerCase()) > -1
      );

      console.log(allFilteredCities);

      state.allCities = allFilteredCities;
    },
    setGeographical: (state, { payload }) => {
      state.excludedCities = payload.excludedCities;
      state.excludedStates = payload.excludedStates;
    },
    setLoanTenure: (state, { payload }) => {
      state.completedTenure = payload.completedTenure;
      state.remainingTenure = payload.remainingTenure;
      state.totalTenure = payload.totalTenure;
      state.maxTotalDpd = payload.maxTotalDpd;
      state.max12MonthDpd = payload.max12MonthDpd;
      state.max24MonthDpd = payload.max24MonthDpd;
      state.isRestructured = payload.isRestructured;
    },
    setLoanProperTies: (state, { payload }) => {
      console.log({ payment: payload.paymentModes });
      state.customerConstitution = payload.customerConstitution;
      state.ltv = payload.ltv;
      state.foir = payload.foir;
      state.paymentModes = payload.paymentModes;
      state.propertyType = payload.propertyType;
      state.customerSegment = payload.customerSegment;
      state.maxPrincipalOutstanding = payload.maxPrincipalOutstanding;
      state.isPddCompletion = payload.isPddCompletion;
    },
    clearLoanFilterState: (state, { payload }) => {
      state.foir = "";
      state.ltv = "";
      state.customerSegment = [];
      state.customerConstitution = [];
      state.propertyType = [];
      state.paymentModes = "";
      state.maxPrincipalOutstanding = "";
      state.isPddCompletion = false;
      state.completedTenure = "";
      state.remainingTenure = "";
      state.totalTenure = "";
      state.maxTotalDpd = "";
      state.max12MonthDpd = "";
      state.max24MonthDpd = "";
      state.isRestructured = "";
      state.excludedStates = "";
      state.excludedCities = "";
      state.creditScrubScore = "";
      state.branchLevelPercentage = "";
      state.branchPar0 = "";
      state.isOverdue = false;
      state.dpd = "";
      state.dealId = "";
      state.value = 0;
      state.checkedPages = [
        { pageName: "assetQualityFilters", isChecked: false },
        { pageName: "geographicalFilters", isChecked: false },
        { pageName: "loanTenureFilters", isChecked: false },
        { pageName: "loanPropertiesFilters", isChecked: false },
      ];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createLoanFilters.rejected, (state, { payload }) => {
      state.message = payload.message;
    });
    builder.addCase(getloanFilterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getloanFilterData.fulfilled, (state, { payload }) => {
      let {
        data: {
          data: {
            data: { loanFilterData: data },
          },
        },
      } = payload;

      let selectedCities = payload?.data?.data?.data?.excludedCitiesData?.map(
        (city) => {
          return { ...city, id: city._id };
        }
      );
      let selectedState = payload?.data?.data?.data?.excludedStatesData?.map(
        (state) => {
          return { ...state, id: state._id };
        }
      );

      state.loading = false;
      state.isOverdue = data?.isOverdue;
      state.dpd = data?.dpd;
      state.dealId = data?.dealId;
      state.branchLevelPercentage = data?.branchLevelPercentage;
      state.branchPar0 = data?.branchPar0;
      state.creditScrubScore = data?.creditScrubScore;
      state.excludedStates = selectedState || [];
      state.excludedCities = selectedCities || [];
      state.completedTenure = data?.completedTenure;
      state.remainingTenure = data?.remainingTenure;
      state.totalTenure = data?.totalTenure;
      state.maxTotalDpd = data?.maxTotalDpd;
      state.max12MonthDpd = data?.max12MonthDpd;
      state.max24MonthDpd = data?.max24MonthDpd;
      state.isRestructured = data?.isRestructured;
      state.customerConstitution = data?.customerConstitution || [];
      state.ltv = data?.ltv;
      state.foir = data?.foir;
      state.paymentModes = data?.paymentModes || [];
      state.propertyType = data?.propertyType || [];
      state.customerSegment = data?.customerSegment || [];
      state.maxPrincipalOutstanding = data?.maxPrincipalOutstanding;
      state.isPddCompletion = data?.isPddCompletion;
      state.vehicleTypes = data?.vehicleTypes || [];

      //? temprory solution for disable update details
      console.log(payload?.data?.data?.data);
      console.log(payload?.data?.data?.data?.loanFilterData);
      state.disableUpdateDetails =
        payload?.data?.data?.data?.loanFilterData === undefined ? false : true;
    });
    builder.addCase(getloanFilterData.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.allSpocsList = [];
      state.error = "Something went wrong";
    });

    builder.addCase(getAllCities.pending, (state, { payload }) => {
      state.citiLoading = true;
    });
    builder.addCase(getAllCities.fulfilled, (state, { payload }) => {
      let tenCitities = [];
      for (let i = 0; i < 10; i++) {
        tenCitities.push(payload.data.data[i]);
      }

      console.log({ payload });
      state.citiLoading = false;
      state.allCities = payload?.data?.data || [];
    });
    builder.addCase(getAllCities.rejected, (state, { payload }) => {
      console.log({ payload });
      state.citiLoading = false;

      state.error = "Something went wrong";
    });
    builder.addCase(getAllStates.pending, (state, { payload }) => {
      state.stateLoading = true;
    });
    builder.addCase(getAllStates.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.stateLoading = false;
      state.allStates = payload?.data?.data?.data || [];
    });
    builder.addCase(getAllStates.rejected, (state, { payload }) => {
      console.log({ payload });
      state.stateLoading = false;
      state.error = "Something went wrong";
    });
  },
});

export const {
  pageStatusAction,
  setAssetQuality,
  setLoanProperTies,
  setLoanTenure,
  setGeographical,
  setStatus,
  clearLoanFilterState,
  setSearchContent,
} = loanSlice.actions;

export const loanReducer = loanSlice.reducer;
