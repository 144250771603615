import Delete from "../../assets/icons/blueDelete.svg";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ChooseFileComponent, SelectedFileComponent } from "./OriginatorSelect";
import { useState } from "react";
import styles from "./Wrapper.module.css";
import {
  uploadKYCDoc,
  deleteDoc,
  getDownloadImage,
} from "../../redux/originator/originatorSlice";
import { uploadDocs } from "../../redux/L1/editOriginatorSlice";
import { uploadValidationFile } from "../../redux/L1/secondaryCheckSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showErrorAlertAction } from "../../redux/app/appSlice";
import { downloadImageReports } from "../../redux/Phase2/SearchReports";

const UploadTemplate = ({
  setdata,
  docName,
  isdisabled = false,
  setLoading,
}) => {
  const [myFiles, setMyFiles] = useState([]);
  const [state, setState] = useState(null);

  let dispatch = useDispatch();
  let originatorState = useSelector((state) => state.originator);
  let editOriginatorState = useSelector((state) => state.editOriginator);
  let secondaryCheckState = useSelector((state) => state.secondaryCheck);

  const onDrop = useCallback((acceptedFiles) => {
    let formData = new FormData();
    console.log(acceptedFiles);
    if (acceptedFiles.length < 1) {
      dispatch(showErrorAlertAction("Please select a supported format file"));
      return;
    }
    // setdata(true);
    formData.set("file", acceptedFiles[0]);

    // var sizeInMB = (acceptedFiles[0]['size'] / (1024*1024)).toFixed();
    // var IntMb= Math.trunc(sizeInMB)
    // console.log({IntMb})

    dispatch(
      uploadKYCDoc({
        formData: formData.getAll("file")[0],
        fileName: docName,
      })
    );

    setState(acceptedFiles[0]);
  }, []);




  const downloadFile = ({ _id, filename }) => async () => {
    setLoading(true);
    try {
      let result = await dispatch(downloadImageReports({ id: _id, fileName: filename })).unwrap()
      setLoading(false)
      return;

    } catch (error) {
      setLoading(false)
      dispatch(showErrorAlertAction("Something went wrong! Please try again later."))
    }
  };

  const removeFile = () => {
    setState(null);
    dispatch(deleteDoc(docName));
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      pdf: [".pdf"],
      doc: [".jpg"],
      jpeg: [".jpeg"],
      png: [".png"],
    },
  });

  console.log({ getRootProps, getInputProps, acceptedFiles, inputRef });
  console.log(getRootProps());
  console.log({ getInputProps: getInputProps() });

  //? useEffect
  useEffect(() => {
    console.log(originatorState[docName])
    if (originatorState[docName]) {
      setState(originatorState[docName]);
      setdata(false);
    }
  }, [originatorState[docName]]);

  useEffect(() => {
    if (editOriginatorState[docName]) {
      setState(editOriginatorState[docName]);
      setdata(false);
    }
  }, [editOriginatorState[docName]]);

  useEffect(() => {
    if (secondaryCheckState[docName]) {
      setState(secondaryCheckState[docName]);
      setdata(false);
    }
  }, [secondaryCheckState[docName]]);

  
  const Test = () => {
    return (
      <>
        <input
          {...getInputProps()}
          disabled={isdisabled === true ? true : false}
        />
        {state.filename == null && state.fileName == null ? (
          <div className={styles.wrapper}>
            <div className={styles.DetailsWrapper}>
              <div className={styles.together}>
                <p>Loading...</p>
              </div>
            </div>
          </div>
        ) 
        : originatorState[docName] === null || originatorState[docName] === undefined ? (
          <SelectedFileComponent
            fileName={state.filename || state.fileName}
            onClickHandler={removeFile}
            isDownloadIcon={false}
          />
        ) 
        : (
          <SelectedFileComponent
            fileName={state.filename || state.fileName}
            fileId={originatorState[docName]._id}
            onClickHandler={() => downloadFile({ _id: originatorState[docName]._id, filename: state.filename })}
            isDownloadIcon={true}
          />
        )}
      </>
    );
  };

  const FileComponent =
    state && state != null ? <Test /> : console.log("state is null");

  console.log({ state });

  return (
    <div>
      {state && state != null ? (
        FileComponent
      ) : (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <ChooseFileComponent />
        </div>
      )}
    </div>
  );
};

export default UploadTemplate;
