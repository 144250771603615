import * as React from "react";
import { Colors } from "../../../theme/theme";

import {
  MenuItem,
  Select,
  ListItemText,
  FormControl,
  ListSubheader,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Grid,
  TextField,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import "../../../App.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  ReportDetails,
} from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../../assets/images/Group 42722.svg";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import Paths from "../../../components/constants/Path";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import { useState, useMemo } from "react";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchDealList,
  getSOAReport,
  getMonthUplods,
} from "../../../redux/Phase2/UploadReportSlice";
import { useEffect } from "react";
import {
  clearSucessAndErrorAction,
} from "../../../redux/Phase2/SearchReports";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import useDebounce from "../../../Hooks/useDebounce";
import { getVoucherDetails } from "../../../redux/Phase2/UploadReportSlice";
import { useHistory } from "react-router-dom";
import styles from "./F2.module.css";
import { dealNameSelectStyle, descriptionIconStyle, displayedOptionsMenuItemStyle, documentHeaderStack, documentHeaderTypographyStyle, financialYearFormContorl, listSubheaderStyle, mainBoxStyle, menuPropsStyle, reportDetailsIconStyle, searchDealStyle, viewDetailsButton } from "./F2CSS";

export default function GenerateReports() {
  const ledgerLists = useSelector((state) => state.uploadReport.ledgerCodes);
  const [statusData, setStatusData] = useState({
    dealName: "",
    financialYear: "",
  });
  const [voucherDetails, setVoucherDetails] = useState({
    financialYear: "",
    voucherNumber: "",
  });
  const [loanNumber, setLoanNumber] = useState("");

  const history = useHistory();
  const transactionHanlder = (name) => (e) => {
    setVoucherDetails({ ...voucherDetails, [name]: e.target.value });
  };

  const handleVoucherReport = async () => {
    console.log("handler");
    dispatch(
      getVoucherDetails({
        transaction: voucherDetails.voucherNumber,
        year: voucherDetails.financialYear,
      })
    ).then((res) => {
      console.log({ res });
      console.log({ res: res.payload.data.data.data.length });
      if (res.payload.data.data.data.length < 1) {
        dispatch(showErrorAlertAction("No Data Found"));
        return;
      } else {
        return history.push("/view/voucher-details");
      }
    });
    console.log("hanlder");
  };
  const handleSoaReport = async () => {
    console.log("handler");
    dispatch(getSOAReport({ loanNumber })).then((res) => {
      console.log({ res });
      console.log({ res: res.payload.data.data.data.length });
      if (res.payload.data.data.data.length < 1) {
        dispatch(showErrorAlertAction("No Data Found"));
        return;
      } else {
        return history.push("/view/soa-report-details");
      }
    });
    console.log("hanlder");
  };

  console.log({ ledgerLists });

  const [statusData1, setStatusData1] = useState({
    selectReportType: "",
  });

  const allBranchesLists = useSelector(
    (state) => state.uploadReport.allBranches
  );

  const [branch, setBranch] = useState("");

  useEffect(() => {
    if (
      statusData.dealName === "" &&
      statusData.transactionMonth === "" &&
      statusData.typeOfReport === ""
    ) {
      setstatus(false);
    } else {
      setstatus(false);
    }
  }, [statusData]);

  const [status, setstatus] = useState(true);
  const [ledgerStatus, setLedgerStatus] = useState(true);
  const [selectedAll, setSelectedAll] = useState(false);
  const [trailStatus, setTrailStatus] = useState(true);
  const [asOnDateStatus, setAsOnDate] = useState(true);
  const [multiDeal, setMultiDeal] = useState([]);
  const [multiledgercodes, setMultiledgercodes] = useState([]);
  const [multiBranch, setMultiBranch] = useState([]);
  const [reportName, setReportName] = useState(null);
  const [selectedReportName, setSelectedReportName] = useState(null);
  const [value, setValue] = React.useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [ledgerstartDate, setledgerstartDate] = useState(null);
  const [ledgerendDate, setledgerEndDate] = useState(null);
  const [ledgerCode, setLedgerCode] = useState("");
  const [filter, setFilter] = useState("");
  const [loanNo, setLoanNo] = useState("");
  let debounceSearch = useDebounce(filter, 700);

  const searchReportState = useSelector((state) => state.serachReport);

  const [previousMonthList, setPreviousMonthList] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  // get list of financial year from 2023-2024 to current year

  function getFinancialYears() {
    const currentYear = new Date().getFullYear();
    let years = [];
    const startYear = 2022;
    for (let year = startYear; year <= currentYear; year++) {
      years.push(`${year}-${year + 1}`);
    }
    return years;
  }

  getFinancialYears();

  // Reports List Data
  const [reportList, setReportList] = useState([
    {
      id: 1,
      name: "Ledger Report",
    },

    {
      id: 2,
      name: "Accounting Report",
    },
    {
      id: 3,
      name: "Poolwise Report",
    },
    {
      id: 4,
      name: "ABC Report",
    },
    {
      id: 5,
      name: "Synergy Extracted Report",
    },
    {
      id: 6,
      name: "Trial Balances Report",
    },
    {
      id: 7,
      name: "As On Date Report",
    },
    {
      id: 8,
      name: "Voucher Report",
    },
    {
      id: 9,
      name: "SOA Report",
    },
  ]);

  // Header Component of the card starts here
  const DocumentHeader = ({ title }) => {
    return (
      <div className={styles.documentHeaderWraper}>
        <Stack sx={documentHeaderStack} spacing={1}>
          <DescriptionIcon
            sx={descriptionIconStyle}
          ></DescriptionIcon>
          <Typography sx={documentHeaderTypographyStyle}>
            {title}
          </Typography>
        </Stack>
      </div>
    );
  };
  // Header Component of the card ends here

  // BreadCrumb Component starts here
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Reports",
    },
  ];
  // BreadCrumb Component ends here

  let icon = <DescriptionIcon sx={reportDetailsIconStyle} />;

  //Useffect for fetching the deal list
  useEffect(() => {
    dispatch(fetchDealList());
  }, []);

  let dispatch = useDispatch();

  let SerachReportState = useSelector((state) => state.serachReport);
  let uploadReportState = useSelector((state) => state.uploadReport);

  useEffect(() => {
    dispatch(clearSucessAndErrorAction());
  }, []);

  //Useffect to check report is null or not

  //Generate Report Function starts here
  const handleViewDealDetails = () => {
    dispatch(
      getMonthUplods({
        page: 1,
        limit: 10,
        dealId: statusData.dealName,
        financialYear: statusData.financialYear,
      })
    ).then((res) => {
      console.log({ res });
      if (res?.payload?.data?.data?.data?.length === 0) {
        dispatch(showErrorAlertAction("No data found "));
        return;
      }
      return history.push(
        `/month-end/deal/${statusData.dealName}/year/${statusData.financialYear}/uploads`
      );
    });
  };

  //Handle Input Change Function starts here
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setStatusData({ ...statusData, [name]: value });
    dispatch(clearSucessAndErrorAction());
  };
  //Handle Input Change Function ends here

  //MenuProps for the select component
  const MenuProps = {
    PaperProps: {
      style: menuPropsStyle,
    },
  };

  //Report Heading

  const containsText = (text, searchText, dealCodeSerach) =>
    (text && text.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
    dealCodeSerach.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const branchContainsText = (text, searchText) =>
    text && text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  let displayedOptions = useMemo(
    () =>
      uploadReportState.dealList.filter((option) =>
        containsText(option.name, debounceSearch, option.dealCode)
      ),
    [debounceSearch]
  );



  console.log({ displayedOptions });

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <Box sx={mainBoxStyle}>
          <div>
            <ReportDetails
              icon={icon}
              iconColor={Colors.ReportDetails_iconColor}
              formName="View Deals"
            />
          </div>
          <div className={styles.monthEndUploadImageDiv}>
            <img className={styles.monthEndUploadImage} src={Image} alt="image" />
          </div>
        </Box>

        {uploadReportState["dealList"].length === 0 ? (
          <div className={styles.uploadReportStateLoader}>
            <Typography>Loading....</Typography>
          </div>
        ) : (
          //View Report starts here
          <WrapperComponent title="View Deals">
            <div className={styles.viewDealsWrapper}></div>
            <>
              <Grid container spacing={4}>
                {/* DEAL TYPE */}

                <Grid item xs={4}>
                  <TextWrapperComponent text="Deal Name">
                    <Select
                      sx={dealNameSelectStyle}
                      IconComponent={ExpandMoreIcon}
                      displayEmpty
                      value={statusData.dealName}
                      onChange={handleInputChange("dealName")}
                      variant="standard"
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <p className={styles.dealNameStyle}>Deal Name</p>;
                        }

                        let selectedItems = uploadReportState.dealList.filter(
                          (item) => item.id == selected
                        );
                        return selectedItems[0]?.name;
                      }}
                    >
                      <ListSubheader sx={listSubheaderStyle}>
                        <TextField
                          size="medium"
                          placeholder="Search deal"
                          fullWidth
                          value={filter}
                          sx={{
                            input: searchDealStyle,
                          }}
                          onChange={(e) => {
                            setFilter(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </ListSubheader>

                      {displayedOptions.length > 0
                        ? [...displayedOptions].map((variant, index) => {
                          return (
                            <MenuItem
                              key={variant.id}
                              value={variant.id}
                              sx={displayedOptionsMenuItemStyle}
                            >
                              <ListItemText
                                primary={
                                  variant?.dealCode?.length > 0
                                    ? variant.name +
                                    "(" +
                                    variant?.dealCode +
                                    ")"
                                    : variant.name
                                }
                              />
                            </MenuItem>
                          );
                        })
                        : uploadReportState.dealList.map((variant, index) => {
                          return (
                            <MenuItem
                              key={variant.id}
                              value={variant.id}
                              sx={displayedOptionsMenuItemStyle}
                            >
                              <ListItemText
                                primary={
                                  variant?.dealCode?.length > 0
                                    ? variant.name +
                                    "(" +
                                    variant?.dealCode +
                                    ")"
                                    : variant.name
                                }
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </TextWrapperComponent>
                </Grid>

                {/* TRANSACTION MONTH */}
                <Grid item xs={4}>
                  <TextWrapperComponent text="Financial Year">
                    <FormControl sx={financialYearFormContorl}>
                      <Select
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        value={statusData.financialYear}
                        onChange={handleInputChange("financialYear")}
                        variant="standard"
                        name="financialYear"
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <p className={styles.dealNameStyle}>
                                Select Financial Year
                              </p>
                            );
                          }

                          return selected;
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {getFinancialYears().map((year, index) => (
                          <MenuItem
                            key={index}
                            value={year}
                            sx={displayedOptionsMenuItemStyle}
                          >
                            <ListItemText primary={year} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TextWrapperComponent>
                </Grid>
              </Grid>
              <div className={styles.noContentDiv}></div>
              <div className={styles.viewDetailsMainButtonDiv}>
                <Button
                  endIcon={<ArrowForwardIosIcon />}
                  variant="contained"
                  disabled={
                    statusData.dealName === "" ||
                    statusData.financialYear === ""
                  }
                  onClick={handleViewDealDetails}
                  sx={{
                    backgroundColor: Colors.piramal_orange,
                    color: Colors.white,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: Colors.piramal_orange,
                    },
                    "&:disabled": viewDetailsButton,
                  }}
                  type="submit"
                >
                  {SerachReportState["loading"] ? "Loading..." : "View Details"}
                </Button>
              </div>
            </>
          </WrapperComponent>
        )}
      </Layout>
    </>
  );
}
