import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  dealId: null,
  loading: null,
  error: null,
  success: null,
  queryList: [],
};

export const fetchQueryList = createAsyncThunk(
  "fetchQueryList",
  async ({ id, changeById }, thunkApi) => {
    let changedUserByID;
    console.log({ id, changeById });
    //try {
    //   const queryData = await instance.get(
    //     `/portfolio-service/query?documentId=${id}&changedById=${changeById}`
    //     // config
    //   );
    //   console.log(queryData);
    //   return queryData;
    // } catch (err) {
    //   thunkApi.rejectWithValue(err);
    // }

    if (changeById == undefined) {
      changedUserByID = "";
    } else {
      changedUserByID = changeById;
    }
    try {
      const histroydata = await instance.get(
        `/portfolio-service/query?documentId=${id}&changedById=${changedUserByID}`
      );
      return histroydata;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);
export const postQuery = createAsyncThunk(
  "createQuery",
  async (value, thunkApi) => {
    let data = JSON.stringify(value);
    try {
      const queryData = await instance.post(
        "/portfolio-service/query",
        data
        // config
      );
      console.log(queryData);
      return queryData;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);
export const replyQuery = createAsyncThunk(
  "replyQuery",
  async (value, thunkApi) => {
    let data = JSON.stringify(value);
    console.log("value", data);

    try {
      const queryData = await instance.post(
        "/portfolio-service/query/reply",
        data
        // config
      );
      console.log(queryData);
      return queryData;
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const postComment = createAsyncThunk(
  "postComment",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      query: values.query,
      documentId: values.documentId,
    });
    try {
      const dealData = await instance.post(
        "/pool-upload-service/comments",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const postCommentV2 = createAsyncThunk(
  "postComment",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      query: values.query,
      documentId: values.documentId,
      queryModule: values.queryModule,
    });
    try {
      const dealData = await instance.post(
        "/pool-upload-service/comments",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchComment = createAsyncThunk(
  "fetchComment",
  async ({ dealId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/pool-upload-service/comments?dealId=${dealId}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postCommentReply = createAsyncThunk(
  "postCommentReply",
  async (values, thunkApi) => {
    let data = JSON.stringify({
      query: values.query,
      commentId: values.commentId,
    });
    try {
      const dealData = await instance.post(
        "/pool-upload-service/comments/reply",

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...dealData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const QuerySlice = createSlice({
  name: "QuerySlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
    },

    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    monthlyCollectionReportAction: (state, { payload }) => {
      state.monthlyCollectionReportFile = payload.monthlyCollectionReportFile;
    },
    dealDetailsAction: (state, { payload }) => {
      state.dealName = payload.dealName;
      state.collectionMonth = payload.collectionMonth;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQueryList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchQueryList.fulfilled, (state, { payload }) => {
      console.log(payload.data.data.data);
      state.loading = false;
      state.queryList = payload.data.data.data;
    });

    builder.addCase(fetchQueryList.rejected, (state, { payload }) => {
      state.loading = false;
      state.queryList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    builder.addCase(postComment.rejected, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.response.data.message);
      state.loading = false;
      state.error = payload.response.data.message;
    });

    builder.addCase(fetchComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchComment.fulfilled, (state, payload) => {
      console.log({ payload });
      //  ;
      // state.loading = false;
      // state.allData = payload;
    });
    builder.addCase(fetchComment.rejected, (state, payload) => {
      console.log({ payload });
      // state.loading = false;
      state.error = "Something went wrong";
    });

    builder.addCase(postCommentReply.rejected, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.response.data.message);
      state.loading = false;
      state.error = payload.response.data.message;
    });
  },
});

export const { clearSucessAndErrorAction } = QuerySlice.actions;

export const querySliceReducer = QuerySlice.reducer;
