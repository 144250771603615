import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../Hooks/useDebounce";
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { pageStatusAction } from "../../../redux/TermSheet/TermSheetSlice";
import Loader from "../../../utils/Loader";
import TermSheetDealUpload from "./../CreateDealTabs/BasicinformationTab/BasicInformationForm/TermSheetDealUpload";
import TabFooter from "./../TabFooter/TabFooter";

const UploadTermSheet = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  let dealState = useSelector((state) => state.deal);
  let allDeals = useSelector((state) => state?.deal?.allDeals);

  let loading = useSelector((state) => state.deal.loading);
  const [filterName, setFilterName] = useState("All");
  const [limit, setLimit] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  let { name, originator } = useSelector((state) => state.deal);

  let [state, setState] = useState({
    name: name || "",
    originator: originator || "",
  });

  const [value, setValue] = useState(originator || "");
  const [status, setStatus] = useState(true);

  let approveDeals = [];

  const debouncedSearch = useDebounce(searchFilter, 1000);
  //getting list of deals
  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        isPoolApproved: true,
        filterName: "APPROVED",
        source: "PHFL",
      })
    );
  }, [currentPage, filterName, debouncedSearch]);

  let userData = JSON.parse(localStorage.getItem("UserData"));
  let activRole = localStorage.getItem("activeRole")
  const roleName = activRole;

  // store list of specific  deals for originator & L2 as per requirement of flow
  if (roleName === "Originator") {
    allDeals.map((item) => {
      if (item?.poolUploadFinal?.isTermSheetApproved == true) {
        approveDeals.push(item);
      }
    });
  }
  if (roleName === "L2") {
    allDeals.map((item) => {
      if (item?.poolUploadFinal?.termsheetCurrentStatus != "PENDING") {
        approveDeals.push(item);
      }
    });
  }

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "UploadTermSheet" }));
    nextTabHandler();
  };

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState({ ...state, [name]: value });
  };

  localStorage.setItem("dealId", value);

  // button disable handler
  useEffect(() => {
    if (state.name != "" || value != "") {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [value]);

  return (
    <>
      {loading && searchFilter == "" ? (
        <Loader />
      ) : (
        <>
          <div style={{ padding: "24px" }}>
            <form>
              <div>
                <TermSheetDealUpload
                  handleInputChange={handleInputChange}
                  dealState={
                    roleName === "L1" ? dealState?.allDeals : approveDeals
                  }
                  state={state}
                  value={value}
                  setValue={setValue}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  originator={originator}
                  name={name}
                />
              </div>
            </form>
          </div>
          <TabFooter
            onclickHandler={nextBtnClickHandler}
            isBtnDisable={status}
          />
        </>
      )}
    </>
  );
};

export default UploadTermSheet;
