import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

export const uploadCreditScrubReport = createAsyncThunk(
  "uploadCreditScrubReport",
  async ({ dealId, formData, uniqueCodeId, type }, thunkApi) => {
    console.log(dealId, uniqueCodeId, formData, type);
    try {
      const uploadData = await instance.post(
        `/pool-upload-service/validateCibilScore`,
        {
          file: formData,
          dealId: dealId,
          uniqueCode: uniqueCodeId,
          type: type
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const updateCreditScrubReport = createAsyncThunk(
  "uploadCreditScrubReport",
  async ({ dealId, updatedcibilScore, uniqueCodeId }, thunkApi) => {
    console.log(dealId, uniqueCodeId, updatedcibilScore);
    try {
      const uploadData = await instance.post(
        `/pool-upload-service/validateCibilScore`,
        {
          updatedcibilScore: updatedcibilScore,
          dealId: dealId,
          uniqueCode: uniqueCodeId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const getCreditBureauScrubReport = createAsyncThunk(
  "getCreditBureauScrubReport",
  async ({ dealId, uniqueCode }, thunkApi) => {
    console.log(dealId, uniqueCode);
    try {
      const uploadData = await instance.get(
        // `/pool-upload-service/validateCibilScore?dealId=${dealId}&uniqueCode=${uniqueCode}}`,
        `/pool-upload-service/validateCibilScore?dealId=${dealId}&uniqueCode=${uniqueCode}`,
        {
          dealId: dealId,
          uniqueCode: uniqueCode,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const initialState = {
  checkedPages: [
    { pageName: "typeOfReport", isChecked: false },
    { pageName: "creditBureauScrubReport", isChecked: false },
    { pageName: "CIBILScore", isChecked: false },
    { pageName: "loanValidation", isChecked: false },
  ],
  creditReportData: null,
  creditBureauScrub: null,
  typeOfReport: "",
};

const creditBureauSlice = createSlice({
  name: "creditBureauSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    setTypeOfReport: (state, { payload }) => {
      state.typeOfReport = payload
    },
    clearCreditBureauState: (state) => {

      // eslint-disable-next-line no-unused-expressions
      state.checkedPages = [
        { pageName: "typeOfReport", isChecked: false },
        { pageName: "creditBureauScrubReport", isChecked: false },
        { pageName: "CIBILScore", isChecked: false },
        { pageName: "loanValidation", isChecked: false },
      ],
        state.creditReportData = null;
      state.creditBureauScrub = null;
      state.typeOfReport = "";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadCreditScrubReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadCreditScrubReport.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.creditReportData = payload.data.data;
    });
    builder.addCase(uploadCreditScrubReport.rejected, (state, { payload }) => {
      state.loading = false;
      state.dealList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
    builder.addCase(getCreditBureauScrubReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCreditBureauScrubReport.fulfilled,
      (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.creditBureauScrub = payload.data.data[0];
      }
    );
    builder.addCase(
      getCreditBureauScrubReport.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.dealList = [];
        state.error =
          payload?.message || payload?.error
            ? payload?.message || payload?.error
            : "Something went wrong!";
      }
    );
  },
});

export const { pageStatusAction, setTypeOfReport, clearCreditBureauState } = creditBureauSlice.actions;

export const creditBureauReducer = creditBureauSlice.reducer;
