import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TabFooter from "../../TabFooter/TabFooter";
import UploadTemplate from "../../../Wrapper/UploadTemplate";
import { useHistory } from "react-router-dom";
import { getloanUploadTemplate, pageStatusAction } from "../../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import UploadTemplateDeal from "../../../Wrapper/UploadTemplateDeal";
import { getOriginatorSpocs } from "../../../../redux/Deals/dealSlice";
import ViewDownloadDeleteComp from "../../../L2/Phase2DownloadComp";
import { showErrorAlertAction } from "../../../../redux/app/appSlice";

const DocumentsTab = ({ nextTabHandler }) => {
  const dealState = useSelector((state) => state.deal);
  let history = useHistory();
  const [status, setStatus] = useState(true);
  const [data, setData] = useState(true);
  const [loanTempName, setLoanTempName] = useState("")
  const [loanTempId, setLoanTempId] = useState("")
  let { name, originator } = useSelector((state) => state.deal);
  
  let loanTypeId = useSelector((state) => state.deal.loanTypeId);


  let dispatch = useDispatch();
  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "loanTemplate" }));
    // dispatch(getOriginatorSpocs(originator));

    nextTabHandler();
  };
  useEffect(() => {
    if (loanTypeId !== undefined) {
      dispatch(getloanUploadTemplate(loanTypeId)).then((res)=>{
        if(res?.payload != undefined){

          setLoanTempId(res?.payload?.fileData?._id)
          setLoanTempName(res?.payload?.fileData?.filename)
        }
      })
      return;
    }
  }, [dealState?.loanTypeId]);
  console.log({loanTempId})
  useEffect(() => {
    if (loanTempId != "" && loanTempId != undefined) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  });
  
  
  
  return (
    <>
      <Box sx={{ width: "100%", p: 2 }}>
        <div>
           <ViewDownloadDeleteComp
              fileName={loanTempName || ""}
              id={loanTempId}
              showEye={true}
            ></ViewDownloadDeleteComp>
          <div style={{ paddingTop: "10px" }}></div>
        </div>
      </Box>
      <TabFooter
        nextTabHandler={nextTabHandler}
        isBtnDisable={status}
        onclickHandler={nextBtnClickHandler}
      />
    </>
  );
};

export default DocumentsTab;
