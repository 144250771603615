import React from "react";
import Layout from "../../components/Layout/Layout.jsx";
import {
  Box,
  Stack,
  Typography,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DealTable from "../../components/Table/SortedDealTable.jsx";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SecoundaryButton from "../../components/Buttons/SecoundaryButton.js";
import { getAllDeals } from "../../redux/Deals/dealSlice.js";
//? react-router-dom
import { useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path.jsx";
import searchIcon from "../../assets/icons/searchIcon.svg";
import useDebounce from "../../Hooks/useDebounce.js";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../redux/app/appSlice.js";
import L1FinalDealTable from "../../components/Table/Phase2/L1FinalDealTable.jsx";

const PortfolioFinalDealList = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setFilter] = useState(0);
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const filterDeals = dealState?.allDeals;

  const debouncedSearch = useDebounce(search, 1000);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const filterHandler = (name) => {
    if(name == "PEL" || name == "PCHFL"){
      setEntityFilter(name);
      setFilterName("")
    }else{
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);

  useEffect(() => {
    dispatch(getAllDeals());
  }, []);

  useEffect(() => {
    if (userData.roles[0] === "L2") {
      dispatch(
        getAllDeals({
          limit,
          currentPage,
          debouncedSearch,
          filterName,
          entityFilter,
          isApproverCommiteeAdded: true,
          source: "PHFL",
        })
      );
    } else if (userData.roles[0] == "C1" ||
      userData.roles[0] == "R1" ||
      userData.roles[0] == "CP1" ||
      userData.roles[0] == "IA1" ||
      userData.roles[0] == "CO1") {
      dispatch(
        getAllDeals({
          limit,
          currentPage,
          debouncedSearch,
          filterName,
          entityFilter,
          source: "PHFL",
        })
      );
    }
    else {
      dispatch(
        getAllDeals({
          limit,
          currentPage,
          debouncedSearch,
          filterName,
          entityFilter,
          source: "PHFL",
          IsEntityExist: true,
          isTermsheetCompleted: true,
        })
      );
    }
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  const filterList = [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Approved",
    },
    {
      id: "2",
      name: "Rejected",
    },
    {
      id: "3",
      name: "PEL",
    },
    {
      id: "4",
      name: "PCHFL",
    },
  ];
  let history = useHistory();

  const clickHandlerToViewDetails = (deal) => {
    if (deal.status == "CLOSED") {
      history.push({
        pathname: `/portfolio/${deal._id || deal}/deal-details`,
        state: deal,
      });
      return;
    }
    if (deal?.investmentMemo !== "" && deal?.investmentMemo !== undefined) {
      return history.push(`/portfolio/${deal._id || deal}/deal-details`);
    }
    return history.push({
      pathname: `/portfolio/view/${deal._id || deal}/details`,
      state: deal,
    });
  };

  const nextPageHandler = () => {
    if (currentPage == dealState.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));

      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: "Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
          </Stack>

          <Divider></Divider>
          <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          >
            <div>
              {filterList.map((item, index) => {
                console.log(item, index);
                return (
                  <>
                    <StyledButton
                      id={item.id}
                      variant={
                        filterName === item.name || entityFilter == item?.name ? "contained" : "outlined"
                      }
                      sx={{
                        color: filterName === item.name || entityFilter == item?.name ? "white" : "black",
                        backgroundColor:
                          filterName === item.name || entityFilter == item?.name ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item.name)}
                    >
                      {item.name}
                    </StyledButton>
                  </>
                );
              })}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
            </div>
          ) : (
            <Box>
              <L1FinalDealTable
                data={filterDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                setSortOrder={setSortOrder}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Loan/Pool Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
              ></L1FinalDealTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default PortfolioFinalDealList;
