import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance, { instanceV2 } from "../../../API/BaseUrl";
import { isAuthenticated } from "../../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "KYCAMLUpload", isChecked: false },
    { pageName: "KYCAMLSummary", isChecked: false },
  ],
  termTemplate: [],
  loading: null,
  error: null,
};

export const getAMLTemplate = createAsyncThunk(
  "getTemplate",
  async ({ type, loanTypeId }, { rejectWithValue }) => {
    try {
      const url = loanTypeId
        ? `/master-service/getTemplate?loanTypeId=${loanTypeId}`
        : `/master-service/getTemplate?type=${type}`;
      const response = await instance.get(url);
      console.log({ response });
      return response.data;

      // if(loanTypeId){
      //   const response = await instance.get(
      //     `/master-service/getTemplate?type=${type}&loanTypeId=${loanTypeId}`
      //   );
      //   console.log({ response });
      //   return response.data;
      // }

      // const response = await instance.get(
      //   `/master-service/getTemplate?type=${type}`
      // );
      // console.log({ response });
      // return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadKYCAMLDoc = createAsyncThunk(
  "uploadKYCAMLDoc",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("file", values.file);
    formData.set("dealId", values.dealId);
    formData.set("financialYear", values.financialYear);
    formData.set("serviceName", values.serviceName);
    formData.set("endpoint", values.endpoint);
    try {
      const uploadData = await instanceV2.post(
        "/utility-service/upload-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSingleAmlUpload = createAsyncThunk(
  "getSingleAmlUpload",
  async ({ dealId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/portfolio-service/getSingleAmlUpload?dealId=${dealId}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const kycAMLRequestSlice = createSlice({
  name: "kycAMLRequestSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    clearkycAMLState: (state) => {
      // eslint-disable-next-line no-unused-expressions
      state.checkedPages = [
        { pageName: "KYCAMLUpload", isChecked: false },
        { pageName: "KYCAMLSummary", isChecked: false },
      ],
        state.termTemplate = [];
      state.loading = null;
      state.error = null;
    },
  },
  extraReducer: (builder) => {
    builder.addCase(getAMLTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAMLTemplate.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.termTemplate = payload;
    });
    builder.addCase(getAMLTemplate.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = payload.response.data.message;
    });
    builder.addCase(getSingleAmlUpload.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleAmlUpload.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      // state.uploadedData = payload;
    });
    builder.addCase(getSingleAmlUpload.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = payload.response.data.message;
    });
    builder.addCase(uploadKYCAMLDoc.rejected, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.response.data.message);
      state.loading = false;
      state.error = payload.response.data.message;
    });
  },
});

export const { pageStatusAction, clearkycAMLState } =
  kycAMLRequestSlice.actions;

export const kycAMLRequestReducer = kycAMLRequestSlice.reducer;
