import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Delete from "../../../assets/icons/blueDelete.svg";
import Download from "../../../assets/icons/downloadIcon.svg";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Box, Button, Stack } from "@mui/material";
import styles from "./UploadReports.module.css";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector } from "react-redux";
export const SelectedFile = ({
  fileName,
  fileSize,
  onClickHandler,
  isDeleteIcon = true,
  csvData,
}) => {
  const handle = () => {
    // console.log("handle", { handle });
    // var wb = XLSX.utils.book_new();
    // var ws = XLSX.utils.json_to_sheet(csvData["data"]);
    // console.log({ data: csvData["data"] })

    // XLSX.utils.book_append_sheet(wb, ws, "MyFilesheet");

    // XLSX.writeFile(wb, fileName + ".xlsx");
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.DetailsWrapper}>
          <div className={styles.together}>
            <CheckCircleIcon
              sx={{ color: "#41C66E", width: "20px", height: "20px" }}
            ></CheckCircleIcon>
            <div padding="10px"></div>
            <p>{fileName}</p>
          </div>
          <p> {fileSize} KB</p>
        </div>
        <div
          className={styles.iconWrapper}
          onClick={onClickHandler}
        >
          {isDeleteIcon ? (
            <img src={Delete}></img>
          ) : (
            <div
              onClick={handle}
              style={{
                pointer: "cursor",
              }}
            >
              <img src={Download}></img>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
