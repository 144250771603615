import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import { Alert, Slide } from "@mui/material";

//?
import { useSelector, useDispatch } from "react-redux";
import { showErrorAlertAction } from "../../redux/app/appSlice";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const ErrorAlert = () => { 
  //? useSelector
  let alert = useSelector((state) => state.app.showErrorAlert);

  console.log({ alert });

  const [state, setState] = useState(alert);
  const [transition, setTransition] = useState(<Slide direction="up" />);

  //? useDispatch
  let dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showErrorAlertAction(false));
  };

  useEffect(() => {
    setState(alert && alert.message ? alert.message : alert);
  }, [alert]);

  console.log({ state });

  return (
    <div>
      {/* <Button onClick={handleClick(SlideTransition)}>Click to See Magic</Button> */}
      {state && (
        <Snackbar
          open={state}
          onClose={handleClose}
          // TransitionComponent={transition}
          message="I love snacks"
          // key={state.Transition.name}
          autoHideDuration={6000}
        >
          <Alert
            onClose={handleClose}
            style={{ backgroundColor: "#EB5752", color: "white" }}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {state}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default ErrorAlert;
