//? style
import styles from "./FiltersComp.module.css";

//? mui
import { Autocomplete, Paper, TextField } from "@mui/material";

//? icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  fetchFiltersName,
  fetchHistroyData,
} from "../../redux/Phase2/HistroySlice";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { fetchQueryList } from "../../redux/Queries/queriesSlice";
import { paperStyle } from "./FiltersCSS";


const QueriesFilters = ({ id }) => {
  const [Data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFiltersName(id));
  }, []);

  const { queryList } = useSelector((state) => state.queries);
  console.log({ id });

  let histroyState = useSelector((state) => state.history);
  useEffect(() => {
    if (histroyState["histroyUserList"] != null) {
      setData(histroyState["histroyUserList"]);
    } else {
      setData(null);
    }
  }, [histroyState]);

  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.name,
  };

  const handleChange = (changeById) => {
    console.log({ changeById });
    if (changeById != null) {
      dispatch(fetchQueryList({ id, changeById }));
    } else {
      dispatch(fetchQueryList({ id }));
    }
  };

  return (
    <div className={styles.filtersCompWrapper}>
      <div lassName={styles.filterByWrapper} >
        <p className={styles.filterBy}> Filter By:</p>
      </div>
      <div className={styles.filtersWrapper}>
        <div className={styles.componentWrapper}>
          <Autocomplete
            popupIcon={<ExpandMoreIcon />}
            {...defaultProps}
            clearOnEscape
            onChange={(event, newValue) => {
              console.log({ newValue });
              handleChange(newValue?.id);
            }}
            PaperComponent={({ children }) => (
              <Paper sx={paperStyle}>
                {children}
              </Paper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Queries By"
                size="small"
                autoComplete="off"
              />
            )}
          />
        </div>
        <div className={styles.width}></div>
      </div>
    </div>
  );
};

export default QueriesFilters;
