import React, { useEffect } from "react"
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader"
import DescriptionIcon from '@mui/icons-material/Description';
import UploadTemplate from "../../Wrapper/UploadLoanTemplate_Originator";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from "react-redux";
import { setTypeOfReport, uploadCreditScrubReport } from "../../../redux/originator/creditBureauSlice";
import { useHistory, useParams } from "react-router-dom";
import Paths from "../../constants/Path";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import { showAlertAction, showErrorAlertAction } from "../../../redux/app/appSlice";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import {
    Typography,
} from "@mui/material";



const CreditBureauReport = ({ nextTabHandler }) => {
    const dispatch = useDispatch();
    const dealState = useSelector((state) => state.deal);
    const cibilState = useSelector((state) => state.creditScrub)
    const params = useParams();
    const [state, setState] = React.useState(null)
    const [loanFile, setLoanFile] = React.useState(null)
    
    let loading = dealState?.loading
   
    useEffect(() => {
        console.log({ loanFile })
    }, [state, loanFile])

    useEffect(() => {
        dispatch(getSingleDealDetails(params.dealId));
        
    }, [])
    
    let history = useHistory();
    const nextBtnClickHandler = (e) => {
        e.preventDefault();

        console.log({ data: dealState })
       
        dispatch(uploadCreditScrubReport({
            dealId: params.dealId, formData: loanFile, uniqueCodeId: dealState?.filterdata?.uniqueCode , type: cibilState?.typeOfReport
        })).then((res) => {
            
            if(res?.payload?.status === 200){
                dispatch(showAlertAction(res.payload.data.message))
                dispatch(setTypeOfReport(""))
                return history.push(Paths.ORIGINATORCREDITDEALSLIST);
            }
            if(res?.payload?.response?.status === 400){

                dispatch(showErrorAlertAction(res?.payload?.response?.data?.message))
                // return history.push(Paths.ORIGINATORCREDITDEALSLIST);
            }
        })
        
        // dispatch(pageStatusAction({ pageName: "creditBureauScrubReport" }));
        // nextTabHandler();
    };

    

    return (
        <>
            {loading && loading ? (
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <img alt="loader" src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
                </div>
            ) : (
                <>

               

                    <DocumentHeader
                        title={"Credit Bureau Scrub Report"}
                        startIcon={
                            <DescriptionIcon
                                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                            />
                        }>
                    </DocumentHeader>
                    <div style={{ padding: "10px", width: "auto" }}>
                        <UploadTemplate docName="creditBureauReport" setLoanFile={setLoanFile}
                            setdata={setState}
                            isdisabled={false}

                        />
                    </div>
                   
                    {(dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile) && (
                        <>

                            <DocumentHeader
                                title={"Approved Pool"}
                                startIcon={
                                    <DescriptionIcon
                                        sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                                    />
                                }
                            ></DocumentHeader>

                            <div
                                style={{
                                    padding: "24px",
                                }}
                            >
                                <Typography
                                    fontSize="14px"
                                    color="#808080"
                                    fontWeight="400"
                                    paddingBottom="10px"
                                >
                                    Approved Pool
                                </Typography>
                                <ViewDownloadDeleteComp
                                    showEye={false}
                                    fileName={dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile?.filename || ""}
                                    id={dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile?._id}
                                ></ViewDownloadDeleteComp>
                            </div>
                        </>
                    )}


                    <TabFooter onclickHandler={nextBtnClickHandler} label="Submit" endIcon={<DoneIcon />} isBtnDisable={false}></TabFooter>
                </>
            )}
        </>
    )
}

export default CreditBureauReport