import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from "../../assets/images/Group 42722.svg";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import Paths from "../../components/constants/Path";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../redux/Deals/dealSlice";
import AMLForm from "../Phase3/AML/AMLForm";
import DealDetails from "./DealDetails";
import { getSingleAmlUpload } from "../../redux/Phase3/KYCAML/KYCAMLSlice";
import { statusConstants } from "../../components/constants/FiltersConstant";

const F1DealDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const [uploadData, setUploadData] = useState("");

  const params = useParams();
  let { dealId } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: dealId })).then((res) => {
      setUploadData(res?.payload?.data);
      // setNewLoader(false);
    });
  }, []);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.F1LISTOFDEAL,
      pathName: "Deals",
    },
    {
      pathName: dealState?.name,
    },
  ];

  let Tabs = [
    {
      name: "Deal",
      index: 0,
      component: <DealDetails />,
    },
  ];

  if(userData?.roles.length == 1){
    if (dealState?.filterdata?.dealType !== "SELL") {
      Tabs.push({
        name: "Compliance",
        index: 3,
        component: <AMLForm />,
      });
    }
  }else{
    if (dealState?.filterdata?.dealType !== "SELL" && 
      uploadData &&
      uploadData?.currentStatus !== statusConstants.PENDING &&
      uploadData?.currentStatus !== statusConstants.FILE_RECEIVED &&
      uploadData?.currentStatus !== statusConstants.IN_PROGRESS ) {
      Tabs.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  //? getting deal data from single dealid which is comming from location state
  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDealState());
    };
  }, []);
  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={Tabs}
      ></TabsLayout>
    </Layout>
  );
};

export default F1DealDetails;
