import React, { useEffect } from "react";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import ViewDownloadAPI from "../../L2/ViewDownloadAPI";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import {
  deleteDoc,
  getOriginatorDetails,
  uploadQuatarlyDoc,
} from "../../../redux/originator/originatorSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { useParams } from "react-router-dom";
import Loader from "./../../../utils/Loader";

const Documents = ({ nextTabHandler }) => {
  let originatorState = useSelector((state) => state.originator);
  const [loading, setLoading] = useState(false);
  const [isAddDocClick, setIsAddDocClick] = useState(false);
  const [data, setData] = useState(true);
  const [loanFile, setLoanFile] = useState(null);

  let dispatch = useDispatch();
  const { originatorId } = useParams();
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const nextBtnClickHandler = (e) => {
    console.log({ loanFile });
    if (loanFile == null) {
      dispatch(showErrorAlertAction("Please select a file"));
      return;
    }

    setLoading(true);
    e.preventDefault();

    var quaterlyPerformanceId = originatorState.quaterlyPer.fileId;


    dispatch(uploadQuatarlyDoc({ quaterlyPerformanceId, originatorId })).then(
      (res) => {
        console.log({ res });
        setLoading(false);
        if (res.payload.status == 200) {
          dispatch(showAlertAction(res.payload.data.message));
          dispatch(getOriginatorDetails(originatorId));
          dispatch(deleteDoc("quaterlyPer"));
          setData(true);
          setIsAddDocClick(false);
        }
      }
    );
    // nextTabHandler();
  };

  const handleAddDocuments = () => {
    setIsAddDocClick(true);
  };

  const handleClose = () => {
    setIsAddDocClick(false);
  };

  useEffect(() => { }, [data]);

  return (
    <>
      {originatorState.loading ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Portfolio Performance Metrics
                </Typography>

                {originatorState.portfolioPerformanceMetrics.map((l) => {
                  return (
                    <>
                      <ViewDownloadDeleteComp
                        id={l._id}
                        fileName={l.fileName || l.filename}
                      ></ViewDownloadDeleteComp>
                      <br></br>
                    </>
                  );
                })}
              </div>
            </Grid>

            <Grid item xs={6}>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Financial Performance Document
                </Typography>
                {originatorState.financialPerformanceMetrics.map((l) => {
                  return (
                    <>
                      <ViewDownloadDeleteComp
                        id={l._id}
                        fileName={l.fileName || l.filename}
                      ></ViewDownloadDeleteComp>
                      <br></br>
                    </>
                  );
                })}
              </div>
            </Grid>
          </Grid>

          {originatorState?.quaterlyPerformance?.length > 0 ? (
            <div
              style={{
                padding: "14px",
              }}
            >
              <Typography
                fontSize="14px"
                color="#808080"
                fontWeight="400"
                paddingBottom="8px"
              >
                Quarterly Performance
              </Typography>
              {originatorState?.quaterlyPerformance?.map((l) => {
                return (
                  <>
                    <ViewDownloadAPI
                      id={l._id}
                      fileName={l.fileName || l.filename}
                    ></ViewDownloadAPI>
                    <br></br>
                  </>
                );
              })}
            </div>
          ) : null}

          {isAddDocClick ? (
            <>
              <DocumentHeader
                title={"Quaterly Performance"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>
              <div style={{ padding: "10px", width: "auto" }}>
                <UploadTemplate
                  docName="quaterlyPer"
                  setdata={setData}
                  isdisabled={true}
                  setLoanFile={setLoanFile}
                />
              </div>
            </>
          ) : null}

          {userData?.roles.length != 1 ? (
            <></>
          ) : (
            <>

              {isAddDocClick ? (
                <TabFooter
                  isClose={true}
                  isBtnDisable={originatorState?.quaterlyPer === null}
                  label={loading ? "Loading..." : "Submit for approval"}
                  endIcon={<DoneIcon />}
                  onclickHandler={nextBtnClickHandler}
                  handleClose={handleClose}
                />
              ) : (
                <TabFooter
                  isBtnDisable={false}
                  label="Add Documents"
                  endIcon={<AddIcon />}
                  onclickHandler={handleAddDocuments}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Documents;
