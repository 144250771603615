import Delete from "../../assets/icons/blueDelete.svg";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ChooseFileComponent, SelectedFileComponent } from "./UploadComponents";
import { useState } from "react";
import styles from "./Wrapper.module.css";

import {
  uploadKYCDoc,
  uploadDocuments,
  deleteDoc,
  deleteDocument,
} from "../../redux/originator/originatorSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showErrorAlertAction } from "../../redux/app/appSlice";

const UploadDocuments = ({ docName, value, index }) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  let dispatch = useDispatch();
  let originatorState = useSelector((state) => state.originator);

  const onDrop = useCallback((acceptedFiles) => {
    let formData = new FormData();

    formData.set("file", acceptedFiles[0]);
    console.log(acceptedFiles);
    if (acceptedFiles[0] === undefined) {
      dispatch(showErrorAlertAction("Please select a supported format file"));
      return;
    }

    setLoading(true);
    dispatch(
      uploadDocuments({
        formData: formData.getAll("file")[0],
        fileName: docName,
      })
    );

    setState(acceptedFiles[0]);
  }, []);

  console.log("originatorloasuingg", originatorState["loading"]);

  useEffect(() => {
    console.log("in useeffecte");
    setLoading(false);
  }, [originatorState[docName]]);

  console.log({ loading });
  const removeFile = () => {
    setState({});
    dispatch(deleteDocument({ docName, index }));
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      xlsx: [".xlsx"],
      doc: [".doc"],
      pdf: [".pdf"],
      ppt: [".ppt"],
      docx: [".docx"]
    },
  });

  //? useEffect

  const Test = () => {
    return (
      <>
        <input {...getInputProps()} />

        <SelectedFileComponent
          fileName={state[0]["fileName"]}
          onClickHandler={removeFile}
        />
      </>
    );
  };

  console.log({ state });

  const FileComponent =
    state && Object.keys(state).length > 0 ? (
      <Test />
    ) : (
      console.log("state is null")
    );

  return (
    <div>
      {Object.keys(value).length === 0 ? (
        <>
          {loading ? (
            <div className={styles.wrapper}>
              <div className={styles.DetailsWrapper}>
                <div className={styles.together}>
                  <p>Loading...</p>
                </div>
              </div>
            </div>
          ) : (
            <div {...getRootProps()} style={{ cursor: "pointer" }}>
              <ChooseFileComponent />
            </div>
          )}
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          <SelectedFileComponent
            fileName={value["fileName"]}
            onClickHandler={removeFile}
          />
        </>
      )}
    </div>
  );
};

export default UploadDocuments;
