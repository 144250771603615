import React, { useState } from "react";
import TabFooter from "../TabFooter/TabFooter";
import {
  TextField,
  Typography,
  Grid,
  Box,
  Button,
  Stack,
  Divider,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  ListSubheader,
} from "@mui/material";
import Delete from "../../../assets/icons/delete.svg";
import { Colors } from "../../../theme/theme";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import OutlineButton from "../../Buttons/OutlineButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  assignSPOCAction,
  originatorIdsAction,
  pageStatusAction,
} from "../../../redux/originator/originatorSlice";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getOriginatorNames } from "../../../redux/Deals/dealSlice";
import { setStatus } from "../../../redux/Loans/loanSlice";
import styles from "./CreateOriginatorTabs.module.css"
import { istSubheaderStyle, paddingRightStyle, paddingTopStyle, peopleAltIconStyle, typographyStyle } from "./CreateOriginatorTabsCSS";

const AssignSPOCTab = ({ nextTabHandler }) => {
  let originatorState = useSelector((state) => state.originator);

  const [inputList, setinputList] = useState([
    { email: "", name: "", phoneNumber: "" },
  ]);

  const [valueOriginator, setValueOriginator] = useState(
    [{ _id: "", name: "" }] || originatorState["originatorIds"]
  );
  const [status, setstatus] = useState(true);

  let { allOriginatorNames } = useSelector((state) => state.deal);

  let checkNumber = () => {
    let checkNum = inputList.every(
      (list) => list.phoneNumber.toString().length == 10
    );
  };

  const checkStatus = () => {
    for (let i = 0; i < inputList.length; i++) {
      let valuesArray = Object.values(inputList[i]);

      if (valueOriginator[0]._id === "") {
        setstatus(true);
      } else {
        for (let value of valuesArray) {
          let test = valuesArray[2];
          console.log({ value });
          if (value.length == 0) {
            console.log({ valueOriginator: valueOriginator[0]._id });
            setstatus(true);
            break;
          } else {
            console.log({ status });
            setstatus(false);
          }
        }
      }
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    if (name == "email") {
      let emailArray = value.split("");

      if (
        value.includes("@gmail.") ||
        value.includes("@yahoo.") ||
        value.includes("@google.") ||
        value.includes("@hotmail.") ||
        value.includes("@outlook.") ||
        value.includes(" ") ||
        value.includes("&") ||
        value.includes("$") ||
        value.includes("!") ||
        value.includes("#") ||
        value.includes("%") ||
        value.includes("^") ||
        value.includes("*") ||
        emailArray.filter((char) => char == "@").length > 1
      ) {
        console.log({ lengthMatch: value.match(/[@]/g).length });
        dispatch(
          showErrorAlertAction("Email Field should contain company domain")
        );

        return;
      }
    }
    if (name == "phoneNumber") {
      if (value[0] < 6) {
        dispatch(showErrorAlertAction("Please enter valid number"));
        return;
      }
    }

    //? getting object according to index from inputlist state
    let obj = list[index];

    //? creating new object & destructuring old obj with new values
    let updatedObj = {
      ...obj,
      [name]: value,
    };

    //? setting updated object in the list and returning un-updated objects
    let updatedArray = list.map((val, ind) => {
      if (ind == index) {
        return updatedObj;
      }
      return val;
    });
    //? setting updatedArray in inputList
    setinputList(updatedArray);
  };

  const deleteSPOC = (index) => {
    const list = [...inputList];
    list.pop();
    setinputList(list);
    setstatus(false);
  };

  const addSPOC = () => {
    setinputList([...inputList, { email: "", name: "", phoneNumber: "" }]);
    setstatus(true);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
        background: "#EBEEF0",
      },
    },
  };

  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  const nextBtnClickHandler = (e) => {
    let selectedOriginators = valueOriginator.map(
      (originator) => originator._id
    );

    console.log({ selectedOriginators });
    let allEmail = inputList.every((input) =>
      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(
        input.email
      )
    );
    if (!allEmail) {
      dispatch(
        showErrorAlertAction("Email Field should contain company domain")
      );
      return;
    }

    let checkNum = inputList.every(
      (list) => list.phoneNumber.toString().length == 10
    );

    if (!checkNum) {
      dispatch(showErrorAlertAction("Please provide a valid phone number"));
      return;
    }

    e.preventDefault();
    dispatch(originatorIdsAction(selectedOriginators));
    dispatch(assignSPOCAction(inputList));
    dispatch(pageStatusAction({ pageName: "assignSPOC" }));
    nextTabHandler();
  };

  useEffect(() => {
    if (originatorState["spocs"].length > 0) {
      setinputList(originatorState["spocs"]);
    }
  }, [originatorState["spocs"]]);

  useEffect(() => {
    checkStatus();
    checkNumber();
  }, [inputList, valueOriginator]);

  useEffect(() => {
    dispatch(getOriginatorNames());
  }, []);

  const [menuOriginator, setMenuOriginator] = useState(allOriginatorNames);

  console.log({ menuOriginator });

  const [variantName, setVariantName] = React.useState([]);

  const handleChangeOriginator = (onChangeValue, i) => {
    console.log({ onChangeValue });
    console.log({ valueOriginator: onChangeValue.props.value._id });
    let inputdata = [...valueOriginator];
    console.log({ inputdata });
    inputdata[i]["_id"] = onChangeValue.props.value._id;
    inputdata[i]["name"] = onChangeValue.props.value.name;

    setValueOriginator(inputdata);
    console.log({ valueOriginator });
  };

  console.log({ valueOriginator });

  const [filter, setFilter] = useState("");
  let dispatch = useDispatch();
  console.log({ variantName });

  return (
    <>
      <div>
        {inputList.map((x, i) => {
          var index = i + 1;
          console.log("INPUT", inputList);
          return (
            <>
              <div className={styles.main}
                key={i}
              >
                <>
                  <Stack direction="row" spacing={1}>
                    <PeopleAltIcon sx={peopleAltIconStyle}></PeopleAltIcon>
                    <Typography sx={typographyStyle} >
                      SPOC-{index}
                    </Typography>
                  </Stack>

                  <div
                    onClick={deleteSPOC}
                    className={styles.deleteIcn}
                  >
                    {index > 1 ? <img src={Delete}></img> : ""}
                  </div>
                </>
              </div>

              <div className={styles.formWrapper} key={i}>
                <form>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <TextWrapperComponent text="Name">
                        <TextField
                          fullWidth
                          style={paddingRightStyle}
                          size="small"
                          name="name"
                          autoComplete="off"
                          value={x.name}
                          variant="standard"
                          placeholder="Enter Contact's Name"
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </TextWrapperComponent>
                    </Grid>

                    <Grid item xs={4}>
                      <TextWrapperComponent text="Email Address">
                        <TextField
                          fullWidth
                          autoComplete="off"
                          style={paddingRightStyle}
                          size="small"
                          name="email"
                          value={x.email}
                          variant="standard"
                          placeholder="Enter Contact's Email Address"
                          onChange={(e) => handleInputChange(e, i)}
                          onKeyPress={(e) => {
                            if (!/[a-z0-9@.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </TextWrapperComponent>
                    </Grid>
                    <Grid item xs={4}>
                      <TextWrapperComponent text="Mobile Number">
                        <TextField
                          fullWidth
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          min={0}
                          autoComplete="off"
                          style={paddingRightStyle}
                          size="small"
                          name="phoneNumber"
                          value={x.phoneNumber}
                          variant="standard"
                          onChange={(e) => handleInputChange(e, i)}
                          placeholder="Enter Contact's Mobile No."
                        />
                      </TextWrapperComponent>
                    </Grid>
                  </Grid>
                </form>
              </div>

              <Divider />
            </>
          );
        })}
      </div>

      <div className={styles.addNewSpocWrapper}>
        <OutlineButton
          text="Add New SPOC"
          showIcon={true}
          onClickHandler={addSPOC}
        ></OutlineButton>
      </div>

      <div className={styles.originatorListWrapper}>
        <>
          <Stack direction="row" spacing={1}>
            <PeopleAltIcon sx={peopleAltIconStyle}></PeopleAltIcon>
            <Typography
              sx={typographyStyle}
            >
              Originators
            </Typography>
          </Stack>
        </>
      </div>
      <div className={styles.formWrapper} >
        <FormControl sx={{ width: 250 }}>
          {valueOriginator.map((data, i) => {
            return (
              <Box sx={paddingTopStyle} key={i}>
                <FormControl variant="standard" sx={{ width: 350 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueOriginator[i]}
                    displayEmpty
                    IconComponent={null}
                    onChange={(e, n) => handleChangeOriginator(n, i)}
                    MenuProps={MenuProps}
                    renderValue={
                      valueOriginator[i]._id !== ""
                        ? () => valueOriginator[i].name
                        : () => (
                            <Placeholder>Enter Originator's Name</Placeholder>
                          )
                    }
                  >
                    <ListSubheader
                      sx={istSubheaderStyle}
                    >
                      <TextField
                        size="medium"
                        placeholder="Search Originator Names"
                        fullWidth
                        value={filter}
                        sx={{
                          input: {
                            fontFamily: "Nunito",
                            fontSize: "18px",
                            fontWeight: "400",
                            height: "7px",
                            width: "380px",
                          },
                        }}
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {allOriginatorNames
                      .filter((item) =>
                        item.name.toLowerCase().includes(filter.toLowerCase())
                      )
                      .map((approver, index) => {
                        console.log(approver);
                        return (
                          <MenuItem key={index} value={approver}>
                            {approver.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            );
          })}
        </FormControl>
      </div>

      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
    </>
  );
};

export default AssignSPOCTab;
