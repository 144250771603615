import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import _ from "lodash";
import { norecordFoundStyle } from "../TableCSS";

const VoucherTableComp = ({
    rows,
    data,
    totalCreditAmount = "",
    totalDebitAmount = "",
}) => {


    let totalRow = [...data, {
        creditAmount: totalCreditAmount, ledgerDescription: "", debitAmount: totalDebitAmount, ledgerCode: "", transactionFor: ""
    }]

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        textAlign: "center",
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#EBEEF0",
            color: "#5E7387",
            fontSize: "12px",
            fontWeight: "700",
            minWidth: "200px",
            textAlign: "left",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            textAlign: "left",
        },
    }));

    const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#EBEEF0",
            color: "#5E7387",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "left",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            textAlign: "left",
        },
    }));




    return (
        <div className="App">
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {rows &&
                                rows.map((row, index) => {
                                    return <StyledTableCell key={index}>{row}</StyledTableCell>;
                                })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {totalRow &&
                            totalRow.length !== 0 &&
                            totalRow.map((item, index) => {
                                return (
                                    <TableRow key={item.index}>
                                        <StyledTableCell1 component="th" scope="row">
                                            {item?.ledgerCode || ""}
                                        </StyledTableCell1>
                                        <StyledTableCell1 component="th" scope="row">
                                            {item?.ledgerDescription}
                                        </StyledTableCell1>
                                        <StyledTableCell1 component="th" scope="row">
                                            {item?.transactionDate || ""}
                                        </StyledTableCell1>
                                        <StyledTableCell1 component="th" scope="row">
                                            {item?.transactionFor || ""}
                                        </StyledTableCell1> 
                                        <StyledTableCell1 component="th" scope="row">
                                            {item?.creditAmount}
                                        </StyledTableCell1>
                                        <StyledTableCell1 component="th" scope="row">
                                            {item?.debitAmount}
                                        </StyledTableCell1>
 
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {data && data.length === 0 && (
                    <Typography sx={norecordFoundStyle}>
                        No Records Found!!!
                    </Typography>
                )}

            </TableContainer>
        </div>
    );
};

export default VoucherTableComp;
