import React from "react"
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import DescriptionIcon from "@mui/icons-material/Description";
import DealDetails from "./DealDetails";
import LoanDetails from "./LoanDetails";
import Image from "../../assets/images/Group 42722.svg";

const DealDuplicationDetails = () => {
    const breadCrumbs = [
        {
          path: "/dashboard",
          pathName: "Home",
        },
        {
          path: "/dealduplication/deal/list",
          pathName: "Deal List",
        },
        {
          pathName: "P1 Home Loan",
        },
      ];

      let icon = (
        <DescriptionIcon
          sx={{
            color: "#2C4584",
          }}
        />
      );

    return (
        <Layout breadCrumbs={breadCrumbs}>
            <TabsLayout
              formName="P1 Home Loan"
              image={Image}
              icon={icon}
              iconColor={"#DBE3F8"}
              TabsList={[
                {
                  name: "Deal",
                  index: 0,
                  component: <DealDetails />,
                },
                { name: "Loan", index: 1, component: <LoanDetails /> },
              ]}
            ></TabsLayout>
        </Layout>
    )

}

export default DealDuplicationDetails;