import React from "react";
import LabelWithDownload from "../../LabelWithDownload";
import { useSelector } from "react-redux";

export default function EntityDetailComp() {
  const dealState = useSelector((state) => state.deal);

  const entity = dealState?.filterdata?.entityDetail?.file;
  const leReports = dealState?.filterdata?.entityDetail?.leReports;

  // console.log({ entityId });
  return (
    <div>
      <LabelWithDownload
        label={"Entity Detail File"}
        withHeader
        fileId={entity?._id}
        fileName={entity?.filename}
      />
      <LabelWithDownload withHeader label={"Le Reports"} />
      {leReports.map((val, index) => (
        <LabelWithDownload
          label={`LE Report ${index + 1}`}
          fileId={val?._id}
          fileName={val?.filename}
          withPadding
        />
      ))}
    </div>
  );
}
