import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack, Typography } from "@mui/material";
import TabFooter from "../../../components/Phase2/Tabs/TabFooter/TabFooter";
import {
  clearCheckPages,
  clearUploadStateAction,
  pageStatusAction,
  uploadReport,
} from "../../../redux/Phase2/UploadReportSlice";
import { showAlertAction } from "../../../redux/app/appSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import UploadReports from "../../../components/Phase2/UploadReports/UploadReports";
import RedirectToLoginPage from "../../redirect";
import { useState } from "react";
import BasicModal from "../../../components/Modal/Modal";
import { useHistory } from "react-router-dom";
import Paths from "../../../components/constants/Path";
import { ENV } from "../../../API/BaseUrl";
import moment from "moment";
import Styles from "./F1.module.css";
import { descriptionIconStyle, documentHeaderStack, documentHeaderTypographyStyleF1, monthlyCollectionReportSummaryMainBox } from "./F1CSS";

const MonthlyCollectionTab = ({ nextTabHandler }) => {
  const [state, setState] = React.useState(true);
  const [status, setStatus] = useState(true);
  const [count, setCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [partialLoading, setPartialLoading] = React.useState(false);
  const [fileState, setFileState] = useState(null);
  const [uploadType, setUploadType] = useState(false);

  let dispatch = useDispatch();

  let uploadReportState = useSelector((state) => state.uploadReport);

  let { error, success } = useSelector((state) => state.uploadReport);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "monthlyCollectionReport" })); //? FOR GREEN TICK
    setOpen(true);
  };

  useEffect(() => {
    if (fileState != null) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [fileState]);

  console.log({ fileState });

  const DocumentHeader = ({ title }) => {
    return (
      <div className={Styles.documentHeaderWraper}>
        <Stack sx={documentHeaderStack} spacing={1}>
          <DescriptionIcon
            sx={descriptionIconStyle}
          ></DescriptionIcon>
          <Typography
            sx={documentHeaderTypographyStyleF1}
          >
            {title}
          </Typography>
        </Stack>
      </div>
    );
  };
  const current = new Date();
  current.setMonth(current.getMonth() - 2);

  // const previousMonth = current.toLocaleString("default", { month: "long" });
  var previousMonth =
    ENV === "PREPROD" || ENV === "PROD"
      ? moment().subtract(1, "month").startOf("month").format("MMMM")
      : moment().subtract(2, "month").startOf("month").format("MMMM");

  let history = useHistory();

  console.log({ previousMonth });

  const uploadFileHandler = () => {
    setPartialLoading(true);
    let formData = new FormData();
    formData.append("file", fileState[0]);
    dispatch(
      uploadReport({
        formData: formData.getAll("file")[0],
        fileName: "monthlyCollectionReportFile",
        isReUpload: false,
        reUploadId: "",
        dealId: uploadReportState["dealName"],
        month: uploadReportState["collectionMonth"].split("(")[0],
        year: uploadReportState["collectionMonth"].split("(")[1].split(")")[0],
        updateOtherData:
          uploadReportState["uploadType"] == "MIS UPLOAD" ? true : false,
      })
    ).then((res) => {
      console.log({ res });
      dispatch(showAlertAction(res.payload.data.message));
      history.push(Paths.LISTOFREPORTS);
    });
  };

  useEffect(() => {
    return () => {
      dispatch(clearUploadStateAction());
      dispatch(clearCheckPages());
    };
  }, []);

  return (
    <>
      <RedirectToLoginPage />
      <Box sx={monthlyCollectionReportSummaryMainBox}>
        <Grid container>
          <Grid item xs={12}>
            <DocumentHeader title="Monthly Collection Report"></DocumentHeader>
            <div className={Styles.uploadMonthlyCollectionReportSummaryMainBox}>
              <UploadReports
                docName="monthlyCollectionReportFile"
                setdata={setFileState}
                data={fileState}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <div className={Styles.tabFooterDiv}>
        <TabFooter
          onclickHandler={nextBtnClickHandler}
          isBtnDisable={status}
          showSubmitButton={true}
        />
        <BasicModal
          open={open}
          setOpen={setOpen}
          title="Do you want to Upload this File?"
          positiveText={partialLoading ? "Loading...." : "Yes"}
          negativeText="No"
          positiveOnClickHandler={uploadFileHandler}
          setloading={setPartialLoading}
          isDisable={partialLoading}
        ></BasicModal>
      </div>
    </>
  );
};

export default MonthlyCollectionTab;
