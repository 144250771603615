import React from "react";
import {
  Box,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import ReportsTable from "../../../components/Table/Phase2/ReportsTable";
import { getReportList } from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import { useSelector } from "react-redux";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import { showAlertAction, showErrorAlertAction } from "../../../redux/app/appSlice";
import Loader from "./../../../utils/Loader";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const ListOfReports = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("ALL")
  const [totalPageNumber, setTotalPageNumber] = useState("")
  const [sortedList, setSortedList] = useState([]);
  const [reportList, setReportsList] = useState("")

  //? useDispatch
  let dispatch = useDispatch();

  //? useSelector for getting the data from the store
  let { loading,  totalPages, error, success } = useSelector(
    (state) => state.uploadReport
  );
  

  //Using debounce Hook for search
  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    setFilterName(name);
  };

  //Useffect for getting the list of reports
  useEffect(() => {
    dispatch(getReportList({ limit, currentPage, debouncedSearch, entity: filterName })).then(
      (res) => {
        setReportsList(res?.payload)
        setTotalPageNumber(res?.payload?.totalPages)
      }
    );
  }, [currentPage, debouncedSearch, filterName]);
  

  const filterList = [
    {
      id: "0",
      name: "ALL",
    },
    {
      id: "1",
      name: "DHFL",
    },

    {
      id: "2",
      name: "PEL",
    },
    {
      id: "3",
      name: "PCHFL",
    },

  ];


  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage  == totalPageNumber) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"))
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  // Previous Page Handler Function for Pagination ends here

  let history = useHistory();

  //Breadcrumb Navigation
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Reports",
    },
  ];

  //user data from local storage
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
      >
        <>
          {/* <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          > */}
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="space-between"
              paddingBottom="12px"
              display="flex"
            >
              <Typography fontSize="16px" fontWeight="700" paddingBottom={1}>
                List of Reports
              </Typography>
            </Stack>

            <Divider></Divider>

            <div
               style={{
                padding: "16px",
                justifyContent: "space-between",
                alignItems: "space-between",
                display: "flex",
              }}
            >

<div>
              {filterList.map((item, index) => {
                console.log(item, index);
                return (
                  <>
                    <StyledButton
                      id={item.id}
                      variant={
                        filterName === item.name ? "contained" : "outlined"
                      }
                      sx={{
                        color: filterName === item.name ? "white" : "black",
                        backgroundColor:
                          filterName === item.name ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item.name)}
                    >
                      {item.name}
                    </StyledButton>
                  </>
                );
              })}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            </div>
          {/* </div> */}

          {loading ? (
            <Loader/>
          ) : (
            <Box>
              {/* Report Table Component */}
              <ReportsTable
                data={reportList?.data}
                page={reportList?.page}
                header={
                      [
                        "Deal Name",
                        "Month",
                        "Upload Date",
                        "Uploaded By",
                        "Approval Status",
                        "Upload Status",
                        "Upload Type",
                        "Accounting Entry",
                        "Action",
                      ]
                }
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={totalPageNumber}
              ></ReportsTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default ListOfReports;
