import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import Tabs from "../../components/Tabs/Tabs";
import Image from "../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveLinkApprovedPool from "../../components/Tabs/AuditorKYCApproveRejectTabs/DriveLinkApprovedPool";
import VerificationReport from "../../components/Tabs/AuditorKYCApproveRejectTabs/VerificationReport";
import { clearDealState, getSingleDealDetails } from "../../redux/Deals/dealSlice";
import { useParams, useHistory } from "react-router-dom";

const AuditorKYCApproveReject = () => {
  let dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  const history = useHistory();
  const dealInfo = history.location.state;
  let KYCApproveRejectState = useSelector(
    (state) => state.auditorKYCApproveReject
  );
  console.log("viewdealid", dealInfo)
  const params = useParams();
  let { dealId } = params;

  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDealState());
    };
  }, []);

  const tabs = [
    {
      tabName: "KYC Drive Link & Approved Pool",
      comp: DriveLinkApprovedPool,
      heading: `KYC Drive Link & Approved Pool for - ${dealInfo?.name}`,
    },
    {
      tabName: "KYC Verification Report",
      comp: VerificationReport,
      heading: `KYC Verification Report for - ${dealInfo?.name}`,
    },
  ];

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      path: "/auditordealist",
      pathName: "Deals",
    },
    {
      pathName: "Create New Deal",
    },
  ];

  let TabsList = [
    {
      name: "Loan",
      component: (
        <Tabs
          tabs={tabs}
          status={KYCApproveRejectState["checkedPages"]}
          breadCrumbs={breadCrumbs}
          isAuditorTab={true}
        />
      ),
      index: 0,
    },
    {
      name: "Queries",
      component: <QueriesPage dealIdorOriginatorId={params?.dealId} currentStage={dealState?.filterdata?.currentStage || ""} />,
      index: 1,
    },
    {
      name: "History",
      component: <Histories id={params.dealId} />,
      index: 2,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        TabsList={TabsList}
        formName={dealInfo?.name}
        icon={icon}
        iconColor={"#DBE3F8"}
        image={Image}
      />
    </Layout>
  );
};

export default AuditorKYCApproveReject;
