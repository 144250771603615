import {Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../Buttons/PrimaryButton";
import ManIcon from "../../../assets/images/Man.png";
import { showAlertAction } from "../../../redux/app/appSlice";
import { postRequest } from "../../../API/apiHandler";
import styles from "./SecondaryCheckTabs.module.css"
import { descWrapper, headingWrapper, stackWrapper } from "./SecondaryCheckTabsCSS";

const CreditBureauScrubReport = () => {
  let dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const onClickHandler = async () => {
    await postRequest("/utility-service/notification", {
      type: "FRONT-END NOTIFICATION",
      dealId: dealState?.filterdata?._id,
      event: "CREDIT_SCRUB_BUREAU_REQUEST",
    });
    dispatch(showAlertAction("Request Send Successfully"));
  };

  return (
    <>
      <div className={styles.main}>
        <div>
          <Stack sx={stackWrapper}>
            <img src={ManIcon} width="150px" height="150px" alt="manIcon"></img>
            <Typography
              sx={headingWrapper}
              paddingTop={1}
            >
              Request for Credit Bureau Scrub Report
            </Typography>
            <Typography
              sx={descWrapper}
              padding={1}
            >
              You can request for it here
            </Typography>
{userData?.roles.length != 1 ? (
  <>
  </>
):(
 <>
 <PrimaryButton
   isDisable={
     dealState?.filterdata?.poolUploadFinal?.poolStatus ===
     "APPROVED"
       ? true
       : false
   }
   text="Request Credit Bureau Scrub Report"
   onClickHandler={onClickHandler}
   width="384px"
 ></PrimaryButton>
 </>
)}
          </Stack>
        </div>
      </div>
    </>
  );
};

export default CreditBureauScrubReport;
