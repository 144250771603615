import React, { useEffect } from "react";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import {
  Box,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import OriginatorTable from "../../../components/Table/SortedOriginatorTable";
import { useState } from "react";
import { Button } from "@mui/material";
import SecoundaryButton from "../../../components/Buttons/SecoundaryButton";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import CircularProgress from "@mui/material/CircularProgress";

//? redux
import { getOriginatorList } from "../../../redux/originator/originatorSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../Hooks/useDebounce";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import Paths from "../../../components/constants/Path";
import searchIcon from "../../../assets/icons/searchIcon.svg";

const L2OriginatorsList = () => {
  //? useSelector
  let { loading, originatorsList, totalPages, pageNumber, pageLimit, error } =
    useSelector((state) => state.originator);
  console.log({ originatorsList });

  // useState ??
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(pageNumber || 1);
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("All");

  let userData = JSON.parse(localStorage.getItem("UserData"));

  //? useHistory
  let history = useHistory();

  //? useDispatch
  let dispatch = useDispatch();

  if (error) {
    dispatch(showErrorAlertAction("something went wrong"));
  }

  //? for breadCrumbs component
  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Originator Profiles",
    },
  ];

  const filterList = ["All", "Approved", "On Hold", "Pending", "Rejected"];

  const filterHandler = (status) => {
    setFilterName(status);
  };

  //? useEffect

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);

  const debouncedSearch = useDebounce(search, 1000);
  console.log({ debouncedSearch });

  useEffect(() => {
    dispatch(
      getOriginatorList({ limit, currentPage, debouncedSearch, filterName })
    );
  }, [currentPage, filterName, debouncedSearch]);

  const nextPageHandler = () => {
    if (currentPage == totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const onClickHandlerToCreateOriginator = () => {
    return history.push("/create/originator");
  };

  const viewActionClickHandler = (id) => {
    return history.push(`/view/${id}/l2originatorsdetails`);
  };

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Originators
            </Typography>
          </Stack>

          <Divider />
          <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          >
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item ? "white" : "black",
                        backgroundColor:
                          filterName === item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>

            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                    {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {loading ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
            </div>
          ) : (
            <Box style={{ height: "calc( 100% - 64px)" }}>
              <OriginatorTable
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                page={currentPage}
                totalPages={totalPages}
                limit={limit}
                rows={[
                  "Originator Name",
                  "Originator Type",
                  "Creation Date",
                  "Status",
                  "Action",
                ]}
                data={originatorsList}
                actionOnClick={viewActionClickHandler}
              />
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default L2OriginatorsList;
