import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tabs from "../../../components/Tabs/Tabs";
import {
  clearkycAMLState,
  getSingleAmlUpload,
  pageStatusAction,
} from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import KYCAMLUpload from "../../../components/Tabs/AMLTabs/KYCAMLUpload";
import KYCAMLSummary from "../../../components/Tabs/AMLTabs/KYCAMLSummary";
import { useParams } from "react-router-dom";
import Paths from "../../../components/constants/Path";
import Loader from "../../../utils/Loader";
import { statusConstants } from "../../../components/constants/FiltersConstant";
import { setStatus } from "../../../redux/Loans/loanSlice";

const AMLForm = () => {
  const dealState = useSelector((state) => state.deal);
  const kycAMLRequest = useSelector((state) => state.kycAMLRequest);
  const loanState = useSelector((state) => state.loan);
  const [uploadData, setUploadData] = useState("");
  const [newloader, setNewLoader] = useState(true);

  const params = useParams();
  let { dealId } = params;
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: dealId })).then((res) => {
      setUploadData(res?.payload?.data);
      setNewLoader(false);
    });
  }, []);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  let tabs = [];
if(userData?.roles.length == 1 ){

  if (
    userData?.roles[0] === statusConstants.L1 ||
    userData?.roles[0] == statusConstants.L2 ||
    userData?.roles[0] == statusConstants.F1
  ) {
    tabs.push({
      tabName: "KYC AML Upload",
      comp: KYCAMLUpload,
      heading: `KYC AML Upload for - ${dealState?.name}`,
    });
  }
}

  if (
    uploadData &&
    uploadData?.currentStatus !== statusConstants.PENDING &&
    uploadData?.currentStatus !== statusConstants.FILE_RECEIVED &&
    uploadData?.currentStatus !== statusConstants.IN_PROGRESS
  ) {
    tabs.push({
      tabName: `KYC AML Upload Summary`,
      comp: KYCAMLSummary,
      heading: `KYC AML Upload Summary for - ${dealState?.name}`,
    });
  }

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearkycAMLState());
    };
  }, []);

  useEffect(() => {
    dispatch(setStatus(4));
  }, []);

  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <Tabs
          tabs={tabs}
          status={kycAMLRequest["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      )}
    </>
  );
};

export default AMLForm;
