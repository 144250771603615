import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  FilterConstants,
  lowerConstants,
  statusConstants,
} from "../constants/FiltersConstant"
import styles from "./Table.module.css"
import { actionTypo, actionsIcon, originatorNameStyle, statusTopBox } from "./TableCSS";

const L2LoanDealListTable = ({
  data,
  viewActionClickHandler,
  header,
  statusList,
  page,
  filterName,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  const [dealStatus, setDealStatus] = useState("");

  let sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.DealStatus > b.DealStatus) {
      return -1;
    }
    if (a.DealStatus < b.DealStatus) {
      return 1;
    }
    return 0;
  });
  console.log(sortedData);
  let filterData = data.filter(
    (item) => item.DealStatus === FilterConstants.PENDING
  );

  let newData = [...filterData, ...sortedData];
  let finalData = _.uniqBy(newData, (e) => e.id);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  }));

  const StatusContainer = ({ statusType }) => {
    console.log({ statusType });
    const Textcolor =
      statusType === statusConstants.APPROVED ||
      statusType === lowerConstants.CREATED
        ? "#277742"
        : statusType === statusConstants.REJECTED
        ? "#8D3431"
        : statusType === statusConstants.CLOSED
        ? "#2C4584"
        : "#996E37";

    const boxBackgroudColor =
      statusType === statusConstants.APPROVED ||
      statusType === lowerConstants.CREATED
        ? "#E3F6E9"
        : statusType === statusConstants.REJECTED
        ? "#FCE6E5"
        : statusType === statusConstants.CLOSED
        ? "#E4EAFA"
        : "#FFF4E7";

    const newstatus =
      statusType === statusConstants.APPROVED ||
      statusType === lowerConstants.CREATED
        ? "Added"
        : "Not Added";

    return (
      <Box
        sx={statusTopBox}
        style={{color: Textcolor}}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {newstatus
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const Actions = ({ label = "View", Icon, item }) => {
    // label = "View"
    return (
      <div onClick={() => viewActionClickHandler(item)}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Icon sx={actionsIcon}></Icon>
          <Typography sx={actionTypo}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };
  let userData = JSON.parse(localStorage.getItem("UserData"));

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item?.id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item?.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell component="th" scope="row">
                    {item?.loanType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.requestedDealAmount}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(item?.createdAt).format("D MMM YYYY")}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <StatusContainer
                      statusType={item?.poolUploadFinal?.poolStatus}
                    ></StatusContainer>
                  </StyledTableCell>

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        Icon={VisibilityIcon}
                        dealId={item.id}
                        item={item}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        Page={page}
        TotalNoNumber={totalRecords}
        nextPageHandler={page === totalRecords ? null : nextPageHandler}
        prevPageHandler={prevPageHandler}
      ></Pagination>
    </div>
  );
};

export default L2LoanDealListTable;
