import React from "react";
import styles from "./EntityDetailsDownload.module.css";
import Download from "../../../assets/icons/download.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { downloadImageReports } from "../../../redux/Phase2/SearchReports";
import { getFileSize } from "../../../utils/helper";

const EntityDetailsDownload = ({ fileName, id, size }) => {
  const dealState = useSelector((state) => state.deal);

  // let id = dealState?.loanUploadTemplate?.fileData?._id
  const dispatch = useDispatch();

  const downloadExcel = ({ id, fileName }) => {
    dispatch(downloadImageReports({ id, fileName }));
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.filenameWrapper}>
          <CheckCircleIcon
            sx={{
              color: "#41C66E",
              paddingLeft: "8px",
            }}
          ></CheckCircleIcon>
          <p>{fileName}</p>
        </div>

        <div>
          <div onClick={() => downloadExcel({ id, fileName })}>
            <img
              className={styles.singleIconWrapper}
              src={Download}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className={styles.break}>{getFileSize(size)}</div>
      </div>
    </>
  );
};

export default EntityDetailsDownload;
