import { FormControlLabel, Grid, Radio, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabFooter from "../../TabFooter/TabFooter";

import { RadioGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  createLoanFilters,
  pageStatusAction,
  setLoanTenure,
} from "../../../../redux/Loans/loanSlice";
import { showAlertAction } from "../../../../redux/app/appSlice";
import TextWrapperComponent from "../../../FormComponents/TextWrapperComponent";
import Paths from "../../../constants/Path";

const LoanTenureFilters = ({ nextTabHandler }) => {
  const loanState = useSelector((state) => state.loan);

  const assetType = useSelector((state) =>
    state.deal?.loanTypeData?.name.toString().toLowerCase().trim()
  );
  const history = useHistory();

  // managing state for tenure filters
  const [tenure, setTenure] = useState({
    completedTenure: loanState?.completedTenure,
    remainingTenure: loanState?.remainingTenure,
    totalTenure: loanState?.totalTenure,
    maxTotalDpd: loanState?.maxTotalDpd,
    max12MonthDpd: loanState?.max12MonthDpd,
    max24MonthDpd: loanState?.max24MonthDpd,
    isRestructured: loanState?.isRestructured,
  });

  // managing state for status of button
  const [status, setStatus] = useState(true);

  let dispatch = useDispatch();

  useEffect(() => {
    const requiredFields =
      assetType === "microfinance loan"
        ? ["isRestructured", "completedTenure"]
        : [
            "completedTenure",
            "remainingTenure",
            "totalTenure",
            "maxTotalDpd",
            "max12MonthDpd",
            "max24MonthDpd",
            "isRestructured",
          ];
    const isValid =
      requiredFields.every(
        (field) =>
          tenure[field] !== undefined &&
          tenure[field] !== "" &&
          tenure[field] !== null
      ) &&
      (assetType !== "microfinance loan" || tenure.isRestructured !== "");

    setStatus(!isValid);
  }, [tenure, assetType]);

  const handleInputChange = (name) => (e) => {
    const value = e.target.value;
    setTenure((prevTenure) => ({ ...prevTenure, [name]: value }));
  };
  const params = useParams();
  let { dealId } = params;

  const nextBtnClickHandler = (e) => {
    e.preventDefault();

    if (assetType === "microfinance loan") {
      dispatch(createLoanFilters({ ...loanState, ...tenure, dealId })).then(
        (res) => {
          dispatch(showAlertAction(res.payload.data.message));
          history.push(Paths.DEALS_LIST);
        }
      );
    } else {
      dispatch(pageStatusAction({ pageName: "loanTenureFilters" }));
      dispatch(setLoanTenure({ ...tenure }));
      nextTabHandler();
    }
  };

  const formData = [
    {
      label: "Seasoning / MHP",
      value: tenure.completedTenure,
      name: "completedTenure",
      type: "number",
      visible: true,
    },
    {
      label: "Balance Tenure (in months)",
      value: tenure.remainingTenure,
      name: "remainingTenure",
      type: "number",
      visible: true,
    },
    {
      label: "Total Tenure (in months)",
      value: tenure.totalTenure,
      name: "totalTenure",
      type: "number",
      visible: true,
    },
    {
      label: "Max Lifetime DPD",
      value: tenure.maxTotalDpd,
      name: "maxTotalDpd",
      type: "number",
      visible: assetType === "microfinance loan" ? false : true,
    },
    {
      label: "Max lifetime dpd -12 months",
      value: tenure.max12MonthDpd,
      name: "max12MonthDpd",
      type: "number",
      visible: assetType === "microfinance loan" ? false : true,
    },
    {
      label: "Max lifetime dpd -24 months",
      value: tenure.max24MonthDpd,
      name: "max24MonthDpd",
      type: "number",
      visible: assetType === "microfinance loan" ? false : true,
    },
  ];

  return (
    <>
      <div>
        <div style={{ padding: "20px" }}>
          <form>
            <Grid container spacing={4}>
              {formData.map((item, index) => (
                <>
                  {item?.visible && (
                    <Grid item xs={4}>
                      <TextWrapperComponent text={item?.label}>
                        <TextField
                          size="small"
                          autoComplete="off"
                          variant="standard"
                          type={item?.type}
                          value={item?.value}
                          onChange={handleInputChange(item?.name)}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 7);
                          }}
                          min={0}
                          fullWidth
                          placeholder={`Enter ${item?.label}`}
                        />
                      </TextWrapperComponent>
                    </Grid>
                  )}
                </>
              ))}
              <Grid item xs={4}>
                <TextWrapperComponent text="Restructured">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tenure?.isRestructured}
                    onChange={handleInputChange("isRestructured")}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </TextWrapperComponent>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
    </>
  );
};

export default LoanTenureFilters;
