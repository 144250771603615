import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { Colors } from "../../theme/theme";
import AddIcon from "../../assets/icons/AddIcon.svg";
import { boxStyle, stackStyle, typographyStyle } from "./ButtonsCSS";

const OutlineButton = ({
  onClickHandler,
  text,
  showIcon = true,
  isDisable = false,
}) => {
  const box = boxStyle(isDisable);
  const typo = typographyStyle(isDisable);
  return (
    <Box
      border={1}
      sx={{
        ...box,
        marginLeft: 1,
      }}
    >
      <div onClick={onClickHandler} style={{ cursor: "pointer" }}>
        <Stack sx={stackStyle}>
          {showIcon ? <img src={AddIcon} sx={{ color: "white" }}></img> : ""}
          {showIcon ? <Box paddingLeft={1}></Box> : ""}

          <Typography sx={typo}>{text}</Typography>
        </Stack>
      </div>
    </Box>
  );
};

export default OutlineButton;
