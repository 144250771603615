import React, { useState, useEffect } from "react";
import TabFooter from "../../TabFooter/TabFooter";
import Paths from "../../../constants/Path";
import { useHistory } from "react-router-dom";
import {
  assignSpocs,
  clearDealState,
  createDeal,
  pageStatusAction,
  getApprovers,
  clearErrorAndSuccessMessage,
  getOriginatorNames,
} from "../../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../../redux/app/appSlice";
import AssignSpocForm from "./AssignSpocForm/AssignSpocForm";

import { getOriginatorSpocs } from "../../../../redux/Deals/dealSlice";
import { getOriginatorDetails } from "../../../../redux/originator/originatorSlice";

const AssignSPOCTab = ({ nextTabHandler }) => {
  let dealState = useSelector((state) => state.deal);
  console.log({ dealState });

  const [open, setOpen] = useState(false);

  const [list, setList] = useState([]);

  const [status, setStatus] = useState(true);
  const [filter, setFilter] = useState("");
  const clickHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (dealState.error) {
      dispatch(showErrorAlertAction(dealState.error));
    }
    setTimeout(() => {
      dispatch(clearErrorAndSuccessMessage());
    });
  }, [dealState.error]);

  const [approversIds, setApproversIds] = useState([]);

  const [originatorIds, setOriginatorIds] = useState([]);
  // useEffect should be called whenever allspoclist changes not dealState changes it reflacts changes here whenever a single value of
  // deal getting changes and it also renders page again and again
  // useEffect(() => {
  //   setList(dealState.allSpocsList);
  // }, [dealState]); dealState.allSpocsList

  useEffect(() => {
    setList(dealState.allSpocsList);
  }, [dealState.allSpocsList]);

  let { name, originator, allApprovers, allOriginatorNames, error } =
    useSelector((state) => state.deal);
  let { originatorName } = useSelector((state) => state.originator);

  console.log({ allOriginatorNames });

  useEffect(() => {
    console.log("list in useeffect");
    dispatch(getOriginatorSpocs(originator));
    dispatch(getOriginatorDetails(originator));
  }, []);

  useEffect(() => {
    dispatch(getApprovers());
  }, []);

  useEffect(() => {
    dispatch(getOriginatorNames(originator));
  }, []);

  useEffect(() => {
    let selectedContacts = list.filter((contact) => contact.checked === true);
    let selectedIds = selectedContacts.map((contact) => contact.id);
    dispatch(assignSpocs({ contacts: selectedIds }));
    if (
      selectedContacts.length > 0 &&
      approversIds.length > 0 &&
      approversIds[0]._id != ""
    ) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [list, approversIds]);

  //? useHistory
  let history = useHistory();
  let dispatch = useDispatch();

  const onClickHandlerToOriginatorList = () => {
    let selectedContacts = list.filter((contact) => contact.checked === true);
    let selectedApprovers = approversIds.map((approver) => approver._id);
    let selectedOriginators = originatorIds.map((originator) => originator._id);
    console.log({ selectedOriginators });
    console.log({ selectedApprovers });
    let selectedIds = selectedContacts.map((contact) => contact.id);
    dispatch(pageStatusAction({ pageName: "assignSPOCs" }));
    // return history.push(Paths.DEALS_LIST);
    dispatch(assignSpocs({ contacts: selectedIds }));
    dispatch(
      createDeal({
        ...dealState,
        approvers: selectedApprovers,
        originatorId: dealState.originatorId,
      })
    )
      .unwrap()
      .then((res) => {
        console.log({ res });
        if (res.status === 201) {
          history.push(Paths.DEALS_LIST);
          dispatch(showAlertAction("Deal Created Successfully"));
          dispatch(clearDealState());
        }
      });
  };

  const [checkAll, setCheckAll] = useState(false);
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    checkAll ? deselectAll() : selectAll();
  };

  const deselectAll = () => {
    let checkedItems = list.map((data) => {
      return { ...data, checked: false };
    });
    setList(checkedItems);
  };

  const selectAll = () => {
    let checkedItems = list.map((data) => {
      return { ...data, checked: true };
    });
    setList(checkedItems);
  };

  const handleSearchChange = (value) => {
    console.log(value);
    console.log({ listing: list });
    console.log(" function called", value);
    setFilter(value);
    // filterData(value);
  };

  const filterData = (value) => {
    const excludeColumns = ["id"];
    const lowercasedValue = value.toLowerCase().trim();
    console.log("lowercasedValue", lowercasedValue);

    if (lowercasedValue.length < 1) {
      console.log("if called");
      setList(dealState.allSpocsList);
      return;
    } else {
      console.log("else called");
      console.log({ list });
      const filteredData = dealState.allSpocsList.filter((item) => {
        let newItem = { name: item?.name, email: item?.email };
        return Object.values(newItem)
          .join("")
          .toLowerCase()
          .includes(lowercasedValue);
      });

      let listData = filteredData.map((item) => {
        let matchingData = list.find((listItem) => listItem.id === item.id);
        if (matchingData) {
          return matchingData;
        }
        return item;
      });

      console.log({ listData });

      setList(listData);
    }
  };
  console.log("list in asssisiisis", list);
  console.log("list in approversId's", approversIds);

  function getFilterData() {
    return list.filter((item) => {
      let newItem = { name: item?.name, email: item?.email };
      return Object.values(newItem)
        .join("")
        .toLowerCase()
        .includes(filter.toLowerCase());
    });
  }

  function getBtnStatus() {
    return list.some((item) => item.checked === true);
  }
  return (
    <div>
      <AssignSpocForm
        handleCheckAll={handleCheckAll}
        clickHandler={clickHandler}
        open={open}
        setOpen={setOpen}
        handleSearchChange={handleSearchChange}
        list={getFilterData()}
        allItems={list}
        setList={setList}
        allApprovers={allApprovers}
        allOriginatorNames={allOriginatorNames}
        setApproversIds={setApproversIds}
        setOriginatorIds={setOriginatorIds}
        originatorName={originatorName}
      />
      <TabFooter
        onclickHandler={onClickHandlerToOriginatorList}
        showSubmitButton={true}
        isBtnDisable={!getBtnStatus()}
      />
    </div>
  );
};

export default AssignSPOCTab;
