import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

export const getEntityTemplate = createAsyncThunk(
  "getEntityTemplate",
  async ({ type }, rejectWithValue) => {
    try {
      const response = await instance.get(
        `/master-service/getTemplate?type=${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );

      // console.log({ originatorResponse });
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setEntityFile = createAsyncThunk(
  "setEntityFile",
  async ({ file, leReports = [], dealId }, { rejectWithValue }) => {
    let entityData = JSON.stringify({
      file,
      leReports,
      dealId,
    });
    try {
      const data = await instance.post(
        `/portfolio-service/entity-detail`,
        entityData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const setInvestmentMemo = createAsyncThunk(
  "setInvestmentMemo",
  async ({ file, dealId }, { rejectWithValue }) => {
    let entityData = JSON.stringify({
      file,
      dealId,
    });
    try {
      const data = await instance.post(
        `/portfolio-service/investment-memo`,
        entityData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  checkedPages: [
    { pageName: "creditBureauScrubReport", isChecked: false },
    { pageName: "CIBILScore", isChecked: false },
    { pageName: "loanValidation", isChecked: false },
  ],
  entitydata: null,
  loading: false,
};

const entitySlice = createSlice({
  name: "entitySlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEntityTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEntityTemplate.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.entitydata = payload;
    });
    builder.addCase(getEntityTemplate.rejected, (state, { payload }) => {
      state.loading = false;
      state.dealList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
    builder.addCase(setInvestmentMemo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setInvestmentMemo.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
    });
    builder.addCase(setInvestmentMemo.rejected, (state, { payload }) => {
      state.loading = false;
      state.dealList = [];
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
  },
});

export const { pageStatusAction } = entitySlice.actions;

export const entitySliceReducer = entitySlice.reducer;
