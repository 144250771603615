import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import UploadTemplate from "../../../components/Wrapper/UploadTemplate";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import Paths from "../../../components/constants/Path";
import {
  clearPageStatusAction,
  submitKYCVerificationReport,
} from "../../../redux/Auditor/kycApproveRejectSlice";
import {
  getAllDeals,
  getSingleDealDetails,
} from "../../../redux/Deals/dealSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { deleteDoc } from "../../../redux/originator/originatorSlice";
import { Colors } from "../../../theme/theme";
import Loader from "../../../utils/Loader";

const AuditorLoanUploadSummaryDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const poolState = useSelector((state) => state.poolUpload);
  const [open, setOpen] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [data, setData] = useState(null);
  const [state, setState] = useState(null);
  const [loanFile, setLoanFile] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  let loading = dealState?.loading;

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const onClickHandler = (e) => {
    e.preventDefault();
    if (dealState?.filterdata?.poolUploadFinal?.kycVerificationRejectedFile) {
      if (loanFile == undefined) {
        dispatch(
          showErrorAlertAction(
            "Please Upload Corrected KYC Verification Report !"
          )
        );
        setOpen(false);
        return;
      }
    }
    if (
      dealState?.filterdata?.poolUploadFinal?.kycVerificationSuccessFile &&
      !dealState?.filterdata?.poolUploadFinal?.kycVerificationRejectedFile
    ) {
      dispatch(showAlertAction("Your file is Uploaded!"));
      history.push("/auditordealist");
      return;
    }
    dispatch(
      submitKYCVerificationReport({
        file: loanFile,
        uniqueCode: dealState?.filterdata?.uniqueCode,
      })
    ).then((res) => {
      dispatch(showAlertAction(res.payload.data.message));
      dispatch(deleteDoc("correctKYCloanUpload"));
      dispatch(clearPageStatusAction());
      dispatch(
        getAllDeals({
          limit: 10,
          currentPage: 1,
          isKycUploded: true,
          isCreditBureauCheckDone: true,
          isSecondaryCheckDone: true,
          filterName: "APPROVED",
          source: "PHFL",
        })
      );
      setOpen(false);
      dispatch(showAlertAction("Your file is getting Uploaded!"));
      history.push(Paths.AUDITORDEALIST);
    });
    history.push("/auditordealist");
  };

  const showFileInputHandler = () => {
    setShowFileInput(true);
  };

  const handleClose = () => {
    setOpen(false);
    showFileInputHandler();
  };

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  // useEffect(() => {
  //   if (poolState?.uniqueCode !== undefined && poolState?.uniqueCode !== null) {
  //     dispatch(
  //       getSingleUploadPoolLoanDetails({ uniqueCode: poolState?.uniqueCode })
  //     );
  //   }
  // }, [poolState?.uniqueCode]);

  const history = useHistory();

  return (
    <>
      {loading && loading ? (
        <Loader />
      ) : (
        <>
          <WrapperComponent title="KYC Verification Report">
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      padding: "14px",
                    }}
                  >
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      sx={{ color: "#5E7387" }}
                    >
                      KYC Verification Report
                    </Typography>
                  </div>
                  <div style={{ padding: "5px", width: "auto" }}>
                    <ViewDownloadDeleteComp
                      fileName={
                        dealState?.filterdata?.poolUploadFinal
                          ?.kycVerificationUploadedFile?.filename
                      }
                      id={
                        dealState?.filterdata?.poolUploadFinal
                          ?.kycVerificationUploadedFile?._id
                      }
                    // reportData={poolState?.primaryRejectedFileData?.fileUrl}
                    ></ViewDownloadDeleteComp>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </WrapperComponent>
          {/* {
            poolState?.primaryRejectedRecords > 0 && ( */}
          {dealState?.filterdata?.poolUploadFinal
            ?.kycVerificationRejectedFile && (
              <WrapperComponent title="Error Log">
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12}>
                      <div
                        style={{
                          padding: "14px",
                        }}
                      >
                        <Typography
                          fontWeight="500"
                          fontSize="14px"
                          sx={{ color: "#5E7387" }}
                        >
                          Error Log
                        </Typography>
                      </div>
                      <div style={{ padding: "5px", width: "auto" }}>
                        <ViewDownloadDeleteComp
                          fileName={
                            dealState?.filterdata?.poolUploadFinal
                              ?.kycVerificationRejectedFile?.filename
                          }
                          id={
                            dealState?.filterdata?.poolUploadFinal
                              ?.kycVerificationRejectedFile?._id
                          }
                        // reportData={poolState?.primaryRejectedFileData?.fileUrl}
                        ></ViewDownloadDeleteComp>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </WrapperComponent>
            )}

          {/* )
          } */}

          {userData?.roles.length == 1 ? (
            <>
              {/* {showFileInput && ( */}
              {dealState?.filterdata?.poolUploadFinal
                ?.kycVerificationRejectedFile && (
                  <WrapperComponent title="Upload Corrected KYC Verification Report">
                    <div
                      style={{
                        padding: "14px",
                      }}
                    >
                      <div style={{ padding: "10px" }}>
                        <UploadTemplate
                          docName="correctKYCloanUpload"
                          setdata={setState}
                          setLoanFile={setLoanFile}
                        />
                      </div>
                    </div>
                  </WrapperComponent>
                )}

              {/* // )} */}

              <div style={{ padding: "10px" }}></div>
              <Box
                sx={{
                  background: "white",
                  boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Stack
                  padding={2}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  display="flex"
                >
                  <Button
                    endIcon={<ArrowForwardIosIcon />}
                    variant="contained"
                    onClick={onClickHandler}
                    disabled={
                      dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                        "APPROVED"
                        ? true
                        : false
                    }
                    sx={{
                      backgroundColor: Colors.piramal_orange,
                      color: Colors.white,

                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: Colors.piramal_orange,
                      },
                      "&:disabled": {
                        backgroundColor: "#FAC3B3",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    Submit Pool
                  </Button>
                </Stack>
              </Box>
            </>
          ) : (<></>)}



          {/* {showFileInput ? (
            <BasicModal
              open={open}
              setOpen={setOpen}
              title="Do you want to Upload this File?"
              positiveText="Yes"
              negativeText="No"
              positiveOnClickHandler={handlePositiveBtn}
              negativeOnClickHandler={handleClose}
            ></BasicModal>
          ) : ( */}

          {/* <BasicModal
              open={open}
              setOpen={setOpen}
              title="What would you like to do?"
              positiveText="Re-upload Entire Pool"
              negativeText="Continue with Approved Pools"
              negativeOnClickHandler={handleContinueWithApprovedPools}
              positiveOnClickHandler={handleClose}
            // setloading={setMsgLoading}
            ></BasicModal> */}

          {/* )} */}
        </>
      )}
    </>
  );
};

export default AuditorLoanUploadSummaryDetails;
