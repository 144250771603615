import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ViewDownloadDeleteComp from "../components/L2/Phase2DownloadComp";
import Layout from "../components/Layout/TabsLayout/Phase2Layout/Layout";
import { Box, Grid, Typography } from "@mui/material";
import WrapperComponent from "../components/Wrapper/WrapperComponent";

export default function DownloadReport() {
  const params = useParams();

  const fileId = params.fileId;
  const extension = ".xlsx";
  const reportName = params?.reportName ?? "REPORT";

  return (
    <Layout>
      <WrapperComponent title="Report">
        <>
          <div
            style={{
              paddingTop: "14px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={13}>
                <Typography fontSize="14px" fontWeight="400" color="#808080">
                  Report
                </Typography>
                <Typography fontSize="14px" fontWeight="400" paddingTop="10px">
                  <ViewDownloadDeleteComp
                    fileName={reportName + extension}
                    id={fileId}
                  />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      </WrapperComponent>
    </Layout>
  );
}
