import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ChooseFileComponent, SelectedFileComponent } from "./UploadComponents";
import styles from "./Wrapper.module.css";
import { useDispatch } from "react-redux";
import { uploadDocuments } from "../../redux/originator/originatorSlice";
import Loader from "./../../utils/Loader";

const UploadMultipleHandler = ({
  setLoanFile,
  setIds,
  ids,
  setdata,
  docName,
  isdisabled = false,
  loanId,
  dealId,
  index,
}) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // State for tracking upload progress
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsUploading(true); // Start uploading, show loader
      let formData = new FormData();
      formData.set("file", acceptedFiles[0]);
      dispatch(
        uploadDocuments({
          formData: formData.getAll("file")[0],
          fileName: docName,
        })
      )
        .then((res) => {
          console.log(res.payload.data);
          setIds((data) => [...data, res.payload.data]);
        })
        .finally(() => {
          setIsUploading(false); // Upload finished, hide loader
        });

      setFiles([...files, ...acceptedFiles]);
      setdata(true);
    },
    [files]
  );

  const removeFile = (fileToRemove) => {
    console.log("fileToRemove", fileToRemove);
    console.log("fileToRemove", fileToRemove.name);
    setFiles(files.filter((file) => file !== fileToRemove));
    setIds(ids.filter((file) => file.fileName !== fileToRemove.name));
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: true,
    onDrop,
    accept: {
      xlsx: [".xlsx"],
      doc: [".doc"],
      pdf: [".pdf"],
      ppt: [".ppt"],
      docx: [".docx"],
    },
  });

  return (
    <div>
      {files.map((file, index) => (
        <div key={index}>
          <input
            {...getInputProps()}
            disabled={isdisabled === true ? true : false}
          />
          {isUploading ? (
            <SelectedFileComponent
              fileName={file.name}
              isLoading={isUploading}
              onClickHandler={() => console.log("clicked")}
            />
          ) : (
            <SelectedFileComponent
              fileName={file.name}
              onClickHandler={() => removeFile(file)}
            />
          )}
        </div>
      ))}
      {files.length === 0 && (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <ChooseFileComponent />
        </div>
      )}
    </div>
  );
};

export default UploadMultipleHandler;
