import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./layout.module.css";
import Header from "../../../../components/Header/Header";
import Sidebar from "../../../Sidebar/Sidebar";
import BreadCrumbWrapper from "../../../BreadCrumbHeader/BreadCrumbHeader";

const Layout = ({ children, breadCrumbs, componentName = null }) => {
  const [screenWidth, setScreenWidth] = useState(null);

  //? useSelector
  let { showSidebar } = useSelector((state) => state.app);

  const getDimension = () => {
    let { innerHeight, innerWidth } = window;

    console.log({ innerHeight, innerWidth });
  };

  useEffect(() => {
    getDimension();
  }, []);

  let userData = JSON.parse(localStorage.getItem("UserData"));
  console.log(userData.roleWisePermission);

  return (
    <div className={styles.LayoutWrapper}>
      <Header userName={userData.name} />
      <div className={styles.flex}>
        <Sidebar />
        <div
          className={
            showSidebar
              ? styles.expandContentWrapper
              : styles.shrinkContentWrapper
          }
        >
          <BreadCrumbWrapper
            breadCrumbs={breadCrumbs}
            componentName={componentName}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
