import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Image from "../../assets/images/Frame.svg";
import Tabs from "../../components/Tabs/Tabs";
import BasicInformationTab from "../../components/Tabs/CreateOriginatorTabs/BasicInformationTab";
import AssignSPOCTab from "../../components/Tabs/CreateOriginatorTabs/AssignSPOCTab";
import KYCDocumentsTab from "../../components/Tabs/CreateOriginatorTabs/KYCDocumentsTab";
import DocumentsTab from "../../components/Tabs/CreateOriginatorTabs/DocumentsTab";
import Layout from "../../components/Layout/Layout";
import TabLayout from "../../components/Layout/TabsLayout/Layout";
import DescriptionIcon from "@mui/icons-material/Description";
import { clearOriginatorState } from "../../redux/originator/originatorSlice";
import { useEffect, useState } from "react";
import Paths from "../../components/constants/Path";
import OtherDetailsTab from "../../components/Tabs/CreateOriginatorTabs/OtherDetailsTab";
import OriginatorDealList from "../Deal/OriginatorDealList";

const OriginatorForm = () => {
  let originatorState = useSelector((state) => state.originator);

  console.log(originatorState, "originatorState");
  useEffect(() => {
    return () => {
      dispatch(clearOriginatorState());
    };
  }, []);

  const tabs = [
    {
      tabName: "Basic Information",
      comp: BasicInformationTab,
      heading: "Originator’s Basic Details",
    },
    {
      tabName: "Other Details",
      comp: OtherDetailsTab,
      heading: "Originator’s Other Details",
    },
    {
      tabName: "Assign SPOC",
      comp: AssignSPOCTab,
      heading: `SPOC for - ${originatorState?.originatorName}`,
    },
    {
      tabName: "KYC Documents",
      comp: KYCDocumentsTab,
      heading: "Upload KYC Documents for New Originator Profile",
    },
    {
      tabName: "Documents",
      comp: DocumentsTab,
      heading: "Upload Documents for New Originator Profile",
    },
  ];

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.CREATE_ORIGINATOR,
      pathName: "Orginator Profile",
    },
    {
      pathName: "Create Originator Profile",
    },
  ];

  //? useDispatch
  let dispatch = useDispatch();

  let TabsList = [
    {
      name: "Profile",
      component: (
        <Tabs
          tabs={tabs}
          status={originatorState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
    {
      name: "Deals",
      component: <OriginatorDealList />,
      isDisabled: !originatorState?.dealRedirection,
      index: 1,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <TabLayout
          TabsList={TabsList}
          formName={"Create Originator Profile"}
          icon={icon}
          iconColor={"#DBE3F8"}
          image={Image}
        />
      </Layout>
    </>
  );
};

export default OriginatorForm;
