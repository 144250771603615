import React, { useState, useEffect } from "react";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import Layout from "../../../components/Layout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import DealDetails from "./DealDetails";
import QueriesPage from "../../../components/Queries/Queries";
import Histories from "../../../components/History/Histories";
import Paths from "../../../components/constants/Path";
import { useParams } from "react-router-dom";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import AMLForm from "../../Phase3/AML/AMLForm";
import DocumentForm from "../../Deal/DocumentForm";
import { getSingleAmlUpload } from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import { statusConstants } from "../../../components/constants/FiltersConstant";

const L2ViewDealDetails = () => {
  const [uploadData, setUploadData] = useState("");
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  
  const dispatch = useDispatch();
  
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      path: Paths.L2DEALLIST,
      pathName: "Deals",
    },
    {
      pathName: dealState?.name,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: params.dealId })).then((res) => {
      setUploadData(res?.payload?.data);
      // setNewLoader(false);
    });
  }, []);

  let Tabs = [
    {
      name: "Deal",
      index: 0,
      component: <DealDetails />,
    },
  ];

  if(userData?.roles.length == 1 ){
    Tabs.push(

      {
        name: "Queries",
        index: 1,
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
      { name: "History", index: 2, component: <Histories id={params.dealId} /> },
    )
  }

  if(userData?.roles.length == 1){
    if (dealState?.filterdata?.dealType !== "SELL") {
      Tabs.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }else{
    if (dealState?.filterdata?.dealType !== "SELL" && 
      uploadData &&
      uploadData?.currentStatus !== statusConstants.PENDING &&
      uploadData?.currentStatus !== statusConstants.FILE_RECEIVED &&
      uploadData?.currentStatus !== statusConstants.IN_PROGRESS ) {
      Tabs.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }

  if (dealState?.filterdata?.status === "CLOSED") {
    Tabs.push({
      name: "Documents",
      index: 3,
      component: <DocumentForm />,
    });
  }

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={Tabs}
      ></TabsLayout>
    </Layout>
  );
};

export default L2ViewDealDetails;
