import styles from "./Histories.module.css";

//? components
import HistoryComp from "./HistoryComp/HistoryComp";
import Filters from "../Filters/FiltersComp";
import Pagination from "../../components/Pagination/Pagination";
import { fetchHistroyData } from "../../redux/Phase2/HistroySlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import OutlineButton from "../Buttons/OutlineButton";
import { showErrorAlertAction } from "../../redux/app/appSlice";
import { setStatus } from "../../redux/Loans/loanSlice";

const Histories = ({ id }) => {
  const [Data, setData] = useState(null);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const dispatch = useDispatch();
  let histroyState = useSelector((state) => state.history);

  console.log({ id });
  const nextPageHandler = () => {
    if (currentPage + 1 == histroyState["totalPages"]) {
      showErrorAlertAction("Max Number Reached!");
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 0) {
      showErrorAlertAction("Min Number Reached!");
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if(userData?.roles[0] === "L1"){
      dispatch(setStatus(2));
    }
    return () =>{
      dispatch(setStatus(0));
    }
  }, []);

  // useEffect(() => {
  //   dispatch(fetchHistroyData({ id, limit, currentPage }));
  // }, []);

  useEffect(() => {
    dispatch(fetchHistroyData({ id, limit, currentPage }));
  }, [currentPage]);

  useEffect(() => {
    dispatch(setStatus(2));
  }, []);

  console.log({ histroyState });

  useEffect(() => {
    if (histroyState["histroyData"] != null) {
      setData(histroyState["histroyData"]);
    } else {
      setData(null);
    }
  }, [histroyState]);

  return (
    <>
      <div className={styles.wrapper}>
        <Filters id={id}></Filters>

        {/* {Data && Data.length == 0 ? null : <Filters id={id} />} */}

        {histroyState["loading"] ? (
          <div className={styles.historyLoader}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
          </div>
        ) : (
          <div className={styles.historiesWrapper}>
            {Data && Data.length == 0 ? (
              <div>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  No Records Found!!
                </Typography>
              </div>
            ) : null}
            {Data &&
              Data.slice()
                .reverse()
                .map((data) => {
                  return <HistoryComp data={data} />;
                })}
          </div>
        )}

        {Data && Data.length == 0 ? null : (
          <>
            {histroyState["totalPages"] > 1 ? (
              <div className={styles.historyTotalPage}>
                <div>
                  <OutlineButton
                    onClickHandler={prevPageHandler}
                    showIcon={false}
                    text="Previous"
                  />
                  {"    "}
                  {"   "}
                  <OutlineButton
                    onClickHandler={nextPageHandler}
                    text="Next"
                    showIcon={false}
                  />
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default Histories;
