import React, { useState, useMemo } from "react";
import TabFooter from "../../../components/Phase2/Tabs/TabFooter/TabFooter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Grid,
  TextField,
  Alert,
  MenuItem,
  ListSubheader,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import {
  SelectComponent,
  SelectMonthComponent,
} from "../../../components/FormComponents/SelectMax";
import {
  dealDetailsAction,
  fetchDealList,
  pageStatusAction,
  clearSucessAndErrorAction,
  checkDealUploaded,
  clearMonthlyCollectionReportAction,
  fetchMonthList,
  dealYearAction,
} from "../../../redux/Phase2/UploadReportSlice";
import ErrorAlert from "../../../components/Alert/ErrorAlert";

import {
  showErrorAlertAction,
  showAlertAction,
} from "../../../redux/app/appSlice";
import RedirectToLoginPage from "../../redirect";
import moment from "moment/moment";
import { ENV } from "../../../API/BaseUrl";

const DealDeatils = ({ nextTabHandler }) => {
  const [state, setState] = useState({
    dealName: "",
    collectionMonth: "",
    uploadType: "",
  });
  const [status, setstatus] = useState(true);
  const [count, setCount] = useState(0);

  var previousMonth =
    ENV === "PREPROD" || ENV === "PROD"
      ? moment().subtract(1, "month").startOf("month").format("MMMM")
      : moment().subtract(2, "month").startOf("month").format("MMMM");

  moment().subtract(2, "month").startOf("month").format("MMMM");

  let dispatch = useDispatch();
  let uploadReportState = useSelector((state) => state.uploadReport);
  let uploadtypes = [
    {
      text: "MONTHLY UPLOAD"
    },
    {
      text: "MIS UPLOAD"
    }
  ]



  let { error, success } = useSelector((state) => state.uploadReport);


  useEffect(() => {
    dispatch(fetchDealList());
  }, []);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(dealDetailsAction(state));
    dispatch(pageStatusAction({ pageName: "dealDeatails" }));
    nextTabHandler();
  };

  useEffect(() => {
    dispatch(clearSucessAndErrorAction());
  }, []);

  //? it will run on initial page load

  useEffect(() => {
    console.log(
      "uploadReportState00000000",
      uploadReportState,
      uploadReportState.collectionMonth
    );
    setState({
      dealName: uploadReportState.dealName,
      collectionMonth: uploadReportState.collectionMonth,
      uploadType: uploadReportState.uploadType,
    });

    if (
      uploadReportState.dealName.length === 0 &&
      uploadReportState.collectionMonth.length === 0 &&
      uploadReportState.uploadType.length === 0
    ) {
      setstatus(true);
    } else {
      setstatus(false);
    }
  }, []);




  useEffect(() => {
    if (
      state.dealName === "" &&
      state.collectionMonth === "" &&
      state.uploadType === ""
    ) {
      setstatus(true);
    } else if (
      state.dealName !== "" &&
      state.collectionMonth !== "" &&
      state.uploadType !== ""
    ) {
      setstatus(false);
    }
  }, [state]);

  //Handle Input change function start here
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    console.log("value", { value, name });
    if (name == "dealName") {
      dispatch(fetchMonthList(value));


    }
    let dataUpdated = {
      ...state,
      [name]: value,
    };

    setState(dataUpdated);


    if (state.collectionMonth !== "") {
      var year = state.collectionMonth.split("(")[1].split(")")[0];
      console.log("year", year);
      dispatch(dealYearAction(year));
    }
    dispatch(clearMonthlyCollectionReportAction());
  };
  //Handle Input change function end here

  //Handle deal is upload or not function start here
  const checkDealUploadedHandler = () => {
    dispatch(
      checkDealUploaded({
        month: previousMonth,
        dealId: state.dealName,
        isFinalUpload: true,
      })
    );
  };
  //Handle deal is upload or not function end here

  // useEffect(() => {
  //   if (state.dealName.length != 0) {
  //     checkDealUploadedHandler();
  //   }
  // }, [state.dealName]);

  useEffect(() => {
    if (uploadReportState?.isDealUploaded != null) {
      if (uploadReportState?.isDealUploaded?.already_uploaded == true) {
        setstatus(true);
        if (count === 0) {
          dispatch(showAlertAction("Deal Already Uploaded"));
          setCount(1);
        }
      } else {
        // setstatus(false);
        setCount(0);
      }
    }
  }, [uploadReportState]["isDealUploaded"]);

  const [filter, setFilter] = useState("");

  const containsText = (text, searchText, dealCodeSerach) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
    dealCodeSerach.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  let displayedOptions = useMemo(
    () =>
      uploadReportState.dealList.filter((option) =>
        containsText(option.name, filter, option.dealCode)
      ),
    [filter]
  );

  return (
    <>
      <RedirectToLoginPage />
      <ErrorAlert />
      <div style={{ padding: "24px" }}>
        {uploadReportState["dealList"].length === 0 ? (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: "80px",
              display: "flex",
            }}
          >
            <Typography>Loading....</Typography>
          </div>
        ) : (
          <form>
            <Grid container direction="row" sx={{ rowGap: "10px" }}>
              <Grid item xs={6} padding="5px">
                <TextWrapperComponent text="Deal Name">
                  <SelectComponent
                    placeholder="Select Deal"
                    value={state.dealName}
                    onClickHandler={handleInputChange("dealName")}
                    optionList={uploadReportState.dealList}
                  ></SelectComponent>
                </TextWrapperComponent>
              </Grid>

              <Grid item xs={6} padding="5px">
                <TextWrapperComponent text="Collections Month">
                  <SelectMonthComponent
                    placeholder="Select Month"
                    value={state.collectionMonth}
                    onClickHandler={handleInputChange("collectionMonth")}
                    optionList={
                      uploadReportState?.remainingMonthlyUploadMonthList
                    }
                  ></SelectMonthComponent>
                </TextWrapperComponent>
              </Grid>

              <Grid item xs={6} padding="5px">
                <TextWrapperComponent text="Upload Type">
                  <SelectMonthComponent
                    placeholder="Select Type"
                    value={state.uploadType}
                    onClickHandler={handleInputChange("uploadType")}
                    optionList={
                      uploadtypes
                    }
                  ></SelectMonthComponent>
                </TextWrapperComponent>
              </Grid>

            </Grid>
          </form>
        )}
      </div>

      <div style={{ height: "64px" }}>
        <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
      </div>
    </>
  );
};

export default DealDeatils;
