import { useEffect } from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import OutlineButton from "../Buttons/OutlineButton";
import styles from "./Pagination.module.css"

const Pagination = ({
  Page,
  TotalNoNumber,
  nextPageHandler,
  prevPageHandler,
}) => {
  return (
    <div className={styles.main}>
      <div>
        <p className={styles.pTag}>
          Page {Page} of {TotalNoNumber}
        </p>
      </div>

      <div></div>

      <div>
        <OutlineButton
          onClickHandler={prevPageHandler}
          showIcon={false}
          text="Previous"
        />
        {"    "}
        {"   "}
        <OutlineButton
          onClickHandler={nextPageHandler}
          text="Next"
          showIcon={false}
        />
      </div>
    </div>
  );
};

export default Pagination;
