import React, { useState } from "react";
import TabFooter from "../TabFooter/TabFooter";
import { TextField, Grid } from "@mui/material";
import { pageStatusAction } from "../../../redux/DealDuplication/dealDuplicationSlice";
import { useDispatch } from "react-redux";
import AutocompleteComponent from "../../FormComponents/AutocompleteCompoent";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";

const BasicInformation = ({ nextTabHandler }) => {
  const [state, setState] = useState({
    dealName: "",
    originatorOfDeal: "",
  });

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const OriginatorsList = [
    { id: 1, title: "Bajaj Finserv Pvt Ltd." },
    { id: 2, title: "Paytm Payments Bank" },
    { id: 3, title: "HSVC Bank" },
    { id: 4, title: "Money View" },
  ];

  const defaultProps = {
    options: OriginatorsList,
    getOptionLabel: (option) => option.title,
  };

  let dispatch = useDispatch();
  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "basicInfo" }));
    nextTabHandler();
  };

  return (
    <>
      <div style={{ padding: "24px" }}>
        <form>
          <div>
            <Grid container direction="row" sx={{ rowGap: "10px" }}>
              <Grid item xs={6} padding="8px">
                <TextWrapperComponent text="Name Of Deal">
                  <TextField
                    size="small"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    value={state.dealName}
                    onChange={handleInputChange("dealName")}
                    placeholder="Enter Name for this Deal"
                  />
                </TextWrapperComponent>
              </Grid>
              <Grid item xs={6} padding="5px">
                <TextWrapperComponent text="Originator for this Deal">
                  <AutocompleteComponent
                    defaultProps={defaultProps}
                    placeholder="Search Select Originator"
                  />
                </TextWrapperComponent>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>

      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={false} />
    </>
  );
};

export default BasicInformation;
