import Image from "../../../assets/images/Frame.svg";
import Layout from "../../../components/Layout/Layout";
import TabLayout from "../../../components/Layout/TabsLayout/Layout";
import DescriptionIcon from "@mui/icons-material/Description";
import * as React from "react";
import ProfileTab from "./ProfileTab";
import { useSelector } from "react-redux";

const CompleteKyc = () => {
  const originatorState = useSelector((state) => state.originator);
  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: "Complete KYC",
    },
  ];

  let TabsList = [
    {
      name: "Profile",
      component: <ProfileTab></ProfileTab>,
      index: 0,
    },
    {
      name: "Deals",
      component: <h6>Deals Page</h6>,
      isDisabled: true,
      index: 1,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <TabLayout
          TabsList={TabsList}
          formName={originatorState?.name}
          icon={icon}
          iconColor={"#DBE3F8"}
          image={Image}
        />
      </Layout>
    </>
  );
};

export default CompleteKyc;
