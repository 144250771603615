import {
  Grid,
  Select,
  ListSubheader,
  TextField,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";
import { Checkbox } from "@mui/material";
import { MenuItem } from "@mui/material";
import { ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { DocumentHeader } from "../DocumentHeaderWrapper/DocumentHeader";
import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";

import {
  GenerateReportWidth,
  GenerateReportHeight,
  GenerateReportSelect,
  listSubheaderStyle,
  GenerateReportDatePickerPopper,
  searchDealStyle,
  MenuItemSelectAll,
  GenerateReportMenuProps,
  searchCodeStyle,
  GenerateReportDatePickerInput,
  GenerateReportButton,
  GenerateReportBoxContainer
} from "../../Pages/Phase2/SearchReports/SearchReports";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GeneratedReportFileV2 } from "../Phase2/UploadReports/GenerateReportV2";
const MenuProps = {
  PaperProps: {
    style: GenerateReportMenuProps,
  },
};
export default function LedgerReportComponent({
  loading,
  reportList,
  statusData,
  handleInputChange,
  multiDeal,
  handleChange,
  displayedOptions,
  setFilter,
  filter,
  selectedAll,
  setSelectedAll,
  uploadReportState,
  SerachReportState,
  ledgerLists,
  ledgerCode,
  setLedgerCode,
  ledgerCodeOptions,
  startDate,
  setstartDate,
  endDate,
  setEndDate,
  handleGenerateReport,
  ledgerStatus,
  ledgerReportHeading,
  handleEntityChange,
  sourceList,
  entityData,
  entityStatus,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <div className={Styles.CommanPaddingTop}>
              <SelectComponent
                compWidth="70px"
                placeholder="Select Type of Report"
                value={statusData.typeOfReport}
                onClickHandler={handleInputChange("typeOfReport")}
                optionList={reportList}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>

        {/* MULTIPLE DEAL SELECTION */}

        <Grid item xs={4}>
          <TextWrapperComponent text="Entity">
            <div
              style={{
                paddingTop: "3px",
                // width: "180px",
              }}
            >
              <SelectComponent
                compWidth="70px"
                placeholder="Select Entity of Report"
                value={entityData.sourceOfReport}
                onClickHandler={handleEntityChange("sourceOfReport")}
                optionList={sourceList}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>

        <Grid item xs={4}>
          <TextWrapperComponent isdisable= {entityStatus} text="Deal Name">
            <Select
              multiple
              sx={GenerateReportWidth}
              IconComponent={ExpandMoreIcon}
              displayEmpty
              value={multiDeal}
              onChange={handleChange}
              variant="standard"
              disabled={entityStatus}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p className={Styles.dealNameStyle}>{entityStatus ?   "" : "Deal Name" }</p>;
                }

                return selected
                  .map((x) =>
                    x?.dealCode?.length > 0
                      ? x.name + "(" + x?.dealCode + ")"
                      : x.name
                  )
                  .join(", ");
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <ListSubheader sx={listSubheaderStyle}>
                <TextField
                  size="medium"
                  placeholder="Search deal"
                  fullWidth
                  value={filter}
                  sx={{
                    input: searchDealStyle,
                  }}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {filter == "" && (
                <MenuItem
                  value={"All"}
                  onClick={() => setSelectedAll(!selectedAll)}
                  sx={MenuItemSelectAll}
                >
                  <Checkbox size="small" checked={selectedAll} />
                  <ListItemText primary={"SELECT ALL"} />
                </MenuItem>
              )}

              {displayedOptions.length > 0
                ? [...displayedOptions].map((variant, index) => {
                  return (
                    <MenuItem
                      key={variant.id}
                      value={variant}
                      sx={GenerateReportHeight}
                      className="muidropdown"
                    >
                      <Checkbox
                        size="small"
                        checked={
                          multiDeal.findIndex(
                            (item) => item.id === variant.id
                          ) >= 0
                        }
                      />
                      <ListItemText
                        primary={
                          variant?.dealCode?.length > 0
                            ? variant.name + "(" + variant?.dealCode + ")"
                            : variant.name
                        }
                      />
                    </MenuItem>
                  );
                })
                : uploadReportState.dealList.map((variant, index) => {
                  return (
                    <MenuItem
                      key={variant.id}
                      value={variant}
                      sx={GenerateReportHeight}
                      className="muidropdown"
                    >
                      <Checkbox
                        size="small"
                        checked={
                          multiDeal.findIndex(
                            (item) => item.id === variant.id
                          ) >= 0
                        }
                      />
                      <ListItemText
                        primary={
                          variant?.dealCode?.length > 0
                            ? variant.name + "(" + variant?.dealCode + ")"
                            : variant.name
                        }
                      />
                    </MenuItem>
                  );
                })}
            </Select>
          </TextWrapperComponent>
        </Grid>

        <Grid item xs={4}>
          {ledgerLists.length > 0 && (
            <TextWrapperComponent text="Ledger Code">
              <Select
                sx={GenerateReportSelect}
                IconComponent={ExpandMoreIcon}
                displayEmpty
                value={ledgerCode}
                onChange={(e) => {
                  setLedgerCode(e.target.value);
                }}
                variant="standard"
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p className={Styles.dealNameStyle}>Ledger Code</p>;
                  }

                  let selectedItems = ledgerLists.filter(
                    (item) => item.id == selected
                  );

                  return (
                    selectedItems[0]?.systemCode +
                    " " +
                    "(" +
                    selectedItems[0]?.purpose?.name +
                    ")"
                  );
                }}
              >
                <ListSubheader sx={listSubheaderStyle}>
                  <TextField
                    size="medium"
                    placeholder="Search Code"
                    fullWidth
                    value={filter}
                    sx={{
                      input: searchCodeStyle,
                    }}
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </ListSubheader>

                {ledgerCodeOptions.length > 0
                  ? [...ledgerCodeOptions].map((variant, index) => {
                    return (
                      <MenuItem
                        key={variant.id}
                        value={variant.id}
                        sx={GenerateReportHeight}
                      >
                        <ListItemText
                          primary={
                            variant?.systemCode +
                            " " +
                            "(" +
                            variant?.purpose?.name +
                            ")"
                          }
                        />
                      </MenuItem>
                    );
                  })
                  : ledgerLists.map((variant, index) => {
                    return (
                      <MenuItem
                        key={variant.id}
                        value={variant.id}
                        sx={GenerateReportHeight}
                      >
                        <ListItemText
                          primary={
                            variant?.systemCode +
                            " " +
                            "(" +
                            variant?.purpose?.name +
                            ")"
                          }
                        />
                      </MenuItem>
                    );
                  })}
              </Select>
            </TextWrapperComponent>
          )}
        </Grid>
        {/* START DATE */}
        <Grid item xs={2}>
          <TextWrapperComponent text="Start Date">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                inputFormat="DD-MM-YYYY"
                value={startDate}
                PopperProps={{
                  sx: GenerateReportDatePickerPopper,
                }}
                InputProps={{
                  sx: GenerateReportDatePickerInput,
                }}
                onChange={(newValue) => {
                  setstartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </LocalizationProvider>
          </TextWrapperComponent>
        </Grid>

        {/* END DATE */}
        <Grid item xs={2}>
          <TextWrapperComponent text="End Date">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // disableFuture
                inputFormat="DD-MM-YYYY"
                value={endDate}
                PopperProps={{
                  sx: GenerateReportDatePickerPopper,
                }}
                InputProps={{
                  sx: GenerateReportDatePickerInput,
                }}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </LocalizationProvider>
          </TextWrapperComponent>
        </Grid>
      </Grid>

      {/* SUBMIT BUTTON */}
      <br></br>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={SerachReportState["loading"] ? true : ledgerStatus}
          onClick={handleGenerateReport}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "Generate Report"}
        </Button>
      </div>
      {SerachReportState["reportData"] === null ? null : (
        <>
          {SerachReportState["reportData"]["message"] ===
            "No Data Found" ? null : (
            <Box sx={GenerateReportBoxContainer}>
              <Grid container>
                <Grid item xs={12}>
                  <DocumentHeader title={ledgerReportHeading}></DocumentHeader>
                  <div className={Styles.CommanPadding}>
                    <GeneratedReportFileV2
                      // fileName={selectedReportName + ".xlsx"}
                      fileName={
                        SerachReportState["reportData"]["fileName"] ||
                        SerachReportState["reportData"]["filename"]
                      }
                      id={SerachReportState["reportData"]["file"]}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
}
