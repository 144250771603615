import { Colors } from "../../../../theme/theme";

export const StackStyle = {
    padding: "20px",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
}

export const ConditionalStackStyle = (showSkinBtn) => {
    if (showSkinBtn) {
        return {
            ...StackStyle,
            justifyContent: "space-between",
            alignItems: "space-between"
        }
    }
    return StackStyle;
}

export const skipBtnStyle = {
    backgroundColor: Colors.white,
    color: Colors.black,
    textTransform: "none",
    "&:hover": {
        backgroundColor: Colors.white,
    },
    "&:disabled": {
        backgroundColor: Colors.white,
        color: "white",
    },
}

export const loadingStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,
    textTransform: "none",
    "&:hover": {
        backgroundColor: Colors.piramal_orange,
    },
    "&:disabled": {
        backgroundColor: "#FAC3B3",
        color: "white",
    },
}

export const submitBtnStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,
    textTransform: "none",
    "&:hover": {
        backgroundColor: Colors.piramal_orange,
    },
    "&:disabled": {
        backgroundColor: "#FAC3B3",
        color: "white",
    },
}

export const nextBtnStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,

    textTransform: "none",
    "&:hover": {
        backgroundColor: Colors.piramal_orange,
    },
    "&:disabled": {
        backgroundColor: "#FAC3B3",
        color: "white",
    },
}