export const stackStyle = {
    flexDirection:"row",
    alignContent:"center",
    justifyContent:"space-between",
    paddingBottom:"12px",
    display:"flex",
}

export const fontsStyle = {
    fontSize:"16px",
    fontWeight:"700"
}