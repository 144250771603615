import React from "react";

import { Typography, Box, Divider, Stack, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Colors } from "../../theme/theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { checkCircleIconStyle, ctaBtnStyle, entryButtonWrapper, entryTypo, settingsOutlinedIconStyle, stackStyle, titleBoxStyle, titleTypo, topBoxStyle } from "./WrapperCSS";
const WrapperComponent = ({
  title,
  showButton = false,
  isDisabled = false,
  onClickButton,
  children,
  text = "Duplicate Deal",
  icon,
  checkedIcon = false,
  RegenrateEntry = false,
  onClickRegenrateEntryButton,
  regenratekey = false
}) => {
  const titleBox = titleBoxStyle(RegenrateEntry)
  const entryBtnBox = entryButtonWrapper(regenratekey)
  const buttonStyle = ctaBtnStyle(isDisabled)
  return (
    <Box sx={topBoxStyle}>
      <Stack sx={stackStyle}>
        <Box sx={titleBox}>
          <Typography sx={titleTypo} >
            {title}
          </Typography>

          {checkedIcon && (
            <CheckCircleIcon
              sx={checkCircleIconStyle}
            ></CheckCircleIcon>
          )}
          {RegenrateEntry && <Box onClick={onClickRegenrateEntryButton} sx={entryBtnBox}>
            <SettingsOutlinedIcon style={settingsOutlinedIconStyle} />
            <Typography sx={entryTypo} >Regenerate Accounting Entry</Typography>
          </Box>}
        </Box>
        {showButton && (
          <Button
            disabled={isDisabled}
            startIcon={icon}
            style={{ color: isDisabled && "white" }}
            sx={buttonStyle}
            onClick={onClickButton}
          >
            {text}
          </Button>
        )}
      </Stack>

      <Divider></Divider>
      {children}
    </Box>
  );
};

export default WrapperComponent;
