import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import Loader from "../../../utils/Loader";
import { getOriginatorDetails } from "../../../redux/originator/originatorSlice";
import { useParams } from "react-router-dom";

const Otherdetails = ({ nextTabHandler }) => {
  const [originatorData, setOriginatorData] = useState("")
  let dispatch = useDispatch();
  const params = useParams();
  const { originatorId } = params;

  let originatorState = useSelector((state) => state.originator);

  useEffect(() => {
    
    dispatch(getOriginatorDetails(originatorId)).then((res) => {
      setOriginatorData(res?.payload)
    });
  }, []);
console.log({ originatorData });

  return (
    <>
      <h1>{originatorState.loading}</h1>
      {originatorState.fetchOriginatorLoding ? (
        <Loader />
      ) : (
        <div style={{ padding: "24px" }}>
          <Grid container spacingLeft={4}>
            <Grid item xs={6}>
              <Typography fontSize="14px" fontWeight="400" color="#808080">
              Originator's Pan
              </Typography>
              <Typography style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000000",
                    height: "24px",
                  }} >
                      {originatorData?.kycData?.panNumber}
                    </Typography>
            </Grid>
            {originatorData?.kycData?.gstNumbers?.length > 0 ? (
              <>
                {originatorData?.kycData?.gstNumbers.map((item, index) => (

            <Grid item xs={6}>
              <Typography fontSize="14px" fontWeight="400" color="#808080">
              GST Number - {index + 1}
              </Typography>
              <Typography style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000000",
                    height: "24px",
                  }}>
                              {item}
                            </Typography>
            </Grid>
                ))}
              </>
            ):(<></>)}

        
          </Grid>
        </div>
      )}
    </>
  );
};

export default Otherdetails;
