import * as React from "react";
import {
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, TextField, Stack, Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";
import { Box } from "@mui/system";
import { GeneratedReportFileV2 } from "../Phase2/UploadReports/GenerateReportV2";
import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";
import {
  GenerateReportBoxContainer,
  GenerateReportButton,
  GenerateReportHeight,
  GenerateReportMenuProps,
  GenerateReportWidth,
  listSubheaderStyle,
  searchCodeStyle1,
} from "../../Pages/Phase2/SearchReports/SearchReports";

import { DocumentHeader } from "./../DocumentHeaderWrapper/DocumentHeader";
const MenuProps = {
  PaperProps: {
    style: GenerateReportMenuProps,
  },
};

const Placeholder = ({ children }) => {
  return <div className={Styles.GenerateReportPlaceholder}>{children}</div>;
};

export default function ABCReportComponent({
  reportList,
  statusData,
  handleInputChange,
  multiDeal,
  handleChange,
  displayedOptions,
  setFilter,
  filter,
  selectedAll,
  setSelectedAll,
  uploadReportState,
  SerachReportState,
  status,
  handleGenerateReport,
  reportHeading,
  previousMonthList,
  monthList,
  yearData,
  entityData,
  handleEntityChange,
  sourceList,
  entityStatus,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <SelectComponent
              placeholder="Select Type of Report"
              value={statusData.typeOfReport}
              onClickHandler={handleInputChange("typeOfReport")}
              optionList={reportList}
            ></SelectComponent>
          </TextWrapperComponent>
        </Grid>

        <Grid item xs={4}>
          <TextWrapperComponent text="Entity">
            <div className={Styles.CommanPaddingTop}>
              <SelectComponent
                compWidth="70px"
                placeholder="Select Entity of Report"
                value={entityData.sourceOfReport}
                onClickHandler={handleEntityChange("sourceOfReport")}
                optionList={sourceList}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>

        {/* DEAL TYPE */}
        <Grid item xs={4}>
          <TextWrapperComponent isdisable={entityStatus} text="Deal Name">
            <Select
              multiple
              sx={GenerateReportWidth}
              IconComponent={ExpandMoreIcon}
              displayEmpty
              disabled={entityStatus}
              value={multiDeal}
              onChange={handleChange}
              variant="standard"
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p className={Styles.dealNameStyle}>{entityStatus ? "" : "Deal Name"} </p>;
                }

                return selected
                  .map((x) =>
                    x?.dealCode?.length > 0
                      ? x.name + "(" + x?.dealCode + ")"
                      : x.name
                  )
                  .join(", ");
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <ListSubheader sx={listSubheaderStyle}>
                <TextField
                  size="medium"
                  placeholder="Search deal"
                  fullWidth
                  value={filter}
                  sx={{
                    input: searchCodeStyle1,
                  }}
                  onChange={(e) => setFilter(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {filter == "" && (
                <MenuItem
                  value={"All"}
                  onClick={() => setSelectedAll(!selectedAll)}
                  sx={GenerateReportHeight}
                >
                  <Checkbox size="small" checked={selectedAll} />
                  <ListItemText primary={"SELECT ALL"} />
                </MenuItem>
              )}

              {displayedOptions.length > 0
                ? [...displayedOptions].map((variant, index) => {
                  return (
                    <MenuItem
                      key={variant.id}
                      value={variant}
                      sx={GenerateReportHeight}
                      autoFocus="false"
                    >
                      <Checkbox
                        size="small"
                        checked={
                          multiDeal.findIndex(
                            (item) => item.id === variant.id
                          ) >= 0
                        }
                      />
                      <ListItemText
                        primary={
                          variant?.dealCode?.length > 0
                            ? variant.name + "(" + variant?.dealCode + ")"
                            : variant.name
                        }
                      />
                    </MenuItem>
                  );
                })
                : uploadReportState.dealList.map((variant, index) => {
                  return (
                    <MenuItem
                      key={variant.id}
                      value={variant}
                      sx={GenerateReportHeight}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          multiDeal.findIndex(
                            (item) => item.id === variant.id
                          ) >= 0
                        }
                      />
                      <ListItemText
                        primary={
                          variant?.dealCode?.length > 0
                            ? variant.name + "(" + variant?.dealCode + ")"
                            : variant.name
                        }
                      />
                    </MenuItem>
                  );
                })}
            </Select>
          </TextWrapperComponent>
        </Grid>

        {/* TRANSACTION MONTH */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Transaction Month">
            <Select
              IconComponent={ExpandMoreIcon}
              value={statusData.transactionMonth}
              onChange={handleInputChange("transactionMonth")}
              displayEmpty
              size={"small"}
              variant="standard"
              fullWidth
              MenuProps={MenuProps}
              renderValue={
                statusData.transactionMonth !== ""
                  ? undefined
                  : () => <Placeholder>Select Transaction Month</Placeholder>
              }
            >
              {/* {monthList &&
                monthList.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))} */}
              {previousMonthList &&
                previousMonthList.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
            </Select>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Transaction Year">
            <Select
              IconComponent={ExpandMoreIcon}
              value={statusData.year}
              onChange={handleInputChange("year")}
              displayEmpty
              size={"small"}
              variant="standard"
              fullWidth
              MenuProps={MenuProps}
              renderValue={
                statusData.year !== ""
                  ? undefined
                  : () => <Placeholder>Select Transaction Year</Placeholder>
              }
            >
              {yearData?.years &&
                yearData?.years.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
            </Select>
          </TextWrapperComponent>
        </Grid>
      </Grid>
      <div className={Styles.noContentDiv}></div>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={SerachReportState["loading"] ? true : status}
          onClick={handleGenerateReport}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "Generate Report"}
        </Button>
      </div>

      {/* report data */}
      {SerachReportState["reportData"] === null ? null : SerachReportState[
        "reportData"
      ]["message"] === "No Data Found" ? null : (
        <Box sx={GenerateReportBoxContainer}>
          <Grid container>
            <Grid item xs={12}>
              <DocumentHeader title={reportHeading}></DocumentHeader>
              <div className={Styles.CommanPadding}>
                <GeneratedReportFileV2
                  fileName={
                    SerachReportState["reportData"]["fileName"] ||
                    SerachReportState["reportData"]["filename"]
                  }
                  // fileName={selectedReportName + ".xlsx"}
                  id={SerachReportState["reportData"]["file"]}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
