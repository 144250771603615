import React from "react";
import styles from "../../L2/ViewDownloadDeleteComp.module.css";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch } from "react-redux";
import { pageStatusAction } from "../../../redux/originator/creditBureauSlice";
import { Typography } from "@mui/material";
import ViewDownloadAPI from "../../L2/ViewDownloadAPI";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";

const KYCDocuments = ({ nextTabHandler }) => {
  let originatorState = useSelector((state) => state.originator);

  let dispatch = useDispatch();
  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "KYCDocuments" }));
    nextTabHandler();
  };
  
  console.log(
    originatorState.gstCertificate === (null || undefined) &&
    originatorState.incorporationCertificate === (null || undefined) &&
    originatorState.cancelledCheque === (null || undefined) &&
    originatorState.rbiRegistrationCertificate === (null || undefined) &&
    originatorState.leiCard === (null || undefined)
  );

  return (
    <>
      {
        originatorState.loading ? <Loader /> :
          <>
            {/* PanCard */}
            {originatorState.gstCertificate === (null || undefined) &&
              originatorState.incorporationCertificate === (null || undefined) &&
              originatorState.cancelledCheque === (null || undefined) &&
              originatorState.rbiRegistrationCertificate === (null || undefined) &&
              originatorState.leiNumber === (null || undefined) &&
              originatorState.leiCard === (null || undefined) && (
                <p style={{ textAlign: "center", fontSize: "16px", padding: "10px" }}>
                  No KYC Documents Found
                </p>
              )}

            {originatorState?.panCard?.filename == null ? null : (
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  PAN Card
                </Typography>
                <ViewDownloadDeleteComp
                  id={originatorState?.panCard?._id}
                  fileName={originatorState?.panCard?.filename}
                ></ViewDownloadDeleteComp>
              </div>
            )}
            {/* gstCertificate */}
            {originatorState?.gstCertificate.length == 0  ? null :(
              <>
               {originatorState?.gstCertificate?.map((item, index) => {
                
                return(
                <> 
                <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  GST Certificate - {index + 1}
                </Typography>
                <ViewDownloadDeleteComp
                  id={item?._id}
                  fileName={item?.filename}
                ></ViewDownloadDeleteComp>
              </div>
               
                </>
                )
    })};         
             </>
            ) }
            {/* incorporationCertificate */}
            {originatorState?.incorporationCertificate?.filename == null ? null : (
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Incorporation Certificate
                </Typography>
                <ViewDownloadDeleteComp
                  id={originatorState?.incorporationCertificate?._id}
                  fileName={originatorState?.incorporationCertificate?.filename}
                ></ViewDownloadDeleteComp>
              </div>
            )}
            {/* cancelledCheque */}
            {originatorState?.cancelledCheque?.filename == null ? null : (
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Cancelled Cheque
                </Typography>
                <ViewDownloadDeleteComp
                  id={originatorState?.cancelledCheque?._id}
                  fileName={originatorState?.cancelledCheque?.filename}
                ></ViewDownloadDeleteComp>
              </div> 
            )}
            {/* rbiRegistrationCertificate */}
            {originatorState?.rbiRegisterationCertificate?.filename == null ? null : (
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  RBI Registration Certificate
                </Typography>
                <ViewDownloadDeleteComp
                  id={originatorState?.rbiRegisterationCertificate?._id}
                  fileName={originatorState?.rbiRegisterationCertificate?.filename}
                ></ViewDownloadDeleteComp>
              </div>
            )}
            {/* leiCard */}
            {originatorState?.leiCard?.filename == null ? null : (
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  LEI Card
                </Typography>
                <ViewDownloadDeleteComp
                  id={originatorState?.leiCard?._id}
                  fileName={originatorState?.leiCard?.filename}
                ></ViewDownloadDeleteComp>
              </div>
            )}
            {originatorState?.leiNumber == null ||
              originatorState?.leiNumber == "" ? null : (
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  LEI Number
                </Typography>
                <div className={styles.wrapper}>
                  <div className={styles.filenameWrapper}>
                    <p>{originatorState?.leiNumber}</p>
                  </div>

                  <div className={styles.iconsWrapper}></div>
                </div>
              </div>
            )}

            
          </>
      }

    </>

  );
};

export default KYCDocuments;
