import React from "react";
import TabHeader from "../TabHeader/TabHeader";
import Styles from "./TabContentWrapper.module.css";

const TabContentWrapper = ({ children, heading }) => {
  return (
    <>
      <TabHeader heading={heading} />
      <div className={Styles.tabContent}>{children}</div>
    </>
  );
};

export default TabContentWrapper;
