import React from "react";
import styles from "./Wrapper.module.css"
import { Typography, Box, Divider, Stack, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Colors } from "../../theme/theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AddMoreFileComponent } from "./UploadComponents";
import { checkCircleIconStyle, displayStyle, stackStyle, titleTypo, topBoxStyle } from "./WrapperCSS";

const WrapperComponent = ({
  title,
  showButton = false,
  isDisabled = false,
  onClickButton,
  children,
  text = "Duplicate Deal",
  icon,
  checkedIcon = false,
  name,
}) => {
  return (
    <Box sx={topBoxStyle}>
      <Stack sx={stackStyle}>
        <Box sx={displayStyle}>
          <Typography sx={titleTypo} >
            {title}
          </Typography>
          {checkedIcon && (
            <CheckCircleIcon sx={checkCircleIconStyle}></CheckCircleIcon>
          )}
        </Box>
        {showButton && (
          <div className={styles.padding} >
            <AddMoreFileComponent
              clickhandler={() => onClickButton(name)}
            ></AddMoreFileComponent>
          </div>
        )}
      </Stack>

      <Divider></Divider>
      {children}
    </Box>
  );
};

export default WrapperComponent;
