import React from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import Styles from "./FormComponents.module.css";
import { TextFieldStyleCustomWidth, autoCompletePaper } from "./FormComponents";
const AutocompleteComponent = ({
  defaultProps,
  placeholder,
  value,
  optionHandler,
  customWidth = false,
}) => {
  console.log(defaultProps);
  const TextStyle = TextFieldStyleCustomWidth(customWidth);
  return (
    <Autocomplete
      popupIcon={<ExpandMoreIcon />}
      {...defaultProps}
      onChange={(event, newValue) => optionHandler(newValue)}
      clearOnEscape
      PaperComponent={({ children }) => (
        <Paper sx={autoCompletePaper}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="standard"
          autoComplete="off"
          sx={TextStyle}
          // InputProps={{endAdornment: (<InputAdornment position="end"><IconButton><DeleteIcon/></IconButton></InputAdornment>)}}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
