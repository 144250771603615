import React from "react";
import TextWrapperComponent from "../../../../FormComponents/TextWrapperComponent";
import BasicSelect from "../../../TabSelectOptions/SelectTabOptions";
import { FormControl, TextField, Grid } from "@mui/material";

const BasicInformationForm = ({
  state,
  name,
  dealState,
  handleInputChange,
  value,
  setValue,
  originator,
  entityValue,
  entity,
  setEntityValue
}) => {
  
  const dealType = [
    { id: "PCHFL", title: "PCHFL" },
    { id: "PEL", title: "PEL" },
  ];

  return (
    <div>
      <Grid container direction="row" sx={{ rowGap: "10px" }}>
        <Grid item xs={6} padding="8px">
          <TextWrapperComponent text="Name Of Deal">
            <TextField
              size="small"
              autoComplete="off"
              variant="standard"
              fullWidth
              value={state.dealName}
              defaultValue={name}
              onChange={handleInputChange("name")}
              placeholder="Enter Name for this Deal"
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={6} padding="5px">
          <TextWrapperComponent text="Originator for this Deal">
            <FormControl variant="standard" sx={{ width: 300 }}>
              <BasicSelect
                data={dealState.allOriginators}
                value={value}
                setValue={setValue}
                defaultValue={originator}
                placeholder="Search & Select Originator"
              />
            </FormControl>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={6} padding="5px">
          <TextWrapperComponent text="Deal Type">
            <FormControl variant="standard" sx={{ width: 300 }}>
              <BasicSelect
                data={dealType}
                value={entityValue}
                setValue={setEntityValue}
                defaultValue={entity}
                placeholder="Select Entity"
              />
            </FormControl>
          </TextWrapperComponent>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicInformationForm;
