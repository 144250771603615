import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Colors } from "../../theme/theme";
import whiteaddicon from "../../assets/icons/whiteaddicon.svg";

const SecoundaryButton = ({ onClickHandler, text, showIcon = true, isDisabled=false, isMargin= false }) => {
  return (
    <Box
      border={1}
      sx={{
        backgroundColor: isDisabled
        ? Colors.piramal_light_orange
        : Colors.piramal_orange,

        border: `1px solid ${isDisabled
          ? Colors.piramal_light_orange
          : Colors.piramal_orange}`,
        borderRadius: 2,
        display: "inline-flex",
        height: "40px",
        paddingLeft: 2,
        paddingRight: 2,
        marginRight:isMargin ?"6px" : ""
      }}
    >
      <div onClick={onClickHandler} style={{ cursor: !isDisabled && "pointer" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          height="40px"
        >
          {showIcon ? (
            <img src={whiteaddicon} sx={{ color: "white" }}></img>
          ) : (
            ""
          )}
          {showIcon ? <Box paddingLeft={1}></Box> : ""}

          <Typography fontSize="12px" fontWeight="500" color={Colors.white}>
            {text}
          </Typography>
        </Stack>
      </div>
    </Box>
  );
};

export default SecoundaryButton;
