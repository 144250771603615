import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAlert: false,
  showErrorAlert: false,
  showSidebar: true,
  users: [],
  entries: [],
  redirectToLogin: null,
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    toggleSidebarAction: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    setSidebarAction: (state) => {
      state.showSidebar = false;
    },

    showAlertAction: (state, { payload }) => {
      state.showAlert = payload;
    },

    redirectToLoginAction: (state) => {
      state.redirectToLogin = !state.redirectToLogin;
    },

    showErrorAlertAction: (state, { payload }) => {
      state.showErrorAlert = payload;
    },

    clearErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
      state.success = null;
    },

    logout: (state) => {},
  },
  //? For API's Calls
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getData.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(getData.fulfilled, (state, { payload }) => {
  //       state.loading = false;
  //       state.users = payload.users;
  //       state.entries = payload.entries;
  //       state.success = true;
  //     })
  //     .addCase(getData.rejected, (state, { payload }) => {
  //       state.loading = false;
  //       state.error = "Something went wrong";
  //     });
  // },
});

export const {
  toggleSidebarAction,
  setSidebarAction,
  showAlertAction,
  showErrorAlertAction,
  clearErrorAndMessage,
  redirectToLoginAction,
  logout,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
