import React, { useEffect } from "react";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";
import ManIcon from "../../assets/images/Man.png";
import { Box, Stack, Typography } from "@mui/material";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import Paths from "../../components/constants/Path";
import { useDispatch, useSelector } from "react-redux";
import { getOriginatorList } from "../../redux/originator/originatorSlice";
import Loader from "../../utils/Loader";

const CreateOriginator = () => {
  let history = useHistory();
  let originatorState = useSelector((state) => state.originator);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOriginatorList({
        limit: 10,
        currentPage: 1,
        debouncedSearch: "",
        filterName: "",
      })
    );
  }, []);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: " Orginator Profile",
    },
  ];
  const onClickHandlerToCreateOriginatorProfile = () => {
    return history.push(Paths.ORIGINATOR_FORM);
  };

  if (originatorState?.originatorsList?.length > 0) {
    // return history.push(Paths.ORIGINATOR_LIST);
    return <Redirect to={Paths.ORIGINATOR_LIST} />;
  }
  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      {originatorState.loading ? (
        <Loader />
      ) : (
        <WrapperComponent
          title="List of Originators"
          children={
            <Box height="419px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  height="auto"
                >
                  <img src={ManIcon} width="192px" height="192px"></img>
                  <Typography fontSize="18px" fontWeight="800" paddingTop={1}>
                    No originator profiles created yet
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="400"
                    color="#4D4D4D"
                    padding={1}
                  >
                    You can start by creating one
                  </Typography>
                  <PrimaryButton
                    text="Create Originator Profile"
                    width="384px"
                    onClickHandler={onClickHandlerToCreateOriginatorProfile}
                  ></PrimaryButton>
                </Stack>
              </Box>
            </Box>
          }
        ></WrapperComponent>
      )}
    </Layout>
  );
};

export default CreateOriginator;
