import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import Paths from "../../../components/constants/Path";
//? react-router-dom
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../Hooks/useDebounce";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import OriginatorDealListTable from "../../../components/Table/SortedDealTable";
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import Loader from "../../../utils/Loader";

const OriginatorDealList = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterName, setFilterName] = useState("All");
  const [isCreditFilterAdded, setIsCreditFilterAdded] = useState(false);
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);
  console.log({ dealState });

  const debouncedSearch = useDebounce(search, 1000);
  const filterHandler = (name) => {
    if(name == "PEL" || name == "PCHFL"){
      setEntityFilter(name);
      setFilterName("")
    }else{
      setFilterName(name);
      setEntityFilter("")
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        filterName,
        entityFilter,
        loanFilterExists: true,
        isCreditFilterAdded: true,
        source: "PHFL",
      })
    );
  }, [currentPage, filterName, entityFilter, debouncedSearch]);


  let history = useHistory();
  const clickHandlerToViewDetails = (deal) => {
    if (
      deal?.loanUploadStatus &&
      (deal.loanUploadStatus === "DONE" ||
        deal.loanUploadStatus === "FAILED" ||
        deal.loanUploadStatus === "PARTIALLY_DONE")
    ) {
      return history.push({
        pathname: `/originator/deal/${
          deal._id || deal.id || deal
        }/loan/summary`,
        state: deal,
      });
    }
    console.log({ deal });
    if (deal.loanUploadStatus === "FILE_RECEIVED") {
      return;
    }

    if (deal?.status === "CLOSED") {
      return history.push({
        pathname: `/originator/deal/${
          deal._id || deal.id || deal
        }/loan/summary`,
        state: deal,
      });
    }

    return history.push({
      pathname: `/originator/${deal._id || deal.id || deal}/upload-pool`,
      state: deal,
    });
  };
  const breadCrumbs = [
    {
      path: Paths.ORIGINATORHOME,
      pathName: "Home",
    },
    {
      pathName: "Deals List",
    },
  ];

  const filterList = [
    {
      id: "0",
      name: "All",
      value: "All",
    },
    {
      id: "1",
      name: "Pool Upload",
      value: "POOL_UPLOAD",
    },

    {
      id: "2",
      name: "Pool Submitted",
      value: "DONE",
    },
    {
      id: "3",
      name: "Pool Re-upload",
      value: "REUPLOAD",
    },
    {
      id: "4",
      name: "PEL",
      value: "PEL",
    },
    {
      id: "5",
      name: "PCHFL",
      value: "PCHFL",
    },
  ];

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      {dealState["loading"] ? (
        <Loader />
      ) : (
        <Box
          backgroundColor="white"
          minHeight="144px"
          maxHeight="auto"
          minWidth="auto"
          maxWidth="auto"
          boxShadow={4}
          borderRadius={2}
          sx={{ p: 2, m: 2 }}
        >
          <>
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="space-between"
              paddingBottom="12px"
              display="flex"
            >
              <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
                List of Deals
              </Typography>
            </Stack>

            <Divider></Divider>

            <div
              style={{
                padding: "16px",
                justifyContent: "space-between",
                alignItems: "space-between",
                display: "flex",
              }}
            >
              <div>
                {filterList.map((item, index) => {
                  console.log(item, index);
                  return (
                    <>
                      <StyledButton
                        id={item.id}
                        variant={
                          filterName === item.value || entityFilter == item?.name ? "contained" : "outlined"
                        }
                        sx={{
                          color: filterName === item.value || entityFilter == item?.name ? "white" : "black",
                          backgroundColor:
                            filterName === item.value || entityFilter == item?.name ? "#365069" : "white",
                          borderColor: "black",
                          mr: 1,
                        }}
                        onClick={() => filterHandler(item.value)}
                      >
                        {item.name}
                      </StyledButton>
                    </>
                  );
                })}
              </div>

              <TextField
                placeholder="Search"
                autoComplete="off"
                value={search}
                variant="standard"
                size="small"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} />
                      {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>

            <Box>
              <OriginatorDealListTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                setIsCreditFilterAdded={setIsCreditFilterAdded}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Pending Action ",
                  "Upload Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
                // statusList={filterList}
              ></OriginatorDealListTable>
            </Box>
          </>
        </Box>
      )}
    </Layout>
  );
};

export default OriginatorDealList;
