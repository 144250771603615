import React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import ReportsTable from "../../../components/Table/Phase2/ReportsTable";
import {
  getReportList,
  getVoucherDetails,
} from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import { useSelector } from "react-redux";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import VoucherTable from "./../../../components/Table/Phase2/VoucherTable";
import { Colors } from "../../../theme/theme";
import { ReportDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../../assets/images/Group 42722.svg";
import { useReactToPrint } from "react-to-print";
import TextWrapperComponent from "./../../../components/FormComponents/TextWrapperComponent";
import { SuffixTextField } from "./../../../components/FormComponents/SuffixPrefixTextFieldV2";
import VoucherCard from "./VoucherCard";
import CommonFields from "./CommonFields";
import jsPDF from "jspdf";
import Styles from "./VoucherCard.module.css";
import Styles1 from "./F2.module.css";
import Logo from "../../../assets/images/Logo.png";
import { setSidebarAction } from "../../../redux/app/appSlice";
import DOMPurify from "dompurify";
import { toJpeg } from "html-to-image";
import {
  MonthEndUploadListTableBox,
  ViewSOAReportDetailsTypography,
  ViewSOAReportSaveButton,
  ViewSOAReportStack,
  ViewSOAReportStack1,
  mainBoxStyle,
  reportDetailsIconStyle,
} from "./F2CSS";

const ViewSoaReport = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [transactionNo, setTransactionNo] = useState("");
  const [sortedList, setSortedList] = useState([]);
  const [state, setState] = useState({
    customerName: "",
  });

  //? useDispatch
  let dispatch = useDispatch();
  const history = useHistory();

  let financialYear = history?.location?.state?.financialYear || "";

  //? useSelector for getting the data from the store
  let { loading, ReportsList, totalPages, error, success, soaReport } =
    useSelector((state) => state.uploadReport);

  //Using debounce Hook for transactionNo
  const debouncedSearch = useDebounce(transactionNo, 1000);

  const componentRef = useRef();
  const handleSaveButton = useReactToPrint({
    content: () => componentRef.current,
  });

  // const handleSaveButton = () => {
  //     dispatch(setSidebarAction());

  //     //? converting html to image and  image to pdf for prevention of XSS attacks

  //     setTimeout(() => {
  //         toJpeg(componentRef.current, { quality: 0.95 })
  //             .then(function (dataUrl) {
  //                 const doc = new jsPDF();
  //                 doc.addImage(dataUrl, 'JPEG', 0, 0, 210, 297);
  //                 doc.save('SOA_Report.pdf');
  //             });
  //     }, 2000);
  // };

  //
  //Breadcrumb Navigation
  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Reports",
    },
  ];

  let icon = <DescriptionIcon sx={reportDetailsIconStyle} />;

  //user data from local storage
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const keyDownHandler = (e) => {
    console.log(e);
  };

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box sx={mainBoxStyle}>
        <div>
          <ReportDetails
            icon={icon}
            iconColor="#DBE3F8"
            formName="View Reports"
          />
        </div>

        <div className={Styles1.monthEndUploadImageDiv}>
          <img
            className={Styles1.monthEndUploadImage}
            src={Image}
            alt="image"
          />
        </div>
      </Box>
      <Box
        sx={MonthEndUploadListTableBox}
        ref={componentRef}
        onKeyDown={keyDownHandler}
      >
        <div className={Styles.header} style={{ display: "flex" }}>
          <div className={Styles.logoWrapper}>
            <div className={Styles.imageWrapper}>
              <img src={Logo} alt="logo" />
            </div>
            <p className={Styles.logoName}>Swayam</p>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleSaveButton}
              sx={ViewSOAReportSaveButton}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>

        <br />
        <>
          <div className={Styles1.ViewSOAReportDetailsDiv}>
            <Stack sx={ViewSOAReportStack}>
              <Typography sx={ViewSOAReportDetailsTypography}>
                SOA Report Details
              </Typography>
            </Stack>

            <Stack sx={ViewSOAReportStack1}>
              <div className={Styles.logoWrapper} style={{ width: "250px" }}>
                <p className={Styles.logoName}>
                  Financial Year : {financialYear && financialYear}
                </p>
              </div>
            </Stack>
          </div>
          <Divider></Divider>
          <div>
            {soaReport !== null && <CommonFields soaReport={soaReport} />}
          </div>
        </>
      </Box>
    </Layout>
  );
};

export default ViewSoaReport;
