import React from "react";
import SecoundaryButton from "../../../Buttons/SecoundaryButton";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import { Colors } from "../../../../theme/theme";
import DoneIcon from "@mui/icons-material/Done";
import { Stack, Button, Box } from "@mui/material";
import OutlineButton from "../../../Buttons/OutlineButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  skipPageAction,
  pageStatusAction,
} from "../../../../redux/originator/originatorSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styles from "./TabFooter1.module.css";
import { ConditionalStackStyle, skipBtnStyle, loadingStyle, submitBtnStyle, nextBtnStyle } from "./TabFooterCSS";

const TabFooter = ({
  onclickHandler,
  showSubmitButton = false,
  submitBtnText = "Upload & Check",
  submitHandler,
  isBtnDisable = true,
  showSkinBtn = false,
  skipBtnClickHandler,
  btnLoading = false,
}) => {
  let dispatch = useDispatch();
  let originatorState = useSelector((state) => state.originator);

  const skipFunction = (e) => {
    e.preventDefault();
    dispatch(skipPageAction(originatorState));
    dispatch(pageStatusAction({ pageName: "assignSPOC" }));
    skipBtnClickHandler();
  };

  const stackStyle = ConditionalStackStyle(showSkinBtn)
  // padding={2.5}
  // direction="row"
  // justifyContent={showSkinBtn ? " space-between" : "flex-end"}
  // alignItems={showSkinBtn ? " space-between" : "flex-end"}
  // display="flex
  return (
    <div className={styles.tabFooterWrapper}>
      <Stack sx={stackStyle} >
        {showSkinBtn ? (
          <Box>
            <Button
              onClick={skipFunction}
              variant="outlined"
              sx={skipBtnStyle}
              type="submit"
            >
              Skip
            </Button>
          </Box>
        ) : null}

        {showSubmitButton ? (
          <>
            {btnLoading ? (
              <Button
                onClick={onclickHandler}
                variant="contained"
                disabled={isBtnDisable}
                sx={loadingStyle}
                type="submit"
              >
                Loading...
              </Button>
            ) : (
              <Button
                endIcon={<DoneIcon />}
                onClick={onclickHandler}
                variant="contained"
                disabled={isBtnDisable}
                sx={submitBtnStyle}
                type="submit"
              >
                {submitBtnText}
              </Button>
            )}
          </>
        ) : (
          <Button
            endIcon={<ArrowForwardIosIcon />}
            onClick={onclickHandler}
            variant="contained"
            disabled={isBtnDisable}
            sx={nextBtnStyle}
            type="submit"
          >
            Next
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default TabFooter;
