import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../../Pagination/Pagination";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import {
  getAllDeals,
  setSortValue,
  setSortName,
} from "../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { ArrowDownward } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { arrowDownwardStyle, arrowUpwardStyle, midWidthStyle, norecordFoundStyle, originatorNameStyle, statusTopBox } from "../TableCSS";
import styles from "../Table.module.css"

const L1FinalDealTable = ({
  data,
  viewActionClickHandler,
  header,
  page,
  filterName,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  console.log({ data });

  let finalData = [...data];
  console.log("finaldatataa", finalData);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const Textcolor =
      statusType === "APPROVED" ||
      statusType === "Created" ||
      statusType === "DONE"
        ? "#277742"
        : statusType === "REJECTED"
        ? "#8D3431"
        : statusType === "CLOSED"
        ? "#2C4584"
        : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
      statusType === "Created" ||
      statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "REJECTED" || statusType === "FAILED"
        ? "#FCE6E5"
        : statusType === "CLOSED"
        ? "#E4EAFA"
        : "#FFF4E7";

    // const status = statusType.toLowerCase()

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    // var finalTime = hours + ":" + minutes + " " + AmOrPm;
    return finalTime;
  }

  var history = useHistory();

  const Actions = ({ isDealAdded, dealId, label, Icon, item, status }) => {
    return (
      <div
        onClick={() =>
          item?.status !== "REJECTED" ? viewActionClickHandler(item) : null
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          <Icon
            fontSize="small"
            sx={{
              color: item?.status !== "REJECTED" ? "#365069" : "#AFB9C3",
            }}
          ></Icon>

          <Typography
            sx={{
              color: item?.status !== "REJECTED" ? "#365069" : "#AFB9C3",
              fontSize: "14px",
            }}
          >
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  const headerFilters = {
    "Deal Name": "deal",
    "Asset Class": "assetClass",
    "Requested Pool Size": "poolSize",
    Transaction: "transaction",
    "Deal Creation Date": "startDate",
    "Created By": "createdBy",
  };

  const MapHeader = ({ data }) => {
    const [sort, setSort] = useState("");
    useEffect(() => {
      if (sort !== "") {
        dispatch(setSortValue(!dealState.sortValue));
        dispatch(setSortName(data));
        let sortName = headerFilters[`${data}`];
        if (sortName !== undefined) {
          dispatch(
            getAllDeals({
              currentPage: page,
              limit: 10,
              sortName: sortName,
              filterName,
              sortOrder: dealState.sortValue ? 1 : -1,
            })
          );
        }
      }
      return;
    }, [sort]);

    return (
      <StyledTableCell
        onClick={() =>
          data == "Action" || data == "Deal Status" || data == "Loan Status"
            ? null
            : setSort(!sort)
        }
        style={{
          cursor:
            data == "Action" || data == "Deal Status" || data == "Loan Status"
              ? null
              : "pointer",
        }}
      >
        {data}

        {data !== dealState?.sortFieldName ? null : dealState?.sortValue !==
          true ? (
          <ArrowUpward sx={arrowUpwardStyle} />
        ) : (
          <ArrowDownward sx={arrowDownwardStyle} />
        )}
      </StyledTableCell>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={midWidthStyle}>
              {header.map((data) => {
                return <MapHeader data={data} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((item) => {
              return (
                <TableRow key={item.id || item._id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>

                  <StyledTableCell1 component="th" scope="row">
                    {item?.loanType === undefined && "NA"}
                    {item?.loanType === "" && "NA"}
                    {item?.loanType || item?.loanTypeId}
                  </StyledTableCell1>

                  <StyledTableCell1 component="th" scope="row">
                    {item.requestedDealAmount === undefined ||
                    item.requestedDealAmount === null ||
                    item.requestedDealAmount === ""
                      ? "NA"
                      : numberFormat(Number(item?.requestedDealAmount))}
                  </StyledTableCell1>

                  <StyledTableCell1 component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell1>

                  <StyledTableCell1 component="th" scope="row">
                    {moment(item.createdAt).format("D MMM YYYY")}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {convertTime(item.createdAt) || ""}
                    </Typography>
                  </StyledTableCell1>

                  <StyledTableCell1 component="th" scope="row">
                    <StatusContainer statusType={item.status}></StatusContainer>
                  </StyledTableCell1>

                  {/* <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        label="View"
                        dealId={item.id}
                        item={item}
                        Icon={VisibilityIcon}
                        status={item.uploadstatus}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell> */}
                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        label="View"
                        dealId={item.id}
                        item={item}
                        Icon={VisibilityIcon}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>

      {data && data.length === 0 ? null : (
        <Pagination
          Page={page}
          TotalNoNumber={totalRecords}
          nextPageHandler={page === totalRecords ? null : nextPageHandler}
          prevPageHandler={prevPageHandler}
        />
      )}
    </div>
  );
};

export default L1FinalDealTable;
