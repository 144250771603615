import DescriptionIcon from "@mui/icons-material/Description";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Image from "../../../assets/images/Frame.svg";
import Layout from "../../../components/Layout/Layout";
import TabLayout from "../../../components/Layout/TabsLayout/Layout";
import Loader from "../../../utils/Loader";
import ProfileDetailsTab from "./ProfileDetailsTab";

const OriginatorProfileDetails = () => {
  const originatorState = useSelector((state) => state.originator);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(originatorState?.loading);
  }, []);

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },

    {
      path: "/originatorprofilelist",
      pathName: "Orginator Profile",
    },
    {
      pathName: "KYC Details",
    },
  ];

  let TabsList = [
    {
      name: "Profile",
      component: <ProfileDetailsTab></ProfileDetailsTab>,
      index: 0,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Layout breadCrumbs={breadCrumbs}>
            <TabLayout
              TabsList={TabsList}
              formName={originatorState?.name}
              icon={icon}
              iconColor={"#DBE3F8"}
              image={Image}
            />
          </Layout>
        </>
      )}
    </>
  );
};

export default OriginatorProfileDetails;
