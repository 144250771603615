import { useState } from "react";
import styles from "./QueryComp.module.css";

//? icons
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";

//? components
import IconButton from "../IconButton/IconButton";
import ViewThreadComp from "../ViewThreadComp/ViewThreadComp";
import Thread from "../ThreadComp/ThreadComp";
import moment from "moment/moment";

const QueryComp = ({
  id,
  name,
  query,
  replies,
  showInput,
  replyHandler,
  inputHandler,
  input,
  setInput,
  querySubmitHandler,
  createdAt,
  currentStage = '',
}) => {
  const [show, setShow] = useState(null);

  let convertIntoNormalDate = (date) => {
    // return new Date(date).toLocaleDateString();
    return moment(date).format("DD/MM/YYYY");
    // return moment(date).format("LT")
  };

  let convertIntoNormalTime = (date) => {
    // return new Date(date).toLocaleDateString();
    // return moment(date).format("DD/MM/YYYY");
    return moment(date).format("LT")
  };

  const buttonHandler = () => {
    console.log(showInput);
    setShow(!show);
    replyHandler(id);
    // show ? setInput("") : setInput("");
  };

  console.log({ showInput, replies });
  return (
    <>
      <div className={styles.QueryCompWrapper}>
        <div className={styles.dateTimeWrapper}>
          <p>
            {convertIntoNormalDate(createdAt)},
            <br />
            {convertIntoNormalTime(createdAt)}
          </p>
        </div>
        <div className={styles.queryWrapper}>
          <div className={styles.nameAndQueryWrapper}>
            <p className={styles.name}>Added By : {name} {currentStage && "at "}{currentStage && currentStage}</p>
            <p className={styles.query}> {query}</p>
          </div>

          {replies && replies.length == 0 && (
            <div className={styles.buttonWrapper}>
              {showInput && show ? (
                <IconButton
                  buttonHandler={buttonHandler}
                  icon={<CloseIcon />}
                />
              ) : (
                <IconButton
                  buttonHandler={buttonHandler}
                  icon={<CreateIcon />}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <ViewThreadComp
        id={id}
        replyHandler={replyHandler}
        inputHandler={inputHandler}
        buttonHandler={buttonHandler}
        replies={replies}
        show={show}
        setShow={setShow}
      />

      {/* <Thread name={"Divyansh"} query={"Something"} /> */}

      {showInput && show && (
        <div style={{ display: "flex" }}>
          {/* FOR SIDE GAP */}
          <div style={{ width: "11%" }}></div>
          <div
            style={{
              width: `calc(100% - 11%)`,
              background: "#FFFFFF",
              marginBottom: "25px",
            }}
          >
            <div
              className={styles.inputWrapper}
              style={{ position: "relative" }}
            >
              <textarea
                className={styles.input}
                onChange={inputHandler}
                value={input}
                type="text"
              />
              <button
                onClick={() => querySubmitHandler()}
                className={styles.postButton}
              // style={{}}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QueryComp;

