import { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import Home from "./Home";
import { useDispatch } from "react-redux";
import { getOriginatorList } from "./../redux/originator/originatorSlice";
import { clearDealState, getAllDeals } from "../redux/Deals/dealSlice";
import { useState } from "react";
import { getData } from "../API/apiHandler";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOriginatorList({
        limit: 10,
        currentPage: 1,
        debouncedSearch: "",
        filterName: "All",
      })
    );

    dispatch(
      getAllDeals({
        limit: 10,
        currentPage: 1,
        debouncedSearch: "",
        filterName: "All",
        source: "PHFL",
      })
    );
    return () => {
      dispatch(clearDealState());
    };
  }, []);

  const breadCrumbs = [
    {
      path: "/",
      pathName: "Home",
    },
    {
      path: "/profile",
      pathName: "Orginator Profile",
    },
    {
      pathName: "Create Orginator Profile",
    },
  ];

  return (
    <Layout componentName={"Home"}>
      <Home />
    </Layout>
  );
};

export default Dashboard;
