import { Divider, TextField, Grid } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader"
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent"
import { pageStatusAction } from "../../../redux/L1/kycDocumentsRequestSlice"
import TabFooter from "../TabFooter/TabFooter"
import { updateCreditScrubReport } from "../../../redux/originator/creditBureauSlice"
import { showAlertAction } from "../../../redux/app/appSlice"

const CibileScoreUpdation = ({ nextTabHandler }) => {

    let dealState = useSelector((state) => state.deal);

    let dispatch = useDispatch();
    let history = useHistory();

    let userData = JSON.parse(localStorage.getItem("UserData"));

    const [isBtnEnable, setIsBtnEnable] = React.useState(false);
    const [number, setNumber] = React.useState("" || dealState?.filterdata?.loanFilterData?.creditScrubScore);

    const nextBtnClickHandler = (e) => {
        e.preventDefault();
        console.log("step 2")
        console.log(number)
        console.log(dealState)
        console.log(dealState?.filterdata?.uniqueCode)
        console.log(dealState?.filterdata?._id)
            ;

        dispatch(pageStatusAction({ pageName: "CIBILScoreUpdation" }));
        dispatch(updateCreditScrubReport({ dealId: dealState?.filterdata?._id, uniqueCodeId: dealState?.filterdata?.uniqueCode, updatedcibilScore: number })).then((res) => {
            console.log(res.payload)
            history.push("/creditbureaureport/dealist");
            dispatch(showAlertAction("Cibil Score Updated Successfully"))
        });
        // nextTabHandler();
    };



    return (
        <>
            <DocumentHeader title={"Cibil Score"} ></DocumentHeader>
            <div style={{ padding: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TextWrapperComponent text="Current Cibil Score">
                            <TextField
                                size="small"
                                autoComplete="off"
                                variant="standard"
                                type="number"
                                disabled={!isBtnEnable}
                                min={0}
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                fullWidth
                                placeholder="Input Numbers"
                            />
                        </TextWrapperComponent>

                    </Grid>
                </Grid>
            </div>
            <Divider></Divider>
            {userData?.roles.length != 1 ? (
                <></>
            ) : (
                <>
                    {
                        isBtnEnable ? <TabFooter
                            showSkinBtn={true}
                            onclickHandler={nextBtnClickHandler}
                            isBtnDisable={number.length === 0}
                            skipBtnClickHandler={nextTabHandler}
                            label={"Update & Edit Pool"}
                            pageStatus={pageStatusAction}
                            pageName={"CIBILScoreUpdation"}
                        />
                            :
                            <TabFooter
                                showSkinBtn={true}
                                onclickHandler={() => setIsBtnEnable(true)}
                                isBtnDisable={dealState?.filterdata?.poolUploadFinal?.poolStatus === "APPROVED" ? true : false}
                                skipBtnClickHandler={nextTabHandler}
                                label={"Edit"}
                                pageStatus={pageStatusAction}
                                pageName={"CIBILScoreUpdation"}
                            />
                    }
                </>
            )}


        </>
    )

}

export default CibileScoreUpdation