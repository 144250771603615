import {
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pageStatusAction,
} from "../../../redux/L1/secondaryCheckSlice";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import TabFooter from "../TabFooter/TabFooter";
import FilterIcon from "../../../assets/icons/filter_icon.svg";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { statusConstants } from "../../constants/FiltersConstant";

const CreditFilter = ({ nextTabHandler, setValue }) => {
  let dispatch = useDispatch();
  const [type, setType] = useState("");
  const dealState = useSelector((state) => state.deal);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "creditFilters" }));
    nextTabHandler();
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const singleDeal = dealState?.filterdata;
  const loanType = singleDeal?.loanTypeData?.code;
  const AssetQualityFilters = [
    {
      name: "DPD",
      value: `${dealState.filterdata?.loanFilterData?.dpd} days`,
    },
    {
      name: "Overdues",
      value: dealState.filterdata?.loanFilterData?.isOverdue ? "Yes" : "NO",
    },
    {
      name: "Branch Level PAR90",
      value: `${dealState?.filterdata?.loanFilterData?.branchLevelPercentage}%`,
    },
    {
      name: "Branch Level PAR0",
      value: `${dealState?.filterdata?.loanFilterData?.branchPar0}%`,
    },
    {
      name: "Credit Scrub Score",
      value: dealState?.filterdata?.loanFilterData?.creditScrubScore,
    },
  ];

  const GeoFilters = [
    {
      name: "City Exculsions",
      value: dealState.filterdata?.excludedCitiesData
        .map((list) => list.name)
        .join(", "),
    },
    {
      name: "State Exclusions",
      value: dealState.filterdata?.excludedStatesData
        .map((list) => list.name)
        .join(", "),
    },
  ];

  const LoanTenureFilters = [
    {
      name: "Seasoning/ MHP",
      value: dealState.filterdata?.loanFilterData?.completedTenure,
    },
    {
      name: "Balance Tenure (in months)",
      value: `${dealState.filterdata?.loanFilterData?.remainingTenure} months`,
    },
    {
      name: "Total Tenure (in months)",
      value: `${dealState.filterdata?.loanFilterData?.totalTenure} months`,
    },
    {
      name: "Max Lifetime DPD (in months",
      value: `${dealState.filterdata?.loanFilterData?.maxTotalDpd} days`,
    },
    {
      name: "Max lifetime dpd -12 months",
      value: `${dealState.filterdata?.loanFilterData?.max12MonthDpd} days`,
    },
    {
      name: "Max lifetime dpd -24 months",
      value: `${dealState.filterdata?.loanFilterData?.max24MonthDpd} days`,
    },
    {
      name: "Restructed",
      value: dealState.filterdata?.loanFilterData?.isRestructured
        ? "Yes"
        : "NO",
    },
  ];

  const LoanPropertyFilters = [
    {
      name: "LTV",
      value: `${dealState.filterdata?.loanFilterData?.ltv}%`,
    },
    {
      name: "FOIR",
      value: `${dealState.filterdata?.loanFilterData?.foir}%`,
    },
    {
      name: "Payment Mode",
      value:
        dealState.filterdata?.loanFilterData?.paymentModes &&
        dealState.filterdata?.loanFilterData?.paymentModes
          .map((list) => list)
          .join(", "),
    },
    {
      name: "Type of Property",
      value:
        dealState.filterdata?.loanFilterData?.propertyType &&
        dealState.filterdata?.loanFilterData?.propertyType
          .map((list) => list)
          .join(", "),
    },
    {
      name: "Type of Vehicle",
      value:
        dealState?.filterdata?.loanFilterData?.vehicleTypes &&
        dealState?.filterdata?.loanFilterData?.vehicleTypes
          .map((list) => list)
          .join(", "),
    },
    {
      name: "Customer Segments",
      value:
        dealState.filterdata?.loanFilterData?.customerSegment &&
        dealState.filterdata?.loanFilterData?.customerSegment
          .map((list) => list)
          .join(", "),
    },
    {
      name: "Customer Constitution",
      value:
        dealState.filterdata?.loanFilterData?.customerConstitution &&
        dealState.filterdata?.loanFilterData?.customerConstitution
          .map((list) => list)
          .join(", "),
    },
    {
      name: "Max. Principal Outstanding",
      value: `₹ ${dealState.filterdata?.loanFilterData?.maxPrincipalOutstanding} Cr.`,
    },
  ];

  if (singleDeal?.loanTypeData?.code !== "MFI") {
    LoanPropertyFilters.push({
      name: "PDD Completion",
      value: dealState.filterdata?.loanFilterData?.isPddCompletion
        ? "Yes"
        : "NO",
    });
  }

  const handleEditOnClick = (e) => {
    setType(e);
  };

  return (
    <>
      <DocumentHeader
        title={"Asset Quality Filters"}
        startIcon={<img alt="filterIcon" src={FilterIcon} />}
        showEditButton={false}
        handleOnClick={() => handleEditOnClick("AssetFilter")}
      ></DocumentHeader>
      <div style={{ padding: "24px" }}>
        <Grid container spacing={4}>
          {AssetQualityFilters.map((item) => (
            <>
              {item?.value != "undefined%" &&
                item?.value != "null%" &&
                item?.value != "" &&
                item?.value != undefined && (
                  <Grid item xs={4} key={item.name} sx={{ paddingTop: "5px" }}>
                    <TextWrapperComponent text={item.name}>
                      <TextField
                        variant="standard"
                        autoComplete="off"
                        fontSize="16px"
                        fontWeight="400"
                        defaultValue={item.value}
                        InputProps={{
                          readOnly: type === "AssetFilter" ? false : true,
                          disableUnderline:
                            type === "AssetFilter" ? false : true,
                        }}
                        sx={{ color: "#20303F" }}
                      />
                    </TextWrapperComponent>
                  </Grid>
                )}
            </>
          ))}
        </Grid>
      </div>

      {dealState?.filterdata?.excludedCitiesData.length != 0 ||
        dealState?.filterdata?.excludedStatesData.length != 0 ? (
        <>
          <DocumentHeader
            title={"Geographical Filters"}
            startIcon={<img alt="filterIcon" src={FilterIcon} />}
            showEditButton={false}
            handleOnClick={() => handleEditOnClick("LoanTenureFilter")}
          ></DocumentHeader>
          <div style={{ padding: "24px" }}>
            <Grid container spacing={4}>
              {GeoFilters.map((item) => (
                <>
                  {item?.value != "" && (
                    <Grid
                      item
                      xs={12}
                      key={item.name}
                      sx={{ paddingTop: "5px" }}
                    >
                      <TextWrapperComponent text={item.name}>
                        <TextField
                          variant="standard"
                          autoComplete="off"
                          fontSize="16px"
                          fontWeight="400"
                          minWidth="800px"
                          style={{ width: "652px" }}
                          defaultValue={item.value}
                          InputProps={{
                            readOnly:
                              type === "Geographical Filters" ? false : true,
                            disableUnderline:
                              type === "Geographical Filters" ? false : true,
                          }}
                          sx={{ color: "#20303F" }}
                        />
                      </TextWrapperComponent>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </div>
        </>
      ) : (
        <></>
      )}

      <DocumentHeader
        title={"Loan Tenure Filters"}
        startIcon={<img alt="filterIcon" src={FilterIcon} />}
        showEditButton={false}
        handleOnClick={() => handleEditOnClick("LoanTenureFilter")}
      ></DocumentHeader>
      <div style={{ padding: "24px" }}>
        <Grid container spacing={4}>
          {LoanTenureFilters.map((item) => (
            <>
              {item?.value != "undefined%" &&
                item?.value != "null%" &&
                item?.value != "undefined months" &&
                item?.value != "null months" &&
                item?.value != "undefined days" &&
                item?.value != "null days" &&
                item?.value != "" &&
                item?.value != undefined && (
                  <Grid item xs={4} key={item.name} sx={{ paddingTop: "5px" }}>
                    <TextWrapperComponent text={item.name}>
                      <TextField
                        variant="standard"
                        autoComplete="off"
                        fontSize="16px"
                        fontWeight="400"
                        defaultValue={item.value}
                        InputProps={{
                          readOnly: type === "LoanTenureFilter" ? false : true,
                          disableUnderline:
                            type === "LoanTenureFilter" ? false : true,
                        }}
                        sx={{ color: "#20303F" }}
                      />
                    </TextWrapperComponent>
                  </Grid>
                )}
            </>
          ))}
        </Grid>
      </div>

      <>
        {loanType !== "MFI" && (
          <>
            <DocumentHeader
              title={"Loan Property Filters"}
              startIcon={<img alt="filterIcon" src={FilterIcon} />}
              showEditButton={false}
              handleOnClick={() => handleEditOnClick("LoanPropertyFilter")}
            ></DocumentHeader>
            <div style={{ padding: "24px" }}>
              <Grid container spacing={4}>
                {LoanPropertyFilters.map((item) => (
                  <>
                    {item?.value != "undefined%" &&
                      item?.value != "₹ undefined Cr." &&
                      item?.value != "₹ null Cr." &&
                      item?.value != "" &&
                      item?.value != "null%" &&
                      item?.value != undefined && (
                        <Grid
                          item
                          xs={4}
                          key={item.name}
                          sx={{ paddingTop: "5px" }}
                        >
                          <TextWrapperComponent text={item.name}>
                            <TextField
                              variant="standard"
                              autoComplete="off"
                              fontSize="16px"
                              fontWeight="400"
                              defaultValue={item.value}
                              InputProps={{
                                readOnly:
                                  type === "LoanPropertyFilter" ? false : true,
                                disableUnderline:
                                  type === "LoanPropertyFilter" ? false : true,
                              }}
                              sx={{ color: "#20303F" }}
                            />
                          </TextWrapperComponent>
                        </Grid>
                      )}
                  </>
                ))}
              </Grid>
            </div>
          </>
        )}
      </>
      {userData?.roles.length != 1 ? (
        <>
        </>
      ) : (
        <>
          {type === "" ? (
            <TabFooter
              isBtnDisable={
                dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                  statusConstants.APPROVED
                  ? true
                  : false
              }
              onclickHandler={nextBtnClickHandler}
            ></TabFooter>
          ) : (
            <TabFooter
              label="Save Changes"
              endIcon={<DoneIcon />}
              isBtnDisable={
                dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                  statusConstants.APPROVED
                  ? true
                  : false
              }
              onclickHandler={nextBtnClickHandler}
            ></TabFooter>
          )}
        </>
      )}
    </>
  );
};

export default CreditFilter;
