import { createTheme } from "@mui/material/styles";

export const Colors = {
  primary: "#98BFD5",
  secondary: "#F26841",
  border: "#AFB9C3",
  white: "#FFFFFF",
  black: "#333333",
  light_grey: "#666666",
  piramal_blue: "#2B4054",
  piramal_orange: "#F26841",
  piramal_light_orange: "#FAC3B3",
  piraml_light_blue: "#365069",
  Piramal_Blue_PB_20: "#D7DCE1",
  Piramal_Blue_PB_10: "#EBEEF0",
  green: "#8DDDA8",
  light_green: "#F8FAFF",
  emerald_green: "#D9F4E2",
  Emerald_Green_G_10: "#E3F6E9",
  royal_blue: "#92ABEA",
  Piramal_Orange_PO_10: "#FEF0EC",
  Piramal_Blue_PB_60: "#8696A5",
  Piramal_Disabled: "black",
  ReportDetails_iconColor: "#DBE3F8",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },

    others: {
      main: Colors.black,
    },
    text: {
      disabled: Colors.Piramal_Disabled,
    },
  },

  typography: {
    fontFamily: "Nunito",
  },
});
export default theme;
