import styles from "./ViewDownloadDeleteComp.module.css";

// import View from "../../assets/View.png";
// import Delete from "../../assets/Delete.png";
import Eye from "../../assets/icons/eye.svg";
import Download from "../../assets/icons/download.svg";
import FileIcon from "../../assets/icons/Icon3.svg";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { getDownloadImage } from "../../redux/originator/originatorSlice";
import { showErrorAlertAction } from "../../redux/app/appSlice";
import { downloadImageReports } from "../../redux/Phase2/SearchReports";

const ViewDownloadDeleteCompAPI = ({ showEye = true, fileName, id }) => {
    let uploadReportState = useSelector((state) => state.uploadReport);
    const dispatch = useDispatch();


    async function downloadExcel(id) {
        // setLoading(true);
        console.log(id)
        try {
            let result = await dispatch(downloadImageReports({ id, fileName })).unwrap()
            return;

        } catch (error) {
            // setLoading(false)
            dispatch(showErrorAlertAction("Something went wrong! Please try again later."))
        }
    };



    let fileData = fileName.split(".");
    console.log(fileData[0]);
    console.log(fileData[1]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.filenameWrapper}>
                <img src={FileIcon} />
                <p>{`${fileData[0].slice(0, 24)}.${fileData[1]}`}</p>
            </div>
            <div className={styles.iconsWrapper}>
                <div className={styles.singleIconWrapper}>
                    <div onClick={() => downloadExcel(id)}>
                        <img src={Download} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewDownloadDeleteCompAPI;