import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Delete from "../../assets/icons/blueDelete.svg";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Typography,
  Box,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Colors } from "../../theme/theme";
import UploadIcon from "../../assets/icons/UploadIcon.svg";

import styles from "./Wrapper.module.css";
import Loader from "../../utils/Loader";

export const ChooseFileComponent = ({
  isDeal = false,
  label = "(Supported file formats : .pdf, .doc, docx, .xls, .xlsx, .csv)",
}) => {
  return (
    <Box
      boxShadow={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        p: 2,
        borderRadius: "10px",
        borderWidth: "1px",
        minHeight: "70px",
      }}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={1}>
          <img src={UploadIcon}></img>
          <Typography
            fontSize="14px"
            fontWeight="400"
            color={Colors.piraml_light_blue}
          >
            Upload File
          </Typography>
        </Stack>

        <Typography
          fontSize="12px"
          fontWeight="200"
          color={Colors.Piramal_Blue_PB_60}
        >
          {<>{label}</>}
        </Typography>
      </Stack>
    </Box>
  );
};

export const SelectedFileComponent = ({
  fileName,
  fileSize,
  onClickHandler,
  isLoading = false,
}) => {
  let fileData = fileName.split(".");
  console.log(fileData[1]);

  // make 10 size of circular progress ba

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.DetailsWrapper}>
          {isLoading ? (
            <>
              <div className={styles.together}>
                <div padding="10px"></div>
                <p>Uploading...</p>
              </div>
            </>
          ) : (
            <>
              <div className={styles.together}>
                <CheckCircleIcon
                  sx={{ color: "#41C66E", width: "20px", height: "20px" }}
                ></CheckCircleIcon>
                <div padding="10px"></div>
                <p>{`${fileData[0].slice(0, 24)}.${fileData[1]}`}</p>
              </div>
              <p> {fileSize} KB</p>
            </>
          )}
        </div>
        {!isLoading && (
          <div
            className={styles.iconWrapper}
            
            onClick={onClickHandler}
          >
            <img src={Delete}></img>
          </div>
        )}
      </div>
    </>
  );
};
export const AddMoreFileComponent = ({ clickhandler }) => {
  return (
    <div>
      <Box
        sx={{
          paddingLeft: 2,
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            onClick={clickhandler}
            variant="none"
            startIcon={<AddIcon />}
            sx={{
              fontSize: "12px",
              fontWeight: "700",
              color: Colors.piraml_light_blue,
              textTransform: "none",
            }}
          >
            Add More Files
          </Button>
        </Box>
      </Box>
    </div>
  );
};
