import React from "react";
import TextWrapperComponent from "../../../../FormComponents/TextWrapperComponent";
import {
  SuffixTextField,
  SuffixTextFieldE,
  SuffixTextFieldV12,
} from "../../../../FormComponents/SuffixPrefixTextFieldV2";
import BasicSelect from "../../../TabSelectOptions/SelectTabOptions";
import { TextField, Grid, FormControl } from "@mui/material";

export default function DealDetailsForm({
  TransactionList,
  transactionType,
  setTransactionType,
  Values,
  dealState,
  loanTypeId,
  setLoanTypeId,
  onChangeHandler,
}) {
  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Transaction">
            <FormControl variant="standard" sx={{ width: 220 }}>
              {TransactionList && TransactionList.length != 0 && (
                <BasicSelect
                  data={TransactionList}
                  value={transactionType}
                  setValue={setTransactionType}
                  defaultValue={Values.transactionType}
                  placeholder="Select Type Of Transaction"
                />
              )}
            </FormControl>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Product/Asset Class">
            <FormControl variant="standard" sx={{ width: 220 }}>
              <BasicSelect
                data={dealState.allLoanTypes}
                value={loanTypeId}
                setValue={setLoanTypeId}
                defaultValue={Values.loanTypeId}
                placeholder="Assign Product/ asset class"
              />
            </FormControl>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text=" Vintage in Operations">
            <SuffixTextFieldE
              placeholder="Years in operations"
              endAdornmentText="Years"
              value={Values.vintageOperation}
              onChangeHandler={onChangeHandler("vintageOperation")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="AUM">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="INR Crs"
              value={Values.aum}
              onChangeHandler={onChangeHandler("aum")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text=" Net Worth">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="INR Crs"
              value={Values.netWorth}
              onChangeHandler={onChangeHandler("netWorth")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="PAT">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="INR Crs"
              value={Values.pat}
              onChangeHandler={onChangeHandler("pat")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text=" GNPA%">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="%"
              value={Values.gnpaPercent}
              onChangeHandler={onChangeHandler("gnpaPercent")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="NNPA%">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="%"
              value={Values.nnpaPercent}
              onChangeHandler={onChangeHandler("nnpaPercent")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Requested Deal Size">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="₹  "
              value={Values.requestedDealAmount}
              onChangeHandler={onChangeHandler("requestedDealAmount")}
            />
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Rating">
            <SuffixTextField
              placeholder="Input Text"
              endAdornmentText=""
              value={Values.rating}
              type="text"
              onChangeHandler={onChangeHandler("rating")}
            />

          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="CRAR%">
            <SuffixTextFieldE
              placeholder="Input Text"
              endAdornmentText="%"
              value={Values.crarPercent}
              onChangeHandler={onChangeHandler("crarPercent")}
            />
          </TextWrapperComponent>
        </Grid>
      </Grid>
    </form>
  );
}
