import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import Loader from "../../../utils/Loader";

const BasicInfo = ({ nextTabHandler }) => {

  let originatorState = useSelector((state) => state.originator);

  return (
    <>
      <h1>{originatorState.loading}</h1>
      {originatorState.fetchOriginatorLoding ? (
        <Loader />
      ) : (
        <div style={{ padding: "24px" }}>
          <Grid container spacingLeft={4}>
            <Grid item xs={6}>
              <Typography fontSize="14px" fontWeight="400" color="#808080">
                Originator Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize="14px" fontWeight="400" color="#808080">
                Type of Originator
              </Typography>
            </Grid>

            <>
              <Grid item xs={6} sx={{ paddingTop: "5px", fontWeight: "500" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000000",
                    height: "24px",
                  }}
                >
                  {originatorState.originatorName}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ paddingTop: "5px" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000000",
                    height: "24px",
                  }}
                >
                  {originatorState.originatorType}
                </Typography>
              </Grid>
            </>
          </Grid>
        </div>
      )}
    </>
  );
};

export default BasicInfo;
