import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../Hooks/useDebounce";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import Layout from "../../../components/Layout/Layout";
import L2LoanDealListTable from "../../../components/Table/L2LoanDealistTable";
import Paths from "../../../components/constants/Path";
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import Loader from "../../../utils/Loader";
import { FilterConstants } from "../../../components/constants/FiltersConstant";
import styles from "./Loans.module.css"

const L2LoanDealList = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);
  const filteredDeals = dealState?.allDeals;

  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if (name == "PEL" || name == "PCHFL") {
      setEntityFilter(name);
      setFilterName("")
    } else {
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName, entityFilter]);

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        filterName,
        entityFilter,
        source: "PHFL",
        isCreditBureauCheckDone: true,
        isSecondaryCheckDone: true,
        isKycVerificationDone: true,
      })
    );
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deal List",
    },
  ];

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];

  let history = useHistory();

  const clickHandlerToViewDetails = (deal) => {
    console.log(`/loan/loanId/deal/dealId/deal-details/loan-details`);
    return history.push({
      pathname: `/loan/pool-upload/${deal.poolUploadFinal._id}/deal-details/${deal._id}/loan-details`,
      state: deal,
    });
  };

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
          </Stack>

          <Divider />

          <div className={styles.filterListWrapper}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item || entityFilter == item ? "white" : "black",
                        backgroundColor:
                          filterName === item || entityFilter == item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <Loader />
          ) : (
            <Box style={{ height: "calc( 100% - 64px)" }}>
              <L2LoanDealListTable
                data={filteredDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                filterName={filterName}
                page={currentPage}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
                statusList={filterList}
              />
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default L2LoanDealList;
