import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkedPages: [
    { pageName: "basicInfo", isChecked: false },
    { pageName: "assignSPOCs", isChecked: false },
  ],
};

const dealDuplicationSlice = createSlice({
  name: "dealDuplicationSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
  },
});

export const { pageStatusAction } = dealDuplicationSlice.actions;

export const dealDuplicationReducer = dealDuplicationSlice.reducer;
