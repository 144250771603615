import React, { useEffect, useState } from "react";

import TabsLayout from "../../../components/Layout/TabsLayout/Layout";

import Layout from "../../../components/Layout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";

import Histories from "../../../components/History/Histories";
import { useSelector, useDispatch } from "react-redux";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../../redux/Deals/dealSlice";
import { useParams, useHistory } from "react-router-dom";
import QueriesPage from "../../../components/Queries/Queries";
import Tabs from "../../../components/Tabs/Tabs";
import InvestmentMemo from "../../../components/Tabs/L1FinalDeal/InvestmentMemo";
import Paths from "../../../components/constants/Path";
import EntityDeatilsComp from "../../../components/Tabs/OriginatorEntityDetails/EntityDetailsComp";
import Loader from "../../../utils/Loader";
import { getEntityTemplate } from "../../../redux/Entity/entitySlice";

const CreateEntity = () => {
  const dealState = useSelector((state) => state.deal);
  const entityState = useSelector((state) => state.entity);
  console.log({ dealState });
  const history = useHistory();
  const dealInfo = history.location.state;
  // const [callFunc, setCallFunc] = useState(false);

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  const params = useParams();
  let { dealId } = params;
  const dispatch = useDispatch();

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.EntityDealList,
      pathName: "Deal",
    },

    {
      pathName: "Test",
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  const tabs = [
    {
      tabName: "Entity Details",
      comp: EntityDeatilsComp,
      heading: `Entity Details for - ${dealState?.name}`,
    },
  ];

  let KYCApproveRejectState = useSelector(
    (state) => state.auditorKYCApproveReject
  );

  //UseEffect
  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
    dispatch(getEntityTemplate({ type: "ENTITY" }));
  }, []);

  //UseEffect

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      {dealState?.loading && entityState.entityData !== null ? (
        <Loader />
      ) : (
        <TabsLayout
          formName="Test"
          image={Image}
          icon={icon}
          iconColor={"#DBE3F8"}
          TabsList={[
            {
              name: "Loan",
              component: (
                <Tabs
                  tabs={tabs}
                  status={KYCApproveRejectState["checkedPages"]}
                  breadCrumbs={breadCrumbs}
                />
              ),
              index: 0,
            },
            {
              name: "Queries",
              index: 3,
              component: (
                <QueriesPage
                  dealIdorOriginatorId={params.dealId}
                  currentStage={dealState?.filterdata?.currentStage || ""}
                />
              ),
            },

            { name: "History", index: 3, component: <Histories id={dealId} /> },
          ]}
        ></TabsLayout>
      )}
    </Layout>
  );
};

export default CreateEntity;
