import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import {
  getAllDeals,
  setSortValue,
  setSortName,
} from "../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "dateformat";
import _ from "lodash";
import styles from "./Table.module.css"
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { ArrowDownward } from "@mui/icons-material";
import { getOriginatorList, setSortOrgName, setSortOrgValue } from "../../redux/originator/originatorSlice";
import { actionTypo, actionsIcon, alignItemStle, arrowDownwardStyle, arrowUpwardStyle, midWidthStyle, norecordFoundStyle, statusTopBox } from "./TableCSS";

const DealTable = ({
  nextPageHandler,
  prevPageHandler,
  rows,
  data,
  actionOnClick,
  page,
  limit,
  totalPages,
}) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  const originatorState = useSelector((state) => state.originator);
  console.log({ data });
  console.log({ page, totalPages });
  const history = useHistory();

  let finalData = data && [...data];
  console.log({ finalData });



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const Textcolor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "DONE"
        ? "#277742"
        : statusType === "REJECTED"
          ? "#8D3431"
          : statusType === "CLOSED"
            ? "#2C4584"
            : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "REJECTED" || statusType === "FAILED"
          ? "#FCE6E5"
          : statusType === "CLOSED"
            ? "#E4EAFA"
            : "#FFF4E7";

    // const status = statusType.toLowerCase()

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const Actions = ({ isDealAdded, label, Icon, id, onClickHandler }) => {
    return (
      <div onClick={onClickHandler} style={{ cursor: "pointer" }}>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
          // paddingLeft="30px"
          sx={{
            justifyContent: "start",
          }}
        >
          <Icon sx={actionsIcon}></Icon>

          <Typography sx={actionTypo}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  const formatRequestedPool = (value) => {
    if (value !== undefined) {
      console.log({ value });
      numberFormat(Number(value));
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  var nf = new Intl.NumberFormat();

  const headerFilters = {
    "Originator Name": "originatorName",
    "Originator Type": "OriginatorType",
    "Creation Date": "createdAt",
    "Status": "status",
    "Action": "action",
  };



  const MapHeader = ({ data }) => {
    const [sort, setSort] = useState("");
    useEffect(() => {
      if (sort !== "") {
        // setCallFunc({ data, sort });
        dispatch(setSortOrgValue(!originatorState.sortOrgValue));
        dispatch(setSortOrgName(data));
        let sortName = headerFilters[`${data}`];
        console.log({ sortName })
        if (sortName !== undefined) {
          dispatch(
            getOriginatorList({
              currentPage: page,
              limit: 10,
              sortName: headerFilters[`${data}`],
              sortOrder: originatorState.sortOrgValue ? 1 : -1,
            })
          );
        }
      }
      return;
    }, [sort]);

    return (
      <StyledTableCell
        onClick={() =>
          data == "Action" || data == "Originator Name" || data == "Originator Type"
            ? null
            : setSort(!sort)
        }
        style={{
          cursor:
            data == "Action" || data == "Originator Name" || data == "Originator Type"
              ? null
              : "pointer",
        }}
      >
        {data}

        {data !== originatorState?.sortOrgName ? null : originatorState?.sortOrgValue !==
          true ? (
          <ArrowUpward sx={arrowUpwardStyle} />
        ) : (
          <ArrowDownward sx={arrowDownwardStyle} />
        )}
      </StyledTableCell>
    );
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    // var finalTime = hours + ":" + minutes + " " + AmOrPm;
    return finalTime;
  }
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={midWidthStyle}>
              {rows && rows.map((data) => {
                return <MapHeader data={data} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData &&
              finalData.length !== 0 &&
              finalData.map((item, index) => {
                return (
                  <TableRow key={item.index}>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.name}
                    </StyledTableCell1>
                    <StyledTableCell component="th" scope="row">
                      {item?.originatorTypeIdData?.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {moment(item?.createdAt).format("D MMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item?.status.replace("_", " ")}
                      ></StatusContainer>
                    </StyledTableCell>
                    <TableCell sx={alignItemStle}>
                      <StyledNoBorderTableCell>
                        <Actions
                          label="View"
                          id={item?._id}
                          Icon={VisibilityIcon}
                          onClickHandler={() => {
                            if (userData.roles[0] === "L1") {
                              history.push(
                                `/editoriginator/view/details/${item._id}`
                              );
                            } else {
                              history.push(
                                `/view/${item._id}/l2originatorsdetails`
                              );
                            }
                          }}
                        ></Actions>
                      </StyledNoBorderTableCell>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {data && data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>

      {data && data.length === 0 ? null : (
        <Pagination
          Page={page}
          TotalNoNumber={totalPages}
          nextPageHandler={page === totalPages ? null : nextPageHandler}
          prevPageHandler={prevPageHandler}
        />
      )}
    </div>
  );
};

export default DealTable;
