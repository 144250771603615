import React, { useState, useMemo } from "react";
import {
  MenuItem,
  Select,
  Box,
  Checkbox,
  ListItemText,
  ListSubheader,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Styles from "./FormComponents.module.css";
import {
  SelectComponentMenuProps,
  SelectMaxListSearchDealInput,
  SelectMaxListSubHeader,
  SelectMaxMenuProps,
} from "./FormComponents";
export const SelectComponent = ({
  placeholder,
  optionList,
  value,
  onClickHandler,
}) => {
  const Placeholder = ({ children }) => {
    return <div className={Styles.placeHolder}>{children}</div>;
  };

  const [filter, setFilter] = useState("");

  console.log({ optionList });
  const MenuProps = {
    PaperProps: {
      style: SelectMaxMenuProps,
    },
  };

  const containsText = (text, searchText, dealCodeSerach) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
    dealCodeSerach.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  let displayedOptions = useMemo(
    () =>
      optionList.filter((option) =>
        containsText(option.name, filter, option.dealCode)
      ),
    [filter]
  );
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      value={value}
      onChange={onClickHandler}
      displayEmpty
      size={"small"}
      variant="standard"
      fullWidth
      MenuProps={MenuProps}
      renderValue={
        value !== ""
          ? undefined
          : () => <Placeholder>{placeholder}</Placeholder>
      }
    >
      <ListSubheader sx={SelectMaxListSubHeader}>
        <TextField
          size="medium"
          placeholder="Search deal"
          fullWidth
          value={filter}
          sx={{
            input: SelectMaxListSearchDealInput,
          }}
          onChange={(e) => setFilter(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {displayedOptions.map((data) => (
        <MenuItem value={data.id || data._id}>
          {data?.dealCode?.length > 0
            ? data.name + "(" + data?.dealCode + ")"
            : data.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export const SelectMonthComponent = ({
  placeholder,
  optionList,
  value,
  onClickHandler,
}) => {
  const Placeholder = ({ children }) => {
    return <div className={Styles.placeHolder}>{children}</div>;
  };
  const compWidth = 250;
  console.log({ optionList });
  const MenuProps = {
    PaperProps: {
      style: SelectComponentMenuProps(compWidth),
    },
  };

  return (
    <Select
      IconComponent={ExpandMoreIcon}
      value={value}
      onChange={onClickHandler}
      displayEmpty
      size={"small"}
      variant="standard"
      fullWidth
      MenuProps={MenuProps}
      renderValue={
        value !== ""
          ? undefined
          : () => <Placeholder>{placeholder}</Placeholder>
      }
    >
      {optionList.map((data) => (
        <MenuItem value={data?.text}>{data?.text}</MenuItem>
      ))}
    </Select>
  );
};
