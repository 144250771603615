import React from "react";
import { MenuItem, Select, Box, Checkbox, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RxCross2 } from 'react-icons/rx';

export const CustomSelect = ({
  placeholder,
  optionList = [],
  value,
  disabled,
  onClickHandler,
  compWidth,
  fieldName = "name",
  fieldValue = "id",
}) => {
  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  console.log({ optionList });
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: compWidth,
        background: "#EBEEF0",
      },
    },
  };
  console.log({ value });

  return (
    <Select
      // IconComponent={ExpandMoreIcon}
      value={value}
      disabled={disabled}
      onChange={onClickHandler}
      displayEmpty
      size={"small"}
      variant="standard"
      IconComponent={() => !disabled && <RxCross2 onClick={onClickHandler} style={{ cursor: "pointer" }} />}
      fullWidth
      MenuProps={MenuProps}
      renderValue={
        value !== ""
          ? undefined
          : () => <Placeholder>{disabled ? "" : placeholder}</Placeholder>
      }
    >
      {optionList.map((data) => (
        <MenuItem value={data[fieldValue]}>
          <option value={data[fieldValue]}>{data[fieldName]}</option>
        </MenuItem>
      ))}
    </Select>
  );
};
