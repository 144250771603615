import styles from "./IconButton.module.css";

const Button = ({ icon, buttonHandler }) => {
  return (
    <button onClick={buttonHandler} className={styles.button}>
      {icon}
    </button>
  );
};

export default Button;
