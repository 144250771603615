import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";
import OutlineButton from "../Buttons/OutlineButton";
import { BoxStyle } from "./ModalCSS";
import Styles from "./Modal.module.css"


export default function BasicModal({
  open = false,
  setOpen,
  title = "No Title",
  positiveText,
  negativeText,
  positiveOnClickHandler,
  negativeOnClickHandler,
  setloading,
  children,
  setOpeMsg,
  isNegativeHandler = false,
  isDisable
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    console.log({ isNegativeHandler });
    if (isNegativeHandler) {
      console.log("step 1");
      setOpen(false);
      // setloading(false);
      console.log({ open });
      negativeOnClickHandler();
    }

    setOpen(false);
    setloading(false);
  };
  console.log({ open });

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BoxStyle}>
          <div className={Styles.topDiv} >
            <p className={Styles.pTag}>
              {title}
            </p>
          </div>
          <div className={Styles.childrenDiv}>
            {children}
          </div>

          <div className={Styles.btnTopDiv}>
            <div></div>
            <div className={Styles.btnMidDiv}>
              <div className={Styles.btnLowerDiv} >
                <OutlineButton
                  showIcon={false}
                  text={negativeText}
                  onClickHandler={handleClose}
                />
              </div>
              <PrimaryButton
                showIcon={false}
                text={positiveText}
                onClickHandler={positiveOnClickHandler}
                width="60px"
                isDisable={isDisable}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
