import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from "../../../assets/images/Group 42722.svg";
import AllDocumentsV2 from "../../../components/AllDocumentsV2";
import Histories from "../../../components/History/Histories";
import Layout from "../../../components/Layout/Layout";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../../components/Queries/Queries";
import {
  getDealDocuments,
  getSingleDealDetails,
} from "../../../redux/Deals/dealSlice";
import { getSingleAmlUpload } from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import { getSingleDeal } from "../../../redux/originator/poolUploadSlice";
import Loader from "../../../utils/Loader";
import AMLForm from "../../Phase3/AML/AMLForm";
import OriginatorLoanUploadSummaryDetails from "../OriginatorLoanUploadSummaryDetails/OriginatorLoanUploadSummaryDetails";
import Paths from "../../../components/constants/Path";
import { statusConstants } from "../../../components/constants/FiltersConstant";

const OriginatorLoanUploadSummary = () => {
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  const [uploadData, setUploadData] = useState("");

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const dispatch = useDispatch();
  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.ORIGINATORDEALLIST,
      pathName: "Deals List",
    },
    {
      pathName: dealState?.name,
    },
  ];

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
    dispatch(getSingleDeal(params.dealId));
    dispatch(getDealDocuments({ dealId: params.dealId }));
  }, []);

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: params.dealId })).then((res) => {
      setUploadData(res?.payload?.data);
    });
  }, []);

  console.log({ dealState });

  let TabsList = [
    {
      name: "Loan Upload Summary",
      component: <OriginatorLoanUploadSummaryDetails />,
      index: 0,
    },
  ];

  if(userData?.roles.length == 1 ){
    TabsList.push(
      {
        name: "Queries",
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
        index: 1,
      },
      {
        name: "History",
        component: <Histories />,
        index: 2,
      },
    )
  }

  if(userData?.roles.length == 1){
    if (dealState?.filterdata?.dealType !== "SELL" && uploadData != undefined) {
      TabsList.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }else{
    if (dealState?.filterdata?.dealType !== "SELL" && 
      uploadData &&
      uploadData?.currentStatus !== statusConstants.PENDING &&
      uploadData?.currentStatus !== statusConstants.FILE_RECEIVED &&
      uploadData?.currentStatus !== statusConstants.IN_PROGRESS ) {
      TabsList.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }
  if (dealState?.filterdata?.status === statusConstants.CLOSED && userData?.roles.length == 1) {
    TabsList.push({
      name: "Documents",
      index: 4,
      component: <AllDocumentsV2 />,
    });
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      {dealState && dealState.loading ? (
        <Loader />
      ) : (
        <Layout breadCrumbs={breadCrumbs}>
          <TabsLayout
            TabsList={TabsList}
            formName={dealState?.name}
            icon={icon}
            iconColor={"#DBE3F8"}
            image={Image}
          />
        </Layout>
      )}
    </>
  );
};

export default OriginatorLoanUploadSummary;
