//? styles
import styles from "./ThreadComp.module.css";

//? icons
import CloseIcon from "@mui/icons-material/Close";

//? Components
import IconButton from "../IconButton/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Thread = ({
  id,
  name,
  query,
  raisedById,
  repliedSelf = false,
  viewThreadHandler,
  buttonHandler,
  isFirstThread,
  replyHandler,
  isLastThread,
}) => {
  console.log(query);
  const threadButtonHandler = () => {
    replyHandler(id);
  };

  return (
    <div className={styles.threadCompWrapper}>
      <div style={{ width: "11%" }}></div>
      <div className={styles.threadWrapper}>
        <div className={styles.nameAndQueryWrapper}>
          {repliedSelf ? (
            <p className={styles.selfRepyText}>You replied : </p>
          ) : (
            // <p className={styles.name}>
            //   {name} replied : {name}
            // </p>
            <p className={styles.name}>{name} replied :</p>
          )}
          <p className={styles.query}>
            {query
              ? query
              : "Ut egestas dignissim gravida. Donec non justo et urna iaculis finibus id ac sem"}
          </p>
        </div>

        <div className={styles.buttonWrapper}>
          {isFirstThread && (
            <IconButton
              buttonHandler={viewThreadHandler}
              icon={<ExpandLessIcon />}
            />
          )}
          {isLastThread && (
            <IconButton
              buttonHandler={buttonHandler}
              icon={<ArrowBackIcon />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Thread;
