import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from "../../../assets/images/Group 42722.svg";
import Histories from "../../../components/History/Histories";
import Layout from "../../../components/Layout/Layout";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../../components/Queries/Queries";
import Paths from "../../../components/constants/Path";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import LoanDeails from "./LoanDetails";

const L2LoanDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const params = useParams();
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.L2LOANDEALIST,
      pathName: "Deals",
    },
    {
      pathName: dealState?.name,
    },
  ];

  let TabList = [
    {
      name: "Loan",
      index: 0,
      component: <LoanDeails />,
    },
    
  ]

  if(userData?.roles.length == 1 ){
    TabList.push(
      {
        name: "Queries",
        index: 1,
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
      { name: "History", index: 2, component: <Histories /> },
    )
  }



  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={TabList}
      ></TabsLayout>
    </Layout>
  );
};

export default L2LoanDetails;
