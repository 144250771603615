import { Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageStatusAction } from "../../../redux/Auditor/kycApproveRejectSlice";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import TabFooter from "../TabFooter/TabFooter";
import DescriptionIcon from "@mui/icons-material/Description";
// import ViewDownloadDeleteComp from "../../L2/ViewDownloadDeleteComp";
import SettingsIcon from "@mui/icons-material/Settings";
import OutlineButton from "../../Buttons/OutlineButton";
import { useHistory, useParams } from 'react-router-dom'
import ViewDownloadDeleteCompV2 from "../../L2/ViewDownloadDeleteCompV2";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import { showAlertAction, showErrorAlertAction } from "../../../redux/app/appSlice";
import { fetchQueryList, postQuery, postComment, fetchComment } from "../../../redux/Queries/queriesSlice";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';


const DriveLinkApprovedPool = ({ nextTabHandler, setValue }) => {
  let dispatch = useDispatch();
  const [queryDetails, setQueryDetails] = useState({
    query: "",
    queryModule: "Deal",
    documentId: "",
  });
  const [query, setQuery] = useState("")
  const [status, setStatus] = useState(true)
  const [comments, setComments] = useState([])
  const params = useParams();
  const dealState = useSelector((state) => state.deal);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "driveLink&ApprovedPool" }));
    nextTabHandler();
  };

  const onAcceptClickHandler = () => {
    dispatch(showAlertAction("Please download the Pool for future reference before clicking next"))
    setStatus(false)
  }
  let id = params.dealId;
  const onPostClickHandler = () => {
    if (query === "") {
      dispatch(showErrorAlertAction("Please Enter Query"));
      return;
    } else {
      dispatch(postComment({ query: query, documentId: id })).then((data) => {
        dispatch(showAlertAction(data.payload.data.message))
      })
    }

  };

  useEffect(() => {
    dispatch(fetchComment({ dealId: params.dealId })).then((data) => {
      setComments(data.payload.data)

    })

  }, [])





  return (
    <>
      <div style={{ padding: "24px" }}>
        <TextWrapperComponent text="KYC Drive Link">
          <Link
            href={`https://${dealState?.filterdata?.kycDocumentLink}`}
            target="_blank"
            underline="always"
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#20303F",
              textDecorationLine: "underline",
            }}
          >
            {dealState?.filterdata?.kycDocumentLink}
          </Link>
        </TextWrapperComponent>
      </div>

      {(dealState?.filterdata?.poolUploadFinal?.cibilScoreSuccessFile) && (
        <>
      <DocumentHeader
        title={"Approved Pool"}
        startIcon={
          <DescriptionIcon
            sx={{ color: "#5E7387", width: "20px", height: "20px" }}
          />
        }
      ></DocumentHeader>
      <div
        style={{
          padding: "24px",
        }}
      >
        <Typography
          fontSize="14px"
          color="#808080"
          fontWeight="400"
          paddingBottom="10px"
        >
          Approved Pool
        </Typography>
        <ViewDownloadDeleteComp
          showEye={false}
          fileName={dealState?.filterdata?.poolUploadFinal?.cibilScoreSuccessFile?.fileName || dealState?.filterdata?.poolUploadFinal?.cibilScoreSuccessFile?.filename || ""}
          id={dealState?.filterdata?.poolUploadFinal?.cibilScoreSuccessFile?._id}
        ></ViewDownloadDeleteComp>
      </div>
      </>
      )}

      <DocumentHeader
        title={"Post a Comment"}
        startIcon={
          <SettingsIcon
            sx={{ color: "#5E7387", width: "20px", height: "20px" }}
          />
        }
      ></DocumentHeader>

      <div
        style={{
          padding: "24px",
        }}
      >
        <Typography
          fontSize="14px"
          color="#365069"
          fontWeight="700"
          paddingBottom="10px"
        >
          Post a Comment
        </Typography>

        <Typography
          fontSize="16px"
          color="#AFB9C3"
          fontWeight="400"
          paddingBottom="10px"
        >
          Add your Comment
        </Typography>
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "space-between",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "700px",
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              autoComplete="off"
              onChange={(e) =>
                setQuery(e.target.value)
              }
            ></TextField>
          </div>
          <OutlineButton
            text="Post"
            showIcon={false}
            onClickHandler={() => onPostClickHandler()}
          ></OutlineButton>
        </div>
      </div>

      {comments.map((cmnt, index) => {
        return (

          <>

            <DocumentHeader
              title={`Originator Response for ${cmnt.query}`}
              startIcon={
                <PersonOutlineIcon
                  sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                />
              }
            >

            </DocumentHeader>

            <br></br>
            {(cmnt?.replies).map((rply, index) => {
              return (
                <>
                  <div
                    style={{
                      padding: "24px",
                    }}
                  >
                    {/* <Typography
          fontSize="14px"
          color="#365069"
          fontWeight="700"
          paddingBottom="10px"
        >
          Post a Comment
        </Typography> */}

                    <Typography
                      fontSize="16px"
                      color="#20303F"
                      fontWeight="400"
                      paddingBottom="10px"
                    >
                      {rply.query}
                    </Typography>
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "space-between",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "700px",
                        }}
                      >
                        <TextField
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          disabled="disabled"

                        ></TextField>
                      </div>
                      <OutlineButton
                        text="Accept"
                        showIcon={false}
                        // onClickHandler={() => onPostClickHandler(queryDetails)}
                        onClickHandler={onAcceptClickHandler}
                      ></OutlineButton>
                    </div>
                  </div>
                </>
              )
            })}



          </>
        )
      })}





      <TabFooter
        onclickHandler={nextBtnClickHandler}
        isBtnDisable={status}
        setValue={setValue}
      />
    </>
  );
};

export default DriveLinkApprovedPool;
