import * as React from "react";
import { MenuItem, Select, ListItemText, FormControl } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Grid, TextField, Stack, Button, Typography } from "@mui/material";
import "../../App.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";

import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";
import {
  GenerateReportButton,
  GenerateReportHeight,
  GenerateReportMenuProps,
  GenerateReportSearchWidth,
} from "../../Pages/Phase2/SearchReports/SearchReports";
import { reportConstants } from "../constants/GlobalConstants";

const MenuProps = {
  PaperProps: {
    style: GenerateReportMenuProps,
  },
};

export default function SOAReportComponent({
  reportList,
  statusData,
  handleInputChange,
  SerachReportState,
  handleVariousReports,
  soaDetails,
  setSoaDetails,
  getFinancialYears,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <SelectComponent
              placeholder="Select Type of Report"
              value={statusData.typeOfReport}
              onClickHandler={handleInputChange("typeOfReport")}
              optionList={reportList}
            ></SelectComponent>
          </TextWrapperComponent>
        </Grid>

        {/* DEAL TYPE */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Loan Number">
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={soaDetails.loanNumber}
              variant="standard"
              size="small"
              sx={GenerateReportSearchWidth}
              onChange={(e) =>
                setSoaDetails({
                  ...soaDetails,
                  loanNumber: e.target.value,
                })
              }
            ></TextField>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Financial Year">
            <FormControl sx={{ width: 250 }}>
              <Select
                IconComponent={ExpandMoreIcon}
                displayEmpty
                value={soaDetails.financialYear}
                onChange={(e) =>
                  setSoaDetails({
                    ...soaDetails,
                    financialYear: e.target.value,
                  })
                }
                variant="standard"
                name="financialYear"
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <p className={Styles.dealNameStyle}>
                        Select Financial Year
                      </p>
                    );
                  }

                  return selected;
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {getFinancialYears().map((year, index) => (
                  <MenuItem key={index} value={year} sx={GenerateReportHeight}>
                    <ListItemText primary={year} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TextWrapperComponent>
        </Grid>
      </Grid>
      <div className={Styles.noContentDiv}></div>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={
            soaDetails.loanNumber == "" || soaDetails.financialYear == "" || 
            SerachReportState["loading"] == true 
          }
          onClick={handleVariousReports}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "View SOA Report"}
        </Button>
      </div>
    </>
  );
}
