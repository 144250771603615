import { Colors } from "../../../theme/theme";

export const GenerateReportDocumentHeaderStack = {
  flexDirection: "row",
};
export const GenerateReportDocumentHeaderIcon = {
  color: "#5E7387",
  width: "20px",
  height: "20px",
};
export const GenerateReportDocumentHeaderTypography = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#5E7387",
};
export const GenerateReportDescIcon = {
  color: "#2C4584",
};
export const GenerateReportMenuProps = {
  maxHeight: 200,
  width: 250,
  background: "#EBEEF0",
};
export const GenerateReportMainBox = {
  width: "100%",
  paddingLeft: "14px",
  backgroundColor: Colors.light_green,
  display: "flex",
  justifyContent: "space-between",
  height: "128px",
};
export const listSubheaderStyle = {
  height: "40px",
  background: "#EBEEF0",
  margin: "10px 0px",
};
export const searchDealStyle = {
  fontFamily: "Nunito",
  fontSize: "18px",
  fontWeight: "400",
  height: "7px",
  width: "380px",
};
export const searchCodeStyle = {
  fontFamily: "Nunito",
  fontSize: "18px",
  fontWeight: "400",
  height: "7px",
  width: "340px",
};
export const searchCodeStyle1 = {
  fontFamily: "Nunito",
  fontSize: "18px",
  fontWeight: "400",
  height: "7px",
  width: "360px",
};
export const searchCodeStyle2 = {
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: "400",
  height: "7px",
  width: "380px",
};
export const MenuItemSelectAll = {
  height: "40px",
  width: "360px",
};
export const GenerateReportHeight = {
  height: "40px",
};
export const GenerateReportWidth = {
  width: "100%",
};
export const GenerateReportSearchWidth = {
  width: "290px",
};
export const GenerateReportBoxContainer = {
  width: "100%",
  paddingTop: "40px",
};
export const GenerateReportSelect = {
  width: "320px",
  height: "30px",
};
export const GenerateReportDatePickerPopper = {
  "& .MuiPaper-root": {
    backgroundColor: "#EBEEF0",
  },
  "& .MuiPickersDay-dayWithMargin": {
    color: "#365069",
    backgroundColor: "#EBEEF0",
  },
  "& .MuiPickersDay-daySelected": {
    backgroundColor: "#365069",
    color: "#FFFFFF",
  },
};
export const GenerateReportDatePickerInput = {
  "& .MuiSvgIcon-root": {
    color: "#20303F",
  },
};
export const GenerateReportButton = {
  backgroundColor: Colors.piramal_orange,
  color: Colors.white,
  textTransform: "none",
  "&:hover": {
    backgroundColor: Colors.piramal_orange,
  },
  "&:disabled": {
    backgroundColor: "#FAC3B3",
    color: "white",
  },
};
