import React from "react";
import Styles from "./TabHeader.module.css";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import EditIcon from "../../../assets/icons/edit_icon.svg";
import { TabHeaderIconButton } from "./TabHeaderStyles";

const TabHeader = ({ heading, showEditbutton, editTabhandler }) => {
  return (
    <div className={Styles.tabHeader}>
      <h3>{heading ? heading : ""}</h3>
      {showEditbutton && (
        <IconButton sx={TabHeaderIconButton} onClick={editTabhandler}>
          <img src={EditIcon} />
        </IconButton>
      )}
    </div>
  );
};

export default TabHeader;
