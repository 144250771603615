export const statusTopBox = {
    textTransform: "none",
    width: "70",
    height: "20px",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    fontSize: "12px",
    borderRadius: "4px",
}

export const actionsIcon = {
    fontSize: "20px",
    color: "#365069"
}

export const dynamicIconStyle = (isCreditfilter) => {
    if(isCreditfilter == undefined){
        return {
            ...actionsIcon,
            color: "#AFB9C3"
        }
    }
    return actionsIcon
}

export const actionTypo = {
    color: "#365069", 
    fontSize: "14px" ,
}

export const dynamicTypoStyle = (isCreditfilter) => {
    if(isCreditfilter == undefined){
        return {
            ...actionTypo,
            color: "#AFB9C3"
        }
    }
    return actionTypo
}

export const originatorNameStyle = {
    color: "#808080",
    fontSize: "13px",
}

export const arrowUpwardStyle = {
    minWidth: "20px", 
    paddingTop: "12px"
}

export const arrowDownwardStyle = {
    minWidth: "20px", 
    paddingTop: "12px"
}

export const marginStyle = {
    margin: "auto"
}

export const alignItemStle = {
    alignItems: "center"
}

export const norecordFoundStyle = {
    textAlign: "center", 
    padding: "10px"
}

export const midWidthStyle = {
    minWidth: "200px"
}

export const timeStyle = {
    color: "#AFB9C3",
    fontSize: "13px",
}

export const colorStyle = {
    color: "#365069",
}

// Reports Table
export const reportpBox = {
    textTransform: "none",
    width: "85px",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "4px",
}

// Voucher Table
export const voucherBox = {
    width: "70px",
    height: "20px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "4px",
}