import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import WrapperComponent from "./Wrapper/WrapperComponent";

export default function DetailsData({ showDealData = false, label }) {
  const dealState = useSelector((state) => state.deal);
  const singleDeal = dealState?.filterdata;
  const loanTypeCode = singleDeal?.loanTypeData?.code;

  const dealDetails = [
    {
      title: "Type of Transaction",
      desc: singleDeal?.transactionType,
    },
    {
      title: "Product / Asset Class",
      desc: singleDeal?.loanTypeData?.name,
    },
    {
      title: "Vintage in Operations",
      desc: singleDeal?.vintageOperation,
      type: "YEARS",
    },
    {
      title: "Rating",
      desc: singleDeal?.rating,
    },
    {
      title: "AUM",
      desc: singleDeal?.aum,
      type: "CURRENCY",
    },
    {
      title: "Net Worth",
      desc: singleDeal?.netWorth,
      type: "CURRENCY",
    },
    {
      title: "PAT",
      desc: singleDeal?.pat,
      type: "CURRENCY",
    },
    {
      title: "CRAR%",
      desc: singleDeal?.crarPercent,
      type: "PERCENTAGE",
    },
    {
      title: "GNPA%",
      desc: singleDeal?.gnpaPercent,
      type: "PERCENTAGE",
    },
    {
      title: "NNPA %",
      desc: singleDeal?.nnpaPercent,
      type: "PERCENTAGE",
    },
    {
      title: "Requested Deal Size",
      desc: singleDeal?.requestedDealAmount,
      type: "CURRENCY",
    },
  ];

  const loanDetails = [
    {
      title: "LTV",
      desc: singleDeal?.loanFilterData?.ltv,
      type: "PERCENTAGE",
    },
    {
      title: "Max. Principal Outstanding",
      desc: singleDeal?.loanFilterData?.maxPrincipalOutstanding,
      type: "CURRENCY",
    },
    {
      title: "Restructured",
      desc: singleDeal?.loanFilterData?.isRestructured ? "Yes" : "NO",
      type: "BOOLEAN",
    },
    {
      title: "PDD Completion",
      desc: singleDeal?.loanFilterData?.isPddCompletion ? "Yes" : "NO",
      type: "BOOLEAN",
      visiblity: loanTypeCode === "MFI" ? true : false,
    },
    {
      title: "DPD",
      desc: singleDeal?.loanFilterData?.dpd,
    },
    {
      title: "Seasoning/ MHP",
      desc: singleDeal?.loanFilterData?.completedTenure,
    },
    {
      title: "Overdues",
      desc: singleDeal?.loanFilterData?.isOverdue ? "Yes" : "NO",
      type: "BOOLEAN",
    },
    {
      title: "Credit Scrub Score",
      desc: singleDeal?.loanFilterData?.creditScrubScore,
    },
    {
      title: "Balance Tenure (in months)",
      desc: singleDeal?.loanFilterData?.remainingTenure,
      type: "MONTHS",
    },
    {
      title: "Total Tenure (in months)",
      desc: singleDeal?.loanFilterData?.totalTenure,
      type: "MONTHS",
    },
    {
      title: "Max Lifetime DPD (in months",
      desc: singleDeal?.loanFilterData?.maxTotalDpd,
      type: "DAYS",
    },
    {
      title: "Max lifetime dpd -12 months",
      desc: singleDeal?.loanFilterData?.max12MonthDpd,
      type: "DAYS",
    },
    {
      title: "Max lifetime dpd -24 months",
      desc: singleDeal?.loanFilterData?.max24MonthDpd,
      type: "DAYS",
    },
    {
      title: "Branch Level PAR90",
      desc: singleDeal?.loanFilterData?.branchLevelPercentage,
      type: "PERCENTAGE",
    },
    {
      title: "Branch Level PAR0",
      desc: singleDeal?.loanFilterData?.branchPar0,
      type: "PERCENTAGE",
    },
    {
      title: "FOIR",
      desc: singleDeal?.loanFilterData?.foir,
      type: "PERCENTAGE",
    },
    {
      title: "Payment Mode",
      desc:
        singleDeal?.loanFilterData?.paymentModes &&
        singleDeal?.loanFilterData?.paymentModes.map((list) => list).join(", "),
    },
    {
      title: "City Exclusions",
      desc:
        singleDeal?.excludedCitiesData &&
        singleDeal?.excludedCitiesData.map((list) => list.name).join(", "),
    },
    {
      title: "State Exclusions",
      desc:
        singleDeal?.excludedStatesData &&
        singleDeal?.excludedStatesData.map((list) => list.name).join(", "),
    },
    {
      title: "Type of Property",
      desc:
        singleDeal?.loanFilterData?.propertyType &&
        singleDeal?.loanFilterData?.propertyType.map((list) => list).join(", "),
    },
    {
      title: "Type of Vehicle",
      desc:
        singleDeal?.loanFilterData?.vehicleTypes &&
        singleDeal?.loanFilterData?.vehicleTypes.map((list) => list).join(", "),
    },
    {
      title: "Customer Segment",
      desc:
        singleDeal?.loanFilterData?.customerSegment &&
        singleDeal?.loanFilterData?.customerSegment
          .map((list) => list)
          .join(", "),
    },
    {
      title: "Customer Constitution",
      desc:
        singleDeal?.loanFilterData?.customerConstitution &&
        singleDeal?.loanFilterData?.customerConstitution
          .map((list) => list)
          .join(", "),
    },
  ];

  const finalArray = showDealData ? dealDetails : loanDetails;

  let symbol = {
    PERCENTAGE: "%",
    CURRENCY: "₹",
    BOOLEAN: "",
    MONTHS: "Months",
    DAYS: "Days",
  };

  return (
    <WrapperComponent title={`${label}`}>
      <>
        <div
          style={{
            padding: "14px",
          }}
        >
          <Grid container spacing={3}>
            {finalArray.map((data, index) => {
              const isCurrency = data?.type === "CURRENCY";
              return (
                <>
                  {data?.desc && !data?.visiblity ? (
                    <Grid item xs={3} key={index}>
                      <div>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          color="#808080"
                        >
                          {data?.title}
                        </Typography>
                        <Typography fontSize="14px" fontWeight="400">
                          {isCurrency && symbol[data?.type]} {data?.desc}{" "}
                          {data?.type && data?.type === "CURRENCY"
                            ? "(INR Crs)"
                            : symbol[data?.type]}
                        </Typography>
                      </div>
                    </Grid>
                  ) : null}
                </>
              );
            })}
          </Grid>
        </div>
      </>
    </WrapperComponent>
  );
}
