import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "UploadTermSheet", isChecked: false },
    { pageName: "TermSheet", isChecked: false },
    { pageName: "OriginatorsDraft", isChecked: false },
    { pageName: "FinaliseTermSheet", isChecked: false },
  ],
  termTemplate: [],
  loading: null,
  error: null,
};

export const getTemplate = createAsyncThunk(
  "getTemplate",
  async ({ type }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/master-service/getTemplate?type=${type}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadTermsheet = createAsyncThunk(
  "uploadTermsheet",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("file", values.file);
    formData.set("uniqueCode", values.uniqueCode);
    formData.set("dealId", values.dealId);
    formData.set("termsheetType", values.termsheetType);
    formData.set("roleName", values.roleName);
    try {
      const uploadData = await instance.post(
        "/pool-upload-service/termsheet",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const approveTermsheet = createAsyncThunk(
  "approveTermsheet",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("uniqueCode", values.uniqueCode);
    formData.set("dealId", values.dealId);
    formData.set("isTermSheetApproved", values.isTermSheetApproved);
    try {
      const uploadData = await instance.post(
        "/pool-upload-service/termsheet",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const uploadoriginatorTermsheet = createAsyncThunk(
  "uploadoriginatorTermsheet",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("file", values.file);
    formData.set("uniqueCode", values.uniqueCode);
    formData.set("dealId", values.dealId);
    formData.set("termsheetType", values.termsheetType);
    formData.set("roleName", values.roleName);
    try {
      const uploadData = await instance.post(
        "/pool-upload-service/termsheet",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const finaliseoriginatorTermsheet = createAsyncThunk(
  "finaliseoriginatorTermsheet",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("uniqueCode", values.uniqueCode);
    formData.set("dealId", values.dealId);
    formData.set("termsheetType", values.termsheetType);
    formData.set("roleName", values.roleName);
    try {
      const uploadData = await instance.post(
        "/pool-upload-service/termsheet",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getTermSheet = createAsyncThunk(
  "getTermSheet",
  async ({ uniqueCode, dealId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/pool-upload-service/termsheet?uniqueCode=${uniqueCode}&dealId=${dealId}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const termSheetRequestSlice = createSlice({
  name: "termSheetRequestSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    clearTermsheetState: (state) => {
      // eslint-disable-next-line no-unused-expressions
      state.checkedPages = [
        { pageName: "UploadTermSheet", isChecked: false },
        { pageName: "TermSheet", isChecked: false },
        { pageName: "OriginatorsDraft", isChecked: false },
        { pageName: "FinaliseTermSheet", isChecked: false },
      ],
        state.termTemplate = [];
      state.loading = null;
      state.error = null;
    },
  },
  extraReducer: (builder) => {
    builder.addCase(getTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplate.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.termTemplate = payload;
    });
    builder.addCase(getTemplate.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });

    builder.addCase(getTermSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTermSheet.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.termTemplate = payload;
    });
    builder.addCase(getTermSheet.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });

    builder.addCase(uploadTermsheet.rejected, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.response.data.message);
      state.loading = false;
      state.error = payload.response.data.message;
    });

    builder.addCase(approveTermsheet.rejected, (state, { payload }) => {
      console.log({ payload });
      console.log(payload.response.data.message);
      state.loading = false;
      state.error = payload.response.data.message;
    });

    builder.addCase(
      uploadoriginatorTermsheet.rejected,
      (state, { payload }) => {
        console.log({ payload });
        console.log(payload.response.data.message);
        state.loading = false;
        state.error = payload.response.data.message;
      }
    );

    builder.addCase(
      finaliseoriginatorTermsheet.rejected,
      (state, { payload }) => {
        console.log({ payload });
        console.log(payload.response.data.message);
        state.loading = false;
        state.error = payload.response.data.message;
      }
    );
  },

});

export const { pageStatusAction, clearTermsheetState } = termSheetRequestSlice.actions;

export const termSheetRequestReducer = termSheetRequestSlice.reducer;
