import { ThemeProvider } from "@mui/system";
import theme from "./theme/theme";
import AllRoutes from "./routes/Routes";
import * as React from "react";
import instance from "./API/BaseUrl";

function App() {
  // Axios interceptor to handle 401 error and redirect to login page in useEffect

  return (
    <ThemeProvider theme={theme}>
      <AllRoutes />
    </ThemeProvider>
  );
}
//
export default App;
