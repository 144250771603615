import React from "react";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch, useSelector } from "react-redux";
import {
  pageStatusAction,
  spocDetails,
} from "../../../redux/L1/editOriginatorSlice";
import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import { useEffect } from "react";
import {
  getOriginatorDetails,
  updateSPOCDetails,
  uploadQuatarlyDoc,
} from "../../../redux/originator/originatorSlice";
import SuccessAlert from "../../Alert/SuccessAlert";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import { useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setOriginatorStatus } from "./../../../redux/originator/originatorSlice";
import { getOriginatorNames } from "./../../../redux/Deals/dealSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./EditOriginatorTab.module.css"
import { colorStyle, conditionalTypoStyle, fontSizeStyle, paddingTopStyle, textFieldStyle, typo } from "./EditOriginatorTabCSS";

const SPOCDetails = ({ nextTabHandler }) => {
  let dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("UserData"));
  let originatorState = useSelector((state) => state.originator);
  let { allOriginatorNames } = useSelector((state) => state.deal);
  const [isreadOnly, setIsReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [spocs, setSpocs] = useState([...originatorState.spocs] || []);
  const [Dataspocs, setDataSpocs] = useState([]);
  const [variantName, setVariantName] = React.useState(
    [...originatorState.originatorIdsData] || []
  );

  const typoStyle = conditionalTypoStyle(isreadOnly)
  const { originatorId } = useParams();
  const history = useHistory();
  const handleDisable = () => {
    setIsReadOnly(false);
  };

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    let allEmail = spocs.every((input) =>
      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(
        input.email
      )
    );

    let allNumber = spocs.every((input) => /^\d{10}$/.test(input.phoneNumber));
    if (!allNumber) {
      dispatch(showErrorAlertAction("Phone Number should be 10 digit"));
      return;
    }
    let allOriginatorsPersons = variantName.map((input) => input._id);
    console.log(allOriginatorsPersons);

    console.log(allNumber, allEmail);

    if (!allEmail) {
      dispatch(
        showErrorAlertAction("Email Field should contain company domain")
      );
      return;
    }
    setLoading(true);

    // dispatch(pageStatusAction({ pageName: "SPOCDetails" }));
    dispatch(updateSPOCDetails(spocs)).then((res) => {
      console.log({ res });
      if (res.payload.status == 200) {
        setLoading(false);
        dispatch(
          setOriginatorStatus({
            id: originatorId,
            status: "PENDING",
          })
        );
        dispatch(
          uploadQuatarlyDoc({
            id: originatorId,
            originatorIds: allOriginatorsPersons,
          })
        ).then((res) => {
          dispatch(getOriginatorDetails(originatorId));
        });
        dispatch(showAlertAction(res.payload.data.message));
        setIsReadOnly(true);
        history.push("/originator/list");
      }
    });

    // nextTabHandler();
  };

  console.log({ Dataspocs });

  const handleValidNumber = (number) => {
    if (number[0] < 6) {
      dispatch(showErrorAlertAction("Please enter valid Number"));
      number = "";
      return;
    }
    number = Math.max(0, parseInt(number)).toString().slice(0, 10);
    return number;
  };

  console.log({ spocs });

  useEffect(() => {
    console.log({ spocs });
  }, [spocs]);

  useEffect(() => {
    dispatch(getOriginatorNames());
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log({ value });

    let duplicateRemoved = [];
    console.log({ value });
    setVariantName([event.target.value]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...spocs];

    if (name == "email") {
      if (
        value.includes("@gmail.") ||
        value.includes("@yahoo.") ||
        value.includes("@google.") ||
        value.includes("@hotmail.") ||
        value.includes("@outlook.") ||
        value.includes(" ")
      ) {
        dispatch(
          showErrorAlertAction("Email Field should contain company domain")
        );

        return;
      }
    }

    if (name == "phoneNumber") {
      if (value[0] < 6) {
        dispatch(showErrorAlertAction("Please enter valid number"));
        return;
      }
    }

    //? getting object according to index from inputlist state
    let obj = list[index];

    //? creating new object & destructuring old obj with new values
    let updatedObj = {
      ...obj,
      [name]: value,
    };

    //? setting updated object in the list and returning un-updated objects
    let updatedArray = list.map((val, ind) => {
      if (ind == index) {
        return updatedObj;
      }
      return val;
    });

    //? setting updatedArray in inputList
    setSpocs(updatedArray);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
        background: "#EBEEF0",
      },
    },
  };
  return (
    <>
      <div className={styles.paddingStyle} >
        <Grid container spacingLeft={4}>
          <Grid item xs={3}>
            <Typography sx={typoStyle}>
              SPOC Name
            </Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Typography sx={typoStyle}>
              Email Address
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={typoStyle}>
              Contact Number
            </Typography>
          </Grid>
          {spocs.map((spoc, index) => (
            <Grid container key={spoc.id}>
              <Grid item xs={3} sx={paddingTopStyle}>
                <TextField
                  variant="standard"
                  autoComplete="off"
                  multiline
                  fontSize="14px"
                  name="name"
                  fullWidth
                  fontWeight="400"
                  // defaultValue={spoc.name}
                  value={spoc.name}
                  onChange={(e) => handleInputChange(e, index)}
                  InputProps={{
                    readOnly: isreadOnly,
                    disableUnderline: isreadOnly,
                  }}
                  sx={textFieldStyle}
                // fullWidth
                />
              </Grid>
              <Grid item xs={5.5} sx={paddingTopStyle}>
                <TextField
                  // disabled={isDisabled}
                  variant="standard"
                  autoComplete="off"
                  fontSize="14px"
                  fullWidth
                  multiline
                  fontWeight="400"
                  name="email"
                  // defaultValue={spoc.email}
                  value={spoc.email}
                  onChange={(e) => handleInputChange(e, index)}
                  InputProps={{
                    disableUnderline: isreadOnly,
                    readOnly: isreadOnly,
                  }}
                  sx={textFieldStyle}
                />
              </Grid>
              <Grid item xs={2} sx={paddingTopStyle}>
                <TextField
                  // disabled={isDisabled}
                  variant="standard"
                  autoComplete="off"
                  fontSize="14px"
                  fontWeight="400"
                  type="number"
                  name="phoneNumber"
                  value={spoc.phoneNumber}
                  onChange={(e) => handleInputChange(e, index)}
                  // defaultValue={spoc.phno}
                  InputProps={{
                    disableUnderline: isreadOnly,
                    readOnly: isreadOnly,
                  }}
                  onInput={(e) => {
                    e.target.value = handleValidNumber(e.target.value);
                  }}
                  sx={textFieldStyle}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {originatorState.originatorIdsData.length !== undefined && (
          <>
            <div className={styles.stackWrapper}>
              <>
                <Stack direction="row" spacing={1}>
                  <PeopleAltIcon sx={colorStyle}></PeopleAltIcon>
                  <Typography
                    // paddingTop="px"
                    sx={typo}
                  >
                    Originators
                  </Typography>
                </Stack>
              </>
            </div>
            <div className={styles.padding2Style} >
              <FormControl sx={{ width: 250 }}>
                <Select
                  labelId="demo-simple-select-label"
                  disabled={isreadOnly}
                  id="demo-simple-select"
                  value={variantName}
                  displayEmpty
                  variant="standard"
                  name="originatorNames"
                  IconComponent={ExpandMoreIcon}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <p className={styles.pStyle}>
                          Select Originators
                        </p>
                      );
                    }
                    console.log({ selected });
                    return selected.map((x) => x.name);
                  }}
                >
                  {allOriginatorNames &&
                    allOriginatorNames.map((variant) => {
                      console.log({ variant });
                      return (
                        <MenuItem key={variant._id} value={variant}>
                          {variant.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          </>
        )}
      </div>

      {userData?.roles.length != 1 ? (
        <></>
      ) : (
        <>
          {isreadOnly ? (
            <TabFooter
              isBtnDisable={false}
              label="Edit"
              endIcon={<EditIcon sx={fontSizeStyle} />}
              onclickHandler={handleDisable}
            />
          ) : (
            <TabFooter
              isBtnDisable={false}
              label={loading ? "Loading..." : "Save Changes"}
              endIcon={<DoneIcon />}
              onclickHandler={loading ? null : nextBtnClickHandler}
            />
          )}
        </>
      )}
    </>
  );
};
export default SPOCDetails;
