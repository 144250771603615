import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path";
import { Colors } from "../../theme/theme";
import { topBox } from "./DealCSS";
import OriginatorDealListTab from "../../components/Table/OriginatorDealListTab";
import { useDispatch, useSelector } from "react-redux";
import { setDealRedirection } from "../../redux/originator/originatorSlice";
import { getAllDeals } from "../../redux/Deals/dealSlice";
import { showErrorAlertAction } from "../../redux/app/appSlice";

const OriginatorDealList = () => {
  const originatorState = useSelector((state) => state.originator);
  const dealState = useSelector((state) => state.deal);
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getAllDeals({
        currentPage,
        limit,
        source: "PHFL",
        originatorId: originatorState?.duplicateOriginator?._id,
      })
    );
    return () => {
      dispatch(setDealRedirection(false));
    };
  }, [currentPage]);

  const TableData = [
    {
      id: 8,
      DealName: "P1 Home Loan",
      AssetClass: "Gold Loan",
      RequestedPoolSize: "₹50,00,000",
      Transaction: "DA",
      DealStatusDate: "12 Aug 2022",
      DealStatus: "Approved",
    },
    {
      id: 18,
      DealName: "P1 Home Loan",
      AssetClass: "Gold Loan",
      RequestedPoolSize: "₹50,00,000",
      DealStatusDate: "12 Aug 2022",
      DealStatus: "Approved",
    },
    {
      id: 98,
      DealName: "P1 Home Loan",
      AssetClass: "Gold Loan",
      RequestedPoolSize: "₹50,00,000",
      DealStatusDate: "12 Aug 2022",
      DealStatus: "Approved",
    },
    {
      id: 28,
      DealName: "P1 Home Loan",
      AssetClass: "Gold Loan",
      RequestedPoolSize: "₹50,00,000",
      DealStatusDate: "12 Aug 2022",
      DealStatus: "Approved",
    },
    {
      id: 44,
      DealName: "P1 Home Loan",
      AssetClass: "Gold Loan",
      RequestedPoolSize: "₹50,00,000",
      DealStatusDate: "12 Aug 2022",
      DealStatus: "Approved",
    },
  ];

  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage == dealState.totalPages) {
      // alert("Max Number Reached!");
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));

      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box sx={topBox}>
      <>
        <Box>
          <OriginatorDealListTab
            data={dealState?.allDeals || []}
            nextPageHandler={nextPageHandler}
            prevPageHandler={prevPageHandler}
            totalRecords={dealState?.totalPages}
            page={currentPage}
            header={[
              "Deal Name",
              "Deal Creation Date",
              "Asset Class",
              "GST Number",
              "Deal Status",
            ]}
          ></OriginatorDealListTab>
        </Box>
      </>
    </Box>
  );
};

export default OriginatorDealList;
