import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import SpocTable from "../../components/Table/SpocTable";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";

import { useDispatch, useSelector } from "react-redux";
import ViewDownloadDeleteComp from "../../components/L2/Phase2DownloadComp";
import { setStatus } from "../../redux/Loans/loanSlice";
import { statusConstants } from "../../components/constants/FiltersConstant";
const DealDetails = () => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);

  let userData = JSON.parse(localStorage.getItem("UserData"));


  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  const DealData = [
    {
      title: "Type of Transaction",
      desc: dealState?.transactionType,
    },
    {
      title: "Entity",
      desc: dealState?.entity,
    },
    {
      title: "Product / Asset Class",
      desc: dealState?.loanTypeData?.name,
    },
    {
      title: "Vintage in Operations",
      desc: `${dealState?.vintageOperation} years`,
    },
    {
      title: "Rating",
      desc: dealState?.rating,
    },
    {
      title: "AUM",
      desc: `${dealState?.aum} (INR Crs)`,
    },
    {
      title: "Net Worth",
      desc: `${dealState?.netWorth} (INR Crs)`,
    },
    {
      title: "PAT",
      desc: `${dealState?.pat} (INR Crs)`,
    },
    {
      title: "CRAR%",
      desc: `${dealState?.crarPercent}%`,
    },
    {
      title: "GNPA%",
      desc: `${dealState?.gnpaPercent}%`,
    },
    {
      title: "NNPA %",
      desc: `${dealState?.nnpaPercent}%`,
    },

    {
      title: "Requested Deal Size",
      desc: `₹ ${dealState?.requestedDealAmount} Cr.`,
    },
  ];

  const handleCreateLoanOnClick = () => {
    // alert("btn clicked");
    dispatch(setStatus(3));
  };

  return (
    <>
      <WrapperComponent
        showButton={ 
          userData?.roles.length == 1 &&
          ![
            statusConstants.F1,
            statusConstants.C1,
            statusConstants.R1,
            statusConstants.CP1,
            statusConstants.IA1,
            statusConstants.CO1,
          ].includes(userData?.roles[0])
        }
        title="Deal Details"
        isDisabled={
          dealState?.status === statusConstants.APPROVED ? false : true
        }
        text="Create New Loan"
        icon={<AddIcon />}
        onClickButton={handleCreateLoanOnClick}
      >
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {DealData.map((data) => {
                return (
                  <>
                    {data?.desc != "undefined%" &&
                      data?.desc != "null years" &&
                      data?.desc != "undefined years" &&
                      data?.desc != "null%" &&
                      data?.desc != "₹ undefined Cr." &&
                      data?.desc != "₹ null Cr." &&
                      data?.desc != "undefined (INR Crs)" &&
                      data?.desc != "null (INR Crs)" &&
                      data?.desc != "" &&
                      data?.desc != undefined && (
                        <Grid item xs={3}>
                          <DataComp title={data.title} desc={data.desc} />
                        </Grid>
                      )}
                  </>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>

      <WrapperComponent title="SPOC Details">
        <>
          <div>
            <SpocTable data={dealState?.contacts}></SpocTable>
          </div>
        </>
      </WrapperComponent>

      {dealState?.originatorIdData && dealState?.originatorIdData !== null && (
        <WrapperComponent title="Originator Details">
          <>
            <SpocTable data={[dealState?.originatorIdData]}></SpocTable>
          </>
        </WrapperComponent>
      )}

      {dealState?.filterdata?.status == statusConstants.CLOSED &&
        dealState?.filterdata?.migratedFile !== undefined &&
        dealState?.filterdata?.migratedFile.fileName !== "" && (
          <WrapperComponent title="Documents">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Migrated Deal Data
                </Typography>

                <ViewDownloadDeleteComp
                  fileName={
                    dealState?.filterdata?.migratedFile?.fileName ||
                    dealState?.filterdata?.migratedFile?.filename ||
                    ""
                  }
                  id={dealState?.filterdata?.migratedFile?._id}
                  showEye={true}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          </WrapperComponent>
        )}

      <div style={{ padding: "10px" }}></div>
    </>
  );
};

export default DealDetails;
