import styles from "./Notifications.module.css";
import NotificationComp from "./NotificationComp/NotificationComp";
import { useEffect} from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getAllData } from "../../../API/apiHandler";
import Loader from "../../../utils/Loader";
import Pagination from "../../Pagination/Pagination";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { Input } from "@mui/material";
import useDebounce from "../../../Hooks/useDebounce";
import { showErrorAlertAction } from "../../../redux/app/appSlice";

const Notifications = () => {
  const dispatch = useDispatch();
  const [Data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState()
  const [totalPage, setTotalPage] = useState()
  const [filter, setFilter] = useState({
    page: 1,
    searchQuery: "",
  });

  const debounced = useDebounce(filter?.searchQuery, 700);

  const getData = async () => {
    setData({ ...Data, loading: true });
    const response = await getAllData("/utility-service/notification", filter);

    setData({ ...response?.data, loading: false });
    setCurrentPage(response?.data?.page)
    setTotalPage(response?.data?.totalPages)

  };
  console.log({ Data });

  useEffect(() => {
    getData();
  }, [filter?.page, debounced]);

  const nextPageHandler = () => {
    if (currentPage == totalPage) {
      // alert("Max Number Reached!");
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setFilter({ ...filter, page: filter.page + 1 })
    }
  };

  const prevPageHandler = () => {
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setFilter({ ...filter, page: filter.page - 1 })
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.topDiv}>
        <TextWrapperComponent text="Search Notification">
          <Input
            fullWidth
            style={{ paddingRight: "20px" }}
            size="small"
            variant="standard"
            name="searchQuery"
            value={filter?.searchQuery}
            placeholder="Search Notification"
            onChange={(e) => {
              setFilter({
                ...filter,
                searchQuery: e.target.value,
                page: 1,
              });
            }}
          />
        </TextWrapperComponent>
      </div>

      {Data?.loading ? (
        <Loader />
      ) : (
        <div>
          <br />
          <div className={styles.historiesWrapper}>
            {Data?.data?.slice().map((data) => {
              return <NotificationComp data={data} />;
            })}
          </div>

          {Data?.data?.length > 0 && (
            <Pagination
            nextPageHandler={nextPageHandler}
            prevPageHandler={prevPageHandler}
            TotalNoNumber={Data?.totalPages}
              Page={filter?.page}
            />
          )}
        </div>
      )}

      {Data?.data?.length === 0 && (
        <div className={styles.notFound}>
          <p> No notifications found !!</p>
        </div>
      )}
    </div>
  );
};

export default Notifications;
