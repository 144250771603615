import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import {
  getAllDeals,
  setSortValue,
  setSortName,
} from "../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "dateformat";
import _ from "lodash";
import styles from "./Table.module.css"
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { ArrowDownward } from "@mui/icons-material";
import { FilterConstants, statusConstants } from "../constants/FiltersConstant";
import { actionTypo, actionsIcon, arrowDownwardStyle, arrowUpwardStyle, midWidthStyle, norecordFoundStyle, statusTopBox, timeStyle } from "./TableCSS";

const DealTable = ({
  data,
  viewActionClickHandler,
  header,
  page,
  filterName,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  console.log({ data });
  console.log({ page, totalRecords });

  let finalData = [...data];
  console.log({ finalData });



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));


  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    statusType = statusType && statusType?.toLowerCase().includes("ts") ? statusType : statusType?.toUpperCase();
    const Textcolor =
      statusType === statusConstants.APPROVED ||
        statusType === statusConstants.CREATED ||
        statusType === statusConstants.DONE
        ? "#277742"
        : statusType === statusConstants.REJECTED || statusType === statusConstants.DEAL_REJECTED
          ? "#8D3431"
          : statusType === statusConstants.CLOSED
            ? "#2C4584"
            : statusType === statusConstants.PENDING
              ? "#996E37"
              : statusType === statusConstants.NOT_CREATED
                ? "#996E37"
                : statusType === statusConstants.APPROVAL_PENDING
                  ? "#996E37"
                  : "#277742";

    const boxBackgroudColor =
      statusType === statusConstants.APPROVED ||
        statusType === statusConstants.CREATED ||
        statusType === statusConstants.DONE
        ? "#E3F6E9"
        : statusType === statusConstants.REJECTED || statusType === statusConstants.FAILED || statusType === statusConstants.DEAL_REJECTED
          ? "#FCE6E5"
          : statusType === statusConstants.CLOSED
            ? "#E4EAFA"
            : statusType === statusConstants.PENDING
              ? "#FFF4E7"
              : statusType === statusConstants.NOT_CREATED
                ? "#FFF4E7"
                : statusType === statusConstants.APPROVAL_PENDING
                  ? "#FFF4E7"
                  : "#E3F6E9";



    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          {
            statusType &&
            <p className={styles.statusPTag} >
              {statusType && statusType?.toLowerCase().includes("ts") ? statusType :
                statusType?.toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
            </p>
          }


        </div>
      </Box>
    );
  };

  const Actions = ({ isDealAdded, dealId, label, Icon, item }) => {
    return (
      <div
        onClick={() =>
          viewActionClickHandler(item)
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          <Icon
            sx={actionsIcon}
          ></Icon>

          <Typography
            sx={actionTypo}
          >
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  const formatRequestedPool = (value) => {
    if (value !== undefined) {
      console.log({ value });
      numberFormat(Number(value));
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  var nf = new Intl.NumberFormat();

  const headerFilters = {
    "Deal Name": "deal",
    "Asset Class": "assetClass",
    "Requested Pool Size": "poolSize",
    Transaction: "transaction",
    "Deal Creation Date": "startDate",
    "Created By": "createdBy",
    "Deal Status": "currentStage"
  };

  const MapHeader = ({ data }) => {
    const [sort, setSort] = useState("");
    useEffect(() => {
      if (sort !== "") {
        // setCallFunc({ data, sort });
        dispatch(setSortValue(!dealState.sortValue));
        dispatch(setSortName(data));
        let sortName = headerFilters[`${data}`];
        if (sortName !== undefined) {
          dispatch(
            getAllDeals({
              currentPage: page,
              limit: 10,
              sortName: sortName,
              filterName,
              sortOrder: dealState.sortValue ? 1 : -1,
            })
          );
        }
      }
      return;
    }, [sort]);

    console.log(headerFilters[data]);
    console.log(dealState?.sortFieldName);

    console.log(data === dealState?.sortFieldName);
    return (
      <StyledTableCell
        onClick={() =>
          data == FilterConstants.ACTION || data == FilterConstants.LOAN_STATUS
            ? null
            : setSort(!sort)
        }
        style={{
          cursor:
            data == FilterConstants.ACTION || data == "Deal Status" || data == FilterConstants.LOAN_STATUS
              ? null
              : "pointer",
        }}
      >
        {data}

        {data !== dealState?.sortFieldName ? null : dealState?.sortValue !==
          true ? (
          <ArrowUpward sx={arrowUpwardStyle} />
        ) : (
          <ArrowDownward sx={arrowDownwardStyle} />
        )}
      </StyledTableCell>
    );
  };

  let activRole = localStorage.getItem("activeRole")

  function getPoolStatus(item) {
    let status = ""

    if (item?.poolUploadFinal && (item?.poolUploadFinal?.primaryCurrentStatus === statusConstants.DONE || item?.poolUploadFinal?.primaryCurrentStatus === "PARTIALLY_DONE")) {
      // checking the secondary status for showing pool submmitted or pool uploaded
      if (item?.poolUploadFinal?.secondaryCurrentStatus !== statusConstants.PENDING) {
        status = "Pool Submitted"
      } else {
        status = "Pool Uploaded"
      }
    } else {
      status = "Pool Upload"
    }
    return status;
  }

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    return finalTime;
  }
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={midWidthStyle}>
              {header.map((data) => {
                return <MapHeader data={data} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((item) => {
              return (
                <TableRow key={item.id || item._id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item.name}
                    <br />
                    <Typography
                      sx={timeStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>
                  {activRole === statusConstants.L1 ||
                    activRole === statusConstants.F1 ||
                    activRole === statusConstants.C1 ||
                    activRole == statusConstants.R1 ||
                    activRole == statusConstants.CP1 ||
                    activRole == statusConstants.IA1 ||
                    activRole == statusConstants.CO1 ? (
                    <StyledTableCell component="th" scope="row">
                      {moment(item.createdAt).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={timeStyle}
                      >
                        {convertTime(item.createdAt) || ""}
                      </Typography>
                      { }
                    </StyledTableCell>
                  ) : activRole === statusConstants.L2 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                    </StyledTableCell>
                  ) : activRole === FilterConstants.Originator ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                    </StyledTableCell>
                  ) : null}

                  {activRole === statusConstants.L1 ||
                    activRole === statusConstants.F1 ||
                    activRole === statusConstants.C1 ||
                    activRole == statusConstants.R1 ||
                    activRole == statusConstants.CP1 ||
                    activRole == statusConstants.IA1 ||
                    activRole == statusConstants.CO1 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                    </StyledTableCell>
                  ) : activRole === statusConstants.L2 ? (
                    <StyledTableCell component="th" scope="row">
                      {item.requestedDealAmount === undefined ||
                        item.requestedDealAmount === null ||
                        item.requestedDealAmount === ""
                        ? "NA"
                        : numberFormat(Number(item?.requestedDealAmount))}
                    </StyledTableCell>
                  ) : activRole === FilterConstants.Originator ? (
                    <StyledTableCell component="th" scope="row">
                      {item.requestedDealAmount === undefined ||
                        item.requestedDealAmount === null ||
                        item.requestedDealAmount === ""
                        ? "NA"
                        : numberFormat(Number(item?.requestedDealAmount))}
                    </StyledTableCell>
                  ) : null}

                  {activRole === statusConstants.L1 ||
                    activRole === statusConstants.F1 ||
                    activRole === statusConstants.C1 ||
                    activRole == statusConstants.R1 ||
                    activRole == statusConstants.CP1 ||
                    activRole == statusConstants.IA1 ||
                    activRole == statusConstants.CO1 ? (
                    <StyledTableCell component="th" scope="row">
                      {item.requestedDealAmount === undefined ||
                        item.requestedDealAmount === null ||
                        item.requestedDealAmount === ""
                        ? "NA"
                        : numberFormat(Number(item?.requestedDealAmount))}
                    </StyledTableCell>
                  ) : activRole === statusConstants.L2 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.entity === undefined && "NA"}
                      {item?.entity === "" && "NA"}
                      {item?.entity}
                    </StyledTableCell>
                  ) : activRole === FilterConstants.Originator ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.transactionType === undefined && "NA"}
                      {item?.transactionType === "" && "NA"}
                      {item?.transactionType}
                    </StyledTableCell>
                  ) : null}

                  { activRole === statusConstants.C1 ||
                    activRole == statusConstants.R1 ||
                    activRole == statusConstants.CP1 ||
                    activRole == statusConstants.IA1 ||
                    activRole == statusConstants.CO1 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.transactionType === undefined && "NA"}
                      {item?.transactionType === "" && "NA"}
                      {item?.transactionType}
                    </StyledTableCell>
                  ) : activRole === statusConstants.F1 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.entity === undefined && "NA"}
                      {item?.entity === "" && "NA"}
                      {item?.entity}
                    </StyledTableCell>
                  ) : activRole === statusConstants.L2 ? (
                    <StyledTableCell component="th" scope="row">
                      {moment(item.createdAt).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={{
                          color: "#AFB9C3",
                          fontSize: "13px",
                        }}
                      >
                        {convertTime(item.createdAt) || ""}
                      </Typography>
                    </StyledTableCell>
                  ) : activRole === FilterConstants.Originator ? (
                    <StyledTableCell component="th" scope="row">
                      {moment(item.createdAt).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={timeStyle}
                      >
                        {convertTime(item.createdAt) || ""}
                      </Typography>
                    </StyledTableCell>
                  ) : activRole === statusConstants.L1 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.entity === undefined && "NA"}
                      {item?.entity === "" && "NA"}
                      {item?.entity}
                    </StyledTableCell>
                  ) : null}

                  {activRole === statusConstants.L1 ||
                    activRole === statusConstants.F1 ||
                    activRole === statusConstants.C1 ||
                    activRole == statusConstants.R1 ||
                    activRole == statusConstants.CP1 ||
                    activRole == statusConstants.IA1 ||
                    activRole == statusConstants.CO1 ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item?.status == "CLOSED" ? item?.status : item?.currentStage}
                      // CreditFilterAdded={}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : activRole === statusConstants.L2 ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.createdBy === undefined && "NA"}
                      {item?.createdBy === "" && "NA"}
                      {item?.createdBy !== undefined}
                      {item?.createdBy}
                    </StyledTableCell>
                  ) : null}

                  {activRole == statusConstants.L1 ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={`${item?.loanFilter !== undefined
                          ? "Created"
                          : "Not Created"
                          }`}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : activRole === statusConstants.L2 ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item?.status == "CLOSED" ? item?.status : item?.currentStage}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : activRole === FilterConstants.Originator ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={`${item?.poolUploadFinal && item?.poolUploadFinal?.primaryCurrentStatus === "REUPLOAD"
                          ? "Pool Re-upload"
                          : getPoolStatus(item)
                          }`}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : null}
                  {activRole === FilterConstants.Originator ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item?.loanUploadStatus}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : null}

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        label="View"
                        dealId={item.id}
                        item={item}
                        Icon={VisibilityIcon}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>

      {
        data && data.length === 0 ? null : (
          <Pagination
            Page={page}
            TotalNoNumber={totalRecords}
            nextPageHandler={page === totalRecords ? null : nextPageHandler}
            prevPageHandler={prevPageHandler}
          />
        )
      }
    </div >
  );
};

export default DealTable;
