import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UploadTemplateKyc from "../../../components/Wrapper/OriginatorUploadKyc";
import Paths from "../../../components/constants/Path";
import {
  deleteDoc,
  getUserProfileByUserId,
  kycUpoloadedAction,
  setLeiNumberAction,
  uploadQuatarlyDoc,
} from "../../../redux/originator/originatorSlice";
import { Colors } from "../../../theme/theme";
import Loader from "../../../utils/Loader";
import styles from "./OriginatorUpdKYC.module.css";
import {
  btnStyle,
  descriptionIconStyle,
  documentHeaderStack,
  documnetHeaderTypo,
  profileRadioGroup,
  profileTabDocumentHeader,
} from "./OriginatorUpdKYC";
const ProfileTab = () => {
  const [state, setState] = React.useState(true);
  const [status, setStatus] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  let completeKYCState = useSelector((state) => state.originator);
  let { leiNumber } = useSelector((state) => state.originator);
  const [selectedValue, setSelectedValue] = React.useState("LEICard");
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    let value = e.target.value;
    dispatch(setLeiNumberAction(value));
  };

  useEffect(() => {
    if (
      completeKYCState?.leiNumber &&
      completeKYCState?.leiNumber?.length > 1
    ) {
      dispatch(deleteDoc("leiCard"));
      setSelectedValue("LEINumber");
      return;
    }
  }, [completeKYCState?.leiNumber]);

  let history = useHistory();

  // useEffect(() => {
  //   dispatch(getOriginatorDetails(completeKYCState.originatorProfileId));
  // }, [completeKYCState.originatorProfileId]);

  const clickHandler = () => {
    dispatch(kycUpoloadedAction(true));
    dispatch(
      uploadQuatarlyDoc({
        id: completeKYCState?.originatorProfileId,
        kyc: {
          panCard: completeKYCState?.panCard?.fileId,
          leiCard: completeKYCState.leiCard?.fileId,
          incorporationCertification:
            completeKYCState.incorporationCertificate?.fileId,
          gstCertificate: completeKYCState.gstCertificate?.fileId,
          cancelledCheque: completeKYCState.cancelledCheque?.fileId,
          rbiCerticationRegistration:
            completeKYCState.rbiRegisterationCertificate?.fileId,
          leiNumber: leiNumber,
        },
      })
    );
    return history.push(Paths.ORIGINATORDEALLIST);
  };
  function handleRadioBtnChange(event) {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  }

  useEffect(() => {
    if (
      completeKYCState.panCard &&
      completeKYCState.gstCertificate &&
      completeKYCState.incorporationCertificate &&
      completeKYCState.cancelledCheque &&
      completeKYCState.rbiRegisterationCertificate
    ) {
      if (completeKYCState.leiCard || completeKYCState.leiNumber) {
        if (
          completeKYCState.leiCard ||
          completeKYCState.leiNumber.length === 20
        ) {
          console.log("dsd" + completeKYCState.leiCard);

          setStatus(false);
        } else {
          setStatus(true);
        }
      } else {
        setState(true);
      }
    } else {
      setStatus(true);
    }
  }, [completeKYCState]);

  useEffect(() => {
    if (completeKYCState?.leiCard === null) {
      setStatus(true);
      return;
    }
  }, [completeKYCState.leiCard]);

  useEffect(() => {
    dispatch(getUserProfileByUserId({ userId: userData?._id || userData?.id }));
  }, []);

  useEffect(() => {
    if (
      selectedValue === "LEICard" &&
      completeKYCState?.leiNumber?.length < 1
    ) {
      dispatch(setLeiNumberAction(""));
      return;
    } else {
      dispatch(deleteDoc("leiCard"));
    }
  }, [selectedValue]);

  const DocumentHeader = ({ title }) => {
    return (
      <div className={styles.documentHeaderProfileTab}>
        <Stack sx={documentHeaderStack} spacing={1}>
          <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
          <Typography sx={documnetHeaderTypo}>{title}</Typography>
        </Stack>
      </div>
    );
  };

  const LEICard = () => {
    return (
      <div className={styles.documentHeaderProfileTab}>
        <Stack sx={documentHeaderStack}>
          <div className={styles.profileTabDiv}>
            <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
          </div>

          <div className={styles.profileTabFormControl}>
            <FormControl component="fieldset" required error>
              <RadioGroup
                value={selectedValue}
                onChange={handleRadioBtnChange}
                row
                sx={profileRadioGroup}
              >
                <FormControlLabel
                  value="LEICard"
                  control={<Radio size="small" />}
                  label="LEI Card"
                />
                <FormControlLabel
                  value="LEINumber"
                  control={<Radio size="small" />}
                  label="LEI Number"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Stack>
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.profileTabMainDiv}>
          <div className={styles.profileTabUploadsDocuments}>
            <h3>
              Upload KYC Documents for originator{" "}
              {completeKYCState?.originatorProfileName}
            </h3>
          </div>
          <Box sx={profileTabDocumentHeader}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <DocumentHeader title="PAN Card"></DocumentHeader>

                <div className={styles.paddingStyle}>
                  <UploadTemplateKyc
                    setLoading={setLoading}
                    docName="panCard"
                    setdata={setState}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <DocumentHeader title="GST Certificate" docName="panCard" />
                <div className={styles.paddingStyle}>
                  <UploadTemplateKyc
                    setLoading={setLoading}
                    setdata={setState}
                    docName="gstCertificate"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <DocumentHeader title="Incorporation Certificate" />
                <div className={styles.paddingStyle}>
                  <UploadTemplateKyc
                    setLoading={setLoading}
                    setdata={setState}
                    docName="incorporationCertificate"
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <DocumentHeader title="Cancelled Cheque" />
                <div className={styles.paddingStyle}>
                  <UploadTemplateKyc
                    setLoading={setLoading}
                    setdata={setState}
                    docName="cancelledCheque"
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <DocumentHeader title="RBI Registration Certificate"></DocumentHeader>
                <div className={styles.paddingStyle}>
                  <UploadTemplateKyc
                    setLoading={setLoading}
                    setdata={setState}
                    docName="rbiRegisterationCertificate"
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <LEICard></LEICard>
                <div className={styles.paddingStyle}>
                  {selectedValue === "LEICard" ? (
                    <UploadTemplateKyc
                      setLoading={setLoading}
                      setdata={setState}
                      docName="leiCard"
                    />
                  ) : (
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      value={leiNumber}
                      type={"text"}
                      onInput={(e) => {
                        let value =
                          e.target.value.replace(/[^0-9a-zA-Z]/g, "") || "";
                        e.target.value = value.slice(0, 20);
                      }}
                      onChange={handleInputChange}
                      placeholder="Enter LEI Number"
                    />
                  )}
                </div>
              </Grid>
            </Grid>

            <div className={styles.profileTabSubmitDocuments}>
              <Button
                endIcon={<DoneIcon />}
                onClick={clickHandler}
                disabled={status}
                variant="contained"
                sx={btnStyle}
                type="submit"
              >
                Submit Documents and Work on Deals
              </Button>
            </div>
          </Box>
        </div>
      )}

      <div className={styles.paddingStyle}></div>
    </>
  );
};

export default ProfileTab;
