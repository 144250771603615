import DescriptionIcon from "@mui/icons-material/Description";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../assets/images/Group 42722.svg";
import Layout from "../../../components/Layout/Layout";
import { FormDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import CreditBureauReport from "../../../components/Tabs/CreditBureauUpdTab/CreditBureauReport";
import TypeOfReport from "../../../components/Tabs/CreditBureauUpdTab/TypeOfReport";
import Tabs from "../../../components/Tabs/Tabs";
import Paths from "../../../components/constants/Path";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import { clearCreditBureauState } from "../../../redux/originator/creditBureauSlice";
import { Colors } from "../../../theme/theme";

const OriginatorCreditUpload = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const dealState = useSelector((state) => state.deal);

  const isCreditBureauUploadDone =
    dealState?.filterdata?.poolUploadFinal?.cibilScoreCurrentStatus;

  useEffect(() => {
    if (isCreditBureauUploadDone === "DONE") {
      history.push(Paths.ORIGINATORCREDITDEALSLIST);
    }
  }, [isCreditBureauUploadDone]);

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
    dispatch(clearCreditBureauState());
  }, []);

  console.log({ dealState });
  const tabs = [
    {
      tabName: "Type Of Report",
      comp: TypeOfReport,
      heading: `Credit Bureau Scrub Report for - ${dealState?.name}`,
    },
    {
      tabName: "Credit Bureau Scrub Report",
      comp: CreditBureauReport,
      heading: `Credit Bureau Scrub Report for - ${dealState?.name}`,
    },
  ];
  const breadCrumbs = [
    {
      path: Paths.ORIGINATORHOME,
      pathName: "Home",
    },
    {
      path: Paths.ORIGINATORCREDITDEALSLIST,
      pathName: "Deals List",
    },
    {
      pathName: "View Deal Details",
    },
  ];

  let creditBureauState = useSelector((state) => state.creditScrub);
  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        <Box
          sx={{
            width: "100%",
            paddingLeft: "14px",
            backgroundColor: Colors.light_green,
            display: "flex",
            justifyContent: "space-between",
            height: "128px",
          }}
        >
          <div>
            <FormDetails
              icon={icon}
              iconColor={"#DBE3F8"}
              formName={dealState?.name}
            />
            <Box paddingTop="16px"></Box>
          </div>
          <div
            style={{ width: "20%", height: "100%", padding: "20px 10px 0px" }}
          >
            <img
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              src={Image}
              alt="image"
            />
          </div>
        </Box>
        <Box height="calc(100% - 172px)">
          <Tabs tabs={tabs} status={creditBureauState["checkedPages"]} />
        </Box>
      </Layout>
    </>
  );
};

export default OriginatorCreditUpload;
