import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton, ListSubheader, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";

const Placeholder = ({ children }) => {
  return <div style={{ color: "#AFB9C3" }}>{children}</div>;
};

export default function TermSheetSelectTaboptions({
  data,
  value,
  setValue,
  defaultValue,
  placeholder,
  valueMultiple,
  setValueMultiple,
  searchFilter,
  setSearchFilter,
  Multiple = false,
  i,
}) {
  console.log(value);
  console.log(valueMultiple);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeMultiple = (onChangeValue, i) => {
    const inputdata = [...valueMultiple];
    inputdata[i][onChangeValue.target.name] = onChangeValue.target.value;
    setValueMultiple(inputdata);
    console.log(valueMultiple);
  };

  const handleDelete = (i) => {
    const deleteValue = [...valueMultiple];
    deleteValue.splice(i, 1);
    setValue(deleteValue);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
        background: "#EBEEF0",
      },
    },
  };



  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      defaultValue={defaultValue}
      label="orignator"
      displayEmpty
      onChange={Multiple ? (e) => handleChangeMultiple(e, i) : handleChange}
      // IconComponent={null}
      // endAdornment={<IconButton onClick={() => handleDelete(i)}><DeleteIcon/></IconButton>}
      // endAdornment={valueMultiple[i] ==="" ? null : <IconButton onClick={() => handleDelete(i)}><DeleteIcon/></IconButton>}
      MenuProps={MenuProps}
      renderValue={
        value !== ""
          ? undefined
          : () => <Placeholder>{placeholder}</Placeholder>
      }
    >
      <ListSubheader
           sx={{
            height: "40px",
            background: "#EBEEF0",
            margin: "10px 0px",
          }}
      >
        <TextField
          size="medium"
          placeholder="Search deal"
          fullWidth
          value={searchFilter}
          sx={{
            input: {
              fontFamily: "Nunito",
              fontSize: "18px",
              fontWeight: "400",
              height: "7px",
              width: "380px",
            },
          }}
          onChange={(e) => {
            setSearchFilter(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        >

        </TextField>
      </ListSubheader>
      {data.map((originator, index) => {
        return (
          <MenuItem key={index} value={originator.id || originator._id}>
            {originator.name || originator.title}
          </MenuItem>
        );
      })}
    </Select>
  );
}

