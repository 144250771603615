import React, { useEffect, useState } from "react";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPageStatusAction,
  getkycTemplate,
  pageStatusAction,
  submitKYCVerificationReport,
} from "../../../redux/Auditor/kycApproveRejectSlice";
import Paths from "../../constants/Path";
import { useHistory } from "react-router-dom";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import BasicModal from "../../Modal/Modal";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { deleteDoc } from "../../../redux/originator/originatorSlice";
import KYCLoanTemplateDownload from "../../Originator/KYCLoanTemplateDownload";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import { getAMLTemplate } from "../../../redux/Phase3/KYCAML/KYCAMLSlice";

const VerificationReport = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  let history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [state, setState] = useState(null);
  const [loanFile, setLoanFile] = useState(null);
  const [kycTemplateData, setKYCTemplateData] = useState([]);
  const dealState = useSelector((state) => state.deal);

  const onClickHandlerToDealsPage = () => {
    setOpen(true);
    dispatch(pageStatusAction({ pageName: "verificationDoc" }));
  };

  const handleClosureStatus = (e) => {
    e.preventDefault();
    if (loanFile == undefined) {
      dispatch(showErrorAlertAction("Please select file !"));
      setOpen(false);
      return;
    }
    dispatch(
      submitKYCVerificationReport({
        file: loanFile,
        uniqueCode: dealState?.filterdata?.uniqueCode,
      })
    ).then((res) => {
      dispatch(showAlertAction(res.payload.data.message));
      dispatch(deleteDoc("VerificationReport"));
      dispatch(clearPageStatusAction());
      dispatch(
        getAllDeals({
          limit: 10,
          currentPage: 1,
          isKycUploded: true,
          isCreditBureauCheckDone: true,
          isSecondaryCheckDone: true,
          filterName: "APPROVED",
          source: "PHFL",
        })
      );
      setOpen(false);
      dispatch(showAlertAction("Your file is getting Uploaded!"));
      history.push(Paths.AUDITORDEALIST);
    });
  };

  useEffect(() => {
    dispatch(getAMLTemplate({ loanTypeId: dealState?.loanTypeData?._id })).then(
      (res) => {
        console.log("res", res);
        setKYCTemplateData(res?.payload?.data);
      }
    );
  }, []);

  return (
    <>
      <DocumentHeader
        title="KYC Verification Report Template"
        startIcon={
          <DescriptionIcon
            sx={{ color: "#5E7387", width: "20px", height: "20px" }}
          ></DescriptionIcon>
        }
      ></DocumentHeader>
      <div style={{ padding: "10px", width: "auto" }}>
        <ViewDownloadDeleteComp
          showEye={false}
          fileName={kycTemplateData?.file?.filename || ""}
          id={kycTemplateData?.file?._id}
        ></ViewDownloadDeleteComp>
      </div>

      <DocumentHeader
        title={"KYC Verification Report"}
        startIcon={
          <DescriptionIcon
            sx={{ color: "#5E7387", width: "20px", height: "20px" }}
          />
        }
      ></DocumentHeader>
      <div style={{ padding: "10px", width: "auto" }}>
        <UploadTemplate
          docName="VerificationReport"
          setdata={setState}
          setLoanFile={setLoanFile}
        />
      </div>

      <TabFooter
        label="Save Details"
        isBtnDisable={false}
        endIcon={<DoneIcon />}
        onclickHandler={onClickHandlerToDealsPage}
      />

      <BasicModal
        open={open}
        setOpen={setOpen}
        title="Do you want to Upload this File?"
        positiveText="Yes"
        negativeText="No"
        positiveOnClickHandler={handleClosureStatus}
        setloading={setMsgLoading}
      ></BasicModal>
    </>
  );
};

export default VerificationReport;
