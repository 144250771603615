import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import {
  getTermSheet,
  pageStatusAction,
  uploadoriginatorTermsheet,
} from "../../../redux/TermSheet/TermSheetSlice";
import { showAlertAction } from "../../../redux/app/appSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import UploadTermSheet from "../../Wrapper/UploadTermSheet";
import TabFooter from "./../TabFooter/TabFooter";

const OriginatorDraft = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  const [loanFile, setLoanFile] = useState(null);
  const [state, setState] = useState(null);
  const [status, setStatus] = useState(true);
  const [termSheetData, setTermSheetData] = useState("");
  const [newLoader, setNewLoader] = useState(true);
  const dealState = useSelector((state) => state.deal);
  let userData = JSON.parse(localStorage.getItem("UserData"));
  let activRole = localStorage.getItem("activeRole")
  let uniqueCode = localStorage.getItem("uniqueCode");
  let dealid = localStorage.getItem("dealId");

  //Upload originator draft by L1
  const onClickHandlerToDealsPage = (e) => {
    e.preventDefault();
    dispatch(
      uploadoriginatorTermsheet({
        file: loanFile,
        uniqueCode: uniqueCode,
        dealId: dealid,
        termsheetType: "draft",
        roleName: activRole,
      })
    ).then((res) => {
      if (res.payload.data.status === 200) {
        dispatch(showAlertAction("Corrected Term Sheet Uploaded!"));
      }
    });
    dispatch(pageStatusAction({ pageName: "OriginatorsDraft" }));
    nextTabHandler();
  };

  //getting deal details
  useEffect(() => {
    dispatch(getSingleDealDetails(dealid)).then((res) => {
      localStorage.setItem(
        "uniqueCode",
        res?.payload?.data?.data?.data?.uniqueCode
      );
    });
  }, []);

  //getting latest termsheet uploaded by Originator
  useEffect(() => {
    if (dealState?.filterdata) {
      let finalCode = dealState?.filterdata?.uniqueCode;
      let finalId = dealState?.filterdata?._id;

      if (finalCode != null) {
        dispatch(getTermSheet({ uniqueCode: finalCode, dealId: finalId })).then(
          (res) => {
            setTermSheetData(res?.payload?.data?.termsheetUploadedFile);
            setNewLoader(false);
          }
        );
      }
    }
  }, [dealState?.filterdata]);

  //button disable handler
  useEffect(() => {
    if (loanFile == undefined) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [loanFile]);

  return (
    <>
      {newLoader ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title="Term Sheet by Originator"
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              ></DescriptionIcon>
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <ViewDownloadDeleteComp
              fileName={termSheetData?.filename}
              id={termSheetData?._id}
            ></ViewDownloadDeleteComp>
          </div>

          {/* hanlde freeze upload option condition */}
          {dealState?.filterdata?.poolUploadFinal?.termsheetFinalStatus?.L1 ==
            "DONE" || userData?.roles.length != 1 ? (
            <></>
          ) : (
            <>
              <DocumentHeader
                title={"Corrected Term Sheet"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>
              <div style={{ padding: "10px", width: "auto" }}>
                <UploadTermSheet
                  docName="OriginatorDraft"
                  setdata={setState}
                  setLoanFile={setLoanFile}
                />
              </div>
            </>
          )}

          {userData?.roles.length == 1 ? (
            <TabFooter
              label="Upload"
              isBtnDisable={status}
              endIcon={<DoneIcon />}
              onclickHandler={onClickHandlerToDealsPage}
            />
          ) : (<></>)}
        </>
      )}
    </>
  );
};

export default OriginatorDraft;
