export const TypographyStyle = {
    fontWeight:"500",
    fontSize:"14px",
    color: "#5E7387",
    
}

export const TypographyStyleOpacity = (opacity) => {
    if(opacity){
        return {
            ...TypographyStyle,
            opacity: "0.5",
        }
    } 
    return TypographyStyle;
}

export const IconButtonStyle = {
    padding: "8px",
    width: "32px",
    height: "32px",
    background: "white",
    border: "1px solid #365069",
    borderRadius: "8px",
    gap: "4px",
}