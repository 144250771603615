export const documentHeaderTypographyStyleF1 = {
  color: "#5E7387",
  fontWeight: "500",
  fontSize: "14px",
  marginTop: "0px !important",
  marginLeft: "8px !important",
};

export const monthlyCollectionReportSummaryMainBox = {
  width: "100%",
};

export const totalEntriesUploadedGridF1 = {
  flexDirection: "row",
  paddingLeft: "20px",
};

export const totalEntriesUploadedTypographyF1 = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#20303F",
};
export const documentHeaderStack = {
  flexDirection: "row",
};
export const descriptionIconStyle = {
  color: "#5E7387",
  width: "20px",
  height: "20px",
};

// F1ListOfDeal.jsx

export const main = {
  backgroundColor: "white",
  minHeight: "144px",
  maxHeight: "auto",
  minWidth: "auto",
  maxWidth: "auto",
  boxShadow: 4,
  borderRadius: 2,
  padding: 2,
  margin: 2,
  minHeight: "calc(100% - 64px)",
};

export const listOfDealsStack = {
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "space-between",
  paddingBottom: "12px",
  display: "flex",
};
export const listOfDealsTitle = {
  fontSize: "16px",
  fontWeight: 700,
  paddingBottom: 2,
};
