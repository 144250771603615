export const paddingLeft = {
    paddingLeft: "65px",
}

export const alertStyle = {
    backgroundColor: "#41C66E",
    color: "white",
    fontFamily: "Nunito",
    fontSize: "14px",
    fontWeight: "400",
    width: "100%" 
}

