import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  pageStatusAction,
  setAssetQuality,
} from "../../../../redux/Loans/loanSlice";
import { Grid, TextField } from "@mui/material";
import TabFooter from "../../TabFooter/TabFooter";
import { SelectComponent } from "../../../FormComponents/SelectComponent";
import { SuffixTextField } from "../../../FormComponents/SuffixPrefixTextFieldV2";
import TextWrapperComponent from "../../../FormComponents/TextWrapperComponent";

const AssetQualityFilters = ({ nextTabHandler }) => {
  const loanState = useSelector((state) => state.loan);
  const dealState = useSelector((state) => state.deal);
  const assetType = dealState?.loanTypeData?.name?.toLowerCase().trim() || "";
  const [status, setStatus] = useState(true);
  const [state, setState] = useState({
    isOverdue: loanState?.isOverdue,
    dpd: loanState?.dpd,
    branchLevelPercentage: loanState?.branchLevelPercentage,
    creditScrubScore: loanState?.creditScrubScore,
    branchPar0: loanState?.branchPar0,
  });

  let history = useHistory();
  let dispatch = useDispatch();

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    const isMicrofinanceLoan =
      assetType === "microfinance loan" ||
      assetType === "unsecured business loan" ||
      assetType === "personal loan";

    if (
      state.dpd !== undefined &&
      state.dpd !== "" &&
      state.isOverdue !== undefined &&
      state.isOverdue !== "" &&
      state.creditScrubScore !== undefined &&
      state.creditScrubScore !== ""
    ) {
      setStatus(false);
    } else {
      setStatus(true);
    }

    if (!isMicrofinanceLoan && state.branchLevelPercentage !== undefined) {
      setState((prevState) => ({ ...prevState, branchLevelPercentage: "" }));
    }
    if (!isMicrofinanceLoan && state.branchPar0 !== undefined) {
      setState((prevState) => ({ ...prevState, branchPar0: "" }));
    }
  }, [state, assetType]);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "assetQualityFilters" }));
    dispatch(setAssetQuality({ ...state }));
    nextTabHandler();
  };

  return (
    <>
      <div>
        {assetType === "" ? (
          "Loading"
        ) : (
          <div style={{ padding: "20px" }}>
            <form>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextWrapperComponent text="DPD">
                    <TextField
                      size="small"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      value={state.dpd}
                      onChange={handleInputChange("dpd")}
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 7);
                      }}
                      min={1}
                      placeholder="Should be greater than 0"
                    />
                  </TextWrapperComponent>
                </Grid>

                <Grid item xs={4}>
                  <TextWrapperComponent text="Overdues (If Any)">
                    <SelectComponent
                      placeholder="Select Overdues"
                      value={state.isOverdue}
                      onClickHandler={handleInputChange("isOverdue")}
                      optionList={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                    ></SelectComponent>
                  </TextWrapperComponent>
                </Grid>

                {assetType === "microfinance loan" && (
                  <>
                    <Grid item xs={4}>
                      <TextWrapperComponent text="Branch Level PAR90">
                        <SuffixTextField
                          placeholder="Input Numbers"
                          endAdornmentText="%"
                          onChangeHandler={handleInputChange(
                            "branchLevelPercentage"
                          )}
                          value={state.branchLevelPercentage}
                        ></SuffixTextField>
                      </TextWrapperComponent>
                    </Grid>
                    <Grid item xs={4}>
                      <TextWrapperComponent text="Branch Level PAR0">
                        <SuffixTextField
                          placeholder="Input Numbers"
                          endAdornmentText="%"
                          onChangeHandler={handleInputChange("branchPar0")}
                          value={state.branchPar0}
                        ></SuffixTextField>
                      </TextWrapperComponent>
                    </Grid>
                  </>
                )}

                <Grid item xs={4}>
                  <TextWrapperComponent text="Credit Scrub Score">
                    <TextField
                      size="small"
                      autoComplete="off"
                      variant="standard"
                      type="number"
                      value={state.creditScrubScore}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      }}
                      min={0}
                      onChange={handleInputChange("creditScrubScore")}
                      fullWidth
                      placeholder="Input Numbers"
                    />
                  </TextWrapperComponent>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
      </div>

      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
    </>
  );
};

export default AssetQualityFilters;
