import React from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import { Colors } from "../../theme/theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { checkCircleIconStyle, ctaBtnStyle, displayStyle, stackStyle, titleTypo, topBoxStyle } from "./WrapperCSS";

const CTAWrapperHandler = ({
  title,
  showButton = false,
  isDisabled = false,
  onClickButton,
  children,
  text = "Duplicate Deal",
  icon,
  checkedIcon = false,
}) => {

  const btnWrapper = ctaBtnStyle(isDisabled)
  return (
    <Box sx={topBoxStyle}>
      <Stack sx={stackStyle}>
        <Box sx={displayStyle} >
          <Typography sx={titleTypo} >
            {title}
          </Typography>
          {checkedIcon && (
            <CheckCircleIcon sx={checkCircleIconStyle}></CheckCircleIcon>
          )}
        </Box>
        {showButton && (
          <Button
            disabled={isDisabled}
            startIcon={icon}
            style={{ color: isDisabled && "white" }}
            sx={btnWrapper}
            onClick={onClickButton}
          >
            {text}
          </Button>
        )}
      </Stack>

      
      {children}
      
    </Box>
  );
};

export default CTAWrapperHandler;
