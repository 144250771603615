import React from "react";
import TabHeader from "../TabHeader/TabHeader";
import Styles from "./TabContentWrapper.module.css";

const TabContentWrapper = ({ children, heading,showEditbutton, editTabhandler }) => {
  return (
    <>
      <TabHeader heading={heading} showEditbutton={showEditbutton} editTabhandler={editTabhandler}/>
      <div className={Styles.tabContent}>{children}</div>
    </>
  );
};

export default TabContentWrapper;
