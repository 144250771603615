import React from 'react'
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Pagination from '../Pagination/Pagination';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@mui/material/Paper";
import { useState } from 'react';
import { actionTypo, actionsIcon } from './TableCSS';


const DealDuplicationDealistTable = ({ data, viewActionClickHandler, header}) => {
  const [page,setPage] = useState(1);

  const nextPageHandler = () => {
    setPage(page+1);
  }

  const prevPageHandler = () => {
    setPage(page-1);
  }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        textAlign: "center",
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "#EBEEF0",
          color: "#5E7387",
          fontSize: "12px",
          fontWeight: "700",
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
        // padding: "6px",
      }));
    
      const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "#EBEEF0",
          color: "#5E7387",
          fontSize: "12px",
          fontWeight: "500",
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          textAlign: "center",
        },
      }));
    
      const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
        borderBottom: "0px solid #EBEEF0",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column"
      }));

      const Actions = ({ label = "View Details", Icon }) => {
        // label = "View Details"
        return (
          <div onClick={() => viewActionClickHandler()} style={{ cursor: "pointer" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              sx={{
                justifyContent: "flex-end"
              }}
            //   justifyItems="flex-end"
            >
              <Icon sx={actionsIcon}></Icon>
    
              <Typography sx={actionTypo}>
                {label}
              </Typography>
            </Stack>
          </div>
        );
      };

      return (
        <div className="App">
          <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {header.map((data) => {
                  return <StyledTableCell>{data}</StyledTableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => {
                return (
                  <TableRow key={item.id} sx={{margin: "auto"}}>
                    <StyledTableCell1 component="th" scope="row">
                      {item.DealName}
                    </StyledTableCell1>

                    <StyledTableCell component="th" scope="row">
                      {item.AssetClass}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.CreationDate}
                    </StyledTableCell>
                    <TableCell sx={{alignItems: "center"}}>
                      <StyledNoBorderTableCell>
                        <Actions Icon={VisibilityIcon}></Actions>
                      </StyledNoBorderTableCell>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableContainer>
          <Pagination TotalNoNumber={Math.ceil(data.length / 10) } Page={page} nextPageHandler={nextPageHandler} prevPageHandler={prevPageHandler}></Pagination>
        </div>
      )

}

export default DealDuplicationDealistTable;