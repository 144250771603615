import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  createLoanFilters,
  pageStatusAction,
  setLoanProperTies,
} from "../../../../redux/Loans/loanSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../../redux/app/appSlice";
import { SelectComponent } from "../../../FormComponents/SelectComponent";
import {
  SuffixPrefixTextField,
  SuffixTextField,
} from "../../../FormComponents/SuffixPrefixTextFieldV2";
import TextWrapperComponent from "../../../FormComponents/TextWrapperComponent";
import TabFooter from "../../TabFooter/TabFooter";
import Paths from "../../../constants/Path";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 350,
      background: "#EBEEF0",
    },
  },
};

const variantsVehicle = [
  { id: 1, name: "MUV" },
  { id: 2, name: "HCV" },
  { id: 3, name: "LCV" },
];

const variants = [
  { id: 1, name: "ECS" },
  { id: 2, name: "PDC" },
  { id: 3, name: "NACH" },
  { id: 4, name: "CASH" },
];

const allConstitution = [
  { id: 1, name: "Proprietor" },
  { id: 2, name: "Partnership" },
  { id: 3, name: "Pvt Ltd" },
  { id: 4, name: "Individual" },
  { id: 5, name: "Trust/Society" },
];

const allCustomerSegment = [
  { id: 1, name: "Salaried" },
  { id: 2, name: "Self Employed" },
];

const allTypeProperty = [
  { id: 1, name: "Industrial" },
  { id: 2, name: "Commercial" },
  { id: 3, name: "Residential" },
  { id: 4, name: "CVV" },
  { id: 5, name: "Gold" },
  { id: 6, name: "NA" },
];

const LoanPropertiesFilters = ({ nextTabHandler }) => {
  const loanState = useSelector((state) => state.loan);
  const [status, setStatus] = useState(true);

  const [properties, setProperties] = useState({
    customerConstitution: loanState.customerConstitution || [],
    ltv: loanState.ltv,
    foir: loanState.foir,
    paymentModes: loanState.paymentModes || "",
    propertyType: loanState.propertyType || [],
    customerSegment: loanState.customerSegment || [],
    maxPrincipalOutstanding: loanState.maxPrincipalOutstanding || "",
    isPddCompletion: loanState.isPddCompletion,
    typeOfVehicle: loanState.vehicleTypes || [],
  });

  const [variantName, setVariantName] = useState({
    paymentModes: [...loanState.paymentModes] || [],
    customerConstitution: [...loanState.customerConstitution] || [],
    customerSegment: [...loanState.customerSegment] || [],
    TypeOfProperty: [...loanState.propertyType] || [],
    typeOfVehicle: [...loanState.vehicleTypes] || [],
  });

  const [state, setState] = useState({
    TypeOfProperty: loanState.propertyType || [],
    CustomerSegment: loanState.customerSegment || [],
    CustomerConsitution: loanState.customerConstitution || [],
    isPddCompletion: loanState.isPddCompletion,
    typeOfVehicle: loanState.typeOfVehicle || [],
  });

  let history = useHistory();
  let dispatch = useDispatch();
  const params = useParams();
  let { dealId } = params;
  const assetType = useSelector((state) =>
    state.deal?.loanTypeData?.name.toString().toLowerCase().trim()
  );

  useEffect(() => {
    if (loanState.message !== "") {
      dispatch(showErrorAlertAction(loanState.message));
    }
  }, [loanState.message]);

  useEffect(() => {
    setState({ ...state, isPddCompletion: loanState.isPddCompletion });
  }, [loanState.isPddCompletion]);

  useEffect(() => {
    setProperties({
      ...properties,
      propertyType: variantName.TypeOfProperty,
      customerSegment: variantName.customerSegment,
      customerConstitution: variantName.customerConstitution,
      isPddCompletion: state.isPddCompletion,
      paymentModes: variantName.paymentModes,
      typeOfVehicle: variantName.typeOfVehicle,
    });
  }, [variantName]);

  useEffect(() => {
    dispatch(setLoanProperTies({ ...properties }));
  }, [properties]);

  useEffect(() => {
    const isUnsecuredLoan =
      assetType === "unsecured business loan" || assetType === "personal loan";

    const isMicrofinanceLoan = assetType === "microfinance loan";

    const requiredFields =
      (isUnsecuredLoan || isMicrofinanceLoan) &&
      properties.isPddCompletion !== undefined &&
      properties.customerConstitution.length > 0 &&
      properties.customerSegment.length > 0 &&
      properties.maxPrincipalOutstanding !== "" &&
      properties.paymentModes.length > 0;

    const requiredFieldsOther =
      !isUnsecuredLoan &&
      !isMicrofinanceLoan &&
      properties.paymentModes.length > 0 &&
      properties.customerConstitution.length > 0 &&
      properties.ltv !== "" &&
      properties.foir !== "" &&
      properties.propertyType.length > 0 &&
      properties.customerSegment.length > 0 &&
      properties.maxPrincipalOutstanding !== "" &&
      properties.isPddCompletion !== undefined;

    const requiredFieldsMicrofinance =
      isMicrofinanceLoan && properties.isPddCompletion !== undefined;

    setStatus(
      !(requiredFields || requiredFieldsOther || requiredFieldsMicrofinance)
    );
  }, [properties, assetType]);

  const handleChange = (event, name) => {
    const { target } = event;
    const { value } = target;

    let duplicateRemoved = Array.from(new Set(value));
    setVariantName({ ...variantName, [name]: duplicateRemoved });
  };

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setProperties({ ...properties, [name]: value });
    setState({ ...state, [name]: value });
    dispatch(setLoanProperTies({ ...properties }));
  };

  const renderCheckboxSelect = (label, name, value, items) => (
    <Grid item xs={4}>
      <TextWrapperComponent text={label}>
        <FormControl sx={{ width: 220 }}>
          <Select
            multiple
            IconComponent={ExpandMoreIcon}
            displayEmpty
            value={value}
            onChange={(event) => handleChange(event, name)}
            variant="standard"
            name={name}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p
                    style={{
                      color: "#AFB9C3",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {`Select ${label}`}
                  </p>
                );
              }
              return selected.map((x) => x).join(", ");
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            {items.map((item) => (
              <MenuItem key={item.id} value={item.name} sx={{ height: "40px" }}>
                <Checkbox size="small" checked={value.includes(item.name)} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TextWrapperComponent>
    </Grid>
  );

  const renderSuffixTextField = (label, name, value) => (
    <Grid item xs={4}>
      <TextWrapperComponent text={label}>
        <SuffixTextField
          placeholder={`Input ${label}`}
          value={value}
          onChangeHandler={handleInputChange(name)}
          endAdornmentText="%"
        />
      </TextWrapperComponent>
    </Grid>
  );

  const renderSuffixPrefixTextField = (label, name, value) => (
    <Grid item xs={4}>
      <TextWrapperComponent text={label}>
        <SuffixPrefixTextField
          isDecimal={true}
          placeholder={`Input ${label}`}
          startAdornmentText="₹"
          value={value}
          onChangeHandler={handleInputChange(name)}
          endAdornmentText="Crores"
        />
      </TextWrapperComponent>
    </Grid>
  );

  const renderSelectComponent = (label, name, value, items) => (
    <Grid item xs={4}>
      <TextWrapperComponent text={label}>
        <SelectComponent
          placeholder={`Select ${label}`}
          value={value}
          onClickHandler={handleInputChange(name)}
          optionList={items}
        ></SelectComponent>
      </TextWrapperComponent>
    </Grid>
  );

  let typeOfVechile = variantName?.TypeOfProperty.filter(
    (item) => item === "CVV"
  );

  const onClickHandlerToOriginatorList = async () => {
    dispatch(pageStatusAction({ pageName: "loanPropertiesFilters" }));

    dispatch(setLoanProperTies({ ...properties }));

    dispatch(
      createLoanFilters({
        ...loanState,
        dealId,
        typeOfVehicle: variantName?.typeOfVehicle,
      })
    ).then((res) => {
      dispatch(showAlertAction(res.payload.data.message));
      history.push(Paths.DEALS_LIST);
    });
  };

  return (
    <>
      <div>
        <div style={{ padding: "20px" }}>
          <form>
            <Grid container spacing={4}>
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
              ].includes(assetType) &&
                renderSuffixTextField("LTV", "ltv", properties.ltv)}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
              ].includes(assetType) &&
                renderSuffixTextField("FOIR", "foir", properties.foir)}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
                "personal loan",
                "unsecured business loan",
              ].includes(assetType) &&
                renderCheckboxSelect(
                  "Payment Mode",
                  "paymentModes",
                  variantName.paymentModes,
                  variants
                )}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
              ].includes(assetType) &&
                renderCheckboxSelect(
                  "Type of Property",
                  "TypeOfProperty",
                  variantName.TypeOfProperty,
                  allTypeProperty
                )}
              {typeOfVechile.includes("CVV") &&
                renderCheckboxSelect(
                  "Type of Vehicle",
                  "typeOfVehicle",
                  variantName.typeOfVehicle,
                  variantsVehicle
                )}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
                "personal loan",
                "unsecured business loan",
              ].includes(assetType) &&
                renderCheckboxSelect(
                  "Customer Segment",
                  "customerSegment",
                  variantName.customerSegment,
                  allCustomerSegment
                )}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
                "personal loan",
                "unsecured business loan",
              ].includes(assetType) &&
                renderCheckboxSelect(
                  "Customer Constitution",
                  "customerConstitution",
                  variantName.customerConstitution,
                  allConstitution
                )}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
                "personal loan",
                "unsecured business loan",
              ].includes(assetType) &&
                renderSuffixPrefixTextField(
                  "Max. Principal Outstanding",
                  "maxPrincipalOutstanding",
                  properties.maxPrincipalOutstanding
                )}
              {[
                "home loan",
                "hl & lap",
                "home loan & loan against property",
                "loan against property",
                "gold loan",
                "commercial vehicle loan",
                "personal vehicle loan",
                "two wheeler",
                "personal loan",
                "unsecured business loan",
                "microfinance loan",
              ].includes(assetType) &&
                renderSelectComponent(
                  "PDD Completion",
                  "isPddCompletion",
                  state.isPddCompletion,
                  [
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]
                )}
            </Grid>
          </form>
        </div>
      </div>

      <TabFooter
        onclickHandler={onClickHandlerToOriginatorList}
        isBtnDisable={status}
        showSubmitButton={true}
        submitBtnText="Update Details"
      />
    </>
  );
};

export default LoanPropertiesFilters;
