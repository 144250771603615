import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import { getEntityTemplate } from "../../../redux/Entity/entitySlice";
import {
  pageStatusAction,
  setTypeOfReport,
} from "../../../redux/originator/creditBureauSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import TabFooter from "../TabFooter/TabFooter";

const TypeOfReport = ({ nextTabHandler }) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  const cibilState = useSelector((state) => state.creditScrub);
  const params = useParams();
  const [cibilTempData, setCibilTempData] = useState("");
  const [crifTempData, setCrifTempData] = useState("");
  const [status, setStatus] = useState(true);

  let loading = dealState?.loading;

  useEffect(() => {
    if (cibilState?.typeOfReport != "") {
      setStatus(false);
    }
  }, [cibilState.typeOfReport]);

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
    dispatch(getEntityTemplate({ type: "CIBIL" })).then((res) => {
      setCibilTempData(res?.payload?.file);
    });
    dispatch(getEntityTemplate({ type: "CRIF" })).then((res) => {
      setCrifTempData(res?.payload?.file);
    });
  }, []);


  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "typeOfReport" }));
    nextTabHandler();
  };

  //radio button download handler

  const downloadHandler = (event) => {
    let reportType = event?.target?._wrapperState?.initialValue;
    dispatch(setTypeOfReport(reportType === true ? "CIBIL" : "CRIF"));
  };

  return (
    <>
      {loading && loading ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title={"Type Of Report"}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              />
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <Grid item xs={4}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={
                  cibilState?.typeOfReport === "CIBIL"
                    ? true
                    : cibilState?.typeOfReport === "CRIF"
                    ? false
                    : ""
                }
                onChange={downloadHandler}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Cibil Report"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Crif Report"
                />
              </RadioGroup>
            </Grid>
          </div>

          {cibilState?.typeOfReport === "CIBIL" ? (
            <>
              <DocumentHeader
                title={"Cibil Report Template"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>

              <div
                style={{
                  padding: "24px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Cibil Report
                </Typography>
                <ViewDownloadDeleteComp
                  showEye={false}
                  fileName={cibilTempData?.filename || ""}
                  id={cibilTempData?._id}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          ) : (
            <></>
          )}

          {cibilState?.typeOfReport === "CRIF" ? (
            <>
              <DocumentHeader
                title={"Crif Report Template"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>

              <div
                style={{
                  padding: "24px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Crif Report
                </Typography>
                <ViewDownloadDeleteComp
                  showEye={false}
                  fileName={crifTempData?.filename || ""}
                  id={crifTempData?._id}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          ) : (
            <></>
          )}

          <TabFooter
            onclickHandler={nextBtnClickHandler}
            label="Next"
            endIcon={<DoneIcon />}
            isBtnDisable={status}
          ></TabFooter>
        </>
      )}
    </>
  );
};

export default TypeOfReport;
