import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import _ from "lodash";

import { useHistory } from "react-router-dom";
import moment from "moment";
import { actionTypo, actionsIcon, originatorNameStyle, statusTopBox } from "./TableCSS";

const L2LoanDealListTable = ({
  data,
  viewActionClickHandler,
  header,
  statusList,
  page,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  // const [page,setPage] = useState(1);
  console.log({ data });
  let sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.DealStatus > b.DealStatus) {
      return -1;
    }
    if (a.DealStatus < b.DealStatus) {
      return 1;
    }
    return 0;
  });
  console.log(sortedData);
  let filterData = data.filter((item) => item.DealStatus === "Pending");

  let newData = [...filterData, ...sortedData];
  let finalData = _.uniqBy(newData, (e) => e.id);

  // const nextPageHandler = () => {
  //   setPage(page+1);
  // }

  // const prevPageHandler = () => {
  //   setPage(page-1);
  // }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  }));

  const StatusContainer = ({ statusType }) => {
    // console.log(statusList)

    const foundStatus = statusList.find((obj) => {
      return obj.status === statusType;
    });

    // console.log(foundStatus.color)
    const Textcolor =
      statusType === "APPROVED" ||
      statusType === "Uploaded" ||
      statusType === "DONE" ||
      statusType === "COMPLETED"
        ? "#277742"
        : statusType === "Pending"
        ? "#8D3431"
        : statusType === "CLOSED"
        ? "#2C4584"
        : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
      statusType === "DONE" ||
      statusType === "Uploaded" ||
      statusType === "COMPLETED"
        ? "#E3F6E9"
        : statusType === "Pending"
        ? "#FCE6E5"
        : statusType === "CLOSED"
        ? "#E4EAFA"
        : "#FFF4E7";

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
        }}
      >
        {statusType}
      </Box>
    );
  };

  const Actions = ({ label = "View", Icon, item }) => {
    // label = "View"
    return (
      <div onClick={() => viewActionClickHandler(item)}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Icon sx={actionsIcon}></Icon>
          {/* <Icon fontSize="small" sx={{ color: item?.poolUploadFinal?.cibilScoreCurrentStatus === "DONE" ? "#365069" : "#AFB9C3", }}></Icon> */}

          <Typography sx={actionTypo}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item?.id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item?.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell component="th" scope="row">
                    {item?.loanType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.requestedDealAmount}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(item.createdAt).format("D MMM YYYY")}
                  </StyledTableCell>

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        Icon={VisibilityIcon}
                        dealId={item.id}
                        item={item}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        Page={page}
        TotalNoNumber={totalRecords}
        nextPageHandler={page === totalRecords ? null : nextPageHandler}
        prevPageHandler={prevPageHandler}
      ></Pagination>
    </div>
  );
};

export default L2LoanDealListTable;
