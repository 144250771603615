import { useState } from "react";
import styles from "./Sidebar.module.css";
import Fold from "../../assets/icons/Fold.svg";
import UnFold from "../../assets/icons/UnFold.svg";
import HomeIcon from "../../assets/icons/Home.svg";
import LoansIcon from "../../assets/icons/Loan.svg";
import PortfolioIcon from "../../assets/icons/Portfolio.svg";
import NotificationsIcon from "../../assets/icons/NotificationsIcon.svg";
import CreditFiltersIcon from "../../assets/icons/CreditFilters.svg";
import TermSheetIcon from "../../assets/icons/TermSheetIcon.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import SearchReportsIcon from "../../assets/icons/searchReports.svg";
import DealIcon from "../../assets/icons/deal_icon.svg";
import OriginatorIcon from "../../assets/icons/originator_icon.svg";
import SecondaryCheckIcon from "../../assets/icons/secondary_check_icon.svg";
import { useDispatch, useSelector } from "react-redux";

//? react router
import {
  NavLink,
  BrowserRouter,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import { toggleSidebarAction } from "../../redux/app/appSlice";
import Paths from "../constants/Path";
import { useEffect } from "react";
import { getData, postRequest } from "../../API/apiHandler";
import { Avatar, Box, Typography } from "@mui/material";

const Sidebar = ({ tabsData }) => {
  let [sideList, setSideList] = useState([]);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  let originatorState = useSelector((state) => state.originator);

  const [activeRole, setActiveRole] = useState(localStorage.getItem("activeRole") || userData.roles[0]);
  
  
  const [APIResponse, setAPIResponse] = useState([])
  
  
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      setActiveRole(localStorage.getItem("activeRole"));  
    });
  }, []);

  useEffect(() => {
    let filterApiREsponse = userData.roleWisePermission.filter(response=>{
      return response.role == activeRole
  });
  setAPIResponse(filterApiREsponse);
  },[activeRole])

  
  let ListofSideBar = [
    {
      path: Paths.PHASE1HOME,
      module: "Dashboard",
      icon: HomeIcon,
    },
    {
      path:
      activeRole === "Originator"
          ? Paths.ORIGINATORDEALLIST
          : activeRole === "L1"
          ? Paths.DEALS_LIST
          : activeRole === "L2"
          ? Paths.L2DEALLIST
          : activeRole === "Auditor"
          ? Paths.AUDITORDEALIST
          : activeRole === "F1"
          ? Paths.F1LISTOFDEAL
          : activeRole == "C1" ||
            activeRole == "R1" ||
            activeRole == "CP1" ||
            activeRole == "IA1" ||
            activeRole == "CO1"
          ? Paths.SWAYAMUSERDEALLIST
          : Paths.APPROVERDEALIST,
      module: "Deal",
      icon: DealIcon,
    },
    {
      path:
        activeRole === "L1"
          ? originatorState?.originatorsList?.length > 0
            ? Paths.ORIGINATOR_LIST
            : Paths.CREATE_ORIGINATOR
          : activeRole === "L2"
          ? Paths.L2OIRIGINATORSLIST
          : Paths.ORIGINATOR_LIST,
      module: "Originator",
      icon: OriginatorIcon,
    },

    {
      path:
      activeRole === "Originator"
          ? Paths.POOLSHORTLISTINGDEALIST
          : activeRole === "L1"
            ? Paths.POOLSHORTLISTINGDEALIST
            : // : activeRole === "L2"
            // ? Paths.CONDUCTPOOLSHORTLISTING
            null,
      module: "Secondary Check",
      icon: SecondaryCheckIcon,
    },
    {
      path:
      activeRole === "Originator" ? Paths.PRIMARYCHECKDEALLIST : null,
      module: "Primary Check",
      icon: SecondaryCheckIcon,
    },

    {
      path:
      activeRole === "Originator"
          ? Paths.ORIGINATORKYCLINKDEALLIST
          : activeRole === "L1"
            ? Paths.CREDITBUREAUREPORTDEALIST
            : // : activeRole === "L2"
            // ? Paths.CREDITBUREAUREPORTDEALIST
            null,
      module: "Loan KYC Documents",
      icon: PortfolioIcon,
    },

    {
      path:
      activeRole === "Originator"
          ? Paths.ORIGINATORKYCLINKDEALLIST
          : activeRole === "L1"
          ? Paths.L1DealList
          : null,
      module: "Final Deal",
      icon: PortfolioIcon,
    },
    {
      path: activeRole === "F2" ? Paths.MONTH_END_UPLOADS : null,
      module: "Month end",
      icon: PortfolioIcon,
    },

    {
      path:
      activeRole === "Originator"
          ? Paths.TERM_SHEET
          : activeRole === "L1"
          ? Paths.TERM_SHEET
          : activeRole === "L2"
          ? Paths.TERM_SHEET
          : null,
      module: "Term Sheet",
      icon: TermSheetIcon,
    },
    {
      path: activeRole === "L2" ? Paths.L2LOANDEALIST : null,
      module: "Loans",
      icon: PortfolioIcon,
    },
    {
      path: activeRole === "L2" ? Paths.L2CREDITFILTERSDEALIST : null,
      module: "Credit Filters",
      icon: CreditFiltersIcon,
    },
    {
      path:
      activeRole === "Originator"
          ? Paths.ORIGINATORCREDITDEALSLIST
          : null,
      module: "Credit Bureau report",
      icon: CreditFiltersIcon,
    },
    {
      path:
      activeRole === "Originator" && userData?.roles.length == 1 ? Paths.ORIGINATORPROFILELIST : null,
      module: "Originator Profile",
      icon: CreditFiltersIcon,
    },

    {
      path: Paths.LISTOFREPORTS,
      module: "Reports",
      icon: PortfolioIcon,
    },

    {
      path: Paths.GENERATEREPORT,
      module: "Search Reports",
      icon: SearchReportsIcon,
    },
  ];

  const location = useLocation();
  const roleName = activeRole;
  const sideListHandler = () => {
    let finalTabs = [
      {
        module: "Dashboard",
        path:
          activeRole === "F1" || activeRole === "F2"
            ? Paths.PHASE1HOME
            : activeRole === "Originator"
            ? Paths.ORIGINATORHOME
            : Paths.DASHBOARD,
        icon: HomeIcon,
      },
    ];


    for (let i of APIResponse) {
      for (let k of ListofSideBar) {
        if (i.module == k.module) {
          finalTabs.push(k);
        }
      }
    }

    if (activeRole === "L1") {
      finalTabs.push({
        path: Paths.PORTFOLIOFINALDEALLIST,
        module: "Portfolio",
        icon: NotificationsIcon,
      });
    }

    if (activeRole === "L2") {
      finalTabs.push({
        path: Paths.PORTFOLIOFINALDEALLIST,
        module: "Portfolio",
        icon: NotificationsIcon,
      });
    }

    if (
      activeRole === "C1" ||
      activeRole == "R1" ||
      activeRole == "CP1" ||
      activeRole == "IA1" ||
      activeRole == "CO1"
    ) {
      finalTabs.push({
        path: Paths.PORTFOLIOFINALDEALLIST,
        module: "Portfolio",
        icon: NotificationsIcon,
      });
    }

    if (activeRole === "Originator") {
      finalTabs.push({
        path: Paths.EntityDealList,
        module: "Entity Details",
        icon: NotificationsIcon,
      });
    }

    finalTabs.push({
      path: Paths.NOTIFICATIONS,
      module: "Notifications",
      icon: NotificationsIcon,
    });

    setSideList(finalTabs);
  };
  let [notificationCount, setNotificationCount] = useState(0);
  var history = useHistory();

  useEffect(() => {
    sideListHandler();
  }, []);

  let History = useHistory();

  //? useSelector
  let { showSidebar } = useSelector((state) => state.app);

  //? useDispatch
  let dispatch = useDispatch();

  const readAllNotifications = async () => {
    // console.log("readAllNotifications");
    await postRequest("/utility-service/notification/readAll");
    setNotificationCount(0);
    history.push(Paths.NOTIFICATIONS);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  };

  let sideListFinal = [];

  const getPath = (module) => {
    switch (module) {
      case "Deal":
        return {
          path:
            roleName === "Originator"
              ? Paths.ORIGINATORDEALLIST
              : roleName === "L1"
              ? Paths.DEALS_LIST
              : roleName === "L2"
              ? Paths.L2DEALLIST
              : roleName === "Auditor"
              ? Paths.AUDITORDEALIST
              : roleName === "F1"
              ? Paths.F1LISTOFDEAL
              : roleName === "C1" ||
                roleName == "R1" ||
                roleName == "CP1" ||
                roleName == "IA1" ||
                roleName == "CO1"
              ? Paths.SWAYAMUSERDEALLIST
              : Paths.APPROVERDEALIST,
          icon: DealIcon,
          sequence: 4,
        };
      case "Dashboard":
        return {
          path:
            roleName === "F1" || roleName === "F2"
              ? Paths.PHASE1HOME
              : roleName === "Originator"
              ? Paths.ORIGINATORHOME
              : Paths.DASHBOARD,
          icon: HomeIcon,
          sequence: 0,
        };
      case "Portfolio":
        return {
          path: Paths.PORTFOLIOFINALDEALLIST,
          icon: PortfolioIcon,
          sequence:
            roleName === "C1" ||
            roleName == "R1" ||
            roleName == "CP1" ||
            roleName == "IA1" ||
            roleName == "CO1"
              ? 4
              : 8,
        };

      case "Notification":
        return {
          path: userData?.roles.length == 1 ? Paths.NOTIFICATIONS : null,
          icon: NotificationsIcon,
          sequence: 2,
        };

      case "Reports":
        return {
          path: Paths.LISTOFREPORTS,
          icon: PortfolioIcon,
          sequence: 3,
        };

      case "Search Reports":
        return {
          path: userData?.roles.length == 1 ? Paths.GENERATEREPORT : null,
          icon: SearchReportsIcon,
          sequence: 4,
        };

      case "Month end":
        return {
          path: activeRole === "F2" && userData?.roles.length == 1 ? Paths.MONTH_END_UPLOADS : null,
          icon: PortfolioIcon,
          sequence: 5,
        };

      case "Credit Bureau report":
        return {
          path:
            roleName === "Originator"
              ? Paths.ORIGINATORCREDITDEALSLIST
              : Paths.CREDITBUREAUREPORTDEALIST,
          icon: CreditFiltersIcon,
          sequence: 6,
        };

      case "Originator":
        return {
          path:
            roleName === "L1"
              ? originatorState?.originatorsList?.length > 0
                ? Paths.ORIGINATOR_LIST
                : Paths.CREATE_ORIGINATOR
              : roleName === "L2"
              ? Paths.L2OIRIGINATORSLIST
              : Paths.ORIGINATOR_LIST,
          icon: OriginatorIcon,
          sequence: 3,
        };

      case "Pool Shortlisting":
        return {
          path: Paths.POOLSHORTLISTINGDEALIST,
          icon: SecondaryCheckIcon,
          sequence: 5,
        };

      case "Term Sheet":
        return {
          path: Paths.TERM_SHEET,
          icon: TermSheetIcon,
          sequence: 7,
        };

      case "Loans":
        return {
          path: Paths.L2LOANDEALIST,
          icon: LoansIcon,
          sequence: 6,
        };

      case "Credit Filter":
        return {
          path: Paths.L2CREDITFILTERSDEALIST,
          icon: CreditFiltersIcon,
          sequence: 5,
        };

      case "KYC Due Diligence":
        return {
          path: Paths.ORIGINATORKYCLINKDEALLIST,
          icon: SecondaryCheckIcon,
          sequence: 5,
        };

      case "Supporting Documents":
        return {
          path: Paths.EntityDealList,
          icon: NotificationsIcon,
          sequence: 6,
        };

      case "Deal Details":
        return {
          path: Paths.APPROVERDEALIST,
          icon: DealIcon,
          sequence: 3,
        };

      case "Originator Profile":
        return {
          path: roleName === "Originator" && userData?.roles.length == 1 ? Paths.ORIGINATORPROFILELIST : null,
          icon: OriginatorIcon,
          sequence: 7,
        };

      default:
        return {
          path: null,
          icon: null,
        };
    }
  };

  // Fixes
  APIResponse?.forEach((item) => {
    // console.log({ ...getPath(item.module) })
    sideListFinal.push({
      module: item.module,
      ...getPath(item.module),
    });
  });

  sideListFinal.push({
    path: "/login",
    module: "Logout",
    icon: LogoutIcon,
    sequence: sideListFinal.length + 1,
  });

  sideListFinal.sort((a, b) => a.sequence - b.sequence);

  // console.log({ sideListFinal });
  let tabsList = sideListFinal.map((tab, index) => {
    // console.log(tab)
    return showSidebar ? (
      tab.path == null ? (
        ""
      ) : (
        <NavLink
          key={index}
          onClick={() => {
            tab.module === "Logout"
              ? logout()
              : tab.module === "Notification" && notificationCount > 0
              ? readAllNotifications()
              : History.push(tab.path);
          }}
          to={tab.path}
          className={styles.tab}
          activeClassName={styles.activeTab}
          style={{
            height: "44px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "30px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "30px",
              }}
            >
              <img src={tab.icon}></img>
            </div>
            <Box
              sx={{
                paddingLeft: "40px",
                fontWeight: "400",
                fontSize: "14px",
                lineheight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{tab.module}</Typography>
              {tab.module === "Notification" && notificationCount > 0 && (
                <Avatar
                  sx={{
                    bgcolor: "red",
                    width: 30,
                    height: 30,
                    fontSize: "12px",
                    padding: "5px",
                    marginLeft: "5px",
                  }}
                  sizes="small"
                >
                  {notificationCount}
                </Avatar>
              )}
            </Box>
            {/* <p
              style={{
                paddingLeft: "40px",
                fontWeight: "400",
                fontSize: "14px",
                lineheight: "20px",
              }}
            >
              {tab.module === "Notification"
                ? `Notifications ` +
                  (
                    <Typography
                      sx={{
                        background: "orange",
                      }}
                    >
                      {notificationCount}
                    </Typography>
                  )
                : tab.module}
            </p> */}
          </div>
        </NavLink>
      )
    ) : (
      <NavLink
        key={index}
        onClick={() => History.push(tab.path)}
        to={tab.path}
        className={styles.tab}
        activeClassName={styles.activeTab}
        style={{
          height: "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={tab.icon}></img>
      </NavLink>
    );
  });

  const getNotificationCount = async () => {
    const count = await getData("/utility-service/notification/count");
    // console.log({ count: count?.data });
    setNotificationCount(count?.data);
  };

  useEffect(() => {
    getNotificationCount();
    const interval = setInterval(() => {
      getNotificationCount();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={
        showSidebar ? styles.expandSidebarWrapper : styles.shrinkSidebarWrapper
      }
    >
      <div className={styles.tabsListWrapper}>
        <BrowserRouter>{tabsList}</BrowserRouter>
      </div>

      <div
        className={
          showSidebar ? styles.shrinkBottomWrapper : styles.expandBottomWrapper
        }
      >
        <div
          className={
            showSidebar
              ? styles.expandBottomButtonWrapper
              : styles.shrinkBottomButtonWrapper
          }
        >
          {showSidebar ? (
            <img
              onClick={() => dispatch(toggleSidebarAction())}
              src={UnFold}
              alt="bottomBtn"
            />
          ) : (
            <img
              onClick={() => dispatch(toggleSidebarAction())}
              src={Fold}
              alt="bottomBtn"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
