export const stackWrapper = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  height: "auto",
};

export const headingWrapper = {
  fontSize: "18px",
  fontWeight: "800",
  lineHeight: "26px",
  align: "center",
};

export const descWrapper = {
  fontSize: "14px",
  fontWeight: "400",
  color: "#4D4D4D",
  lineHeight: "20px",
};
export const CreditBureauScrubReportTypograhy = {
  fontSize: "14px",
  color: "#808080",
  fontWeight: 400,
  paddingBottom: "10px",
};
