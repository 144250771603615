import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import styles from "./CreditFilters.module.css";
import Layout from '../../../components/Layout/Layout';
import { styled } from "@mui/material/styles";
import L2LoanDealistTable from '../../../components/Table/L2LoanDealistTable';
import searchIcon from "../../../assets/icons/searchIcon.svg"
import CreditFiltersDealTable from '../../../components/Table/CreditFiltersDealTable';
import { useDispatch, useSelector } from "react-redux";
import { getAllDeals } from "../../../redux/Deals/dealSlice.js";
import useDebounce from "../../../Hooks/useDebounce";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import { FilterConstants } from '../../../components/constants/FiltersConstant.js';

const L2CreditFiltersDealist = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setFilter] = useState(0);
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);


  const debouncedSearch = useDebounce(search, 1000);


  const filterHandler = (name) => {
    if (name == "PEL" || name == "PCHFL") {
      setEntityFilter(name);
      setFilterName("")
    } else {
      setFilterName(name);
      setEntityFilter("")
    }
  };
  useEffect(() => {
    dispatch(getAllDeals({ limit, currentPage, debouncedSearch, filterName, entityFilter, source: "PHFL" }));
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(
      getAllDeals({ limit, currentPage: 1, debouncedSearch, filterName, entityFilter, source: "PHFL" })
    );
  }, [filterName, entityFilter]);



  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: "Deal List",
    },
  ];

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];



  let history = useHistory();
  const clickHandlerToViewDetails = (deal, isCreditfilter) => {
    // return history.push(Paths.L2CREDITFILTERSDETAILS); 
    console.log("data", isCreditfilter)
    if (isCreditfilter != undefined) {

      return history.push({
        pathname: `/${deal._id || deal.id || deal}/l2creditfiltersdetails`,
        state: deal,
      });
    }
    //  /l2creditfiltersdetails
  };
  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };




  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
          </Stack>

          <Divider></Divider>

          <div className={styles.filterListWrapper}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item || entityFilter == item ? "white" : "black",
                        backgroundColor:
                          filterName === item || entityFilter == item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                    {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
            </div>
          ) : (

            <Box>
              <CreditFiltersDealTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Credit Filters Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
              ></CreditFiltersDealTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  )

}

export default L2CreditFiltersDealist;