import { Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { pageStatusAction } from "../../../redux/L1/kycDocumentsRequestSlice";
import TabFooter from "../TabFooter/TabFooter";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import DescriptionIcon from "@mui/icons-material/Description";
import Loader from "../../../utils/Loader";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import styles from "./PoolValidation.module.css";
import moment from "moment/moment";

const PoolValidation = ({ nextTabHandler }) => {
  let creditScrubState = useSelector((state) => state.creditScrub);
  let dealState = useSelector((state) => state.deal);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let dispatch = useDispatch();


  const nextBtnClickHandler = (e) => {
    e.preventDefault();

    if (
      !(
        creditScrubState?.creditBureauScrub?.cibilScoreSuccessFile &&
        creditScrubState?.creditBureauScrub?.cibilScoreSuccessFile?.filename !==
        ""
      )
    ) {
      dispatch(showErrorAlertAction("No Approved Pool Found"));
      return;
    }

    dispatch(pageStatusAction({ pageName: "poolValidation" }));
    nextTabHandler();
  };

  const recordStatus = [
    {
      title: "Total Loans Uploaded",
      count: creditScrubState?.creditBureauScrub?.cibilScoreTotalRecords || "0",
    },
    {
      title: "Total Loans Approved",
      count:
        creditScrubState?.creditBureauScrub?.cibilScoreSuccessRecords || "0",
    },
    {
      title: "Total Loans Rejected",
      count:
        creditScrubState?.creditBureauScrub?.cibilScoreRejectedRecords || "0",
    },
    {
      title: "Principal Outstanding",
      count: creditScrubState?.creditBureauScrub?.outstandingAmount || "0",
    },
    {
      title: "Current Cibil Score",
      count: creditScrubState?.creditBureauScrub?.cibilScore || "0",
    },
  ];

  let CibilScoreMaxComp = ({ item, cibilHistory, index }) => {
    const summary = [
      {
        title: "Principal Outstanding",
        count: item?.outstandingAmount || "0",
      },
      {
        title: "Total Loans Uploaded",
        count: item?.cibilScoreTotalRecords || "0",
      },
      {
        title: "Total Loans Approved",
        count: item?.cibilScoreSuccessRecords || "0",
      },
      {
        title: "Total Loans Rejected",
        count: item?.cibilScoreRejectedRecords || "0",
      },
    ];
    console.log({ cibilHistory });

    return (
      <>
        <div style={{ padding: "20px" }}>
          <div className={styles.poolHeading}>
            <Grid container>
              <Grid item xs={3}>
                <TextWrapperComponent
                  text={
                    cibilHistory.length - 1 == index
                      ? "Latest Cibil Score"
                      : "Previous Cibil Score"
                  }
                ></TextWrapperComponent>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  marginLeft: "-35px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", color: "#20303F", fontWeight: 400 }}
                >
                  {` : `} {item.cibilScore}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ marginLeft: "80px" }}>
                <TextWrapperComponent
                  text={"Updated At :"}
                ></TextWrapperComponent>
              </Grid>
              <Grid item xs={2} sx={{ marginLeft: "-70px" }}>
                <Typography
                  sx={{ fontSize: "16px", color: "#20303F", fontWeight: 400 }}
                >
                  {item?.updatedAt &&
                    moment(item.updatedAt).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  marginLeft: "-20px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", color: "#20303F", fontWeight: 200 }}
                >
                  {item?.updatedAt && moment(item.updatedAt).format("LTS")}
                </Typography>
              </Grid>
            </Grid>
          </div>

          <Grid
            container
            spacing={4}
            xs={{
              textAlgin: "center",
            }}
          >
            {summary.map((item) => {
              return (
                <Grid item xs={6} key={item.title}>
                  <TextWrapperComponent text={item.title}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#20303F",
                        fontWeight: 400,
                      }}
                    >
                      {item.count}
                    </Typography>
                  </TextWrapperComponent>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <hr />
      </>
    );
  };

  return (
    <>
      {dealState?.loading ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader title={"Loan Validation Summary"}></DocumentHeader>
          {!dealState?.filterdata?.poolUploadFinal?.cibilScoreHistory && (
            <div style={{ padding: "20px" }}>
              <Grid container spacing={4}>
                {recordStatus.map((item) => {
                  return (
                    <Grid item xs={4} key={item.title}>
                      <TextWrapperComponent text={item.title}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            color: "#20303F",
                            fontWeight: 400,
                          }}
                        >
                          {item.count}
                        </Typography>
                      </TextWrapperComponent>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          )}
          <div style={{ padding: "20px" }}>
            <Grid container spacing={4}>
              {dealState?.filterdata?.poolUploadFinal?.cibilScoreHistory &&
                dealState?.filterdata?.poolUploadFinal?.cibilScoreHistory.map(
                  (item, index) => {
                    return (
                      <>
                        <CibilScoreMaxComp
                          item={item}
                          cibilHistory={
                            dealState?.filterdata?.poolUploadFinal
                              ?.cibilScoreHistory || []
                          }
                          index={index}
                        />
                      </>
                    );
                  }
                )}
            </Grid>
          </div>
          {creditScrubState?.creditBureauScrub?.cibilScoreSuccessFile
            ?.filename && (
              <>
                <DocumentHeader
                  title={"Approved Pool"}
                  startIcon={
                    <DescriptionIcon
                      sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                    />
                  }
                ></DocumentHeader>

                <div
                  style={{
                    padding: "24px",
                  }}
                >
                  <ViewDownloadDeleteComp
                    showEye={false}
                    fileName={
                      creditScrubState?.creditBureauScrub?.cibilScoreSuccessFile
                        ?.filename || ""
                    }
                    id={
                      creditScrubState?.creditBureauScrub?.cibilScoreSuccessFile
                        ?._id || ""
                    }
                  ></ViewDownloadDeleteComp>
                </div>
              </>
            )}
        </>
      )}
      {userData?.roles.length != 1 ? (
        <></>
      ) : (

        <TabFooter
          onclickHandler={nextBtnClickHandler}
          // showSubmitButton={true}
          isBtnDisable={
            dealState?.filterdata?.poolUploadFinal?.poolStatus === "APPROVED"
              ? true
              : false
          }
        />
      )}
    </>
  );
};

export default PoolValidation;
