import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import ViewDownloadDeleteComp from "../../../components/L2/ViewDownloadDeleteComp";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paths from "../../../components/constants/Path";
import { useDispatch, useSelector } from "react-redux";
import { postQuery } from "../../../redux/Queries/queriesSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { fetchQueryList } from "./../../../redux/Queries/queriesSlice";
import DetailsData from "../../../components/DetailsData";

const CreditFilterDetails = () => {
  const dealState = useSelector((state) => state.deal);

  const [state, setState] = useState("");
  const [queryDetails, setQueryDetails] = useState({
    query: "",
    queryModule: "Deal",
    documentId: "",
  });

  const params = useParams();
  const dispatch = useDispatch();

  let history = useHistory();
  const handleUpdatePoolStatus = () => {
    history.push(Paths.L2LOANDEALIST);
  };
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState(value);
  };
  useEffect(() => {
    console.log({ state });
  }, [state]);
  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  const CreditFilters = [
    {
      title: "LTV",
      desc: `${dealState.filterdata?.loanFilterData?.ltv}%`,
    },
    {
      title: "Max. Principal Outstanding",
      desc: `₹ ${dealState.filterdata?.loanFilterData?.maxPrincipalOutstanding} Cr.`,
    },
    // {
    //     title: "Fully Disbursed Loan",
    //     desc: "Yes"
    // },
    {
      title: "Restructured",
      desc: dealState.filterdata?.loanFilterData?.isRestructured ? "Yes" : "NO",
    },
    {
      title: "PDD Completion",
      desc: dealState.filterdata?.loanFilterData?.isPddCompletion
        ? "Yes"
        : "NO",
    },
    {
      title: "DPD",
      desc: `${dealState.filterdata?.loanFilterData?.dpd} days`,
    },
    {
      title: "Seasoning/ MHP",
      desc: dealState.filterdata?.loanFilterData?.completedTenure,
    },
    {
      title: "Overdues",
      desc: dealState.filterdata?.loanFilterData?.isOverdue ? "Yes" : "NO",
    },
    {
      title: "Credit Scrub Score",
      desc: dealState.filterdata?.loanFilterData?.creditScrubScore,
    },
    {
      title: "Balance Tenure (in months)",
      desc: `${dealState.filterdata?.loanFilterData?.remainingTenure} months`,
    },
    {
      title: "Total Tenure (in months)",
      desc: `${dealState.filterdata?.loanFilterData?.totalTenure} months`,
    },
    {
      title: "Max Lifetime DPD (in months",
      desc: `${dealState.filterdata?.loanFilterData?.maxTotalDpd} days`,
    },
    {
      title: "Max lifetime dpd -12 months",
      desc: `${dealState.filterdata?.loanFilterData?.max12MonthDpd} days`,
    },
    {
      title: "Max lifetime dpd -24 months",
      desc: `${dealState.filterdata?.loanFilterData?.max24MonthDpd} days`,
    },
    {
      title: "Branch Level PAR90",
      desc: `${dealState?.filterdata?.loanFilterData?.branchLevelPercentage}%`,
    },
    {
      title: "FOIR",
      desc: `${dealState.filterdata?.loanFilterData?.foir}%`,
    },
    {
      title: "Payment Mode",
      desc:
        dealState.filterdata?.loanFilterData?.paymentModes &&
        dealState.filterdata?.loanFilterData?.paymentModes
          .map((list) => list)
          .join(", "),
    },
    {
      title: "City Exclusions",
      desc:
        dealState.filterdata?.excludedCitiesData &&
        dealState.filterdata?.excludedCitiesData
          .map((list) => list.name)
          .join(", "),
    },
    {
      title: "State Exclusions",
      desc:
        dealState.filterdata?.excludedStatesData &&
        dealState.filterdata?.excludedStatesData
          .map((list) => list.name)
          .join(", "),
    },
    {
      title: "Type of Property",
      desc:
        dealState.filterdata?.loanFilterData?.propertyType &&
        dealState.filterdata?.loanFilterData?.propertyType
          .map((list) => list)
          .join(", "),
    },
    {
      title: "Type of Vehicle",
      desc:
        dealState.filterdata?.loanFilterData?.vehicleTypes &&
        dealState.filterdata?.loanFilterData?.vehicleTypes
          .map((list) => list)
          .join(", "),
    },
    {
      title: "Customer Segment",
      desc:
        dealState.filterdata?.loanFilterData?.customerSegment &&
        dealState.filterdata?.loanFilterData?.customerSegment
          .map((list) => list)
          .join(", "),
    },
    {
      title: "Customer Constitution",
      desc:
        dealState.filterdata?.loanFilterData?.customerConstitution &&
        dealState.filterdata?.loanFilterData?.customerConstitution
          .map((list) => list)
          .join(", "),
    },
  ];

  const secondaryAutoCheckSummary = [
    {
      title: "No. of Pools Uploaded (Primary Check)",
      desc: "10000",
    },
    {
      title: "No. of Pools Approved (Primary Check)",
      desc: "8000",
    },
    {
      title: "No. of Pools Approved (Secondary Check)",
      desc: "2000",
    },
    {
      title: "No. of Pools Rejected (Secondary Check)",
      desc: "2000",
    },
  ];

  const onPostClickHandler = (queryDetails) => {
    console.log(queryDetails);
    if (queryDetails.query == "") {
      dispatch(showErrorAlertAction("Please Enter Query"));
      return;
    }

    dispatch(postQuery(queryDetails)).then((data) => {
      let id = params.dealId;
      dispatch(fetchQueryList({ id }));
      dispatch(showAlertAction(data.payload.data.message));
      history.push("/l2creditfiltersdealist");
    });
  };
  let userData = JSON.parse(localStorage.getItem("UserData"));
  return (
    <>
      <DetailsData label="Credit Filters" />
      {userData?.roles.length == 1 ? (
        <WrapperComponent title="Queries">
          <>
            <div
              style={{
                padding: "14px",
              }}
            >
              <Typography
                fontSize="14px"
                color="#365069"
                fontWeight="700"
                paddingBottom="10px"
              >
                Post a Query
              </Typography>

              <Typography
                fontSize="14px"
                color="#AFB9C3"
                fontWeight="400"
                paddingBottom="10px"
              >
                Add your query
              </Typography>
              <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "space-between",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "368px",
                  }}
                >
                  <TextField
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    onChange={(e) =>
                      setQueryDetails({
                        ...queryDetails,
                        query: e.target.value,
                        documentId: params.dealId,
                      })
                    }
                  ></TextField>
                </div>
                <OutlineButton
                  text="Post"
                  showIcon={false}
                  onClickHandler={() => onPostClickHandler(queryDetails)}
                ></OutlineButton>
              </div>
            </div>
          </>
        </WrapperComponent>
      ) : (<></>)}


      <div style={{ padding: "10px" }}></div>
    </>
  );
};

export default CreditFilterDetails;
