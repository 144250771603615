import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Delete from "../../assets/icons/blueDelete.svg";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Box, Button, Stack } from "@mui/material";
import { Colors } from "../../theme/theme";
import UploadIcon from "../../assets/icons/UploadIcon.svg";

import styles from "./Wrapper.module.css";
import { addMoreMidBox, addMoreTopBox, btnStyle, checkCircleIcon, supportFileTypoGraphy, uploadBoxStyle, uploadStackStyle, uploadTypoGraphy } from "./WrapperCSS";

export const ChooseFileComponent = ({ compnames }) => {
  return (
    <Box sx={uploadBoxStyle}>
      <Stack sx={uploadStackStyle} >
        <Stack direction="row" spacing={1}>
          <img src={UploadIcon}></img>
          <Typography sx={uploadTypoGraphy} >
            Upload File
          </Typography>
        </Stack>

        <Typography sx={supportFileTypoGraphy}>
          (Supported file formats : .pdf, .png, .jpg, .jpeg)
        </Typography>
      </Stack>
    </Box>
  );
};

export const SelectedFileComponent = ({
  fileName,
  fileSize,
  onClickHandler,
}) => {
  let fileData = fileName.split(".");
  console.log(fileData[1]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.DetailsWrapper}>
          <div className={styles.together}>
            <CheckCircleIcon sx={checkCircleIcon} ></CheckCircleIcon>
            <p>{`${fileData[0].slice(0, 24)}.${fileData[1]}`}</p>
          </div>
          <p> {fileSize} KB</p>
        </div>
        <div
          className={styles.iconWrapper}
          
          onClick={onClickHandler}
        >
          <img src={Delete}></img>
        </div>
      </div>
    </>
  );
};
export const AddMoreFileComponent = ({ clickhandler }) => {
  return (
    <div onClick={clickhandler}>
      <Box sx={addMoreTopBox}>
        <Box sx={addMoreMidBox}>
          <Button
            variant="none"
            startIcon={<AddIcon />}
            sx={btnStyle}
          >
            Add More Files
          </Button>
        </Box>
      </Box>
    </div>
  );
};
