import { useState, useEffect } from "react";
//? css
import styles from "./Queries.module.css";

//? components
import QueryComp from "./QueryComp/QueryComp";
import Filters from "../Filters/FiltersComp";
import { useSelector, useDispatch } from "react-redux";
import { replyQuery, fetchQueryList } from "../../redux/Queries/queriesSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../redux/app/appSlice";
import { useParams } from "react-router-dom";
import QueriesFilters from "../Filters/QueriesFilter";
import { Typography } from "@mui/material";
import { setStatus } from "../../redux/Loans/loanSlice";

const QueriesPage = ({ dealIdorOriginatorId, currentStage = "" }) => {
  const { queryList } = useSelector((state) => state.queries);
  const dispatch = useDispatch();
  const params = useParams();
  let id = params.id || dealIdorOriginatorId;
  let userData = JSON.parse(localStorage.getItem("UserData"));

  //? useState
  const [activeQuery, setActiveQuery] = useState(null); //? for storing the id of the query and open input
  const [input, setInput] = useState("");

  useEffect(() => {
    dispatch(fetchQueryList({ id }));
  }, [id]);

  useEffect(() => {
    dispatch(setStatus(1));
  }, []);

  //? if will set the  query id in the state
  const replyHandler = (queryId) => {
    console.log({ queryId });
    setActiveQuery(queryId);
  };

  //? for getting input from user
  const inputHandler = (e) => {
    let value = e.target.value;
    setInput(value);
  };

  console.log({ id });

  const querySubmitHandler = () => {
    console.log({ input, activeQuery });

    //dispatching the alert for query creation
    if (input == "") {
      dispatch(showErrorAlertAction("Please Enter reply"));
      return;
    }
    dispatch(
      replyQuery({
        queryModule: "DEAL",
        query: input,
        queryId: activeQuery,
      })
    ).then((data) => {
      dispatch(fetchQueryList({ id }));
      setInput("");
      setActiveQuery("");
      dispatch(showAlertAction(data.payload.data.message));
    });
  };
  console.log("kdkd", queryList);

  useEffect(() => {
    if(userData?.roles[0] === "L1"){
      dispatch(setStatus(1));
    }
    return () =>{
      dispatch(setStatus(0));
    }
  }, []);

  let allQueries = queryList.map((val, index) => {
    console.log(val);
    return (
      <>
        {/* //? there is an alternate way to pass all query props as data */}
        {!val.isParent && val?.isParent == false && (
          <QueryComp
            key={index}
            id={val?._id}
            name={val?.raisedbyIdRefData?.name}
            query={val?.query}
            replies={val?.repliesData}
            showInput={val._id == activeQuery}
            querySubmitHandler={querySubmitHandler}
            //? METHODS
            replyHandler={replyHandler}
            input={input}
            setInput={setInput}
            inputHandler={inputHandler}
            createdAt={val?.createdAt}
            currentStage={val?.queryStage || ""}
          />
        )}
      </>
    );
  });

  return (
    <div className={styles.wrapper}>
      <QueriesFilters id={id || params.dealId} />

      {queryList.length == 0 ? (
        <div>
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            No Records Found!!
          </Typography>
        </div>
      ) : (
        <div className={styles.queriesWrapper}>{allQueries}</div>
      )}
    </div>
  );
};

export default QueriesPage;
