import * as React from "react";
import Box from "@mui/material/Box";
import { Colors } from "../../../theme/theme";

import { Stack, Typography, Grid } from "@mui/material";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { Link, useHistory } from "react-router-dom";
import SecoundaryButton from "../../../components/Buttons/SecoundaryButton";
import Paths from "../../../components/constants/Path";
import originatorMan from "../../../assets/images/originatorMan.svg";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout/Layout";
import { useState, useEffect } from "react";
import { fetchPhase1DashboardData } from "../../../redux/Phase2/DashBoardSlice";
import { getUserProfileByUserId } from "../../../redux/originator/originatorSlice";
import Home from "../../Home";

const L2Home = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const originatorState = useSelector((state) => state.originator);
  const dealState = useSelector((state) => state.deal);
  let dashBoardState = useSelector((state) => state.dashboard);
  let loading = useSelector((state) => state.dashboard.loading);
  const [Data, setData] = useState(null);

  useEffect(() => {
    dispatch(fetchPhase1DashboardData());
  }, []);

  const data = [
    {
      loan_number: "1",
      description: "Pool Upload Pending",
    },
    {
      loan_number: "2",
      description: "Add link of KYC for loans",
    },
    {
      loan_number: "30",
      description: "Re-upload Pool File",
    },
    {
      loan_number: "1",
      description: "Upload Credit Report Scrub",
    },
  ];

  useEffect(() => {
    if (dashBoardState["phase1DashboardData"] != null) {
      if (userData.roles[0] === "Originator") {
        setData([
          {
            loan_number:
              dashBoardState["phase1DashboardData"]["poolUploadPending"],
            description: "Pool Upload Pending",
          },
          {
            loan_number: dashBoardState["phase1DashboardData"]["linkOfKYC"],
            description: "Add link of KYC for loans",
          },
          {
            loan_number:
              dashBoardState["phase1DashboardData"]["reUploadPoolFile"],
            description: "Re-upload Pool File",
          },
          {
            loan_number:
              dashBoardState["phase1DashboardData"]["uploadCreditReport"],
            description: "Upload Credit Report Scrub",
          },
        ]);
      }
    }
  }, [dashBoardState["loading"]]);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  useEffect(() => {
    if (userData.roles[0] === "Originator") {
      dispatch(getUserProfileByUserId({ userId: userData?.id }));
    }
  }, []);

  console.log({ userData });

  const onClickHandler = () => {
    return history.push(Paths.ORIGINATORCOMPLETEKYC);
  };

  const onClickHandlerToUploadPool = () => {
    return history.push(Paths.ORIGINATORDEALLIST);
  };

  const onClickHandlerToUploadKYCLink = () => {
    return history.push(Paths.ORIGINATORKYCLINKDEALLIST);
  };

  const onClickHandlerToUploadCreditReport = () => {
    return history.push(Paths.ORIGINATORCREDITDEALSLIST);
  };

  let completeKYCState = useSelector((state) => state.originator);
  console.log({ completeKYCState });
  console.log(completeKYCState["isKYCUploaded"]);

  const UserDetails = () => {
    return (
      <>
        <Typography fontSize="14px" fontWeight="400" color={Colors.light_grey}>
          Hello
        </Typography>
        <Stack direction="row" justifyContent="space-between" paddingRight={4}>
          <Typography fontSize="20px" fontWeight="800" color={Colors.black}>
            {userData.name} 👋
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={3}>
          <SecoundaryButton
            onClickHandler={onClickHandlerToUploadPool}
            text="Upload Pool"
          />
          <SecoundaryButton
            onClickHandler={onClickHandlerToUploadKYCLink}
            // isDisabled={true}
            text="Upload KYC Drive Link"
          ></SecoundaryButton>
          <SecoundaryButton
            onClickHandler={onClickHandlerToUploadCreditReport}
            // isDisabled={}
            text="Upload Credit Bureau Scrub Report"
          ></SecoundaryButton>
        </Stack>
        {/* {completeKYCState.isKYCUploaded ? (
          <Stack direction="row" justifyContent="end" spacing={3}>
            <SecoundaryButton
              onClickHandler={onClickHandlerToUploadPool}
              text="Upload Pool"
            />
            <SecoundaryButton
              onClickHandler={onClickHandlerToUploadKYCLink}
              text="Upload KYC Drive Link"
            ></SecoundaryButton>
            <SecoundaryButton
              onClickHandler={onClickHandlerToUploadCreditReport}
              text="Upload Credit Bureau Scrub Report"
            ></SecoundaryButton>
          </Stack>
        ) : null} */}
      </>
    );
  };

  const LoanStatusCard =
    Data &&
    Data.map((data) => (
      <>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <>
              <Box
                backgroundColor={Colors.white}
                minWidth="240px"
                maxWidth="auto"
                boxShadow={2}
                borderRadius={2}
                paddingTop="14px"
                paddingLeft={2}
                paddingBottom="14px"
              >
                <Typography
                  fontSize="20px"
                  fontWeight="800"
                  color={Colors.black}
                >
                  {data.loan_number}
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight="400"
                  color={Colors.light_grey}
                >
                  {data.description}
                </Typography>
              </Box>
            </>
          </Grid>
        </Grid>
      </>
    ));

  return (
    <Layout componentName="Home">
      <>
        {originatorState && originatorState.loading ? (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
          </div>
        ) : (
          <div>
           
              <Stack direction="row" paddingTop={3}>
                {<Home />}
              </Stack>
        
           
            
          </div>
        )}
      </>
    </Layout>
  );
};

export default L2Home;
