import { Box, Button, Grid } from '@mui/material';
import React from 'react'
import Layout from '../../../components/Layout/Layout';
import { Colors } from "../../../theme/theme";
import Image from "../../../assets/images/Group 42722.svg";
import { FormDetails } from '../../../components/Layout/TabsLayout/TabsComponents';
import DescriptionIcon from "@mui/icons-material/Description";
import { DocumentHeader } from '../../../components/DocumentHeaderWrapper/DocumentHeader';
import DoneIcon from "@mui/icons-material/Done";
import UploadTemplate from '../../../components/Wrapper/UploadTemplate';
import Paths from '../../../components/constants/Path';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import styles from "./UploadDocuments.module.css"
import { boxSizing, btnStyle, documentHeaderStyle, paddingTopStyle, topBoxStyle } from './UploadDocumentsCSS';



const ApproverUpdDocs = () => {

    let history = useHistory();
    const [state, setState] = useState(true);

    const clickHandler = () => {
        return history.push(Paths.APPROVERDOCUMENTUPDDEALIST);
    };

    let icon = (
        <DescriptionIcon
            sx={{
                color: "#2C4584",
            }}
        />
    );

    const breadCrumbs = [
        {
            path: "/dashboard",
            pathName: "Home",
        },
        {
            path: "/approverdocumentupddealist",
            pathName: "Deals List",
        },
        {
            pathName: "P1 Home Loan",
        },
    ];

    return (
        <Layout breadCrumbs={breadCrumbs}>
            <Box sx={topBoxStyle}>
                <div>
                    <FormDetails icon={icon} iconColor={"#DBE3F8"} formName={"P1 Home Loan"} />
                    <Box sx={paddingTopStyle} ></Box>
                </div>
                <div className={styles.imgWrapper}>
                    <img
                        className={styles.imgStyle}
                        src={Image}
                        alt="image"
                    />
                </div>
            </Box>
            <div className={styles.mainHeading}>
                <div className={styles.headingWrapper}>
                    <h3>Upload Documents for - P1 Home Loan</h3>
                </div>
                <Box sx={boxSizing}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={6}>
                            <DocumentHeader title={"Information Memorandum"} startIcon={<DescriptionIcon
                                sx={documentHeaderStyle} />}></DocumentHeader>

                            <div className={styles.paddingStyle} >
                                <UploadTemplate docName="panCard" setdata={setState} />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <DocumentHeader title={"Loss Estimation Report"} startIcon={<DescriptionIcon
                                sx={documentHeaderStyle} />}></DocumentHeader>
                            <div className={styles.paddingStyle}>
                                <UploadTemplate setdata={setState} docName="gstCertificate" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <DocumentHeader title={"Credit Due Diligence"} startIcon={<DescriptionIcon
                                sx={documentHeaderStyle} />}></DocumentHeader>
                            <div className={styles.paddingStyle}>
                                <UploadTemplate
                                    setdata={setState}
                                    docName="incorporationCertificate"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <DocumentHeader title={"Legal Due Diligence"} startIcon={<DescriptionIcon
                                sx={documentHeaderStyle} />}></DocumentHeader>
                            <div className={styles.paddingStyle}>
                                <UploadTemplate setdata={setState} docName="cancelledCheque" />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <DocumentHeader title={"Others"} startIcon={<DescriptionIcon
                                sx={documentHeaderStyle} />}></DocumentHeader>
                            <div className={styles.paddingStyle}>
                                <UploadTemplate
                                    setdata={setState}
                                    docName="rbiRegisterationCertificate"
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <div className={styles.btnWrapper}>
                        <Button
                            endIcon={<DoneIcon />}
                            onClick={clickHandler}
                            variant="contained"
                            sx={btnStyle}
                            type="submit"
                        >
                            Upload Documents
                        </Button>
                    </div>
                </Box>
            </div>
        </Layout>
    )

}

export default ApproverUpdDocs;