import React from "react";
import TextWrapperComponent from "../../../../FormComponents/TextWrapperComponent";
import BasicSelect from "../../../TabSelectOptions/SelectTabOptions";
import { FormControl, TextField, Grid } from "@mui/material";
import TermSheetSelectTaboptions from "../../../TabSelectOptions/TermSheetSelectTaboptions";

const TermSheetDealUpload = ({
  state,
  name,
  dealState,
  handleInputChange,
  value,
  setValue,
  originator,
  searchFilter,
  setSearchFilter
}) => {
  
  return (
    <div>
      <Grid container direction="row" sx={{ rowGap: "10px" }}>
        <Grid item xs={6} padding="5px">
          <TextWrapperComponent text="Deal Name">
            <FormControl variant="standard" sx={{ width: 300 }}>
              <TermSheetSelectTaboptions
                data={dealState}
                value={value}
                setValue={setValue}
                defaultValue={originator}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                placeholder="Select Deal"
              />
            </FormControl>
          </TextWrapperComponent>
        </Grid>
      </Grid>
    </div>
  );
};

export default TermSheetDealUpload;
