import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { postRequest } from "../../../API/apiHandler";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import BasicModal from "../../../components/Modal/ModalV2";
import UploadTemplateOriginator from "../../../components/Wrapper/UploadLoanTemplate_Originator";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import { goForSecondaryAutoCheck } from "../../../redux/L1/secondaryCheckSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import {
  deleteDoc,
  getSingleUploadPoolLoanDetails,
  uploadLoanPool,
} from "../../../redux/originator/poolUploadSlice";
import { Colors } from "../../../theme/theme";
import Loader from "../../../utils/Loader";
import Paths from "../../../components/constants/Path";

const OriginatorLoanUploadSummaryDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const poolState = useSelector((state) => state.poolUpload);
  const [open, setOpen] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [data, setData] = useState(null);
  const [state, setState] = useState(null);
  const [msgLoading, setMsgLoading] = useState(false);
  const [loanFile, setLoanFile] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const onClickHandler = () => {
    if (showFileInput) {
      if (loanFile == null) {
        dispatch(showErrorAlertAction("Please Upload a File"));
        return;
      }
    }

    setOpen(true);
  };

  const showFileInputHandler = () => {
    setShowFileInput(true);
  };

  const handleClose = () => {
    setOpen(false);
    showFileInputHandler();
  };

  useEffect(() => {
    if (poolState?.uniqueCode !== undefined && poolState?.uniqueCode !== null) {
      dispatch(
        getSingleUploadPoolLoanDetails({ uniqueCode: poolState?.uniqueCode })
      );
    }
  }, [poolState?.uniqueCode]);

  const history = useHistory();

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  const DealData = [
    {
      title: "Type of Transaction",
      desc: dealState?.transactionType,
    },
    {
      title: "Product / Asset Class",
      desc: dealState?.loanTypeData?.name,
    },
    {
      title: "Vintage in Operations",
      desc: `${dealState?.vintageOperation} years`,
    },
    {
      title: "Rating",
      desc: dealState?.rating,
    },
    {
      title: "AUM",
      desc: `${dealState?.aum} (INR Crs)`,
    },
    {
      title: "Net Worth",
      desc: `${dealState?.netWorth} (INR Crs)`,
    },
    {
      title: "PAT",
      desc: `${dealState?.pat} (INR Crs)`,
    },
    {
      title: "CRAR%",
      desc: `${dealState?.crarPercent}%`,
    },
    {
      title: "GNPA%",
      desc: `${dealState?.gnpaPercent}%`,
    },
    {
      title: "NNPA %",
      desc: `${dealState?.nnpaPercent}%`,
    },

    {
      title: "Requested Deal Size",
      desc: `₹ ${dealState?.requestedDealAmount} Cr.`,
    },
  ];

  const handleContinueWithApprovedPools = async () => {
    if (poolState?.primarySuccessRecords > 0) {
      dispatch(
        goForSecondaryAutoCheck({
          dealId: dealState?.filterdata?._id,
          uniqueCode: dealState?.filterdata?.uniqueCode,
        })
      );
      await postRequest("/utility-service/notification", {
        type: "FRONT-END NOTIFICATION",

        dealId: dealState?.filterdata?._id,
        event: "PRIMARY_CHECK_SUBMIT",
      });
      dispatch(showAlertAction("Pool Submitted Successfully"));
      history.push(Paths.ORIGINATORDEALLIST);
    } else {
      dispatch(showErrorAlertAction("No Approved Loans Found"));
    }
  };


  const handlePositiveBtn = () => {
    dispatch(
      uploadLoanPool({
        formData: loanFile,
        // reUpload: true,
        reUpload: false,
        dealId: params.dealId,
        // reUploadId: poolState?.uniqueCode,
        reUploadId: "",
        loanId: poolState?.loanTypeId,
      })
    ).then((res) => {
      if (res.payload.status === 200) {
        setOpen(false);
        setShowFileInput(false);
        dispatch(showAlertAction("File Uploaded Successfully"));
        history.push(Paths.ORIGINATORDEALLIST);
      }
    });
    dispatch(deleteDoc("correctedloanUpload"));
  };

  return (
    <>
      {poolState && poolState.loading ? (
        <Loader />
      ) : (
        <>
          <WrapperComponent title="Deal Details">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Grid container spacing={3}>
                  {DealData.map((data) => {
                    return (
                      <>
                        {data?.desc != "" &&
                          data?.desc != "undefined%" &&
                          data?.desc != "null%" &&
                          data?.desc != "undefined years" &&
                          data?.desc != "null years" &&
                          data?.desc != "₹  undefined  Cr" &&
                          data?.desc != "₹  null  Cr" &&
                          data?.desc != "undefined  (INR Crs)" &&
                          data?.desc != "null  (INR Crs)" &&
                          data?.desc != undefined && (
                            <Grid item xs={3}>
                              <DataComp title={data.title} desc={data.desc} />
                            </Grid>
                          )}
                      </>
                    );
                  })}
                </Grid>
              </div>
            </>
          </WrapperComponent>

          <WrapperComponent title="Loan Upload Summary">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Typography
                      fontSize="14px"
                      fontWeight="700"
                      color="#365069"
                    >
                      Number of Loans Uploaded
                    </Typography>
                    <Typography fontSize="14px" fontWeight="400">
                      {poolState?.primaryTotalRecords}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      fontSize="14px"
                      fontWeight="700"
                      color="#365069"
                    >
                      Number of Loans Approved
                    </Typography>
                    <Typography fontSize="14px" fontWeight="400">
                      {poolState?.primarySuccessRecords}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography
                      fontSize="14px"
                      fontWeight="700"
                      color="#365069"
                    >
                      Number of Loans Rejected
                    </Typography>
                    <Typography fontSize="14px" fontWeight="400">
                      {poolState?.primaryRejectedRecords}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </>
          </WrapperComponent>
          {poolState?.primaryRejectedRecords > 0 && (
            <WrapperComponent title="Rejected Loans">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        padding: "14px",
                      }}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        sx={{ color: "#5E7387" }}
                      >
                        Rejected Loans
                      </Typography>
                    </div>
                    <div style={{ padding: "5px", width: "auto" }}>
                      <ViewDownloadDeleteComp
                        fileName={
                          poolState?.primaryRejectedFileData?.filename ||
                          poolState?.primaryRejectedFileData?.fileName
                        }
                        id={poolState?.primaryRejectedFileData?._id}
                      // reportData={poolState?.primaryRejectedFileData?.fileUrl}
                      ></ViewDownloadDeleteComp>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </WrapperComponent>
          )}
          {poolState?.primarySuccessRecords > 0 && (
            <WrapperComponent title="Approved Loans">
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        padding: "14px",
                      }}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        sx={{ color: "#5E7387" }}
                      >
                        Approved Loans
                      </Typography>
                    </div>
                    <div style={{ padding: "5px", width: "auto" }}>
                      <ViewDownloadDeleteComp
                        fileName={poolState?.primarySuccessFileData?.filename}
                        id={poolState?.primarySuccessFileData?._id}
                      ></ViewDownloadDeleteComp>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </WrapperComponent>
          )}

          {showFileInput && (
            <WrapperComponent title="Upload Corrected Loans">
              <div
                style={{
                  padding: "14px",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <UploadTemplateOriginator
                    docName="correctedloanUpload"
                    setdata={setState}
                    setLoanFile={setLoanFile}
                  />
                </div>
              </div>
            </WrapperComponent>
          )}

          <div style={{ padding: "10px" }}></div>
          {userData?.roles.length == 1 ? (
            <Box
              sx={{
                background: "white",
                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Stack
                padding={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
              >
                <Button
                  endIcon={<ArrowForwardIosIcon />}
                  variant="contained"
                  disabled={
                    dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                      "APPROVED"
                      ? true
                      : false
                  }
                  onClick={onClickHandler}
                  sx={{
                    backgroundColor: Colors.piramal_orange,
                    color: Colors.white,

                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: Colors.piramal_orange,
                    },
                    "&:disabled": {
                      backgroundColor: "#FAC3B3",
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Submit Pool
                </Button>
              </Stack>
            </Box>
          ) : (<></>)}

          {showFileInput ? (
            <BasicModal
              open={open}
              setOpen={setOpen}
              title="Do you want to Upload this File?"
              positiveText="Yes"
              negativeText="No"
              positiveOnClickHandler={handlePositiveBtn}
              negativeOnClickHandler={handleClose}
              setloading={setMsgLoading}
            ></BasicModal>
          ) : (
            <BasicModal
              open={open}
              setOpen={setOpen}
              title="What would you like to do?"
              positiveText="Re-upload Entire Pool"
              negativeText="Continue with Approved Pools"
              negativeOnClickHandler={handleContinueWithApprovedPools}
              positiveOnClickHandler={handleClose}
              setloading={setMsgLoading}
            ></BasicModal>
          )}
        </>
      )}
    </>
  );
};

export default OriginatorLoanUploadSummaryDetails;
