import { React, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Badge,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import EmployeeIcon from "../assets/images/EmployeeIcon.png";
import { Colors } from "../theme/theme";
import SuccessAlert from "../components/Alert/SuccessAlert";
import {
  logout,
  showAlertAction,
  showErrorAlertAction,
} from "../redux/app/appSlice";
import {
  ImageContainer,
  InformationComponent,
  InformationContainer,
  LoginContainer,
} from "../components/Login/LoginComponents";
import { useHistory } from "react-router-dom";
import instance, { API, API_KEY, ENV } from "../API/BaseUrl";
import { Authenticate } from "../helper/AuthHelper";
import EnableDisableButton from "../components/Buttons/EnableDisableButton";
import Paths from "../components/constants/Path";
import axios from "axios";
import ErrorAlert from "../components/Alert/ErrorAlert";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  let [status, setstatus] = useState(true);
  const [userCredentials, setuserCredentials] = useState({
    email: "",
    password: "",
  });

  //? useDispatch
  let dispatch = useDispatch();

  //? useHistory
  let history = useHistory();

  // localStorage.clear();

  //? handleLogin Function
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${API}/authentication-service/auth/login`,
        {
          email: userCredentials.email,
          password: userCredentials.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // "Access-Control-Allow-Origin": "*",
            "apikey": API_KEY,
            environment: ENV,
          },
        }
      )
      .then((result) => {
        localStorage.clear()
        setLoading(false);
        Authenticate(result.data.data.userData?.token, "Token");
        // Authenticate(result.data.data.userData, "UserData");
        // localStorage.setItem("Token", result.data.data.userData?.token);
        localStorage.setItem("UserData", JSON.stringify(result?.data?.data?.userData));
        //test
        let userData = JSON.parse(localStorage.getItem("UserData"));
        
        if (result.data.userData === null) {
          dispatch(showErrorAlertAction(result.message));
        }

        if (userData.roles[0] == "F1" || userData.roles[0] == "F2") {
          //  Will Redirect to Phase 2
          window.open(Paths.PHASE1HOME, "_self");
        } else {
          if (userData.roles[0] === "Originator") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.ORIGINATORHOME);
          }
          if (userData.roles[0] === "Approver 1") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] === "Approver 2") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] === "Approver 3") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] === "Approver 4") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] === "Approver 5") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] === "Approver 6") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] === "Approver 7") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] == "L1") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] == "L2") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] == "Auditor") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
          if (userData.roles[0] == "C1" || 
              userData.roles[0] == "R1" || 
              userData.roles[0] == "CP1" ||
              userData.roles[0] == "IA1" ||
              userData.roles[0] == "CO1") {
            dispatch(showAlertAction("Logged in Successfully"));
            history.push(Paths.DASHBOARD);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(showErrorAlertAction(error?.response?.data?.message));
      });
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        console.log("name", userCredentials.email);
        console.log("password", userCredentials.password);
        setLoading(true);
        handleLogin(event);
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [userCredentials.email, userCredentials.password]);

  //? Check Login Button Status Function
  const checkStatus = () => {
    for (const key in userCredentials) {
      if (userCredentials[key] == 0) {
        setstatus(true);
        break;
      }
      setstatus(false);
    }
  };

  //? Textfield input handler Function
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    let dataUpdated = {
      ...userCredentials,
      [name]: value,
    };

    setuserCredentials(dataUpdated);
  };

  //? this useEffect will check the status of the login button
  useEffect(() => {
    checkStatus();
  }, [userCredentials]);

  return (
    <>
      {/* ErrorAlert component will active if any error comes with login */}
      <ErrorAlert />
      {/* SuccessAlert component will active if login is successfull */}
      <SuccessAlert />

      <Stack direction="row" sx={{ height: "100vh" }}>
        <InformationContainer>
          <InformationComponent />
        </InformationContainer>

        <LoginContainer>
          <div
            style={{
              display: "flex",
              paddingLeft: "40px",
              paddingTop: "40px",
            }}
          >
            <ImageContainer src={EmployeeIcon}></ImageContainer>
          </div>
          <div
            style={{
              paddingTop: "30px",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <Typography
              lineHeight={"28px"}
              fontSize="20px"
              fontWeight="800"
              color={Colors.black}
            >
              Employee Login
            </Typography>

            <div
              style={{
                paddingTop: "8px",
              }}
            >
              <Typography
                fontSize="14px"
                fontWeight="400"
                color={Colors.light_grey}
              >
                Enter your email id and password to
                <br />
                access your account
              </Typography>
            </div>

            <div style={{ paddingTop: "24px" }}>
              <form>
                <Typography
                  fontSize="14px"
                  fontWeight="700"
                  color={Colors.piraml_light_blue}
                >
                  Email ID
                </Typography>

                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  placeholder="Piramal email Id"
                  value={userCredentials.email}
                  onChange={handleInputChange("email")}
                />

                <Typography
                  fontSize="14px"
                  fontWeight="700"
                  paddingTop="10px"
                  color={Colors.piraml_light_blue}
                >
                  Password
                </Typography>

                <TextField
                  fullWidth
                  sx={{ color: Colors.piramal_blue }}
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  placeholder="Piramal email password"
                  autoComplete="off"
                  value={userCredentials.password}
                  onChange={handleInputChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VisibilityIcon fontSize="small" />
                          ) : (
                            <VisibilityOffIcon fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <div
                  style={{
                    paddingTop: "30%",
                  }}
                >
                  <EnableDisableButton
                    text="Login"
                    onClickHandler={handleLogin}
                    isLoading={loading}
                    status={status}
                  ></EnableDisableButton>
                </div>
              </form>
            </div>
          </div>
        </LoginContainer>
      </Stack>
    </>
  );
};
export default LoginPage;
