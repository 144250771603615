import DescriptionIcon from "@mui/icons-material/Description";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../assets/images/Group 42722.svg";
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import { breadCrums } from "../../components/constants/BreadCrumbs";
import Paths from "../../components/constants/Path";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../redux/Deals/dealSlice";
import { getSingleAmlUpload } from "../../redux/Phase3/KYCAML/KYCAMLSlice";
import LoanForm from "../Loan/LoanMultiForm";
import AMLForm from "../Phase3/AML/AMLForm";
import DealDetails from "./DealDetails";
import DocumentForm from "./DocumentForm";
import { setStatus } from "../../redux/Loans/loanSlice";

const ViewDealDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const [uploadData, setUploadData] = useState("");
  console.log({ dealState });
  const params = useParams();
  let { dealId } = params;
  const dispatch = useDispatch();
  let userData = JSON.parse(localStorage.getItem("UserData"));

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: params.dealId })).then((res) => {
      setUploadData(res?.payload?.data);
    });
  }, []);

  const breadCrumbs = ["C1", "R1", "CP1", "IA1", "CO1"].includes(
    userData?.roles[0]
  )
    ? [
        {
          path: Paths.DASHBOARD,
          pathName: "Home",
        },

        {
          path: Paths.SWAYAMUSERDEALLIST,
          pathName: "Deals",
        },
        {
          pathName: dealState?.name,
        },
      ]
    : [
        {
          path: Paths.DASHBOARD,
          pathName: "Home",
        },

        {
          path: Paths.DEALS_LIST,
          pathName: "Deals",
        },
        {
          pathName: dealState?.name,
        },
      ];

  useEffect(() => {
    return () => {
      dispatch(setStatus(0));
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setStatus(0));
    };
  }, []);

  let Tabs = [
    {
      name: "Deal",
      index: 0,
      component: <DealDetails />,
    },
    
  ];

  if(userData?.roles.length == 1){
    Tabs.push(
      {
        name: "Queries",
        index: 1,
        component: (
          <QueriesPage
          dealIdorOriginatorId={params?.dealId}
          currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
      { name: "History", index: 2, component: <Histories id={dealId} /> },
    )
  }

  if (userData?.roles.length == 1 && !["C1", "R1", "CP1", "IA1", "CO1"].includes(userData?.roles[0])) {
    Tabs.push({
      name: "Loan",
      isDisabled: dealState?.status !== "APPROVED" ? true : false,
      index: 3,
      component: <LoanForm></LoanForm>,
    });
  }

  if (userData?.roles[0] === "L1") {
    if (dealState?.filterdata?.dealType !== "SELL") {
      Tabs.push({
        name: "Compliance",
        index: 4,
        component: <AMLForm></AMLForm>,
      });
    }
  } else {
    if (dealState?.filterdata?.dealType !== "SELL" && uploadData != undefined) {
      Tabs.push({
        name: "Compliance",
        index: 4,
        component: <AMLForm></AMLForm>,
      });
    }
  }

  if (
    dealState?.filterdata?.status === "CLOSED" && userData?.roles.length == 1 &&
    !["C1", "R1", "CP1", "IA1", "CO1"].includes(userData?.roles[0])
  ) {
    Tabs.push({
      name: "Documents",
      index: 5,
      component: <DocumentForm />,
    });
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDealState());
    };
  }, []);
  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={Tabs}
        isDealTab={true}
      ></TabsLayout>
    </Layout>
  );
};

export default ViewDealDetails;
