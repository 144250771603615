import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { BoxStyle } from "./ModalCSS";
import Styles from "./Modal.module.css";
import OutlineButton from "../Buttons/OutlineButton";
import { Typography } from "@mui/material";
import ViewDownloadDeleteComp from "../L2/Phase2DownloadComp";
import SecoundaryButton from "../Buttons/SecoundaryButton";

export default function ModalV3({
  popUpOpen = false,
  setPopUpOpen,
  title = "No Title",
  negativeText,
  btnText,
  positiveOnClickHandler,
  negativeOnClickHandler,
  setloading,
  children,
  isDuplicate = false,
  setOpeMsg,
  isNegativeHandler = false,
  isDisable,
  midHead1,
  midHead2,
  midHead3,
  midHead4,
  midHead1Text,
  midHead2Text,
  midHead3Text,
  isFile,
  fileName,
  fileId,
  isMiddleBtn = false,
  middleBtnText,
  middleBtnOnClickHandler,
  dedupeProceedWithApprovedCases = "",
  dedupeProceedWithApprovedCasesOnClickHandler = () => { },
}) {
  const handleOpen = () => setPopUpOpen(true);
  console.log("isDuplicate",isDuplicate);
  
  const handleClose = () => {
    console.log({ isNegativeHandler });
    if (isNegativeHandler) {
      console.log("step 1");
      setPopUpOpen(false);
      // setloading(false);

      negativeOnClickHandler();
    }

    setPopUpOpen(false);
    setloading(false);
  };

  let duplicateRecords = Number(midHead3) - Number(midHead4)
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={popUpOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BoxStyle}>
          <div className={Styles.topDiv}>
            <p className={Styles.pTag}>{title}</p>
          </div>
          {isFile ? (
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="15px"
                  color="black"
                  fontWeight="600"
                  paddingBottom="10px"
                >
                  Rejected File
                </Typography>
                <ViewDownloadDeleteComp
                  fileName={fileName}
                  id={fileId}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          ) : (
            <div className={Styles.childrenDiv}>{children}</div>
          )}
          <div className={Styles.midChildrenDiv}>
            <b>
              {midHead1Text} {midHead1}
            </b>
            <br></br>

            <b>
              {midHead2Text} {midHead2}
            </b>
            <br></br>
            {isDuplicate == true ? (
              <b>
                {midHead3Text} {midHead3} ({duplicateRecords} Duplicate Records)
              </b>
            ) : (
              <b>
                {midHead3Text} {midHead3}
              </b>
            )}
          </div>

          <div className={Styles.btnTopDiv}>
            <div></div>
            <div className={Styles.btnMidDiv}>
              <div className={Styles.btnLowerDiv}>
                <OutlineButton
                  showIcon={false}
                  text={negativeText}
                  onClickHandler={negativeOnClickHandler}
                />
                {dedupeProceedWithApprovedCases !== "" && (
                  <OutlineButton
                    showIcon={false}
                    text={dedupeProceedWithApprovedCases}
                    onClickHandler={
                      dedupeProceedWithApprovedCasesOnClickHandler
                    }
                  />
                )}
              </div>
              {isMiddleBtn ? (
                <SecoundaryButton
                  showIcon={false}
                  text={middleBtnText}
                  onClickHandler={middleBtnOnClickHandler}
                  width="60px"
                  isMargin={true}
                  isDisable={isDisable}
                />
              ) : (
                ""
              )}
              <PrimaryButton
                showIcon={false}
                text={btnText}
                onClickHandler={positiveOnClickHandler}
                width="60px"
                isDisable={isDisable}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
