import React from "react";
import Layout from "../../../components/Layout/Layout";
import {
  Box,
  Stack,
  Typography,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./Originator.module.css" 
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SecoundaryButton from "../../../components/Buttons/SecoundaryButton";
import { getAllDeals } from "../../../redux/Deals/dealSlice.js";
//? react-router-dom
import { useHistory } from "react-router-dom";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import useDebounce from "../../../Hooks/useDebounce";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import L1FinalDealTable from "../../../components/Table/Phase2/L1FinalDealTable";
import Paths from "../../../components/constants/Path";
import EntityDealTable from "../../../components/Table/Phase2/EntityDealTable";
import Loader from "../../../utils/Loader.jsx";
import { FilterConstants } from "../../../components/constants/FiltersConstant.js";

const EntityDealList = () => {
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if(name == "PEL" || name == "PCHFL"){
      setEntityFilter(name);
      setFilterName("")
    }else{
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        filterName,
        entityFilter,
        source: "PHFL",
        isPoolApproved: true,
      })
    );
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.APPROVED,
    FilterConstants.REJECTED,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];

  let history = useHistory();

  function clickHandlerToViewDetails(deal) {
    if (deal.entityDetail !== "" && deal.entityDetail !== undefined) {
      return history.push(`/entityloandeatils/${deal._id || deal}/details`);
    }
    return history.push({
      pathname: `/createntity/${deal._id || deal}`,
      //   state: deal,
    });
  }

  const nextPageHandler = () => {
    if (currentPage == dealState.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));

      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
          </Stack>

          <Divider></Divider>
          <div className={styles.filterListWrapper}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item || entityFilter == item ? "white" : "black",
                        backgroundColor:
                          filterName === item || entityFilter == item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <Loader />
          ) : (
            <Box>
              <EntityDealTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                setSortOrder={setSortOrder}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Loan/Pool Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
              ></EntityDealTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default EntityDealList;
