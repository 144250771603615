import { Typography, Link, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Colors } from "../../theme/theme";
// import Styles from "./BreadCrumbWrapper.module.css";
import Styles from "./BreadCrumbWrapper.module.css";

import {
  NavLink,
  BrowserRouter,
  withRouter,
  useHistory,
} from "react-router-dom";

const BreadCrumbWrapper = ({ breadCrumbs, componentName }) => {
  let History = useHistory();
  const handleClick = (event) => {
    event.preventDefault();

    console.info("You clicked a breadcrumb.");
  };

  const breadCrumbsList =
    breadCrumbs &&
    breadCrumbs.map((crumb, index) => {
      let { pathName, path } = crumb;
      console.log({ pathName });
      console.log({ path });
      return !path ? (
        <Typography key={index} color={Colors.piramal_orange}>
          {pathName}
        </Typography>
      ) : (
        <Link
          underline="hover"
          key={index}
          color="#666666"
          to={path}
          onClick={() => History.push(path)}
        >
          {pathName}
        </Link>
      );
    });

  return (
    <div className={Styles.breadcrumbWrapper}>
      {componentName ? (
        <Typography color="#666666" fontWeight="500">
          {componentName}
        </Typography>
      ) : (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadCrumbsList}
        </Breadcrumbs>
      )}
    </div>
  );
};

export default BreadCrumbWrapper;
