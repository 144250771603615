import * as React from "react";
import { Grid, TextField, Stack, Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";
import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";
import { GenerateReportButton } from "../../Pages/Phase2/SearchReports/SearchReports";
import { reportConstants } from "../constants/GlobalConstants";

export default function SynergyReportComponent({
  reportList,
  statusData,
  handleInputChange,
  SerachReportState,
  status,
  handleVariousReports,
  loanNo,
  setLoanNo,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <div className={Styles.CommanPaddingTop}>
              <SelectComponent
                compWidth="70px"
                placeholder="Select Type of Report"
                value={statusData.typeOfReport}
                onClickHandler={handleInputChange("typeOfReport")}
                optionList={reportList}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>
        {/* LOAN ID */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Loan Id">
            <TextField
              fullWidth
              type="text"
              value={loanNo}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[0-9a-zA-Z]+$/;
                if (value.match(regex) || value === "") {
                  setLoanNo(value);
                }
              }}
              variant="standard"
              onInput={(e) => {
                // alert(JSON.stringify(e.target.value));
                let value = e.target.value;
                e.target.value = value.toString().slice(0, 11);
              }}
              min={0}
            ></TextField>
          </TextWrapperComponent>
        </Grid>
      </Grid>

      {/* SUBMIT BUTTON */}
      <br></br>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={SerachReportState["loading"] ? true : status}
          onClick={handleVariousReports}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "Generate Report"}
          {/* Generate Report */}
        </Button>
      </div>
    </>
  );
}
