import React from "react";
import Styles from "./TabHeader.module.css";

const TabHeader = ({ heading }) => {
  return (
    <div className={Styles.tabHeader}>
      <h3>{heading ? heading : ""}</h3>
    </div>
  );
};

export default TabHeader;
