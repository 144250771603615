import React, { useState, useEffect } from "react";
import { Typography, Select, Grid, MenuItem, TextField } from "@mui/material";
import SpocTable from "../../Table/SpocTable";
import WrapperComponent from "../../Wrapper/WrapperComponent";
import ViewDownloadDeleteComp from "../../L2/ViewDownloadDeleteComp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutlineButton from "../../Buttons/OutlineButton";
import { useHistory, useParams } from "react-router-dom";
import TabFooter from "../TabFooter/TabFooter";
import { useDispatch, useSelector } from "react-redux";
import { pageStatusAction } from "../../../redux/originator/poolUploadSlice";
import { getloanUploadTemplate } from "../../../redux/Deals/dealSlice";

const DealDetails = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  const [state, setState] = useState("");
  const dealState = useSelector((state) => state.deal);
  const params = useParams();
  let loanTypeId = dealState?.loanTypeData?._id



  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "dealDetails" }));

    nextTabHandler();
  };

  let history = useHistory();
  useEffect(() => {
    console.log({ state });
  }, [state]);


  const DealData = [
    {
      title: "Type of Transaction",
      desc: dealState?.transactionType,
    },
    {
      title: "Product / Asset Class",
      desc: dealState?.loanTypeData?.name,
    },
    {
      title: "Vintage in Operations",
      desc: `${dealState?.vintageOperation} years`,
    },
    {
      title: "Rating",
      desc: dealState?.rating,
    },
    {
      title: "AUM",
      desc: `${dealState?.aum} (INR Crs)`,
    },
    {
      title: "Net Worth",
      desc: `${dealState?.netWorth} (INR Crs)`,
    },
    {
      title: "PAT",
      desc: `${dealState?.pat} (INR Crs)`,
    },
    {
      title: "CRAR%",
      desc: `${dealState?.crarPercent}%`,
    },
    {
      title: "GNPA%",
      desc: `${dealState?.gnpaPercent}%`,
    },
    {
      title: "NNPA %",
      desc: `${dealState?.nnpaPercent}%`,
    },
    {
      title: "Requested Deal Size",
      desc: `₹ ${dealState?.requestedDealAmount} Cr.`,
    },
  ];
  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <WrapperComponent title="Deal Details"> 
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {DealData.map((data) => {
                return (
                  <>
                    {data.desc != "undefined%" && data.desc != "₹ undefined Cr." && data.desc != undefined &&
                      <Grid item xs={3}>
                        <DataComp title={data.title} desc={data.desc} />
                      </Grid>
                    }
                  </>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>

      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={false} />
    </>
  );
};

export default DealDetails;
