import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react"
import Layout from "../../../components/Layout/Layout";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Paths from "../../../components/constants/Path";
import { useHistory } from "react-router-dom";
import KYCLinkDealListTable from "../../../components/Table/KYCLinkDealTableList";
import searchIcon from "../../../assets/icons/searchIcon.svg"
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../Hooks/useDebounce";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import { FilterConstants } from "../../../components/constants/FiltersConstant";
import styles from "./OriginatorKYCLink.module.css"
import Loader from "../../../utils/Loader";

const OriginatorKYCLinkDealist = () => {

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const dealState = useSelector((state) => state.deal);
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("All");
  const [entityFilter, setEntityFilter] = useState("")

  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if (name == "PEL" || name == "PCHFL") {
      setEntityFilter(name);
      setFilterName("")
    } else {
      setFilterName(name);
      setEntityFilter("")
    }
  };

  let history = useHistory();
  const clickHandlerToViewDetails = (deal) => {
    console.log({ ID: deal.id })
    return history.push({
      pathname: `/${deal.id || deal}/originatorkyclinkupdate`,
      state: deal,
    });
  };

  useEffect(() => {
    dispatch(getAllDeals({ limit, currentPage, debouncedSearch, filterName, entityFilter, isCreditBureauCheckDone: true, isSecondaryCheckDone: true, source: "PHFL" }));
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(
      getAllDeals({ limit, currentPage: 1, debouncedSearch, filterName, entityFilter, isCreditBureauCheckDone: true, isSecondaryCheckDone: true, source: "PHFL" })
    );
  }, [filterName, entityFilter]);

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];

  const breadCrumbs = [
    {
      path: Paths.ORIGINATORHOME,
      pathName: "Home",
    },
    {
      pathName: "Deals List",
    },
  ];

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
          </Stack>

          <Divider></Divider>

          <div className={styles.filterListWrapper}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item || entityFilter == item ? "white" : "black",
                        backgroundColor:
                          filterName === item || entityFilter == item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>

            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <Loader />
          ) : (
            <Box>
              <KYCLinkDealListTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "KYC Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
                statusList={filterList}
              ></KYCLinkDealListTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
}

export default OriginatorKYCLinkDealist;
