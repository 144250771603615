import { Colors } from "../../../theme/theme";

export const boxWrapper = {
  width: "100%",
  paddingLeft: "14px",
  backgroundColor: Colors.light_green,
  display: "flex",
  justifyContent: "space-between",
  height: "128px",
};

export const paddingTop = {
  paddingTop: "16px",
};

export const typoGraphyStyle = {
  fontSize: "14px",
  fontWeight: "400",
  color: "#808080",
};

export const typoStyle = {
  fontSize: "14px",
  fontWeight: "400",
  paddingTop: "10px",
};

export const descriptionIconStyle = {
  color: "#5E7387",
  width: "20px",
  height: "20px",
};

export const typo = {
  color: "#5E7387",
  fontWeight: "500",
  fontSize: "14px",
};
export const documnetHeaderTypo = {
  color: "#5E7387",
  fontWeight:"500",
  fontSize:"14px",
  marginTop : "0px !important",
  marginLeft : "8px !important"
}

export const btnStyle = {
  backgroundColor: Colors.piramal_orange,
  color: Colors.white,

  textTransform: "none",
  "&:hover": {
    backgroundColor: Colors.piramal_orange,
  },
  "&:disabled": {
    backgroundColor: "#FAC3B3",
    color: "white",
  },
};

export const documentHeaderStack = {
  flexDirection: "row",
};
export const ProfileDetailsTabDocumentHeader = {
  width: "100%",
  height: "100%",
};
export const profileDetailsRadioGroup = {
  "& .MuiSvgIcon-root": {
    fontSize: 20,
    color: Colors.piramal_blue,
  },

  ".MuiFormControlLabel-label": {
    fontSize: 14,
    color: "#5E7387",
  },
};

// L2ViewOriginatorsDetails.jsx........

export const originatorName = {
  fontSize:"14px",
  fontWeight:"400"
}

export const widthStyle = {
  width: "100%"
}

export const selectStatus = {
  fontSize:"14px",
  color:"#365069",
  fontWeight:"700",
  paddingBottom:"10px"
}

export const queriesStackWrapper = {
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"space-between",
  display:"flex",
  marginTop:"21px",
}

export const displayFlex = {
  display: "flex"
}

export const queryTypo = {
  fontSize:"16px", 
  fontWeight:"700", 
  paddingBottom:"16px"
}

export const addQueryTypo = {
  fontSize:"14px",
  color:"#AFB9C3",
  fontWeight:"400",
  paddingBottom:"10px"
}