import * as React from "react";
import Box from "@mui/material/Box";
import { Colors } from "../../theme/theme";
import { Stack, Typography, Grid } from "@mui/material";

import { useHistory } from "react-router-dom";
import SecoundaryButton from "../../components/Buttons/SecoundaryButton";
import Paths from "../../components/constants/Path";
import { useDispatch } from "react-redux";
import Layout from "../../components/Layout/TabsLayout/Phase2Layout/Layout";
import RedirectToLoginPage from "../redirect";
import { fetchDashBoardData } from "../../redux/Phase2/DashBoardSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import Loader from "../../utils/Loader";

const Home = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [Data, setData] = useState(null);

  useEffect(() => {
    dispatch(fetchDashBoardData());
  }, []);

  let dashBoardState = useSelector((state) => state.dashboard);
  let loading = useSelector((state) => state.dashboard.loading);

  useEffect(() => {
    if (dashBoardState["dashboardData"] != null) {
      if (userData.roles[0] === "F1") {
        setData([
          {
            loan_number: dashBoardState["dashboardData"]["totalLoans"],
            description: "Reports Uploaded Successfully",
          },

          {
            loan_number: dashBoardState["dashboardData"]["approvedLoans"],
            description: "Reports Approved",
          },
          {
            loan_number: dashBoardState["dashboardData"]["rejectedLoans"],
            description: "Reports Rejected",
          },
        ]);
      } else if (userData.roles[0] === "F2") {
        setData([
          {
            loan_number: dashBoardState["dashboardData"]["approvedLoans"],
            description: "Reports Approved",
          },
          {
            loan_number: dashBoardState["dashboardData"]["rejectedLoans"],
            description: "Reports Rejected",
          },
        ]);
      }
    } else {
      setData(null);
    }
  }, [dashBoardState["loading"]]);

  const onClickHandlerToCreateOriginator = () => {
    return history.push(Paths.PHASE2UPLOADNEWREPORT);
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));
  const UserDetails = () => {
    return (
      <>
        <RedirectToLoginPage />
        <Typography fontSize="14px" fontWeight="400" color={Colors.light_grey}>
          Hello
        </Typography>
        <Stack direction="row" justifyContent="space-between" paddingRight={4}>
          <Typography fontSize="20px" fontWeight="800" color={Colors.black}>
            {userData.name}👋
          </Typography>
          {userData.roles[0] === "F1" ? (
            <Stack direction="row" justifyContent="start" spacing={3}>
              <SecoundaryButton
                onClickHandler={onClickHandlerToCreateOriginator}
                text="Upload New Report"
              ></SecoundaryButton>
            </Stack>
          ) : null}
        </Stack>
      </>
    );
  };

  const LoanStatusCard =
    Data &&
    Data.map((data) => (
      <>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={11}>
            <>
              <Box
                backgroundColor={Colors.white}
                minWidth="240px"
                maxWidth="auto"
                boxShadow={2}
                borderRadius={2}
                paddingTop="14px"
                paddingLeft={2}
                paddingBottom="14px"
              >
                <Typography
                  fontSize="20px"
                  fontWeight="800"
                  color={Colors.black}
                >
                  {data.loan_number}
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight="400"
                  color={Colors.light_grey}
                >
                  {data.description}
                </Typography>
              </Box>
            </>
          </Grid>
        </Grid>
      </>
    ));

  return (
    <>
      <Layout componentName="Home">
        <Box paddingLeft="24px" paddingTop="28px">
          {loading ? (
            <Loader/>
          ) : (
            <>
              <UserDetails></UserDetails>

              <Stack direction="row" paddingTop={3}>
                {LoanStatusCard}
              </Stack>
            </>
          )}
        </Box>
      </Layout>
    </>
  );
};

export default Home;
