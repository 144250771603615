import { Colors } from "../../../theme/theme";
// AssignSPOCTab.jsx //

export const peopleAltIconStyle = {
    color: "#5E7387"
}

export const typographyStyle = {
    color: "#5E7387",
    fontWeight:"700",
    fontSize:"14px",
    paddingTop:"2px"
}

export const paddingRightStyle = {
    paddingRight: "20px"
}

export const paddingTopStyle = {
    paddingTop: "15px" 
}

export const istSubheaderStyle = {
    height: "40px",
    background: "#EBEEF0",
    margin: "10px 0px",
}

// BasicinformationTab.jsx //

export const rowGapStyle = {
    rowGap: "10px"
}

// DocumentsTab.jsx ////////////

export const widthStyle = {
    width: "100%"
}

export const descriptionIconStyle = {
    color: "#5E7387", 
    width: "20px", 
    height: "20px"
}

export const typoStyle = {
    color: "#5E7387",
    fontWeight:"700",
    fontSize:"14px",
} 

// KYCDocumentsTab.jsx///

export const radioGroupStyle = {
    "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: Colors.piramal_blue,
      },

      ".MuiFormControlLabel-label": {
        fontSize: 14,
        color: "#5E7387",
      },
}