import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import AllDocuments from "../../../components/AllDocuments";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import DetailsData from "../../../components/DetailsData";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import Paths from "../../../components/constants/Path";
import { fetchQueryList, postQuery } from "../../../redux/Queries/queriesSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { updatePoolStatus } from "../../../redux/originator/poolUploadSlice";
import Loader from "../../../utils/Loader";

const LoanDetails = () => {
  const [state, setState] = useState("");

  const dealState = useSelector((state) => state.deal);
  const originatorState = useSelector((state) => state.originator);
  const poolState = useSelector((state) => state.poolUpload);
  const params = useParams();
  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const [queryDetails, setQueryDetails] = useState({
    query: "",
    queryModule: "Deal",
    documentId: "",
  });
  let history = useHistory();
  const handleUpdatePoolStatus = () => {
    if (state === "") {
      dispatch(showErrorAlertAction("Please select status"));
      return;
    }

    dispatch(
      updatePoolStatus({
        poolStatus: state,
        dealId: params.dealId,
        uniqueCode: dealState?.filterdata?.uniqueCode,
      })
    ).then((res) => {
      if (res.payload.status === 200) {
        dispatch(showAlertAction(res?.payload?.data?.message));
        history.push(Paths.L2LOANDEALIST);
      } else {
        dispatch(showErrorAlertAction("Something went wrong"));
      }
    });
  };
  // post query click handler
  const onPostClickHandler = (queryDetails) => {
    console.log(queryDetails);
    if (queryDetails.query == "") {
      dispatch(showErrorAlertAction("Please Enter Query"));
      return;
    }

    dispatch(postQuery(queryDetails)).then((data) => {
      let id = params.dealId;
      dispatch(fetchQueryList({ id }));
      dispatch(showAlertAction(data.payload.data.message));
      history.push(Paths.L2LOANDEALIST);
    });
  };
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState(value);
  };
  const Placeholder = ({ children }) => {
    return (
      <div
        style={{
          color:
            dealState?.filterdata?.poolUploadFinal?.poolStatus == "PENDING"
              ? "#AFB9C3"
              : "#365069",
        }}
      >
        {children}
      </div>
    );
  };

  const secondaryAutoCheckSummary = [
    {
      title: "No. of Pools Uploaded (Primary Check)",
      desc: dealState?.filterdata?.poolUploadFinal?.primaryTotalRecords,
    },
    {
      title: "No. of Pools Approved (Primary Check)",
      desc: dealState?.filterdata?.poolUploadFinal?.primarySuccessRecords,
    },
    {
      title: "No. of Pools Approved (Secondary Check)",
      desc: dealState?.filterdata?.poolUploadFinal?.secondarySuccessRecords,
    },
    {
      title: "No. of Pools Rejected (Secondary Check)",
      desc: dealState?.filterdata?.poolUploadFinal?.secondaryRejectedRecords,
    },
  ];

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {originatorState.loading || poolState.loading ? (
        <Loader />
      ) : (
        <>
          <DetailsData label="Credit Filters" />

          <WrapperComponent title="Secondary Auto Check Summary">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Grid container spacing={3}>
                  {secondaryAutoCheckSummary.map((data) => {
                    return (
                      <Grid item xs={3}>
                        <DataComp title={data.title} desc={data.desc} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </>
          </WrapperComponent>
          <AllDocuments />
          {/* <WrapperComponent title="Related Documents">
            <>
              <div
                style={{
                  paddingTop: "14px",
                }}
              >
                <Grid container spacing={3}>
                  {dealState?.filterdata?.poolUploadFinal
                    ?.cibilScoreSuccessFile &&
                    dealState?.filterdata?.poolUploadFinal
                      ?.cibilScoreSuccessFile?.filename !== undefined && (
                      <Grid item xs={4}>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          color="#808080"
                          paddingBottom="10px"
                        >
                          Finalized pool
                        </Typography>

                        <ViewDownloadDeleteComp
                          fileName={
                            dealState?.filterdata?.poolUploadFinal
                              ?.cibilScoreSuccessFile?.filename
                          }
                          id={
                            dealState?.filterdata?.poolUploadFinal
                              ?.cibilScoreSuccessFile?._id
                          }
                        />
                      </Grid>
                    )}
                  {dealState?.filterdata?.poolUploadFinal
                    ?.uploadedCibilScoreFile &&
                    dealState?.filterdata?.poolUploadFinal
                      ?.uploadedCibilScoreFile?.filename !== undefined && (
                      <Grid item xs={4}>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          color="#808080"
                        >
                          Credit Bureau Scrub Report
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          paddingTop="10px"
                        >
                          <ViewDownloadDeleteComp
                            fileName={
                              dealState?.filterdata?.poolUploadFinal
                                ?.uploadedCibilScoreFile?.filename || ""
                            }
                            id={
                              dealState?.filterdata?.poolUploadFinal
                                ?.uploadedCibilScoreFile?._id || ""
                            }
                          />
                        </Typography>
                      </Grid>
                    )}

                  {dealState?.filterdata?.poolUploadFinal
                    ?.kycVerificationSuccessFile && (
                    <Grid item xs={4}>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#808080"
                      >
                        KYC Report
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        paddingTop="10px"
                      >
                        <ViewDownloadDeleteComp
                          fileName={
                            dealState?.filterdata?.poolUploadFinal
                              ?.kycVerificationSuccessFile?.filename || ""
                          }
                          id={
                            dealState?.filterdata?.poolUploadFinal
                              ?.kycVerificationSuccessFile?._id || ""
                          }
                        />
                      </Typography>
                    </Grid>
                  )}

                  {dealState?.filterdata?.poolUploadFinal?.termsheetFinalFile &&
                    dealState?.filterdata?.poolUploadFinal?.termsheetFinalFile
                      .filename !== undefined && (
                      <Grid item xs={4}>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          color="#808080"
                        >
                          Term Sheet
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          paddingTop="10px"
                        >
                          <ViewDownloadDeleteComp
                            fileName={
                              dealState?.filterdata?.poolUploadFinal
                                ?.termsheetFinalFile.filename
                            }
                            id={
                              dealState?.filterdata?.poolUploadFinal
                                ?.termsheetFinalFile._id || ""
                            }
                          />
                        </Typography>
                      </Grid>
                    )}
                  {dealState?.filterdata?.entityDetail !== undefined &&
                    dealState?.filterdata?.entityDetail?.leReports.length > 0 &&
                    dealState?.filterdata?.entityDetail?.leReports.map(
                      (item, index) => {
                        console.log(item);
                        return (
                          <Grid item xs={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="400"
                              color="#808080"
                            >
                              LE Report {`${index + 1}`}
                            </Typography>
                            <Typography
                              fontSize="14px"
                              fontWeight="400"
                              paddingTop="10px"
                            >
                              <ViewDownloadDeleteComp
                                fileName={item.filename || ""}
                                id={item._id || ""}
                              />
                            </Typography>
                          </Grid>
                        );
                      }
                    )}
                  {dealState?.filterdata?.investmentMemo?.file?.filename !==
                    undefined && (
                    <Grid item xs={4}>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#808080"
                      >
                        Investment Memo
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        paddingTop="10px"
                      >
                        <ViewDownloadDeleteComp
                          fileName={
                            dealState?.filterdata?.investmentMemo?.file
                              ?.filename
                          }
                          id={dealState?.filterdata?.investmentMemo?.file?._id}
                        />
                      </Typography>
                    </Grid>
                  )}

                  {dealState?.filterdata?.entityDetail?.file?.filename !==
                    undefined && (
                    <Grid item xs={4}>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        color="#808080"
                      >
                        Entity Details
                      </Typography>
                      <Typography
                        fontSize="14px"
                        fontWeight="400"
                        paddingTop="10px"
                      >
                        <ViewDownloadDeleteComp
                          fileName={
                            dealState?.filterdata?.entityDetail?.file
                              ?.filename || ""
                          }
                          id={
                            dealState?.filterdata?.entityDetail?.file?._id || ""
                          }
                        />
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            </>
          </WrapperComponent> */}
          {userData?.roles.length == 1 ? (
            <>
              <WrapperComponent title="Pool Status">
                <>
                  <div
                    style={{
                      padding: "14px",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      color="#365069"
                      fontWeight="700"
                      paddingBottom="10px"
                    >
                      Select Status
                    </Typography>
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "space-between",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "368px",
                        }}
                      >
                        <Select
                          IconComponent={ExpandMoreIcon}
                          value={state}
                          onChange={handleInputChange("loanStatus")}
                          disabled={
                            dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                              "APPROVED"
                              ? true
                              : false
                          }
                          displayEmpty
                          size={"small"}
                          variant="standard"
                          fullWidth
                          renderValue={
                            state !== ""
                              ? undefined
                              : () => (
                                <Placeholder>
                                  {dealState?.filterdata?.poolUploadFinal
                                    ?.poolStatus === "PENDING"
                                    ? "Select an Option"
                                    : dealState?.filterdata?.poolUploadFinal
                                      ?.poolStatus}
                                </Placeholder>
                              )
                          }
                        >
                          <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                          <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
                        </Select>
                      </div>
                      <OutlineButton
                        text="Update"
                        showIcon={false}
                        onClickHandler={handleUpdatePoolStatus}
                      ></OutlineButton>
                    </div>
                  </div>
                </>
              </WrapperComponent>

              <WrapperComponent title="Queries">
                <>
                  <div
                    style={{
                      padding: "14px",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      color="#365069"
                      fontWeight="700"
                      paddingBottom="10px"
                    >
                      Post a Query
                    </Typography>

                    <Typography
                      fontSize="14px"
                      color="#AFB9C3"
                      fontWeight="400"
                      paddingBottom="10px"
                    >
                      Add your query
                    </Typography>
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "space-between",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "368px",
                        }}
                      >
                        <TextField
                          variant="standard"
                          fullWidth
                          autoComplete="off"
                          onChange={(e) =>
                            setQueryDetails({
                              ...queryDetails,
                              query: e.target.value,
                              documentId: params.dealId,
                            })
                          }
                        ></TextField>
                      </div>
                      <OutlineButton
                        text="Post"
                        showIcon={false}
                        onClickHandler={() => onPostClickHandler(queryDetails)}
                      ></OutlineButton>
                    </div>
                  </div>
                </>
              </WrapperComponent>
            </>
          ) : (<></>)}

          <div style={{ padding: "10px" }}></div>
        </>
      )}
    </>
  );
};

export default LoanDetails;
