import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance, { instanceV2, API, ZIPKINAPI } from "../../API/BaseUrl";
import axios, { Axios } from "axios";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  reportData: null,
  loading: false,
  error: null,
  success: null,
  kanazaURL: null,
};

//? fetch Report
export const fetchReportData = createAsyncThunk(
  "fetchReportData",
  async (
    {
      dealId,
      year = "",
      month,
      reportType,
      endDate,
      startDate,
      ledgerCode,
      multiBranch,
      type,
      multiLedgerCode,
      reportName,
      entity
    },
    rejectWithValue
  ) => {
    console.log({
      dealId,
      month,
      reportType,
      endDate,
      startDate,
      ledgerCode,
      multiBranch,
      multiLedgerCode,
      reportName,
      entity,
    });
    let response;
    try {
      if (reportType === "getLedgerReport") {
        response = await instance.get(
          `${ZIPKINAPI}/accounting-service/getLedgerReport?ledgerCode=${ledgerCode}&dealId=${dealId}
          &startDate=${startDate}&endDate=${endDate}`
        );
      } else if (reportType === "getNetAccountingEntries") {
        response = await instance.get(
          `${ZIPKINAPI}/accounting-service/getNetAccountingEntries?month=${month}&dealId=${dealId}&year=${year}`
        );
      } else if (reportType === "trialbalances") {
        // if (type == "SUMMARY") {
          response = await instance.get(
            `/accounting-service/getTrialBalanceReport?reportType=${type}&entity=${entity}&fromDate=${startDate}
            &toDate=${endDate}`
          );
        // } else {
        //   response = await instance.get(
        //     `/accounting-service/getTrialBalanceReport?branch=${multiBranch}&fromDate=${startDate}
        //     &toDate=${endDate}`
        //   );
        // }
      } else {
        response = await instance.get(
          `${ZIPKINAPI}/accounting-service/${reportType}?month=${
            reportType == "getSecuritizationReportAsOnDate" ? "" : month
          }&dealId=${dealId}&year=${
            reportType == "getSecuritizationReportAsOnDate" ? "" : year
          }&reportName=${
            reportType == "getSecuritizationReportAsOnDate" ? "" : reportName
          }&entity=${
            reportType == "getSecuritizationReportAsOnDate" ? "" : entity
          }`

        );
      }

      return response?.data;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

//? fetching file

export const downloadImageReports = createAsyncThunk(
  "downloadImageReports",
  async (
    { id, fileName, isDelete = false, setLoading = () => {} },
    { rejectWithValue }
  ) => {
    try {
      setLoading(true);
      console.log("hey");
      const response = await instanceV2.get(
        `utility-service/image?id=${id}&isDelete=${isDelete}`,
        {
          responseType: "blob",
        }
      );
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
);
export const getFileData = createAsyncThunk(
  "downloadImageReports",
  async ({ id, isDelete = false }, { rejectWithValue }) => {
    try {
      console.log("hey");
      const response = await instanceV2.get(
        `utility-service/image?id=${id}&isDelete=${isDelete}`,
        {
          responseType: "blob",
        }
      );
      return response?.data ?? {};
    } catch (error) {
      console.error(error);
    }
  }
);

//? fetch Kanaza Report
export const fetchKanazaData = createAsyncThunk(
  "fetchKanazaData",
  async (loanNo, { rejectWithValue }) => {
    console.log({ loanNo });
    let response;
    try {
      response = await instance.get(
        `/accounting-service/getKanazaReports?loanId=${loanNo}`
      );
      return response;
    } catch (err) {
      console.log({ err });
      return rejectWithValue(err);
    }
  }
);

const SearchReportSlice = createSlice({
  name: "SearchReportSlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
      state.reportData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchReportData.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.reportData = payload.data;
    });
    builder.addCase(fetchReportData.rejected, (state, { payload }) => {
      state.loading = false;
      state.reportData = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    builder.addCase(fetchKanazaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchKanazaData.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.kanazaURL = payload.data.data.url;
    });
    builder.addCase(fetchKanazaData.rejected, (state, { payload }) => {
      state.loading = false;
      state.url = null;
      state.error = payload;
    });
    builder.addCase(downloadImageReports.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadImageReports.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
    });
    builder.addCase(downloadImageReports.rejected, (state, { payload }) => {
      state.loading = false;
      state.url = null;
      state.error = payload;
    });
  },
});

export const { clearSucessAndErrorAction } = SearchReportSlice.actions;

export const searchReportReducer = SearchReportSlice.reducer;
