import React, { useState, useEffect } from "react";
import TabFooter from "../../TabFooter/TabFooter";
import {
  basicInformation,
  pageStatusAction,
} from "../../../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import BasicInformationForm from "./BasicInformationForm/BasicInformationForm";
import { getOriginatorswithStatus } from "./../../../../redux/Deals/dealSlice";

const BasicInformationTab = ({ nextTabHandler }) => {
  let dispatch = useDispatch();

  //? deal state from redux store
  let dealState = useSelector((state) => state.deal);
  let { name, originator, entity } = useSelector((state) => state.deal);

  //? managing the state of the form
  const [value, setValue] = useState(originator || "");
  let [state, setState] = useState({
    name: name || "",
    originator: originator || "",
    entity: entity || "",
  });
  const [entityValue, setEntityValue] = useState( entity || "")

  //? disable enable next button status
  const [status, setStatus] = useState(true);

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState({ ...state, [name]: value });
  };

  //? setting the value of the select option

  useEffect(() => {
    setState({ ...state, originator: value });
  }, [value]);

  //? hitting api to get originators
  useEffect(() => {
    dispatch(getOriginatorswithStatus({ status: "APPROVED" }));
  }, []);

  //? checking that all fields are filled or not ( for button status)
  useEffect(() => {
    if (state.name != "" && value != "" && entityValue) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [state, entityValue]);

  //? dispatching the state to redux store

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    let selectedOriginators = dealState?.allOriginators.filter((originator) => originator.id === value);
    console.log({ select: selectedOriginators[0]?.originatorIds })
    dispatch(pageStatusAction({ pageName: "basicInformation" }));
    dispatch(basicInformation({ ...state, entity: entityValue, originator: value, originatorId: selectedOriginators[0]?.originatorIds }));

    nextTabHandler();
  };


  return (
    <>
      <div style={{ padding: "24px" }}>
        <form>
          <div>
            {dealState.allOriginators &&
              dealState?.allOriginators.length != 0 && (
                <BasicInformationForm
                  handleInputChange={handleInputChange}
                  dealState={dealState}
                  state={state}
                  value={value}
                  setValue={setValue}
                  originator={originator}
                  name={name}
                  entityValue={entityValue}
                  setEntityValue={setEntityValue}
                  entity={entity}
                />
              )}
          </div>
        </form>
      </div>
      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
    </>
  );
};

export default BasicInformationTab;

