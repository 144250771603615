import * as React from "react";
import { Grid, TextField, Stack, Button, Typography } from "@mui/material";
import "../../App.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";
import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";
import {
  GenerateReportButton,
  GenerateReportSearchWidth,
} from "../../Pages/Phase2/SearchReports/SearchReports";

export default function VoucherReportComponent({
  reportList,
  statusData,
  handleInputChange,
  SerachReportState,
  voucherDetails,
  handleVoucherReport,
  transactionHanlder,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <SelectComponent
              placeholder="Select Type of Report"
              value={statusData.typeOfReport}
              onClickHandler={handleInputChange("typeOfReport")}
              optionList={reportList}
            ></SelectComponent>
          </TextWrapperComponent>
        </Grid>

        {/* DEAL TYPE */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Voucher Number">
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={voucherDetails.voucherNumber}
              variant="standard"
              size="small"
              sx={GenerateReportSearchWidth}
              onChange={transactionHanlder("voucherNumber")}
            ></TextField>
          </TextWrapperComponent>
        </Grid>
      </Grid>
      <div className={Styles.noContentDiv}></div>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={voucherDetails.voucherNumber == "" || SerachReportState["loading"] == true }
          onClick={handleVoucherReport}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "View Voucher Report"}
        </Button>
      </div>
    </>
  );
}
