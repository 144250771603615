import React from 'react'
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Pagination from '../Pagination/Pagination';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@mui/material/Paper";
import { useState } from 'react';
import moment from "moment/moment";
import { dynamicIconStyle, dynamicTypoStyle, norecordFoundStyle, originatorNameStyle, statusTopBox } from './TableCSS';
import styles from "./Table.module.css"

const CreditFiltersDealTable = ({ data, viewActionClickHandler, header, page, filterName, totalRecords,
  nextPageHandler,
  prevPageHandler, }) => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
    // padding: "6px",
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  }));


  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    // var finalTime = hours + ":" + minutes + " " + AmOrPm
    return finalTime;
  }

  const StatusContainer = ({ statusType }) => {
    const Textcolor =
      statusType === "APPROVED" || statusType === "Created"
        ? "#277742"
        : statusType === "REJECTED"
          ? "#8D3431"
          : statusType === "CLOSED"
            ? "#2C4584"
            : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" || statusType === "Created"
        ? "#E3F6E9"
        : statusType === "REJECTED"
          ? "#FCE6E5"
          : statusType === "CLOSED"
            ? "#E4EAFA"
            : "#FFF4E7";



    const newstatus = statusType === "APPROVED" || statusType === "Created"
      ? "Added"
      : "Not Added"


    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {newstatus
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const Actions = ({ isDealAdded, dealId, label = "View", Icon, item, isCreditfilter }) => {
    // label = "View"
    const iconStyle = dynamicIconStyle(isCreditfilter)
    const typoStyle = dynamicTypoStyle(isCreditfilter)
    return (
      <div onClick={() =>
        item.status !== "CLOSED" ? viewActionClickHandler(item, isCreditfilter) : null
      } style={{ cursor: "pointer" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{
            justifyContent: "flex-end"
          }}
        //   justifyItems="flex-end"
        >
          <Icon  sx={iconStyle}></Icon>

          <Typography sx={typoStyle}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item.id} sx={{ margin: "auto" }}>
                  <StyledTableCell1 component="th" scope="row">
                    {item.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>
                  <StyledTableCell component="th" scope="row">
                    {item?.loanType === undefined && "NA"}
                    {item?.loanType === "" && "NA"}
                    {item?.loanType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item.requestedDealAmount === undefined ||
                      item.requestedDealAmount === null ||
                      item.requestedDealAmount === ""
                      ? "NA"
                      : numberFormat(
                        Number(
                          item?.requestedDealAmount
                        )
                      )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(item.createdAt).format("D MMM YYYY")}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {convertTime(item.createdAt) || ""}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <StatusContainer statusType={`${item?.loanFilter !== undefined
                      ? "Created"
                      : "Not Created"
                      }`}></StatusContainer>
                  </StyledTableCell>
                  <TableCell sx={{ alignItems: "center", }} >
                    <StyledNoBorderTableCell >
                      <Actions
                        label="View"
                        dealId={item.id}
                        item={item}
                        isCreditfilter={item?.loanFilter}
                        Icon={VisibilityIcon}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>

      {data && data.length === 0 ? null : (
        <Pagination
          Page={page}
          TotalNoNumber={totalRecords}
          nextPageHandler={page === totalRecords ? null : nextPageHandler}
          prevPageHandler={prevPageHandler}
        />
      )}
    </div>
  )

}

export default CreditFiltersDealTable;