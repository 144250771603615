import React, { useState } from "react"
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader"
import UploadTemplate from "../../Wrapper/UploadTemplate"
import TabFooter from "../TabFooter/TabFooter"
import DoneIcon from '@mui/icons-material/Done';
import { Divider, Grid, Typography } from "@mui/material";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { useDispatch, useSelector } from "react-redux";
import { pageStatusAction } from "../../../redux/L1/kycDocumentsRequestSlice";
// import { pageStatusAction  } from "../../../redux/originator/creditBureauSlice";
// import { useState } from "react";
import Modal from "../../Modal/Modal";

const CIBILScoreValidation = ({ nextTabHandler }) => {

    let creditScrubState = useSelector((state) => state.creditScrub);

    const [open, setOpen] = useState(false);
    const [reupload, setReupload] = useState(false);
    const [poolsContinue, setContinue] = useState(false);
    let dealState = useSelector((state) => state.deal);

    let userData = JSON.parse(localStorage.getItem("UserData"));

    let dispatch = useDispatch();
    const nextBtnClickHandler = () => {
        // e.preventDefault();
        console.log("step 2")
        // setOpen(false)
        dispatch(pageStatusAction({ pageName: "CIBILScore" }));
        nextTabHandler();
    };


    const handleReupload = (e) => {

    }

    const summary = [
        {
            title: "Total Loans Uploaded",
            count: creditScrubState?.creditBureauScrub?.cibilScoreTotalRecords || "0",
        },
        {
            title: "Total Loans Approved",
            count: creditScrubState?.creditBureauScrub?.cibilScoreSuccessRecords || "0",
        },
        {
            title: "Total Loans Rejected",
            count: creditScrubState?.creditBureauScrub?.cibilScoreRejectedRecords || "0"
        },
    ]


    return (
        <>
            <DocumentHeader title={"CIBIL Check Loan Summary"} ></DocumentHeader>
            <div style={{ padding: "20px" }}>
                <Grid container spacing={4}>
                    {summary.map((item) => {
                        return (
                            <Grid item xs={4} key={item.title}>
                                <TextWrapperComponent text={item.title}>
                                    <Typography
                                        sx={{ fontSize: "16px", color: "#20303F", fontWeight: 400 }}
                                    >{item.count}</Typography>
                                </TextWrapperComponent>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
            <Divider></Divider>
{userData?.roles.length != 1 ?(
<></>
):(
            <TabFooter
                onclickHandler={nextBtnClickHandler}
                // showSubmitButton={true}
                isBtnDisable={dealState?.filterdata?.poolUploadFinal?.poolStatus === "APPROVED" ? true : false}
            />
)}
            <Modal open={open} setOpen={setOpen} title="What you would like to do?" negativeText="Continue With Approved Pools" positiveText="Re-Upload Rejected Pool" positiveOnClickHandler={handleReupload} negativeOnClickHandler={nextBtnClickHandler} isNegativeHandler={true}>
            </Modal>
        </>
    )
}

export default CIBILScoreValidation