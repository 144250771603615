import { Box } from "@mui/material";
import React from "react";
import Layout from "../../components/Layout/Layout";
import { FormDetails } from "../../components/Layout/TabsLayout/TabsComponents";
import { Colors } from "../../../src/theme/theme";
import Image from "../../../src/assets/images/Group 42722.svg";
// import icon from "../../../src/assets/icons/person_icon.svg";
import DescriptionIcon from "@mui/icons-material/Description";

import { useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Tabs from "../../components/Tabs/Tabs";
import { useSelector } from "react-redux";
import BasicInfo from "../../components/Tabs/EditOriginatorTab/BasicInfo";
import SPOCDetails from "../../components/Tabs/EditOriginatorTab/SPOCDetails";
import Documents from "../../components/Tabs/EditOriginatorTab/Documents";
import KYCDocuments from "../../components/Tabs/EditOriginatorTab/KYCDocuments";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  clearOriginatorState,
  getOriginatorDetails,
} from "../../redux/originator/originatorSlice";
import ViewDownloadDeleteComp from "../../components/L2/ViewDownloadDeleteComp";
import QueriesPage from "../../components/Queries/Queries";
import Histories from "../../components/History/Histories";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import Paths from "../../components/constants/Path";
import Otherdetails from "../../components/Tabs/EditOriginatorTab/Otherdetails";

const EditOriginatorViewDetails = () => {
  let icon = (
    <PersonIcon
      sx={{
        color: "#41C66E",
      }}
    />
  );

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.ORIGINATOR_LIST,
      pathName: "Originator Profiles",
    },
    {
      pathName: "View Originator Profile",
    },
  ];

  const tabs = [
    {
      tabName: "Basic Information",
      comp: BasicInfo,
      heading: "Originator's Basic Information",
    },
    {
      tabName: "Other Details",
      comp: Otherdetails,
      heading: "Other Details",
    },
    {
      tabName: "SPOC Details",
      comp: SPOCDetails,
      heading: "SPOC Details",
    },
    {
      tabName: "Documents",
      comp: Documents,
      heading: "Documents",
    },
    {
      tabName: "KYC Documents",
      comp: KYCDocuments,
      heading: "KYC Documents",
    },
  ];

  const params = useParams();
  const { originatorId } = params;

  let userData = JSON.parse(localStorage.getItem("UserData"));

  var dispatch = useDispatch();

  useEffect(() => {
    
    dispatch(getOriginatorDetails(originatorId));
  }, []);

  let editOriginatorState = useSelector((state) => state.editOriginator);
  let originatorState = useSelector((state) => state.originator);
  let dealState = useSelector((state) => state.deal);
  
  useEffect(() => {
    return () => {
      dispatch(clearOriginatorState());
    };
  }, []);

  let TabsList = [
    {
      name: "Loan",
      component: (
        <Tabs
          tabs={tabs}
          status={editOriginatorState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
    
  ];

  if(userData?.roles.length == 1 ){
    TabsList.push(
      {
        name: "Queries",
        component: (
          <QueriesPage
            dealIdorOriginatorId={params?.dealId || originatorId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
        index: 1,
      },
      {
        name: "History",
        component: <Histories id={originatorId} />,
        index: 2,
      },
    )
  }

  // let icon = (
  //   <DescriptionIcon
  //     sx={{
  //       color: "#2C4584",
  //     }}
  //   />
  // );

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        TabsList={TabsList}
        formName={originatorState?.originatorName}
        icon={icon}
        iconColor={"#DBE3F8"}
        image={Image}
      />
    </Layout>
  );
};

export default EditOriginatorViewDetails;
