import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import {
  approveTermsheet,
  getTemplate,
  getTermSheet,
  pageStatusAction,
  uploadTermsheet,
  uploadoriginatorTermsheet,
} from "../../../redux/TermSheet/TermSheetSlice";
import { showAlertAction } from "../../../redux/app/appSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import LabelWithDownload from "../../LabelWithDownload";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import TabFooter from "./../TabFooter/TabFooter";

const TermSheetTemplate = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  const [loanFile, setLoanFile] = useState(null);
  const [state, setState] = useState(null);
  const [status, setStatus] = useState(true);
  const [templateData, setTemplateData] = useState("");
  const [termSheetData, setTermSheetData] = useState("");
  const [newloader, setNewLoader] = useState(true);
  const [uniqueCode, setUniqueCode] = useState("");
  const dealState = useSelector((state) => state.deal);

  let uniqueCodeTemp = dealState?.filterdata?.uniqueCode;
  let dealid = localStorage.getItem("dealId");

  let userData = JSON.parse(localStorage.getItem("UserData"));
  let activRole = localStorage.getItem("activeRole")
  const roleName = activRole;

  useEffect(() => {
    if (uniqueCodeTemp) {
      setUniqueCode(uniqueCodeTemp);
    }
  }, [uniqueCodeTemp]);

  //handler for upload termsheet by L1, approve termsheet by L2 & then upload correct termsheet by Origintor
  const onClickHandlerToDealsPage = (e) => {
    e.preventDefault();
    if (roleName === "L1") {
      dispatch(
        uploadTermsheet({
          file: loanFile,
          uniqueCode: uniqueCode,
          dealId: dealid,
          roleName: "L1",
          termsheetType: "draft",
        })
      ).then((res) => {
        if (res.payload.data.status === 200) {
          dispatch(showAlertAction("Term Sheet Uploaded!"));
        }
      });
    } else if (roleName === "L2") {
      dispatch(
        approveTermsheet({
          uniqueCode: uniqueCode,
          dealId: dealid,
          isTermSheetApproved: "True",
        })
      ).then((res) => {
        if (res.payload.data.status === 200) {
          dispatch(showAlertAction("Term Sheet Confirmed!"));
        }
      });
    } else if (roleName === "Originator") {
      dispatch(
        uploadoriginatorTermsheet({
          file: loanFile,
          uniqueCode: uniqueCode,
          dealId: dealid,
          termsheetType: "draft",
          roleName: roleName,
        })
      ).then((res) => {
        if (res.payload.data.status === 200) {
          dispatch(showAlertAction("Term Sheet Confirmed!"));
        }
      });
    }
    dispatch(pageStatusAction({ pageName: "TermSheet" }));
    nextTabHandler();
  };

  

  //handler to get termsheet template for L1 and also get uploaded termsheet by L1 for Originator & L2 roles
  useEffect(() => {
    if (dealState?.filterdata) {
      let finalCode = dealState?.filterdata?.uniqueCode;
      let finalId = dealState?.filterdata?._id;

      if (roleName === "L2" || roleName === "Originator") {
        dispatch(getTermSheet({ uniqueCode: finalCode, dealId: finalId })).then(
          (res) => {
            setTermSheetData(res?.payload?.data?.termsheetDraftFile);
            setNewLoader(false);
          }
        );
      }

      if (roleName === "L1") {
        dispatch(getTemplate({ type: "TERM_SHEET" })).then((res) => {
          setTemplateData(res?.payload?.data);
          setNewLoader(false);
        });
      }
    }
  }, [dealState?.filterdata]);

  //button disable handler
  useEffect(() => {
    if (roleName != "L2") {
      if (loanFile == undefined) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } else {
      setStatus(false);
    }
  }, [loanFile]);

  useEffect(() => {
    dispatch(getSingleDealDetails(dealid)).then((res) => {
      localStorage.setItem(
        "uniqueCode",
        res?.payload?.data?.data?.data?.uniqueCode
      );
    });
  }, []);

  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <>
          {/* Render Approved Pool if available */}
          {dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile &&
            dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile
              ?._id && (
              <>
                <DocumentHeader
                  title={"Approved Pool"}
                  startIcon={
                    <DescriptionIcon
                      sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                    />
                  }
                ></DocumentHeader>
                <div
                  style={{
                    padding: "24px",
                  }}
                >
                  <LabelWithDownload
                    label="Approved Pool"
                    fileId={
                      dealState?.filterdata?.poolUploadFinal
                        ?.cibilScoreSuccessFile?._id
                    }
                    fileName={
                      dealState?.filterdata?.poolUploadFinal
                        ?.cibilScoreSuccessFile?.filename
                    }
                  />
                </div>
              </>
            )}

          {/* Handle Termsheet tab as per role wise condition */}
          {roleName != "L2" ? (
            <>
              <DocumentHeader
                title={
                  roleName === "L1"
                    ? "Term Sheet Template (Downloadable)"
                    : "Term Sheet"
                }
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  ></DescriptionIcon>
                }
              ></DocumentHeader>
              <div style={{ padding: "10px", width: "auto" }}>
                <ViewDownloadDeleteComp
                  fileName={
                    roleName === "Originator"
                      ? termSheetData?.filename
                      : templateData?.file?.filename
                  }
                  id={
                    roleName === "Originator"
                      ? termSheetData?._id
                      : templateData?.file?._id
                  }
                ></ViewDownloadDeleteComp>
              </div>
              {dealState?.filterdata?.poolUploadFinal?.termsheetUploadedFile
                ?._id != undefined || userData?.roles.length != 1 ? (
                <></>
              ) : (
                <>
                  <DocumentHeader
                    title={"Term Sheet"}
                    startIcon={
                      <DescriptionIcon
                        sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                      />
                    }
                  ></DocumentHeader>
                  <div style={{ padding: "10px", width: "auto" }}>
                    <UploadTemplate
                      docName="TermsheetReport"
                      setdata={setState}
                      setLoanFile={setLoanFile}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <DocumentHeader
                title="Term Sheet - Uploaded By L1"
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  ></DescriptionIcon>
                }
              ></DocumentHeader>
              <div style={{ padding: "10px", width: "auto" }}>
                <ViewDownloadDeleteComp
                  fileName={termSheetData?.filename}
                  id={termSheetData?._id}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          )}

          {userData?.roles.length == 1 ? (
            <TabFooter
              label={roleName != "L2" ? "Upload" : "Confirm"}
              isBtnDisable={
                dealState?.filterdata?.poolUploadFinal?.termsheetFinalStatus
                  ?.L1 == "DONE"
                  ? "true"
                  : status
              }
              endIcon={<DoneIcon />}
              onclickHandler={onClickHandlerToDealsPage}
            />
          ) : (<></>)}
        </>
      )}
    </>
  );
};

export default TermSheetTemplate;
