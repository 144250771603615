import * as React from "react";
import { DocumentHeader } from "../DocumentHeaderWrapper/DocumentHeader";
import { Grid, TextField, Button } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextWrapperComponent from "../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../FormComponents/SelectComponent";
import { Box } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GeneratedReportFileV2 } from "../Phase2/UploadReports/GenerateReportV2";
import Styles from "../../Pages/Phase2/SearchReports/SearchReports.module.css";
import {
  GenerateReportBoxContainer,
  GenerateReportButton,
  GenerateReportDatePickerInput,
  GenerateReportDatePickerPopper,
} from "../../Pages/Phase2/SearchReports/SearchReports";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function TrialBalancesReportComponent({
  reportList,
  statusData,
  handleInputChange,
  SerachReportState,
  handleGenerateReport,
  trailStatus,
  handleInputChange1,
  statusData1,
  startDate,
  setstartDate,
  endDate,
  setEndDate,
  CompHeading = "",
  entityData,
  handleEntityChange,
  sourceList
}) {
  return (
    <>
      <Grid container spacing={4}>
        {/* TYPE OF REPORT */}
        <Grid item xs={4}>
          <TextWrapperComponent text="Type of Report">
            <div className={Styles.CommanPaddingTop}>
              <SelectComponent
                compWidth="70px"
                placeholder="Select Type of Report"
                value={statusData.typeOfReport}
                onClickHandler={handleInputChange("typeOfReport")}
                optionList={reportList}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Select Report Type">
            <div className={Styles.CommanPaddingTop}>
              <SelectComponent
                compWidth="70px"
                placeholder="Select Report Type"
                value={statusData1.selectReportType}
                onClickHandler={handleInputChange1("selectReportType")}
                optionList={[
                  {
                    id: "1",
                    name: "Summary Report",
                  },
                  {
                    id: "2",
                    name: "Detailed Report",
                  },
                ]}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>
        <Grid item xs={4}>
          <TextWrapperComponent text="Entity">
            <div
              style={{
                paddingTop: "3px",
                // width: "180px",
              }}
            >
              <SelectComponent
                compWidth="70px"
                placeholder="Select Entity of Report"
                value={entityData.sourceOfReport}
                onClickHandler={handleEntityChange("sourceOfReport")}
                optionList={sourceList}
              ></SelectComponent>
            </div>
          </TextWrapperComponent>
        </Grid>


        {/* {statusData1.selectReportType ==
          "" ? null : statusData1.selectReportType === "1" ? (
            <Grid item xs={4}>
              {ledgerLists.length > 0 && (
                <TextWrapperComponent text="Ledger Code">
                  <Select
                    multiple
                    sx={GenerateReportWidth}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    value={multiledgercodes}
                    onChange={handleChange}
                    variant="standard"
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <p className={Styles.dealNameStyle}>Ledger Code</p>
                        );
                      }

                      return selected
                        .map(
                          (x) =>
                            x?.systemCode + " " + "(" + x?.purpose?.name + ")"
                        )
                        .join(", ");
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <ListSubheader sx={listSubheaderStyle}>
                      <TextField
                        size="medium"
                        placeholder="Search Code"
                        fullWidth
                        value={filter}
                        sx={{
                          input: searchCodeStyle2,
                        }}
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {filter == "" && (
                      <MenuItem
                        value={"All"}
                        onClick={() => setSelectedAll(!selectedAll)}
                        sx={MenuItemSelectAll}
                      >
                        <Checkbox size="small" checked={selectedAll} />
                        <ListItemText primary={"SELECT ALL"} />
                      </MenuItem>
                    )}

                    {ledgerCodeOptions.length > 0
                      ? [...ledgerCodeOptions].map((variant, index) => {
                        return (
                          <MenuItem
                            key={variant.id}
                            value={variant}
                            sx={GenerateReportHeight}
                            className="muidropdown"
                          >
                            <Checkbox
                              size="small"
                              checked={
                                multiledgercodes.findIndex(
                                  (item) => item.id === variant.id
                                ) >= 0
                              }
                            />
                            <ListItemText
                              primary={
                                variant?.systemCode +
                                " " +
                                "(" +
                                variant?.purpose?.name +
                                ")"
                              }
                            />
                          </MenuItem>
                        );
                      })
                      : uploadReportState.ledgerCodes.map((variant, index) => {
                        return (
                          <MenuItem
                            key={variant.id}
                            value={variant}
                            sx={GenerateReportHeight}
                            className="muidropdown"
                          >
                            <Checkbox
                              size="small"
                              checked={
                                multiledgercodes.findIndex(
                                  (item) => item.id === variant.id
                                ) >= 0
                              }
                            />
                            <ListItemText
                              primary={
                                variant?.systemCode +
                                " " +
                                "(" +
                                variant?.purpose?.name +
                                ")"
                              }
                            />
                          </MenuItem>
                        );
                      })}
                  </Select>
                </TextWrapperComponent>
              )}
            </Grid>
          ) : (
          <Grid item xs={4}>
            {allBranchesLists.length > 0 && (
              <TextWrapperComponent text="Branch">
                <Select
                  multiple
                  sx={GenerateReportWidth}
                  IconComponent={ExpandMoreIcon}
                  displayEmpty
                  value={multiBranch}
                  onChange={handleBranchChange}
                  variant="standard"
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <p className={Styles.dealNameStyle}>Branch</p>;
                    }

                    return selected.map((x) => x?.branch).join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <ListSubheader sx={listSubheaderStyle}>
                    <TextField
                      size="medium"
                      placeholder="Search Branch"
                      fullWidth
                      value={filter}
                      sx={{
                        input: searchCodeStyle2,
                      }}
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {filter == "" && (
                    <MenuItem
                      value={"All"}
                      onClick={() => setSelectedAll(!selectedAll)}
                      sx={MenuItemSelectAll}
                    >
                      <Checkbox size="small" checked={selectedAll} />
                      <ListItemText primary={"SELECT ALL"} />
                    </MenuItem>
                  )}

                  {branchOptions.length > 0
                    ? [...branchOptions].map((variant, index) => {
                      return (
                        <MenuItem
                          key={variant.branch}
                          value={variant}
                          sx={GenerateReportHeight}
                          className="muidropdown"
                        >
                          <Checkbox
                            size="small"
                            checked={
                              multiBranch.findIndex(
                                (item) => item.branch === variant.branch
                              ) >= 0
                            }
                          />
                          <ListItemText primary={variant?.branch} />
                        </MenuItem>
                      );
                    })
                    : uploadReportState.allBranches.map((variant, index) => {
                      return (
                        <MenuItem
                          key={variant.branch}
                          value={variant}
                          sx={GenerateReportHeight}
                          className="muidropdown"
                        >
                          <Checkbox
                            size="small"
                            checked={
                              multiBranch.findIndex(
                                (item) => item.branch === variant.branch
                              ) >= 0
                            }
                          />
                          <ListItemText primary={variant?.branch} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </TextWrapperComponent>
            )}
          </Grid>
        )} */}
        {/* START DATE */}
        <Grid item xs={2}>
          <TextWrapperComponent text="Start Date">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                inputFormat="DD-MM-YYYY"
                value={startDate}
                PopperProps={{
                  sx: GenerateReportDatePickerPopper,
                }}
                InputProps={{
                  sx: GenerateReportDatePickerInput,
                }}
                onChange={(newValue) => {
                  setstartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </LocalizationProvider>
          </TextWrapperComponent>
        </Grid>
        {/* END DATE */}
        <Grid item xs={2}>
          <TextWrapperComponent text="End Date">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                inputFormat="DD-MM-YYYY"
                value={endDate}
                PopperProps={{
                  sx: GenerateReportDatePickerPopper,
                }}
                InputProps={{
                  sx: GenerateReportDatePickerInput,
                }}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </LocalizationProvider>
          </TextWrapperComponent>
        </Grid>

      </Grid>

      {/* SUBMIT BUTTON */}
      <br></br>
      <div className={Styles.viewDetailsMainButtonDiv}>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          disabled={SerachReportState["loading"] ? true : trailStatus}
          onClick={handleGenerateReport}
          sx={GenerateReportButton}
          type="submit"
        >
          {SerachReportState["loading"] ? "Loading..." : "Generate Report"}
        </Button>
      </div>
      {SerachReportState["reportData"] === null ? null : (
        <>
          {SerachReportState["reportData"]["message"] ===
            "No Data Found" ? null : (
            <Box sx={GenerateReportBoxContainer}>
              <Grid container>
                <Grid item xs={12}>
                  <DocumentHeader title={CompHeading}></DocumentHeader>
                  <div className={Styles.CommanPadding}>
                    <GeneratedReportFileV2
                      fileName={
                        SerachReportState["reportData"]["fileName"] ||
                        SerachReportState["reportData"]["filename"]
                      }
                      id={SerachReportState["reportData"]["file"]}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
}
