import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import Pagination from "../Pagination/Pagination";
import { colorStyle } from "./TableCSS";

const SPOCList = ({ data, setList, allItems = [] }) => {
  const [page, setPage] = useState(1);

  const nextPageHandler = () => {
    setPage(page + 1);
  };

  const prevPageHandler = () => {
    setPage(page - 1);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    borderRight: "1px solid #EBEEF0",
    padding: "6px",
  }));

  console.log(data);

  const handleUnchecked = (id) => (e) => {
    console.log("unc", id, e);
    let unCheckItems = allItems.map((item) => {
      if (item.id == id) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    console.log({ unCheckItems });

    setList(unCheckItems);
  };
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell component="th" scope="row">
                    <Checkbox
                      checked={item.checked}
                      style={colorStyle}
                      onChange={handleUnchecked(item.id)}
                    />
                    {item.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item.email}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item.phoneNumber}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SPOCList;
