import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Paths from '../../components/constants/Path';
import Layout from '../../components/Layout/Layout';
import { styled } from "@mui/material/styles";
import SecoundaryButton from '../../components/Buttons/SecoundaryButton';
import EditOriginatorDealistTable from '../../components/Table/EditOriginatorDealistTable';
import L1KYCDocumentsRequestDealistTable from '../../components/Table/L1KYCDocumentsRequestDealistTable';
import searchIcon from "../../assets/icons/searchIcon.svg"
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../Hooks/useDebounce';
import { getAllDeals } from '../../redux/Deals/dealSlice';
import { useEffect } from 'react';
import { showErrorAlertAction } from '../../redux/app/appSlice';
import { FilterConstants } from '../../components/constants/FiltersConstant';

const CREDITBUREAUREPORTDEALIST = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setFilter] = useState(0);
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (status) => {
    if(status == "PEL" || status == "PCHFL"){
      setEntityFilter(status);
      setFilterName("")
    }else{
      setFilterName(status);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    dispatch(getAllDeals({ limit, currentPage, debouncedSearch, filterName, source: "PHFL", entityFilter, isCreditBureauCheckDone: true }));
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: "Deal List",
    },
  ];

  const filterList = [
    {
      status: FilterConstants.ALL,
    },
    {
      status: FilterConstants.PEL,
    },
    {
      status: FilterConstants.PCHFL,
    },
  ];

  let history = useHistory();
  const clickHandlerToViewDetails = (deal) => {
    console.log(deal)
    return history.push({
      pathname: `/deal/${deal._id || deal}/details/${deal?.poolUploadFinal?.uniqueCode}/requestkycdocuments`,
      state: deal,
    });
  };

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
            
          </Stack>

          <Divider />
          <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          >
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item.status || entityFilter == item?.status ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item.status || entityFilter == item?.status ? "white" : "black",
                        backgroundColor:
                          filterName === item.status || entityFilter == item?.status ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item.status)}
                    >
                      {item.status}
                    </StyledButton>
                  </>
                ))}
            </div>

            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                    {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>
          {dealState["loading"] ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
            </div>
          ) : (
            <Box style={{ height: "calc( 100% - 64px)" }}>
              <L1KYCDocumentsRequestDealistTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                filterName={filterName}
                page={currentPage}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Credit Bureau Scrub Report Status",
                  "Validation Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
                statusList={filterList}
              />
            </Box>
          )}
        </>
      </Box>

    </Layout>
  )

}

export default CREDITBUREAUREPORTDEALIST;