import styles from "./ViewDownloadDeleteComp.module.css";

// import View from "../../assets/View.png";
// import Delete from "../../assets/Delete.png";
import Eye from "../../assets/icons/eye.svg";
import Download from "../../assets/icons/download.svg";
import FileIcon from "../../assets/icons/Icon3.svg";
import { CSVLink, CSVDownload } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { downloadImageReports } from "../../redux/Phase2/SearchReports";
import { CircularProgress } from "@mui/material";
import { useState } from "react";

const ViewDownloadDeleteComp = ({
  showEye = true,
  id,
  fileName,
  reportData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  console.log({ reportData });
  const downloadExcel = ({ id, fileName }) => {
    dispatch(downloadImageReports({ id, fileName, setLoading }));
  };

  console.log({ loading });

  return (
    <div
      className={styles.wrapper}
      onClick={loading ? () => {} : () => downloadExcel({ id, fileName })}
    >
      <div className={styles.filenameWrapper}>
        <img src={FileIcon} />

        {!loading && fileName && (
          <p>
            {fileName.length > 20 ? fileName.slice(0, 20) + "..." : fileName}
          </p>
        )}
        {loading && (
          <marquee>
            <p>Please Wait your file is downloading...</p>
          </marquee>
        )}
      </div>
      <div className={styles.iconsWrapper}>
        <div className={styles.singleIconWrapper}>
          {loading ? (
            <div>
              <CircularProgress size={20} />
            </div>
          ) : (
            <div>
              <img src={Download} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewDownloadDeleteComp;
