import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const SpocTable = ({ data }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      width: "380px",
      color: "#5E7387",
      fontSize: "14px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


  const TableData = [
    {
      id: 1,
      Name: "Foram Chandra",
      Email: "foram.chandra@bajaj.com",
      ContactNumber: "973452678",
    },
    {
      id: 5,
      Name: "Jhanvi Soni",
      Email: "jhanvi.soni@piramal.com",
      ContactNumber: "973452678",
    },
  ];

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>{data[0]?.email === undefined || data[0]?.email === null || data[0]?.email === "" ? null : "Email Address"}</StyledTableCell>
              <StyledTableCell>{data[0]?.phoneNumber === undefined || data[0]?.phoneNumber === null || data[0]?.phoneNumber === "" ? null : "Contact Number"}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => {
                return (
                  <TableRow key={item._id}>
                    <StyledTableCell component="th" scope="row">
                      {item?.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item?.email}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item?.phoneNumber}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SpocTable;

