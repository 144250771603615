import Delete from "../../assets/icons/blueDelete.svg";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  ChooseFileComponent,
  SelectedFileComponent,
} from "./NewChooseFileComp";
import { useState } from "react";
import styles from "./Wrapper.module.css";
import {
  uploadKYCDoc,
  deleteDoc,
} from "../../redux/originator/originatorSlice";
import { uploadDocs } from "../../redux/L1/editOriginatorSlice";
import { uploadValidationFile } from "../../redux/L1/secondaryCheckSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showErrorAlertAction } from "../../redux/app/appSlice";

const UploadTemplate = ({ setdata, docName, isdisabled = false }) => {
  const [myFiles, setMyFiles] = useState([]);
  const [state, setState] = useState(null);

  let dispatch = useDispatch();
  let originatorState = useSelector((state) => state.originator);
  let editOriginatorState = useSelector((state) => state.editOriginator);
  let secondaryCheckState = useSelector((state) => state.secondaryCheck);

  const onDrop = useCallback((acceptedFiles) => {
    let formData = new FormData();
    console.log(acceptedFiles);
    if (acceptedFiles.length < 1) {
      dispatch(showErrorAlertAction("Please select a supported format file"));
      return;
    }
    // setdata(true);
    formData.set("file", acceptedFiles[0]);


    dispatch(
      uploadKYCDoc({
        formData: formData.getAll("file")[0],
        fileName: docName,
      })
    );

    setState(acceptedFiles[0]);
  }, []);

  const removeFile = () => {
    setState(null);
    dispatch(deleteDoc(docName));
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      pdf: [".pdf"],
      doc: [".jpg"],
      jpeg: [".jpeg"],
      png: [".png"],
    },
  });

  console.log({ getRootProps, getInputProps, acceptedFiles, inputRef });
  console.log(getRootProps());
  console.log({ getInputProps: getInputProps() });

  //? useEffect
  useEffect(() => {
    if (originatorState[docName]) {
      setState(originatorState[docName]);
      setdata(false);
    }
  }, [originatorState[docName]]);

  useEffect(() => {
    if (editOriginatorState[docName]) {
      setState(editOriginatorState[docName]);
      setdata(false);
    }
  }, [editOriginatorState[docName]]);

  useEffect(() => {
    if (secondaryCheckState[docName]) {
      setState(secondaryCheckState[docName]);
      setdata(false);
    }
  }, [secondaryCheckState[docName]]);

  const Test = () => {
    return (
      <>
        <input
          {...getInputProps()}
          disabled={isdisabled === true ? true : false}
        />
        {state.fileName == null ? (
          <div className={styles.wrapper}>
            <div className={styles.DetailsWrapper}>
              <div className={styles.together}>
                <p>Loading...</p>
              </div>
            </div>
          </div>
        ) : (
          <SelectedFileComponent
            fileName={state.fileName}
            onClickHandler={removeFile}
          />
        )}
      </>
    );
  };

  const FileComponent =
    state && state != null ? <Test /> : console.log("state is null");

  console.log({ state });

  return (
    <div>
      {state && state != null ? (
        FileComponent
      ) : (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <ChooseFileComponent />
        </div>
      )}
    </div>
  );
};

export default UploadTemplate;
