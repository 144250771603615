import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  checkedPages: [
    { pageName: "creditBureauScrubReport", isChecked: false },
    { pageName: "CIBILScore", isChecked: false },
    { pageName: "CIBILScoreUpdation", isChecked: false },
    { pageName: "poolValidation", isChecked: false },
    { pageName: "requestKYCDocuments", isChecked: false },
  ],
};

const kycDocumentsRequestSlice = createSlice({
  name: "kycDocumentsRequestSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
       ;
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
  },
});

export const { pageStatusAction } = kycDocumentsRequestSlice.actions;

export const kycDocumentsRequestReducer = kycDocumentsRequestSlice.reducer;
