import React, { useEffect, useState } from "react";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import Layout from "../../components/Layout/Layout";
import Image from "../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import Histories from "../../components/History/Histories";
import { useSelector, useDispatch } from "react-redux";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../redux/Deals/dealSlice";
import { useParams, useHistory } from "react-router-dom";
import QueriesPage from "../../components/Queries/Queries";
import PortfolioFinalLoanDeatils from "./PortfolioFinalLoanDeatils";
import Paths from "../../components/constants/Path";
import Loader from "../../utils/Loader";
import ReadOnlyFinalLoanDetails from "./ReadOnlyFinalLoanDetails";
import { setStatus } from "../../redux/Loans/loanSlice";

const PortfolioFinalViewDealDeatils = () => {
  const dealState = useSelector((state) => state.deal);

  const params = useParams();
  let { dealId } = params;
  const dispatch = useDispatch();

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.PORTFOLIOFINALDEALLIST,
      pathName: "Deal",
    },

    {
      pathName: dealState?.name,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let TabList=[
    {
      name: "Loan",
      component:
        userData.roles[0] !== "L1" ? (
          <ReadOnlyFinalLoanDetails />
        ) : (
          <PortfolioFinalLoanDeatils />
        ),
      index: 0,
    },
    ,
  ]

  if(userData?.roles.length == 1 ){
    TabList.push(
      {
        name: "Queries",
        index: 1,
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
      { name: "History", index: 3, component: <Histories id={dealId} /> }
    )
  }

  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDealState());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setStatus(0));
    };
  }, []);
  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      {dealState?.loading ? (
        <Loader />
      ) : (
        <TabsLayout
          formName={dealState?.name}
          image={Image}
          icon={icon}
          iconColor={"#DBE3F8"}
          TabsList={TabList}
        ></TabsLayout>
      )}
    </Layout>
  );
};

export default PortfolioFinalViewDealDeatils;
