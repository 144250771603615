import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DocumentHeader } from "../../../components/DocumentHeaderWrapper/DocumentHeader";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import TabFooter from "../../../components/Tabs/TabFooter/TabFooter";
import { pageStatusAction } from "../../../redux/Phase2/UploadReportSlice";
import { getAMLTemplate } from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import Loader from "../../../utils/Loader";

const TemplateDownload = ({ nextTabHandler }) => {
  const [status, setStatus] = useState(true);
  const [templateData, setTemplateData] = useState("");
  const [newloader, setNewLoader] = useState(true);

  let dispatch = useDispatch();

  const onClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "template" }));
    nextTabHandler();
  };

  useEffect(() => {
    dispatch(getAMLTemplate({ type: "MONTHLY_UPLOAD" })).then((res) => {
      setTemplateData(res?.payload?.data);
      setNewLoader(false);
      setStatus(false);
    });
  }, []);

  var updateDate = templateData?.updatedAt?.slice(0, 10);
  var newUpdateDate = updateDate?.replace("-", "");
  var updatedAt = newUpdateDate?.replace("-", "");
  var updateHistory = moment(updatedAt, "YYYYMMDD").fromNow();

  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title={`Download Monthly Upload Template (Last Updated: ${updateHistory})`}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              />
            }
          ></DocumentHeader>
          <div
            style={{
              padding: "24px",
            }}
          >
            <ViewDownloadDeleteComp
              showEye={false}
              fileName={templateData?.file?.filename}
              id={templateData?.file?._id}
            ></ViewDownloadDeleteComp>
          </div>

          <TabFooter
            label="Next"
            isBtnDisable={status}
            endIcon={<DoneIcon />}
            onclickHandler={onClickHandler}
          />
        </>
      )}
    </>
  );
};

export default TemplateDownload;
