import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Delete from "../../../assets/icons/blueDelete.svg";
import Download from "../../../assets/icons/downloadIcon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Box, Button, Stack } from "@mui/material";
import styles from "./UploadReports.module.css";
import { useDispatch } from 'react-redux';
import { getDownloadImageById } from "../../../redux/Phase2/UploadReportSlice";
import axios from 'axios';
import { downloadImageReports } from "../../../redux/Phase2/SearchReports";

export const GeneratedReportFileV2 = ({ fileName, id }) => {
    const dispatch = useDispatch();

    const downloadExcel = async ({ id, fileName }) => {
        dispatch(downloadImageReports({ id, fileName, isDelete: true })).unwrap();
        return;
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.DetailsWrapper}>
                    <div className={styles.together}>
                        <CheckCircleIcon
                            sx={{ color: "#41C66E", width: "20px", height: "20px" }}
                        ></CheckCircleIcon>
                        <div padding="10px"></div>
                        <p>{fileName}</p>
                    </div>
                </div>
                <div className={styles.iconWrapper} >
                    <div
                        onClick={() => downloadExcel({ id, fileName })}
                        style={{
                            pointer: "cursor",
                        }}
                    >
                        <img src={Download} />
                    </div>
                </div>
            </div>
        </>
    );
};
