import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ChooseFileComponent, SelectedFileComponent } from "./UploadComponents";
import styles from "./Wrapper.module.css";
import { useDispatch } from "react-redux";
import { uploadDocuments } from "../../redux/originator/originatorSlice";
import Loader from "./../../utils/Loader";
import {
  uploadDealDocuments,
  uploadMultipleDocuments,
} from "../../redux/Deals/dealSlice";
import { showErrorAlertAction } from "../../redux/app/appSlice";

const UploadMultipleHandler = ({
  setIds = () => {},
  setdata = () => {},
  docName = "",
  isdisabled = false,
  index = 0,
  data = {},
  section,
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (
      //check if data type is Object
      data !== null &&
      typeof data === "object" &&
      Object.keys(data)?.length > 0
    ) {
      // create dummy file object
      const dummyFile = new File([""], data?.fileName);
      setFiles([dummyFile]);
    }
  }, [data]);

  const [isUploading, setIsUploading] = useState(false); // State for tracking upload progress
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!docName || acceptedFiles.length < 1) {
        dispatch(showErrorAlertAction("Please select a file type first"));
        return;
      }
      setIsUploading(true); // Start uploading, show loader
      let formData = new FormData();
      formData.set("file", acceptedFiles[0]);
      dispatch(
        uploadMultipleDocuments({
          formData: formData.getAll("file")[0],
          fileName: docName,
        })
      )
        .then((res) => {
          console.log(res.payload.data);
          setIds((data) => {
            let updatedValue = { ...data };
            // updatedValue[docName][index] = res.payload.data;
            // console.log({ updatedValue });
            // // return { ...data, [docName]: [...data[docName], res.payload.data] };
            // return updatedValue;

            console.log({
              temp: updatedValue[section],
              section: section,
            });

            updatedValue[section][docName][index] = res.payload.data;
            return updatedValue;
          });
        })
        .finally(() => {
          setIsUploading(false); // Upload finished, hide loader
        });

      setFiles([...files, acceptedFiles[0]]);
      setdata(true);
    },
    [files]
  );

  const removeFile = (fileToRemove) => {
    console.log("fileToRemove", fileToRemove);
    console.log("fileToRemove", fileToRemove.name);
    setFiles(files.filter((file) => file.name !== fileToRemove.name));
    setIds((data) => {
      let updatedValue = { ...data };
      // if (updatedValue[docName].length > 1) {
      //   // then also remove from the array
      //   updatedValue[docName].splice(index, 1);
      // } else {
      //   // then set it to null
      //   updatedValue[docName][index] = null;
      // }
      // return updatedValue;

      if (updatedValue[section][docName].length > 1) {
        // then also remove from the array
        updatedValue[section][docName].splice(index, 1);
      } else {
        // then set it to null
        updatedValue[section][docName][index] = null;
      }

      return updatedValue;
    });
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      pdf: [".pdf"],
      doc: [".doc"],
      docx: [".docx"],
      xls: [".xls"],
      xlsx: [".xlsx"],
      csv: [".csv"],
    },
  });

  return (
    <div>
      {files.map((file, index) => (
        <div key={index}>
          <input
            {...getInputProps()}
            disabled={isdisabled === true ? true : false}
          />
          {isUploading ? (
            <SelectedFileComponent
              fileName={file.name}
              isLoading={isUploading}
              onClickHandler={() => console.log("clicked")}
            />
          ) : (
            <SelectedFileComponent
              fileName={file.name}
              onClickHandler={() => removeFile(file)}
            />
          )}
        </div>
      ))}
      {files.length === 0 && (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <ChooseFileComponent />
        </div>
      )}
    </div>
  );
};

export default UploadMultipleHandler;
