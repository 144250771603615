import React from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import OutlineButton from "../../../../Buttons/OutlineButton";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Box, Grid, TextField } from "@mui/material";
import SPOCList from "../../../../Table/SPOCList";
import AddSPOCDetails from "../AddNewSpoc/AddNewSpoc";
import TextWrapperComponent from "../../../../FormComponents/TextWrapperComponent";
import AutocompleteComponent from "../../../../FormComponents/AutocompleteCompoent";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { Colors } from "../../../../../theme/theme";
import BasicSelect from "../../../TabSelectOptions/SelectTabOptions";
import { useEffect } from "react";
import DeleteIcon from "../../../../../assets/icons/delete_icon.svg";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  getOriginators,
  getOriginatorSpocs,
} from "../../../../../redux/Deals/dealSlice";
import { useSelector } from "react-redux";
import { clearOriginatorState } from "../../../../../redux/originator/originatorSlice";
import styles from "./AssignSpocForm.module.css"
import { selectAllBtn, listSubHeader } from "./AssignSpocFormCSS";

export default function AssignSpocForm({
  handleCheckAll,
  clickHandler,
  open,
  setOpen,
  handleSearchChange,
  list,
  allItems,
  setList,
  allApprovers,
  setApproversIds,
  originatorName,
  setOriginatorIds,
  allOriginatorNames,
}) {
  const OriginatorsList = [
    { _id: 2, name: "Anupam Pandey" },
    { _id: 3, name: "Purva Chandurkar" },
    { _id: 4, name: "Jhanvi Soni" },
  ];
  const defaultProps = {
    options: OriginatorsList,
    getOptionLabel: (option) => option.name,
  };
  // const [counter, setCounter] = useState(1);
  var dealState = useSelector((state) => state.deal);

  const [value, setValue] = useState([{ _id: "", name: "" }]);
  const [valueOriginator, setValueOriginator] = useState([
    { _id: "", name: "" },
  ]);
  const [menu, setMenu] = useState(allApprovers || OriginatorsList);
  const [menuOriginator, setMenuOriginator] = useState(allOriginatorNames);

  console.log({ menuOriginator });

  const handleAdd = () => {
    const abc = [...value, { _id: "", name: "" }];
    setValue(abc);
  };

  useEffect(() => {
    setApproversIds(value);
  }, [value]);

  useEffect(() => {
    setOriginatorIds(valueOriginator);
  }, [valueOriginator]);

  useEffect(() => {
    return () => {
      dispatch(clearOriginatorState());
    };
  }, []);

  

  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: 200,
  //       width: 250,
  //       background: "#EBEEF0",
  //     },
  //   },
  // };

  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  const handleDuplicate = () => {
    console.log(menu);
    let duplicate = [...menu];
    value.forEach((item) => {
      // console.log(item.id);
      if (duplicate.findIndex((o) => o.id === item.id) >= 0) {
        console.log("yes");
        duplicate = duplicate.filter((x) => x.id !== item.id);
        console.log(duplicate);
      } else {
        console.log("no");
        //   duplicate.push(item);
      }
      setMenu(duplicate);
    });
    console.log(duplicate);

    console.log(menu);
  };

  let { name, originator } = useSelector((state) => state.deal);
  const dispatch = useDispatch();

  const handleChange = (onChangeValue, i) => {
    console.log({ onChangeValue });
    console.log({ value: onChangeValue.props.value._id });
    let inputdata = [...value];
    console.log({ inputdata });
    inputdata[i]["_id"] = onChangeValue.props.value._id;
    inputdata[i]["name"] = onChangeValue.props.value.name;

    setValue(inputdata);
    console.log(value);
  };

  const handleChangeOriginator = (onChangeValue, i) => {
    console.log({ onChangeValue });
    console.log({ valueOriginator: onChangeValue.props.value._id });
    let inputdata = [...valueOriginator];
    console.log({ inputdata });
    inputdata[i]["_id"] = onChangeValue.props.value._id;
    inputdata[i]["name"] = onChangeValue.props.value.name;

    setValueOriginator(inputdata);
    console.log({ valueOriginator });
  };
  const handleDelete = (i) => {
    const deleteValue = [...value];
    deleteValue.splice(i, 1);
    setValue(deleteValue);
  };

  const [filter, setFilter] = useState("");

  return (
    <div>
      <div>
        <div className={styles.main}>
          <Stack direction="row" spacing={1}>
            <PeopleAltIcon sx={{ color: "#5E7387" }}></PeopleAltIcon>
            <TextWrapperComponent
              text={`Point of Contact · ${originatorName || ""}`}
            ></TextWrapperComponent>
          </Stack>
          <div className={styles.midDiv}>
            <div className={styles.selectAllBtnWrapper}>
              <Button
                sx={selectAllBtn}
                startIcon={<DoneIcon sx={{ color: "#365069" }} />}
                onClick={handleCheckAll}
              >
                Select All
              </Button>
              <OutlineButton
                text="Add New SPOC"
                showIcon={true}
                onClickHandler={clickHandler}
              ></OutlineButton>
            </div>

            <AddSPOCDetails
              open={open}
              clickHandler={clickHandler}
              setOpen={setOpen}
            />
          </div>
        </div>
        <ListSubheader
          disableSticky
          sx={listSubHeader}
        >
          <TextField
            size="small"
            placeholder="Search for SPOC Name, Email ID or Phone Number"
            fullWidth
            sx={{
              border: "0.5px solid #5E7387",
              borderRadius: "4px",
              input: {
                fontFamily: "Nunito",
                fontSize: "12px",
                fontWeight: "400",
                padding: "5px",
                height: "20px",
                color: "#8696A5",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#365069" }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleSearchChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <AddSPOCDetails
          open={open}
          clickHandler={clickHandler}
        ></AddSPOCDetails>

        {dealState.loading ? (
          <div className={styles.loaderWrapper}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
              width="200px"
            />
          </div>
        ) : (
          <SPOCList
            data={list}
            allItems={allItems}
            setList={setList}
          ></SPOCList>
        )}
      </div>
    </div>
  );
}
