import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";


const initialState = {
  checkedPages: [
    { pageName: "driveLink&ApprovedPool", isChecked: false },
    { pageName: "verificationDoc", isChecked: false },
  ],
};

export const submitKYCVerificationReport = createAsyncThunk(
  "submitKYCVerificationReport",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("file",values.file)
    formData.set("uniqueCode",values.uniqueCode) 
    try {
      const uploadData = await instance.post(
        "/pool-upload-service/kycVerification",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const getkycTemplate = createAsyncThunk(
  "getkycTemplate",
  async ( values,{ rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/master-service/kycTemplate`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const kycApproveRejectSlice = createSlice({
  name: "kycApproveRejectSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    clearPageStatusAction: (state, { payload }) => {
      state.checkedPages = [
        { pageName: "driveLink&ApprovedPool", isChecked: false },
        { pageName: "verificationDoc", isChecked: false },
      ];
    },
    updDocuments: (state, { payload }) => {
      state.loanValidation = payload.loanValidation
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
    deleteDocument: (state, { payload }) => {
      state[payload.docName] =
        state[payload.docName] &&
        state[payload.docName].filter((val, index) => index != payload.index);
    },
    extraReducer: (builder) => {
      builder.addCase(submitKYCVerificationReport.rejected, (state, { payload }) => {
        console.log({ payload });
        console.log(payload.response.data.message);
        state.loading = false;
        state.error = payload.response.data.message;
      });

      builder.addCase(getkycTemplate.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getkycTemplate.fulfilled, (state, payload) => {
        console.log({ payload });
        //  ;
        state.loading = false;
        state.allData = payload;
      });
      builder.addCase(getkycTemplate.rejected, (state, payload) => {
        console.log({ payload });
        state.loading = false;
        state.error = "Something went wrong";
      });
    }
  },
});

export const { pageStatusAction, clearPageStatusAction } = kycApproveRejectSlice.actions;

export const kycApproveRejectReducer = kycApproveRejectSlice.reducer;
