export const TextfieldStyle = {
  fontSize: "16px",
  fontWeight: "400",
};

export const TextFieldStyleCustomWidth = (customWidth) => {
  if (customWidth) {
    return {
      ...TextfieldStyle,
      width: "600px",
    };
  }
  return TextfieldStyle;
};

export const autoCompletePaper = {
  backgroundColor: "#EBEEF0",
  color: "#365069",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Nunito",
};
export const SelectComponentMenuProps = (compWidth) => {
  return {
    maxHeight: 200,
    width: compWidth,
    background: "#EBEEF0",
  };
};

export const SelectMaxMenuProps = {
  maxHeight: 200,
  width: 450,
  marginTop: 5,
  background: "#EBEEF0",
};
export const SelectMaxListSubHeader = {
  height: "40px",
  background: "#EBEEF0",
  margin: "10px 0px",
};
export const SelectMaxListSearchDealInput = {
  fontFamily: "Nunito",
  fontSize: "18px",
  fontWeight: "400",
  height: "7px",
  width: 360,
};
