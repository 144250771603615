export const Authenticate = (data, name) => {
  if (typeof window != undefined) {
    if (localStorage.getItem(name)) {
      localStorage.removeItem(name);
      localStorage.setItem(name, JSON.stringify(data));
    }
    localStorage.setItem(name, JSON.stringify(data));
  }
};

export const isAuthenticated = (name) => {
  if (typeof window == undefined) {
    return false;
  }
  if (localStorage.getItem(name)) {
    return JSON.parse(localStorage.getItem(name));
  } else {
    return false;
  }
};
