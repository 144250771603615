import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AssetQualityFilters from "../../components/Tabs/LoanTabs/AssetQualityFilters/AssetQualityFilters";
import GeographicalFilters from "../../components/Tabs/LoanTabs/GeoGraphicalFilters/GeographicalFilters";
import LoanPropertiesFilters from "../../components/Tabs/LoanTabs/LoanPropertiesFilter/LoanPropertiesFilters";
import LoanTenureFilters from "../../components/Tabs/LoanTabs/LoanTenureFilters/LoanTenureFilters";
import Tabs from "../../components/Tabs/Tabs";
import { breadCrums } from "../../components/constants/BreadCrumbs";
import Paths from "../../components/constants/Path";
import {
  clearLoanFilterState,
  getloanFilterData,
  setStatus,
} from "../../redux/Loans/loanSlice";

const LoanForm = () => {
  const dealState = useSelector((state) => state.deal);
  const loanState = useSelector((state) => state.loan);
  const loanType = dealState?.loanTypeData?.code;
  let params = useParams();
  let tabs = [
    {
      tabName: "Asset Quality Filters",
      comp: AssetQualityFilters,
      heading: `Asset Quality Filters for - ${dealState?.name}`,
    },
    {
      tabName: `Geographical Filters`,
      comp: GeographicalFilters,
      heading: `Geographical Filters for - ${dealState?.name}`,
    },
    {
      tabName: `Loan Tenure Filters`,
      comp: LoanTenureFilters,
      heading: `Loan Tenure Filters for - ${dealState?.name}`,
    },
  ];

  if (loanType !== "MFI") {
    tabs.push({
      tabName: `Loan Properties Filters`,
      comp: LoanPropertiesFilters,
      heading: `Loan Properties Filters for - ${dealState?.name}`,
    });
  }

  useEffect(() => {
    dispatch(setStatus(3));
  }, []);

  useEffect(() => {
    dispatch(getloanFilterData(params.dealId));
    return () => {
      dispatch(clearLoanFilterState());
    };
  }, []);

  useEffect(() => {
    dispatch(setStatus(3));
  }, []);
  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.CREATE_ORIGINATOR,
      pathName: "Orginator Profile",
    },
    {
      pathName: "Create Originator Profile",
    },
  ];

  let dispatch = useDispatch();

  let originatorState = useSelector((state) => state.loan);

  return (
    <>
      {loanState?.loading ? (
        <div className="loader"></div>
      ) : (
        <Tabs
          tabs={tabs}
          status={originatorState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      )}
    </>
  );
};

export default LoanForm;
