import { useState } from "react";

//? styles
import styles from "./ViewThreadComp.module.css";

//? components
import Thread from "../ThreadComp/ThreadComp";
import IconButton from "../IconButton/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ViewThreadComp = ({
  id,
  replies,
  replyHandler,
  inputHandler,
  buttonHandler,
  show,
  setShow,
}) => {
  const [viewThreads, setViewThreads] = useState(false);
  console.log("replies", replies);

  const viewThreadHandler = () => {
    setViewThreads(!viewThreads);
    setShow(false);
  };

  let Threads =
    replies &&
    replies.map((reply, index) => {
      console.log({ reply });
      return (
        <Thread
          key={index}
          id={reply?._id}
          raisedById={reply?.raisedbyId}
          repliedSelf={reply?.selfReplied}
          name={reply?.user.name}
          query={reply?.query}
          isFirstThread={index == 0 && replies.length != 1 ? true : false}
          isLastThread={replies.length - 1 === index}
          viewThreadHandler={viewThreadHandler}
          replyHandler={replyHandler}
          inputHandler={inputHandler}
          buttonHandler={buttonHandler}
          show={show}
          setShow={setShow}
        />
      );
    });

  return (
    <div style={{ marginBottom: "25px" }}>
      {viewThreads
        ? Threads
        : replies &&
          replies.length > 0 && (
            <div className={styles.viewThreadWrapper}>
              <div style={{ width: "11%" }}></div>
              <div className={styles.viewThread}>
                <p className={styles.viewThreadText}>View Thread</p>

                <IconButton
                  buttonHandler={viewThreadHandler}
                  icon={<ExpandMoreIcon />}
                />
              </div>
            </div>
          )}
    </div>
  );
};

export default ViewThreadComp;
