import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import { FormDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DealDetails from "../../../components/Tabs/UpdPoolTabs/DealDetails";
import LoanUploadSummary from "../../../components/Tabs/UpdPoolTabs/LoanUploadSummary";
import LoanUploadTemplate from "../../../components/Tabs/UpdPoolTabs/LoanUploadTemplate";
import { Colors } from "../../../theme/theme";
import Image from "../../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import Tabs from "../../../components/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";

const OriginatorDealDetails = () => {
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  let loading = useSelector((state) => state.deal.loading);
  

  const dispatch = useDispatch();

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );
  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);
  const tabs = [
    {
      tabName: "Deal Details",
      index: 0,
      comp: DealDetails,
      heading: `Deal Details for - ${dealState.name}`,
    },
    {
      tabName: "Loan Upload Template",
      index: 1,
      comp: LoanUploadTemplate,
      heading: "Loan Upload Template for - " + dealState?.name,
    },
  ];
  const breadCrumbs = [
    {
      path: "/originatorhome",
      pathName: "Home",
    },
    {
      path: "/originatordealist",
      pathName: "Deals List",
    },
    {
      pathName: "View Deal Details",
    },
  ];

  let poolUploadState = useSelector((state) => state.poolUpload);

  console.log({ loading });

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        {loading ? (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
          </div>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                paddingLeft: "14px",
                backgroundColor: Colors.light_green,
                display: "flex",
                justifyContent: "space-between",
                height: "128px",
              }}
            >
              <div>
                <FormDetails
                  icon={icon}
                  iconColor={"#DBE3F8"}
                  formName={dealState?.name}
                />
                <Box paddingTop="16px"></Box>
              </div>
              <div
                style={{
                  width: "20%",
                  height: "100%",
                  padding: "20px 10px 0px",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={Image}
                  alt="image"
                />
              </div>
            </Box>
            <Box height="calc(100% - 172px)">
              <Tabs
                formName={dealState?.name}
                tabs={tabs}
                status={poolUploadState["checkedPages"]}
              />
            </Box>
          </>
        )}
      </Layout>
    </>
  );
};

export default OriginatorDealDetails;
