import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  notificationData: null,
  loading: false,
  error: null,
  success: null,
};

export const fetchNotificationData = createAsyncThunk(
  "fetchNotificationData",
  async (rejectWithValue) => {
    try {
      const notificationsData = await instance.get(
        "/utility-service/notification"
      );
      return notificationsData;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const NotificationsSlice = createSlice({
  name: "NotificationsSlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNotificationData.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.notificationData = payload.data.data.data;
    });
    builder.addCase(fetchNotificationData.rejected, (state, { payload }) => {
      state.loading = false;
      state.notificationData = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
  },
});

export const { clearSucessAndErrorAction } = NotificationsSlice.actions;

export const notificationsReducer = NotificationsSlice.reducer;
