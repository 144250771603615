import { Colors } from "../../../theme/theme";

export const ChooseFileBoxStyle = {
  boxShadow: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  borderRadius: "10px",
  borderWidth: "1px",
  minHeight: "70px",
};
export const ChooseFileStackStyle = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
export const ChooseFileTypographyStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: Colors.piraml_light_blue,
};
export const ChooseFileTypographySupportedFile = {
  fontSize: "12px",
  fontWeight: 200,
  color: Colors.Piramal_Blue_PB_60,
};
export const selectedFileComponentCheckCircle = {
  color: "#41C66E",
  width: "20px",
  height: "20px",
};
export const AddMoreFileComponentBoxStyle = {
  paddingLeft: "2px",
  alignItems: "flex-end",
  justifyContent: "flex-end",
};
export const AddMoreFileComponentBoxTwoStyle = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
};
export const AddMoreFileComponentButtonStyle = {
  fontSize: "12px",
  fontWeight: "700",
  color: Colors.piraml_light_blue,
  textTransform: "none",
}
