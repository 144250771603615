import React from "react";
import SecoundaryButton from "../../Buttons/SecoundaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { Colors } from "../../../theme/theme";
//? styles
import Styles from "./TabFooter.module.css";
import DoneIcon from "@mui/icons-material/Done";
import { Stack, Button, Box } from "@mui/material";
import OutlineButton from "../../Buttons/OutlineButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  skipPageAction,
  pageStatusAction,
} from "../../../redux/originator/originatorSlice";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { useEffect } from "react";

const TabFooter = ({
  onclickHandler,
  showSubmitButton = false,
  submitBtnText = "Submit for Approval",
  isBtnDisable = true,
  showSkinBtn = false,
  skipBtnClickHandler,
  btnLoading = false,
  label = "Next",
  endIcon = <ArrowForwardIosIcon />,
  isClose = false,
  handleClose,
  pageStatus = false,
  pageName = "",
}) => {
  let dispatch = useDispatch();
  let originatorState = useSelector((state) => state.originator);

  const skipFunction = (e) => {
    e.preventDefault();
    dispatch(skipPageAction(originatorState));
    dispatch(pageStatus({ pageName }));
    skipBtnClickHandler();
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "64px",
        background: " #ffffff",
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Stack
        padding={2}
        direction="row"
        justifyContent={
          showSkinBtn
            ? " space-between"
            : isClose
            ? "space-between"
            : "flex-end"
        }
        alignItems={
          showSkinBtn
            ? " space-between"
            : isClose
            ? "space-between"
            : "flex-end"
        }
        display="flex"
      >
        {showSkinBtn ? (
          <Box>
            <Button
              onClick={skipFunction}
              variant="outlined"
              sx={{
                backgroundColor: Colors.white,
                color: Colors.black,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: Colors.white,
                },
                "&:disabled": {
                  backgroundColor: Colors.white,
                  color: "white",
                },
              }}
              type="submit"
            >
              Skip
            </Button>
          </Box>
        ) : (
          <>
            {isClose ? (
              <Box>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{
                    backgroundColor: Colors.white,
                    color: Colors.black,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: Colors.white,
                    },
                    "&:disabled": {
                      backgroundColor: Colors.white,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Close
                </Button>
              </Box>
            ) : null}
          </>
        )}

        {showSubmitButton ? (
          <>
            {btnLoading ? (
              <Button
                onClick={onclickHandler}
                variant="contained"
                disabled={isBtnDisable}
                sx={{
                  backgroundColor: Colors.piramal_orange,
                  color: Colors.white,

                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: Colors.piramal_orange,
                  },
                  "&:disabled": {
                    backgroundColor: "#FAC3B3",
                    color: "white",
                  },
                }}
                type="submit"
              >
                Loading...
              </Button>
            ) : (
              <Button
                endIcon={<DoneIcon />}
                onClick={onclickHandler}
                variant="contained"
                disabled={isBtnDisable}
                sx={{
                  backgroundColor: Colors.piramal_orange,
                  color: Colors.white,

                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: Colors.piramal_orange,
                  },
                  "&:disabled": {
                    backgroundColor: "#FAC3B3",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {submitBtnText}
              </Button>
            )}
          </>
        ) : (
          <Button
            endIcon={endIcon}
            onClick={onclickHandler}
            variant="contained"
            disabled={isBtnDisable}
            sx={{
              backgroundColor: Colors.piramal_orange,
              color: Colors.white,

              textTransform: "none",
              "&:hover": {
                backgroundColor: Colors.piramal_orange,
              },
              "&:disabled": {
                backgroundColor: "#FAC3B3",
                color: "white",
              },
            }}
            type="submit"
          >
            {label}
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default TabFooter;
