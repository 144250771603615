import { Colors } from "../../../theme/theme";

export const documentHeaderStack = {
  flexDirection: "row",
};
export const descriptionIconStyle = {
  color: "#5E7387",
  width: "20px",
  height: "20px",
};
export const documnetHeaderTypo = {
  color: "#5E7387",
  fontWeight: "500",
  fontSize: "14px",
  marginTop: "0px !important",
  marginLeft: "8px !important",
};
export const profileRadioGroup = {
  "& .MuiSvgIcon-root": {
    fontSize: 20,
    color: Colors.piramal_blue,
  },

  ".MuiFormControlLabel-label": {
    fontSize: 14,
    color: "#5E7387",
  },
};
export const profileTabDocumentHeader = {
  width: "100%",
  height: "100%",
};

export const btnStyle = {
  backgroundColor: Colors.piramal_orange,
  color: Colors.white,

  textTransform: "none",
  "&:hover": {
    backgroundColor: Colors.piramal_orange,
  },
  "&:disabled": {
    backgroundColor: "#FAC3B3",
    color: "white",
  },
};
