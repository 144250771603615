import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import NotFound from "../assets/images/not_found.svg";
import PrimaryButton from "../components/Buttons/PrimaryButton";

export default function Unauthorized() {
  const history = useHistory();
  return (
    <Container maxWidth="sm">
      <Box mt={10} textAlign="center">
        {/* <Typography variant="h4" color="error">
          Unauthorized Access
        </Typography> */}
        <img src={NotFound} alt="Not Found" />
        {/* Go Back */}
        {/* <Button onClick={() => history.goBack()}>Go Back</Button> */}
        <br />
        <br />
        <Box marginLeft={"100px"}>
          <PrimaryButton
            onClickHandler={() => history.goBack()}
            text={"Go Back"}
          />
        </Box>
      </Box>
    </Container>
  );
}
