import React from "react";
import Layout from "../../components/Layout/Layout";
import Image from "../../assets/images/Group 42722.svg";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import Histories from "../../components/History/Histories";
import DescriptionIcon from "@mui/icons-material/Description";
import Tabs from "../../components/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import CreditBureauScrubReport from "../../components/Tabs/L1KYCDocumentsRequestTabs/CreditBureauScrubReport";
import RequestKYCDocuments from "../../components/Tabs/L1KYCDocumentsRequestTabs/RequestKYCDocuments";
import CIBILScoreValidation from "../../components/Tabs/CreditBureauUpdTab/CIBILScoreValidation";
import { useHistory, useParams } from "react-router-dom";
import { getSingleDealDetails } from "../../redux/Deals/dealSlice";
import { useEffect } from "react";
import { getCreditBureauScrubReport } from "../../redux/originator/creditBureauSlice";
import CibileScoreUpdation from "../../components/Tabs/CreditBureauUpdTab/CibilScoreUpdation";
import PoolValidation from "./../../components/Tabs/CreditBureauUpdTab/PoolValidation";
import { setStatus } from "../../redux/Loans/loanSlice";
import Paths from "./../../components/constants/Path"
import { TabList } from "@mui/lab";

const KYCDocumentsRequest = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const dealInfo = history.location.state;

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let KYCDocumentsRequestState = useSelector(
    (state) => state.kycDocumentsRequest
  );
  let creditBureauState = useSelector((state) => state.creditScrub);
  let dealState = useSelector((state) => state.deal);
  console.log({ creditBureauState });

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.CREDITBUREAUREPORTDEALIST,
      pathName: "Deal List",
    },
    {
      pathName: dealInfo?.name,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  const getTabStatus = () => {
    return (
      !creditBureauState?.creditBureauScrub?.cibilScoreSuccessFile &&
      creditBureauState?.creditBureauScrub?.cibilScoreSuccessFile?.filename !==
        ""
    );
  };

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
    console.log({ params });

    dispatch(
      getCreditBureauScrubReport({
        dealId: params.dealId,
        uniqueCode: params.uniqueCode,
      })
    );
  }, []);
  const tabs=[]

  if(userData?.roles.length == 1){
    tabs.push(
      {
        tabName: "Credit Bureau Scrub Report",
        comp: CreditBureauScrubReport,
        heading: `Credit Bureau Scrub Report for - ${dealInfo?.name}`,
      },
      {
        tabName: "CIBIL Score Validation",
        comp: CIBILScoreValidation,
        heading: `CIBIL Score Validation for - ${dealInfo?.name}`,
      },
      {
        tabName: "CIBIL Score Updation",
        comp: CibileScoreUpdation,
        heading: `CIBIL Score Updation for - ${dealInfo?.name}`,
      },
      {
        tabName: "Pool Validation ",
        comp: PoolValidation,
        heading: `Pool Validation for - ${dealInfo?.name}`,
      },
      {
        tabName: "Request KYC Documents",
        comp: RequestKYCDocuments,
        heading: `Request KYC Documents for - ${dealInfo?.name}`,
        isDisabled: getTabStatus(),
      },
    )
  }

  if(userData?.roles.length != 1){
    tabs.push(
      {
        tabName: "CIBIL Score Validation",
        comp: CIBILScoreValidation,
        heading: `CIBIL Score Validation for - ${dealInfo?.name}`,
      },
    )
  }

  let TabsList = [
    {
      name: "Loan",
      component: (
        <Tabs
          tabs={tabs}
          status={KYCDocumentsRequestState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    }, 
  ];

  if(userData?.roles.length == 1){
    TabsList.push(
      {
        name: "History",
        component: <Histories />,
        index: 1,
      },
      {
        name: "Queries",
        component: (
          <QueriesPage
            dealIdorOriginatorId={params?.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
        index: 2,
      },
    )
  }

  useEffect(() => {
    return () => {
      dispatch(setStatus(0));
    };
  }, []);

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={TabsList}
      ></TabsLayout>
    </Layout>
  );
};

export default KYCDocumentsRequest;
