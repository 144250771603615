import React, { useEffect, useState } from "react";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import Layout from "../../components/Layout/Layout";
import Image from "../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";

import Histories from "../../components/History/Histories";
import { useSelector, useDispatch } from "react-redux";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../redux/Deals/dealSlice";
import { useParams, useHistory } from "react-router-dom";
import QueriesPage from "../../components/Queries/Queries";
import Tabs from "../../components/Tabs/Tabs";
import InvestmentMemo from "../../components/Tabs/L1FinalDeal/InvestmentMemo";
import Paths from "../../components/constants/Path";
import { getEntityTemplate } from "../../redux/Entity/entitySlice";
import Loader from "../../utils/Loader";
import EntityDetailComp from "../../components/Tabs/L1FinalDeal/EntityDetailComp";

const PortfolioViewDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const entityState = useSelector((state) => state.entity);
  console.log({ dealState });
  const history = useHistory();

  const params = useParams();
  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let KYCApproveRejectState = useSelector(
    (state) => state.auditorKYCApproveReject
  );

  let { dealId } = params;
  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
    dispatch(getEntityTemplate({ type: "INVESTMENT_MEMO" }));
  }, []);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.PORTFOLIOFINALDEALLIST,
      pathName: "deal",
    },

    {
      pathName: "test",
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  const tabs = [
    {
      tabName: "Entity Details",
      comp: EntityDetailComp,
      heading: `Entity Detail Files - ${dealState?.name}`,
    },
    {
      tabName: "Investment Memo",
      comp: InvestmentMemo,
      heading: `Investment Memo - ${dealState?.name}`,
    },
  ];

  let TabList = [
    {
      name: "Loan",
      component: (
        <Tabs
          tabs={tabs}
          status={KYCApproveRejectState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
  ];

  if (userData?.roles.length == 1) {
    TabList.push(
      {
        name: "Queries",
        index: 3,
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
      { name: "History", index: 3, component: <Histories id={dealId} /> }
    );
  }

  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDealState());
    };
  }, []);
  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      {dealState.loading ? (
        <Loader />
      ) : (
        <TabsLayout
          formName={dealState?.name}
          image={Image}
          icon={icon}
          iconColor={"#DBE3F8"}
          TabsList={TabList}
        ></TabsLayout>
      )}
    </Layout>
  );
};

export default PortfolioViewDetails;
