import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from "../../assets/images/Group 42722.svg";
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import Tabs from "../../components/Tabs/Tabs";
import FinaliseTermSheet from "../../components/Tabs/TermSheet/FinaliseTermSheet";
import OriginatorDraft from "../../components/Tabs/TermSheet/OriginatorDraft";
import TermSheetTemplate from "../../components/Tabs/TermSheet/TermSheetTemplate";
import UploadTermSheet from "../../components/Tabs/TermSheet/UploadTermSheet";
import { clearFilterData } from "../../redux/Deals/dealSlice";
import { clearTermsheetState } from "../../redux/TermSheet/TermSheetSlice";
import Loader from "../../utils/Loader";
import Paths from "../../components/constants/Path";
import { statusConstants } from "../../components/constants/FiltersConstant";
import { setStatus } from "../../redux/Loans/loanSlice";

const TermSheetRequest = () => {
  const params = useParams();
  const dispatch = useDispatch();
  let termSheetRequestState = useSelector((state) => state.termSheetRequest);
  let dealState = useSelector((state) => state.deal);
  let loading = useSelector((state) => state?.deal?.isTempLoading);
  let userData = JSON.parse(localStorage.getItem("UserData"));
  let activRole = localStorage.getItem("activeRole")
  let seletedDealId = localStorage.getItem("dealId");
  let forName = [];

  // for selected deal name
  dealState?.allDeals.map((item) => {
    if (seletedDealId) {
      if (item._id == seletedDealId) {
        seletedDealId = item.name;
        forName.push(seletedDealId);
      }
    }
  });

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Term Sheet",
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearTermsheetState());
      dispatch(clearFilterData());
    };
  }, []);

  // For L1 role :- Handle tabs view, availability as per condition/flow
  
  let L1tabs = [
    {
      tabName: "Upload Term Sheet",
      comp: UploadTermSheet,
      heading: `Upload Term Sheet for - Deal`,
      showEditButton: false,
    },
    {
      tabName: "Term Sheet",
      comp: TermSheetTemplate,
      heading: `Term Sheet - ${seletedDealId}`,
      showEditButton: false,
    },
  ];

  //  1st condition
  if (
    !(
      dealState?.filterdata?.poolUploadFinal?.termsheetUploadedFile !==
      undefined
    )
  ) {
    L1tabs = L1tabs.splice(0, 2);
  } else if (
    dealState?.filterdata?.poolUploadFinal?.isTermSheetApproved === true
  ) {
    L1tabs.push({
      tabName: "Originator’s Draft",
      comp: OriginatorDraft,
      heading: `Originator’s Draft - ${seletedDealId}`,
      showEditButton: false,
    });
  }
  //2nd Conditon
  if (
    dealState?.filterdata?.poolUploadFinal?.termsheetFinalStatus?.Originator ==
    "DONE"
  ) {
    console.log({ L1tabs });
    L1tabs.push({
      tabName: "Finalise Term Sheet",
      comp: FinaliseTermSheet,
      heading: `Finalise Term Sheet - ${seletedDealId} `,
      showEditButton: false,
    });
  }

  // For L2 role :- Handle tabs view, availability as per condition/flow
  let L2tabs = [
    {
      tabName: "Upload Term Sheet",
      comp: UploadTermSheet,
      heading: `Upload Term Sheet for - Deal`,
      showEditButton: false,
    },
    {
      tabName: "Term Sheet",
      comp: TermSheetTemplate,
      heading: `Term Sheet - ${seletedDealId}`,
      showEditButton: false,
    },
  ];

  // handle  Conditions for entire flow for L2
  if (
    !(dealState?.filterdata?.poolUploadFinal.termsheetDraftFile !== undefined)
  ) {
    L2tabs = L2tabs.splice(0, 2);
  } else if (
    dealState?.filterdata?.poolUploadFinal?.isTermSheetApproved === false
  ) {
    L2tabs = L2tabs.splice(0, 2);
  } else if (
    dealState?.filterdata?.poolUploadFinal?.termsheetFinalStatus?.L1 ==
    statusConstants.DONE
  ) {
    L2tabs.push({
      tabName: "Finalise Term Sheet",
      comp: FinaliseTermSheet,
      heading: `Finalise Term Sheet - ${seletedDealId}`,
      showEditButton: false,
    });
  }

  // For Originator role :- Handle tabs view, availability as per condition/flow
  let Originatortabs = [
    {
      tabName: "Upload Term Sheet",
      comp: UploadTermSheet,
      heading: `Upload Term Sheet for - Deal`,
      showEditButton: false,
    },

    {
      tabName: "Term Sheet",
      comp: TermSheetTemplate,
      heading: `Term Sheet - ${seletedDealId}`,
      showEditButton: false,
    },
    {
      tabName: "Finalise Term Sheet",
      comp: FinaliseTermSheet,
      heading: `Finalise Term Sheet - ${seletedDealId}`,
      showEditButton: false,
    },
  ];

  // handle  Conditions for entire flow for Originator
  if (
    !(
      dealState?.filterdata?.poolUploadFinal.termsheetDraftFile !== undefined
    ) &&
    !(dealState?.filterdata?.poolUploadFinal?.isTermSheetApproved === true)
  ) {
    Originatortabs = Originatortabs.splice(0, 2);
  } else if (
    !(
      dealState?.filterdata?.poolUploadFinal?.termsheetUploadedFile !==
      undefined
    )
  ) {
    Originatortabs = Originatortabs.splice(0, 2);
  }
  // 2nd condition
  if (
    !(
      dealState?.filterdata?.poolUploadFinal?.termsheetFinalStatus !== undefined
    )
  ) {
    Originatortabs = Originatortabs.splice(0, 2);
  }

  let TabsList = [
    {
      name: "Loan",
      component: (
        <Tabs
          tabs={
            activRole === statusConstants.L1
              ? L1tabs
              : activRole === statusConstants.L2
              ? L2tabs
              : Originatortabs
          }
          status={termSheetRequestState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
    
  ];

  if(userData?.roles.length == 1){
    TabsList.push(
      {
        name: "Queries",
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
        index: 1,
      },
      {
        name: "History",
        component: <Histories />,
        index: 2,
      },
    )
  }

  useEffect(() => {
    return () => {
      dispatch(setStatus(0));
    };
  }, []);

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout breadCrumbs={breadCrumbs}>
          <TabsLayout
            TabsList={TabsList}
            formName={forName[0]}
            icon={icon}
            iconColor={"#DBE3F8"}
            image={Image}
          />
        </Layout>
      )}
    </>
  );
};

export default TermSheetRequest;
