import React from "react";
import Layout from "../components/Layout/TabsLayout/Phase2Layout/Layout";
import Notifications from "../components/Phase2/Notification/Notifications";
import Paths from "../components/constants/Path";

const NotificationsPage = () => {

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const breadCrumbs = [
    {
      path: userData?.roles[0] === "L1" || 
      userData?.roles[0] === "L2" || 
      userData?.roles[0] === "Approver 1" || 
      userData?.roles[0] === "Approver 2" || 
      userData?.roles[0] === "Approver 3" || 
      userData?.roles[0] === "Approver 4" || 
      userData?.roles[0] === "Approver 5" ||
      userData?.roles[0] === "Approver 6" ||
      userData?.roles[0] === "Approver 7" ||
      userData?.roles[0] === "Auditor" ||
      userData.roles[0] === "C1" ||
      userData.roles[0] === "R1" ||
      userData.roles[0] === "CP1" ||
      userData.roles[0] === "IA1" ||
      userData.roles[0] === "CO1"
       ?   
      Paths.DASHBOARD :
      userData?.roles[0] === "Originator" ?
      Paths.ORIGINATORHOME :
      Paths.PHASE1HOME,
      pathName: "Home",

    },

    {
      pathName: "Notifications",
    },
  ];

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Notifications></Notifications>
    </Layout>
  );
};

export default NotificationsPage;
