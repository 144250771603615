import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Pagination from "../Pagination/Pagination";
import styles from "./Table.module.css"
import moment from "moment";
import { originatorNameStyle, statusTopBox } from "./TableCSS";

const CreditScrubDealListTable = ({
  data,
  viewActionClickHandler,
  header,
  statusList,
  page,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  let userData = JSON.parse(localStorage.getItem("UserData"));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      padding: "6px 7px",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const colorMap = {
      APPROVED: { textColor: "#277742", boxBackgroundColor: "#E3F6E9" },
      Created: { textColor: "#277742", boxBackgroundColor: "#E3F6E9" },
      DONE: { textColor: "#277742", boxBackgroundColor: "#E3F6E9" },
      REJECTED: { textColor: "#8D3431", boxBackgroundColor: "#FCE6E5" },
      FAILED: { textColor: "#8D3431", boxBackgroundColor: "#FCE6E5" },
      CLOSED: { textColor: "#2C4584", boxBackgroundColor: "#E4EAFA" },
    };

    const defaultColor = {
      textColor: "#996E37",
      boxBackgroundColor: "#FFF4E7",
    };

    const { textColor, boxBackgroundColor } =
      colorMap[statusType] || defaultColor;

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: textColor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroundColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const Actions = ({ isDealAdded, dealId, label, Icon, item }) => {
    return (
      <div
        onClick={() =>
          item?.poolUploadFinal?.cibilScoreUploadFileStatus !== "UPLOADED" && userData?.roles.length == 1 
            ? viewActionClickHandler(item)
            : null
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          <Icon
            fontSize="small"
            sx={{
              color:
                item?.poolUploadFinal?.cibilScoreUploadFileStatus !== "UPLOADED" && userData?.roles.length == 1
                  ? "#365069"
                  : "#AFB9C3",
            }}
          ></Icon>

          <Typography
            sx={{
              color:
                item?.poolUploadFinal?.cibilScoreUploadFileStatus !== "UPLOADED" && userData?.roles.length == 1
                  ? "#365069"
                  : "#AFB9C3",
              fontSize: "14px",
            }}
          >
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item?.id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item?.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>

                  <StyledTableCell component="th" scope="row">
                    {item?.loanType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.requestedDealAmount}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(item.createdAt).format("D MMM YYYY")}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    <StatusContainer
                      statusType={
                        item.poolUploadFinal?.cibilScoreUploadFileStatus ===
                        "PENDING"
                          ? "Upload Credit Bureau Scrub Report"
                          : "DONE"
                      }
                      // statusType={item.status}
                    ></StatusContainer>
                  </StyledTableCell>

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        isPoolAdded={
                          item.status !== "Upload Credit Bureau Scrub Report"
                        }
                        Icon={VisibilityIcon}
                        dealId={item.id}
                        label={"Upload"}
                        item={item}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        Page={page}
        TotalNoNumber={totalRecords}
        nextPageHandler={page === totalRecords ? null : nextPageHandler}
        prevPageHandler={prevPageHandler}
      ></Pagination>
    </div>
  );
};

export default CreditScrubDealListTable;
