import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "dateformat";
import _ from "lodash";

import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { ArrowDownward } from "@mui/icons-material";
import {
  CommanTableStyledStatusContainerBox,
  CommanTableStyledTableCell1Head,
  CommanTableStyledTableCell2Head,
  CommanTableStyledTableCellBody,
  CommanTableStyledTableCellHead,
} from "./F2CSS";
import Styles from "./F2.module.css";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  [`&.${tableCellClasses.head}`]: CommanTableStyledTableCellHead,
  [`&.${tableCellClasses.body}`]: CommanTableStyledTableCellBody,
}));

const SoaReportTableComp = ({ soaReport }) => {
  return (
    <>
      {soaReport?.createdAt.map((transactionDate) => (
        <>
          {soaReport.interestRateTrailData.map((interestRate, index) => (
            <TableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {moment(transactionDate).format("DD/MM/YY")}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {interestRate}
              </StyledTableCell>
            </TableRow>
          ))}
        </>
      ))}
    </>
  );
};

const DealTable = ({ soaReport }) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: CommanTableStyledTableCell1Head,
    [`&.${tableCellClasses.body}`]: CommanTableStyledTableCellBody,
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: CommanTableStyledTableCell2Head,
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const Textcolor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "DONE"
        ? "#277742"
        : statusType === "REJECTED"
          ? "#8D3431"
          : statusType === "CLOSED"
            ? "#2C4584"
            : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "REJECTED" || statusType === "FAILED"
          ? "#FCE6E5"
          : statusType === "CLOSED"
            ? "#E4EAFA"
            : "#FFF4E7";

    // const status = statusType.toLowerCase()

    return (
      <Box
        borderRadius={1}
        color={Textcolor}
        sx={CommanTableStyledStatusContainerBox}
      >
        <div
          className={Styles.CommanTableStatusContainerDiv}
          style={{
            backgroundColor: boxBackgroudColor,
          }}
        >
          <p className={Styles.CommanTableStatusContainerP}>
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const formatRequestedPool = (value) => {
    if (value !== undefined) {
      console.log({ value });
      numberFormat(Number(value));
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  var nf = new Intl.NumberFormat();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    // var finalTime = hours + ":" + minutes + " " + AmOrPm;
    return finalTime;
  }

  const isValidSoaReport = ({ soaReport }) => {
    // eslint-disable-next-line no-unused-expressions
    soaReport.hasOwnProperty("createdAt") &&
      soaReport?.createdAt.length > 0 &&
      soaReport.hasOwnProperty("interestRateTrailData") &&
      soaReport?.interestRateTrailData.length > 0;
  };
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ minWidth: "200px" }}>
              {["Transaction Date", "Interest Rate"].map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isValidSoaReport && <SoaReportTableComp soaReport={soaReport} />}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DealTable;
