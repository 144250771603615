import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance, { instanceV2 } from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "basicInfo", isChecked: false },
    { pageName: "otherDetails", isChecked: false },
    { pageName: "SPOCDetails", isChecked: false },
    { pageName: "documents", isChecked: false },
    { pageName: "KYCDocuments", isChecked: false },
  ],
  originatorName: "",
  originatorType: "",
  spocs: [],
  portfolioPerformanceMetrics: [],
  financialPerformanceMetrics: [],
  panCard: null,
  gstCertificate: null,
  incorporationCertificate: null,
  cancelledCheque: null,
  rbiRegisterationCertificate: null,
  leiCard: null,
  leiNumber: "",
  quaterlyPer: null,
};

export const uploadDocs = createAsyncThunk(
  "uploadDocs",
  async ({ formData, fileName }, rejectWithValue) => {
    console.log({ formData, fileName });
    try {
      const uploadData = await instanceV2.post(
        "/utility-service/upload-file",
        {
          file: formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      console.log(uploadData);
      return {
        ...uploadData,
        fileName,
      };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const editOriginatorSlice = createSlice({
  name: "editOriginatorSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    spocDetails: (state, { payload }) => {
      state.allSpocs = payload.allSpocs;
    },
    updDocuments: (state, { payload }) => {
      state.quaterlyPer = payload.quaterlyPer;
    },
    deleteDoc: (state, { payload }) => {
      state[payload] = null;
    },
    deleteDocument: (state, { payload }) => {
      state[payload.docName] =
        state[payload.docName] &&
        state[payload.docName].filter((val, index) => index != payload.index);
    },
    extraReducer: (builder) => {
      //Get Originator Details
    },
  },
});

export const { pageStatusAction, spocDetails } = editOriginatorSlice.actions;

export const editOriginatorReducer = editOriginatorSlice.reducer;
