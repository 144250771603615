import React from "react";
import { Colors } from "../../theme/theme";
import { Button } from "@mui/material";

const EnableDisableButton = ({ status, onClickHandler, text, isLoading }) => {
  return (
    <>
      {status ? (
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: Colors.piramal_light_orange,
            color: Colors.white,
            "&:hover": {
              backgroundColor: Colors.piramal_light_orange,
            },
          }}
        >
          {text}
        </Button>
      ) : (
        <>
          {isLoading ? (
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: Colors.piramal_orange,
                color: Colors.white,
                "&:hover": {
                  backgroundColor: Colors.piramal_orange,
                },
              }}
              type="submit"
            >
              Loading...
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={onClickHandler}
              fullWidth
              sx={{
                backgroundColor: Colors.piramal_orange,
                color: Colors.white,
                "&:hover": {
                  backgroundColor: Colors.piramal_orange,
                },
              }}
              type="button"
            >
              {text}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default EnableDisableButton;
