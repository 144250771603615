import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TabFooter from "../TabFooter/TabFooter";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import { AddMoreFileComponent } from "../../Wrapper/UploadComponents";
import { useHistory } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack, Typography } from "@mui/material";
import Paths from "../../constants/Path";
import UploadDocuments from "../../Wrapper/UploadDocuments";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styles from "./CreateOriginatorTabs.module.css";
import {
  submitOriginatorDetails,
  setDealRedirection,
  setDuplicateOriginator,
} from "../../../redux/originator/originatorSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import {
  descriptionIconStyle,
  typoStyle,
  typographyStyle,
  widthStyle,
} from "./CreateOriginatorTabsCSS";
import ModalV3 from "../../Modal/ModalV3";

const DocumentsTab = ({ nextTabHandler, setShowDeals }) => {
  const [portfolio, setPortfolio] = useState([{}]);
  const [fanacial, setfanacial] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState(true);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState("");
  const [gstValues, setGstValues] = useState("");
  
  //? useDispatch
  let dispatch = useDispatch();

  //? useSelector
  let originatorState = useSelector((state) => state.originator);
  
  // let filter space for GSt 
  function checkSpace(space){
    return space >= " ";
  }
  let filteredGst = originatorState?.gstNumbers?.filter(checkSpace);
  

  //? useHistory
  let history = useHistory();

  const checkStatus = () => {
    if (
      originatorState["originatorName"].length > 0 &&
      originatorState["originatorType"].length > 0 &&
      originatorState["spocs"].length > 0 &&
      filteredGst?.length > 0 &&
      originatorState["panNumber"].length > 0 &&
      originatorState["portfolioPerformanceMetrics"].length > 0 &&
      originatorState["financialPerformanceMetrics"].length > 0
    ) {
      setState(false);
    } else {
      setState(true);
    }
  };
  //? useEffect
  useEffect(() => {
    checkStatus();
    if (
      originatorState["portfolioPerformanceMetrics"] &&
      originatorState["portfolioPerformanceMetrics"].length > 0
    ) {
      setPortfolio(originatorState["portfolioPerformanceMetrics"]);
    } else {
      setPortfolio([{}]);
    }

    if (
      originatorState["financialPerformanceMetrics"] &&
      originatorState["financialPerformanceMetrics"].length > 0
    ) {
      setfanacial(originatorState["financialPerformanceMetrics"]);
    } else {
      setfanacial([{}]);
    }
  }, [
    originatorState["portfolioPerformanceMetrics"],
    originatorState["financialPerformanceMetrics"],
  ]);

  // if (originatorState.success) {
  //   setLoading(false);
  //   return history.push(Paths.ORIGINATOR_LIST);
  // }

  const handlePortfolioClick = () => {
    setPortfolio((data) => [...data, {}]);
  };

  const handleFanacialClick = () => {
    setfanacial((data) => [...data, {}]);
    // setState(false);
  };

  const onClickHandlerToOriginatorList = () => {
    let data = {
      name: originatorState["originatorName"],
      originatorTypeId: originatorState["originatorType"],
      contacts: originatorState["spocs"],
      portfolioPerformanceMetrics:
        originatorState["portfolioPerformanceMetrics"].length > 0
          ? originatorState["portfolioPerformanceMetrics"].map(
              (val) => val.fileId
            )
          : originatorState["portfolioPerformanceMetrics"],

      financialPerformanceFocument:
        originatorState["financialPerformanceMetrics"].length > 0
          ? originatorState["financialPerformanceMetrics"].map(
              (val) => val.fileId
            )
          : originatorState["financialPerformanceMetrics"],

      gstCertificate:
        (originatorState.allgstCertificate &&
          originatorState.allgstCertificate.length > 0 &&
          originatorState.allgstCertificate.map((item) => item.fileId)) ||
        [],
      panCard:
        originatorState["panCard"] && originatorState["panCard"]["fileId"],
      incorporationCertification:
        originatorState["incorporationCertificate"] &&
        originatorState["incorporationCertificate"]["fileId"],
      rbiCerticationRegistration:
        originatorState["rbiRegisterationCertificate"] &&
        originatorState["rbiRegisterationCertificate"]["fileId"],
      leiCard:
        originatorState["leiCard"] && originatorState["leiCard"]["fileId"],
      leiNumber: originatorState["leiNumber"],
      cancelledCheque:
        originatorState["cancelledCheque"] &&
        originatorState["cancelledCheque"]["fileId"],
      originatorIds: originatorState["originatorIds"],
      gstNumbers: filteredGst,
        // originatorState["gstNumbers"] && originatorState["gstNumbers"],
      panNumber: originatorState["panNumber"] && originatorState["panNumber"],
    };

    let originatorName = originatorState?.originatorsList.some(
      (originator) => originator.name === originatorState.originatorName
    );

    if (originatorName === true) {
      dispatch(
        showErrorAlertAction("Originator with same name already exists")
      );
    } else {
      setLoading(true);
      dispatch(submitOriginatorDetails(data)).then((res) => {
        console.log({ responsed: res });
        console.log(res?.payload?.response?.data?.data?.isExist);
        // if (res?.payload?.response?.data?.data?.isExist === true) {
        //   let duplicateOrg = res?.payload?.response?.data?.data?.originator;
        //   dispatch(setDuplicateOriginator(duplicateOrg));
        //   setModalData(res?.payload?.response?.data?.data?.originator);
        //   setGstValues(
        //     res?.payload?.response?.data?.data?.originator?.kyc?.gstNumbers
        //   );
        //   setShowModal(true);
        //   setOpen(true);
        //   return;
        // }
        dispatch(showAlertAction("Originator Sent for Approval"));
        return history.push(Paths.ORIGINATOR_LIST);
      });
    }
  };

  const positiveOnClickHandler = () => {
    dispatch(setDealRedirection(true));
  };

  return (
    <>
      <Box sx={widthStyle}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <div className={styles.gridWrapper}>
              <Stack direction="row" spacing={1}>
                <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
                <Typography sx={typoStyle}>
                  Portfolio Performance Metrics
                </Typography>
              </Stack>
            </div>
            <div className={styles.paddingStyle}>
              {portfolio &&
                portfolio.map((data, i) => {
                  return (
                    <div>
                      <UploadDocuments
                        docName="portfolioPerformanceMetrics"
                        value={data}
                        index={i}
                      ></UploadDocuments>
                      <div className={styles.paddingTopStyle}></div>
                    </div>
                  );
                })}
            </div>
            <div className={styles.paddingStyle}>
              <AddMoreFileComponent
                clickhandler={handlePortfolioClick}
              ></AddMoreFileComponent>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={styles.gridWrapper}>
              <Stack direction="row" spacing={1}>
                <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
                <Typography sx={typoStyle}>
                  Financial Performance Metrics
                </Typography>
              </Stack>
            </div>
            <div className={styles.paddingStyle}>
              {fanacial.map((data, i) => {
                return (
                  <div>
                    <UploadDocuments
                      docName="financialPerformanceMetrics"
                      value={data}
                      index={i}
                    ></UploadDocuments>
                    <div className={styles.paddingTopStyle}></div>
                  </div>
                );
              })}
            </div>
            <div className={styles.paddingStyle}>
              <AddMoreFileComponent
                clickhandler={handleFanacialClick}
              ></AddMoreFileComponent>
            </div>
          </Grid>
        </Grid>
      </Box>
      <TabFooter
        nextTabHandler={nextTabHandler}
        showSubmitButton={true}
        isBtnDisable={state}
        btnLoading={loading}
        onclickHandler={onClickHandlerToOriginatorList}
      />
      {showModal && showModal ? (
        <>
          <ModalV3
            open={open}
            setOpen={setOpen}
            title="Potential Duplicate Profile Detected"
            children="A profile with similar details in the system. Following are the details of the similar profile"
            midHead1={modalData?.name}
            midHead2={gstValues.join(" , ")}
            midHead3={modalData?.kyc?.panNumber}
            btnText="View Originator"
            positiveOnClickHandler={positiveOnClickHandler}
          ></ModalV3>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default DocumentsTab;
