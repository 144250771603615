import React from "react";
import WrapperComponent from "./Wrapper/WrapperComponent";
import { Grid, Typography } from "@mui/material";
import LabelWithDownload from "./LabelWithDownload";
import { useSelector } from "react-redux";
import customStyle from "../Pages/L2/Originator/Originator.module.css"
import { typo } from "../Pages/L2/Originator/OriginatorCSS";
import styles from "../components/L2/ViewDownloadDeleteComp.module.css";

export default function AllOriginatorDocuments() {
  
  let originatorState = useSelector((state) => state.originator);

  
  const documentData = [
    {
      label: "PAN Card",
      fileId: originatorState?.panCard?._id,
      fileName: originatorState?.panCard?.filename,
    },
    {
      label: "Incorporation Certificate",
      fileId: originatorState?.incorporationCertificate?._id,
      fileName: originatorState?.incorporationCertificate?.filename,
    },
    {
      label: " RBI Registration Certificate",
      fileId: originatorState?.rbiRegisterationCertificate?._id,
      fileName: originatorState?.rbiRegisterationCertificate?.filename,
    },
    {
      label: "LEI Card",
      fileId: originatorState?.leiCard?._id,
      fileName: originatorState?.leiCard?.filename,
    },
    {
      label: "Cancelled Cheque",
      fileId: originatorState?.cancelledCheque?._id,
      fileName: originatorState?.cancelledCheque?.filename,
    },
  ];

  
  if (originatorState?.gstCertificate?.length > 0) {
    
    originatorState?.gstCertificate.forEach((item, index) => {
      console.log("item", item?.file);
      documentData.push({
        label: `GST Certificates - ${index + 1}`,
        fileId: item?._id,
        fileName: item?.filename,
      });
    });
  }

 

  return (
    <WrapperComponent title="KYC Documents">
      <>
        <div
          style={{
            paddingTop: "14px ",
          }}
        >
          <Grid container spacing={4}>
            {documentData.map((item, index) => (
              <>
                {item?.fileId && (
                  <Grid item xs={4}>
                    <LabelWithDownload
                      fileName={item?.fileName}
                      fileId={item?.fileId}
                      label={item?.label}
                    />
                  </Grid>
                )}
              </>
            ))}
          {originatorState?.leiNumber == null ||
                originatorState?.leiNumber == "" ? null : (
                  <Grid item xs={6} style={{ paddingTop: "18px "}}>
                    <div  className={customStyle.paddingStyle}>
                      <Typography sx={typo}>
                        LEI Number
                      </Typography>
                    </div>
                    <div className={customStyle.mappingStyle}>
                      <div className={styles.wrapper}>
                        <div className={styles.filenameWrapper}>
                          <p>{originatorState?.leiNumber}</p>
                        </div>

                        <div className={styles.iconsWrapper}></div>
                      </div>
                    </div>
                  </Grid>
                )}
          </Grid>
        </div>
      </>
    </WrapperComponent>
  );
}
