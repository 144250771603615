import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { styled } from "@mui/material/styles";
import { useHistory } from 'react-router-dom';
import Paths from '../../../components/constants/Path';
import { Colors } from '../../../theme/theme';
import Layout from '../../../components/Layout/Layout';
import CreditScrubDealListTable from '../../../components/Table/CreditScrubDealListTable';
import ApproverDocUpdDealistTable from '../../../components/Table/ApproverDocUpdDealistTable';
import searchIcon from "../../../assets/icons/searchIcon.svg"
import styles from "./UploadDocuments.module.css"
import { listDealStack, titleTypo, topBox } from './UploadDocumentsCSS';

const ApproverDocUpdDealist = () => {

    const [selectedFilter, setFilter] = useState(0);
    const [search, setSearch] = useState("");

    const filterHandler = (index) => {
        setFilter(index);
      };

      let history = useHistory();
      const clickHandlerToViewDetails = () => {
        return history.push(Paths.APPROVERUPDDOC);
    };
      const [filterName, setFilterName] = useState("All");
    
      const StyledButton = styled(Button)(() => ({
        borderRadius: "24px",
        textTransform: "none",
      }));

    const TableData = [
        {
          id: 8,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          RequestedPoolSize: "₹50,00,000",
          Transaction: "DA",
          DealStatusDate: "12 Aug 2022",
          DealStatus: "Approved",
        },
        {
          id: 18,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          RequestedPoolSize: "₹50,00,000",
          Transaction: "DA",
          DealStatusDate: "12 Aug 2022",
          DealStatus: "Approved",
        },
        {
          id: 98,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          RequestedPoolSize: "₹50,00,000",
          Transaction: "DA",
          DealStatusDate: "12 Aug 2022",
          DealStatus: "Approved",
        },
        {
          id: 28,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          RequestedPoolSize: "₹50,00,000",
          Transaction: "DA",
          DealStatusDate: "12 Aug 2022",
          DealStatus: "Approved",
        },
        {
            id: 44,
            DealName: "P1 Home Loan",
            AssetClass: "Gold Loan",
            RequestedPoolSize: "₹50,00,000",
            Transaction: "DA",
            DealStatusDate: "12 Aug 2022",
            DealStatus: "Approved",
          },
      ];

      const breadCrumbs = [
        {
          path: "/dashboard",
          pathName: "Home",
        },
        {
          pathName: "Deals List",
        },
      ];

      const filterList = [
        {
            status: "All",
            bgcolor: null,
            color: null,
        },
        {
            status: "Approved",
            bgcolor: Colors.Emerald_Green_G_10,
            color: "#277742",
        }
      ]

      return (
        <Layout breadCrumbs={breadCrumbs}>
            <Box sx={topBox}>
            <>
              <Stack sx={listDealStack}>
                <Typography sx={titleTypo} >
                  List of Deals
                </Typography>
              </Stack>
    
              <Divider></Divider>
    
              <div className={styles.main}>
                <div>
                  {filterList &&
                    filterList.map((item, index) => (
                      <>
                        <StyledButton
                          key={index}
                          variant={filterName === item.status ? "contained" : "outlined"}
                          sx={{
                            color: filterName === item.status ? "white" : "black",
                            backgroundColor:
                              filterName === item.status ? "#365069" : "white",
                            borderColor: "black",
                            mr: 1,
                          }}
                          onClick={() => filterHandler(item.status)}
                        >
                          {item.status}
                        </StyledButton>
                      </>
                    ))}
                </div>
    
                <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon}/>
                    {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            ></TextField>
              </div>
    
              <Box>
                <ApproverDocUpdDealistTable
                  data={TableData}
                  header={[
                    "Deal Name",
                    "Asset Class",
                    "Requested Pool Size",
                    "Transaction",
                    "Deal Start Date",
                    "Pending Action ",
                    "Action",
                  ]}
                  viewActionClickHandler={clickHandlerToViewDetails}
                  statusList={filterList}
                ></ApproverDocUpdDealistTable>
              </Box>
            </>
          </Box>
        </Layout>
      )

}

export default ApproverDocUpdDealist;