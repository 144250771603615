import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import styles from "./StatusChange.module.css";
import customStyle from "./Table.module.css"

import {
  Stack,
  Table,
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Pagination from "../Pagination/Pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDeals,
  setSortValue,
  setSortName,
} from "../../redux/Deals/dealSlice";
import { ArrowDownward } from "@mui/icons-material";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import moment from "moment/moment";
import { actionTypo, actionsIcon, alignItemStle, arrowDownwardStyle, arrowUpwardStyle, marginStyle, norecordFoundStyle, originatorNameStyle, statusTopBox } from "./TableCSS";

const AuditorDealistTable = ({ 
  data, 
  viewActionClickHandler, 
  header ,
  page,
  filterName,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  


  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  let finalData = [...data];
  

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#365069",
      fontSize: "12px",
      fontWeight: "800",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
      fontWeight: "600",
    },
    // padding: "6px",
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
      fontWeight: "600",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  }));

  //Color for the Approval status of the deal in the table row //
  const StatusContainer = ({ statusType }) => {
    console.log({ statusType });
    const Textcolor =
      statusType === "APPROVED" || statusType === "DONE" || statusType === "Done"
        ? "#277742"
        : statusType === "REJECTED"
        ? "#996E37"
        : "#8D3431";

    const boxBackgroudColor =
      statusType === "APPROVED" || statusType === "DONE" || statusType === "Done"
        ? "#E3F6E9"
        : statusType === "REJECTED"
        ? "#FFF4E7"
        : "#FCE6E5";
    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
        }}
      >
        <div className={styles.p}>
          <p style={{ fontSize: "12px", fontWeight: "800" }}>{statusType}</p>
        </div>
      </Box>
    );
  };
  //Color for the Approval status of the deal in the table row ends here //

  //Color for the upload status of the deal in the table row //
  const FinalContainer = ({ statusType }) => {
    console.log({ statusType });
    const Textcolor =
      statusType === "IN PROGRESS"
        ? "#4C2E65"
        : statusType === "PENDING"
        ? "#996E37"
        : statusType === "DONE" || statusType === "Done"
        ? "#277742"
        : "#8D3431";

    const boxBackgroudColor =
      statusType === "IN PROGRESS"
        ? "#ECE4F2"
        : statusType === "PENDING"
        ? "#FFF4E7"
        : statusType === "DONE" || statusType === "Done"
        ? "#E3F6E9"
        : "#FCE6E5";
    return (
      <Box
        borderRadius={1}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
          width: "85px",
          height: "auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className={styles.p}>
          <p className={customStyle.statusPTag} >{statusType}</p>
        </div>
      </Box>
    );
  };
  //Color for the upload status of the deal in the table row ends here //

  const Actions = ({Icon,dealId, label,item  }) => {
    // label = "View";
    return (
      <div
        onClick={() => viewActionClickHandler(item)}
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{
            justifyContent: "flex-end",
          }}
          //   justifyItems="flex-end"
        >
          <Icon sx={actionsIcon}></Icon>

          <Typography
            sx={actionTypo}
          >
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  const headerFilters = {
    "Deal Name": "deal",
    "Asset Class": "assetClass",
    Transaction: "transaction",
    "Audit Assigned Date": "auditAssignedDate",
  };

  const MapHeader = ({ data }) => {
    const [sort, setSort] = useState("");
    useEffect(() => {
      if (sort !== "") {
        // setCallFunc({ data, sort });
        dispatch(setSortValue(!dealState.sortValue));
        dispatch(setSortName(data));
        let sortName = headerFilters[`${data}`];
        if (sortName !== undefined) {
          dispatch(
            getAllDeals({
              currentPage: page,
              limit: 10,
              sortName: sortName,
              filterName,
              sortOrder: dealState.sortValue ? 1 : -1,
              isKycUploded: true,
              filterName: "APPROVED"
            })
          );
        }
      }
      return;
    }, [sort]);

    console.log(headerFilters[data]);
    console.log(dealState?.sortFieldName);

    console.log(data === dealState?.sortFieldName);
    return (
      <StyledTableCell
        onClick={() =>
          data == "Action" || data == "Deal Status" || data == "Loan Status"
            ? null
            : setSort(!sort)
        }
        style={{
          cursor:
            data == "Action" || data == "Deal Status" || data == "Loan Status"
              ? null
              : "pointer",
        }}
      >
        {data}

        {data !== dealState?.sortFieldName ? null : dealState?.sortValue !==
          true ? (
          <ArrowUpward sx={arrowUpwardStyle} />
        ) : (
          <ArrowDownward sx={arrowDownwardStyle} />
        )}
      </StyledTableCell>
    );
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    // var finalTime = hours + ":" + minutes + " " + AmOrPm
    return finalTime;
  }


  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                  return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((item) => {
              return (
                <TableRow key={item.id} sx={marginStyle}>
                  <StyledTableCell1 component="th" scope="row">
                    {item.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>

                  <StyledTableCell component="th" scope="row">
                  {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                  {moment(item?.poolUploadFinal?.auditAssignedDate).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={originatorNameStyle}
                      >
                        {convertTime(item?.poolUploadFinal?.auditAssignedDate) || ""}
                      </Typography>
                  </StyledTableCell>
                  {/* <StyledTableCell component="th" scope="row">
                    <StatusContainer
                      statusType={item?.poolUploadFinal?.kycVerificationCurrentStatus}
                    ></StatusContainer>
                  </StyledTableCell> */}
                  <StyledTableCell component="th" scope="row">
                    <FinalContainer
                      statusType={item?.poolUploadFinal?.kycVerificationCurrentStatus}
                    ></FinalContainer>
                  </StyledTableCell>
                  <TableCell sx={alignItemStle}>
                    <StyledNoBorderTableCell>
                      <Actions 
                      Icon={VisibilityIcon}
                      label="View"
                      dealId={item.id}
                      item={item}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>
      {data && data.length === 0 ? null : (
        <Pagination
          Page={page}
          TotalNoNumber={totalRecords}
          nextPageHandler={page === totalRecords ? null : nextPageHandler}
          prevPageHandler={prevPageHandler}
        />
      )}
    </div>
  );
};

export default AuditorDealistTable;
