import React from "react";
import { Colors } from "../../../theme/theme";

export const descriptionIconStyle = {
  color: "#5E7387",
  width: "20px",
  height: "20px",
};
export const documentHeaderTypographyStyle = {
  color: "#5E7387",
  fontWeight: "700",
  fontSize: "14px",
};
export const documentHeaderStack = {
  flexDirection: "row",
};
export const reportDetailsIconStyle = {
  color: "#2C4584",
};
export const menuPropsStyle = {
  maxHeight: 200,
  width: 250,
  background: "#EBEEF0",
};
export const mainBoxStyle = {
  width: "100%",
  paddingLeft: "14px",
  backgroundColor: Colors.light_green,
  display: "flex",
  justifyContent: "space-between",
  height: "128px",
};
export const dealNameSelectStyle = {
  width: 320,
  height: "30px",
};
export const listSubheaderStyle = {
  height: "40px",
  background: "#EBEEF0",
  margin: "10px 0px",
};
export const searchDealStyle = {
  fontFamily: "Nunito",
  fontSize: "18px",
  fontWeight: "400",
  height: "7px",
  width: 340,
};
export const displayedOptionsMenuItemStyle = {
  height: "40px",
};
export const viewDetailsButton = {
  backgroundColor: "#FAC3B3",
  color: "white",
};
export const financialYearFormContorl = {
  width: 250,
};
export const CommanTableStyledTableCellHead = {
  backgroundColor: "#EBEEF0",
  color: "#5E7387",
  fontSize: "12px",
  fontWeight: "700",
  textAlign: "left",
};
export const CommanTableStyledTableCellBody = {
  fontSize: "14px",
  textAlign: "left",
};
export const CommanTableStyledTableCell1Head = {
  backgroundColor: "#EBEEF0",
  color: "#5E7387",
  fontSize: "12px",
  fontWeight: "500",
  textAlign: "left",
};
export const CommanTableStyledTableCell2Head = {
  backgroundColor: "#EBEEF0",
  color: "#5E7387",
  fontSize: "12px",
  fontWeight: "700",
  textAlign: "center",
};
export const CommanTableStyledStatusContainerBox = {
  textTransform: "none",
  width: "70",
  height: "20px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  fontSize: "12px",
};
export const MonthEndUploadListBox = {
  width: "100%",
  paddingLeft: "14px",
  backgroundColor: Colors.light_green,
  display: "flex",
  justifyContent: "space-between",
  height: "128px",
};
export const MonthEndUploadListTableBox = {
  backgroundColor: "white",
  minHeight: "144px",
  maxHeight: "auto",
  minWidth: "auto",
  maxWidth: "auto",
  boxShadow: 4,
  borderRadius: 2,
  padding: 2,
  margin: 2,
};

export const ViewSOAReportSaveButton = {
  backgroundColor: Colors.piramal_orange,
  color: Colors.white,
  textTransform: "none",
  width: "220px",
  margin: "0px 50px",
  "&:hover": {
    backgroundColor: Colors.piramal_orange,
  },
  "&:disabled": {
    backgroundColor: "#FAC3B3",
    color: "white",
  },
};
export const ViewVoucherDetailsPrintButton = {
  backgroundColor: Colors.piramal_orange,
  color: Colors.white,
  textTransform: "none",
  width: "150px",
  "&:hover": {
    backgroundColor: Colors.piramal_orange,
  },
  "&:disabled": {
    backgroundColor: "#FAC3B3",
    color: "white",
  },
};
export const ViewSOAReportStack = {
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "space-between",
  paddingBottom: "12px",
};
export const ViewSOAReportStack1 = {
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "space-between",
  margin: "10px",
};
export const ViewSOAReportDetailsTypography = {
  fontSize: "16px",
  fontWeight: 700,
  paddingBottom: 1,
};
