import styles from "./NotificationComp.module.css";
import moment from "moment/moment";

const NotificationComp = ({ data }) => {
  function convertTextToHtml(text) {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(linkRegex);

    const htmlParts = parts.map((part, index) => {
      if (part.match(linkRegex)) {
        return (
          <a href={part} key={index} rel="noopener noreferrer">
            Here
          </a>
        );
      } else {
        return part;
      }
    });

    return <>{htmlParts}</>;
  }

  let dataTimeData = moment(data.createdAt).format("DD/MM/YYYY , LT");
  let splitDataTime = dataTimeData.split(",");
  return (
    <div className={styles.historyCompWrapper}>
      <div className={styles.dateTimeWrapper}>
        <p>{splitDataTime[0]}</p>
        <p>{splitDataTime[1]}</p>
      </div>
      <div className={styles.queryWrapper}>
        <div className={styles.nameAndQueryWrapper}>
          {/* <p className={styles.name}>Deal Approved By : {data.notification}</p> */}
          <p className={styles.name}>{convertTextToHtml(data.notification)}</p>
        </div>
      </div>
    </div>
  );
};

export default NotificationComp;
