import React, { useState, useEffect } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "./../../Pagination/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import { changeClosureStatus } from "../../../redux/Phase2/UploadReportSlice";
import { useDispatch } from "react-redux";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import BasicModal from "../../Modal/Modal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getMonthUplods } from "./../../../redux/Phase2/UploadReportSlice";
import moment from "moment/moment";
import { norecordFoundStyle, statusTopBox } from "../TableCSS";
import styles from "../Table.module.css"

const MonthEndUploadTable = ({
  rows,
  data,
  page,
  prevPageHandler,
  nextPageHandler,
  totalRecords,
}) => {
  const [openMsg, setOpenMsg] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [proceedWithUpload, setproceedWithUpload] = useState(false);
  const [msgData, setMsgData] = useState("");

  let params = useParams();
  const dispatch = useDispatch();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      minWidth: "200px",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const viewActionClickHandler = (item) => {
    console.log({ item });
    dispatch(
      changeClosureStatus({
        uniqueCode: item.uniqueCode,
        Status: "APPROVED",
        isfinalUpload: true,
        proceedWithUploadVal: false,
      })
    ).then((res) => {
      console.log(res);
    });
  };

  const Actions = ({ isDealAdded, dealId, label, Icon, item }) => {
    return (
      <div
        onClick={
          () =>
            item.monthEndStatus !== "CLOSED" ? handleClosureStatus(item) : null //condtion change
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          <Icon
            fontSize="small"
            sx={{
              color: item?.monthEndStatus !== "CLOSED" ? "#365069" : "#AFB9C3", //condtion change
              // color: "#365069"
            }}
          ></Icon>

          <Typography
            sx={{
              color: item?.monthEndStatus !== "CLOSED" ? "#365069" : "#AFB9C3", //condtion change
              // color: "#365069",
              fontSize: "14px",
            }}
          >
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const Textcolor =
      statusType === "APPROVED" ||
      statusType === "Created" ||
      statusType === "DONE"
        ? "#277742"
        : statusType === "REJECTED"
        ? "#8D3431"
        : statusType === "CLOSED"
        ? "#2C4584"
        : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
      statusType === "Created" ||
      statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "REJECTED" || statusType === "FAILED"
        ? "#FCE6E5"
        : statusType === "CLOSED"
        ? "#E4EAFA"
        : "#FFF4E7";

    // const status = statusType.toLowerCase()

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  //? Change Closure Status Handler Function starts here
  const handleClosureStatus = (item) => {
    dispatch(
      changeClosureStatus({
        uniqueCode: item.uniqueCode,
        Status: "APPROVED",
        isfinalUpload: true,
        proceedWithUploadVal: false,
      })
    ).then((res) => {
      console.log({ res });
      if (res?.payload?.status == 200) {
        // dispatch(showAlertAction("Closure Status Changed Successfully"));
        setMsgData(res?.payload?.data?.message);
        setOpenMsg(item.uniqueCode);
      } else {
        dispatch(showErrorAlertAction("Something Went Wrong"));
      }
    });
  };
  //? Change Closure Status Handler Function ends here
  const handleClosureStatus1 = (item) => {
    console.log({ item });
    console.log("$$$$");
    setMsgLoading(true);
    console.log("111111111", msgLoading);
    dispatch(
      changeClosureStatus({
        uniqueCode: openMsg,
        Status: "APPROVED",
        isfinalUpload: true,
        proceedWithUploadVal: true,
      })
    ).then((res) => {
      if (res?.payload?.status == 200) {
        dispatch(
          getMonthUplods({
            dealId: params.dealId,
            financialYear: params.year,
            page: 1,
            limit: 10,
          })
        );
        dispatch(showAlertAction("Closure Status Changed Successfully"));
        setMsgLoading(false);
        setOpenMsg(false);
      } else {
        dispatch(showErrorAlertAction("Something Went Wrong"));
      }
    });
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {rows &&
                rows.map((row, index) => {
                  return <StyledTableCell key={index}>{row}</StyledTableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length !== 0 &&
              data.map((item, index) => {
                console.log({ itemFromTable: item });
                return (
                  <TableRow key={item.index}>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.month || ""} {item?.year || ""}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.totalUploads}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {/* {item?.monthClosureDate || "NA"} */}
                      {item?.monthClosureDate
                        ? moment(item?.monthClosureDate).format("DD/MM/YYYY")
                        : "NA"}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      <StatusContainer
                        statusType={item?.monthEndStatus || ""}
                        // CreditFilterAdded={}
                      ></StatusContainer>
                    </StyledTableCell1>
                    <TableCell>
                      <StyledNoBorderTableCell>
                        <Actions
                          label="Close Deal"
                          dealId={item.id}
                          item={item}
                          Icon={CloseIcon}
                        ></Actions>
                      </StyledNoBorderTableCell>
                    </TableCell>
                    <BasicModal
                      open={openMsg}
                      setOpen={setOpenMsg}
                      title="Are you sure you want to close the monthly upload?"
                      // positiveText="Yes"
                      positiveText={msgLoading ? "Loading...." : "Yes"}
                      negativeText="No"
                      positiveOnClickHandler={() => handleClosureStatus1(item)}
                      setloading={setMsgLoading}
                      isDisable={msgLoading}
                    >
                      <Typography>{msgData}</Typography>
                    </BasicModal>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {data && data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}

        {data && data.length === 0 ? null : (
          <Pagination
            Page={page}
            TotalNoNumber={totalRecords}
            nextPageHandler={page === totalRecords ? null : nextPageHandler}
            prevPageHandler={prevPageHandler}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default MonthEndUploadTable;
