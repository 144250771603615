import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import {
  getAllDeals,
  setSortValue,
  setSortName,
} from "../../redux/Deals/dealSlice";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "dateformat";
import _ from "lodash";
import styles from "./Table.module.css"
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { ArrowDownward } from "@mui/icons-material";
import { actionTypo, actionsIcon, arrowDownwardStyle, arrowUpwardStyle, midWidthStyle, norecordFoundStyle, originatorNameStyle, statusTopBox, timeStyle } from "./TableCSS";

const DealTable = ({
  data,
  viewActionClickHandler,
  header,
  page,
  filterName,
  totalRecords,
  nextPageHandler,
  prevPageHandler,
}) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  console.log({ data });
  console.log({ page, totalRecords });

  let finalData = [...data];
  console.log({ finalData });



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const Textcolor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "DONE"
        ? "#277742"
        : statusType === "REJECTED"
          ? "#8D3431"
          : statusType === "CLOSED"
            ? "#2C4584"
            : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "REJECTED" || statusType === "FAILED"
          ? "#FCE6E5"
          : statusType === "CLOSED"
            ? "#E4EAFA"
            : "#FFF4E7";

    // const status = statusType.toLowerCase()

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p className={styles.statusPTag} >
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const Actions = ({ isDealAdded, dealId, label, Icon, item }) => {
    return (
      <div
        onClick={() =>
          // item.status !== "CLOSED" ? viewActionClickHandler(item) : null       //condtion change
          viewActionClickHandler(item)
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          <Icon
            // sx={{
            //   // color: item?.status !== "CLOSED" ? "#365069" : "#AFB9C3",         //condtion change
            //   color: "#365069"
            // }}
            sx={actionsIcon}
          ></Icon>

          <Typography sx={actionTypo}
            // sx={{
            //   // color: item?.status !== "CLOSED" ? "#365069" : "#AFB9C3",          //condtion change
            //   color: "#365069",
            //   fontSize: "14px",
            // }}
          >
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  const formatRequestedPool = (value) => {
    if (value !== undefined) {
      console.log({ value });
      numberFormat(Number(value));
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  var nf = new Intl.NumberFormat();

  const headerFilters = {
    "Deal Name": "deal",
    "Asset Class": "assetClass",
    "Requested Pool Size": "poolSize",
    Transaction: "transaction",
    "Deal Creation Date": "startDate",
    "Created By": "createdBy",
  };

  const MapHeader = ({ data }) => {
    const [sort, setSort] = useState("");
    useEffect(() => {
      if (sort !== "") {
        // setCallFunc({ data, sort });
        dispatch(setSortValue(!dealState.sortValue));
        dispatch(setSortName(data));
        let sortName = headerFilters[`${data}`];
        if (sortName !== undefined) {
          dispatch(
            getAllDeals({
              currentPage: page,
              limit: 10,
              sortName: sortName,
              filterName,
              sortOrder: dealState.sortValue ? 1 : -1,
            })
          );
        }
      }
      return;
    }, [sort]);

    console.log(headerFilters[data]);
    console.log(dealState?.sortFieldName);

    console.log(data === dealState?.sortFieldName);
    return (
      <StyledTableCell
        onClick={() =>
          data == "Action" || data == "Deal Status" || data == "Loan Status"
            ? null
            : setSort(!sort)
        }
        style={{
          cursor:
            data == "Action" || data == "Deal Status" || data == "Loan Status"
              ? null
              : "pointer",
        }}
      >
        {data}

        {data !== dealState?.sortFieldName ? null : dealState?.sortValue !==
          true ? (
          <ArrowUpward sx={arrowUpwardStyle} />
        ) : (
          <ArrowDownward sx={arrowDownwardStyle} />
        )}
      </StyledTableCell>
    );
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));
  let activeRole = localStorage.getItem("activeRole")


  function getPoolStatus(item) {
    let status = ""

    if (item?.poolUploadFinal && (item?.poolUploadFinal?.primaryCurrentStatus === "DONE" || item?.poolUploadFinal?.primaryCurrentStatus === "PARTIALLY_DONE")) {
      // checking the secondary status for showing pool submmitted or pool uploaded
      if (item?.poolUploadFinal?.secondaryCurrentStatus !== "PENDING") {
        status = "Pool Submitted"
      } else {
        status = "Pool Uploaded"
      }
    } else {
      status = "Pool Upload"
    }
    return status;
  }

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    // var finalTime = hours + ":" + minutes + " " + AmOrPm;
    return finalTime;
  }
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={midWidthStyle}>
              {header.map((data) => {
                return <MapHeader data={data} />;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((item) => {
              return (
                <TableRow key={item.id || item._id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>
                  {activeRole === "L1" ? (
                    <StyledTableCell component="th" scope="row">
                      {moment(item.createdAt).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={originatorNameStyle}
                      >
                        {convertTime(item.createdAt) || ""}
                      </Typography>
                      { }
                    </StyledTableCell>
                  ) : activeRole === "L2" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                    </StyledTableCell>
                  ) : activeRole === "Originator" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                    </StyledTableCell>
                  ) : null}

                  {activeRole === "L1" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.loanType === undefined && "NA"}
                      {item?.loanType === "" && "NA"}
                      {item?.loanType || item?.loanTypeId}
                    </StyledTableCell>
                  ) : activeRole === "L2" ? (
                    <StyledTableCell component="th" scope="row">
                      {item.requestedDealAmount === undefined ||
                        item.requestedDealAmount === null ||
                        item.requestedDealAmount === ""
                        ? "NA"
                        : numberFormat(Number(item?.requestedDealAmount))}
                    </StyledTableCell>
                  ) : activeRole === "Originator" ? (
                    <StyledTableCell component="th" scope="row">
                      {item.requestedDealAmount === undefined ||
                        item.requestedDealAmount === null ||
                        item.requestedDealAmount === ""
                        ? "NA"
                        : numberFormat(Number(item?.requestedDealAmount))}
                    </StyledTableCell>
                  ) : null}

                  {activeRole === "L1" ? (
                    <StyledTableCell component="th" scope="row">
                      {item.requestedDealAmount === undefined ||
                        item.requestedDealAmount === null ||
                        item.requestedDealAmount === ""
                        ? "NA"
                        : numberFormat(Number(item?.requestedDealAmount))}
                    </StyledTableCell>
                  ) : activeRole === "L2" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.transactionType === undefined && "NA"}
                      {item?.transactionType === "" && "NA"}
                      {item?.transactionType}
                    </StyledTableCell>
                  ) : activeRole === "Originator" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.entity === undefined && "NA"}
                      {item?.entity === "" && "NA"}
                      {item?.entity}
                    </StyledTableCell>
                  ) : null}

                  {activeRole === "L1" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.transactionType === undefined && "NA"}
                      {item?.transactionType === "" && "NA"}
                      {item?.transactionType}
                    </StyledTableCell>
                  ) : activeRole === "L2" ? (
                    <StyledTableCell component="th" scope="row">
                      {moment(item.createdAt).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={timeStyle}
                      >
                        {convertTime(item.createdAt) || ""}
                      </Typography>
                    </StyledTableCell>
                  ) : activeRole === "Originator" ? (
                    <StyledTableCell component="th" scope="row">
                      {moment(item.createdAt).format("D MMM YYYY")}
                      <br />
                      <Typography
                        sx={originatorNameStyle}
                      >
                        {convertTime(item.createdAt) || ""}
                      </Typography>
                    </StyledTableCell>
                  ) : null}

                  {activeRole === "L1" ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item.status}
                      // CreditFilterAdded={}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : activeRole === "L2" ? (
                    <StyledTableCell component="th" scope="row">
                      {item?.createdBy === undefined && "NA"}
                      {item?.createdBy === "" && "NA"}
                      {item?.createdBy !== undefined}
                      {item?.createdBy}
                      {/* {
                        item?.createdByData.map((names, index) => (
                          <div key={index}>
                          {names?.name}
                          </div>
                        ))
                      } */}
                    </StyledTableCell>
                  ) : null}

                  {activeRole === "L1" ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={`${item?.loanFilter !== undefined
                          ? "Created"
                          : "Not Created"
                          }`}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : activeRole === "L2" ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item.status}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : activeRole === "Originator" ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={`${item?.poolUploadFinal && item?.poolUploadFinal?.primaryCurrentStatus === "REUPLOAD"
                          ? "Pool Re-upload"
                          : getPoolStatus(item)
                          }`}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : null}
                  {activeRole === "Originator" ? (
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item?.loanUploadStatus}
                      ></StatusContainer>
                    </StyledTableCell>
                  ) : null}

                  {/* <StyledTableCell component="th" scope="row">
                    {item?.transactionType}
                  </StyledTableCell> */}

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions
                        label="View"
                        dealId={item.id}
                        item={item}
                        Icon={VisibilityIcon}
                      ></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>

      {
        data && data.length === 0 ? null : (
          <Pagination
            Page={page}
            TotalNoNumber={totalRecords}
            nextPageHandler={page === totalRecords ? null : nextPageHandler}
            prevPageHandler={prevPageHandler}
          />
        )
      }
    </div >
  );
};

export default DealTable;
