import axios from "axios";
import { Redirect } from "react-router-dom";

const getApi = (url, setData = () => {}) => {
  const res = axios.get(url);
  res.then((response) => {
    setData(response.data);
  });
  return res;
};

// get amount formated with commas and decimal
export const getFormatedAmount = (amount) => {
  if (!amount) return "₹ 0";

  let formattedAmount = "₹ ";

  // Convert the amount to a number and round it to 2 decimal places
  amount = parseFloat(amount).toFixed(2);

  // Define the suffixes for thousands, lakhs, and crores
  const suffixes = ["", "K", "Lac", "Cr"];

  // Initialize the index for suffixes (e.g., 0 for "", 1 for "K", etc.)
  let suffixIndex = 0;

  // Loop through the amount and apply the appropriate suffix
  while (amount >= 1000 && suffixIndex < suffixes.length - 1) {
    amount /= 1000;
    suffixIndex++;
  }

  // Format the amount with commas and append the suffix
  formattedAmount +=
    amount.toLocaleString("en-IN", {
      maximumFractionDigits: 2,
    }) +
    " " +
    suffixes[suffixIndex];

  return formattedAmount;
};

export const getFileSize = (fileSizeInBytes) => {
  if (isNaN(fileSizeInBytes) || fileSizeInBytes < 0) {
    return "Invalid file size";
  }

  const units = ["B", "KB", "MB", "GB", "TB"]; // Add more units as needed
  let unitIndex = 0;

  while (fileSizeInBytes >= 1024 && unitIndex < units.length - 1) {
    fileSizeInBytes /= 1024;
    unitIndex++;
  }

  const unit = units[unitIndex];
  const formattedSize = fileSizeInBytes.toFixed(2);
  console.log({ formattedSize });
  return `${formattedSize} ${unit}`;
};

export const withAuthorization = (allowedUserTypes) => (WrappedComponent) => {
  const AuthorizedComponent = (props) => {
    console.log("withAuthorization", props);
    // Get the user type from your authentication system
    let userType = ["L1", "L2", "AUDITOR", "ORIGINATOR"];

    for (let i = 0; i < 8; i++) {
      userType.push(`APPROVER ${i + 1}`);
    }

    console.log({ allowedUserTypes });
    console.log({ userType });

    if (allowedUserTypes.includes(userType)) {
      // Render the protected component if the user type is allowed
      return <WrappedComponent {...props} />;
    }

    // Redirect to a different page if the user type is not allowed
    return <Redirect to="/unauthorized" />;
  };

  return AuthorizedComponent;
};
