import React from "react";
import { MenuItem, Select, Box, Checkbox, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SelectComponent = ({
  placeholder,
  optionList,
  value,
  onClickHandler,
  index,
  compWidth,
  fieldWidth,
  selectedOptions,
  section,
}) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        minWidth: compWidth,
        background: "#EBEEF0",
      },
    },
  };

  return (
    <Select
      IconComponent={ExpandMoreIcon}
      value={value}
      onChange={onClickHandler}
      displayEmpty
      index={index}
      sx={{
        width: fieldWidth,
      }}
      size={"small"}
      variant="standard"
      MenuProps={MenuProps}
      renderValue={
        value === ""
          ? () => <Placeholder>{placeholder}</Placeholder>
          : undefined
      }
    >
      {optionList?.filter((option) => {
          if (option === "Others") {
            return true;
          }
          if (!selectedOptions[section].includes(option) || option === value) {
            return true;
          } else {
            return false;
          }
        })
        .map((item) => {
          return (
            <MenuItem key={item + section + index} value={item}>
              {item}
            </MenuItem>
          );
        })}
    </Select>
  );
};

const Placeholder = ({ children }) => {
  return <div style={{ color: "#AFB9C3" }}>{children}</div>;
};
