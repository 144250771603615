import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";

const initialState = {
  histroyData: null,
  histroyUserList: null,
  loading: false,
  error: null,
  success: null,
  totalPages: 0,
};

export const fetchHistroyData = createAsyncThunk(
  "fetchHistroyData",
  async ({ id, ChangedById, limit = 10, currentPage = 0 }, rejectWithValue) => {
    let changedUserByID;
    if (ChangedById == undefined) {
      changedUserByID = "";
    } else {
      changedUserByID = ChangedById;
    }
    try {
      const histroydata = await instance.get(
        `/portfolio-service/statusHistory?documentId=${id}&changedById=${changedUserByID}&page=${currentPage}&limit=${limit}`
      );
      return histroydata;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const fetchFiltersName = createAsyncThunk(
  "fetchFiltersName",
  async (id, rejectWithValue) => {
    try {
      const histroyusers = await instance.get(
        `/portfolio-service/historyUsers?documentId=${id}`
      );
      return histroyusers;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const HistroySlice = createSlice({
  name: "HistroySlice",
  initialState,
  reducers: {
    clearSucessAndErrorAction: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistroyData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchHistroyData.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.totalPages = payload?.totalPages;
      state.histroyData = payload.data.data.data;
    });
    builder.addCase(fetchHistroyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.histroyData = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });

    builder.addCase(fetchFiltersName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFiltersName.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.histroyUserList = payload.data.data;
    });
    builder.addCase(fetchFiltersName.rejected, (state, { payload }) => {
      state.loading = false;
      state.histroyUserList = null;
      state.error =
        payload?.message || payload?.error
          ? payload?.message || payload?.error
          : "Something went wrong!";
    });
  },
});

export const { clearSucessAndErrorAction } = HistroySlice.actions;

export const histroyReducer = HistroySlice.reducer;
