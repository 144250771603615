import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import dateFormat from "dateformat";
import _ from "lodash";
import { useHistory } from "react-router-dom";

//? moment
import moment from "moment";
import { actionTypo, actionsIcon, norecordFoundStyle, statusTopBox } from "./TableCSS";

const OriginatorTable = ({
  nextPageHandler,
  prevPageHandler,
  rows,
  data,
  actionOnClick,
  page,
  limit,
  totalPages,
}) => {
  let sortedData = data?.length > 0 ? [...data] : [];
  console.log({ data });
  console.log({ sortedData });
  sortedData.sort((a, b) => {
    if (a.status > b.status) {
      return -1;
    }
    if (a.status < b.status) {
      return 1;
    }
    return 0;
  });
  
  let activeRole = localStorage.getItem("activeRole");

  let filterData = data.filter((item) => item.status === "PENDING");
  console.log(filterData);
  let newData = [...filterData, ...sortedData];
  console.log(newData);
  let finalData = _.uniqBy(newData, (e) => e.name);

  console.log(finalData);
  var history = useHistory();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
      minWidth: "200px",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  const StatusContainer = ({ statusType }) => {
    const status = statusType.toLowerCase();
    console.log(
      status
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    );
    const Textcolor =
      statusType === "APPROVED"
        ? "#277742"
        : statusType === "REJECTED"
        ? "#8D3431"
        : statusType === "ON HOLD"
        ? "#2C4584"
        : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED"
        ? "#E3F6E9"
        : statusType === "REJECTED"
        ? "#FCE6E5"
        : statusType === "ON HOLD"
        ? "#E4EAFA"
        : "#FFF4E7";
    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
        }}
      >
        <div
          style={{
            backgroundColor: boxBackgroudColor,
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <p  style={{ fontSize: "12px", fontWeight: "800" }}>
            {status
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const Actions = ({ isDealAdded, label, Icon, id, onClickHandler }) => {
    return (
      <div onClick={onClickHandler} style={{ cursor: "pointer" }}>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
          // paddingLeft="30px"
          sx={{
            justifyContent: "start",
          }}
        >
          <Icon sx={actionsIcon}></Icon>

          <Typography sx={actionTypo}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {rows &&
                rows.map((row, index) => {
                  return <StyledTableCell key={index}>{row}</StyledTableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData &&
              finalData.length !== 0 &&
              finalData.map((item, index) => {
                return (
                  <TableRow key={item.index}>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.name}
                    </StyledTableCell1>
                    <StyledTableCell component="th" scope="row">
                      {item?.originatorTypeIdData?.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {moment(item?.createdAt).format("D MMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <StatusContainer
                        statusType={item?.status.replace("_", " ")}
                      ></StatusContainer>
                    </StyledTableCell>
                    <TableCell sx={{ alignItems: "center" }}>
                      <StyledNoBorderTableCell>
                        <Actions
                          label="View"
                          id={item?._id}
                          Icon={VisibilityIcon}
                          onClickHandler={() => {
                            if (activeRole == "L1") {
                              history.push(
                                `/editoriginator/view/details/${item._id}`
                              );
                            } else {
                              history.push(
                                `/view/${item._id}/l2originatorsdetails`
                              );
                            }
                          }}
                        ></Actions>
                      </StyledNoBorderTableCell>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {finalData.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
        {finalData.length === 0 ? (
          ""
        ) : (
          <Pagination
            TotalNoNumber={totalPages}
            Page={page}
            nextPageHandler={nextPageHandler}
            prevPageHandler={prevPageHandler}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default OriginatorTable;
