import React, { useCallback, useEffect, useState } from "react";
import WrapperComponent from "../../components/Wrapper/WrapperDocument";
import { Grid, Button } from "@mui/material";
import Documents from "./Documents";
import { Colors } from "../../theme/theme";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { uploadDealDocuments } from "../../redux/Deals/dealSlice";
import { showAlertAction } from "../../redux/app/appSlice";
import { isAuthenticated } from "../../helper/AuthHelper";
import instance from "../../API/BaseUrl";
import styles from "./Deal.module.css";
import { btnStyle } from "./DealCSS";
import { setStatus } from "../../redux/Loans/loanSlice";

export default function DocumentForm() {
  const dispatch = useDispatch();

  const [documentCount, setDocumentCount] = useState({
    supportingFieldsLength: 2,
    legalDocumentsFieldsLength: 2,
    lossEstimateReportLength: 2,
    otherDocumentsLength: 2,
    termSheetLength: 1,
  });

  const [selectedOptions, setSelectedOptions] = useState(Array(2).fill(""));
  const [selectedOptionsV2, setSelectedOptionsV2] = useState(Array(2).fill(""));
  const [selectedOptionsV3, setSelectedOptionsV3] = useState(Array(2).fill(""));
  const [selectedOptionsV4, setSelectedOptionsV4] = useState(Array(2).fill(""));
  const [selectedOptionsV5, setSelectedOptionsV5] = useState(Array(2).fill(""));
  const [state, setState] = useState(null);
  const [documentKey, setDocumentKey] = useState("");

  const params = useParams();

  const [ids, setIds] = useState({
    "Disbursement Request Letter": [{}],
    "Management Undertaking": [{}],
    "Pool Credit Compliance CA Certificate": [{}],
    "Cancelled Cheque": [{}],
    "RBI Annexure": [{}],
  });

  const [otherDocs, setOtherDocs] = useState([]);
  const [legalDocumentIds, setLegalDocumentIds] = useState({
    "Deed Of Assignment": [{}],
    "Servicing Agreement": [{}],
    "Power Of Attorney 1": [{}],
    "Power Of Attorney 2": [{}],
    "Signed Termsheet": [{}],
  });
  const [lossEstimateDocumentIds, setLossEstimateDocumentIds] = useState({
    "LE Reports": [{}],
  });
  const [otherDocumentFields, setOtherDocumentFields] = useState({
    "Finalised Pool": [{}],
    "CIBIL Scrub Report": [{}],
    "Entity Details": [{}],
    "Approval Memo": [{}],
    "KYC Report": [{}],
  });

  const [termSheetDocumentIds, setTermSheetDocumentIds] = useState({
    "Term Sheet": [{}],
  });

  const [supportingValues, setSupportingValues] = useState([
    {
      name: "Disbursement Request Letter",
      data: "Disbursement Request Letter",
      enable: true,
    },
    {
      name: "Management Undertaking",
      data: "Management Undertaking",
      enable: true,
    },
    {
      name: "Pool Credit Compliance CA Certificate",
      data: "Pool Credit Compliance CA Certificate",
      enable: true,
    },
    {
      name: "Cancelled Cheque",
      data: "Cancelled Cheque",
      enable: true,
    },
    {
      name: "RBI Annexure",
      data: "RBI Annexure",
      enable: true,
    },
    {
      name: "Others",
      data: "Others",
      enable: true,
    },
  ]);
  const legalDocuments = [
    {
      name: "Deed Of Assignment",
      data: "Deed Of Assignment",
    },
    {
      name: "Servicing Agreement",
      data: "Servicing Agreement",
    },
    {
      name: "Power Of Attorney 1",
      data: "Power Of Attorney 1",
    },
    {
      name: "Power Of Attorney 2",
      data: "Power Of Attorney 2",
    },
    {
      name: "Signed Termsheet",
      data: "Signed Termsheet",
    },
    {
      name: "Others",
      data: "Others",
    },
  ];

  const otherDocumentsValues = [
    {
      name: "Finalised Pool",
      data: "Finalised Pool",
    },
    {
      name: "CIBIL Scrub Report",
      data: "CIBIL Scrub Report",
    },
    {
      name: "Entity Details",
      data: "Entity Details",
    },
    {
      name: "Approval Memo",
      data: "Approval Memo",
    },
    {
      name: "KYC Report",
      data: "KYC Report",
    },

    {
      name: "Others",
      data: "Others",
    },
  ];

  const lossEstimateReport = [
    {
      name: "LE Reports",
      data: "LE Reports",
    },
    {
      name: "Others",
      data: "Others",
    },
  ];

  const termsheetValues = [
    {
      name: "Term Sheet",
      data: "Term Sheet",
    },
    {
      name: "Others",
      data: "Others",
    },
  ];

  const [dealDocuments, setDealDocuments] = useState(null);

  useEffect(() => {
    dispatch(setStatus(5));
  }, []);

  useEffect(() => {
    instance
      .get(`/portfolio-service/getDealDocuments?dealId=${params?.dealId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + isAuthenticated("Token"),
        },
      })
      .then((res) => {
        console.log(res?.data?.data);
        setDealDocuments(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.dealId]);

  useEffect(() => {
    const processDocumentType = (
      documents,
      documentIds,
      values,
      setDocumentIds,
      setSelectedOptions,
      documentCountKey
    ) => {
      if (documents?.length > 0) {
        const documentFiles = documents;
        const documentFilesIds = {};

        documentFiles?.forEach((item) => {
          documentFilesIds[item.key] = item?.values?.map((value) => {
            return {
              fileId: value,
              fileName: getFileNameFromId(value),
              fileSize: 0,
            };
          });
        });

        const selectedOptions = documentFiles?.map((item) => {
          if (!values?.map((item) => item?.data)?.includes(item.key)) {
            return "Others";
          }
          return item.key;
        });

        Object.keys(documentIds).forEach((key) => {
          if (!documentFilesIds[key]) {
            documentFilesIds[key] = [{}];
          }
        });

        setDocumentIds(documentFilesIds);
        setSelectedOptions(selectedOptions);

        setDocumentCount((data) => ({
          ...data,
          [documentCountKey]: documents.length,
        }));
      }
    };

    processDocumentType(
      dealDocuments?.supportingDocuments,
      ids,
      supportingValues,
      setIds,
      setSelectedOptions,
      "supportingFieldsLength"
    );
    processDocumentType(
      dealDocuments?.legalDocuments,
      legalDocumentIds,
      legalDocuments,
      setLegalDocumentIds,
      setSelectedOptionsV2,
      "legalDocumentsFieldsLength"
    );
    processDocumentType(
      dealDocuments?.lossEstimateReports,
      lossEstimateDocumentIds,
      lossEstimateReport,
      setLossEstimateDocumentIds,
      setSelectedOptionsV3,
      "lossEstimateReportLength"
    );
    processDocumentType(
      dealDocuments?.termsheets,
      termSheetDocumentIds,
      termsheetValues,
      setTermSheetDocumentIds,
      setSelectedOptionsV4,
      "termSheetLength"
    );
    processDocumentType(
      dealDocuments?.otherDocuments,
      otherDocumentFields,
      otherDocumentsValues,
      setOtherDocumentFields,
      setSelectedOptionsV5,
      "otherDocumentsLength"
    );
  }, [dealDocuments, params.dealId]);

  const getFileNameFromId = (id) => {
    return dealDocuments?.files?.filter((item) => item._id === id)?.[0]
      ?.filename;
  };

  function onClickButton(name) {
    console.log(name);
    setDocumentCount((data) => {
      return { ...data, [name]: data[name] + 1 };
    });

    switch (name) {
      case "supportingFieldsLength":
        setSelectedOptions((prevValues) => [...prevValues, ""]);
        break;
      case "legalDocumentsFieldsLength":
        setSelectedOptionsV2((prevValues) => [...prevValues, ""]);
        break;
      case "lossEstimateReportLength":
        setSelectedOptionsV3((prevValues) => [...prevValues, ""]);
        break;
      case "termSheetLength":
        setSelectedOptionsV4((prevValues) => [...prevValues, ""]);
        break;
      case "otherDocumentsLength":
        setSelectedOptionsV5((prevValues) => [...prevValues, ""]);
        break;
      default:
        break;
    }
  }

  console.log({ ids });

  const getId = (item) => {
    console.log(item);
    return item?.fileId || "";
  };

  const finalData = useCallback(() => {
    const termsheetsFiles = [];
    const lossEstimateReports = [];
    const supportingDocuments = [];
    const legalDocuments = [];
    const otherDocuments = [];

    function getParsedData(obj, arr) {
      Object.keys(obj).forEach((key) => {
        // check if field is not empty
        if (
          obj[key]?.length > 0 &&
          obj[key]?.map(getId).filter((item) => item !== "").length > 0
        ) {
          arr.push({
            key,
            values: obj[key].map(getId).filter((item) => item !== ""),
          });
        }
      });
    }

    getParsedData(ids, supportingDocuments);
    getParsedData(legalDocumentIds, legalDocuments);
    getParsedData(lossEstimateDocumentIds, lossEstimateReports);
    getParsedData(termSheetDocumentIds, termsheetsFiles);
    getParsedData(otherDocumentFields, otherDocuments);

    return {
      dealId: params.dealId,
      supportingDocuments,
      legalDocuments,
      lossEstimateReports,
      termsheets: termsheetsFiles,
      otherDocuments,
    };
  }, [
    ids,
    legalDocumentIds,
    lossEstimateDocumentIds,
    otherDocumentFields,
    termSheetDocumentIds,
  ]);

  function onClickHandler() {
    dispatch(uploadDealDocuments({ ...finalData() })).then((res) => {
      dispatch(showAlertAction(res?.payload?.data?.message));
    });

    // go back
    // eslint-disable-next-line no-restricted-globals
    history.back();
  }

  return (
    <div className={styles.paddingTopStyle}>
      <WrapperComponent
        title={"Upload Supporting Documents"}
        showButton={true}
        onClickButton={onClickButton}
        name="supportingFieldsLength"
      >
        <Grid container spacing={4}>
          {Array.from(
            { length: documentCount.supportingFieldsLength },
            () => " "
          ).map((data, index) => {
            return (
              <Documents
                key={"supportingDocuments" + index}
                index={index}
                data={supportingValues}
                selectedValue={selectedOptions[index]}
                setSelectedOptions={setSelectedOptions}
                ids={ids}
                setIds={setIds}
                selectedOptions={selectedOptions}
                setState={setState}
                setOtherDocs={setOtherDocs}
                otherDocs={otherDocs}
              />
            );
          })}
        </Grid>
      </WrapperComponent>
      <WrapperComponent
        title={"Upload Legal Documents"}
        showButton={true}
        onClickButton={onClickButton}
        name="legalDocumentsFieldsLength"
      >
        <Grid container spacing={4}>
          {Array.from(
            { length: documentCount.legalDocumentsFieldsLength },
            () => " "
          ).map((data, index) => {
            return (
              <Documents
                key={"legalDocuments" + index}
                data={legalDocuments}
                index={index}
                ids={legalDocumentIds}
                selectedValue={selectedOptionsV2[index]}
                selectedOptions={selectedOptionsV2}
                setSelectedOptions={setSelectedOptionsV2}
                setIds={setLegalDocumentIds}
                setOtherDocs={setOtherDocs}
                otherDocs={otherDocs}
                setState={setState}
              />
            );
          })}
        </Grid>
      </WrapperComponent>
      <WrapperComponent
        title={"Upload Loss Estimate Report"}
        showButton={true}
        onClickButton={onClickButton}
        name="lossEstimateReportLength"
      >
        <Grid container spacing={4}>
          {Array.from(
            { length: documentCount.lossEstimateReportLength },
            () => " "
          ).map((data, index) => {
            return (
              <Documents
                key={"lossEstimateReport" + index}
                data={lossEstimateReport}
                index={index}
                ids={lossEstimateDocumentIds}
                selectedValue={selectedOptionsV3[index]}
                selectedOptions={selectedOptionsV3}
                setSelectedOptions={setSelectedOptionsV3}
                setIds={setLossEstimateDocumentIds}
                setOtherDocs={setOtherDocs}
                otherDocs={otherDocs}
                setState={setState}
              />
            );
          })}
        </Grid>
      </WrapperComponent>
      <WrapperComponent
        title={"Signed Term Sheet"}
        showButton={true}
        name={"termSheetLength"}
        onClickButton={onClickButton}
      >
        <Grid container spacing={4}>
          {Array.from({ length: documentCount.termSheetLength }, () => " ").map(
            (data, index) => {
              return (
                <Documents
                  key={"termsheet" + index}
                  data={termsheetValues}
                  gridWidth={12}
                  index={index}
                  ids={termSheetDocumentIds}
                  selectedValue={selectedOptionsV4[index]}
                  selectedOptions={selectedOptionsV4}
                  setSelectedOptions={setSelectedOptionsV4}
                  setIds={setTermSheetDocumentIds}
                  setOtherDocs={setOtherDocs}
                  otherDocs={otherDocs}
                  setState={setState}
                  selectWidth="65%"
                />
              );
            }
          )}
        </Grid>
      </WrapperComponent>

      <WrapperComponent
        title={"Upload Other Report"}
        onClickButton={onClickButton}
        showButton={true}
        name="otherDocumentsLength"
      >
        <Grid container spacing={4}>
          {Array.from(
            { length: documentCount.otherDocumentsLength },
            () => " "
          ).map((data, index) => {
            return (
              <Documents
                key={"otherDocuments" + index}
                data={otherDocumentsValues}
                index={index}
                selectedValue={selectedOptionsV5[index]}
                selectedOptions={selectedOptionsV5}
                setSelectedOptions={setSelectedOptionsV5}
                setOtherDocs={setOtherDocs}
                otherDocs={otherDocs}
                setState={setState}
                setIds={setOtherDocumentFields}
                ids={otherDocumentFields}
              />
            );
          })}
        </Grid>
      </WrapperComponent>
      <div className={styles.btnWrapper}
      >
        <Button
          variant="contained"
          sx={btnStyle}
          type="submit"
          onClick={onClickHandler}
          disabled={
            !(
              finalData()?.supportingDocuments?.length > 0 ||
              finalData()?.legalDocuments?.length > 0 ||
              finalData()?.lossEstimateReports?.length > 0 ||
              finalData()?.termsheets?.length > 0 ||
              finalData()?.otherDocuments?.length > 0
            )
          }
        >
          Upload Documents
        </Button>
      </div>
    </div>
  );
}
