import React from "react";
import WrapperComponent from "./Wrapper/WrapperComponent";
import { Grid } from "@mui/material";
import LabelWithDownload from "./LabelWithDownload";
import { useSelector } from "react-redux";

export default function AllDocuments() {
  const dealState = useSelector((state) => state.deal);

  const poolUploadFinal = dealState?.filterdata?.poolUploadFinal;
  const documentData = [
    {
      label: "Finalized Pool",
      fileId: poolUploadFinal?.kycVerificationSuccessFile?._id,
      fileName: poolUploadFinal?.kycVerificationSuccessFile?.filename,
    },
    {
      label: "Term Sheet",
      fileId: poolUploadFinal?.termsheetFinalFile?._id,
      fileName: poolUploadFinal?.termsheetFinalFile?.filename,
    },
    {
      label: "KYC Report",
      fileId: poolUploadFinal?.kycVerificationSuccessFile?._id,
      fileName: poolUploadFinal?.kycVerificationSuccessFile?.filename,
    },
    {
      label: "Investment Memo",
      fileId: dealState?.filterdata?.investmentMemo?.file?._id,
      fileName: dealState?.filterdata?.investmentMemo?.file?.filename,
    },
    {
      label: "Entity Detail",
      fileId: dealState?.filterdata?.entityDetail?.file?._id,
      fileName: dealState?.filterdata?.entityDetail?.file?.filename,
    },
    {
      label: "Credit Bureau Scrub Report",
      fileId: poolUploadFinal?.uploadedCibilScoreFile?._id,
      fileName: poolUploadFinal?.uploadedCibilScoreFile?.filename,
    },
  ];

  if (dealState?.filterdata?.entityDetail?.leReports.length > 0) {
    console.log(
      "dealState?.filterdata?.entityDetail?.leReports",
      dealState?.filterdata?.entityDetail?.leReports
    );
    dealState?.filterdata?.entityDetail?.leReports.forEach((item, index) => {
      console.log("item", item?.file);
      documentData.push({
        label: `LE Report - ${index + 1}`,
        fileId: item?._id,
        fileName: item?.filename,
      });
    });
  }

  console.log("documentData", documentData.length);

  return (
    <WrapperComponent title="Related Documents">
      <>
        <div
          style={{
            paddingTop: "14px",
          }}
        >
          <Grid container spacing={4}>
            {documentData.map((item, index) => (
              <>
                {item?.fileId && (
                  <Grid item xs={4}>
                    <LabelWithDownload
                      fileName={item?.fileName}
                      fileId={item?.fileId}
                      label={item?.label}
                    />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </div>
      </>
    </WrapperComponent>
  );
}
