import { Colors } from "../../theme/theme";

export const uploadBoxStyle = {
    padding: "16px",
    borderRadius: "10px",
    borderWidth: "1px",
    minHeight: "70px", 
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);"
}

export const uploadStackStyle = {
    alignItems:"center",
    justifyContent:"center",
    flexDirection: "column"
}

export const uploadTypoGraphy = {
    fontSize:"14px",
    fontWeight:"400",
    color: Colors.piraml_light_blue
}

export const supportFileTypoGraphy = {
    fontSize:"12px",
    fontWeight:"200",
    color: Colors.Piramal_Blue_PB_60
}

export const checkCircleIcon = {
    color: "#41C66E", 
    width: "20px", 
    height: "20px"
}

export const addMoreTopBox = {
    paddingLeft: "16px",
    alignItems: "flex-end",
    justifyContent: "flex-end",
}

export const addMoreMidBox = {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    display: "flex",
}

export const btnStyle = {
    fontSize: "12px",
    fontWeight: "700",
    color: Colors.piraml_light_blue,
    textTransform: "none",
}

// CTAWrapperHandler..............

export const topBoxStyle = {
    backgroundColor:"white",
    minHeight:"144px",
    maxHeight:"auto",
    minWidth:"auto",
    maxWidth:"auto",
    boxShadow:"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderRadius: "8px",
    padding:"16px",
    margin:"16px",
}

export const stackStyle = {
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"space-between",
    display:"flex"
}

export const displayStyle = {
    display: "flex"
}

export const titleTypo = {
    fontSize:"16px",
    fontWeight:"700",
    paddingBottom:"16px"
}

export const checkCircleIconStyle = {
    color: "#41C66E",
    paddingLeft: "8px",
    width: "45px",
}

export const ctaBtn = {
    padding: "8px 12px",
    background: Colors.piramal_orange,
    borderRadius: "8px",
    height: "32px",
    textTransform: "none",
    color: "white",
    "&:hover": {
      backgroundColor: Colors.piramal_orange,
    },
}

export const ctaBtnStyle = (isDisabled) => {
    if(isDisabled){
        return {
            ...ctaBtn,
            background: Colors.piramal_light_orange
        }
    }
    return ctaBtn
}


// OriginatorSelect.jsx///
 export const downloadRoundedIconStyle = {
    color: "#5E7387",
 }

//  WrapperComponentV2.jsx ////

export const viewVoucherStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,
    textTransform: "none",
    margin: "4px 20px",
    width: "156px",
    "&:hover": {
        backgroundColor: Colors.piramal_orange,
    },
    "&:disabled": {
        backgroundColor: "#FAC3B3",
        color: "white",
    },
}

// WrapperComponent.js///////

export const titleBoxWrapper = {
    display: "flex",
    justifyContent: "space-between",
}
 export const titleBoxStyle = (RegenrateEntry) => {
    if(RegenrateEntry){
        return {
            ...titleBoxWrapper,
            width: "100%"
        }
    }
    return titleBoxWrapper
 }

 export const entryBtn = {
    display: "flex",
    gap: "5px", 
    height: "20px", 
    pointerEvents: "none", 
    color:  "#9eb6cd", 
    cursor: "pointer" 
 }

 export const entryButtonWrapper = (regenratekey) => {
    if(regenratekey){
        return {
            ...entryBtn,
            pointerEvents: "auto",
            color: "#365069"
        }
    }
    return entryBtn
 }

 export const settingsOutlinedIconStyle = {
    height: "20px", 
    width: "20px" 
 }

 export const entryTypo = {
    fontSize: "12px",
    fontWeight: "700"
 }