import React from "react";

//? Router
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../helper/AuthHelper";

//? Paths
import Paths from "../components/constants/Path";

// Private Route
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
    
      render={({ location }) =>
        //? if we will get user token in localstorage then we will render component otherwise redirect the user to home page
        isAuthenticated("Token") ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: Paths.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
