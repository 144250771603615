import { Grid, Link, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageStatusAction } from "../../../redux/L1/kycDocumentsRequestSlice";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import TabFooter from "../TabFooter/TabFooter";
import DescriptionIcon from "@mui/icons-material/Description";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import SettingsIcon from "@mui/icons-material/Settings";
import OutlineButton from "../../Buttons/OutlineButton";
import Loader from "../../../utils/Loader";
import Styles from './L1KYCDocumentsRequestTabs.module.css'
import { CreditBureauScrubReportTypograhy } from "./L1KYCDocumentsRequestTabsCSS";
const CreditBureauScrubReport = ({ nextTabHandler, setValue }) => {
  let creditScrubState = useSelector((state) => state.creditScrub);
  let dealState = useSelector((state) => state.deal);
  let dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "creditBureauScrubReport" }));
    nextTabHandler();
  };

  console.log({ dealState });

  return (
    <>
      {creditScrubState?.loading ? (
        <Loader />
      ) : (
        <>
          <div
            className={Styles.CreditBureauScrubReportMainDiv}
          >
            
            <Typography
              sx={CreditBureauScrubReportTypograhy}
            >
              Credit Bureau Scrub Report
            </Typography>
            <ViewDownloadDeleteComp
              //   showEye={false}
              fileName={
                dealState?.filterdata?.poolUploadFinal?.uploadedCibilScoreFile
                  ?.fileName ||
                dealState?.filterdata?.poolUploadFinal?.uploadedCibilScoreFile
                  ?.filename ||
                ""
              }
              id={
                dealState?.filterdata?.poolUploadFinal?.uploadedCibilScoreFile
                  ?._id
              }
            ></ViewDownloadDeleteComp>
          </div>

          <div
            className={Styles.CreditBureauScrubReportMainDiv}
          >
            <Typography
              sx={CreditBureauScrubReportTypograhy}
            >
              Approved Pool
            </Typography>
            <ViewDownloadDeleteComp
              //   showEye={false}
              fileName={
                dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile
                  ?.fileName ||
                dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile
                  ?.filename ||
                ""
              }
              id={
                dealState?.filterdata?.poolUploadFinal?.secondarySuccessFile
                  ?._id
              }
            ></ViewDownloadDeleteComp>
          </div>
        </>
      )}
      {userData?.roles.length != 1 ? (
        <></>
      ) : (
        <TabFooter
          isBtnDisable={
            dealState?.filterdata?.poolUploadFinal?.poolStatus === "APPROVED"
              ? true
              : false
          }
          onclickHandler={nextBtnClickHandler}
        ></TabFooter>
      )}
    </>
  );
};

export default CreditBureauScrubReport;
