import React, { useEffect, useState } from "react";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import Layout from "../../../components/Layout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import Histories from "../../../components/History/Histories";
import { useSelector, useDispatch } from "react-redux";
import {
  clearDealState,
  getSingleDealDetails,
} from "../../../redux/Deals/dealSlice";
import { useParams, useHistory } from "react-router-dom";
import QueriesPage from "../../../components/Queries/Queries";

import Paths from "../../../components/constants/Path";
import PortfolioFinalLoanDeatils from "../../L1FinalDeal/PortfolioFinalLoanDeatils";
import EntityLoanDetails from "./EntityLoanDetails";

const EntityViewDealDeatils = () => {
  const dealState = useSelector((state) => state.deal);
  const history = useHistory();
  const dealInfo = history.location.state;

  const params = useParams();
  let { dealId } = params;
  const dispatch = useDispatch();

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.EntityDealList,
      pathName: "Deal",
    },

    {
      pathName: "Test",
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  useEffect(() => {
    dispatch(getSingleDealDetails(dealId));
  }, []);

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName="Test"
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={[
          {
            name: "Loan",
            component: <EntityLoanDetails />,
            index: 0,
          },
          {
            name: "Queries",
            index: 3,
            component: (
              <QueriesPage
                dealIdorOriginatorId={params.dealId}
                currentStage={dealState?.filterdata?.currentStage || ""}
              />
            ),
          },

          { name: "History", index: 3, component: <Histories id={dealId} /> },
        ]}
      ></TabsLayout>
    </Layout>
  );
};

export default EntityViewDealDeatils;
