import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./Layout.module.css";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import BreadCrumbWrapper from "../BreadCrumbHeader/BreadCrumbHeader";

import HomeIcon from "../../assets/icons/Home.svg";
import LoansIcon from "../../assets/icons/Loan.svg";
import NotificationIcon from "../../assets/icons/Notification.svg";
import PortfolioIcon from "../../assets/icons/Portfolio.svg";
import UsersIcon from "../../assets/icons/Users.svg";
import SettingsIcon from "../../assets/icons/Settings.svg";
import Paths from "../constants/Path";

const Layout = ({ children, breadCrumbs, componentName = null }) => {
  const [screenWidth, setScreenWidth] = useState(null);

  //? useSelector
  let { showSidebar } = useSelector((state) => state.app);

  const getDimension = () => {
    let { innerHeight, innerWidth } = window;

    console.log({ innerHeight, innerWidth });
  };

  useEffect(() => {
    getDimension();
  }, []);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let tabs = [
    {
      path: Paths.DASHBOARD,
      name: "Dashboard",
      imgsrc: HomeIcon,
    },
    {
      path: Paths.DEALS_LIST,
      name: "Deals",
      imgsrc: PortfolioIcon,
    },
    {
      path: "/demo",
      name: "Loans",
      imgsrc: LoansIcon,
    },
    {
      path: Paths.ORIGINATOR_LIST,
      name: "Originator",
      imgsrc: UsersIcon,
    },
    {
      path: Paths.L2DEALLIST,
      name: "L2 Approve/Reject Deal",
      imgsrc: NotificationIcon,
    },
    {
      path: Paths.L2OIRIGINATORSLIST,
      name: "L2 Approve/Reject Originator Profile",
      imgsrc: NotificationIcon,
    },
    {
      path: Paths.ORIGINATORHOME,
      name: "Originator Upload KYC",
      imgsrc: NotificationIcon,
    },

    {
      path: "/notifications",
      name: "Notifications",
      imgsrc: NotificationIcon,
    },
    {
      path: "/test",
      name: "Settings",
      imgsrc: SettingsIcon,
    },
  ];

  return (
    <div className={styles.LayoutWrapper}>
      <Header userName={userData?.name} />
      <div className={styles.flex}>
        <Sidebar tabsData={tabs} />
        <div
          className={
            showSidebar
              ? styles.expandContentWrapper
              : styles.shrinkContentWrapper
          }
        >
          <BreadCrumbWrapper
            breadCrumbs={breadCrumbs}
            componentName={componentName}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
