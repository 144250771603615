import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import { styled } from "@mui/material/styles";
import ApproverDealistTable from "../../../components/Table/ApproverDealistTable";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "./../../../Hooks/useDebounce";
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import Loader from "./../../../utils/Loader";
import Paths from "../../../components/constants/Path";
import styles from "./ApproveRejectEntireDeal.module.css"
import { listDealStack, titleTypo, topBoxStyle } from "./ApproveRejectEntireDealCSS";
import { FilterConstants } from "../../../components/constants/FiltersConstant";

const ApproverDealist = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("All");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if (name == "PEL" || name == "PCHFL") {
      setEntityFilter(name);
      setFilterName("")
    } else {
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName, entityFilter]);

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        filterName,
        entityFilter,
        source: "PHFL",
      })
    );
  }, [currentPage, filterName, entityFilter, debouncedSearch]);


  let history = useHistory();

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deals List",
    },
  ];

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const clickHandlerToViewDetails = (deal) => {
    return history.push({
      pathname: `approver/${deal._id || deal.id || deal}/deal-details`,
      state: deal,
    });
  };

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Layout breadCrumbs={breadCrumbs}>
      {dealState?.loading ? (
        <Loader />
      ) : (
        <Box sx={topBoxStyle}>
          <>
            <Stack sx={listDealStack}>
              <Typography sx={titleTypo}  >
                List of Deals
              </Typography>
            </Stack>

            <Divider></Divider>

            <div className={styles.main}>
              <div>
                {filterList &&
                  filterList.map((item, index) => (
                    <>
                      <StyledButton
                        key={index}
                        variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                        sx={{
                          color: filterName === item || entityFilter == item ? "white" : "black",
                          backgroundColor:
                            filterName === item || entityFilter == item ? "#365069" : "white",
                          borderColor: "black",
                          mr: 1,
                        }}
                        onClick={() => filterHandler(item)}
                      >
                        {item}
                      </StyledButton>
                    </>
                  ))}
              </div>
              <TextField
                placeholder="Search"
                autoComplete="off"
                value={search}
                variant="standard"
                size="small"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>

            <Box>
              <ApproverDealistTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                filterName={filterName}
                page={currentPage}
                header={
                  userData.roles[0] === "Approver 1" ||
                    userData.roles[0] === "Approver 2" ||
                    userData.roles[0] === "Approver 3" ||
                    userData.roles[0] === "Approver 4" ||
                    userData.roles[0] === "Approver 5" ||
                    userData.roles[0] === "Approver 6" ||
                    userData.roles[0] === "Approver 7"
                    ? [
                      "Deal Name",
                      "Asset Class",
                      "Requested Pool Size",
                      "Entity Type",
                      "Deal Start Date",
                      "Deal Approval Status",
                      // "Deal Status",
                      "Action",
                    ]
                    : [
                      "Deal Name",
                      "Asset Class",
                      "Requested Pool Size",
                      "Entity Type",
                      "Deal Start Date",
                      "Deal Approval Status",
                      // "Upload Status",
                      "Action",
                    ]
                }
                viewActionClickHandler={clickHandlerToViewDetails}
              // statusList={filterList}
              ></ApproverDealistTable>
            </Box>
          </>
        </Box>
      )}
    </Layout>
  );
};

export default ApproverDealist;
