import React from "react";
import Layout from "../../../components/Layout/Layout";
import DescriptionIcon from "@mui/icons-material/Description";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import DealDetails from "./DealDetails";
import QueriesPage from "../../../components/Queries/Queries";
import Histories from "../../../components/History/Histories";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleAmlUpload } from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import AMLForm from "../../Phase3/AML/AMLForm";
import Paths from "../../../components/constants/Path";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import { statusConstants } from "../../../components/constants/FiltersConstant";

const ApproverViewDealDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const params = useParams();
  let dispatch = useDispatch();
  const [uploadData, setUploadData] = useState("");

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: params.dealId })).then((res) => {
      setUploadData(res?.payload?.data);
    });
  }, []);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.APPROVERDEALIST,
      pathName: "Deal List",
    },
    {
      pathName: dealState?.name,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let Tabs = [
    {
      name: "Deal",
      index: 0,
      component: <DealDetails />,
    },

  ];

  if (userData?.roles.length == 1) {
    Tabs.push(
      {
        name: "Queries",
        index: 2,
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
      },
      { name: "History", index: 3, component: <Histories /> },
    )
  }


  if (userData?.roles.length == 1) {
    if (dealState?.filterdata?.dealType !== "SELL" && uploadData != undefined) {
      Tabs.push({
        name: "Compliance",
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  } else {
    if (dealState?.filterdata?.dealType !== "SELL" &&
      uploadData &&
      uploadData?.currentStatus !== statusConstants.PENDING &&
      uploadData?.currentStatus !== statusConstants.FILE_RECEIVED &&
      uploadData?.currentStatus !== statusConstants.IN_PROGRESS) {
      Tabs.push({
        name: "Compliance",
        // isDisabled: dealState?.filterdata?.dealType !== "SELL" ? true : false,
        index: 3,
        component: <AMLForm></AMLForm>,
      });
    }
  }

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={Tabs}
      ></TabsLayout>
    </Layout>
  );
};

export default ApproverViewDealDetails;
