export const monthList = {
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
};

export const reportConstants = {
  LEDGER_REPORT: "Ledger Report",
  ACCOUNTING_REPORT: "Accounting Report",
  POOLWISE_REPORT: "Poolwise Report",
  ABC_REPORT: "ABC Report",
  SYNERGY_EXTRACTED_REPORT: "Synergy Extracted Report",
  TRIAL_BALANCES_REPORT: "Trial Balances Report",
  AS_ON_DATE_REPORT: "As On Date Report",
  VOUCHER_REPORT: "Voucher Report",
  SOA_REPORT: "SOA Report",
  CRISMAC_REPORT: "Crismac Report",
};

export const crismacReportType = [
  { id: 1, name: "Account Template", actualValue: "ACCOUNT_TEMPLATE" },
  { id: 2, name: "Buy Template", actualValue: "BUY_TEMPLATE" },
  { id: 3, name: "Customer Template", actualValue: "CUSTOMER_TEMPLATE" },
  { id: 4, name: "Securitized Template", actualValue: "SECURITIZED_TEMPLATE" },
  { id: 5, name: "Security Template", actualValue: "SECURITY_TEMPLATE" },
];
