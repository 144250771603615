import React, { useEffect } from "react";
import Paths from "../../../components/constants/Path";
import Histories from "../../../components/History/Histories";
import Layout from "../../../components/Layout/Layout";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../../components/Queries/Queries";
import LoanDeails from "../Loans/LoanDetails";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../../assets/images/Group 42722.svg";
import CreditFilterDetails from "./CreditFiltersDetails";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";


const L2CreditFilterDetails = () => {
  const params = useParams();
  const dealState = useSelector((state) => state.deal);

  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      path: Paths.L2CREDITFILTERSDEALIST,
      pathName: "Deals",
    },
    {
      pathName: dealState?.name,
    },
  ];

  let TabList = [
    {
      name: "Loan",
      index: 0,
      component: <CreditFilterDetails />,
    },
    
  ]

  if(userData?.roles.length == 1 ){
    TabList.push(
      {
        name: "Queries", index: 1, component: <QueriesPage
          dealIdorOriginatorId={params.dealId}
          currentStage={dealState?.filterdata?.currentStage || ""}
  
        />,
      },
      { name: "History", index: 2, component: <Histories id={params.dealId} /> },  
    )
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={dealState?.name}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={TabList}
      ></TabsLayout>
    </Layout>
  )
}

export default L2CreditFilterDetails