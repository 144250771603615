import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from "../../assets/images/Group 42722.svg";
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import CreditBureauScrubReport from "../../components/Tabs/SecondaryCheckTabs/CreditBureauScrubReport";
import CreditFilter from "../../components/Tabs/SecondaryCheckTabs/CreditFilters";
import DownloadPool from "../../components/Tabs/SecondaryCheckTabs/DownloadPool";
import SecondaryAutoCheck from "../../components/Tabs/SecondaryCheckTabs/SecondaryAutoCheck";
import Tabs from "../../components/Tabs/Tabs";
import { getSingleDealDetails } from "../../redux/Deals/dealSlice";
import Loader from "../../utils/Loader";
import Paths from "../../components/constants/Path";
import { statusConstants } from "../../components/constants/FiltersConstant";
import { setStatus } from "../../redux/Loans/loanSlice";

const POOLSHORTLISTING = () => {
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  let loading = useSelector((state) => state.deal.loading);
  const dispatch = useDispatch();

  let secondaryCheckState = useSelector((state) => state.secondaryCheck);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.POOLSHORTLISTINGDEALIST,
      pathName: "Deals",
    },
    {
      pathName: dealState?.name,
    },
  ];
  
  const tabs = [];

  if(userData?.roles.length == 1 ){
    tabs.push(
      {
        tabName: "Credit Filter",
        comp: CreditFilter,
        heading: `Credit Filter for - ${dealState.name}`,
        showEditButton:
          dealState?.filterdata?.poolUploadFinal?.poolStatus ===
          statusConstants.APPROVED
            ? false
            : true,
        id: dealState?.filterdata?._id,
        dealData: dealState,
      },
      {
        tabName: "Download Pool",
        comp: DownloadPool,
        heading: `Download Pool for - ${dealState.name}`,
        showEditButton: false,
        id: dealState?.filterdata?._id,
        dealData: dealState,
      },
      {
        tabName: "Secondary Auto Check",
        comp: SecondaryAutoCheck,
        heading: `Secondary Auto Check for - ${dealState.name}`,
        showEditButton: false,
        id: dealState?.filterdata?._id,
        dealData: dealState,
      },
      {
        tabName: "Credit Bureau Scrub Report",
        comp: CreditBureauScrubReport,
        heading: `Credit Bureau Scrub Report for - ${dealState.name}`,
        showEditButton: false,
        id: dealState?.filterdata?._id,
        dealData: dealState,
        isDisabled:
          dealState?.filterdata?.poolUploadFinal?.secondarySuccessRecords === 0,
      },
    )
  }

  if(userData?.roles.length != 1 ){
    tabs.push(
      {
        tabName: "Credit Filter",
        comp: CreditFilter,
        heading: `Credit Filter for - ${dealState.name}`,
        showEditButton: false,
        id: dealState?.filterdata?._id,
        dealData: dealState,
      },
    )
  }

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  let TabsList = [
    {
      name: "Loan",
      component: (
        <Tabs
          tabs={tabs}
          status={secondaryCheckState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
    
  ];
  if(userData?.roles.length == 1){
    TabsList.push(
      {
        name: "History",
        component: <Histories />,
        index: 1,
      },
      {
        name: "Queries",
        component: (
          <QueriesPage
            dealIdorOriginatorId={params.dealId}
            currentStage={dealState?.filterdata?.currentStage || ""}
          />
        ),
        index: 2,
      },
    )
  }

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <Layout breadCrumbs={breadCrumbs}>
      {loading ? (
        <Loader />
      ) : (
        <TabsLayout
          TabsList={TabsList}
          formName={dealState?.name}
          icon={icon}
          iconColor={"#DBE3F8"}
          image={Image}
        />
      )}
    </Layout>
  );
};

export default POOLSHORTLISTING;
