import React from "react";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";
import { Button, Grid, MenuItem, Typography, TextField } from "@mui/material";
import TextWrapperComponent from "../../components/FormComponents/TextWrapperComponent";
import { Colors } from "../../theme/theme";
import { useEffect } from "react";
import { getApprovers, goForUpdateDeal } from "../../redux/L1/finalDealSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Loader from "../../utils/Loader";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../redux/app/appSlice";
import { CustomSelect } from "../../components/FormComponents/CustomSelect";
import OutlineButton from "../../components/Buttons/OutlineButton";
import { postCommentReply } from "../../redux/Queries/queriesSlice";
import {
  getDealDocuments,
  clearAllDocuments,
  getSingleDealDetails,
} from "../../redux/Deals/dealSlice";
import AllDocuments from "../../components/AllDocuments";
import AllDocumentsV2 from "../../components/AllDocumentsV2";
import Paths from "../../components/constants/Path";
import { statusConstants } from "../../components/constants/FiltersConstant";

const PortfolioFinalLoanDeatils = () => {
  let dispatch = useDispatch();
  const [approver1, setApprover1] = useState([]);
  const [approver2, setApprover2] = useState([]);
  const [approver3, setApprover3] = useState([]);
  const [approver4, setApprover4] = useState([]);
  const [approver5, setApprover5] = useState([]);
  const [approver6, setApprover6] = useState([]);
  const [approver7, setApprover7] = useState([]);
  const [query, setQuery] = useState("");
  const [approverIdData, setApproverIdData] = useState({
    approver1: "",
    approver2: "",
    approver3: "",
    approver4: "",
    approver5: "",
    approver6: "",
    approver7: "",
  });

  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  const history = useHistory();

  const setApproverData = () => {
    const approverData = dealState?.filterdata?.approvers ?? [];
    console.log({ approverData });
    approverData.map((val, index) => {
      console.log({ val });
      console.log({ index });
      setApproverIdData((prev) => ({
        ...prev,
        [val?.roleName?.toLowerCase().replace(" ", "")]: val.approverId,
      }));
    });
  };

  useEffect(() => {
    dispatch(getApprovers({ approverType: "Approver 1", dealId: params.dealId })).then((res) => {
      setApprover1(res?.payload?.data);
    });
    dispatch(getApprovers({ approverType: "Approver 2", dealId: params.dealId })).then((res) => {
      setApprover2(res?.payload?.data);
    });
    dispatch(getApprovers({ approverType: "Approver 3", dealId: params.dealId })).then((res) => {
      setApprover3(res?.payload?.data);
    });
    dispatch(getApprovers({ approverType: "Approver 4", dealId: params.dealId })).then((res) => {
      setApprover4(res?.payload?.data);
    });
    dispatch(getApprovers({ approverType: "Approver 5", dealId: params.dealId })).then((res) => {
      setApprover5(res?.payload?.data);
    });
    dispatch(getApprovers({ approverType: "Approver 6", dealId: params.dealId })).then((res) => {
      setApprover6(res?.payload?.data);
    });
    dispatch(getApprovers({ approverType: "Approver 7", dealId: params.dealId })).then((res) => {
      setApprover7(res?.payload?.data);
    });
    setApproverData();
  }, []);
  

  useEffect(() => {
    dispatch(getDealDocuments({ dealId: params.dealId }));
  }, []);

  const updateDealHandler = () => {
    // Get value from arrId

    let approverArray = Object.values(approverIdData);
    approverArray = approverArray.filter(
      (item) => item !== "" && item !== undefined
    );

    if (approverArray.length != 0) {
      dispatch(
        goForUpdateDeal({ dealId: params.dealId, approvers: approverArray })
      ).then((res) => {
        if (res.payload.data.status === 200) {
          dispatch(showAlertAction("Sent for Approval Successfully"));
        }
        history.push(Paths.PORTFOLIOFINALDEALLIST);
      });
    } else {
      dispatch(showErrorAlertAction("Please Select At least One Approver"));
    }
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));
  useEffect(() => {
    console.log({ approverIdData });
  }, [approverIdData]);

  const approversDropdowns = [
    {
      label: "Approver 1",
      options: approver1,
      value: approverIdData.approver1,
    },
    {
      label: "Approver 2",
      options: approver2,
      value: approverIdData.approver2,
    },
    {
      label: "Approver 3",
      options: approver3,
      value: approverIdData.approver3,
    },
  ];
  if(dealState?.entity == "PCHFL"){
    approversDropdowns.push(
      {
        label: "Approver 4",
        options: approver4,
        value: approverIdData.approver4,
      },
      {
        label: "Approver 5",
        options: approver5,
        value: approverIdData.approver5,
      },
      {
        label: "Approver 6",
        options: approver6,
        value: approverIdData.approver6,
      },
      {
        label: "Approver 7",
        options: approver7,
        value: approverIdData.approver7,
      },
    )
  }

  console.log({ dealState });

  console.log({ approverIdData });

  function onPostClickHandler(commentId, query) {
    console.log({ commentId, query });
    dispatch(postCommentReply({ query, commentId })).then((res) => {
      dispatch(getSingleDealDetails(params.dealId));
    });
  }

  useEffect(() => {
    return () => {
      dispatch(clearAllDocuments());
    };
  }, []);

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {dealState?.loading ? (
        <Loader />
      ) : (
        <div
          style={{
            paddingTop: "14px",
          }}
        >
          {dealState?.status == statusConstants.CLOSED && dealState?.filterdata?.isMigrated === true ? (
            <AllDocumentsV2 />
          ) : (
            <AllDocuments  />
          )}
          {dealState?.status !== statusConstants.CLOSED && (
            <>
              <WrapperComponent title="Approval Committee Setup">
                <>
                  <br></br>
                  {userData.roles[0] === "L1" ? (
                    <div
                      style={{
                        paddingTop: "14px",
                      }}
                    >
                      <Grid container spacing={3}>
                        {approversDropdowns.map((val) => {
                          const name = val?.label
                            ?.toLowerCase()
                            .replace(" ", "");

                          return (
                            <Grid item xs={3}>
                              <TextWrapperComponent
                                text={`Select ${val?.label}`}
                              >
                                <CustomSelect
                                  disabled={
                                    dealState?.filterdata?.approvers &&
                                    (dealState?.filterdata?.approvers).length !=
                                      0
                                      ? true
                                      : false
                                  }
                                  placeholder={`Select ${val?.label}`}
                                  optionList={val?.options ?? []}
                                  value={val?.value}
                                  onClickHandler={(e) => {
                                    setApproverIdData({
                                      ...approverIdData,
                                      [name]: e.target.value,
                                    });
                                  }}
                                  fieldName="name"
                                  fieldValue="_id"
                                />
                              </TextWrapperComponent>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  ) : (
                    <>
                      <Grid container spacing={3}>
                        {dealState?.filterdata?.approvers &&
                          dealState?.filterdata?.approvers.map(
                            (item, index) => {
                              return (
                                <Grid item xs={3}>
                                  <DataComp
                                    title={item.roleName}
                                    desc={item.name}
                                  ></DataComp>
                                </Grid>
                              );
                            }
                          )}
                      </Grid>
                    </>
                  )}
                </>
              </WrapperComponent>
              <br></br>
              {dealState?.filterdata?.comments !== undefined && (
                <>
                  {dealState?.filterdata?.comments["Approver 1"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 1"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 1"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 1 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 2"] !==
                      undefined &&
                    dealState?.filterdata?.comments["Approver 2"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 2"].map(
                          (reply, index) => {
                            const roleName =
                              reply?.roleName == undefined ||
                              reply?.roleName == null
                                ? ""
                                : reply?.roleName + " Reply";
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 2 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 3"] !==
                      undefined &&
                    dealState?.filterdata?.comments["Approver 3"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 3"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 3 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";

                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 4"] !==
                      undefined &&
                    dealState?.filterdata?.comments["Approver 4"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 4"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 4 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 5"] !==
                      undefined &&
                    dealState?.filterdata?.comments["Approver 5"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 5"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 5 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 6"] !==
                      undefined &&
                    dealState?.filterdata?.comments["Approver 6"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 6"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 6 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 7"] !==
                      undefined &&
                    dealState?.filterdata?.comments["Approver 7"].length >
                      0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 7"].map(
                          (reply, index) => {
                            //
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 7 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                              data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                              // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                </>
              )}

              {userData.roles[0] === "L1" ? (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    alignContent: "flex-end",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    height: "60px",
                    paddingBottom: "10px",
                    background: " #ffffff",
                    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={
                      dealState?.filterdata?.approvers &&
                      (dealState?.filterdata?.approvers).length != 0
                        ? true
                        : false
                    }
                    onClick={updateDealHandler}
                    sx={{
                      backgroundColor: Colors.piramal_orange,
                      color: Colors.white,

                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: Colors.piramal_orange,
                      },
                      "&:disabled": {
                        backgroundColor: "#FAC3B3",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    Send for Approval
                  </Button>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PortfolioFinalLoanDeatils;
