export const selectAllBtn = {
    padding: "8px 12px",
    background: "white",
    border: "1px solid #365069",
    borderRadius: "8px",
    height: "40px",
    fontWeight: "800",
    fontSize: "12px",
    color: "#365069",
}

export const listSubHeader = {
    height: "40px",
    background: "white",
    padding: "10px",
}