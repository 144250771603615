import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Delete from "../../../assets/icons/blueDelete.svg";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Box, Button, Stack } from "@mui/material";
import { Colors } from "../../../theme/theme";
import UploadIcon from "../../../assets/icons/UploadIcon.svg";

import styles from "./UploadReports.module.css";
import { AddMoreFileComponentBoxStyle, AddMoreFileComponentBoxTwoStyle, AddMoreFileComponentButtonStyle, ChooseFileBoxStyle, ChooseFileStackStyle, ChooseFileTypographyStyle, ChooseFileTypographySupportedFile, selectedFileComponentCheckCircle } from "./UploadReportsMaterailUI";

export const ChooseFile = () => {
  return (
    <Box
      sx={ChooseFileBoxStyle}
    >
      <Stack sx={ChooseFileStackStyle}>
        <Stack direction="row" spacing={1}>
          <img src={UploadIcon}></img>
          <Typography
            sx={ChooseFileTypographyStyle}
          >
            Upload File
          </Typography>
        </Stack>

        <Typography
          sx={ChooseFileTypographySupportedFile}
        >
          (Supported file formats : .csv and .xlsx)
        </Typography>
      </Stack>
    </Box>
  );
};

export const SelectedFileComponent = ({
  fileName,
  fileSize,
  onClickHandler,
}) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.DetailsWrapper}>
          <div className={styles.together}>
            <CheckCircleIcon
              sx={selectedFileComponentCheckCircle}
            ></CheckCircleIcon>
            {/* <div></div> */}
            <p>{fileName}</p>
          </div>
          <p> {fileSize} KB</p>
        </div>
        <div
          className={styles.iconWrapper}
          onClick={onClickHandler}
        >
          <img src={Delete}></img>
        </div>
      </div>
    </>
  );
};
export const AddMoreFileComponent = ({ clickhandler }) => {
  return (
    <div onClick={clickhandler}>
      <Box
        sx={AddMoreFileComponentBoxStyle}
      >
        <Box
          sx={AddMoreFileComponentBoxTwoStyle}
        >
          <Button
            variant="none"
            startIcon={<AddIcon />}
            sx={AddMoreFileComponentButtonStyle}
          >
            Add More Files
          </Button>
        </Box>
      </Box>
    </div>
  );
};
