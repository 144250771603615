import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { Stack, Typography, Divider } from "@mui/material";
import TabsLayout from "../../../components/Layout/TabsLayout/Layout";
import SpocTable from "../../../components/Table/SpocTable";
import Layout from "../../../components/Layout/Layout";
import Image from "../../../assets/images/Group 42722.svg";
import DescriptionIcon from "@mui/icons-material/Description";

import QueriesPage from "../../../components/Queries/Queries";
import Histories from "../../../components/History/Histories";
import L2ViewOriginatorsDetails from "./L2ViewOriginatorsDetails";
import Paths from "../../../components/constants/Path";
import {
  checkDeDup,
  clearOriginatorState,
  getOriginatorDetails,
  setDuplicateOriginator,
} from "../../../redux/originator/originatorSlice";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const L2OriginatorsTabs = () => {
  const originatorData = useSelector((state) => state.originator);
  const dealState = useSelector((state) => state.deal);
  const [showModal, setShowModal] = useState(false);
  const [popUpOpen, setPopUpOpen] = React.useState(false);
  const [modalData, setModalData] = useState("");
  const [gstValues, setGstValues] = useState("");
  const [childMsg, setChildMsg] = useState("")


  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOriginatorDetails(params.originatorId)).then((res) => {

      if (res?.payload?.name != undefined && res?.payload?.status != "APPROVED") {

        let data = {
          name: res?.payload?.name,
          gstNumbers: res?.payload?.kycData?.gstNumbers,
          panNumber: res?.payload?.kycData?.panNumber,
          dedupeCheck: true
        }
        dispatch(checkDeDup(data)).then((res) => {
          
          if (res?.payload?.response?.data?.data?.isExist == true && res?.payload?.response?.data?.data?.type != "name") {
            let duplicateOrg = res?.payload?.response?.data?.data?.originator;
            dispatch(setDuplicateOriginator(duplicateOrg));
            setChildMsg(res?.payload?.response?.data?.message)
            setModalData(res?.payload?.response?.data?.data?.originator);
            setGstValues(
              res?.payload?.response?.data?.data?.originator?.kyc?.gstNumbers
            );
            setShowModal(true);
            setPopUpOpen(true);
            return;
          }
        })
      }
    });
  }, []);


  useEffect(() => {
    return () => {
      dispatch(clearOriginatorState());
    };
  }, []);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.L2OIRIGINATORSLIST,
      pathName: "Originator Profiles",
    },
    {
      pathName: originatorData?.originatorName || "",
    },
  ];

  let userData = JSON.parse(localStorage.getItem("UserData"));

  let TabList = [
    {
      name: "Profile",
      index: 0,
      component: <L2ViewOriginatorsDetails
        popUpOpen={popUpOpen}
        setPopUpOpen={setPopUpOpen}
        showModal={showModal}
        modalData={modalData}
        gstValues={gstValues}
        childMsg={childMsg}
      ></L2ViewOriginatorsDetails>,
    },
  ]

  if(userData?.roles.length == 1 ){
    TabList.push(
    {
      name: "Queries",
      index: 1,
      component: (
        <QueriesPage
          currentStage={dealState?.filterdata?.currentStage || ""}
          dealIdorOriginatorId={params?.originatorId}
        />
      ),
    },
    {
      name: "History",
      index: 2,
      component: <Histories id={params.originatorId} />,
    },
    )
  }

  //
  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName={originatorData.originatorName || ""}
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={TabList}
      ></TabsLayout>
    </Layout>
  );
};

export default L2OriginatorsTabs;
