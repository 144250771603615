import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment/moment";
import {
  CommanTableStyledStatusContainerBox,
  CommanTableStyledTableCell1Head,
  CommanTableStyledTableCell2Head,
  CommanTableStyledTableCellBody,
  CommanTableStyledTableCellHead,
} from "./F2CSS";
import Styles from "./F2.module.css";

const DealTable = ({ soaReport }) => {
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: CommanTableStyledTableCellHead,
    [`&.${tableCellClasses.body}`]: CommanTableStyledTableCellBody,
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: CommanTableStyledTableCell1Head,
    [`&.${tableCellClasses.body}`]: CommanTableStyledTableCellBody,
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: CommanTableStyledTableCell2Head,
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "left",
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    padding: "6px 0px",
  }));

  const StatusContainer = ({ statusType, CreditFilterAdded = false }) => {
    const Textcolor =
      statusType === "APPROVED" ||
      statusType === "Created" ||
      statusType === "DONE"
        ? "#277742"
        : statusType === "REJECTED"
        ? "#8D3431"
        : statusType === "CLOSED"
        ? "#2C4584"
        : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
      statusType === "Created" ||
      statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "REJECTED" || statusType === "FAILED"
        ? "#FCE6E5"
        : statusType === "CLOSED"
        ? "#E4EAFA"
        : "#FFF4E7";


    return (
      <Box
        borderRadius={1}
        color={Textcolor}
        sx={CommanTableStyledStatusContainerBox}
      >
        <div
          className={Styles.CommanTableStatusContainerDiv}
          style={{
            backgroundColor: boxBackgroudColor,
          }}
        >
          <p className={Styles.CommanTableStatusContainerP}>
            {statusType &&
              statusType
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
          </p>
        </div>
      </Box>
    );
  };

  const formatRequestedPool = (value) => {
    if (value !== undefined) {
      console.log({ value });
      numberFormat(Number(value));
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(value);

  var nf = new Intl.NumberFormat();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  function convertTime(date) {
    var dt = new Date(date);
    var hours = dt.getHours(); // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    var minutes = dt.getMinutes();
    var finalTime = moment(date).format("hh:mm") + " " + AmOrPm;
    return finalTime;
  }
  let lastRow = {
    debitAmount: soaReport?.totalDebitAmount,
    creditAmount: soaReport?.totalCreditAmount,
    transactionDate: "",
    transactionId: "",
    instrumentType: "",
    transactionFor: "",
  };
  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ minWidth: "200px" }}>
              {[
                "Date",
                "Voucher",
                "Ledger Code",
                "Ledger Description",
                "Account Head",
                "Dr. AMT",
                "Cr. AMT",
              ].map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {soaReport?.accountingEntries &&
              [...soaReport.accountingEntries, lastRow].map((item) => {
                return (
                  <TableRow key={item.id || item._id}>
                    <StyledTableCell1 component="th" scope="row">
                      {item.transactionDate !== ""
                        ? moment(item?.transactionDate).format("DD/MM/YYYY")
                        : ""}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.transactionId + " " + item?.instrumentType}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.ledgerCode || " "}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.ledgerDescription || " "}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.transactionFor}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.debitAmount}
                    </StyledTableCell1>
                    <StyledTableCell1 component="th" scope="row">
                      {item?.creditAmount}
                    </StyledTableCell1>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DealTable;
