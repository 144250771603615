import React, { useState, useEffect } from "react";
import TabFooter from "../TabFooter/TabFooter";
import { Button, InputAdornment, ListSubheader, Stack } from "@mui/material";
import OutlineButton from "../../Buttons/OutlineButton";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Box, Grid, TextField } from "@mui/material";
import SPOCList from "../../Table/SPOCList";
import AddSPOCDetails from "../CreateDealTabs/AssignSpocs/AddNewSpoc/AddNewSpoc";
import Paths from "../../constants/Path";
import { useHistory } from "react-router-dom";
import { pageStatusAction } from "../../../redux/DealDuplication/dealDuplicationSlice";
import { useDispatch } from "react-redux";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import AutocompleteComponent from "../../FormComponents/AutocompleteCompoent";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import { useMemo } from "react";
import styles from "./DealDuplicationTabs.module.css"
import { colorStyle, listSubheaderStyle, paddingStyle, searchIconStyle, selectAllBtn } from "./DealDuplicationTabsCSS";

const TableData = [
  {
    id: 198,
    Name: "Foram Chandra",
    Email: "foram.chandra@bajaj.com",
    Number: "9876543210",
    checked: false,
  },

  {
    id: 8,
    Name: "Jhanvi Soni",
    Email: "jhanvi.soni@bajaj.com",
    Number: "9876543210",
    checked: false,
  },
];

const AssignSPOCs = ({ nextTabHandler }) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState(TableData);
  const [filter, setFilter] = useState("");
  const clickHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setList(TableData);
  }, []);

  const [checkAll, setCheckAll] = useState(false);

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    checkAll ? deselectAll() : selectAll();
  };

  const deselectAll = () => {
    let checkedItems = list.map((data) => {
      return { ...data, checked: false };
    });
    console.log(checkedItems);
    setList(checkedItems);
  };

  
  let history = useHistory();
  let dispatch = useDispatch();

  const onClickHandlerToOriginatorList = () => {
    dispatch(pageStatusAction({ pageName: "assignSPOCs" }));
    return history.push(Paths.DEALDUPLICATIONDEALIST);
  };

  const OriginatorsList = [
    { id: 2, title: "Anupam Pandey" },
    { id: 3, title: "Purva Chandurkar" },
    { id: 4, title: "Jhanvi Soni" },
  ];

  const selectAll = () => {
    let checkedItems = list.map((data) => {
      return { ...data, checked: true };
    });
    console.log(checkedItems);
    setList(checkedItems);
  };

  const defaultProps = {
    options: OriginatorsList,
    getOptionLabel: (option) => option.title,
  };

  const [data, setData] = useState(TableData);

  const excludeColumns = ["id"];

  const handleSearchChange = (value) => {
    setFilter(value);
    filterData(value);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setList(TableData);
    else {
      const filteredData = TableData.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setList(filteredData);
    }
  };

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

 

  return (
    <>
      <div>
        <>
          <div className={styles.divWrapper}>
            <>
              <Stack direction="row" spacing={1}>
                <PeopleAltIcon sx={colorStyle}></PeopleAltIcon>
                <TextWrapperComponent text="Point of Contact · Bajaj Finserv Pvt Ltd."></TextWrapperComponent>
              </Stack>

              <div className={styles.paddingStyle}>
                <div className={styles.selectAllBtnWrapper}>
                  <Button
                    sx={selectAllBtn}
                    startIcon={<DoneIcon sx={{ color: "#365069" }} />}
                    onClick={handleCheckAll}
                  >
                    Select All
                  </Button>
                  <OutlineButton
                    text="Add New SPOC"
                    showIcon={true}
                    onClickHandler={clickHandler}
                  ></OutlineButton>
                </div>

                <AddSPOCDetails open={open} clickHandler={clickHandler} />
              </div>
            </>
          </div>
          <ListSubheader
            disableSticky
            sx={listSubheaderStyle}
          >
            <TextField
              size="small"
              // Autofocus on textfield
              // autoFocus
              placeholder="Search for SPOC Name, Email ID or Phone Number"
              fullWidth
              sx={{
                border: "0.5px solid #5E7387",
                borderRadius: "4px",
                input: {
                  fontFamily: "Nunito",
                  fontSize: "12px",
                  fontWeight: "400",
                  padding: "5px",
                  height: "20px",
                  color: "#8696A5",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={searchIconStyle} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleSearchChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <AddSPOCDetails
            open={open}
            clickHandler={clickHandler}
          ></AddSPOCDetails>
          <SPOCList data={list} setList={setList}></SPOCList>
          <div className={styles.divWrapper}>
            <>
              <Stack direction="row" spacing={1}>
                <PeopleAltIcon sx={colorStyle}></PeopleAltIcon>
                <TextWrapperComponent text="Approvers for the Deal"></TextWrapperComponent>
              </Stack>
            </>
          </div>
          <div className={styles.heightStyle} >
            <Grid container columns={{ md: 12 }}>
              <Grid item xs={9}>
                <Box sx={paddingStyle}>
                  <TextWrapperComponent text="Name">
                    <AutocompleteComponent
                      placeholder="Enter Approver’s Name"
                      defaultProps={defaultProps}
                    ></AutocompleteComponent>
                  </TextWrapperComponent>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.paddingStyle} >
                  <OutlineButton
                    text="Add New Approver"
                    showIcon={true}
                  ></OutlineButton>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      </div>

      <TabFooter
        onclickHandler={onClickHandlerToOriginatorList}
        showSubmitButton={true}
        isBtnDisable={false}
      />
    </>
  );
};

export default AssignSPOCs;
