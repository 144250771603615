import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";

export default function CommentSection({ comments = [] }) {
  console.log({ comments });
  return (
    <Grid container>
      {comments &&
        comments?.map((val, index) => {
          return (
            <Fragment>
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom:
                    index + 1 !== comments.length ? "1px solid #ccc" : "",
                  padding: "10px 0px",
                }}
              >
                <p>
                  {index + 1}) {val?.query}
                </p>

                {val?.replies?.map((item, index) => {
                  return (
                    <>
                      {item?.raisedById && (
                        <Typography
                          fontSize="14px"
                          color="#808080"
                          fontWeight="400"
                          paddingTop="10px"
                        >
                          [ {item?.roleName} ] | {item?.raisedbyName} Replied :{" "}
                          {item?.query}
                        </Typography>
                      )}
                    </>
                  );
                })}
              </Grid>
              <Divider />
            </Fragment>
          );
        })}
    </Grid>
  );
}
