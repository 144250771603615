import React, { useState } from "react";
import TabFooter from "../TabFooter/TabFooter";
import { TextField, Typography, Grid, Box } from "@mui/material";
import { Colors } from "../../../theme/theme";
import { MenuItem, Select } from "@mui/material";
import {
  basicInformationAction,
  pageStatusAction,
} from "../../../redux/originator/originatorSlice";
import {
  fetchOriginatorType,
  clearSucessAndErrorAction,
} from "../../../redux/originator/originatorSlice";
import { isAuthenticated } from "../../../helper/AuthHelper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../../FormComponents/SelectComponent";
import styles from "./CreateOriginatorTabs.module.css";
import { rowGapStyle } from "./CreateOriginatorTabsCSS";

const BasicInformationTab = ({ nextTabHandler }) => {
  const [state, setState] = useState({
    originatorName: "",
    originatorType: "",
  });
  const [status, setstatus] = useState(true);

  let dispatch = useDispatch();
  let originatorState = useSelector((state) => state.originator);

  useEffect(() => {
    dispatch(fetchOriginatorType());
  }, []);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(basicInformationAction(state));
    dispatch(pageStatusAction({ pageName: "basicInformation" }));
    nextTabHandler();
  };

  useEffect(() => {
    dispatch(clearSucessAndErrorAction());
  }, []);

  //? it will run on initial page load
  useEffect(() => {
    setState({
      originatorName: originatorState.originatorName,
      originatorType: originatorState.originatorType,
    });

    if (originatorState.originatorName.length === 0) {
      setstatus(true);
    } else {
      setstatus(false);
    }
  }, []);

  //? it will run whenever the state will change or update
  useEffect(() => {
    // console.log({ updatedState: state });
    checkStatus();
  }, [state]);

  const checkStatus = () => {
    let valuesArray = Object.values(state);
    for (const key in state) {
      if (state[key] == 0) {
        setstatus(true);
        break;
      }
      setstatus(false);
    }
  };

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;

    let dataUpdated = {
      ...state,
      [name]: value,
    };

    setState(dataUpdated);
  };

  return (
    <>
      <div style={{ padding: "24px" }}>
        {originatorState["loading"] ? (
          <div className={styles.loadingWrapper}>
            <Typography>Loading....</Typography>
          </div>
        ) : (
          <form>
            <Grid container direction="row" sx={rowGapStyle}>
              <Grid item xs={6} padding="5px">
                <TextWrapperComponent text="Originators Name">
                  <TextField
                    size="small"
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    value={state.originatorName}
                    onChange={handleInputChange("originatorName")}
                    placeholder="Originator's Name"
                  />
                </TextWrapperComponent>
              </Grid>
              <Grid item xs={6} padding="5px">
                <TextWrapperComponent text="Type of Originator">
                  <SelectComponent
                    placeholder="Select Type of Originator"
                    value={state.originatorType}
                    onClickHandler={handleInputChange("originatorType")}
                    optionList={originatorState?.originatorTypeList}
                  ></SelectComponent>
                </TextWrapperComponent>
              </Grid>
            </Grid>
          </form>
        )}
      </div>

      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status} />
    </>
  );
};

export default BasicInformationTab;
