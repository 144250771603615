import { Colors } from "../../theme/theme";

export const btnStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,

    textTransform: "none",
    "&:hover": {
      backgroundColor: Colors.piramal_orange,
    },
    "&:disabled": {
      backgroundColor: "#FAC3B3",
      color: "white",
    },
}

// Documents.jsx

export const descriptionIconStyle = {
    color: "#5E7387",
    height: "20px"
}

export const typographyStyle = {
    color: "#5E7387",
    fontWeight:"500",
    fontSize:"14px",
    width:"90%",
}

export const textFieldStyle = {
    width: "230px",
    padding: "0px 10px",
}

// ListOfDeals

export const boxWrapper = {
    minHeight: "calc( 100% - 64px)",
    maxHeight: "auto",
    minWidth: "auto",
    maxWidth: "auto",
    backgroundColor: "white"
}

export const stackStyle = {
    paddingBottom:"12px",
    display:"flex",
    flexDirection:"row", 
    alignItems:"center", 
    justifyContent:"space-between", 
}

export const fontStyle = {
    fontSize: "16px",
    fontWeight: "700"
}

// CreateDeal.jsx.........////

export const listDealStackWrapper = {
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    height:"auto"
}

export const noDealType = {
    paddingTop:"8px",
    fontSize:"18px",
    fontWeight:"800",
    lineHeight:"26px",
    textAlign:"center"
}

export const startCreate = {
    fontSize:"14px",
    fontWeight:"400",
    color:"#4D4D4D",
    lineHeight:"20px",
    padding:"8px"
}


// OriginatorDealList

export const topBox = {
    backgroundColor:"white",
    minHeight:"144px",
    maxHeight:"auto",
    minWidth:"auto",
    maxWidth:"auto",
    boxShadow:"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderRadius: "8px",
    padding:"16px",
    margin:"16px",
}

export const titleTypo = {
    fontSize:"16px",
    fontWeight:"700",
    paddingBottom:"16px"
}