import { Colors } from "../../../../../theme/theme";

export const stackStyle = {
    flexDirection:"row",
    justifyContent:"space-between",
    padding:"16px"
}

export const titleStyle = {
    fontSize:"18px",
    fontWeight:"700"
}

export const descStyle = {
    fontSize:"14px",
    fontWeight:"400",
    color:"#666666"
}

export const loadingBtnStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,
    textTransform: "none",
    "&:hover": {
                 backgroundColor: Colors.piramal_orange,
                },
}
