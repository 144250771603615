import * as React from "react";
import { Colors } from "../../../theme/theme";
import { Tabs, Box } from "@mui/material";
import {
  FormDetails,
  TabPanel,
  StyledTab,
  LayoutMainBox,
  LayoutNoContentBox,
  LayoutTabsStyle,
} from "./TabsComponents";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Styles from "./Tabs.module.css";
export default function TabsLayout({
  TabsList,
  formName,
  // callFunc,
  isDealTab = false,
  icon,
  iconColor,
  image,
}) {
  const state = useSelector((state) => state.uploadReport);
  const Loanstate = useSelector((state) => state.loan);
  const originatorState = useSelector((state) => state.originator);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (userData?.roles[0] === "L1" && isDealTab) {
      setValue(Loanstate.value);
    }
  }, [Loanstate.value]);

  useEffect(() => {
    if (originatorState?.dealRedirection) {
      setValue(1);
    }
  }, [originatorState?.dealRedirection]);

  // useEffect(() => {
  //   setValue(1);
  // }, [callFunc]);

  const handleChange = (event, newValue) => {
    if (!state.disableQueryandHistory) {
      setValue(newValue);
    }
  };

  console.log({ value });

  console.log(TabsList);
  const tabsTitle = TabsList.map((tab, indx) => (
    <StyledTab
      key={indx}
      disabled={tab?.isDisabled ? true : false}
      label={tab["name"]}
    />
  ));

  const tabsChildren = TabsList?.map((tab, index) => (
    <TabPanel value={value} key={index} index={index}>
      {tab["component"]}
    </TabPanel>
  ));

  return (
    <>
      <Box sx={LayoutMainBox}>
        <div>
          <FormDetails icon={icon} iconColor={iconColor} formName={formName} />
          <Box sx={LayoutNoContentBox}></Box>

          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: LayoutTabsStyle,
            }}
          >
            {tabsTitle}
          </Tabs>
        </div>
        <div className={Styles.layoutMainImageDiv}>
          <img className={Styles.layoutMainImage} src={image} alt="image" />
        </div>
      </Box>
      {tabsChildren}
    </>
  );
}
