import React, { useEffect } from "react";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";
import {
  Box,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import OriginatorTable from "../../components/Table/Table";
import { useState } from "react";
import { Button } from "@mui/material";
import SecoundaryButton from "../../components/Buttons/SecoundaryButton";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import CircularProgress from "@mui/material/CircularProgress";

//? redux
import { getOriginatorList } from "../../redux/originator/originatorSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../Hooks/useDebounce";
import {
  showErrorAlertAction,
  showAlertAction,
  clearErrorAndMessage,
} from "../../redux/app/appSlice";
import Paths from "../../components/constants/Path";
import SearchIcon from "@mui/icons-material/Search";
import searchIcon from "../../assets/icons/searchIcon.svg";
import { FilterConstants } from "../../components/constants/FiltersConstant";
import { filter } from "lodash";
import Loader from "./../../utils/Loader";
import { breadCrums } from "../../components/constants/BreadCrumbs";

const ListOfOriginator = () => {
  //? useSelector
  let {
    loading,
    foundOriginatorList,
    originatorsList,
    totalPages,
    pageNumber,
    pageLimit,
    error,
    success,
  } = useSelector((state) => state.originator);

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(pageNumber || 1);
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("All");

  //? useHistory
  let history = useHistory();

  //? useDispatch
  let dispatch = useDispatch();

  //? for breadCrumbs component
  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: " Orginator Profile",
    },
  ];

  let userData = JSON.parse(localStorage.getItem("UserData"));

  // const filterList = ["All", "Approved", "On Hold", "Pending", "Rejected"];
  const filterList = [
    FilterConstants.ALL,
    FilterConstants.APPROVED,
    FilterConstants.ON_HOLD,
    FilterConstants.PENDING,
    FilterConstants.REJECTED,
  ];

  const filterHandler = (status) => {
    setFilterName(status);
  };

  //? useEffect

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);

  const debouncedSearch = useDebounce(search, 1000);
  

  useEffect(() => {
    dispatch(
      getOriginatorList({ limit, currentPage, debouncedSearch, filterName })
    );
  }, [currentPage, filterName, debouncedSearch]);

  useEffect(() => {
    if (foundOriginatorList) {
      return history.push(Paths.ORIGINATOR_LIST);
    } else {
      return history.push(Paths.CREATE_ORIGINATOR);
    }
  }, [foundOriginatorList]);

  const nextPageHandler = () => {
    if (currentPage == totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  const onClickHandlerToCreateOriginator = () => {
    return history.push(
      originatorsList?.length < 0
        ? Paths.CREATE_ORIGINATOR
        : Paths.ORIGINATOR_FORM
    );
  };

  // to remove unused code
  const clickHandlerToViewDetails = () => {
    history.push("/editoriginator/view/details/:originatorId");
  };

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Originators
            </Typography>
            {userData?.roles.length != 1 ? (
              <></>
            ) : (
            <SecoundaryButton
              onClickHandler={onClickHandlerToCreateOriginator}
              text="Create Originator Profile"
            ></SecoundaryButton>
            )}
          </Stack>

          <Divider />
          <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          >
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item ? "white" : "black",
                        backgroundColor:
                          filterName === item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <Box style={{ height: "calc( 100% - 64px)" }}>
              {originatorsList && originatorsList?.length > 0 && (
                <OriginatorTable
                  nextPageHandler={nextPageHandler}
                  prevPageHandler={prevPageHandler}
                  page={currentPage}
                  limit={pageLimit}
                  totalPages={totalPages}
                  // actionOnClick={clickHandlerToViewDetails}
                  rows={[
                    "Originator Name",
                    "Originator Type",
                    "Creation Date",
                    "Status",
                    "Action",
                  ]}
                  data={originatorsList}
                />
              )}
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default ListOfOriginator;
