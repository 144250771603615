import React from "react";
import { Stack, Tab, Box, Avatar, Typography } from "@mui/material";
import Style from "./Tabs.module.css";
import { styled } from "@mui/material/styles";
import { Colors } from "../../../theme/theme";

export const FormDetailsBoxStyles = {
  height: "auto",
  paddingLeft: "14px",
  paddingRight: "24px",
  paddingTop: "40px",
};
export const FormDetailsStackStyles = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
};

export const FormDetailsFormNameStyles = {
  fontSize: "18px",
  fontWeight: 500,
  paddingLeft: "15px",
};

export const FormDetailsAvatarStyles = (iconColor) => {
  return {
    backgroundColor: iconColor,
  };
};

export const FormDetails = ({ formName, icon, iconColor }) => {
  return (
    <>
      <Box sx={FormDetailsBoxStyles}>
        <Stack sx={FormDetailsStackStyles}>
          <Avatar sx={FormDetailsAvatarStyles(iconColor)}>{icon}</Avatar>
          <Typography sx={FormDetailsFormNameStyles}>{formName}</Typography>
        </Stack>
      </Box>
    </>
  );
};

export const ReportDetailsBoxStyles = {
  height: "auto",
  paddingLeft: "14px",
  paddingRight: "24px",
  paddingTop: "46px",
};

export const ReportDetailsFormNameStyles = {
  fontSize: "18px",
  fontWeight: 700,
  paddingLeft: "15px",
};

export const ReportDetails = ({ formName, icon, iconColor }) => {
  return (
    <>
      <Box sx={ReportDetailsBoxStyles}>
        <Stack sx={FormDetailsStackStyles}>
          <Avatar sx={FormDetailsAvatarStyles(iconColor)}>{icon}</Avatar>
          <Typography sx={ReportDetailsFormNameStyles}>{formName}</Typography>
        </Stack>
      </Box>
    </>
  );
};

export const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "black",
    fontWeight: "700",
  },
  textTransform: "none",
});

export const TabPanel = (props) => {
  const { children, value, index, setQueryHandler } = props;

  return (
    <>{value === index && <div className={Style.tabStyle}>{children}</div>}</>
  );
};

export const LayoutMainBox = {
  width: "100%",
  paddingLeft: "14px",
  backgroundColor: Colors.light_green,
  display: "flex",
  justifyContent: "space-between",
  height: "128px",
};
export const LayoutNoContentBox = {
  paddingTop: "16px",
};
export const LayoutTabsStyle = {
  backgroundColor: Colors.piramal_orange,
  height: 4,
};
