import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Paths from "../../components/constants/Path";
import Layout from "../../components/Layout/Layout";
import { styled } from "@mui/material/styles";
import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import DealDuplicationDealistTable from "../../components/Table/DealDuplicationDealistTable";
import searchIcon from "../../assets/icons/searchIcon.svg"

const DealDuplicationDealist = () => {
    const breadCrumbs = [
        {
          path: "/dashboard",
          pathName: "Home",
        },
        {
          pathName: "Deal List",
        },
      ];

      const [selectedFilter, setFilter] = useState(0);
    const [search, setSearch] = useState("");

    const filterHandler = (index) => {
        setFilter(index);
      };
    
      const StyledButton = styled(Button)(() => ({
        borderRadius: "24px",
        textTransform: "none",
      }));

      const TableData = [
        {
          id: 8,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          CreationDate: "12 Aug 2022",
        },
        {
          id: 18,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          CreationDate: "12 Aug 2022",
        },
        {
          id: 98,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          CreationDate: "12 Aug 2022",
        },
        {
          id: 28,
          DealName: "P1 Home Loan",
          AssetClass: "Gold Loan",
          CreationDate: "12 Aug 2022",
        },
        {
            id: 44,
            DealName: "P1 Home Loan",
            AssetClass: "Gold Loan",
            CreationDate: "12 Aug 2022",
          },
      ];

      let history = useHistory();
      const clickHandlerToViewDetails = () => {
          return history.push(Paths.DEALDUPLICATIONDETAILS);
      };

      const [filterName, setFilterName] = useState("All");
    return (
        <Layout breadCrumbs={breadCrumbs}>
            <Box
            backgroundColor="white"
            minHeight="144px"
            maxHeight="auto"
            minWidth="auto"
            maxWidth="auto"
            boxShadow={4}
            borderRadius={2}
            sx={{ p: 2, m: 2 }}
          >
            <>
              <Stack
                direction="row"
                alignContent="center"
                justifyContent="space-between"
                paddingBottom="12px"
                display="flex"
              >
                <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
                  Deals Assigned
                </Typography>
                <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon}/>
                    {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            ></TextField>
              </Stack>
    
              <Divider></Divider>
    
              
    
              <Box>
                <DealDuplicationDealistTable
                  data={TableData}
                  header={[
                    "Deal Name",
                    "Asset Class",
                    "Creation Date",
                    "Action",
                  ]}
                  viewActionClickHandler={clickHandlerToViewDetails}
                ></DealDuplicationDealistTable>
              </Box>
            </>
          </Box>
        </Layout>
    )

}

export default DealDuplicationDealist