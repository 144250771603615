import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Download from "../../../assets/icons/download.svg";
import DisableDownload from "../../../assets/icons/disableDownload.svg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Stack, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../../Pagination/Pagination";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import styles from "./Status.module.css";
import { useDispatch, useSelector } from "react-redux";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import {
  getDownloadImageById,
  getReportList,
  setSortValue,
  setSortName,
} from "../../../redux/Phase2/UploadReportSlice";
import { ArrowDownward } from "@mui/icons-material";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { downloadImageReports } from "../../../redux/Phase2/SearchReports";
import { arrowDownwardStyle, arrowUpwardStyle, reportpBox, statusTopBox } from "../TableCSS";
import customStyles from "../Table.module.css"

const ReportsTable = ({
  data,
  page,
  totalRecords,
  header,
  nextPageHandler,
  prevPageHandler,
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  //? Local Storage Data
  let userData = JSON.parse(localStorage.getItem("UserData"));

  let uploadReportState = useSelector((state) => state.uploadReport);
  //Download Report Function End Here //

  // Downloading File
  async function processDownloadExcel(item) {
    console.log("pktest",item?.accountingEntryData);
    let fileId = item?.accountingEntryData?._id;
    try {
      let result = await dispatch(
        downloadImageReports({
          id: fileId,
          fileName: item?.accountingEntryData?.filename,
        })
      ).unwrap();
      return result;
    } catch (error) {
      dispatch(
        showErrorAlertAction("Something went wrong! Please try again later.")
      );
    }
  }

  // Styles for Table Cell and Table Row starts here
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#365069",
      fontSize: "14px",
      fontWeight: "800",
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
      fontWeight: "600",
    },
    padding: "20px",
  }));
  // Styles for Table Cell and Table Row ends here

  //Color for the Approval status of the deal in the table row //
  const StatusContainer = ({ statusType }) => {
    console.log({ statusType });
    const Textcolor =
      statusType === "APPROVED"
        ? "#277742"
        : statusType === "REJECTED"
        ? "#996E37"
        : "#8D3431";

    const boxBackgroudColor =
      statusType === "APPROVED"
        ? "#E3F6E9"
        : statusType === "REJECTED"
        ? "#FFF4E7"
        : "#FCE6E5";
    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
        }}
      >
        <div className={styles.p}>
          <p style={{ fontSize: "12px", fontWeight: "800" }}>{statusType}</p>
        </div>
      </Box>
    );
  };
  //Color for the Approval status of the deal in the table row ends here //

  //Color for the upload status of the deal in the table row //
  const FinalContainer = ({ statusType }) => {
    console.log({ statusType });
    const Textcolor =
      statusType === "PARTIALLY DONE"
        ? "#4C2E65"
        : statusType === "IN PROGRESS"
        ? "#996E37"
        : statusType === "DONE"
        ? "#277742"
        : statusType === "MONTHLY"
        ? "#277742"
        : statusType === "MIS"
        ? "#8D3431"
        : "#8D3431";

    const boxBackgroudColor =
      statusType === "PARTIALLY DONE"
        ? "#ECE4F2"
        : statusType === "IN PROGRESS"
        ? "#FFF4E7"
        : statusType === "DONE"
        ? "#E3F6E9"
        : statusType === "MONTHLY"
        ? "#E3F6E9"
        : statusType === "MIS"
        ? "#FCE6E5"
        : "#FCE6E5";
    return (
      <Box
        sx={reportpBox}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
        }}
      >
        {statusType === "MIS" || statusType === "MONTHLY" ? (
          <div className={styles.p}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "800",
                textTransform: "uppercase",
              }}
            >
              {statusType}
            </p>
          </div>
        ) : (
          <div className={styles.p}>
            <p style={{ fontSize: "12px", fontWeight: "800" }}>{statusType}</p>
          </div>
        )}
      </Box>
    );
  };
  //Color for the upload status of the deal in the table row ends here //

  //Action Button Function Start Here //
  const Actions = ({ label, Icon, onclickhandler, data }) => {
    console.log({ data });
  
    return (
      <div
        onClick={
          userData.roles[0] === "F2" && data?.status === "SUBMITTED"
            ? onclickhandler
            : data?.currentStatus === "IN_PROGRESS"
            ? null
            : onclickhandler
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          {label === "View" ? (
            <Icon
              fontSize="small"
              sx={{
                color:
                  data?.currentStatus === "IN_PROGRESS" ? "#AFB9C3" : "#365069",
              }}
            ></Icon>
          ) : (
            <>
              {data?.accountingEntryData === null || data?.accountingEntryData === undefined? (
                <img src={DisableDownload} width="18px"></img>
              ) : (
                <img src={Download} width="18px"></img>
              )}
            </>
          )}

          {label === "View" ? (
            <Typography
              sx={{
                // color: "#365069",
                color:
                  data?.currentStatus === "IN_PROGRESS" ? "#AFB9C3" : "#365069",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {label}
            </Typography>
          ) : (
            <Typography
              sx={{
                color:
                  data?.accountingEntryData === null || data?.accountingEntryData === undefined ? "#AFB9C3" : "#365069",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {label}
            </Typography>
          )}
        </Stack>
      </div>
    );
  };
  //Action Button Function End Here //

  //Action Button Function Start Here //
  const Actions2 = ({ label, Icon, onclickhandler, data }) => {
    
    return (
      <div
        onClick={
          data?.status === "SUBMITTED" || data?.status === "APPROVED"
            ? onclickhandler
            : null
        }
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="start"
          spacing={1}
        >
          {label === "View" ? (
            <Icon
              fontSize="small"
              sx={{
                color:
                  data?.status === "SUBMITTED" || data?.status === "APPROVED"
                    ? "#365069"
                    : "#AFB9C3",
              }}
            ></Icon>
          ) : (
            <>
              {data?.accountingEntryData === null || data?.accountingEntryData === undefined ? (
                <img src={DisableDownload} width="18px"></img>
              ) : (
                <img src={Download} width="18px"></img>
              )}
            </>
          )}

          {label === "View" ? (
            <Typography
              sx={{
                color:
                  data?.status === "SUBMITTED" || data?.status === "APPROVED"
                    ? "#365069"
                    : "#AFB9C3",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {label}
            </Typography>
          ) : (
            <Typography
              sx={{
                color: data?.status === "SUBMITTED" ? "#365069" : "#AFB9C3",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {label}
            </Typography>
          )}
        </Stack>
      </div>
    );
  };
  //Action Button Function End Here //

  const headerFilters = {
    "Upload Name": "name",
    "Name of F1": "uploadedByName",
    "Report Upload Date (F1)": "createdAt",
  };

  const MapHeader = ({ data }) => {
    const [sort, setSort] = useState("");
    useEffect(() => {
      if (sort !== "") {
        // setCallFunc({ data, sort });
        dispatch(setSortValue(!uploadReportState.sortValue));
        dispatch(setSortName(data));
        let sortName = headerFilters[`${data}`];
        if (sortName !== undefined) {
          dispatch(
            getReportList({
              currentPage: page,
              limit: 10,
              sortName: sortName,
              // filterName,
              sortOrder: uploadReportState.sortValue ? 1 : -1,
            })
          );
        }
      }
      return;
    }, [sort]);

    console.log(headerFilters[data]);
    console.log(uploadReportState?.sortFieldName);

    console.log(data === uploadReportState?.sortFieldName);
    return (
      <StyledTableCell
        onClick={() =>
          data == "Upload Name" ||
          data == "Name of F1" ||
          data == "Report Upload Date (F1)"
            ? setSort(!sort)
            : null
        }
        style={{
          cursor:
            data == "Upload Name" ||
            data == "Name of F1" ||
            data == "Report Upload Date (F1)"
              ? "pointer"
              : null,
        }}
      >
        {data}

        {data !==
        uploadReportState?.sortFieldName ? null : uploadReportState?.sortValue !==
          true ? (
          <ArrowUpward sx={arrowUpwardStyle} />
        ) : (
          <ArrowDownward sx={arrowDownwardStyle} />
        )}
      </StyledTableCell>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <MapHeader data={data} />;
              })}
            </TableRow>
          </TableHead>

          {data && data.length === 0 ? (
            <div className={customStyles.noRecordFoind}>
              No Records Found!!
            </div>
          ) : (
            // Table Body Start Here
            <TableBody>
              {data &&
                data.map((item) => {
                  var uploadedDate = moment(item.uploadedDate).format(
                    "D MMM YYYY"
                  );
                  var uploadName = `${item?.dealIdRefData?.dealCode} (${item.iteration})`;
                  var month = `${item?.month}  (${item?.year})`
                  var currentStatus = item?.currentStatus.replace("_", " ");
                  return (
                    <TableRow key={item._id}>
                      <StyledTableCell component="th" scope="row">
                        {item?.dealIdRefData?.name} {`(${item?.dealIdRefData?.dealCode})`}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {month}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {uploadedDate}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {item?.createdByRefData?.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <StatusContainer
                          statusType={item?.status}
                        ></StatusContainer>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FinalContainer
                          statusType={currentStatus}
                        ></FinalContainer>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <FinalContainer
                          statusType={item?.updateOtherData ? "MIS" : "MONTHLY"}
                        ></FinalContainer>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Actions
                          label="Download"
                          data={item}
                          Icon={Download}
                          onclickhandler={
                            item?.accountingEntryData === null || 
                            item?.accountingEntryData === undefined
                              ? null
                              : () => processDownloadExcel(item)
                          }
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        {userData.roles[0] === "F1" ? (
                          <Actions
                            label="View"
                            data={item}
                            Icon={VisibilityIcon}
                            onclickhandler={() => {
                              history.push(
                                `/phase2listofReports/${item._id}`
                              );
                            }}
                          ></Actions>
                        ) : (
                          <Actions2
                            label="View"
                            data={item}
                            Icon={VisibilityIcon}
                            onclickhandler={() => {
                              history.push(
                                `/phase2listofReports/${item._id}`
                              );
                            }}
                          />
                        )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            // Table Body End Here
          )}
        </Table>
      </TableContainer>

      {/* Pagination Start Here */}
      {data && data.length === 0 ? null : (
        <Pagination
          Page={page }
          TotalNoNumber={totalRecords}
          nextPageHandler={page  === totalRecords ? nextPageHandler : nextPageHandler}
          prevPageHandler={prevPageHandler}
        />
      )}
      {/* Pagination End Here */}
    </div>
  );
};

export default ReportsTable;
