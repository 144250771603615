export const topBoxStyle = {
    backgroundColor:"white",
    minHeight:"144px",
    maxHeight:"auto",
    minWidth:"auto",
    maxWidth:"auto",
    boxShadow:"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderRadius: "8px",
    padding:"16px",
    margin:"16px",
}

export const listDealStack = {
    flexDirection:"row",
    alignContent:"center",
    justifyContent:"space-between",
    paddingBottom:"12px",
    display:"flex"
}

export const titleTypo = {
    fontSize:"16px",
    fontWeight:"700",
    paddingBottom:"16px"
}