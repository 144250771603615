import React from "react";
import Histories from "../../components/History/Histories";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import QueriesPage from "../../components/Queries/Queries";
import DescriptionIcon from "@mui/icons-material/Description";
import Image from "../../assets/images/Group 42722.svg";
import Tabs from "../../components/Tabs/Tabs";
import { useSelector } from "react-redux";
import BasicInformation from "../../components/Tabs/DealDuplicationTabs/BasicInformation";
import AssignSPOCs from "../../components/Tabs/DealDuplicationTabs/AssignSPOCs";
import { useParams } from "react-router-dom";

const DealDuplication = () => {
  const params = useParams();
  const dealState = useSelector((state) => state.deal);
  const tabs = [
    {
      tabName: "Basic Information",
      comp: BasicInformation,
      heading: "Deal’s Basic Details",
    },
    {
      tabName: "Assign SPOCs",
      comp: AssignSPOCs,
      heading: "Assign SPOCs for - P1 Home Loan Deal",
    },
  ];

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      path: "/dealduplication/deal/list",
      pathName: "Deal List",
    },
    {
      pathName: "Create New Deal",
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  let dealDuplicationState = useSelector((state) => state.dealDuplication);

  return (
    <Layout breadCrumbs={breadCrumbs}>
      <TabsLayout
        formName="Create New Deal"
        image={Image}
        icon={icon}
        iconColor={"#DBE3F8"}
        TabsList={[
          {
            name: "Deal",
            index: 0,
            component: (
              <Tabs
                tabs={tabs}
                status={dealDuplicationState["checkedPages"]}
                breadCrumbs={breadCrumbs}
              />
            ),
          },
          {
            name: "Queries", index: 1, component: <QueriesPage
              dealIdorOriginatorId={params.dealId}
              currentStage={dealState?.filterdata?.currentStage || ""}

            />,
          },
          { name: "History", index: 2, component: <Histories /> },
        ]}
      ></TabsLayout>
    </Layout>
  );
};

export default DealDuplication;
