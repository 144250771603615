import axios from "axios";
import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, Checkbox } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";

import { useHistory } from "react-router-dom";
import { actionTypo, actionsIcon, statusTopBox } from "./TableCSS";

const EditOriginatorDealistTable = ({ data, viewActionClickHandler, header, statusList }) => {
  const [page,setPage] = useState(1);

  const nextPageHandler = () => {
    setPage(page+1);
  }

  const prevPageHandler = () => {
    setPage(page-1);
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
        display: "flex",
        flexDirection: "column"
  }));


  const StatusContainer = ({ statusType }) => {

    // console.log(statusList)
    
    const foundStatus = statusList.find(obj => {
        return obj.status === statusType
    })

    // console.log(foundStatus.color)
    const Textcolor = foundStatus.color;

    const boxBackgroudColor = foundStatus.bgcolor;

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
        }}
      >
        {statusType}
      </Box>
    );
  };

  const Actions = ({ label = "View", Icon }) => {
    // label = "View"
    return (
      <div onClick={() => viewActionClickHandler()} style={{ cursor: "pointer" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Icon sx={actionsIcon}></Icon>

          <Typography sx={actionTypo}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item.OriginatorName}
                  </StyledTableCell1>
                  <StyledTableCell component="th" scope="row">
                    {item.OriginatorType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item.CreationDate}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <StatusContainer
                      statusType={item.PendingAction}
                    ></StatusContainer>
                  </StyledTableCell>

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions Icon={VisibilityIcon}></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination TotalNoNumber={Math.ceil(data.length / 10) } Page={page} nextPageHandler={nextPageHandler} prevPageHandler={prevPageHandler}></Pagination>
    </div>
  );
};

export default EditOriginatorDealistTable;
