import { Colors } from "../../theme/theme";

export const box = {
    backgroundColor: "white",
    border: "1px solid #5E7387",
    borderRadius: 2,
    display: "inline-flex",
    height: "40px",
    paddingLeft: "16px",
    paddingRight: "16px",
}

export const boxStyle = (isDisable) => {
    if(isDisable){
        return {
            ...box,
            border: "1px solid #AFB9C3",
        }
    }
    return box;
}

export const stackStyle = {
    flexDirection: "row",
    alignItems:"center",
    justifyContent:"center",
    height:"40px"
}

export const typography = {
    fontSize:"12px",
    fontWeight:"800",
    color: "#365069"
}

export const typographyStyle = (isDisable) => {
    if(isDisable){
        return {
            ...typography,
            color: "#AFB9C3"
        }
    }
    return typography; 
}


// PrimaryButton.js ///////

export const btnStyle = {
    backgroundColor: Colors.piramal_orange,
    color: Colors.white,
    // minWidth: "165px",
    maxWidth: "auto",
    textTransform: "none",
    "&:hover": {
      backgroundColor: Colors.piramal_orange,
    },
    "&:disabled": {
      backgroundColor: "#FAC3B3",
      color: "white",
    },
}