import React, { useEffect, useState } from "react";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadTemplate from "../../Wrapper/UploadTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPageStatusAction,
  pageStatusAction,
  submitKYCVerificationReport,
} from "../../../redux/Auditor/kycApproveRejectSlice";
import Paths from "../../constants/Path";
import { useHistory, useParams } from "react-router-dom";
import TabFooter from "../TabFooter/TabFooter";
import DoneIcon from "@mui/icons-material/Done";
import BasicModal from "../../Modal/Modal";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { getAllDeals } from "../../../redux/Deals/dealSlice";
import { deleteDoc } from "../../../redux/originator/originatorSlice";
import InvestmentMemoDownload from "./InvestmentMemoDownload";
import Loader from "../../../utils/Loader";
import { setInvestmentMemo } from "./../../../redux/Entity/entitySlice";

const InvestmentMemo = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const entityState = useSelector((state) => state.entity);
  const originatorState = useSelector((state) => state.originator);
  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const [msgLoading, setMsgLoading] = React.useState(false);
  const [state, setState] = useState(null);
  const [loanFile, setLoanFile] = useState(null);
  const dealState = useSelector((state) => state.deal);
  console.log("finalsubmit", loanFile);
  const onClickHandlerToDealsPage = () => {
    setOpen(true);
    dispatch(pageStatusAction({ pageName: "verificationDoc" }));
  };

  const handleClosureStatus = (e) => {
    e.preventDefault();
    if (loanFile == undefined) {
      dispatch(showErrorAlertAction("Please select file !"));
      setOpen(false);
      return;
    }

    dispatch(
      setInvestmentMemo({
        file: originatorState.InvestmentMemo.fileId,
        dealId: params.dealId,
      })
    );
    history.push(`/portfolio/${dealState?.filterdata?._id}/deal-details`);
  };
  //
  console.log({ open });

  return (
    <>
      {entityState.entitydata == null ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader
            title="Investment Memo Template"
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              ></DescriptionIcon>
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <InvestmentMemoDownload
              fileName={entityState.entitydata?.file.filename}
              id={entityState.entitydata?.file._id}
            ></InvestmentMemoDownload>
          </div>

          <DocumentHeader
            title={"Investment Memo"}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              />
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <UploadTemplate
              docName="InvestmentMemo"
              setdata={setState}
              setLoanFile={setLoanFile}
            />
          </div>

          <TabFooter
            label="Upload"
            isBtnDisable={false}
            endIcon={<DoneIcon />}
            onclickHandler={onClickHandlerToDealsPage}
          />

          <BasicModal
            open={open}
            setOpen={setOpen}
            title="Do you want to Upload this File?"
            positiveText="Yes"
            negativeText="No"
            positiveOnClickHandler={handleClosureStatus}
            setloading={setMsgLoading}
          ></BasicModal>
        </>
      )}
    </>
  );
};

export default InvestmentMemo;
