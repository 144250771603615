import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";
import searchIcon from "../../assets/icons/searchIcon.svg";
import Layout from "../../components/Layout/Layout";
import AuditorDealistTable from "../../components/Table/AuditorDealistTable";
import { getAllDeals } from "../../redux/Deals/dealSlice.js";
import { showErrorAlertAction } from "../../redux/app/appSlice";
import styles from "./Auditor.module.css";
import { fontsStyle, stackStyle } from "./AuditorCSS";
import Loader from "../../utils/Loader.jsx";
import { FilterConstants, statusConstants } from "../../components/constants/FiltersConstant.js";
import Paths from "../../components/constants/Path.jsx";

const AuditorDealList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filterName, setFilterName] = useState("All");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);
  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if (name == "PEL" || name == "PCHFL") {
      setEntityFilter(name);
      setFilterName("")
    } else {
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        entityFilter,
        filterName,
        isKycUploded: true,
        isCreditBureauCheckDone: true,
        isSecondaryCheckDone: true,
        source: "PHFL",
      })
    );
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];

  let history = useHistory();
  const clickHandlerToViewDetails = (deal) => {
    if (
      deal?.poolUploadFinal?.kycVerificationCurrentStatus &&
      (deal?.poolUploadFinal?.kycVerificationCurrentStatus ===
        statusConstants.DONE ||
        deal?.poolUploadFinal?.kycVerificationCurrentStatus ===
        statusConstants.FAILED ||
        deal?.poolUploadFinal?.kycVerificationCurrentStatus ===
        statusConstants.PARTIALLY_DONE)
    ) {
      return history.push({
        pathname: `/auditor/${deal._id || deal.id || deal}/loan/summary`,
        state: deal,
      });
    }
    if (
      deal?.poolUploadFinal?.kycVerificationCurrentStatus ===
      statusConstants.FILE_RECEIVED
    ) {
      return;
    }
    return history.push({
      pathname: `/auditor/${deal._id || deal}/kycapprovereject`,
      state: deal,
    });
  };

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Deals List",
    },
  ];

  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout breadCrumbs={breadCrumbs}>
      {dealState["loading"] ? (
        <Loader />
      ) : (
        <Box
          backgroundColor="white"
          minHeight="144px"
          maxHeight="auto"
          minWidth="auto"
          maxWidth="auto"
          boxShadow={4}
          borderRadius={2}
          sx={{ p: 2, m: 2 }}
        >
          <>
            <Stack sx={stackStyle}>
              <Typography sx={fontsStyle} paddingBottom={2}>
                List of Deals
              </Typography>
            </Stack>

            <Divider></Divider>

            <div className={styles.filterListWrapper}>
              <div>
                {filterList &&
                  filterList.map((item, index) => (
                    <>
                      <StyledButton
                        key={index}
                        variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                        sx={{
                          color: filterName === item || entityFilter == item ? "white" : "black",
                          backgroundColor:
                            filterName === item || entityFilter == item ? "#365069" : "white",
                          borderColor: "black",
                          mr: 1,
                        }}
                        onClick={() => filterHandler(item)}
                      >
                        {item}
                      </StyledButton>
                    </>
                  ))}
              </div>
              <TextField
                placeholder="Search"
                autoComplete="off"
                value={search}
                variant="standard"
                size="small"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>

            <Box>
              <AuditorDealistTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Entity Type",
                  "Audit Assigned Date",
                  // "KYC Verification Report Status",
                  "Upload Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
              ></AuditorDealistTable>
            </Box>
          </>
        </Box>
      )}
    </Layout>
  );
};

export default AuditorDealList;
