import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import ViewDownloadDeleteComp from "../../../components/L2/ViewDownloadAPI";
import SpocTable from "../../../components/Table/SpocTable";
import CTAWrapperHandler from "../../../components/Wrapper/CTAWrapperHandler";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import { fetchQueryList, postQuery } from "../../../redux/Queries/queriesSlice";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import {
  getOriginatorDetails,
  setOriginatorStatus,
} from "../../../redux/originator/originatorSlice";
import Loader from "../../../utils/Loader";
import Paths from "./../../../components/constants/Path";
import { statusConstants } from "../../../components/constants/FiltersConstant";
import customStyle from "./Originator.module.css"
import { addQueryTypo, displayFlex, originatorName, queriesStackWrapper, queryTypo, selectStatus, typo, typoGraphyStyle, widthStyle } from "./OriginatorCSS";
import AllOriginatorDocuments from "../../../components/AllOriginatorDocuments";
import ModalV3 from "../../../components/Modal/ModalV3";

const L2ViewOriginatorsDetails = ({ showModal, childMsg, popUpOpen, setPopUpOpen, modalData, gstValues }) => {
  const [state, setState] = useState("");
  const [dedupemodal, setDeDupeModal] = useState(false)
  const [queryDetails, setQueryDetails] = useState({
    query: "",
    originatorId: "",
    queryModule: "Originator",
  });
  const [originatorData, setOriginatorData] = useState("")

  let userData = JSON.parse(localStorage.getItem("UserData"));

  const params = useParams();
  const dispatch = useDispatch();
  console.log({ params });

  let history = useHistory();
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    console.log({ value });
    setState(value);
  };

  const { originatorId } = useParams();



  useEffect(() => {
    console.log("id", originatorId);
    dispatch(getOriginatorDetails(originatorId)).then((res) => {
      console.log({ res });
      setOriginatorData(res?.payload)
      setState(res.payload?.data?.status || res.payload?.status);
    });
  }, []);

  let originatorState = useSelector((state) => state.originator);


  // for setting status in dropdown
  useEffect(() => {
    setState(originatorState?.status);
    console.log({ state });
  }, [originatorState?.status]);

  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  console.log({ state });

  //handle CTA functionality
  const CTAClickHandler = (queryDetails) => {
    //handle status update api
    if (originatorState.status != statusConstants.APPROVED) {
      if (state != "") {
        dispatch(
          setOriginatorStatus({ id: params.originatorId, status: state })
        ).then((res) => {
          if (res?.payload?.status == 200) {
            dispatch(showAlertAction(res?.payload?.data?.message));
          }
          history.push(Paths.L2OIRIGINATORSLIST);
        });
      }

      //handle empty state selection case
      if (state == "") {
        dispatch(showErrorAlertAction("Please Select Status"));
      }
    }

    //handle query api to post query
    if (queryDetails.query != "" && state != "") {
      dispatch(postQuery(queryDetails)).then((data) => {
        let id = originatorId;
        dispatch(fetchQueryList({ id }));
        dispatch(showAlertAction(data.payload.data.message));
        history.push(Paths.L2OIRIGINATORSLIST);
      });
    }

  };

  const DeDupeCheck = () => {
    setPopUpOpen(true)
    setDeDupeModal(true);
  }

  const positiveOnClickHandler = () => {
    if (state != "") {
      dispatch(
        setOriginatorStatus({ id: params.originatorId, status: state })
      ).then((res) => {
        if (res?.payload?.status == 200) {
          dispatch(showAlertAction(res?.payload?.data?.message));
        }
        history.push(Paths.L2OIRIGINATORSLIST);
      });
    }

    if (state == "") {
      dispatch(showErrorAlertAction("Please Select Status"));
    }

    if (queryDetails.query != "" && state != "") {
      dispatch(postQuery(queryDetails)).then((data) => {
        let id = originatorId;
        dispatch(fetchQueryList({ id }));
        dispatch(showAlertAction(data.payload.data.message));
        history.push(Paths.L2OIRIGINATORSLIST);
      });
    }
  }

  const negativeOnClickHandler = () => {
    setPopUpOpen(!popUpOpen)
  };


  return (
    <>
      {originatorState?.fetchOriginatorLoding === true ||
        originatorState.loading ? (
        <Loader />
      ) : (
        <>
          <WrapperComponent title="Basic Information">
            <>
              <div className={customStyle.padding}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Typography sx={typoGraphyStyle}>
                      Originator Name
                    </Typography>
                    <Typography sx={originatorName} >
                      {originatorState?.originatorName}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography sx={typoGraphyStyle}>
                      Type of Originator
                    </Typography>
                    <Typography sx={originatorName} >
                      {originatorState?.originatorType}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </>
          </WrapperComponent>

          <WrapperComponent title="Other Details">
            <>
              <div className={customStyle.padding}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Typography sx={typoGraphyStyle}>
                      Originator's Pan
                    </Typography>
                    <Typography sx={originatorName} >
                      {originatorData?.kycData?.panNumber}
                    </Typography>
                  </Grid>
                  {originatorData?.kycData?.gstNumbers?.length > 0 ? (
                    <>
                      {originatorData?.kycData?.gstNumbers.map((item, index) => (
                        <>
                          <Grid item xs={3}>
                            <Typography sx={typoGraphyStyle}>
                              GST Number - {index + 1}
                            </Typography>
                            <Typography sx={originatorName} >
                              {item}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </>
          </WrapperComponent>

          {showModal && showModal == true ? (

            <WrapperComponent title="Duplicate Originator Details">
              <>
                <div className={customStyle.padding}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography sx={typoGraphyStyle}>
                        Originator's Name
                      </Typography>
                      <Typography sx={originatorName} >
                        {modalData?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={typoGraphyStyle}>
                        Originator's Pan
                      </Typography>
                      <Typography sx={originatorName} >
                        {modalData?.kyc?.panNumber}
                      </Typography>
                    </Grid>
                    {gstValues?.length > 0 ? (
                      <>
                        {gstValues?.map((item, index) => (
                          <>
                            <Grid item xs={3}>
                              <Typography sx={typoGraphyStyle}>
                                GST Number - {index + 1}
                              </Typography>
                              <Typography sx={originatorName} >
                                {item}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </div>
              </>
            </WrapperComponent>
          ) : (<></>)}

          <WrapperComponent title="SPOC Details">
            <>
              <div>
                <SpocTable data={originatorState?.spocs}></SpocTable>
              </div>
            </>
          </WrapperComponent>
          {originatorState?.originatorIdsData &&
            originatorState?.originatorIdsData !== null && (
              <WrapperComponent title="Originator Details">
                <>
                  <div>
                    <SpocTable
                      data={originatorState?.originatorIdsData}
                    ></SpocTable>
                  </div>
                </>
              </WrapperComponent>
            )}

          <WrapperComponent title="Documents">
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <div className={customStyle.padding}>
                    <Typography sx={typo}>
                      Portfolio Performance Metrics
                    </Typography>
                  </div>
                  <div className={customStyle.mappingStyle} >
                    {originatorState.portfolioPerformanceMetrics.map((l) => {
                      return (
                        <>
                          <ViewDownloadDeleteComp
                            id={l._id}
                            fileName={l.filename}
                            reportData={l.fileUrl}
                          ></ViewDownloadDeleteComp>
                          <br></br>
                        </>
                      );
                    })}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={customStyle.padding}>
                    <Typography sx={typo}>
                      Financial Performance Metrics
                    </Typography>
                  </div>
                  <div className={customStyle.halfPadding} >
                    {originatorState.financialPerformanceMetrics.map((l) => {
                      return (
                        <>
                          <ViewDownloadDeleteComp
                            fileName={l.filename}
                            id={l._id}
                            reportData={l.fileUrl}
                          ></ViewDownloadDeleteComp>
                          <br></br>
                        </>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </WrapperComponent>
          {originatorState.quaterlyPerformance.length !== 0 ? (
            <WrapperComponent title="Quaterly Performance Documents">
              <Box sx={widthStyle}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <div className={customStyle.padding}></div>
                    <div className={customStyle.mappingStyle} >
                      {originatorState.quaterlyPerformance.map((l) => {
                        return (
                          <>
                            <ViewDownloadDeleteComp
                              fileName={l.filename}
                              id={l._id}
                              reportData={l.fileUrl}
                            ></ViewDownloadDeleteComp>
                            <br></br>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </WrapperComponent>
          ) : null}

          <AllOriginatorDocuments />

          {userData?.roles.length == 1 ? (
            <CTAWrapperHandler title="Case Status">
              <div className={customStyle.padding}>
                <Typography sx={selectStatus}>
                  Select Status
                </Typography>
                <div className={customStyle.statusTopDiv}>
                  <div className={customStyle.statusWidth}>
                    {originatorState?.status === statusConstants.APPROVED ? (
                      <Select
                        IconComponent={ExpandMoreIcon}
                        value={state}
                        onChange={handleInputChange("originatorType")}
                        disabled={
                          originatorState.status === statusConstants.APPROVED
                            ? true
                            : false
                        }
                        displayEmpty
                        size={"small"}
                        variant="standard"
                        fullWidth
                        renderValue={
                          state !== ""
                            ? undefined
                            : () => <Placeholder>Select an Option</Placeholder>
                        }
                      >
                        <MenuItem value={"APPROVED"}>Approved</MenuItem>
                        <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                        <MenuItem value={"PENDING"}>Pending</MenuItem>
                        <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        IconComponent={ExpandMoreIcon}
                        value={state}
                        onChange={handleInputChange("originatorType")}
                        displayEmpty
                        size={"small"}
                        variant="standard"
                        fullWidth
                        renderValue={
                          state !== ""
                            ? undefined
                            : () => <Placeholder>Select an Option</Placeholder>
                        }
                      >
                        <MenuItem value={"APPROVED"}>Approved</MenuItem>
                        <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                        <MenuItem value={"PENDING"}>Pending</MenuItem>
                        <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                        <MenuItem value={"CLOSED"}>Closed</MenuItem>
                      </Select>
                    )}
                  </div>
                </div>
              </div>

              <Divider></Divider>

              {/* For Query UI */}
              <Stack sx={queriesStackWrapper}>
                <Box sx={displayFlex} >
                  <Typography sx={queryTypo}>
                    Queries
                  </Typography>
                </Box>
              </Stack>

              <div className={customStyle.padding}>
                <Typography sx={selectStatus}>
                  Post a Query
                </Typography>

                <Typography sx={addQueryTypo}>
                  Add your query
                </Typography>
                <div className={customStyle.statusTopDiv}>
                  <div className={customStyle.statusWidth}>
                    <TextField
                      variant="standard"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) =>
                        setQueryDetails({
                          ...queryDetails,
                          query: e.target.value,
                          documentId: originatorId,
                        })
                      }
                    ></TextField>
                  </div>
                  {originatorState.status != statusConstants.APPROVED && showModal && showModal && state && state == "APPROVED" ? (
                    <OutlineButton
                      text="Update"
                      showIcon={false}
                      onClickHandler={() => DeDupeCheck()}
                    ></OutlineButton>
                  ) : (
                    <OutlineButton
                      text="Update"
                      showIcon={false}
                      onClickHandler={() => CTAClickHandler(queryDetails)}
                    ></OutlineButton>
                  )}
                </div>
              </div>
            </CTAWrapperHandler>
          ) : (<></>)}

          {dedupemodal && dedupemodal && state && state == "APPROVED" ? (
            <>
              <ModalV3
                popUpOpen={popUpOpen}
                setPopUpOpen={setPopUpOpen}
                title="Potential Duplicate Profile Detected"
                isFile={false}
                children={childMsg}
                midHead1Text={"Entity Name:"}
                midHead2Text={"GST Number:"}
                midHead3Text={"PAN Number:"}
                midHead1={modalData?.name}
                midHead2={gstValues.join(" , ")}
                midHead3={modalData?.kyc?.panNumber}
                btnText="Continue"
                negativeText="Cancel"
                positiveOnClickHandler={() => positiveOnClickHandler(queryDetails)}
                negativeOnClickHandler={negativeOnClickHandler}
              ></ModalV3>
            </>
          ) : (
            <></>
          )}

          <div className={customStyle.paddingStyle}></div>
        </>
      )}
    </>
  );
};

export default L2ViewOriginatorsDetails;
