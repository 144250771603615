import React from "react";
import { MenuItem, Select, Box, Checkbox, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Styles from "./FormComponents.module.css";
import { SelectComponentMenuProps } from "./FormComponents";
export const SelectComponent = ({
  placeholder,
  optionList,
  value,
  onClickHandler,
  compWidth,
}) => {
  const Placeholder = ({ children }) => {
    return <div className={Styles.placeHolder}>{children}</div>;
  };

  const MenuProps = {
    PaperProps: {
      style: SelectComponentMenuProps(compWidth),
    },
  };
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      value={value}
      onChange={onClickHandler}
      displayEmpty
      size={"small"}
      variant="standard"
      fullWidth
      MenuProps={MenuProps}
      renderValue={
        value !== ""
          ? undefined
          : () => <Placeholder>{placeholder}</Placeholder>
      }
    >
      {optionList.map((data) => (
        <MenuItem value={data.id}>
          {data?.dealCode?.length > 0
            ? data.name + "(" + data?.dealCode + ")"
            : data.name}
        </MenuItem>
      ))}
    </Select>
  );
};