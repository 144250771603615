import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TabFooter from "../TabFooter/TabFooter";
import { useDispatch } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  FormControl,
  Stack,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import { Colors } from "../../../theme/theme";
import UploadTemplate from "../../Wrapper/UploadTemplateV3";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  deleteDoc,
  pageStatusAction,
} from "../../../redux/originator/originatorSlice";
import Radio from "@mui/material/Radio";
import {
  setLeiNumberAction,
  setGSTCards,
} from "../../../redux/originator/originatorSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./CreateOriginatorTabs.module.css";
import {
  descriptionIconStyle,
  radioGroupStyle,
  typoStyle,
  widthStyle,
} from "./CreateOriginatorTabsCSS";
import OriginatorGstMultipleUpload from "../../Wrapper/OriginatorGstMultipleUpload";
import AddIcon from "@mui/icons-material/Add";
import { IconButtonStyle } from "./../../DocumentHeaderWrapper/DocumentHeaderWrapperCSS";
import { AddMoreFileComponent } from "../../Wrapper/UploadComponents";

const KYCDocumentsTab = ({ nextTabHandler }) => {
  //? useSelector
  let originitorState = useSelector((state) => state.originator);
  const [selectedValue, setSelectedValue] = React.useState("LEICard");
  const [LEINumber, setLEINumber] = React.useState("");

  const [state, setState] = React.useState(true);
  const [status, setStatus] = React.useState(false);

  const [allGstFiles, setAllFiles] = React.useState(
    originitorState.allgstCertificate || [{}]
  );
  const [ids, setIds] = React.useState(originitorState.allgstCertificate || []);

  let dispatch = useDispatch();

  useEffect(() => {
    if (ids.length > 0) {
      dispatch(setGSTCards(ids));
    }
  }, [ids]);

  const addMoreGSTFiles = () => {
    if (allGstFiles.length > 9) return;
    setAllFiles([...allGstFiles, {}]);
  };

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(setLeiNumberAction(LEINumber));
    dispatch(pageStatusAction({ pageName: "kycDocuments" })); //? FOR GREEN TICK
    nextTabHandler();
  };

  function handleRadioBtnChange(event) {
    setSelectedValue(event.target.value);

    console.log(event.target.value);
  }

  const handleInputChange = (e) => {
    let value = e.target.value;
    setLEINumber(value);
    dispatch(setLeiNumberAction(value));
  };

  useEffect(() => {
    if (LEINumber !== null && LEINumber.length > 0 && LEINumber.length < 20) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [LEINumber]);

  useEffect(() => {
    if (
      (LEINumber == "" && originitorState["leiNumber"] != "") ||
      originitorState["leiNumber"] != null
    ) {
      setState(false);
      setLEINumber(originitorState["leiNumber"]);
    }
  }, [LEINumber]);

  useEffect(() => {
    if (selectedValue == "LEICard") {
      setLEINumber("");
      dispatch(setLeiNumberAction(""));
    } else {
      setLEINumber("");
      dispatch(deleteDoc("leiCard"));
    }
  }, [selectedValue]);

  useEffect(() => {
    if (originitorState["leiNumber"] != "") {
      setSelectedValue("LEINumber");
      setLEINumber(originitorState["leiNumber"]);
    } else {
      setSelectedValue("LEICard");
    }
  }, [originitorState["leiNumber"]]);

  const DocumentHeader = ({ title, AddMoreBtn = false, handleOnClick }) => {
    return (
      <div className={styles.gridWrapper}>
        <Stack direction="row" spacing={1}>
          <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
          <Typography sx={typoStyle}>{title}</Typography>
        </Stack>
        {AddMoreBtn && (
          <Stack>
            <AddMoreFileComponent
              clickhandler={handleOnClick}
            ></AddMoreFileComponent>
          </Stack>
        )}
      </div>
    );
  };

  console.log({ ids });

  const LEICard = () => {
    return (
      <div className={styles.gridWrapper}>
        <Stack direction="row">
          <div className={styles.paddingTopStyle}>
            <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
          </div>

          <div className={styles.paddingLeftStyle}>
            <FormControl component="fieldset" required error>
              <RadioGroup
                value={selectedValue}
                onChange={handleRadioBtnChange}
                row
                sx={radioGroupStyle}
              >
                <FormControlLabel
                  value="LEICard"
                  control={<Radio size="small" />}
                  label="LEI Card"
                />
                <FormControlLabel
                  value="LEINumber"
                  control={<Radio size="small" />}
                  label="LEI Number"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Stack>
      </div>
    );
  };

  return (
    <>
      <Box sx={widthStyle}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <DocumentHeader title="PAN Card"></DocumentHeader>

            <div className={styles.paddingStyle}>
              <UploadTemplate docName="panCard" setdata={setState} />
            </div>
          </Grid>

          <Grid item xs={6}>
            <DocumentHeader title="Incorporation Certificate" />
            <div className={styles.paddingStyle}>
              <UploadTemplate
                setdata={setState}
                docName="incorporationCertificate"
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <DocumentHeader title="Cancelled Cheque" />
            <div className={styles.paddingStyle}>
              <UploadTemplate setdata={setState} docName="cancelledCheque" />
            </div>
          </Grid>

          <Grid item xs={6}>
            <DocumentHeader title="RBI Registration Certificate"></DocumentHeader>
            <div className={styles.paddingStyle}>
              <UploadTemplate
                setdata={setState}
                docName="rbiRegisterationCertificate"
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <LEICard></LEICard>
            <div className={styles.paddingStyle}>
              {selectedValue === "LEICard" ? (
                <UploadTemplate setdata={setState} docName="leiCard" />
              ) : (
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  value={LEINumber}
                  type={"text"}
                  onInput={(e) => {
                    let value =
                      e.target.value.replace(/[^0-9a-zA-Z]/g, "") || "";
                    e.target.value = value.slice(0, 20);
                  }}
                  onChange={handleInputChange}
                  placeholder="Enter LEI Number"
                />
              )}
            </div>
          </Grid>
          {allGstFiles.map((data, i) => {
            return (
              <Grid item xs={6}>
                <DocumentHeader
                  title={`GST Certificate ${i + 1}`}
                  AddMoreBtn={i == 0 && true}
                  handleOnClick={addMoreGSTFiles}
                />
                <div className={styles.paddingStyle}>
                  {data && data !== undefined ? (
                    <OriginatorGstMultipleUpload
                      setdata={setState}
                      index={i}
                      data={data}
                      setIds={setIds}
                      ids={ids}
                      docName="gstCertificate"
                    />
                  ) : (
                    <OriginatorGstMultipleUpload
                      setdata={setState}
                      index={i}
                      setIds={setIds}
                      ids={ids}
                      docName="gstCertificate"
                    />
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <TabFooter
        showSkinBtn={true}
        onclickHandler={nextBtnClickHandler}
        isBtnDisable={status}
        skipBtnClickHandler={nextTabHandler}
        pageStatus={pageStatusAction}
        pageName={"kycDocuments"}
      />
    </>
  );
};

export default KYCDocumentsTab;
