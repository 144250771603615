import React, { useState, useEffect } from "react";
import { Typography, Divider } from "@mui/material";
import { Grid, Box } from "@mui/material";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import { useParams } from "react-router-dom";
import { getSingleAmlUpload } from "../../../redux/Phase3/KYCAML/KYCAMLSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../utils/Loader";

export default function KYCAMLSummary() {
  const [uploadData, setUploadData] = useState("");
  const [newloader, setNewLoader] = useState(true);

  const params = useParams();
  let { dealId } = params;
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleAmlUpload({ dealId: dealId })).then((res) => {
      setUploadData(res?.payload?.data);
      setNewLoader(false);
    });
  }, []);

  const summary = [
    {
      title: "Total Entries Uploaded",
      count: uploadData?.totalRecords,
    },
    {
      title: "Total Entries Approved",
      count: uploadData?.successRecords,
    },
    {
      title: "Total Entries Rejected",
      count: uploadData?.rejectedRecords,
    },
  ];

  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <>
          <div style={{ padding: "20px" }}>
            <Grid container spacing={4}>
              {summary.map((item) => {
                return (
                  <Grid item xs={4} key={item.title}>
                    <TextWrapperComponent text={item.title}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#20303F",
                          fontWeight: 400,
                        }}
                      >
                        {item.count}
                      </Typography>
                    </TextWrapperComponent>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <Divider></Divider>

          {uploadData?.successRecords > 0 && uploadData?.successFile && (
            <>
              <DocumentHeader
                title={"KYC AML Success Report"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>
              <div
                style={{
                  padding: "24px",
                }}
              >
                <ViewDownloadDeleteComp
                  showEye={false}
                  fileName={uploadData?.successFile?.filename || ""}
                  id={uploadData?.successFile?._id}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          )}

          {uploadData?.rejectedRecords > 0 && uploadData?.rejectedFile && (
            <>
              <DocumentHeader
                title={"KYC AML Rejection Report"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>
              <div
                style={{
                  padding: "24px",
                }}
              >
                <ViewDownloadDeleteComp
                  showEye={false}
                  fileName={uploadData?.rejectedFile?.filename}
                  id={uploadData?.rejectedFile?._id}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
