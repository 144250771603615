import {
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paths from "../../../components/constants/Path";
import { useSelector, useDispatch } from "react-redux";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import { updatePoolStatus } from "../../../redux/originator/poolUploadSlice";
import { useParams } from "react-router-dom";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { fetchQueryList, postQuery } from "../../../redux/Queries/queriesSlice";
import {
  getDealDocuments,
  getSingleDealDetails,
  setDealStatus,
} from "../../../redux/Deals/dealSlice";
import { postCommentV2 } from "./../../../redux/Queries/queriesSlice";
import { DocumentHeader } from "./../../../components/DocumentHeaderWrapper/DocumentHeader";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useEffect } from "react";
import AllDocuments from "../../../components/AllDocuments";
import CommentSection from "../../../components/CommentSection";
import LoanDetailsData from "../../../components/DetailsData";
import DetailsData from "../../../components/DetailsData";
import AllDocumentsV2 from "../../../components/AllDocumentsV2";
import { statusConstants } from "../../../components/constants/FiltersConstant";

const ApproverViewDealDetails = () => {
  const [state, setState] = useState("");

  const dealState = useSelector((state) => state.deal);
  const originatorState = useSelector((state) => state.originator);
  const poolState = useSelector((state) => state.poolUpload);
  const params = useParams();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [dealStatus, setDealStatus] = useState("");
  const [queryDetails, setQueryDetails] = useState({
    query: "",
    queryModule: "FINAL_DEAL_COMMENTS",
    documentId: "",
  });

  let history = useHistory();
  const singleDeal = dealState?.filterdata;
  

  // update deal handler
  const handleUpdatePoolStatus = () => {
    
    if (state === "") {
      dispatch(showErrorAlertAction("Please select status"));
      return;
    }
    

    dispatch(
      updatePoolStatus({
        status: true,
        dealId: params.dealId,
        poolStatus: state,
        uniqueCode: dealState.filterdata?.uniqueCode,
      })
    ).then((res) => {
      dispatch(showAlertAction(res?.payload?.data?.message));
      history.push(Paths.APPROVERDEALIST);
    });
  };

  // post query click handler
  const onPostClickHandler = (queryDetails) => {
    
    if (queryDetails.query == "") {
      dispatch(showErrorAlertAction("Please Enter Query"));
      return;
    }

    dispatch(postCommentV2(queryDetails)).then((data) => {
      let id = params.dealId;
      dispatch(fetchQueryList({ id }));
      dispatch(getSingleDealDetails(params.dealId));
      dispatch(showAlertAction(data.payload.data.message));
      // history.push(Paths.L2LOANDEALIST);
    });
  };

  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState(value);
  };

  //placeholder handler
  const Placeholder = ({ children }) => {
    return <div style={{ color: dealStatus == "" ? "#AFB9C3" : "#365069" }}>{children}</div>;
  };

  let userData = JSON.parse(localStorage.getItem("UserData"));
  //prefilled dealstatus
  useEffect(() => {
    singleDeal?.approvers &&
      singleDeal?.approvers.map((item) => {
        if (item.roleName == userData.roles[0]) {
          setDealStatus(item.status);
        }
      });
  });

    useEffect(() => {
      dispatch(getDealDocuments({ dealId: params.dealId }));
    }, []);

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };
  
  let commentData = {};
  let selfComments = [];
  const getPreviousApproversData = () => {
    const tempData = [];

    if (singleDeal != null) {
      const userId = userData?.id;
      const approverData = singleDeal?.approvers;
      let currentData = approverData?.filter(
        (data) => data?.approverId === userId
      );

      const singleData = currentData?.[0];
      approverData?.forEach((data) => {
        if (singleData?.approverNumber > data?.approverNumber) {
          tempData.push(data);
          commentData[data?.roleName] = singleDeal?.comments?.[data?.roleName];
        }
        if (data?.approverId === userId) {
          selfComments = singleDeal?.comments?.[singleData?.roleName];
        }
      });
    }

    return tempData;
  };

  
  return (
    <>
      <>
        <DetailsData showDealData label="Deal Details" />
        <DetailsData label="Loan Details" />
        {singleDeal?.status !== statusConstants.CLOSED ? <AllDocuments /> : <AllDocumentsV2/>}

        {getPreviousApproversData().map((data) => {
          // console.log({ ARRAY: data });
          return (
            <WrapperComponent
              title={`Approved By ${data?.name} |  ${data?.roleName}`}
              checkedIcon
            >
              <Grid container spacing={3} padding="24px">
                <>
                  <Divider />
                  {commentData?.[data?.roleName]?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          paddingBottom: "10px",
                        }}
                      >
                        Comments Section
                      </Typography>
                      <Divider />
                      <br />

                      <CommentSection comments={commentData[data?.roleName]} />
                      {/* commentData[data?.roleName] ?? */}
                    </Grid>
                  )}
                </>
              </Grid>
            </WrapperComponent>
          );
        })}

        {/* Handle deal Status :- Approved, Reject & On Hold */}
        {singleDeal?.status !== statusConstants.CLOSED && userData?.roles.length == 1 && (
          <WrapperComponent title="Deal Status">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#365069"
                  fontWeight="700"
                  paddingBottom="10px"
                >
                  Select Status
                </Typography>
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "space-between",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "368px",
                    }}
                  >
                    <Select
                      IconComponent={ExpandMoreIcon}
                      value={state}
                      onChange={handleInputChange("loanStatus")}
                      disabled={dealStatus === statusConstants.APPROVED ? true : false}
                      displayEmpty
                      size={"small"}
                      variant="standard"
                      fullWidth
                      renderValue={
                        state !== ""
                          ? undefined
                          : () => (
                              <Placeholder>
                                {dealStatus === ""
                                  ? "Select an Option"
                                  : dealStatus}
                              </Placeholder>
                            )
                      }
                    >
                      <MenuItem value={statusConstants.APPROVED}>Approved</MenuItem>
                      <MenuItem value={statusConstants.REJECTED}>Rejected</MenuItem>
                      <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                    </Select>
                  </div>
                  <OutlineButton
                    text="Update"
                    showIcon={false}
                    onClickHandler={handleUpdatePoolStatus}
                  ></OutlineButton>
                </div>
              </div>
            </>
          </WrapperComponent>
        )}

        {/* Post comments for respective deal */}
        {singleDeal?.status !== statusConstants.CLOSED && userData?.roles.length == 1 && (
          <WrapperComponent title="Comments">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#365069"
                  fontWeight="700"
                  paddingBottom="10px"
                >
                  Post a Comment
                </Typography>

                <Typography
                  fontSize="14px"
                  color="#AFB9C3"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Add your comment
                </Typography>
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "space-between",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "368px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) =>
                        setQueryDetails({
                          ...queryDetails,
                          query: e.target.value,
                          documentId: params.dealId,
                        })
                      }
                    ></TextField>
                  </div>
                  <OutlineButton
                    text="Post"
                    showIcon={false}
                    onClickHandler={() => onPostClickHandler(queryDetails)}
                  ></OutlineButton>
                </div>
              </div>
            </>
          </WrapperComponent>
        )}

        {/* hanlde Self comments section with L1 & L2 replies(if available) as per condition for all Approvers */}

        {singleDeal?.status !== statusConstants.CLOSED && (
          <WrapperComponent title="Self - Comments">
            <br />
            <CommentSection comments={selfComments} />
            {/* /selfComments */}
          </WrapperComponent>
        )}

        <div style={{ padding: "10px" }}></div>
      </>
      {/* } */}
    </>
  );
};

export default ApproverViewDealDetails;
