import React from "react";
import { Typography, Box } from "@mui/material";
import { Colors } from "../../theme/theme";

const TextWrapperComponent = ({ text, isdisable, children, }) => {
  return (
    <>
      <Typography
        fontSize="13px"
        fontWeight="700"
        color={isdisable? "#AFB9C3" : Colors.piraml_light_blue}
      >
        {text}
      </Typography>

      {children}
    </>
  );
};

export default TextWrapperComponent;
