import React, { useState } from "react";
import TabFooter from "../TabFooter/TabFooter";
import { useForm } from "react-hook-form";
import { TextField, Typography, Grid, Box, Stack } from "@mui/material";
import { Colors } from "../../../theme/theme";
import { MenuItem, Select } from "@mui/material";
import FilterIcon from "../../../assets/icons/filter_icon.svg";
import {
  basicInformationAction,
  checkDeDup,
  othersDetailsTabAction,
  pageStatusAction,
  setDealRedirection,
  setDuplicateOriginator,
  submitOriginatorDetails,
} from "../../../redux/originator/originatorSlice";
import {
  fetchOriginatorType,
  clearSucessAndErrorAction,
} from "../../../redux/originator/originatorSlice";
import { isAuthenticated } from "../../../helper/AuthHelper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";
import { SelectComponent } from "../../FormComponents/SelectComponent";
import styles from "./CreateOriginatorTabs.module.css";
import { rowGapStyle, typographyStyle } from "./CreateOriginatorTabsCSS";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import AddIcon from "@mui/icons-material/Add";
import { setGstDetails } from "./../../../redux/originator/originatorSlice";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import ModalV3 from "../../Modal/ModalV3";


const OtherDetailsTab = ({ nextTabHandler }) => {
  let originatorState = useSelector((state) => state.originator);
  const [state, setState] = useState({
    entityName: originatorState?.originatorName,
    panNumber: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [popUpOpen, setPopUpOpen] = React.useState(false);
  const [modalData, setModalData] = useState("");
  const [gstValues, setGstValues] = useState("");
  const [childMsg, setChildMsg] = useState("")

  const [allGstNumber, setAllGstNumber] = useState([
    { gstNumber: "" },
    // { gstNumber: "" },
  ]);

  const [status, setstatus] = useState(true);
  const [disableStatus,setDisableStatus] = useState(true)
  let dispatch = useDispatch();
  

  const nextBtnClickHandler = (e) => {
    const gstNumbers = allGstNumber.map((item) => item.gstNumber);
    e.preventDefault();
    dispatch(
      othersDetailsTabAction({
        gstNumbers: [...gstNumbers],
        panNumber: state.panNumber,
      })
    );
  
    let data = {
      name: originatorState["originatorName"],
      gstNumbers:[...gstNumbers],
      panNumber: state.panNumber,
      dedupeCheck: true
    }
   
    let originatorName = originatorState?.originatorsList.some(
      (originator) => originator.name === originatorState.originatorName
    );
     
    if (originatorName === true) {
      dispatch(
        showErrorAlertAction("Originator with same name already exists")
      );
    }else {
      dispatch(checkDeDup(data)).then((res) => {
        
        if ( res?.payload?.response?.data?.data?.isExist == true && res?.payload?.response?.data?.data?.type != "name") {
          let duplicateOrg = res?.payload?.response?.data?.data?.originator;
          dispatch(setDuplicateOriginator(duplicateOrg));
          setChildMsg(res?.payload?.response?.data?.message)
          setModalData(res?.payload?.response?.data?.data?.originator);
          setGstValues(
            res?.payload?.response?.data?.data?.originator?.kyc?.gstNumbers
          );
          setShowModal(true);
          setPopUpOpen(true);
          return;
        }
        if(res?.payload?.response?.data?.data?.isExist == true && res?.payload?.response?.data?.data?.type == "name"){
          dispatch(
            showErrorAlertAction("Originator with same name already exists")
          );
          return;
        }
        
          dispatch(pageStatusAction({ pageName: "otherDetails" }));
          nextTabHandler();
        
      })
    }
  };

  useEffect(() => {
    dispatch(clearSucessAndErrorAction());
  }, []);
  
  useEffect(() =>{

    if(state?.panNumber && allGstNumber[0].gstNumber){
      
      setDisableStatus(false)
    }else{
      setDisableStatus(true)
    }
  },[state, allGstNumber])
  

  useEffect(() => {
    setState({
      panNumber: originatorState.panNumber
    })
    setstatus(false)
    
    if(originatorState?.panNumber?.length === 0){
      console.log("step1");
      setstatus(true)
    }else{

      setstatus(false)
    }
    
  },[])

  useEffect(() => {
    if(originatorState?.gstNumbers?.length > 0){

      setAllGstNumber(originatorState?.gstNumbers?.map((item) => {
        return {gstNumber: item}
      }))
    }
  },[])

  useEffect(() => {
    // console.log({ updatedState: state });
    checkStatus();
  }, [state]);

   const checkStatus = () => {
    let valuesArray = Object.values(state);
    for (const key in state) {
      
      if (state[key] === 0) {
        setstatus(true);
        break;
      }
      setstatus(false);
    }
  };


  const handleInputChange = (name) => (e) => {
    let value = e.target.value;

    let dataUpdated = {
      ...state,
      [name]: value,
    };

    setState(dataUpdated);
  };

  

  const checkState = () => {
    let btnState = allGstNumber.some((item) => item.gstNumber === "");
    if (state.entityName && state.panNumber && !btnState) {
      setstatus(false);
    } else {
      setstatus(true);
    }
  };

  useEffect(() => {
    checkState();
  }, [allGstNumber, state.panNumber, state.entityName]);

  const addMore = () => {
    setAllGstNumber([...allGstNumber, { gstNumber: "" }]);
  };

  const deleteGstNumber = (index) => {
    const values = [...allGstNumber];
    values.splice(index, 1);
    setAllGstNumber(values);
  };

  const isOverGstLimit = (allGstNumber) => allGstNumber.length <= 9;
  

  const positiveOnClickHandler = () => {
    dispatch(setDealRedirection(true));
  };

  const negativeOnClickHandler = () => {
    dispatch(pageStatusAction({ pageName: "otherDetails" }));
    nextTabHandler();
  };

  const handleKeyDown = (event) => {
    const regex = /^[a-zA-Z0-9]*$/; // Regex to allow only alphanumeric characters
    const key = event.key;

    // Allow backspace and delete keys
    if (key === 'Backspace' || key === 'Delete') {
      return;
    }

    // Prevent input if the key is not alphanumeric
    if (!regex.test(key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div>
        {originatorState["loading"] ? (
          <div className={styles.loadingWrapper}>
            <Typography>Loading....</Typography>
          </div>
        ) : (
          <div>
            <form>
              <Grid container direction="row" padding="11px" sx={rowGapStyle}>
                <Grid item xs={6} padding="5px">
                  <TextWrapperComponent text="Entity Name">
                    <TextField
                      size="small"
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      autoComplete="off"
                      value={state.entityName}
                      onChange={handleInputChange("entityName")}
                      placeholder="Originator's Entity Name"
                    />
                  </TextWrapperComponent>
                </Grid>
                <Grid item xs={6} padding="5px">
                  <TextWrapperComponent text="Originator's Pan">
                    <TextField
                      size="small"
                      onInput={(e) => {
                        e.target.value = (e.target.value)
                          .toString()
                          .slice(0, 10);
                      }}
                      variant="standard"
                      onKeyDown={handleKeyDown}
                      fullWidth
                      autoComplete="off"
                      value={state.panNumber}
                      onChange={handleInputChange("panNumber")}
                      placeholder="Originator's Pan"
                    />
                  </TextWrapperComponent>
                </Grid>
              </Grid>
            </form>
            <DocumentHeader
              title={"Originator's GST Number(s)"}
              startIcon={<img src={FilterIcon} />}
              showAddButton={true}
              handleOnClick={isOverGstLimit(allGstNumber) ? addMore : () => {}}
            ></DocumentHeader>

            <Grid container direction="row" padding="11px" sx={rowGapStyle}>
              {allGstNumber &&
                allGstNumber.map((item, index) => {
                  return (
                    <Grid item xs={6} padding="5px">
                      <TextWrapperComponent
                        text={`Originator's GST Number ${index + 1}`}
                      >
                        <TextField
                          size="small"
                          variant="standard"
                          fullWidth
                          onInput={(e) => {
                            e.target.value = (e.target.value)
                              .toString()
                              .slice(0, 15);
                          }}
                          autoComplete="off"
                          onKeyDown={handleKeyDown}
                          value={item.gstNumber}
                          onChange={(e) => {
                            const values = [...allGstNumber];
                            values[index].gstNumber = e.target.value;
                            setAllGstNumber(values);
                          }}
                          placeholder={`Originator's GST Number ${index + 1}`}
                        />
                      </TextWrapperComponent>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        )}
      </div>

      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={disableStatus} />

      {showModal && showModal ? (
        <>
          <ModalV3
            popUpOpen={popUpOpen}
            setPopUpOpen={setPopUpOpen}
            title="Potential Duplicate Profile Detected"
            isFile={false}
            children={childMsg}
            midHead1Text={"Entity Name:"}
            midHead2Text={"GST Number:"}
            midHead3Text={"PAN Number:"}
            midHead1={modalData?.name}
            midHead2={gstValues.join(" , ")}
            midHead3={modalData?.kyc?.panNumber}
            btnText="View Originator"
            negativeText="Continue"
            positiveOnClickHandler={positiveOnClickHandler}
            negativeOnClickHandler={negativeOnClickHandler}
          ></ModalV3>
        </>
      ) : (
        <></>
      )}


    </>
  );
};

export default OtherDetailsTab;
