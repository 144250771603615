import React from "react";
import Layout from "../../../components/Layout/Layout";
import {
  Box,
  Stack,
  Typography,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DealTable from "../../../components/Table/SortedDealTableV2";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SecoundaryButton from "../../../components/Buttons/SecoundaryButton";
import { getAllDeals } from "../../../redux/Deals/dealSlice.js";
//? react-router-dom
import { useHistory } from "react-router-dom";
import Paths from "./../../../components/constants/Path";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import useDebounce from "../../../Hooks/useDebounce";
import { showErrorAlertAction } from "../../../redux/app/appSlice";

const ListOfDeal = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setFilter] = useState(0);
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const debouncedSearch = useDebounce(search, 1000);
  console.log({ debouncedSearch });

  console.log({ dealState });

  const filterHandler = (name) => {
    if(name == "PEL" || name == "PCHFL"){
      setEntityFilter(name);
      setFilterName("")
    }else{
      setFilterName(name);
      setEntityFilter("")
    }
  };

  // needs to be optimized;  

  

  useEffect(() => {
    dispatch(getAllDeals({ limit, currentPage, debouncedSearch, entityFilter,  filterName, source: "PHFL" }));
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(
      getAllDeals({ limit, currentPage: 1, debouncedSearch, filterName, entityFilter, source: "PHFL" })
    );
  }, [filterName, entityFilter]);

  const filterList = [
    {
      id: "0",
      name: "All",
    },
    {
      id: "1",
      name: "Approved",
    },

    {
      id: "2",
      name: "On Hold",
    },
    {
      id: "3",
      name: "Pending",
    },
    {
      id: "4",
      name: "Rejected",
    },
    {
      id: "5",
      name: "PEL",
    },
    {
      id: "6",
      name: "PCHFL",
    }
  ];
  let history = useHistory();

  const clickHandlerToViewDetails = (deal) => {

    // return history.push(`${deal._id || deal}/l2viewdetails`);
    return history.push({
      pathname: `/l2deallist/${deal._id || deal.id || deal}/l2viewdetails`,
      state: deal,
    });
    // return history.push(Paths.L2VIEWDETAILS);
  };

  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage == dealState?.totalPages) {
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    console.log("prevPageHandler called" + currentPage);
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  // Previous Page Handler Function for Pagination ends here

  const breadCrumbs = [
    {
      path: "/dashboard",
      pathName: "Home",
    },
    {
      pathName: "Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box
        backgroundColor="white"
        minHeight="144px"
        maxHeight="auto"
        minWidth="auto"
        maxWidth="auto"
        boxShadow={4}
        borderRadius={2}
        sx={{ p: 2, m: 2 }}
        style={{ minHeight: "calc( 100% - 64px)" }}
      >
        <>
          <Stack
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            paddingBottom="12px"
            display="flex"
          >
            <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
              List of Deals
            </Typography>
          </Stack>

          <Divider></Divider>
          <div
            style={{
              padding: "16px",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
            }}
          >
            <div>
              {filterList.map((item, index) => {
                console.log(item, index);
                return (
                  <>
                    <StyledButton
                      id={item.id}
                      variant={
                        filterName === item.name || entityFilter == item?.name ? "contained" : "outlined"
                      }
                      sx={{
                        color: filterName === item.name || entityFilter == item?.name ? "white" : "black",
                        backgroundColor:
                          filterName === item.name || entityFilter == item?.name ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item.name)}
                    >
                      {item.name}
                    </StyledButton>
                  </>
                );
              })}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                    {/* <SearchIcon
                      sx={{ color: "#000000", fontSize: "25px", fontWeight:"0" }}
                    /> */}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
            </div>
          ) : (
            <Box>
              <DealTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                header={[
                  "Deal Name",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Start Date",
                  "Deal Created By",
                  "Deal Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
              ></DealTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default ListOfDeal;
