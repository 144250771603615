import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import styles from "./UploadReports.module.css";
import { deleteDoc } from "../../../redux/originator/originatorSlice";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ChooseFile } from "./ChooseFile";
import { SelectedFile } from "./SelectedFile";
import { uploadReport } from "../../../redux/Phase2/UploadReportSlice";
import { showErrorAlertAction } from "../../../redux/app/appSlice";

const UploadReports = ({
  // setdata,
  docName,
  // isDeleteIcon = true,
  // setChangeStatus,
  setdata,
  data,
}) => {
  const [state, setState] = useState(null);

  let dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = React.useState("");
  let uploadReportState = useSelector((state) => state.uploadReport);

  const current = new Date();
  current.setMonth(current.getMonth() - 2);
  const previousMonth = current.toLocaleString("default", { month: "long" });

  const onDrop = useCallback((acceptedFiles) => {
    console.log({ acceptedFiles });
    if (acceptedFiles[0] === undefined) {
      dispatch(showErrorAlertAction("File Format is not correct"));
    } else {
      setState(acceptedFiles[0]);
      setdata(acceptedFiles);
    }
  }, []);

  // const removeFile = () => {
  //   setState(null);
  //   setChangeStatus(true);
  //   dispatch(deleteDoc(docName));
  // };

  const removeFile = () => {
    setdata(null);
    setState(null);
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      csv: [".csv"],
      xlsx: [".xlsx"],
    },
  });

  let { error, success } = useSelector((state) => state.uploadReport);
  console.log({ error });
  //? useEffect
  useEffect(() => {
    if (uploadReportState[docName]) {
      setState(uploadReportState[docName]);
      setdata(false);
    }
  }, [uploadReportState[docName]]);

  console.log(state);

  const Test = () => {
    return (
      <>
        <input {...getInputProps()} />
        {state.name == null ? (
          <div className={styles.wrapper}>
            <div className={styles.DetailsWrapper}>
              <div className={styles.together}>
                <p>Loading...</p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <SelectedFile
              fileName="UploadedFile"
              onClickHandler={removeFile}
              isDeleteIcon={true}
            />

          </>
        )}
      </>
    );
  };

  const FileComponent =
    data && data[0] != null ? <Test /> : console.log("state is null");

  console.log({ state });

  return (

    <div>
      {data && data[0] != null ? (
        FileComponent
      ) : (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <ChooseFile />
        </div>
      )}
    </div>
  );
};

export default UploadReports;
