/* eslint-disable react-hooks/exhaustive-deps */
import DoneIcon from "@mui/icons-material/Done";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../../API/apiHandler";
import {
  getSinglePoolUpload,
  pageStatusAction,
} from "../../../redux/L1/secondaryCheckSlice";
import Loader from "../../../utils/Loader";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";
import TabFooter from "../TabFooter/TabFooter";
import { statusConstants } from "../../constants/FiltersConstant";

const DownloadPool = ({ nextTabHandler, setValue }) => {
  let dispatch = useDispatch();
  const [downloadPoolData, setDownloadPoolData] = useState("");
  const dealState = useSelector((state) => state.deal);
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const nextBtnClickHandler = async (e) => {
    e.preventDefault();
    await postRequest("/utility-service/notification", {
      type: "FRONT-END NOTIFICATION",

      dealId: dealState?.filterdata?._id,
      event: "SECONDARY CHECK START",
    });
    dispatch(pageStatusAction({ pageName: "downloadPool" }));
    nextTabHandler();
  };
  //
  useEffect(() => {
    dispatch(
      getSinglePoolUpload({ uniqueCode: dealState?.filterdata?.uniqueCode })
    ).then((res) => {
      setDownloadPoolData(res.payload.data);
    });
  }, []);

  return (
    <>
      {dealState && dealState.loading ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Typography
              fontSize="14px"
              color="#808080"
              fontWeight="400"
              paddingBottom="10px"
            >
              Uploaded Pool
            </Typography>

            <ViewDownloadDeleteComp
              fileName={downloadPoolData?.primarySuccessFileData?.filename}
              id={downloadPoolData?.primarySuccessFileData?._id}
            ></ViewDownloadDeleteComp>
          </div>
          {userData?.roles.length != 1 ? (
            <>
            </>
          ) : (
            <>
              <TabFooter
                isBtnDisable={
                  dealState?.filterdata?.poolUploadFinal?.poolStatus ===
                    statusConstants.APPROVED
                    ? true
                    : false
                }
                onclickHandler={nextBtnClickHandler}
                label={"Validate Pools"}
                endIcon={<DoneIcon />}
              ></TabFooter>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DownloadPool;
