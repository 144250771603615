import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllStates,
  getAllCities,
  pageStatusAction,
  setGeographical,
} from "../../../../redux/Loans/loanSlice";
import useDebounce from "../../../../Hooks/useDebounce";
import {
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RxCross2 } from "react-icons/rx";
import TextWrapperComponent from "../../../FormComponents/TextWrapperComponent";
import TabFooter from "../../TabFooter/TabFooter";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
      background: "#EBEEF0",
    },
  },
};

const GeographicalFilters = ({ nextTabHandler }) => {
  const loanState = useSelector((state) => state.loan);
  const [searchIds, setSearchIds] = useState("");
  const [citiesName, setCitiesName] = useState(
    [...loanState?.excludedCities] || []
  );
  const [statesName, setStatesName] = useState(
    [...loanState?.excludedStates] || []
  );
  const [status, setStatus] = useState(true);
  const [filter, setFilter] = useState("");
  const debounceSearch = useDebounce(filter, 700);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus(!(citiesName.length > 0 || statesName.length > 0));
  }, [citiesName, statesName]);

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(pageStatusAction({ pageName: "geographicalFilters" }));
    dispatch(
      setGeographical({
        excludedCities: citiesName,
        excludedStates: statesName,
      })
    );
    nextTabHandler();
  };

  const handleCitiesChange = (event, setStateName) => {
    const {
      target: { value },
    } = event;
    let duplicateRemoved = value.reduce((acc, item) => {
      if (acc.findIndex((o) => o.id === item.id) === -1) {
        acc.push(item);
      }
      return acc;
    }, []);
    setStateName(duplicateRemoved);
  };

  const handleStateChange = (event, setStateName) => {
    const {
      target: { value },
    } = event;
    let duplicateRemoved = value.reduce((acc, item) => {
      if (acc.findIndex((o) => o.id === item.id) === -1) {
        acc.push(item);
      }
      return acc;
    }, []);
    setStateName(duplicateRemoved);
  };

  useEffect(() => {
    dispatch(getAllStates());
  }, []);

  useEffect(() => {
    if (statesName.length < 1) {
      return;
    }
    let allIds = statesName.map((item) => item.id);
    let ids = allIds.join(",");
    ids = ids[0] === "," ? ids.replace(",", "") : ids;
    setSearchIds(ids);
  }, [statesName]);

  const handleClearState = () => {
    console.log("state clear");
    setCitiesName([]);
  };

  useEffect(() => {
    dispatch(getAllCities({ searchIds, filter }));
  }, [debounceSearch]);

  return (
    <>
      <div>
        <div style={{ padding: "20px" }}>
          <form>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <TextWrapperComponent text="State Exclusions" />
                <FormControl sx={{ width: 300 }}>
                  <Select
                    sx={{ width: "752px" }}
                    multiple
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    value={statesName}
                    onChange={(e) => handleStateChange(e, setStatesName)}
                    variant="standard"
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <p
                            style={{
                              color: "#AFB9C3",
                              fontWeight: "400",
                              fontSize: "16px",
                            }}
                          >
                            Select States
                          </p>
                        );
                      }
                      return selected.map((x) => x.name).join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {loanState?.allStates &&
                      loanState?.allStates.map((variant) => (
                        <MenuItem
                          key={variant.id}
                          value={variant}
                          sx={{ height: "40px" }}
                        >
                          <Checkbox
                            size="small"
                            checked={
                              statesName.findIndex(
                                (item) => item.id === variant.id
                              ) >= 0
                            }
                          />
                          <ListItemText primary={variant.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {loanState && loanState?.allCities && (
                  <>
                    <TextWrapperComponent text="City Exclusions">
                      <FormControl sx={{ width: 300 }}>
                        <Select
                          sx={{ width: "752px" }}
                          multiple
                          displayEmpty
                          IconComponent={() => (
                            <RxCross2
                              onClick={handleClearState}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                          value={citiesName}
                          onChange={(e) => handleCitiesChange(e, setCitiesName)}
                          variant="standard"
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <p
                                  style={{
                                    color: "#AFB9C3",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                >
                                  Select Cities
                                </p>
                              );
                            }
                            return selected.map((x) => x.name).join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <ListSubheader
                            sx={{
                              height: "40px",
                              background: "#EBEEF0",
                              margin: "10px 0px",
                            }}
                          >
                            <TextField
                              size="medium"
                              placeholder="Search Cities"
                              fullWidth
                              value={filter}
                              sx={{
                                input: {
                                  fontFamily: "Nunito",
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  height: "7px",
                                  width: "380px",
                                },
                              }}
                              onChange={(e) => {
                                setFilter(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                            />
                          </ListSubheader>
                          {loanState?.allCities &&
                            loanState?.allCities.map((variant) => (
                              <MenuItem
                                key={variant.id}
                                value={variant}
                                sx={{ height: "40px" }}
                              >
                                <Checkbox
                                  size="small"
                                  checked={
                                    citiesName.findIndex(
                                      (item) => item.id === variant.id
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={variant.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TextWrapperComponent>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      </div>

      {/* <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={status}  /> */}
      <TabFooter onclickHandler={nextBtnClickHandler} isBtnDisable={false}  />
    </>
  );
};

export default GeographicalFilters;
