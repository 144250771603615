import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDebounce from "../../../Hooks/useDebounce";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import Layout from "../../../components/Layout/Layout";
import DealTable from "../../../components/Table/SortedDealTableV2";
import Paths from "../../../components/constants/Path.jsx";
import { getAllDeals } from "../../../redux/Deals/dealSlice.js";
import { showErrorAlertAction } from "../../../redux/app/appSlice";
import Styles from "./F1.module.css";
import { listOfDealsStack, listOfDealsTitle, main } from "./F1CSS.js";
import { FilterConstants } from "../../../components/constants/FiltersConstant.js";
const F1ListOfDeal = () => {
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState("All");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [entityFilter, setEntityFilter] = useState("")

  const dealState = useSelector((state) => state.deal);

  const debouncedSearch = useDebounce(search, 1000);

  const filterHandler = (name) => {
    if (name == "PEL" || name == "PCHFL") {
      setEntityFilter(name);
      setFilterName("")
    } else {
      setFilterName(name);
      setEntityFilter("")
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName, entityFilter]);

  useEffect(() => {
    dispatch(getAllDeals());
  }, []);

  useEffect(() => {
    dispatch(
      getAllDeals({
        limit,
        currentPage,
        debouncedSearch,
        filterName,
        entityFilter,
        dealType: "BUY",
      })
    );
  }, [currentPage, filterName, entityFilter, debouncedSearch]);

  const filterList = [
    FilterConstants.ALL,
    FilterConstants.PEL,
    FilterConstants.PCHFL
  ];

  let history = useHistory();

  function clickHandlerToViewDetails(deal) {
    return history.push({
      pathname: `/f1/deal/${deal._id || deal}/details`,
      state: deal,
    });
  }

  // Next Page Handler Function for Pagination starts here
  const nextPageHandler = () => {
    if (currentPage == dealState.totalPages) {
      // alert("Max Number Reached!");
      dispatch(showErrorAlertAction("Max Number Reached!"));
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Next Page Handler Function for Pagination ends here

  // Previous Page Handler Function for Pagination starts here
  const prevPageHandler = () => {
    if (currentPage == 1) {
      dispatch(showErrorAlertAction("Min Number Reached!"));

      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  // Previous Page Handler Function for Pagination ends here

  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },
    {
      pathName: "Deals",
    },
  ];

  const StyledButton = styled(Button)(() => ({
    borderRadius: "24px",
    textTransform: "none",
  }));

  return (
    <Layout componentName={null} breadCrumbs={breadCrumbs}>
      <Box sx={main}>
        <>
          <Stack sx={listOfDealsStack}>
            <Typography sx={listOfDealsTitle}>List of Deals</Typography>

          </Stack>

          <Divider></Divider>

          <div className={Styles.filterListWrapper}>
            <div>
              {filterList &&
                filterList.map((item, index) => (
                  <>
                    <StyledButton
                      key={index}
                      variant={filterName === item || entityFilter == item ? "contained" : "outlined"}
                      sx={{
                        color: filterName === item || entityFilter == item ? "white" : "black",
                        backgroundColor:
                          filterName === item || entityFilter == item ? "#365069" : "white",
                        borderColor: "black",
                        mr: 1,
                      }}
                      onClick={() => filterHandler(item)}
                    >
                      {item}
                    </StyledButton>
                  </>
                ))}
            </div>
            <TextField
              placeholder="Search"
              autoComplete="off"
              value={search}
              variant="standard"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          {dealState["loading"] ? (
            <div className={Styles.f1ListOfDealImg}>
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />
            </div>
          ) : (
            <Box>
              <DealTable
                data={dealState?.allDeals}
                nextPageHandler={nextPageHandler}
                prevPageHandler={prevPageHandler}
                totalRecords={dealState?.totalPages}
                page={currentPage}
                filterName={filterName}
                setSortOrder={setSortOrder}
                header={[
                  "Deal Name",
                  "Deal Creation Date",
                  "Asset Class",
                  "Requested Pool Size",
                  "Entity Type",
                  "Deal Status",
                  "Action",
                ]}
                viewActionClickHandler={clickHandlerToViewDetails}
              ></DealTable>
            </Box>
          )}
        </>
      </Box>
    </Layout>
  );
};

export default F1ListOfDeal;
