import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import OriginatorGstMultipleUpload from "../../../components/Wrapper/OriginatorGstMultipleUpload";
import OriginatorGSTCertificate from "../../../components/Wrapper/OriginatorGSTCertificate";
import UploadTemplateKyc from "../../../components/Wrapper/OriginatorUploadKyc";

import Paths from "../../../components/constants/Path";
import {
  deleteDoc,
  getUserProfileByUserId,
  kycUpoloadedAction,
  setLeiNumberAction,
  uploadQuatarlyDoc,
} from "../../../redux/originator/originatorSlice";
import { Colors } from "../../../theme/theme";
import Loader from "../../../utils/Loader";
import styles from "./Originator.module.css";
import {
  ProfileDetailsTabDocumentHeader,
  btnStyle,
  descriptionIconStyle,
  documentHeaderStack,
  documnetHeaderTypo,
  profileDetailsRadioGroup,
  typo,
  typoHeader,
} from "./OriginatorCSS";
import { AddMoreFileComponent } from "../../../components/Wrapper/UploadComponents";
const ProfileDetailsTab = () => {
  const [state, setState] = React.useState(true);
  const [status, setStatus] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [kycData, setKYCData] = useState("");
  const [btnDisplay, setBtnDisplay] = useState(true);
  const [newLoader, setNewLoader] = useState(true);
  const [allGstFiles, setAllFiles] = React.useState([{}]);
  const [ids, setIds] = React.useState([]);
  const params = useParams();

  console.log({ ids }, { allGstFiles });

  let completeKYCState = useSelector((state) => state.originator);
  let { leiNumber } = useSelector((state) => state.originator);
  const [selectedValue, setSelectedValue] = React.useState("LEICard");
  let userData = JSON.parse(localStorage.getItem("UserData"));

  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    let value = e.target.value;
    dispatch(setLeiNumberAction(value));
  };

  const addMoreGSTFiles = () => {
    if (allGstFiles.length > 9) return;
    setAllFiles((data) => [...data, {}]);
  };

  useEffect(() => {
    if (
      completeKYCState?.leiNumber &&
      completeKYCState?.leiNumber?.length > 1
    ) {
      dispatch(deleteDoc("leiCard"));
      setSelectedValue("LEINumber");
      return;
    }
  }, [completeKYCState?.leiNumber]);

  let history = useHistory();

  const clickHandler = () => {
    dispatch(kycUpoloadedAction(true));
    dispatch(
      uploadQuatarlyDoc({
        id: completeKYCState?.originatorProfileId,
        kyc: {
          panCard: completeKYCState?.panCard?.fileId,
          leiCard: completeKYCState.leiCard?.fileId,
          incorporationCertification:
            completeKYCState.incorporationCertificate?.fileId,
          gstCertificate:
            (ids.length > 0 && ids.map((item) => item.fileId)) || [],
          cancelledCheque: completeKYCState.cancelledCheque?.fileId,
          rbiCerticationRegistration:
            completeKYCState.rbiRegisterationCertificate?.fileId,
          leiNumber: leiNumber,
        },
      })
    );
    dispatch(deleteDoc("panCard"));
    dispatch(deleteDoc("gstCertificate"));
    dispatch(deleteDoc("incorporationCertificate"));
    dispatch(deleteDoc("cancelledCheque"));
    dispatch(deleteDoc("rbiRegisterationCertificate"));
    dispatch(deleteDoc("leiCard"));
    return history.push(Paths.ORIGINATORPROFILELIST);
  };
  function handleRadioBtnChange(event) {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  }

  useEffect(() => {

    if (
      completeKYCState?.panCard &&
      completeKYCState?.gstCertificate &&
      // ids.length > 0 &&
      // completeKYCState?.cancelledCheque &&
      completeKYCState?.incorporationCertificate &&
      completeKYCState?.rbiRegisterationCertificate
    ) {
      setStatus(false)
      if (completeKYCState?.leiCard || completeKYCState?.leiNumber) {
        if (
          completeKYCState?.leiCard ||
          completeKYCState?.leiNumber?.length == 20
        ) {
          setStatus(false);
        } else {
          setStatus(true);
        }
      
      } else {
        setStatus(true);
      }
    } else {
      setStatus(true);
    }
  }, [completeKYCState, ids]);

  // useEffect(() => {
  //   if (completeKYCState?.leiCard === null) {
  //     setStatus(true);
  //     return;
  //   }
  // }, [completeKYCState.leiCard]);

  useEffect(() => {
    dispatch(
      getUserProfileByUserId({
        userId: userData?._id || userData?.id,
        id: params.dealId,
      })
    ).then((res) => {
      setKYCData(res?.payload?.data?.data[0]?.kycData);
      setNewLoader(false);
    });
  }, []);

  useEffect(() => {
    if (
      kycData?.panCard &&
      kycData?.gstCertificate &&
      kycData?.incorporationCertification &&
      kycData?.cancelledCheque &&
      kycData?.rbiCerticationRegistration &&
      (kycData?.leiCard || kycData?.leiNumber)
    ) {
      setBtnDisplay(false);
    }
  });


  useEffect(() => {
    if (
      selectedValue === "LEICard" &&
      completeKYCState?.leiNumber?.length < 1
    ) {
      dispatch(setLeiNumberAction(""));
      return;
    } else {
      // dispatch(deleteDoc("leiCard"));
    }
  }, [selectedValue]);

  
  const DocumentHeader = ({ title, showAddMoreBtn = false, handleOnClick }) => {
    return (
      <div className={styles.documentHeaderProfileDetailsTab}>
        <Stack sx={documentHeaderStack} spacing={1}>
          <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
          <Typography sx={documnetHeaderTypo}>{title}</Typography>
        </Stack>
        {showAddMoreBtn && (
          <Stack>
            <AddMoreFileComponent
              clickhandler={handleOnClick}
            ></AddMoreFileComponent>
          </Stack>
        )}
      </div>
    );
  };

  const LEICard = () => {
    return (
      <div className={styles.documentHeaderProfileDetailsTab}>
        <Stack sx={documentHeaderStack}>
          <div className={styles.ProfileDetailsTabDiv}>
            <DescriptionIcon sx={descriptionIconStyle}></DescriptionIcon>
          </div>

          <div className={styles.ProfileDetailsTabFormControl}>
            <FormControl component="fieldset" required error>
              <RadioGroup
                value={selectedValue}
                onChange={handleRadioBtnChange}
                row
                sx={profileDetailsRadioGroup}
              >
                <FormControlLabel
                  value="LEICard"
                  control={<Radio size="small" />}
                  label="LEI Card"
                  disabled={kycData?.leiNumber ? true : false}
                />
                <FormControlLabel
                  value="LEINumber"
                  control={<Radio size="small" />}
                  label="LEI Number"
                  disabled={kycData?.leiCard ? true : false}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Stack>
      </div>
    );
  };
  return (
    <>
      {newLoader ? (
        <Loader />
      ) : (
        <>
          <div className={styles.ProfileDetailsTabMainDiv}>
            <div className={styles.ProfileDetailsTabUploadsDocuments}>
              <h3>
                Upload KYC Documents for originator{" "}
                {completeKYCState?.originatorProfileName}
              </h3>
            </div>
            <Box sx={ProfileDetailsTabDocumentHeader}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <DocumentHeader title="PAN Card"></DocumentHeader>

                  <div className={styles.paddingStyle}>
                    <UploadTemplateKyc
                      setLoading={setLoading}
                      docName="panCard"
                      setdata={setState}
                    />
                  </div>
                </Grid>
                {completeKYCState?.gstCertificate &&
                completeKYCState?.gstCertificate?.length > 0
                  ? completeKYCState.gstCertificate?.map((item, index) => {
                      return (
                        <Grid item xs={6}>
                          <DocumentHeader
                            title={`GST Certificate ${index + 1}`}
                          />
                          <div className={styles.paddingStyle}>
                            <OriginatorGSTCertificate
                              setLoading={setLoading}
                              setdata={setState}
                              index={index}
                              GSTFile={item}
                              docName="gstCertificate"
                            />
                          </div>
                        </Grid>
                      );
                    })
                  : allGstFiles.map((item, index) => {
                      return (
                        <Grid item xs={6}>
                          <DocumentHeader
                            showAddMoreBtn={index == 0 && true}
                            title={`GST Certificate ${index + 1}`}
                            handleOnClick={addMoreGSTFiles}
                          />
                          <div className={styles.paddingStyle}>
                            <OriginatorGstMultipleUpload
                              setLoading={setLoading}
                              setdata={setState}
                              index={index}
                              ids={ids}
                              setIds={setIds}
                              docName="gstCertificate"
                            />
                          </div>
                        </Grid>
                      );
                    })}

                <Grid item xs={6}>
                  <DocumentHeader title="Incorporation Certificate" />
                  <div className={styles.paddingStyle}>
                    <UploadTemplateKyc
                      setLoading={setLoading}
                      setdata={setState}
                      docName="incorporationCertificate"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <DocumentHeader title="Cancelled Cheque" />
                  <div className={styles.paddingStyle}>
                    <UploadTemplateKyc
                      setLoading={setLoading}
                      setdata={setState}
                      docName="cancelledCheque"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <DocumentHeader title="RBI Registration Certificate"></DocumentHeader>
                  <div className={styles.paddingStyle}>
                    <UploadTemplateKyc
                      setLoading={setLoading}
                      setdata={setState}
                      docName="rbiRegisterationCertificate"
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <LEICard></LEICard>
                  <div className={styles.paddingStyle}>
                    {selectedValue === "LEICard" ? (
                      <UploadTemplateKyc
                        setLoading={setLoading}
                        setdata={setState}
                        docName="leiCard"
                      />
                    ) : (
                      <TextField
                        size="small"
                        variant="standard"
                        fullWidth
                        value={leiNumber}
                        disabled={kycData?.leiNumber ? true : false}
                        type={"text"}
                        onInput={(e) => {
                          let value =
                            e.target.value.replace(/[^0-9a-zA-Z]/g, "") || "";
                          e.target.value = value.slice(0, 20);
                        }}
                        onChange={handleInputChange}
                        placeholder="Enter LEI Number"
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              {btnDisplay ? (
                <div className={styles.ProfileDetailsTabSubmitDocuments}>
                  <Button
                    endIcon={<DoneIcon />}
                    onClick={clickHandler}
                    disabled={status}
                    variant="contained"
                    sx={btnStyle}
                    type="submit"
                  >
                    Submit Documents and Work on Deals
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Box>
          </div>
        </>
      )}

      <div className={styles.paddingStyle}></div>
    </>
  );
};

export default ProfileDetailsTab;
