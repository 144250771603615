import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Image from "../../../assets/images/Frame.svg";
import Histories from "../../../components/History/Histories";
import TabLayout from "../../../components/Layout/TabsLayout/Layout";
import Layout from "../../../components/Layout/TabsLayout/Phase2Layout/Layout";
import Tabs from "../../../components/Phase2/Tabs/Tabs";
import QueriesPage from "../../../components/Queries/Queries";
import Paths from "../../../components/constants/Path";
import RedirectToLoginPage from "../../redirect";
import DealDeatils from "./DealDetailsTab";
import MonthlyCollectionTab from "./MonthlyCollectionReport";
import TemplateDownload from "./TemplateDownload";

const UploadNewReport = () => {
  const current = new Date();
  let params = useParams();
  current.setMonth(current.getMonth() - 2);

  //? useSelector
  let uploadReportState = useSelector((state) => state.uploadReport);
  let dealState = useSelector((state) => state.deal);

  let collectionHeader = `Monthly Collection Report ${uploadReportState.collectionMonth}`;

  const tabs = [
    {
      tabName: "Deal Details",
      comp: DealDeatils,
      heading: "Deal Details",
    },
    {
      tabName: "Template",
      comp: TemplateDownload,
      heading: "Monthly Upload Template",
    },
    {
      tabName: "Monthly Collection Report",
      comp: MonthlyCollectionTab,
      heading: `Monthly Collection Report (${uploadReportState.collectionMonth})`,
    },
  ];

  const breadCrumbs = [
    {
      path: Paths.PHASE1HOME,
      pathName: "Home",
    },

    {
      pathName: "Upload New Report",
    },
  ];

  let TabsList = [
    {
      name: "Upload Monthly Collection Report",
      component: (
        <Tabs
          tabs={tabs}
          status={uploadReportState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
      isDisabled: false,
    },
    {
      name: "Queries",
      component: (
        <QueriesPage
          dealIdorOriginatorId={params.dealId}
          currentStage={dealState?.filterdata?.currentStage || ""}
        />
      ),
      index: 1,
      isDisabled: false,
    },
    {
      name: "History",
      component: <Histories></Histories>,
      index: 1,
      isDisabled: false,
    },
  ];

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      <RedirectToLoginPage />
      <Layout breadCrumbs={breadCrumbs}>
        <TabLayout
          TabsList={TabsList}
          formName={"Deal Collection"}
          icon={icon}
          iconColor={"#DBE3F8"}
          image={Image}
        />
      </Layout>
    </>
  );
};

export default UploadNewReport;
