import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Layout from "../../../components/Layout/Layout";
import { Colors } from "../../../theme/theme";
import Image from "../../../assets/images/Group 42722.svg";
import { FormDetails } from "../../../components/Layout/TabsLayout/TabsComponents";
import DescriptionIcon from "@mui/icons-material/Description";
import TabContentWrapper from "../../../components/Tabs/TabContentWrapper/TabContentWrapper";
import TextWrapperComponent from "../../../components/FormComponents/TextWrapperComponent";
import TabFooter from "../../../components/Tabs/TabFooter/TabFooter";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import DoneIcon from "@mui/icons-material/Done";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { submitKYCLink } from "../../../redux/L1/secondaryCheckSlice";
import { PanoramaSharp } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import { DocumentHeader } from "../../../components/DocumentHeaderWrapper/DocumentHeader";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  fetchComment,
  postCommentReply,
} from "../../../redux/Queries/queriesSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import Paths from "../../../components/constants/Path";
import Loader from "./../../../utils/Loader";

const OriginatorKYCLinkUpdate = () => {
  const dealState = useSelector((state) => state.deal);
  let loading = useSelector((state) => state.deal.loading);
  const params = useParams();
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [commentReply, setCommentReply] = useState("");
  const [value, setValue] = useState();

  let userData = JSON.parse(localStorage.getItem("UserData"));

  useEffect(() => {
    dispatch(getSingleDealDetails(params.dealId));
  }, []);

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      path: Paths.ORIGINATORKYCLINKDEALLIST,
      pathName: "Deals List",
    },
    {
      pathName: "View Deal Details",
    },
  ];

  let history = useHistory();

  const onClickHandler = () => {
    dispatch(
      submitKYCLink({
        dealId: params.dealId,
        link: dealState?.filterdata?.kycDocumentLink
          ? dealState?.filterdata?.kycDocumentLink
          : value,
      })
    ).then((response) => {
      if (response.payload.status === 200) {
        console.log({ response: response });
        dispatch(showAlertAction("Drive Link Updated Successfully!"));
        history.push(Paths.ORIGINATORKYCLINKDEALLIST);
      }
    });
  };

  const onReplyClickHandler = (id) => {
    if (commentReply == "") {
      dispatch(showErrorAlertAction("Please add a reply"));
      return;
    }
    dispatch(postCommentReply({ query: commentReply, commentId: id })).then(
      (data) => {
        if (data.payload.status == 200) {
          dispatch(showAlertAction("Reply Send Successfully"));
        }
        dispatch(showErrorAlertAction(data.payload.message));
      }
    );
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  console.log({ value });

  useEffect(() => {
    dispatch(fetchComment({ dealId: params.dealId })).then((data) => {
      setComments(data.payload.data);
    });
  }, []);

  return (
    <>
      <Layout breadCrumbs={breadCrumbs}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                paddingLeft: "14px",
                backgroundColor: Colors.light_green,
                display: "flex",
                justifyContent: "space-between",
                height: "128px",
              }}
            >
              <div>
                <FormDetails
                  icon={icon}
                  iconColor={"#DBE3F8"}
                  formName={dealState.name}
                />
                <Box paddingTop="16px"></Box>
              </div>
              <div
                style={{
                  width: "20%",
                  height: "100%",
                  padding: "20px 10px 0px",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={Image}
                  alt="image"
                />
              </div>
            </Box>
            <WrapperComponent
              title={`Update KYC Documents Link for - ${dealState.name}`}
            >
              <div
                style={{
                  padding: "10px",
                  paddingTop: "30px",
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextWrapperComponent text="Drive Link for KYC Documents">
                      <TextField
                        value={
                          dealState?.filterdata?.kycDocumentLink
                            ? dealState?.filterdata?.kycDocumentLink
                            : value
                        }
                        onChange={handleChange}
                        size="small"
                        variant="standard"
                        placeholder="Paste Drive Link here"
                        sx={{ width: "631px" }}
                      />
                    </TextWrapperComponent>
                  </Grid>
                </Grid>
              </div>
              {dealState?.filterdata?.poolUploadFinal?.cibilScoreSuccessFile
                ?.filename !== undefined ? (
                <>
                  <DocumentHeader
                    title={"Approved Pool"}
                    startIcon={
                      <DescriptionIcon
                        sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                      />
                    }
                  ></DocumentHeader>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <div
                        style={{
                          padding: "24px",
                        }}
                      >
                        <ViewDownloadDeleteComp
                          showEye={false}
                          fileName={
                            dealState?.filterdata?.poolUploadFinal
                              ?.cibilScoreSuccessFile?.filename || ""
                          }
                          id={
                            dealState?.filterdata?.poolUploadFinal
                              ?.cibilScoreSuccessFile?._id || ""
                          }
                        ></ViewDownloadDeleteComp>
                      </div>
                    </Grid>
                  </Grid>
                </>
              ) : null}

              {comments.map((cmnt, index) => {
                return (
                  <>
                    <div style={{ marginTop: "17px" }}>
                      <DocumentHeader
                        title={"Auditor Comments"}
                        startIcon={
                          <PersonOutlineIcon
                            sx={{
                              color: "#5E7387",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        }
                      ></DocumentHeader>
                    </div>

                    <div
                      style={{
                        padding: "24px",
                      }}
                    >
                      <Typography
                        fontSize="16px"
                        color="#20303F"
                        fontWeight="400"
                        paddingBottom="10px"
                      >
                        {cmnt.query}
                      </Typography>

                      {cmnt?.replies &&
                        cmnt.replies.length > 0 &&
                        cmnt.replies.map((reply, index) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: "17px",
                                  marginLeft: "-25px",
                                }}
                              >
                                <DocumentHeader
                                  title={"Originator Reply"}
                                  startIcon={
                                    <PersonOutlineIcon
                                      sx={{
                                        color: "#5E7387",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                ></DocumentHeader>
                                <div
                                  style={{
                                    padding: "24px",
                                  }}
                                >
                                  <Typography
                                    fontSize="16px"
                                    color="#20303F"
                                    fontWeight="400"
                                    paddingBottom="10px"
                                  >
                                    {reply.query}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      <div
                        style={{
                          justifyContent: "space-between",
                          alignItems: "space-between",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            width: "700px",
                          }}
                        >
                          <TextField
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            // disabled="disabled"
                            onChange={(e) => setCommentReply(e.target.value)}
                          ></TextField>
                        </div>
                        {userData?.roles.length == 1 ? (
                          <OutlineButton
                            text="Reply"
                            showIcon={false}
                            // onClick={}
                            onClickHandler={() => {
                              onReplyClickHandler(cmnt._id);
                            }}
                          ></OutlineButton>
                        ): (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </WrapperComponent>

            {userData?.roles.length == 1 ? (
            <Stack
              padding={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              display="flex"
            >
              <Button
                endIcon={<DoneIcon />}
                variant="contained"
                onClick={onClickHandler}
                sx={{
                  backgroundColor: Colors.piramal_orange,
                  color: Colors.white,

                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: Colors.piramal_orange,
                  },
                  "&:disabled": {
                    backgroundColor: "#FAC3B3",
                    color: "white",
                  },
                }}
                type="submit"
              >
                Save Details
              </Button>
            </Stack>
            ):(<></>)}
          </>
        )}
      </Layout>
    </>
  );
};

export default OriginatorKYCLinkUpdate;
